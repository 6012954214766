// source: domain/outsource.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var domain_enumeration_outsource_payment_method_type_pb = require('../domain/enumeration/outsource_payment_method_type_pb.js');
goog.object.extend(proto, domain_enumeration_outsource_payment_method_type_pb);
var domain_enumeration_outsource_working_day_type_pb = require('../domain/enumeration/outsource_working_day_type_pb.js');
goog.object.extend(proto, domain_enumeration_outsource_working_day_type_pb);
goog.exportSymbol('proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.OutsourceCompany', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.OutsourceCompanyContact', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.OutsourceDomain', null, global);
goog.exportSymbol('proto.coconala.protobuf.domain.OutsourceOnlineMeeting', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.OutsourceDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.coconala.protobuf.domain.OutsourceDomain.repeatedFields_, null);
};
goog.inherits(proto.coconala.protobuf.domain.OutsourceDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.OutsourceDomain.displayName = 'proto.coconala.protobuf.domain.OutsourceDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.OutsourceCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.domain.OutsourceCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.OutsourceCompany.displayName = 'proto.coconala.protobuf.domain.OutsourceCompany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.domain.OutsourceCompanyContact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.OutsourceCompanyContact.displayName = 'proto.coconala.protobuf.domain.OutsourceCompanyContact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.displayName = 'proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.coconala.protobuf.domain.OutsourceOnlineMeeting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.coconala.protobuf.domain.OutsourceOnlineMeeting.displayName = 'proto.coconala.protobuf.domain.OutsourceOnlineMeeting';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.coconala.protobuf.domain.OutsourceDomain.repeatedFields_ = [16,17,18,19,20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.OutsourceDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.OutsourceDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    experienceJobCategoryId: (f = msg.getExperienceJobCategoryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    paymentMethodType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    weeklyMinWorkingHour: (f = msg.getWeeklyMinWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    weeklyMaxWorkingHour: (f = msg.getWeeklyMaxWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMinWorkingHour: (f = msg.getMonthlyMinWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMaxWorkingHour: (f = msg.getMonthlyMaxWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    hourlyMinUnitPrice: (f = msg.getHourlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    hourlyMaxUnitPrice: (f = msg.getHourlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMinUnitPrice: (f = msg.getMonthlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMaxUnitPrice: (f = msg.getMonthlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    workingDayType: jspb.Message.getFieldWithDefault(msg, 13, 0),
    applicationDefaultMessage: (f = msg.getApplicationDefaultMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    outsourceCompany: (f = msg.getOutsourceCompany()) && proto.coconala.protobuf.domain.OutsourceCompany.toObject(includeInstance, f),
    skillForToolIdsList: jspb.Message.toObjectList(msg.getSkillForToolIdsList(),
    google_protobuf_wrappers_pb.Int32Value.toObject, includeInstance),
    skillForProgrammingIdsList: jspb.Message.toObjectList(msg.getSkillForProgrammingIdsList(),
    google_protobuf_wrappers_pb.Int32Value.toObject, includeInstance),
    licenseIdsList: jspb.Message.toObjectList(msg.getLicenseIdsList(),
    google_protobuf_wrappers_pb.Int32Value.toObject, includeInstance),
    urlsList: jspb.Message.toObjectList(msg.getUrlsList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    attachmentFilesList: jspb.Message.toObjectList(msg.getAttachmentFilesList(),
    proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.toObject, includeInstance),
    featureIdsList: jspb.Message.toObjectList(msg.getFeatureIdsList(),
    google_protobuf_wrappers_pb.Int32Value.toObject, includeInstance),
    outsourceCompanyContact: (f = msg.getOutsourceCompanyContact()) && proto.coconala.protobuf.domain.OutsourceCompanyContact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain}
 */
proto.coconala.protobuf.domain.OutsourceDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.OutsourceDomain;
  return proto.coconala.protobuf.domain.OutsourceDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.OutsourceDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain}
 */
proto.coconala.protobuf.domain.OutsourceDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setExperienceJobCategoryId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (reader.readEnum());
      msg.setPaymentMethodType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setWeeklyMinWorkingHour(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setWeeklyMaxWorkingHour(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMinWorkingHour(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMaxWorkingHour(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMinUnitPrice(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMaxUnitPrice(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMinUnitPrice(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMaxUnitPrice(value);
      break;
    case 13:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} */ (reader.readEnum());
      msg.setWorkingDayType(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setApplicationDefaultMessage(value);
      break;
    case 15:
      var value = new proto.coconala.protobuf.domain.OutsourceCompany;
      reader.readMessage(value,proto.coconala.protobuf.domain.OutsourceCompany.deserializeBinaryFromReader);
      msg.setOutsourceCompany(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.addSkillForToolIds(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.addSkillForProgrammingIds(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.addLicenseIds(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addUrls(value);
      break;
    case 20:
      var value = new proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain;
      reader.readMessage(value,proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.deserializeBinaryFromReader);
      msg.addAttachmentFiles(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.addFeatureIds(value);
      break;
    case 22:
      var value = new proto.coconala.protobuf.domain.OutsourceCompanyContact;
      reader.readMessage(value,proto.coconala.protobuf.domain.OutsourceCompanyContact.deserializeBinaryFromReader);
      msg.setOutsourceCompanyContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.OutsourceDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.OutsourceDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExperienceJobCategoryId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getWeeklyMinWorkingHour();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyMaxWorkingHour();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMinWorkingHour();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMaxWorkingHour();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHourlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHourlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWorkingDayType();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getApplicationDefaultMessage();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOutsourceCompany();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.coconala.protobuf.domain.OutsourceCompany.serializeBinaryToWriter
    );
  }
  f = message.getSkillForToolIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSkillForProgrammingIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLicenseIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.serializeBinaryToWriter
    );
  }
  f = message.getFeatureIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOutsourceCompanyContact();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.coconala.protobuf.domain.OutsourceCompanyContact.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value experience_job_category_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getExperienceJobCategoryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setExperienceJobCategoryId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearExperienceJobCategoryId = function() {
  return this.setExperienceJobCategoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasExperienceJobCategoryId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue title = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue description = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional enumeration.OutsourcePaymentMethodType payment_method_type = 4;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getPaymentMethodType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setPaymentMethodType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value weekly_min_working_hour = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getWeeklyMinWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setWeeklyMinWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearWeeklyMinWorkingHour = function() {
  return this.setWeeklyMinWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasWeeklyMinWorkingHour = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value weekly_max_working_hour = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getWeeklyMaxWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setWeeklyMaxWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearWeeklyMaxWorkingHour = function() {
  return this.setWeeklyMaxWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasWeeklyMaxWorkingHour = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_min_working_hour = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getMonthlyMinWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setMonthlyMinWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearMonthlyMinWorkingHour = function() {
  return this.setMonthlyMinWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasMonthlyMinWorkingHour = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_max_working_hour = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getMonthlyMaxWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setMonthlyMaxWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearMonthlyMaxWorkingHour = function() {
  return this.setMonthlyMaxWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasMonthlyMaxWorkingHour = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value hourly_min_unit_price = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getHourlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setHourlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearHourlyMinUnitPrice = function() {
  return this.setHourlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasHourlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value hourly_max_unit_price = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getHourlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setHourlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearHourlyMaxUnitPrice = function() {
  return this.setHourlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasHourlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_min_unit_price = 11;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getMonthlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setMonthlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearMonthlyMinUnitPrice = function() {
  return this.setMonthlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasMonthlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_max_unit_price = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getMonthlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setMonthlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearMonthlyMaxUnitPrice = function() {
  return this.setMonthlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasMonthlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional enumeration.OutsourceWorkingDayType working_day_type = 13;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getWorkingDayType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setWorkingDayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional google.protobuf.StringValue application_default_message = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getApplicationDefaultMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setApplicationDefaultMessage = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearApplicationDefaultMessage = function() {
  return this.setApplicationDefaultMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasApplicationDefaultMessage = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional OutsourceCompany outsource_company = 15;
 * @return {?proto.coconala.protobuf.domain.OutsourceCompany}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getOutsourceCompany = function() {
  return /** @type{?proto.coconala.protobuf.domain.OutsourceCompany} */ (
    jspb.Message.getWrapperField(this, proto.coconala.protobuf.domain.OutsourceCompany, 15));
};


/**
 * @param {?proto.coconala.protobuf.domain.OutsourceCompany|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setOutsourceCompany = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearOutsourceCompany = function() {
  return this.setOutsourceCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasOutsourceCompany = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated google.protobuf.Int32Value skill_for_tool_ids = 16;
 * @return {!Array<!proto.google.protobuf.Int32Value>}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getSkillForToolIdsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Int32Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 16));
};


/**
 * @param {!Array<!proto.google.protobuf.Int32Value>} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setSkillForToolIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.google.protobuf.Int32Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.addSkillForToolIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, google_protobuf_wrappers_pb.Int32Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearSkillForToolIdsList = function() {
  return this.setSkillForToolIdsList([]);
};


/**
 * repeated google.protobuf.Int32Value skill_for_programming_ids = 17;
 * @return {!Array<!proto.google.protobuf.Int32Value>}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getSkillForProgrammingIdsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Int32Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 17));
};


/**
 * @param {!Array<!proto.google.protobuf.Int32Value>} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setSkillForProgrammingIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.google.protobuf.Int32Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.addSkillForProgrammingIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, google_protobuf_wrappers_pb.Int32Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearSkillForProgrammingIdsList = function() {
  return this.setSkillForProgrammingIdsList([]);
};


/**
 * repeated google.protobuf.Int32Value license_ids = 18;
 * @return {!Array<!proto.google.protobuf.Int32Value>}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getLicenseIdsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Int32Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 18));
};


/**
 * @param {!Array<!proto.google.protobuf.Int32Value>} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setLicenseIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.google.protobuf.Int32Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.addLicenseIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, google_protobuf_wrappers_pb.Int32Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearLicenseIdsList = function() {
  return this.setLicenseIdsList([]);
};


/**
 * repeated google.protobuf.StringValue urls = 19;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getUrlsList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.addUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, google_protobuf_wrappers_pb.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearUrlsList = function() {
  return this.setUrlsList([]);
};


/**
 * repeated OutsourceAttachmentFileDomain attachment_files = 20;
 * @return {!Array<!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain>}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getAttachmentFilesList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain, 20));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain>} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setAttachmentFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.addAttachmentFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearAttachmentFilesList = function() {
  return this.setAttachmentFilesList([]);
};


/**
 * repeated google.protobuf.Int32Value feature_ids = 21;
 * @return {!Array<!proto.google.protobuf.Int32Value>}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getFeatureIdsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Int32Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 21));
};


/**
 * @param {!Array<!proto.google.protobuf.Int32Value>} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setFeatureIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.google.protobuf.Int32Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.addFeatureIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, google_protobuf_wrappers_pb.Int32Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearFeatureIdsList = function() {
  return this.setFeatureIdsList([]);
};


/**
 * optional OutsourceCompanyContact outsource_company_contact = 22;
 * @return {?proto.coconala.protobuf.domain.OutsourceCompanyContact}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.getOutsourceCompanyContact = function() {
  return /** @type{?proto.coconala.protobuf.domain.OutsourceCompanyContact} */ (
    jspb.Message.getWrapperField(this, proto.coconala.protobuf.domain.OutsourceCompanyContact, 22));
};


/**
 * @param {?proto.coconala.protobuf.domain.OutsourceCompanyContact|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceDomain.prototype.setOutsourceCompanyContact = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.clearOutsourceCompanyContact = function() {
  return this.setOutsourceCompanyContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceDomain.prototype.hasOutsourceCompanyContact = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.OutsourceCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.OutsourceCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    postalCode: (f = msg.getPostalCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefectureId: (f = msg.getPrefectureId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    districtName: (f = msg.getDistrictName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    streetAddress: (f = msg.getStreetAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buildingName: (f = msg.getBuildingName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    url: (f = msg.getUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    logoFileName: (f = msg.getLogoFileName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    logoFileUrl: (f = msg.getLogoFileUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany}
 */
proto.coconala.protobuf.domain.OutsourceCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.OutsourceCompany;
  return proto.coconala.protobuf.domain.OutsourceCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.OutsourceCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany}
 */
proto.coconala.protobuf.domain.OutsourceCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPostalCode(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPrefectureId(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDistrictName(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStreetAddress(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuildingName(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUrl(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLogoFileName(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLogoFileUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.OutsourceCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.OutsourceCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostalCode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefectureId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDistrictName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreetAddress();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuildingName();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLogoFileName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLogoFileUrl();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue postal_code = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getPostalCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setPostalCode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearPostalCode = function() {
  return this.setPostalCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasPostalCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value prefecture_id = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getPrefectureId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setPrefectureId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearPrefectureId = function() {
  return this.setPrefectureId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasPrefectureId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue city = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasCity = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue district_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getDistrictName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setDistrictName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearDistrictName = function() {
  return this.setDistrictName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasDistrictName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue street_address = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getStreetAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setStreetAddress = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearStreetAddress = function() {
  return this.setStreetAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasStreetAddress = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue building_name = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getBuildingName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setBuildingName = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearBuildingName = function() {
  return this.setBuildingName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasBuildingName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue url = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setUrl = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearUrl = function() {
  return this.setUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue description = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue logo_file_name = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getLogoFileName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setLogoFileName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearLogoFileName = function() {
  return this.setLogoFileName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasLogoFileName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue logo_file_url = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.getLogoFileUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompany.prototype.setLogoFileUrl = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompany} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.clearLogoFileUrl = function() {
  return this.setLogoFileUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompany.prototype.hasLogoFileUrl = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.OutsourceCompanyContact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.OutsourceCompanyContact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastName: (f = msg.getLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstName: (f = msg.getFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastNameKana: (f = msg.getLastNameKana()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstNameKana: (f = msg.getFirstNameKana()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.OutsourceCompanyContact;
  return proto.coconala.protobuf.domain.OutsourceCompanyContact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.OutsourceCompanyContact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastNameKana(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstNameKana(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.OutsourceCompanyContact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.OutsourceCompanyContact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastNameKana();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstNameKana();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue last_name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.getLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.setLastName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.clearLastName = function() {
  return this.setLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue first_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.getFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.setFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.clearFirstName = function() {
  return this.setFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue last_name_kana = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.getLastNameKana = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.setLastNameKana = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.clearLastNameKana = function() {
  return this.setLastNameKana(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.hasLastNameKana = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue first_name_kana = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.getFirstNameKana = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.setFirstNameKana = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.clearFirstNameKana = function() {
  return this.setFirstNameKana(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.hasFirstNameKana = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue phone_number = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.getPhoneNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
*/
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceCompanyContact} returns this
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceCompanyContact.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: (f = msg.getFileName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    fileUrl: (f = msg.getFileUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    size: (f = msg.getSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    mime: (f = msg.getMime()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain;
  return proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFileName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFileUrl(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFileUrl();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue file_name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.getFileName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.setFileName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.clearFileName = function() {
  return this.setFileName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.hasFileName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue file_url = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.getFileUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.setFileUrl = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.clearFileUrl = function() {
  return this.setFileUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.hasFileUrl = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value size = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.getSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.hasSize = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue mime = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.getMime = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} returns this
*/
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.setMime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain} returns this
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.clearMime = function() {
  return this.setMime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceAttachmentFileDomain.prototype.hasMime = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.toObject = function(opt_includeInstance) {
  return proto.coconala.protobuf.domain.OutsourceOnlineMeeting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourceOnlineMeetingId: (f = msg.getOutsourceOnlineMeetingId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    startingAt: (f = msg.getStartingAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endingAt: (f = msg.getEndingAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    joinUrl: (f = msg.getJoinUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.coconala.protobuf.domain.OutsourceOnlineMeeting;
  return proto.coconala.protobuf.domain.OutsourceOnlineMeeting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOutsourceOnlineMeetingId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartingAt(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndingAt(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setJoinUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.coconala.protobuf.domain.OutsourceOnlineMeeting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourceOnlineMeetingId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getStartingAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndingAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getJoinUrl();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value outsource_online_meeting_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.getOutsourceOnlineMeetingId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} returns this
*/
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.setOutsourceOnlineMeetingId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} returns this
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.clearOutsourceOnlineMeetingId = function() {
  return this.setOutsourceOnlineMeetingId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.hasOutsourceOnlineMeetingId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp starting_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.getStartingAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} returns this
*/
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.setStartingAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} returns this
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.clearStartingAt = function() {
  return this.setStartingAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.hasStartingAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp ending_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.getEndingAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} returns this
*/
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.setEndingAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} returns this
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.clearEndingAt = function() {
  return this.setEndingAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.hasEndingAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue join_url = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.getJoinUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} returns this
*/
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.setJoinUrl = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.coconala.protobuf.domain.OutsourceOnlineMeeting} returns this
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.clearJoinUrl = function() {
  return this.setJoinUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.coconala.protobuf.domain.OutsourceOnlineMeeting.prototype.hasJoinUrl = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.coconala.protobuf.domain);
