import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '~/store/_helpers/common-helper'

const initialState = {
  page: 0,
  limit: 0,
  offset: 0,
  keyword: '',
  jobIds: [],
  total: 0,
  maximumPage: 0,
  results: []
}

export const state = () => initialState

export const getters = {}

// TODO store経由じゃなくても参照できる場所に定数まとめたい
/** Protobufのint32最大値 */
const MAX_INT32 = Math.pow(2, 31) - 1

/**
 * 整数正規化
 * @param {number|string|undefined} value
 */
const normalizeInt = (value, min = 0, max = MAX_INT32) => {
  if (typeof value === 'undefined') {
    return min
  }
  if (typeof value === 'string' && !/[0-9]+/.test(value)) {
    return min
  }
  if (typeof value === 'number') {
    return value
  }
  const normalizedValue = Math.floor(Number(value))
  if (normalizedValue < min) {
    return min
  }
  if (normalizedValue > max) {
    return max
  }
  return normalizedValue
}

/**
 * Boolean正規化
 * @param {boolean|string|undefined} value
 */
const normalizeBool = value => {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true'
  }
  // 型をbooleanにする
  return !!value
}

/**
 * SearchUsersRequest生成
 * @param {*} query
 */
const createSearchUserRequest = async query => {
  const { SearchUsersRequest } = await import('~/stub/apigateway/profile/search_pb')
  const request = new SearchUsersRequest()
  if (query.page) {
    request.setPage(normalizeInt(query.page, 1))
  }
  if (query.limit) {
    request.setLimit(normalizeInt(query.limit, 1))
  }
  if (query.keyword) {
    request.setKeyword(query.keyword)
  }
  if (Array.isArray(query.jobIds)) {
    request.setJobIdsList(
      query.jobIds.filter(x => /^[0-9]+$/.test(String(x))).map(x => normalizeInt(x))
    )
  }
  if (query.proPriority) {
    request.setProPriority(normalizeBool(query.proPriority))
  }
  if (query.delivery) {
    request.setDelivery(normalizeInt(query.delivery))
  }
  if (query.identification) {
    request.setIdentification(normalizeBool(query.identification))
  }
  if (query.ndaConclusionFlag) {
    request.setNdaConclusionFlag(normalizeBool(query.ndaConclusionFlag))
  }
  if (query.qualifiedInvoiceIssuerFlag) {
    request.setQualifiedInvoiceIssuerFlag(normalizeBool(query.qualifiedInvoiceIssuerFlag))
  }
  if (query.loginDays) {
    request.setLoginDays(normalizeInt(query.loginDays))
  }
  if (query.userStatus) {
    request.setUserStatus(normalizeInt(query.userStatus))
  }
  if (Array.isArray(query.registrations)) {
    request.setRegistrationsList(query.registrations)
  }
  if (query.businessFlag) {
    request.setBusinessFlag(normalizeBool(query.businessFlag))
  }
  if (query.mzhcFlag) {
    request.setIsMzhc(normalizeBool(query.mzhcFlag))
  }
  return request
}

/**
 * 末尾がListのプロパティ名をListなしに変換
 * プロパティがオブジェクトまたはオブジェクト配列だった場合は再帰的に変換する
 */
const transformGrpcWebReply = reply => {
  const transformedReply = {}
  Object.entries(reply).forEach(([key, value]) => {
    const newKey = key.replace(/List$/, '')
    if (Array.isArray(value)) {
      value = value.map(x => {
        if (typeof x === 'object') {
          return transformGrpcWebReply(x)
        }
        return x
      })
    } else if (typeof value === 'object') {
      value = transformGrpcWebReply(value)
    }
    transformedReply[newKey] = value
  })
  return transformedReply
}

/**
 * @typedef {typeof initialState} UsersSearchState
 */
/**
 * @typedef {import('vuex').ActionContext<UsersSearchState>} UsersSearchActionContext
 */

export const actions = {
  /**
   * 出品者検索
   * @param {UsersSearchActionContext} actionContext
   * @param {*} query
   */
  async searchUsers({ rootState, commit }, query) {
    const request = await createSearchUserRequest(query)
    const metadata = commonMetaData(rootState)
    metadata['x-log-detail'] = JSON.stringify({ view_id: rootState.big_query.viewId })

    const { SearchService } = await import('~/stub/apigateway/profile/search_pb_service')
    const reply = await grpcClient({
      method: SearchService.SearchUsers,
      request,
      metadata,
      transformReply: transformGrpcWebReply
    })
    commit('RECEIVE_SEARCH_USERS', reply)
  },
  /**
   * フォローON/OFF
   * @param {UsersSearchActionContext} actionContext
   * @param {import('~/stub/apigateway/profile/search_pb').UserSearch.AsObject} user
   */
  async toggleFollow({ rootState, commit }, user) {
    const [{ FollowRequest }, { FollowService }] = await Promise.all([
      import('~/stub/apigateway/profile/follow_pb'),
      import('~/stub/apigateway/profile/follow_pb_service')
    ])
    const method = user.isFollowed ? FollowService.DeleteFollow : FollowService.AddFollow

    const request = new FollowRequest()
    request.setFollowId(user.userId)

    const metadata = commonMetaData(rootState)

    // catchしないで成功時のみcommitさせる
    await grpcClient({
      method,
      request,
      metadata
    })

    commit('TOGGLE_FOLLOW', user.userId)
  }
}

export const mutations = {
  /**
   * 検索結果格納
   * @param {UsersSearchState} state
   * @param {*} data
   */
  RECEIVE_SEARCH_USERS(state, data) {
    Object.assign(state, data)
  },
  /**
   * お気に入りON/OFF
   * @param {UsersSearchState} state
   * @param {number} userId
   */
  TOGGLE_FOLLOW(state, userId) {
    let target = state.results.find(user => user.userId === userId)
    if (target) {
      target.isFollowed = !target.isFollowed
    }
  }
}
