import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

import { TemplateService } from '~/stub/apigateway/user/template_pb_service'
import {
  GetUserTemplatesRequest,
  AddUserTemplateRequest,
  UpdateUserTemplateRequest,
  DeleteUserTemplateRequest
} from '~/stub/apigateway/user/template_pb'

// initial state
export const state = () => ({
  resultList: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async getUserTemplates({ commit, rootState }) {
    const reply = await new Promise((resolve, reject) => {
      const request = new GetUserTemplatesRequest()
      const metadata = commonMetaData(rootState)
      grpc.invoke(TemplateService.GetUserTemplates, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })

    commit('RECEIVE_USER_TEMPLATE', reply)
  },
  async addUserTemplate({ commit, rootState }, data) {
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new AddUserTemplateRequest()

    request.setContent(params.content)

    await new Promise((resolve, reject) => {
      grpc.invoke(TemplateService.AddUserTemplate, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })
  },
  async updateUserTemplate({ commit, rootState }, data) {
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new UpdateUserTemplateRequest()

    request.setUserTemplateId(params.userTemplateId)
    request.setContent(params.content)

    await new Promise((resolve, reject) => {
      grpc.invoke(TemplateService.UpdateUserTemplate, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })
  },
  async deleteUserTemplate({ commit, rootState }, data) {
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new DeleteUserTemplateRequest()

    request.setUserTemplateId(params.userTemplateId)

    await new Promise((resolve, reject) => {
      grpc.invoke(TemplateService.DeleteUserTemplate, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_USER_TEMPLATE(state, data) {
    Object.assign(state, data)
  }
}
