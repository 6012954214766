const SP_MENU_BUTTON_GUIDE_LOCALSTORAGE_KEY = 'sp_menu_button_guide'

// initial state
export const state = () => ({
  spMenuButtonGuideScene: ''
})

// getters
export const getters = {}

// actions
export const actions = {
  /**
   * SPメニューボタンのシーンを設定し、LocalStorageに保存する
   */
  setSpMenuButtonGuideScene({ commit }, sceneName) {
    commit('SET_SP_MENU_BUTTON_GUIDE_SCENE', sceneName)
    localStorage.setItem(SP_MENU_BUTTON_GUIDE_LOCALSTORAGE_KEY, sceneName)
  }
}

// mutations
export const mutations = {
  SET_SP_MENU_BUTTON_GUIDE_SCENE(state, data) {
    state.spMenuButtonGuideScene = data
  }
}
