<template>
  <div>
    <div
      class="c-theHeaderAdjust"
      :class="{
        'c-theHeaderAdjust-notFixed': !isHeaderFixed,
        'c-theHeaderAdjust-notFixedSp': !isHeaderFixedSp,
        'c-theHeaderAdjust-appBanner': showAppBanner,
        'c-theHeaderAdjust-notFixedPc': isAppBannerFixedPage,
        'c-theHeaderAdjust-fixedAppBanner': isAppBannerFixedPage,
        'c-theHeaderAdjust-logoOnlyMode': logoOnlyMode,
        'c-theHeaderAdjust-mzhc': headerType === HEADER_TYPE.MZHC,
        'c-theHeaderAdjust-mzhcSimple': headerType === HEADER_TYPE.MZHC_SIMPLE
      }"
    ></div>
    <div
      class="c-theHeader js-fixableHeader"
      :class="{
        'c-theHeader-fixedAppBanner': isAppBannerFixedPage,
        'c-theHeader-notFixed': !isHeaderFixed,
        'c-theHeader-notFixedSp': !isHeaderFixedSp,
        'c-theHeader-notFixedPc': isAppBannerFixedPage
      }"
    >
      <AppBanner
        v-if="showAppBanner"
        :app-cookie-key="APP_COOKIE_KEY"
        @closeBanner="setCloseBanner"
        ref="appBanner"
      />
      <header
        v-if="headerType === HEADER_TYPE.MZHC_SIMPLE"
        class="c-header c-header-mzhcSimple js-header"
        ref="header"
      >
        <a href="/mizuho_coconala">
          <img
            src="~/assets/images/mzhc/mzhc-logo.png"
            class="c-header_mzhcSimpleLogo"
            alt="みずほココナラ"
          />
        </a>
      </header>
      <header
        v-else-if="headerType === HEADER_TYPE.MZHC"
        class="c-header c-header-mzhc js-header"
        ref="header"
      >
        <div class="c-header_inner c-header_inner-mzhc">
          <a
            v-if="isLoggedIn"
            href="javascript:;"
            class="c-header_sidebarToggle c-header_sidebarToggle-mzhc js-spMenuButtonGuideTarget"
            @click="showSideBar"
          >
            <CoconalaIcon
              name="bars"
              size="18px"
            />
          </a>
          <div class="c-logo c-logo-mzhc">
            <!-- nuxtの初期化が行われるよう非nuxt-linkにしてある。詳細: https://github.com/welself/web-ui-components/pull/572 -->
            <a href="/">
              <img
                class="c-logo_img c-logo_img-mzhc"
                src="~/assets/images/mzhc/mzhc-logo.png"
                alt="みずほココナラ"
              />
            </a>
          </div>
          <div class="c-searchForm">
            <DSearchInput
              @change="doSearch($event)"
              @suggest="doSuggest"
              @selectSuggest="sendSuggestSelectLog"
              @clearSuggest="clearSuggest"
              :header-type="headerType"
              :suggest-result="suggestResult"
              :initial-placeholder="$t('header.searchPlaceholder')"
              expanded
            />
          </div>
          <div class="c-headerInnerLeft c-headerInner_left">
            <a
              class="c-headerInnerLeft_searchMenu"
              href="javascript:;"
              @click="toggleSearchDrawer"
            >
              <CoconalaIcon
                name="search"
                size="22px"
              />
            </a>
            <MainNav
              :auth="auth"
              :header-type="headerType"
              :is-interest-categories-menu-shown="isInterestCategoriesMenuShown"
              @change-header-to-provider="changeHeaderToProvider()"
              @change-header-to-buyer="changeHeaderToBuyer()"
              @click-search="toggleSearchDrawer"
              @open-user-menu="isOpenUserMenu = true"
              @close-user-menu="isOpenUserMenu = false"
              @click-menu-guide-transaction="clickMenuGuideTransaction"
            />
          </div>
        </div>
        <div class="c-subHeader">
          <HeaderSub
            :jobs="jobs"
            :blog-categories="blogCategories"
            :master-categories="masterCategories"
            :is-logged-in="isLoggedIn"
            :header-type="headerType"
          />
        </div>
      </header>
      <header
        v-else-if="headerType === HEADER_TYPE.SIMPLE"
        class="c-header js-header c-header-logoOnlyMode"
        ref="header"
      >
        <div class="c-header_inner">
          <div class="c-logo">
            <a href="/">
              <CoconalaLogo type="full"></CoconalaLogo>
              <CoconalaLogo type="simple"></CoconalaLogo>
            </a>
          </div>
        </div>
      </header>
      <header
        v-else
        class="c-header js-header"
        ref="header"
      >
        <div class="c-header_inner">
          <a
            href="javascript:;"
            class="c-header_sidebarToggle js-spMenuButtonGuideTarget"
            v-if="isLoggedIn"
            @click="showSideBar"
          >
            <CoconalaIcon
              name="bars"
              size="18px"
            />
          </a>
          <div class="c-logo">
            <!-- nuxtの初期化が行われるよう非nuxt-linkにしてある。詳細: https://github.com/welself/web-ui-components/pull/572 -->
            <a href="/">
              <CoconalaLogo type="full"></CoconalaLogo>
              <CoconalaLogo type="simple"></CoconalaLogo>
            </a>
          </div>
          <div
            class="c-providerLeft"
            v-if="headerType === HEADER_TYPE.PROVIDER"
          >
            <MainNavProviderLeft @click-menu-guide-transaction="clickMenuGuideTransaction" />
          </div>
          <div
            class="c-searchForm"
            v-else-if="headerType"
            v-intersect-translate.once
          >
            <DSearchInput
              @change="doSearch($event)"
              @suggest="doSuggest"
              @selectSuggest="sendSuggestSelectLog"
              @clearSuggest="clearSuggest"
              :suggest-result="suggestResult"
              :initial-placeholder="$t('header.searchPlaceholder')"
              expanded
            />
          </div>
          <div class="c-headerInnerLeft c-headerInner_left">
            <a
              class="c-headerInnerLeft_searchMenu"
              href="javascript:;"
              @click="toggleSearchDrawer"
            >
              <CoconalaIcon
                name="search"
                size="22px"
              />
            </a>
            <MainNavBeforeLogin
              @click-search="toggleSearchDrawer"
              v-if="headerType === HEADER_TYPE.BEFORE_LOGIN"
            />
            <MainNav
              :auth="auth"
              :header-type="headerType"
              :is-interest-categories-menu-shown="isInterestCategoriesMenuShown"
              @change-header-to-provider="changeHeaderToProvider()"
              @change-header-to-buyer="changeHeaderToBuyer()"
              @click-search="toggleSearchDrawer"
              @open-user-menu="isOpenUserMenu = true"
              @close-user-menu="isOpenUserMenu = false"
              @click-menu-guide-transaction="clickMenuGuideTransaction"
              v-else
            />
          </div>
        </div>
        <div class="c-subHeader">
          <HeaderSub
            :jobs="jobs"
            :blog-categories="blogCategories"
            :master-categories="masterCategories"
            :is-logged-in="isLoggedIn"
            :header-type="headerType"
          />
        </div>
      </header>
    </div>
    <ClientOnly>
      <MainNavSearch
        v-if="isActiveSearchDrawer"
        :header-type="headerType"
        @search="doSearch($event)"
        @close="toggleSearchDrawer(false)"
      />
      <OSidebar
        v-if="hasSidebar"
        :open.sync="isActiveSidebar"
        fullheight
        overlay
        scroll="keep"
        @close="onCloseSidebar"
      >
        <MenuSidebar
          :auth="auth"
          :header-type="headerType"
          :is-interest-categories-menu-shown="isInterestCategoriesMenuShown"
          @change-header-to-provider="changeHeaderToProvider('spmenu_change')"
          @change-header-to-buyer="changeHeaderToBuyer('spmenu_change')"
          @change-service-search-collapse="changeServiceSearchCollapse"
          @click-menu-guide-transaction="clickMenuGuideTransaction"
          @click.native="clickSidebar"
        />
      </OSidebar>
      <transition
        v-if="hasSidebar"
        name="fade-in"
      >
        <CoconalaIcon
          name="cross"
          class="c-sidebarClose"
          v-if="isActiveSidebar"
        />
      </transition>

      <div
        class="c-menuGuide-sp"
        v-if="showMenuGuide && isActiveSidebar"
      >
        <MenuGuide
          mode="sp"
          :header-type="headerType"
          :is-sp-service-search-open="isSpServiceSearchOpen"
        />
      </div>

      <div v-if="showMenuGuide">
        <div class="c-menuGuide-pc">
          <MenuGuide
            mode="pc"
            :header-type="headerType"
          />
        </div>
        <Portal
          to="menuGuideTb"
          :disabled="disabledMenuGuideTb"
        >
          <!-- TB及び幅の狭いPCでガイドバルーンをマイメニュー内に表示する場合、マイメニューのホバー中判定にガイドも含めたい為Portal利用でDOM移動 -->
          <div
            v-if="disabledMenuGuideTb || isOpenUserMenu"
            class="c-menuGuide-tb"
          >
            <MenuGuide
              mode="tb"
              :is-open-user-menu="isOpenUserMenu"
              :header-type="headerType"
            />
          </div>
        </Portal>
      </div>

      <SpMenuButtonGuide
        class="c-menuButtonGuide"
        v-if="showMenuGuide && !isActiveSidebar"
        :has-app-banner="showAppBanner"
      />
    </ClientOnly>
  </div>
</template>
<script>
// ヘッダーを固定しないページ（PC,SP両方適用）
const NOT_FIXED_PAGES = [
  'services-id-reservation',
  'blogs-id-blogId-input',
  'blogs-id-blogId-confirm',
  'blogs-id-blogId-complete',
  'talkrooms-talkroomId',
  'talkrooms-talkroomId-information',
  'talkrooms-talkroomId-files'
]

// ヘッダーを固定しないページ（SPのみ適用）
const NOT_FIXED_PAGES_ONLY_SP = ['mypage-reservation-calendar']

// ヘッダーとバナーを固定するページ（SPのみ適応）
const FIXED_PAGES_ONLY_SP = [
  'services-id',
  'search',
  'categories-categoryId',
  'categories-categoryId-categoryTypeId',
  'blogs-id-blogId',
  'blogs-ss-hashId'
]

const APP_COOKIE_KEY = 'smart_app_banner_hidden'

import { HEADER_TYPE_COOKIE } from '~/constants/cookie'
import { GUIDE_SCENE_NAME } from '~/store/menu_guide'
import { mapState, mapActions, mapMutations } from 'vuex'

import CoconalaLogo from '~/components/atoms/CoconalaLogo'
import { toTranslatedTextNoUseCache } from '~/components/logics/translate/translate'
import { LANGUAGES, LANGUAGE_SEARCH_ORIGIN_TEXT } from '~/constants/language'

export default {
  name: 'TheHeader',
  components: {
    CoconalaLogo,
    AppBanner: () => import('./components/AppBanner'),
    HeaderSub: () => import('./components/HeaderSub'),
    MainNav: () => import('./components/MainNav'),
    MainNavBeforeLogin: () => import('./components/MainNavBeforeLogin'),
    MainNavProviderLeft: () => import('./components/MainNavProviderLeft'),
    MainNavSearch: () => import('./components/MainNavSearch'),
    MenuSidebar: () => import('./components/MenuSidebar'),
    DSearchInput: () => import('~/components/molecules/DS2/DSearchInput'),
    DSearchInputBusiness: () => import('~/components/molecules/DS2/DSearchInputBusiness'),
    MenuGuide: () => import('~/components/organisms/MenuGuide'),
    SpMenuButtonGuide: () => import('~/components/organisms/SpMenuButtonGuide')
  },
  props: {
    jobs: {
      type: Array
    },
    masterCategories: {
      type: Array
    },
    blogCategories: {
      type: Array
    },
    auth: {
      type: Object
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      APP_COOKIE_KEY,
      headerType: '',
      isActiveSidebar: false,
      closeAppBanner: false,
      isOpenUserMenu: false,
      isSpServiceSearchOpen: false
    }
  },
  computed: {
    ...mapState('constants', [
      'HEADER_TYPE',
      'HEADER_TYPE_SIMPLE_PAGES',
      'HEADER_TYPE_MZHC_SIMPLE_PAGES',
      'HEADER_TYPE_PROVIDER_ONLY_PAGES',
      'HEADER_TYPE_BUYER_ONLY_PAGES'
    ]),
    ...mapState('my/header', [
      'installedApp',
      'isProvider',
      'headerTypeCookie',
      'hasFetchedProvider',
      'privateAccountInfo',
      'isActiveSearchDrawer',
      'hasRegisteredInterestCategories'
    ]),
    ...mapState('layouts', ['logoOnlyMode']),
    ...mapState('suggest', ['suggestResult']),
    ...mapState('menu_guide', ['currentGuideScene']),
    isHeaderFixed() {
      // ヘッダーを固定しないページの$route.nameをNOT_FIXED_PAGESに記述する
      return NOT_FIXED_PAGES.includes(this.$route.name) ? false : true
    },
    isHeaderFixedSp() {
      // SPのみヘッダーを固定しないページの$route.nameをNOT_FIXED_PAGES_ONLY_SPに記述する
      // NOTE: breakpointは(Header, S)としている為、該当ページのbreakpointが異なる場合は問題ないか要確認
      return NOT_FIXED_PAGES_ONLY_SP.includes(this.$route.name) ? false : true
    },
    // SPのみヘッダーとアプリバナーを固定するページどうかの判定
    isAppBannerFixedPage() {
      return FIXED_PAGES_ONLY_SP.includes(this.$route.name)
    },
    showAppBanner() {
      // 会員登録トップ以外のシンプルヘッダーとビジネスプレビューではアプリバナーを出さない
      if (
        this.$route.name !== 'register-top' &&
        [
          this.HEADER_TYPE.SIMPLE,
          this.HEADER_TYPE.MZHC_SIMPLE,
          this.HEADER_TYPE.MZHC,
          this.HEADER_TYPE.BUSINESS_SIMPLE,
          this.HEADER_TYPE.BUSINESS_BEFORE_LOGIN
        ].includes(this.headerType)
      ) {
        return false
      }

      return (
        this.$ua.isFromSmartphone() &&
        this.$cookies.get(APP_COOKIE_KEY) !== true &&
        !this.installedApp &&
        !this.$route.query.from_ad &&
        !this.closeAppBanner
      )
    },
    showMenuGuide() {
      return (
        [this.HEADER_TYPE.PROVIDER, this.HEADER_TYPE.BUYER].includes(this.headerType) &&
        this.$translate.showJapaneseOnly()
      )
    },
    hasSidebar() {
      return [
        this.HEADER_TYPE.PROVIDER,
        this.HEADER_TYPE.BUYER,
        this.HEADER_TYPE.MZHC,
        this.HEADER_TYPE.BUSINESS
      ].includes(this.headerType)
    },
    isInterestCategoriesMenuShown() {
      return (
        this.auth.user &&
        !this.auth.user.hasBoughtService &&
        this.auth.user.signupAttributePurpose !== '受注' &&
        !this.hasRegisteredInterestCategories
      )
    },
    disabledMenuGuideTb() {
      // TB及び幅の狭いPCで、ガイドバルーンをマイメニュー外で常に表示する場合
      // components/organisms/MenuGuide/MenuGuide.vue内のisShowに条件合わせる
      return (
        this.headerType === this.HEADER_TYPE.PROVIDER &&
        !['', GUIDE_SCENE_NAME.MODE_CHANGE].includes(this.currentGuideScene)
      )
    }
  },
  watch: {
    $route() {
      this.setHeaderType()
    },
    logoOnlyMode() {
      this.setHeaderType()
    },
    isActiveSidebar(newVal) {
      this.$util.bodyScrollPrevent(newVal)
      this.$util.hideZendeskWebWidgetWhileFlag(newVal)
    },
    isActiveSearchDrawer(newVal) {
      this.$util.hideZendeskWebWidgetWhileFlag(newVal)
    },
    // 絞り込みバー追従のための条件を追加
    showAppBanner: {
      handler(newVal) {
        this.updateAppBannerHeight(newVal)
      }
    }
  },
  async fetch() {
    if (this.isLoggedIn && this.$ua.isFromSmartphone()) {
      await this.fetchHeader()
    } else {
      this.$store.commit('my/header/FALSE_INSTALLED_APP')
    }
    await this.setHeaderType()
    this.$store.commit('my/header/SET_HEADER_TYPE', this.headerType)
  },
  mounted() {
    this.updateAppBannerHeight(this.showAppBanner)
    if (this.isLoggedIn) {
      this.fetchHeader()
      this.$store.dispatch('my/header/fetchUserInterestCategories')
    }
    // 人気のご利用シーン取得
    this.$store.dispatch('master/getUseScenes').catch(() => {})
  },
  beforeUnmount() {
    window.removeEventListener('touchmove', this.preventTouchMove)
  },
  methods: {
    ...mapActions('menu_guide', ['setFinishedModeChangeScene', 'setFinishedTransactionScene']),
    ...mapActions('sp_menu_guide', ['setSpMenuButtonGuideScene']),
    ...mapMutations('my/header', ['TOGGLE_SEARCH_DRAWER']),
    async fetchHeader() {
      if (!this.hasFetchedProvider) {
        // TODO: エラー時Sentryに送信
        await this.$store.dispatch('my/fetchHeader')
      }
    },
    async doSearch({ searchType, searchAttributes }) {
      let url = null
      let keyword = searchAttributes.searchText
      if (this.$translate.isTranslatableTarget()) {
        // 外国人が検索した場合、日本語に変換しリクエストを投げる
        keyword = await toTranslatedTextNoUseCache(keyword, LANGUAGES.JA.code)
        // 検索バーに表示するのは入力した文字列にしたいので、Cookieに保存
        this.$cookies.set(
          LANGUAGE_SEARCH_ORIGIN_TEXT,
          JSON.stringify({ [searchAttributes.searchText]: keyword }),
          {
            path: '/',
            maxAge: 60 * 60 * 24 * 365
          }
        )
      }

      const option = { keyword }
      if (searchAttributes.searchCategoryId) {
        option.category_id = searchAttributes.searchCategoryId
      }
      if (searchType === 'service') {
        url = this.$coconala.rootPathUrl('/search', option)
      } else if (searchType === 'user') {
        url = this.$coconala.rootPathUrl('/users/search', option)
      } else if (searchType === 'request') {
        url = this.$coconala.rootPathUrl('/requests', option)
      } else {
        if (option.keyword) {
          url = this.$coconala.rootPathUrl('/blogs/search', option)
        } else {
          // SEO上、末尾をスラッシュにする
          url = '/blogs/'
        }
      }
      if (this.headerType === this.HEADER_TYPE.BUSINESS_BEFORE_LOGIN) {
        url += url.indexOf('?') === -1 ? '?is_business=1' : '&is_business=1'
      }
      // 検索条件を強制リセットするためlocation.hrefにする
      location.href = url
    },
    async doSuggest(keyword) {
      await this.$store.dispatch('suggest/fetchSuggest', { keyword })
    },
    async sendSuggestSelectLog(params) {
      await this.$store.dispatch('suggest/sendSuggestSelectLog', params)
    },
    clearSuggest() {
      this.$store.dispatch('suggest/clearSuggest')
    },
    setCloseBanner() {
      this.closeAppBanner = true
    },
    async setHeaderType() {
      /**
       * header_type(cookie)にセットしている値はTOPページのバナーの出しわけの判定にも使用されてます
       * @see privateIndex heroArea()
       */
      if (
        (!this.isLoggedIn &&
          (this.$store.state.register.isMzhc ||
            this.privateAccountInfo.isMzhc ||
            !!this.$route.query.is_mzhc)) ||
        this.HEADER_TYPE_MZHC_SIMPLE_PAGES?.includes(this.$route.name)
      ) {
        this.headerType = this.HEADER_TYPE.MZHC_SIMPLE
        return
      }
      if (this.isLoggedIn && this.privateAccountInfo.isMzhc) {
        this.headerType = this.HEADER_TYPE.MZHC
        return
      }

      if (this.logoOnlyMode || this.HEADER_TYPE_SIMPLE_PAGES?.includes(this.$route.name)) {
        this.headerType = this.HEADER_TYPE.SIMPLE
        return
      }

      if (!this.isLoggedIn) {
        this.headerType = this.HEADER_TYPE.BEFORE_LOGIN
        return
      }

      if (this.$translate.isTranslatableTarget()) {
        this.headerType = this.HEADER_TYPE.BUYER
        return
      }

      if (this.HEADER_TYPE_PROVIDER_ONLY_PAGES?.includes(this.$route.name)) {
        this.setCookieHeaderTypeProvider()
        this.headerType = this.HEADER_TYPE.PROVIDER
        return
      }

      if (this.HEADER_TYPE_BUYER_ONLY_PAGES?.includes(this.$route.name)) {
        this.setCookieHeaderTypeBuyer()
        this.headerType = this.HEADER_TYPE.BUYER
        return
      }

      const headerTypeCookie = this.$cookies.get(HEADER_TYPE_COOKIE.NAME) || ''
      if (!headerTypeCookie) {
        await this.fetchHeader()

        if (this.isProvider) {
          this.setCookieHeaderTypeProvider()
          this.headerType = this.HEADER_TYPE.PROVIDER
          return
        }

        this.setCookieHeaderTypeBuyer()
        this.headerType = this.HEADER_TYPE.BUYER
        return
      }

      if (headerTypeCookie === HEADER_TYPE_COOKIE.VALUE_PROVIDER) {
        this.headerType = this.HEADER_TYPE.PROVIDER
        return
      }

      this.headerType = this.HEADER_TYPE.BUYER
    },
    async changeHeaderToBuyer(ref = 'header_change') {
      this.setFinishedModeChangeScene()
      this.setCookieHeaderTypeBuyer()
      location.href = '/?ref=' + ref
    },
    async changeHeaderToProvider(ref = 'header_change') {
      this.setFinishedModeChangeScene()
      this.setCookieHeaderTypeProvider()
      location.href = '/mypage/dashboard_provider?ref=' + ref
    },
    changeServiceSearchCollapse(isOpen) {
      this.isSpServiceSearchOpen = isOpen
    },
    setCookieHeaderTypeProvider() {
      this.$store.commit('my/header/SET_COOKIE_HEADER_TYPE_PROVIDER')
    },
    setCookieHeaderTypeBuyer() {
      this.$store.commit('my/header/SET_COOKIE_HEADER_TYPE_BUYER')
    },
    clickSidebar(event) {
      // サイドバーのリンクまたは特定のクラスをクリックしたらサイドバーを閉じる
      if (event.target.nodeName === 'A' || event.target.classList.contains('js-closeSidebar')) {
        this.isActiveSidebar = false
      }
    },
    clickMenuGuideTransaction() {
      this.setFinishedTransactionScene()
    },
    toggleSearchDrawer(flag = null) {
      this.TOGGLE_SEARCH_DRAWER(flag)
      this.$util.bodyScrollPrevent(flag)
    },
    showSideBar() {
      this.setSpMenuButtonGuideScene('complete')
      this.isActiveSidebar = true
      window.addEventListener('touchmove', this.preventTouchMove, { passive: false })
    },
    onCloseSidebar() {
      window.removeEventListener('touchmove', this.preventTouchMove)
    },
    preventTouchMove(event) {
      if (event.target.classList.contains('sidebar-background')) {
        event.preventDefault()
      }
    },
    updateAppBannerHeight(isShowAppBanner) {
      if (isShowAppBanner) {
        this.$util
          .waitUntilWithLimit(() => this.$refs.appBanner, 1000)
          .then(() => {
            const appBannerHeight = this.$refs.appBanner?.$el
              ? this.$refs.appBanner.$el.clientHeight
              : 0

            this.$store.commit('ui/UPDATE_APPBANNER_HEIGHT', { height: appBannerHeight })
          })
          .catch(error => {
            this.$sentry.captureException(error)
          })
      } else {
        this.$store.commit('ui/UPDATE_APPBANNER_HEIGHT', { height: 0 })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// 高さが変わる場合は、assets/stylesheets/lib/_variables.scssの変数も修正が必要
.c-theHeaderAdjust {
  height: $header-height;

  &-business {
    height: $header-height-business;
  }

  &-businessSimple {
    height: $header-height-business-simple;
  }

  &-mzhcSimple {
    height: $header-height-mzhc-simple;
  }

  &-notFixed,
  &-notFixedPc {
    display: none;
  }

  &-logoOnlyMode {
    height: $logo-only-mode-header-height;
  }

  &-businessBanner {
    height: $header-height-business-banner;
  }
}

.c-theHeader {
  position: fixed;
  top: 0;
  z-index: z(header, main);
  width: 100%;

  &_businessBanner {
    height: $business-banner-height;
    background-color: $ds2-color-white;
  }

  &_businessBannerInner {
    @include link-effect;

    width: 100vw;
    height: 50px;
    background: linear-gradient(to right bottom, #212d94, #0a115a);
    text-align: center;
  }

  &-notFixed,
  &-notFixedPc {
    position: relative;
  }
}

.c-header {
  width: 100%;
  height: $header-height;
  background-color: $ds2-color-white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);

  .c-logo {
    min-width: 140px;
    max-width: 140px;
    width: 140px;
    height: 33px;

    ::v-deep .c-logo {
      &.-is-simple {
        display: none;
      }

      &.-is-businessShort {
        display: none;
      }
    }

    &-mzhc {
      max-width: 248px;
      width: 248px;
      height: 43px;
    }

    &_img {
      &-mzhc {
        vertical-align: middle;
      }
    }
  }

  &-business,
  &-businessSimple {
    height: $header-height-business;
    background-color: $ds2-color-business-navy-1000;
    box-shadow: 0 4px 4px rgba(56, 41, 42, 0.05);

    .c-logo {
      min-width: 267px;
      max-width: 267px;
      width: 267px;
      height: 26px;

      ::v-deep .c-logo {
        &.-is-businessShort {
          display: none;
        }
      }
    }
  }

  &-businessSimple {
    height: $header-height-business-simple;
  }

  &-mzhc {
    background-color: $ds2-color-mzhc-blue;
  }

  &-mzhcSimple {
    display: flex;
    padding: 0 6px;
    height: $header-height-mzhc-simple;
    background-color: $ds2-color-mzhc-blue;

    align-items: center;
    justify-content: flex-start;
  }

  &_inner {
    position: relative;
    display: flex;
    margin: auto;
    padding: 0 24px;
    max-width: $breakpoint-xl;
    height: 60px;

    align-items: center;

    &-business {
      height: 72px;
    }

    &-mzhc {
      padding-left: 12px;
    }
  }

  &_sidebarToggle {
    display: none;
  }

  &-logoOnlyMode {
    height: $logo-only-mode-header-height;
  }

  &_mzhcSimpleLogo {
    width: 248px;
    height: 43px;
  }
}

.c-searchForm {
  flex: 1;
  margin-left: 20px;
  max-width: 396px;

  &-business {
    margin-left: 28px;
  }
}

.c-headerInner {
  &_left {
    margin-left: auto;
  }
}

.c-headerInnerLeft {
  display: flex;

  align-items: center;

  &_searchMenu {
    @include link-effect-background;

    display: none;
    margin-left: 20px;
    color: $ds2-color-gray-600;
    transform: translateY(1px);

    &-business {
      @include link-effect-background-business;

      color: $ds2-color-gray-25;
    }
  }
}

// NOTE: サービス検索ページのみのイレギュラー対応
// ・pages/search/searchService.vueを読み込んでいるページでbodyにsearchServiceクラスが付加される
// ・サービス検索の左カラムが消えるタイミングでヘッダの検索メニューを表示
// ・SP判定時では検索メニューが重複してしまうので非表示
body.searchService {
  .c-searchForm {
    display: none;
  }

  .c-headerInnerLeft {
    &_searchMenu {
      display: flex;
    }
  }

  @media (min-width: breakpoint(Search, M) + 1px) {
    .c-searchForm {
      display: block;
    }

    .c-headerInnerLeft {
      &_searchMenu {
        display: none;
      }
    }
  }

  @media (max-width: breakpoint(Header, S)) {
    .c-headerInnerLeft {
      &_searchMenu {
        display: none;
      }
    }
  }
}

.c-subHeader {
  border-top: 1px solid $ds2-color-gray-10;
  background-color: $ds2-color-white;

  &-business {
    border-top: 0;
  }
}

.b-sidebar {
  z-index: z(modal, wrapper);

  ::v-deep .sidebar-background {
    z-index: z(modal, overlay);
    background-color: rgba(0, 0, 0, 0.7);
  }

  ::v-deep .sidebar-content {
    z-index: z(modal, wrapper);
    width: 284px;
    background-color: $ds2-color-white;
  }
}

.c-sidebarClose {
  position: fixed;
  top: 32px;
  left: 300px;
  z-index: z(modal, wrapper);
  color: $ds2-color-white;
}

.c-menuGuide {
  &-business {
    display: block;
  }
  &-pc {
    display: block;
  }
  &-tb {
    display: none;
  }
  &-sp {
    display: none;
  }
}
.c-menuButtonGuide {
  display: none;
}

@media (max-width: breakpoint(Header, M)) {
  .c-header {
    &-business,
    &-businessSimple {
      .c-logo {
        min-width: 107px;
        max-width: 107px;
        width: 107px;
        height: 28px;

        ::v-deep .c-logo {
          &.-is-business {
            display: none;
          }
          &.-is-businessShort {
            display: block;
            margin-left: -4px;
          }
        }
      }
    }
  }

  .c-theHeaderAdjust {
    &-notFixedSp {
      display: none;
    }
  }

  .c-theHeader {
    &-notFixedSp {
      display: initial;
    }
  }

  .c-menuGuide {
    &-business {
      display: block;
    }
    &-pc {
      display: none;
    }
    &-tb {
      display: block;
    }
    &-sp {
      display: none;
    }
  }
  .c-menuButtonGuide {
    display: none;
  }
}

@media (max-width: breakpoint(Header, S)) {
  .c-providerLeft,
  .c-searchForm {
    display: none;
  }

  .c-theHeaderAdjust {
    height: $header-height-sp;

    &-notFixedSp {
      display: none;
    }

    &-appBanner {
      height: $header-height-app-banner + $header-height-sp;
    }

    &-mzhcSimple {
      height: $header-height-mzhc-simple-sp;
    }

    &-fixedAppBanner,
    &-fixedHeader {
      display: block;
    }
  }

  .c-theHeader {
    &-notFixedSp {
      position: relative;
    }

    &_businessBanner {
      display: none;
    }

    &-fixedAppBanner,
    &-fixedHeader {
      position: fixed;
    }
  }

  .c-subHeader {
    display: none;
  }

  .c-header {
    height: $header-height-sp;
    border-bottom: 1px solid $ds2-color-gray-50;
    box-shadow: none;

    .c-logo {
      min-width: 116px;
      max-width: 116px;
      width: 116px;
      height: 30px;

      &-mzhc {
        max-width: 153px;
        width: 153px;
        height: 26px;
      }

      ::v-deep .c-logo {
        &.-is-full {
          display: none;
        }
        &.-is-simple {
          display: block;
        }
      }
    }

    &_inner {
      padding: 0 12px;
      height: 100%;
    }

    &_sidebarToggle {
      display: block;
      margin-right: 12px;
      color: $ds2-color-gray-600;

      &-mzhc {
        color: $ds2-color-white;
      }

      .coconala-icon {
        vertical-align: -5px;
      }
    }

    &-business,
    &-businessSimple {
      border-bottom: 0;

      .c-logo {
        min-width: 107px;
        max-width: 107px;
        width: 107px;
        height: 28px;
      }

      .c-header {
        &_sidebarToggle {
          color: $ds2-color-gray-25;
        }
      }
    }

    &-mzhcSimple {
      height: $header-height-mzhc-simple-sp;
      border: 0;
    }

    &_mzhcSimpleLogo {
      width: 204px;
      height: 36px;
    }

    &-mzhc {
      border: 0;
    }
  }

  .c-menuGuide {
    &-business {
      display: none;
    }
    &-pc {
      display: none;
    }
    &-tb {
      display: none;
    }
    &-sp {
      display: block;
    }
  }

  .c-menuButtonGuide {
    display: block;
  }
}
</style>
