import qs from 'qs'

class Coconala {
  constructor({ config, req, route, a8Id, linkEdgeId, accessTradeId }) {
    this.config = config
    this.req = req
    this.route = route
    this.a8Id = a8Id
    this.linkEdgeId = linkEdgeId
    this.accessTradeId = accessTradeId
    this.baseURL = this.config.coconala['base-url']
    this.legalBaseURL = this.config.legal['base-url']
    this.coconalaProfessionalBaseURL = this.config.coconalaProfessional['base-url']
    this.coconalaAgentBaseURL = this.config.coconalaAgent['base-url']
    this.coconalaConsulBaseURL = this.config.coconalaConsul['base-url']
    this.coconalaAssistBaseURL = this.config.coconalaAssist['base-url']
    this.coconalaJobMatchingBaseURL = this.config.coconalaJobMatching['base-url']
    this.imageURL = this.config.coconala['image-url']
    this.coconalaWebImageURL = this.config.coconalaWeb['image-url']
    this.managementAccount = this.config.coconala['management-account']
  }

  url(relativePath, params) {
    let url = `${this.baseURL}${relativePath}`
    // NOTE: A8アフィリンク経由の場合パラメータにa8=hogehogeをつける
    if (this.a8Id) {
      params = { ...params, a8: this.a8Id }
    }
    // NOTE: LinkEdgeアフィリンク経由の場合パラメータにcid=hogehogeをつける
    if (this.linkEdgeId) {
      params = { ...params, cid: this.linkEdgeId }
    }
    // NOTE: アクセストレードアフィリンク経由の場合パラメータにhoge_001を分割してatss=hogeとroot=001をつける
    if (this.accessTradeId && this.accessTradeId.match(/_00[12]$/g)) {
      const root = this.accessTradeId.match(/00[12]$/g)[0]
      const atss = this.accessTradeId.replace(/_00[12]$/g, '')
      params = { ...params, atss, root }
    }
    if (params) url = `${url}?${qs.stringify(params, { skipNulls: true })}`
    return url
  }

  absoluteUrl(relativePath) {
    return `${this.baseURL}${relativePath}`
  }

  rootPathUrl(relativePath, params) {
    return params ? `${relativePath}?${qs.stringify(params, { skipNulls: true })}` : relativePath
  }

  servicePurchaseUrl(params = {}) {
    return `${this.baseURL}/orders/apply?${qs.stringify(params)}`
  }

  serviceReservationUrl(params = {}) {
    const serviceId = params.service_id
    delete params.service_id
    return `${this.baseURL}/services/${serviceId}/reservation?${qs.stringify(params)}`
  }

  registrationURL(originalPath = null, params = {}) {
    return this.getRedirectURL('/register/top', originalPath, params)
  }

  /**
   * ログインURL取得
   * @param {string | null} originalPath
   */
  loginURL(originalPath = null) {
    return this.getRedirectURL('/login', originalPath)
  }

  businessLoginURL(originalPath = null) {
    return this.getRedirectURL('/business/login', originalPath)
  }

  fileURL(path) {
    return this.coconalaWebImageURL + path
  }

  /**
   * Akamai化されたサービス画像URLを取得
   *
   * @param {string | null | undefined} relativePath サービス画像相対パス
   * @param {number} width リサイズ後の横サイズ
   * @param {number} height リサイズ後の縦サイズ
   * @param {'resize' | 'crop'} type リサイズ方法
   */
  serviceImageURL(relativePath, width = 230, height = 190, type = 'crop') {
    if (relativePath) {
      if (this._isGifFile(relativePath)) {
        return `${this.imageURL}/resize/coconala_crop/${width}x${height}${relativePath}`
      }
      return `${process.env.config.akamaiUrl.service}/${type}/${width}/${height}${relativePath}`
    }
    return `${this.baseURL}/images/no_image_item.png`
  }

  /**
   * Akamai化されたサービス画像URLを取得
   *
   * @param {string | null | undefined} fileName サービス画像ファイル名
   * @param {number} width リサイズ後の横サイズ
   * @param {number} height リサイズ後の縦サイズ
   * @param {'resize' | 'crop'} type リサイズ方法
   */
  serviceImageURLV2(fileName, width = 230, height = 190, type = 'crop') {
    const path = fileName ? `/service_images/original/${fileName}` : null
    if (this._isGifFile(fileName)) {
      if (type === 'resize') {
        return `${this.imageURL}/box_resize/${width}x${height}/${fileName}`
      }
      return `${this.imageURL}/resize/coconala_crop/${width}x${height}/service_images/original/${fileName}`
    }
    return this.serviceImageURL(path, width, height, type)
  }

  blogCoverImageURL(fileName, width = 630, height = 354) {
    if (fileName) {
      return `${this.imageURL}/resize/blog_crop/${width}x${height}${fileName}`
    }
    return `${this.baseURL}/images/no_image_item.png`
  }

  userIconURL(relativePath) {
    if (relativePath) {
      return `${this.baseURL}${relativePath}`
    }
    return `${this.baseURL}/images/no_image_person.svg`
  }

  userIconURLV2(relativePath, width = 50, height = 50, isFromSystem = false) {
    if (isFromSystem) {
      return `${this.baseURL}/images/system_coconala_100x100.png`
    }
    if (relativePath) {
      return `${this.imageURL}/resize/coconala_profile/${width}x${height}${relativePath}`
    }
    return `${this.baseURL}/images/no_image_person.svg`
  }

  levelIconURL(level, isDefaultIcon = false) {
    if (level >= 1 && level <= 5) {
      return `${this.coconalaWebImageURL}/staticfiles/images/level/icon_${level}.svg`
    }
    if (isDefaultIcon) {
      return `${this.coconalaWebImageURL}/staticfiles/images/level/icon_0.svg`
    }
    return null
  }

  levelIconSimpleURL(level, isDefaultIcon = false) {
    if (level >= 1 && level <= 5) {
      return `${this.coconalaWebImageURL}/staticfiles/images/level/icon_s_${level}.svg`
    }
    if (isDefaultIcon) {
      return `${this.coconalaWebImageURL}/staticfiles/images/level/icon_s_0.svg`
    }
    return null
  }

  customizeURL(userId, params = {}) {
    return this.url(`/customize/requests/add/${userId}`, params)
  }

  directMessageURL(userId) {
    return this.url(`/mypage/direct_message_create/${userId}`)
  }

  helpURL(relativePath) {
    return `${this.config.coconala['help-url']}${relativePath}`
  }

  legalUrl(cnClicked, isPC = true, isBusiness = false, path = '') {
    let link = `${this.legalBaseURL}${path}?cn_device=${isPC ? 'pc' : 'sp'}&cn_clicked=${cnClicked}`

    if (isBusiness) link += '&cn_account=business'

    return link
  }

  coconalaProfessionalUrl(path = '') {
    return `${this.coconalaProfessionalBaseURL}${path}`
  }

  coconalaAgentUrl(path = '') {
    return `${this.coconalaAgentBaseURL}${path}`
  }

  coconalaConsulUrl(path = '') {
    return `${this.coconalaConsulBaseURL}${path}`
  }

  coconalaAssistUrl(path = '') {
    return `${this.coconalaAssistBaseURL}${path}`
  }

  coconalaJobMatchingUrl(path = '', query = {}) {
    const paramsText = qs.stringify(query, { skipNulls: true })
    return `${this.coconalaJobMatchingBaseURL}${path}${paramsText ? '?' : ''}${paramsText}`
  }

  /**
   * リダイレクトURLを生成
   * @param {string} to 遷移先パス
   * @param {string | null} originalPath リダイレクト先パス
   * @param {import('vue-router').Route['query']} addParams redirect_to以外のクエリパラメータ
   */
  getRedirectURL(to, originalPath = null, addParams = {}) {
    let redirectTo = originalPath
      ? `${this._getCurrentRootURL()}${originalPath}`
      : this._getCurrentURL()
    let params = { redirect_to: redirectTo }
    params = { ...params, ...addParams }
    return this.url(to, params)
  }

  getCurrentURL(relativePath, params) {
    const baseURL = this._getCurrentRootURL()
    return Object.keys(params).length
      ? `${baseURL}${relativePath}?${qs.stringify(params, { skipNulls: true })}`
      : `${baseURL}${relativePath}`
  }

  // private

  _getCurrentRootURL() {
    if (process.client) {
      return location.origin
    }
    const host = this.req.headers.host
    const protocol = host.match(/^localhost/) ? 'http' : 'https'
    return `${protocol}://${host}`
  }

  _getCurrentURL() {
    if (process.client) {
      return this._getCurrentRootURL() + location.pathname + location.search
    }
    return this._getCurrentRootURL() + this.route.fullPath
  }

  isCoconalaManagementAccount(userId) {
    return this.managementAccount.includes(userId)
  }

  _isGifFile(path) {
    return path ? path.split('.').pop().toLowerCase() === 'gif' : false
  }
}

export default Coconala
