import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'

export const state = () => ({
  privateAccountInfo: null,
  businessAccountInfo: null,
  isProvider: false
})

export const getters = {}

export const actions = {
  async fetchMyAccountInfo({ commit, rootState }, { sessionKeys = [] }) {
    const requestModule = await import('~/stub/apigateway/header/header_pb')
    const methodModule = await import('~/stub/apigateway/header/header_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.GetMyAccountInfoRequest()
    if (sessionKeys) {
      request.setSessionKeysList(sessionKeys)
    }

    const response = await grpcClient({
      method: methodModule.HeaderService.GetMyAccountInfo,
      request,
      metadata
    })
    commit('SAVE_MY_ACCOUNT_INFO', response)
  }
}

export const mutations = {
  SAVE_MY_ACCOUNT_INFO(state, data) {
    Object.assign(state, data)
  },
  SAVE_IS_PROVIDER(state, isProvider) {
    state.isProvider = isProvider
  }
}
