import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '~/grpc/grpc-client'

// initial state
export const state = () => ({
  requestDetail: {},
  additionsList: [],
  questionsList: [],
  relatedRequest: {},
  requester: {}
})

// getters
export const getters = {}

// actions
export const actions = {
  async getRequestDetail({ commit, rootState }, data) {
    const [{ GetRequestDetailRequest }, { RequestDetailService }] = await Promise.all([
      import('~/stub/apigateway/request/request_detail_pb'),
      import('~/stub/apigateway/request/request_detail_pb_service')
    ])
    if (isNaN(data.params.id) || !Number.isInteger(Number(data.params.id)))
      throw { grpcCode: grpc.Code.NotFound }
    const params = data.params
    const metadata = commonMetaData(rootState)
    metadata['user-agent'] = this.$ua._ua
    metadata['x-full-path'] = [params.fullPath]
    const request = new GetRequestDetailRequest()

    request.setId(params.id)
    request.setIsBlockIncrementCall(params.isBlockIncrementCall)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(RequestDetailService.GetRequestDetail, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    reply.detailsList.forEach(detail => {
      if (detail.contentJson) {
        detail.contentJson = JSON.parse(detail.contentJson)
      }
    })

    reply.summariesList.forEach(summary => {
      if (summary.contentJson) {
        summary.contentJson = JSON.parse(summary.contentJson)
      }
    })

    commit('RECEIVE_REQUEST', { requestDetail: reply })
  },
  async getRequestAdditionalNotes({ commit, rootState }, data) {
    const [{ GetRequestAdditionalNotesRequest }, { RequestAdditionalNoteService }] =
      await Promise.all([
        import('~/stub/apigateway/request/request_additional_note_pb'),
        import('~/stub/apigateway/request/request_additional_note_pb_service')
      ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetRequestAdditionalNotesRequest()
    request.setRequestId(params.requestId)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(RequestAdditionalNoteService.GetRequestAdditionalNotes, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    commit('RECEIVE_REQUEST', reply)
  },
  async addRequestAdditionalNote({ commit, rootState }, data) {
    const [{ AddRequestAdditionalNoteRequest }, { RequestAdditionalNoteService }] =
      await Promise.all([
        import('~/stub/apigateway/request/request_additional_note_pb'),
        import('~/stub/apigateway/request/request_additional_note_pb_service')
      ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new AddRequestAdditionalNoteRequest()
    request.setRequestId(params.requestId)
    request.setNote(params.note)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(RequestAdditionalNoteService.AddRequestAdditionalNote, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
  },
  async getQuestions({ commit, rootState }, data) {
    const [{ GetQuestionsRequest }, { RequestService }] = await Promise.all([
      import('~/stub/apigateway/request/request_pb'),
      import('~/stub/apigateway/request/request_pb_service')
    ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetQuestionsRequest()
    request.setRequestId(params.requestId)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(RequestService.GetQuestions, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    commit('RECEIVE_REQUEST', reply)
  },
  async addQuestion({ commit, rootState }, data) {
    const [{ AddQuestionRequest }, { RequestService }] = await Promise.all([
      import('~/stub/apigateway/request/request_pb'),
      import('~/stub/apigateway/request/request_pb_service')
    ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new AddQuestionRequest()
    request.setRequestId(params.requestId)
    request.setMessage(params.message)

    await grpcClient({
      method: RequestService.AddQuestion,
      request,
      metadata
    })
  },
  async addAnswer({ commit, rootState }, data) {
    const [{ AddAnswerRequest }, { RequestService }] = await Promise.all([
      import('~/stub/apigateway/request/request_pb'),
      import('~/stub/apigateway/request/request_pb_service')
    ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new AddAnswerRequest()
    request.setQuestionId(params.questionId)
    request.setMessage(params.message)

    return await grpcClient({
      method: RequestService.AddAnswer,
      request,
      metadata
    })
  },
  async searchRequests({ commit, rootState }, data) {
    const [{ SearchRequestsRequest }, { RequestService }] = await Promise.all([
      import('~/stub/apigateway/request/request_pb'),
      import('~/stub/apigateway/request/request_pb_service')
    ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new SearchRequestsRequest()
    request.setLimit(params.limit)
    request.setMasterCategoryId(params.masterCategoryId)
    request.setRecruiting(params.recruiting)
    request.setUserIconSize('default')

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(RequestService.SearchRequests, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    commit('RECEIVE_REQUEST', { relatedRequest: reply })
  },
  async getRequester({ commit, rootState }, data) {
    const [{ GetRequesterRequest }, { RequesterService }] = await Promise.all([
      import('~/stub/apigateway/user/requester_pb'),
      import('~/stub/apigateway/user/requester_pb_service')
    ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetRequesterRequest()
    request.setRequestId(params.requestId)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(RequesterService.GetRequester, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    commit('RECEIVE_REQUEST', { requester: reply })
  }
}

// mutations
export const mutations = {
  RECEIVE_REQUEST(state, data) {
    Object.assign(state, data)
  }
}
