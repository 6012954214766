import { Middleware } from '@nuxt/types'

/**
 * 末尾に/をつけたパスにリダイレクトする
 */
const endsWithSlash: Middleware = ({ route, redirect }) => {
  if (!route.path.endsWith('/')) {
    redirect(301, route.path + '/', route.query)
  }
}
export default endsWithSlash
