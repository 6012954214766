import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'
import { toInt32 } from '~/grpc/grpc-util'

export const state = () => ({
  blogOrder: null
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchBlogOrder({ commit, rootState }, blogId) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogOrderRequest }, { BlogOrderService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_order_pb'),
        import('~/stub/apigateway/profile/blog_order_pb_service')
      ])
      const request = new GetBlogOrderRequest()
      request.setBlogId(toInt32(blogId, true))
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogOrderService.GetBlogOrder, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          // 購入ブログが0件の場合はNot Foundになるためresolveする
          if (grpcCode === grpc.Code.NotFound) {
            resolve({ blogOrder: null })
          }
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOG_ORDER', response)
  },
  async executePayment({ rootState }, params) {
    const metadata = commonMetaData(rootState)
    const [{ ExecutePaymentRequest }, { BlogOrderService }] = await Promise.all([
      import('~/stub/apigateway/profile/blog_order_pb'),
      import('~/stub/apigateway/profile/blog_order_pb_service')
    ])
    const request = new ExecutePaymentRequest()
    request.setPaymentMethod(params.paymentMethod)
    request.setToken(params.token)
    request.setCardSeq(params.cardSeq)
    request.setHolderName(params.holderName)
    request.setBlogId(params.blogId)
    request.setCallbackUrl(params.callbackUrl)
    request.setDevicePrint(params.devicePrint)
    request.setIpAddress(params.ipAddress)
    request.setRoute(params.route)
    request.setBlogUserId(params.blogUserId)

    return await new Promise((resolve, reject) => {
      grpc.invoke(BlogOrderService.ExecutePayment, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message) => {
          // InvalidArgumentの時だけBEのエラーメッセージをそのまま表示する
          if (grpcCode === grpc.Code.InvalidArgument) {
            reject({ grpcCode, message })
          }
          if (grpcCode !== grpc.Code.OK) {
            // エラーメッセージはGRPC共通のものを使用する
            reject({ grpcCode, message: '' })
          }
        }
      })
    })
  },
  async completeBlogOrder({ commit, rootState }, params) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ CompleteBlogOrderRequest }, { BlogOrderService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_order_pb'),
        import('~/stub/apigateway/profile/blog_order_pb_service')
      ])
      const request = new CompleteBlogOrderRequest()
      request.setGmoOrderId(params.gmoOrderId)
      request.setResultJson(params.resultJson)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogOrderService.CompleteBlogOrder, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message) => {
          if (grpcCode !== grpc.Code.OK) {
            // ここで発生するエラーはクリティカルなためsystem-status-webに通知する
            this.$sentry.captureException({
              grpcCode,
              message,
              rpc: 'BlogOrderService.CompleteBlogOrder',
              gmoOrderId: params.gmoOrderId,
              resultJson: params.resultJson
            })
            // 基本的にここで発生するエラーは想定しなくて良く簡易的なメッセージを表示する
            reject({ grpcCode, message: 'お問い合わせください' })
          }
        }
      })
    })
    commit('RECEIVE_BLOG_ORDER', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOG_ORDER(state, data) {
    Object.assign(state, {
      ...data
    })
  }
}
