// package: common
// file: apigateway/common/file_download.proto

import * as apigateway_common_file_download_pb from "../../apigateway/common/file_download_pb";
export class FileDownloadService {
  static serviceName = "common.FileDownloadService";
}
export namespace FileDownloadService {
  export class GetDownloadUrl {
    static readonly methodName = "GetDownloadUrl";
    static readonly service = FileDownloadService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_common_file_download_pb.GetDownloadUrlRequest;
    static readonly responseType = apigateway_common_file_download_pb.GetDownloadUrlReply;
  }
}
