import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'
import { toInt32 } from '~/grpc/grpc-util'

/**
 * 公式ハッシュタグ
 *
 * ブログに下記のハッシュタグが設定されていたらSocial Share時に付加する
 */
const OFFICIAL_TAGS = ['ココナラストーリー']

// initial state
const initialState = () => ({
  blog: {},
  previousBlog: {},
  nextBlog: {},
  isPaymentViewModal: false, // 購入モーダルのページを開いているかどうか？
  isPaymentInProgress: false, // 決済入力中かどうか？（ブラウザバック・フォワードでも入力した情報を保持するためのフラグ）
  isRegisteredTaxOperator: false
})

export const state = () => initialState()

// getters
export const getters = {
  /** SNSシェアハッシュタグ取得 */
  getSocialSharingTags(state) {
    const blogTagsList = state.blog.blogTagsList?.map(t => t.name) || []
    return (hashtags = blogTagsList) => {
      // 「ココナラ」ハッシュタグは必ず含める
      const tags = ['ココナラ']
      // state.blogが空オブジェクトの可能性があるので念のためOptional Chaining
      hashtags?.forEach(t => {
        if (!tags.includes(t) && OFFICIAL_TAGS.includes(t)) {
          tags.push(t)
        }
      })
      return tags.join(',')
    }
  }
}

// actions
export const actions = {
  async fetchBlog({ commit, rootState }, { id, fullPath }) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new GetBlogRequest()
      try {
        request.setId(toInt32(id, true))
      } catch (e) {
        reject(e)
      }
      const metadata = commonMetaData(rootState)
      metadata['x-full-path'] = [fullPath]
      grpc.invoke(BlogService.GetBlog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOG', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOG(state, data) {
    Object.assign(state, {
      ...data
    })
  },
  SET_IS_PAYMENT_VIEW_MODAL(state, isPaymentViewModal) {
    state.isPaymentViewModal = isPaymentViewModal
  },
  SET_IS_PAYMENT_IN_PROGRESS(state, isPaymentInProgress) {
    state.isPaymentInProgress = isPaymentInProgress
  }
}
