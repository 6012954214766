import grpcClient from '~/grpc/grpc-client'
import { GetAnalyticsBlogRequest } from '~/stub/apigateway/analytics/blog_pb'
import { AnalyticsBlogService } from '~/stub/apigateway/analytics/blog_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// state
export const state = () => ({
  numberOfView: 0,
  numberOfMaxLineView: 0,
  numberOfPerLineView: 0,
  beginOfDate: '',
  endOfDate: '',
  targetDay: '',
  updatedAt: 0,
  numberOfTermsList: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async analyticsBlog({ commit, rootState }, { term }) {
    const request = new GetAnalyticsBlogRequest()
    request.setTargetDay(term)
    const response = await grpcClient({
      method: AnalyticsBlogService.GetAnalyticsBlog,
      request,
      metadata: commonMetaData(rootState)
    })

    commit('RECEIVE_ANALYTICS_BLOG', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_ANALYTICS_BLOG(state, data) {
    Object.assign(state, {
      ...data
    })
  }
}
