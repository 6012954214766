import 'first-input-delay'
import { onCLS, onFCP, onFID, onLCP, onTTFB } from 'web-vitals'
import { onINP } from 'web-vitals/attribution'

export default ({ store }) => {
  const sampleRate = process.env.config['web-vitals']['sample-rate']
  if (Math.random() > sampleRate) {
    return
  }

  const pushMetric = metric => {
    const maxBucketSize = 50
    let metricParam = {
      name: metric.name,
      value: metric.value,
      url: location.href
    }

    if (metric.name === 'INP') {
      metricParam.eventTarget = metric.attribution?.eventTarget || ''
      metricParam.eventType = metric.attribution?.eventType || ''
    }

    store.dispatch('web_vitals/pushMetric', metricParam)

    // navigator.sendBeacon が使用できない場合、イベントでの送信が失敗するため都度送信する
    // navigator.sendBeacon の送信可能サイズに上限があるため、一定件数溜まったら送信する
    if (!navigator.sendBeacon || store.state.web_vitals.metrics.length >= maxBucketSize) {
      sendBeacon()
    }
  }

  const sendBeacon = () => {
    const metrics = store.state.web_vitals.metrics
    if (metrics.length == 0) {
      return
    }

    const endpoint = process.env.config['web-vitals']['endpoint']
    try {
      const body = JSON.stringify(metrics)

      if (navigator.sendBeacon) {
        navigator.sendBeacon(endpoint, body)
      } else {
        fetch(endpoint, {
          body: body,
          method: 'POST',
          keepalive: true
        })
      }

      store.commit('web_vitals/CLEAR_METRIC')
    } catch (err) {
      // エラーは無視する
    }
  }

  window.addEventListener('unload', sendBeacon)

  // Chrome、Firefox以外は unload で送信できない場合がある
  if (!navigator.userAgent.match(/Chrome|Firefox/)) {
    window.addEventListener('pagehide', sendBeacon)
  }

  onCLS(pushMetric)
  onFCP(pushMetric)
  onFID(pushMetric)
  onLCP(pushMetric)
  onTTFB(pushMetric)
  onINP(pushMetric)
}
