import { grpc } from '@improbable-eng/grpc-web'

// 事前予約時のステータスを管理
const ADVANCE_RESERVATION_STATUSES = [
  'waiting_and_can_advance_reservation',
  'can_advance_reservation'
]

const toNumber = value => {
  if (typeof value === 'undefined') {
    return undefined
  }
  if (isNaN(value)) {
    throw { grpcCode: grpc.Code.InvalidArgument }
  }
  return Number(value)
}

// initial state
export const state = () => ({
  serviceDetail: {
    id: null,
    providerId: null,
    parentCategoryId: null,
    parentCategoryName: '',
    childCategoryId: null,
    childCategoryName: '',
    formatType: '',
    priceWot: null,
    unitTime: null,
    name: '',
    overview: '',
    catchphrase: '',
    head: '',
    body: '',
    deliveryTime: null,
    orderLimit: null,
    opened: null,
    openedDate: null,
    manualStoppedDate: null,
    serviceClass: null,
    stopFg: false,
    anonymityAllowFg: false,
    instantOnlyFg: false,
    negotiateFg: false,
    videoType: null,
    proFlag: false,
    customizeFlag: false,
    customizeNotice: '',
    shipmentFlag: false,
    moviePriorityFlag: false,
    fullCorrespondenceFlag: false,
    created: null,
    modified: null,
    favCount: null,
    performanceCount: null,
    serviceMoviesList: [],
    serviceImagesList: [],
    rating: {
      indicator: null,
      ratingCount: null
    },
    imageCount: null,
    orderStockCount: null,
    openedTalkroomCount: null,
    optionsList: [
      {
        id: null,
        title: '',
        priceWot: null
      }
    ],
    faqsList: [
      {
        question: '',
        answer: ''
      }
    ],
    sampleMessagesList: [
      {
        userMessage: '',
        providerMessage: ''
      }
    ],
    isRegisteredTaxOperator: false
  },
  provisionFormat: {},
  serviceStyle: {},
  specifications: [],
  buyer: {
    isFavorited: false,
    coupon: {
      isExpiring: false,
      discount: null,
      discountType: null,
      expireDate: null,
      displayName: '',
      description: ''
    }
  },
  isNotActive: false,
  pointRatio: null,
  pointBackRatio: null,
  purchaseStatus: '',
  providerPhoneStatus: ''
})

// getters
export const getters = {
  // 事前予約機能がつかえるならtrue
  canAdvanceReservation(state) {
    return ADVANCE_RESERVATION_STATUSES.includes(state.serviceDetail.purchaseStatus)
  }
}

// actions
export const actions = {
  async serviceRequests({ commit, rootState }, { params, fullPath, sprKey, detailJson }) {
    const ServiceAccessor = (await import('~/api/accessors/service/service-accessor')).default
    const apiAccessor = new ServiceAccessor(rootState)
    const serviceServiceReply = await apiAccessor.getServiceDetail(
      {
        serviceId: toNumber(params.id),
        sprKey,
        detailJson
      },
      fullPath
    )
    commit('RECEIVE_SERVICE_REQUESTS', {
      search: serviceServiceReply
    })
  },
  async providerServicesRequests({ commit, rootState }, data) {
    const toNumber = value => {
      return typeof value === 'undefined' ? value : Number(value)
    }
    const { ServiceAccessorProfile } = await import('~/api/accessors/profile/service-accessor')
    const apiAccessor = new ServiceAccessorProfile(rootState)
    return await apiAccessor.getServices({
      userId: toNumber(data.userId),
      excludeServiceId: toNumber(data.serviceId),
      userAction: 'view_service_recommend_provider'
    })
  },
  async recommendRequests({ commit, rootState }, data) {
    const { SearchAccessor } = await import('~/api/accessors/service/search-accessor')

    const apiAccessor = new SearchAccessor(rootState)
    return await apiAccessor.getRecommendServices({
      serviceId: toNumber(data.serviceId),
      algorithm: data.algorithm,
      businessFlag: data.businessFlag
    })
  },
  async addServiceReservation({ commit, rootState }, serviceId) {
    const ServiceAccessor = (await import('~/api/accessors/service/service-accessor')).default
    const apiAccessor = new ServiceAccessor(rootState)
    return await apiAccessor.addServiceReservation({ serviceId: toNumber(serviceId) })
  }
}

// mutations
export const mutations = {
  RECEIVE_SERVICE_REQUESTS(state, data) {
    Object.assign(state, data.search)
  }
}
