import { grpc } from '@improbable-eng/grpc-web'
import grpcClient from '~/grpc/grpc-client'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

const TABS = {
  BLOG: 1,
  ANNOUNCE: 2,
  ONLY_FOLLOWING: 3,
  TOP: 4
}

const SORT_TYPES = {
  RECOMMEND: 'recommend',
  NEW: 'new',
  FAVORITE: 'favorite'
}

const PER_PAGE = 60

const NUMBER_OF_CAROUSEL_CASSETTES = 12

const initialRequest = () => ({
  tab: TABS.BLOG,
  kindsList: [1, 3],
  blogCategoryIdsList: [],
  blogTagIdsList: [],
  keyword: '',
  isOnlyFollowing: false,
  isPaidPriority: false,
  sortBy: SORT_TYPES.RECOMMEND,
  page: 1,
  perPage: PER_PAGE,
  urlForLog: ''
})

// initial state
export const state = () => ({
  TABS,
  SORT_TYPES,
  PER_PAGE,
  totalNumber: 0,
  beginItemNumber: null,
  endItemNumber: null,
  blogTagsList: [],
  searchBlogsRequest: initialRequest(),
  searchBlogsList: [],
  metaTitle: '',
  metaDescription: '',
  metaKeywords: '',
  metaCanonical: '',
  heading: '',
  bread: {
    breadcrumb: []
  },
  carouselBlogsList: [],
  topMainContent: {}
})

// getters
export const getters = {
  initialRequest() {
    return initialRequest()
  }
}

// actions
export const actions = {
  async searchBlogs(
    { rootState, commit },
    {
      tab,
      kindsList,
      blogCategoryIdsList,
      blogTagIdsList,
      keyword,
      isOnlyFollowing,
      isPaidPriority,
      sortBy,
      page,
      perPage,
      urlForLog,
      isBlogsTop,
      isViaBlogTop
    }
  ) {
    const [{ SearchBlogsRequest }, { BlogService }] = await Promise.all([
      import('~/stub/apigateway/profile/blog_pb'),
      import('~/stub/apigateway/profile/blog_pb_service')
    ])
    const request = new SearchBlogsRequest()
    const metadata = commonMetaData(rootState)

    request.setTab(tab)
    request.setKindsList(kindsList)
    request.setBlogCategoryIdsList(blogCategoryIdsList)
    request.setBlogTagIdsList(blogTagIdsList)
    request.setKeyword(keyword)
    request.setIsOnlyFollowing(isOnlyFollowing)
    request.setIsPaidPriority(isPaidPriority)
    request.setSortBy(sortBy)
    request.setPage(page)
    request.setPerPage(perPage)
    request.setUrlForLog(urlForLog)
    request.setIsBlogTop(isBlogsTop)
    request.setIsViaBlogTop(isViaBlogTop)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(BlogService.SearchBlogs, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
    commit('RECEIVE_SEARCH_RESULT', reply)
  },
  async paidBlogs({ rootState, commit }, blogCategoryIdsList) {
    const setMetadata = commonMetaData(rootState)
    const [{ SearchBlogsRequest }, { BlogService }] = await Promise.all([
      import('~/stub/apigateway/profile/blog_pb'),
      import('~/stub/apigateway/profile/blog_pb_service')
    ])
    const request = new SearchBlogsRequest()
    request.setKindsList([1, 3])
    request.setSortBy(SORT_TYPES.NEW)
    request.setBlogCategoryIdsList(blogCategoryIdsList)
    request.setIsPaidPriority(true)
    request.setPage(1)
    request.setPerPage(NUMBER_OF_CAROUSEL_CASSETTES)

    const reply = await grpcClient({
      method: BlogService.SearchBlogs,
      request,
      metadata: setMetadata
    })

    commit('RECEIVE_CAROUSEL_BLOGS_LIST', reply)
  },
  async getBlogTopContent({ rootState, commit }) {
    const setMetadata = commonMetaData(rootState)
    const [{ GetBlogTopContentRequest }, { BlogTopService }] = await Promise.all([
      import('~/stub/apigateway/profile/blog_top_pb'),
      import('~/stub/apigateway/profile/blog_top_pb_service')
    ])
    const request = new GetBlogTopContentRequest()
    const reply = await grpcClient({
      method: BlogTopService.GetBlogTopContent,
      request,
      metadata: setMetadata
    })
    commit('RECEIVE_TOP_MAIN_CONETNT', reply)
  }
}

// mutations
export const mutations = {
  RECEIVE_SEARCH_RESULT(state, data) {
    state.totalNumber = data.totalNumber
    state.beginItemNumber = data.beginItemNumber
    state.endItemNumber = data.endItemNumber
    state.blogTagsList = data.blogTagsList
    state.searchBlogsRequest = data.searchBlogsRequest
    state.searchBlogsList = data.searchBlogsList
  },
  UPDATE_META_TITLE(state, data) {
    state.metaTitle = data
  },
  UPDATE_META_DESCRIPTION(state, data) {
    state.metaDescription = data
  },
  UPDATE_META_KEYWORDS(state, data) {
    state.metaKeywords = data
  },
  UPDATE_META_CANONICAL(state, data) {
    state.metaCanonical = data
  },
  UPDATE_HEADING(state, data) {
    state.heading = data
  },
  UPDATE_BREAD(state, data) {
    state.bread = data
  },
  RECEIVE_CAROUSEL_BLOGS_LIST(state, data) {
    state.carouselBlogsList = data.searchBlogsList
  },
  RECEIVE_TOP_MAIN_CONETNT(state, data) {
    state.topMainContent = data
  }
}
