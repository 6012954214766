<template>
  <OField
    v-bind="$attrs"
    v-on="$listeners"
    :class="{
      '-required': required,
      '-either-required': eitherRequired,
      '-has-error': !!errorMessage,
      '-is-small-label': labelSmall,
      '-is-no-margin': noMargin,
      'is-expanded': expanded
    }"
    :variant="errorMessage ? 'danger' : ''"
    :message="errorMessage ? errorMessage : $attrs.message"
  >
    <slot
      v-for="slotName in Object.keys($slots)"
      :name="slotName"
      :slot="slotName"
    />
  </OField>
</template>
<script>
export default {
  name: 'CField',
  props: {
    required: {
      type: Boolean,
      default: false
    },
    eitherRequired: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String
    },
    labelSmall: {
      type: Boolean
    },
    noMargin: {
      type: Boolean
    },
    expanded: {
      type: Boolean
    }
  }
}
</script>
<style lang="scss" scoped>
$space-size: 1.25em;
::v-deep {
  .label {
    font-size: 14px;
    line-height: 1;
  }
  .control {
    font-size: 100%;
  }
  .help {
    margin-bottom: -0.35em;
    line-height: 1.3;
    &.counter {
      &.is-invisible {
        visibility: visible !important;
      }
    }
  }
}
@mixin required($text) {
  white-space: nowrap;
  &::after {
    content: $text;
    color: $color-font-danger;
    font-weight: normal;
    font-size: 12px;
    margin-left: 0.2em;
  }
}
.field {
  &:not(:last-child) {
    margin-bottom: $space-size;
  }
  &.is-grouped {
    margin-bottom: 0;
    .field {
      margin-bottom: $space-size;
    }
  }
  &.-required {
    ::v-deep > .label {
      @include required('(必須)');
    }
  }
  &.-either-required {
    ::v-deep > .label {
      @include required('(いずれか必須)');
    }
  }
  &.-is-small-label {
    ::v-deep > .label {
      margin-bottom: 0.25em;
      font-size: 12px;
      font-weight: normal;
    }
  }
  &.-is-no-margin {
    margin-bottom: 0 !important;
  }
  &.-has-error {
    ::v-deep .taginput-container {
      border-color: $ds2-color-red !important;
    }
  }
}
</style>
