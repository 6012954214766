
import { defineComponent, ref, useRoute, watch } from '@nuxtjs/composition-api'
import { toTranslatedText } from '~/components/logics/translate/translate'
import { translateTargetPage } from '~/components/logics/translate/target-page'

// １つ１つの文字列に対して翻訳を行う
// パフォーマンスは悪いのでなるべくdata-translateやv-intersect-translate.onceを使うようにしてください
export default defineComponent({
  name: 'DTranslate',
  props: {
    text: {
      type: String,
      required: true
    },
    // 引数が日本語かどうかみて、日本語の場合のみ翻訳する
    onlyJapanese: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const replacedText = ref(props.text)
    const route = useRoute()
    const translate = () => {
      if (!translateTargetPage(route.value.name!)) return
      if (props.onlyJapanese && !replacedText.value.match(/[ぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠]/)) return
      toTranslatedText(replacedText.value).then(result => {
        replacedText.value = result
      })
    }

    translate()
    watch(
      () => props.text,
      () => {
        // 一旦、元の言語に戻す
        replacedText.value = props.text
        translate()
      }
    )
    return { replacedText }
  }
})
