import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '~/store/_helpers/common-helper'

const toString = value => {
  return typeof value === 'undefined' ? value : String(value)
}

export const state = () => {}

export const getters = {}

export const actions = {
  async addLogImpression({ rootState }, { action, detailJson }) {
    const [{ AddImpressionLogRequest }, { LogImpressionService }] = await Promise.all([
      import('@/stub/apigateway/big_query/log_impression_pb'),
      import('@/stub/apigateway/big_query/log_impression_pb_service')
    ])
    const metadata = commonMetaData(rootState)
    const request = new AddImpressionLogRequest()
    request.setAction(toString(action))
    request.setDetailJson(toString(detailJson))
    return grpcClient({
      method: LogImpressionService.AddImpressionLog,
      request,
      metadata
    })
  }
}

export const mutations = {}
