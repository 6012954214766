import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'
import { toInt32Value, toUInt32Value, toStringValue, toBoolValue } from '~/grpc/grpc-util'

export const state = () => ({
  projectList: [],
  projectMembers: {
    corporationName: null,
    notParticipatingUsersList: [],
    participatingUsersList: []
  },
  // プロジェクト一覧画面から遷移してきた際、遷移前の表示条件を保存しておく
  pagePathBeforeProjectDetail: '/business/projects',
  // モーダルで使用するプロジェクト一覧。参加中のプロジェクトかつ進行中のプロジェクトの一覧。
  projectsListUsedModal: []
})

export const getters = {}

export const actions = {
  async fetchProjectMembers({ rootState, commit }, params = {}) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.ListProjectMembersRequest()
    request.setProjectHashedId(toStringValue(params.projectHashedId))

    const response = await grpcClient({
      method: methodModule.BusinessProjectService.ListProjectMembers,
      request,
      metadata,
      strip: true
    })
    if (params.isCommit) {
      return commit('SAVE_PROJECT_MEMBERS', response)
    }
    return response
  },

  async fetchProjects({ commit, rootState }, params) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.ListProjectsRequest()

    params.page && request.setPage(toUInt32Value(params.page, { required: true }))
    params.perPage && request.setPerPage(toUInt32Value(params.perPage, { required: true }))
    params.sortBy && request.setSortBy(toStringValue(params.sortBy))
    params.order && request.setOrder(params.order)
    params.participationStatuses &&
      request.setParticipationStatusesList(params.participationStatuses)
    params.progressStatuses && request.setProgressStatusesList(params.progressStatuses)
    params.withoutUsers && request.setWithoutUsers(toBoolValue(params.withoutUsers))

    const response = await grpcClient({
      method: methodModule.BusinessProjectService.ListProjects,
      request,
      metadata,
      strip: true
    })
    if (params.isUsedModal) {
      commit('SAVE_PROJECTS_USED_MODAL', response)
    }
    commit('SAVE_PROJECTS', response)
    return response
  },

  async fetchParticipationRequestUser({ commit, rootState }, params) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.GetParticipationRequest()

    request.setProjectHashedId(toStringValue(params.projectHashedId))
    request.setToken(toStringValue(params.token))

    const response = await grpcClient({
      method: methodModule.BusinessProjectService.GetParticipation,
      request,
      metadata,
      strip: true
    })

    return response
  },

  async updateTalkroomBelongToProject({ rootState }, params) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.ChangeBelongProjectRequest()
    request.setProjectHashedId(toStringValue(params.projectHashedId))
    request.setTalkroomId(toInt32Value(params.talkroomId))
    request.setIsUpdateOnFirstAccess(toBoolValue(params.isUpdateOnFirstAccess))

    await grpcClient({
      method: methodModule.BusinessProjectService.ChangeBelongProject,
      request,
      metadata
    })
  },

  async updateParticipationRequest({ rootState }, params) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.UpdateParticipationRequest()
    request.setProjectHashedId(toStringValue(params.projectHashedId))
    request.setToken(toStringValue(params.token))
    request.setApproveStatus(params.approveStatus)

    await grpcClient({
      method: methodModule.BusinessProjectService.UpdateParticipation,
      request,
      metadata
    })
  },

  async updateProjectMembers({ rootState }, params) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)

    const request = new requestModule.AddProjectMembersRequest()
    request.setProjectHashedId(toStringValue(params.projectHashedId))
    request.setMemberUserIdsList(params.memberUserIds.map(toInt32Value))

    await grpcClient({
      method: methodModule.BusinessProjectService.AddProjectMembers,
      request,
      metadata
    })
  },

  async deleteProjectMember({ rootState }, params) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)

    const request = new requestModule.DeleteProjectMemberRequest()
    request.setProjectHashedId(toStringValue(params.projectHashedId))
    request.setMemberUserId(toInt32Value(params.memberUserId))

    await grpcClient({
      method: methodModule.BusinessProjectService.DeleteProjectMember,
      request,
      metadata
    })
  },

  async updateProject({ rootState }, params) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)

    const request = new requestModule.UpdateProjectRequest()
    request.setProjectHashedId(toStringValue(params.projectHashedId))
    request.setName(toStringValue(params.name))
    request.setDescription(toStringValue(params.description))
    request.setAction(params.action)

    await grpcClient({
      method: methodModule.BusinessProjectService.UpdateProject,
      request,
      metadata
    })
  },

  async sendParticipationRequestToProject({ rootState }, params) {
    const requestModule = await import('~/stub/apigateway/business/business_project_pb')
    const methodModule = await import('~/stub/apigateway/business/business_project_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.CreateParticipationRequest()
    request.setProjectHashedId(toStringValue(params.projectHashedId))

    await grpcClient({
      method: methodModule.BusinessProjectService.CreateParticipation,
      request,
      metadata
    })
  },

  // プロジェクト詳細画面から一覧画面にcsr遷移した際に遷移前のurlを入れる
  async setPagePathBeforeProjectDetail({ commit }, params) {
    commit('SAVE_PAGE_PATH_BEFORE_PROJECT_DETAIL', params)
  }
}

export const mutations = {
  SAVE_PROJECTS(state, projects) {
    state.projectList = projects.projectsList
  },
  SAVE_PROJECT_MEMBERS(state, data) {
    Object.assign(state.projectMembers, data)
  },
  SAVE_PROJECTS_USED_MODAL(state, projects) {
    state.projectsListUsedModal = projects.projectsList
  },
  SAVE_PAGE_PATH_BEFORE_PROJECT_DETAIL(state, path) {
    state.pagePathBeforeProjectDetail = path
  }
}
