// package: notification_activities
// file: apigateway/notification_activities/notification_activities.proto

import * as apigateway_notification_activities_notification_activities_pb from "../../apigateway/notification_activities/notification_activities_pb";
export class NotificationActivitiesService {
  static serviceName = "notification_activities.NotificationActivitiesService";
}
export namespace NotificationActivitiesService {
  export class GetNotifications {
    static readonly methodName = "GetNotifications";
    static readonly service = NotificationActivitiesService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_notification_activities_notification_activities_pb.GetNotificationsRequest;
    static readonly responseType = apigateway_notification_activities_notification_activities_pb.GetNotificationsReply;
  }
}
