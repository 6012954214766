<template>
  <img
    class="c-userIcon"
    :src="$coconala.userIconURL(!hasError ? filePath : null)"
    :alt="userName"
    :style="style"
    loading="lazy"
    decoding="async"
  />
</template>
<script>
export default {
  name: 'CUserIcon',
  props: {
    filePath: {
      type: String
    },
    userName: {
      type: String,
      default: 'user'
    },
    size: {
      type: Number
    }
  },
  data() {
    return {
      hasError: false
    }
  },
  mounted() {
    this.$el.onerror = () => {
      this.hasError = true
    }
  },
  computed: {
    style() {
      if (!this.size) return {}
      return { width: `${this.size}px`, height: `${this.size}px` }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-userIcon {
  max-width: 172px;
  max-height: 172px;
  width: 100%;
  height: auto;
  border-radius: 50%;
}
</style>
