import { grpc } from '@improbable-eng/grpc-web'
import {
  Int32Value,
  UInt32Value,
  StringValue,
  BoolValue
} from 'google-protobuf/google/protobuf/wrappers_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

/** Int32最大値 */
const INT32_MAX = 2147483647
/** Int32最小値 */
const INT32_MIN = -2147483648

/** UInt32最大値 */
const U_INT32_MAX = 4294967295
/** UInt32最小値 */
const U_INT32_MIN = 0

/** 型変換時の不正値エラー */
export class GrpcUtilInvalidValueError extends Error {
  readonly grpcCode: grpc.Code = grpc.Code.InvalidArgument

  constructor(message: string) {
    super(message)
    this.name = 'GrpcUtilInvalidValueError'
  }
}

/**
 * Int32変換
 * @throws {object} grpcCode: InvalidArgument(3)
 */
export const toInt32 = (value: any, required: boolean = false): number | undefined => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    if (required) {
      throw new GrpcUtilInvalidValueError('"value" must be string or number.')
    }
    return undefined
  }
  const numberValue = Number(value)
  if (isNaN(numberValue)) {
    throw new GrpcUtilInvalidValueError('"value" is NaN.')
  }
  if (numberValue < INT32_MIN || INT32_MAX < numberValue) {
    throw new GrpcUtilInvalidValueError(`"value" must be between ${INT32_MIN} and ${INT32_MAX}.`)
  }
  return numberValue
}

/**
 * UInt32変換
 * @throws {object} grpcCode: InvalidArgument(3)
 */
export const toUInt32 = (value: any, required: boolean = false): number | undefined => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    if (required) {
      throw new GrpcUtilInvalidValueError('"value" must be string or number.')
    }
    return undefined
  }
  const numberValue = Number(value)
  if (isNaN(numberValue)) {
    throw new GrpcUtilInvalidValueError('"value" is NaN.')
  }
  if (numberValue < U_INT32_MIN || U_INT32_MAX < numberValue) {
    throw new GrpcUtilInvalidValueError(
      `"value" must be between ${U_INT32_MIN} and ${U_INT32_MAX}.`
    )
  }
  return numberValue
}

/**
 * 配列の要素をInt32変換
 * @throws {object} grpcCode: InvalidArgument(3)
 */
export const toInt32Array = (values: any[]): number[] => {
  // @ts-ignore TS2322
  return values.map(x => toInt32(x)).filter(x => typeof x !== 'undefined')
}

/**
 * "toObject"後のprotobufラッパー型
 * @see {@link https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/google-protobuf/google/protobuf/wrappers_pb.d.ts}
 */
type WrappedValue = { value: string | number | boolean | Uint8Array }

/**
 * WrappedValue判定
 * valueが"value"だけをキーに持つかどうかで判定
 */
const isWrappedValue = (value: Object | WrappedValue): value is WrappedValue => {
  return value.hasOwnProperty('value') && Object.keys(value).length === 1
}

/** stripValue後の型 */
export type Stripped<T> = T extends object
  ? T extends WrappedValue
    ? T['value']
    : T extends any[]
    ? Stripped<T[number]>[]
    : { [key in keyof T]: Stripped<T[key]> }
  : T

/** stripValue関数のオーバーロード定義 */
type StripValue = {
  (value: string): string
  (value: number): number
  (value: boolean): boolean
  (value: Uint8Array): Uint8Array
  (value: WrappedValue): WrappedValue['value']
  <T extends object>(value: T): Stripped<T>
}

/** protobufのラッパー型を展開 */
export const stripValue: StripValue = value => {
  // valueがnullの場合も'object'になるのでTruthyチェックをして弾く
  if (!!value && typeof value === 'object') {
    // WrappedValue型の場合は中身のvalueを取り出す
    if (isWrappedValue(value)) {
      return value.value
    }
    if (Array.isArray(value)) {
      // Array.mapだと新しい配列を生成してしまうので上書き
      for (let i = 0; i < value.length; i++) {
        value[i] = stripValue(value[i])
      }
    } else if (typeof value === 'object') {
      Object.entries(value).forEach(([k, v]) => {
        // @ts-ignore TS2769
        value[k] = typeof v === 'object' ? stripValue(v) : v
      })
    }
    return value
  }
  // 値がFalsy or プリミティブの場合はそのまま返す
  return value
}

type toIntOption = { required: boolean }

/**
 * Int32Valueへの変換
 * @throws {object} grpcCode: InvalidArgument(3)
 */
export const toInt32Value = (
  value: any,
  option: toIntOption = { required: false }
): Int32Value | undefined => {
  const v = toInt32(value, option.required)
  if (typeof v === 'undefined') {
    return undefined
  }
  const int32Value = new Int32Value()
  int32Value.setValue(v)
  return int32Value
}

/**
 * 配列の要素をInt32Value変換
 * @throws {object} grpcCode: InvalidArgument(3)
 */
export const toInt32ValueArray = (values: number[]): Int32Value[] => {
  // @ts-ignore TS2322
  return values.map(x => toInt32Value(x))
}

/**
 * UInt32Valueへの変換
 * @throws {object} grpcCode: InvalidArgument(3)
 */
export const toUInt32Value = (
  value: any,
  option: toIntOption = { required: false }
): UInt32Value | undefined => {
  const v = toUInt32(value, option.required)
  if (typeof v === 'undefined') {
    return undefined
  }
  const uInt32Value = new UInt32Value()
  uInt32Value.setValue(v)
  return uInt32Value
}

/**
 * StringValueへの変換
 */
export const toStringValue = (value?: string): StringValue => {
  const stringValue = new StringValue()
  // @ts-ignore
  stringValue.setValue(value)
  return stringValue
}

/**
 * BoolValueへの変換
 */
export const toBoolValue = (value?: boolean): BoolValue => {
  const boolValue = new BoolValue()
  // @ts-ignore
  boolValue.setValue(value)
  return boolValue
}

/**
 * 秒からTimestampへの変換
 */
export const secondsToTimestamp = (value: number): Timestamp => {
  const timestampValue = new Timestamp()
  timestampValue.setSeconds(value)
  return timestampValue
}

export const nanosToTimestamp = (value: number): Timestamp => {
  const timestampValue = new Timestamp()
  timestampValue.setNanos(value)
  return timestampValue
}
