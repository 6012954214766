import grpcClient from '~/grpc/grpc-client'
import { GetBlogListRequest } from '~/stub/apigateway/analytics/blog_pb'
import { AnalyticsBlogService } from '~/stub/apigateway/analytics/blog_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

const PER_PAGE = 20

// state
export const state = () => ({
  PER_PAGE,
  totalNumber: 0,
  page: 1,
  blogsList: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchBlogList({ commit, rootState }, { term, page }) {
    const request = new GetBlogListRequest()
    request.setTargetDay(term)
    request.setPage(page)
    request.setPerPage(PER_PAGE)
    const response = await grpcClient({
      method: AnalyticsBlogService.GetBlogList,
      request,
      metadata: commonMetaData(rootState)
    })

    commit('RECEIVE_BLOG_LIST', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOG_LIST(state, data) {
    Object.assign(state, {
      ...data
    })
  }
}
