// source: apigateway/ordermade_request/ordermade_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ordermade_request.AddOrdermadeRequestReply', null, global);
goog.exportSymbol('proto.ordermade_request.AddOrdermadeRequestRequest', null, global);
goog.exportSymbol('proto.ordermade_request.AddProviderDeclineReply', null, global);
goog.exportSymbol('proto.ordermade_request.AddProviderDeclineRequest', null, global);
goog.exportSymbol('proto.ordermade_request.AttachmentFile', null, global);
goog.exportSymbol('proto.ordermade_request.AttachmentFileById', null, global);
goog.exportSymbol('proto.ordermade_request.DeclineReason', null, global);
goog.exportSymbol('proto.ordermade_request.EditAttachmentReply', null, global);
goog.exportSymbol('proto.ordermade_request.EditAttachmentRequest', null, global);
goog.exportSymbol('proto.ordermade_request.GetAttachmentsReply', null, global);
goog.exportSymbol('proto.ordermade_request.GetAttachmentsRequest', null, global);
goog.exportSymbol('proto.ordermade_request.GetDeclineReasonReply', null, global);
goog.exportSymbol('proto.ordermade_request.GetDeclineReasonRequest', null, global);
goog.exportSymbol('proto.ordermade_request.GetOrdermadeRequestCopyReply', null, global);
goog.exportSymbol('proto.ordermade_request.GetOrdermadeRequestCopyRequest', null, global);
goog.exportSymbol('proto.ordermade_request.GetOrdermadeRequestEditMasterReply', null, global);
goog.exportSymbol('proto.ordermade_request.GetOrdermadeRequestEditMasterRequest', null, global);
goog.exportSymbol('proto.ordermade_request.GetOrdermadeRequestReply', null, global);
goog.exportSymbol('proto.ordermade_request.GetOrdermadeRequestRequest', null, global);
goog.exportSymbol('proto.ordermade_request.MasterCategory', null, global);
goog.exportSymbol('proto.ordermade_request.MasterCategoryType', null, global);
goog.exportSymbol('proto.ordermade_request.OrdermadeRequestDetailItem', null, global);
goog.exportSymbol('proto.ordermade_request.OrdermadeRequestDetailOutline', null, global);
goog.exportSymbol('proto.ordermade_request.OrdermadeRequestItem', null, global);
goog.exportSymbol('proto.ordermade_request.Provider', null, global);
goog.exportSymbol('proto.ordermade_request.UploadedFile', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.GetOrdermadeRequestEditMasterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.displayName = 'proto.ordermade_request.GetOrdermadeRequestEditMasterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ordermade_request.GetOrdermadeRequestEditMasterReply.repeatedFields_, null);
};
goog.inherits(proto.ordermade_request.GetOrdermadeRequestEditMasterReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetOrdermadeRequestEditMasterReply.displayName = 'proto.ordermade_request.GetOrdermadeRequestEditMasterReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.AddOrdermadeRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ordermade_request.AddOrdermadeRequestRequest.repeatedFields_, null);
};
goog.inherits(proto.ordermade_request.AddOrdermadeRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.AddOrdermadeRequestRequest.displayName = 'proto.ordermade_request.AddOrdermadeRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.AddOrdermadeRequestReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.AddOrdermadeRequestReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.AddOrdermadeRequestReply.displayName = 'proto.ordermade_request.AddOrdermadeRequestReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetAttachmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.GetAttachmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetAttachmentsRequest.displayName = 'proto.ordermade_request.GetAttachmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetAttachmentsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ordermade_request.GetAttachmentsReply.repeatedFields_, null);
};
goog.inherits(proto.ordermade_request.GetAttachmentsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetAttachmentsReply.displayName = 'proto.ordermade_request.GetAttachmentsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.EditAttachmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ordermade_request.EditAttachmentRequest.repeatedFields_, null);
};
goog.inherits(proto.ordermade_request.EditAttachmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.EditAttachmentRequest.displayName = 'proto.ordermade_request.EditAttachmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.EditAttachmentReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.EditAttachmentReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.EditAttachmentReply.displayName = 'proto.ordermade_request.EditAttachmentReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetOrdermadeRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.GetOrdermadeRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetOrdermadeRequestRequest.displayName = 'proto.ordermade_request.GetOrdermadeRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetOrdermadeRequestReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ordermade_request.GetOrdermadeRequestReply.repeatedFields_, null);
};
goog.inherits(proto.ordermade_request.GetOrdermadeRequestReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetOrdermadeRequestReply.displayName = 'proto.ordermade_request.GetOrdermadeRequestReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetDeclineReasonRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.GetDeclineReasonRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetDeclineReasonRequest.displayName = 'proto.ordermade_request.GetDeclineReasonRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetDeclineReasonReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ordermade_request.GetDeclineReasonReply.repeatedFields_, null);
};
goog.inherits(proto.ordermade_request.GetDeclineReasonReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetDeclineReasonReply.displayName = 'proto.ordermade_request.GetDeclineReasonReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.AddProviderDeclineRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.AddProviderDeclineRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.AddProviderDeclineRequest.displayName = 'proto.ordermade_request.AddProviderDeclineRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.AddProviderDeclineReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.AddProviderDeclineReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.AddProviderDeclineReply.displayName = 'proto.ordermade_request.AddProviderDeclineReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.GetOrdermadeRequestCopyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetOrdermadeRequestCopyRequest.displayName = 'proto.ordermade_request.GetOrdermadeRequestCopyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ordermade_request.GetOrdermadeRequestCopyReply.repeatedFields_, null);
};
goog.inherits(proto.ordermade_request.GetOrdermadeRequestCopyReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.GetOrdermadeRequestCopyReply.displayName = 'proto.ordermade_request.GetOrdermadeRequestCopyReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.OrdermadeRequestDetailOutline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.OrdermadeRequestDetailOutline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.OrdermadeRequestDetailOutline.displayName = 'proto.ordermade_request.OrdermadeRequestDetailOutline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.OrdermadeRequestDetailItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.OrdermadeRequestDetailItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.OrdermadeRequestDetailItem.displayName = 'proto.ordermade_request.OrdermadeRequestDetailItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.Provider = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.Provider, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.Provider.displayName = 'proto.ordermade_request.Provider';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.AttachmentFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.AttachmentFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.AttachmentFile.displayName = 'proto.ordermade_request.AttachmentFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.AttachmentFileById = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.AttachmentFileById, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.AttachmentFileById.displayName = 'proto.ordermade_request.AttachmentFileById';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.UploadedFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.UploadedFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.UploadedFile.displayName = 'proto.ordermade_request.UploadedFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.MasterCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.MasterCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.MasterCategory.displayName = 'proto.ordermade_request.MasterCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.MasterCategoryType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.MasterCategoryType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.MasterCategoryType.displayName = 'proto.ordermade_request.MasterCategoryType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.DeclineReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ordermade_request.DeclineReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.DeclineReason.displayName = 'proto.ordermade_request.DeclineReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ordermade_request.OrdermadeRequestItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ordermade_request.OrdermadeRequestItem.repeatedFields_, null);
};
goog.inherits(proto.ordermade_request.OrdermadeRequestItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ordermade_request.OrdermadeRequestItem.displayName = 'proto.ordermade_request.OrdermadeRequestItem';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetOrdermadeRequestEditMasterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterRequest}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetOrdermadeRequestEditMasterRequest;
  return proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetOrdermadeRequestEditMasterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterRequest}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetOrdermadeRequestEditMasterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 provider_id = 1;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterRequest} returns this
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterRequest.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetOrdermadeRequestEditMasterReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    providerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    budgetsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    isAvailableWithholding: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isRegisteredTaxOperator: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetOrdermadeRequestEditMasterReply;
  return proto.ordermade_request.GetOrdermadeRequestEditMasterReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProviderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderName(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setBudgetsList(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAvailableWithholding(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRegisteredTaxOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetOrdermadeRequestEditMasterReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProviderName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBudgetsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getIsAvailableWithholding();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsRegisteredTaxOperator();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 provider_id = 1;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.getProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.setProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string provider_name = 2;
 * @return {string}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.getProviderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.setProviderName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 budgets = 3;
 * @return {!Array<number>}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.getBudgetsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.setBudgetsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.addBudgets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.clearBudgetsList = function() {
  return this.setBudgetsList([]);
};


/**
 * optional bool is_available_withholding = 4;
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.getIsAvailableWithholding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.setIsAvailableWithholding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_registered_tax_operator = 5;
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.getIsRegisteredTaxOperator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestEditMasterReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestEditMasterReply.prototype.setIsRegisteredTaxOperator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ordermade_request.AddOrdermadeRequestRequest.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.AddOrdermadeRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.AddOrdermadeRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AddOrdermadeRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    priceMin: jspb.Message.getFieldWithDefault(msg, 4, 0),
    priceMax: jspb.Message.getFieldWithDefault(msg, 5, 0),
    withholdingTaxFlag: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    expireDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    attachmentFilesList: jspb.Message.toObjectList(msg.getAttachmentFilesList(),
    proto.ordermade_request.AttachmentFile.toObject, includeInstance),
    origin: jspb.Message.getFieldWithDefault(msg, 9, 0),
    relatedProviderId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.AddOrdermadeRequestRequest;
  return proto.ordermade_request.AddOrdermadeRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.AddOrdermadeRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMax(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithholdingTaxFlag(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpireDate(value);
      break;
    case 8:
      var value = new proto.ordermade_request.AttachmentFile;
      reader.readMessage(value,proto.ordermade_request.AttachmentFile.deserializeBinaryFromReader);
      msg.addAttachmentFiles(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrigin(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRelatedProviderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.AddOrdermadeRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.AddOrdermadeRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AddOrdermadeRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPriceMin();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPriceMax();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWithholdingTaxFlag();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExpireDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAttachmentFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.ordermade_request.AttachmentFile.serializeBinaryToWriter
    );
  }
  f = message.getOrigin();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getRelatedProviderId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 category_id = 3;
 * @return {number}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 price_min = 4;
 * @return {number}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getPriceMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setPriceMin = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 price_max = 5;
 * @return {number}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getPriceMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setPriceMax = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool withholding_tax_flag = 6;
 * @return {boolean}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getWithholdingTaxFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setWithholdingTaxFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string expire_date = 7;
 * @return {string}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getExpireDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setExpireDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated AttachmentFile attachment_files = 8;
 * @return {!Array<!proto.ordermade_request.AttachmentFile>}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getAttachmentFilesList = function() {
  return /** @type{!Array<!proto.ordermade_request.AttachmentFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ordermade_request.AttachmentFile, 8));
};


/**
 * @param {!Array<!proto.ordermade_request.AttachmentFile>} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
*/
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setAttachmentFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.ordermade_request.AttachmentFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.AttachmentFile}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.addAttachmentFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.ordermade_request.AttachmentFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.clearAttachmentFilesList = function() {
  return this.setAttachmentFilesList([]);
};


/**
 * optional int32 origin = 9;
 * @return {number}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getOrigin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 related_provider_id = 10;
 * @return {number}
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.getRelatedProviderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.AddOrdermadeRequestRequest.prototype.setRelatedProviderId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.AddOrdermadeRequestReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.AddOrdermadeRequestReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.AddOrdermadeRequestReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AddOrdermadeRequestReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.AddOrdermadeRequestReply}
 */
proto.ordermade_request.AddOrdermadeRequestReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.AddOrdermadeRequestReply;
  return proto.ordermade_request.AddOrdermadeRequestReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.AddOrdermadeRequestReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.AddOrdermadeRequestReply}
 */
proto.ordermade_request.AddOrdermadeRequestReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.AddOrdermadeRequestReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.AddOrdermadeRequestReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.AddOrdermadeRequestReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AddOrdermadeRequestReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string redirect_url = 1;
 * @return {string}
 */
proto.ordermade_request.AddOrdermadeRequestReply.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AddOrdermadeRequestReply} returns this
 */
proto.ordermade_request.AddOrdermadeRequestReply.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetAttachmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetAttachmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetAttachmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetAttachmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetAttachmentsRequest}
 */
proto.ordermade_request.GetAttachmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetAttachmentsRequest;
  return proto.ordermade_request.GetAttachmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetAttachmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetAttachmentsRequest}
 */
proto.ordermade_request.GetAttachmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetAttachmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetAttachmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetAttachmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetAttachmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.GetAttachmentsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetAttachmentsRequest} returns this
 */
proto.ordermade_request.GetAttachmentsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ordermade_request.GetAttachmentsReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetAttachmentsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetAttachmentsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetAttachmentsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetAttachmentsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    attachmentFilesList: jspb.Message.toObjectList(msg.getAttachmentFilesList(),
    proto.ordermade_request.AttachmentFileById.toObject, includeInstance),
    uploadedFilesList: jspb.Message.toObjectList(msg.getUploadedFilesList(),
    proto.ordermade_request.UploadedFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetAttachmentsReply}
 */
proto.ordermade_request.GetAttachmentsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetAttachmentsReply;
  return proto.ordermade_request.GetAttachmentsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetAttachmentsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetAttachmentsReply}
 */
proto.ordermade_request.GetAttachmentsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ordermade_request.AttachmentFileById;
      reader.readMessage(value,proto.ordermade_request.AttachmentFileById.deserializeBinaryFromReader);
      msg.addAttachmentFiles(value);
      break;
    case 2:
      var value = new proto.ordermade_request.UploadedFile;
      reader.readMessage(value,proto.ordermade_request.UploadedFile.deserializeBinaryFromReader);
      msg.addUploadedFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetAttachmentsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetAttachmentsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetAttachmentsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetAttachmentsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttachmentFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ordermade_request.AttachmentFileById.serializeBinaryToWriter
    );
  }
  f = message.getUploadedFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ordermade_request.UploadedFile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AttachmentFileById attachment_files = 1;
 * @return {!Array<!proto.ordermade_request.AttachmentFileById>}
 */
proto.ordermade_request.GetAttachmentsReply.prototype.getAttachmentFilesList = function() {
  return /** @type{!Array<!proto.ordermade_request.AttachmentFileById>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ordermade_request.AttachmentFileById, 1));
};


/**
 * @param {!Array<!proto.ordermade_request.AttachmentFileById>} value
 * @return {!proto.ordermade_request.GetAttachmentsReply} returns this
*/
proto.ordermade_request.GetAttachmentsReply.prototype.setAttachmentFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ordermade_request.AttachmentFileById=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.AttachmentFileById}
 */
proto.ordermade_request.GetAttachmentsReply.prototype.addAttachmentFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ordermade_request.AttachmentFileById, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.GetAttachmentsReply} returns this
 */
proto.ordermade_request.GetAttachmentsReply.prototype.clearAttachmentFilesList = function() {
  return this.setAttachmentFilesList([]);
};


/**
 * repeated UploadedFile uploaded_files = 2;
 * @return {!Array<!proto.ordermade_request.UploadedFile>}
 */
proto.ordermade_request.GetAttachmentsReply.prototype.getUploadedFilesList = function() {
  return /** @type{!Array<!proto.ordermade_request.UploadedFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ordermade_request.UploadedFile, 2));
};


/**
 * @param {!Array<!proto.ordermade_request.UploadedFile>} value
 * @return {!proto.ordermade_request.GetAttachmentsReply} returns this
*/
proto.ordermade_request.GetAttachmentsReply.prototype.setUploadedFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ordermade_request.UploadedFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.UploadedFile}
 */
proto.ordermade_request.GetAttachmentsReply.prototype.addUploadedFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ordermade_request.UploadedFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.GetAttachmentsReply} returns this
 */
proto.ordermade_request.GetAttachmentsReply.prototype.clearUploadedFilesList = function() {
  return this.setUploadedFilesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ordermade_request.EditAttachmentRequest.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.EditAttachmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.EditAttachmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.EditAttachmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.EditAttachmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attachmentFilesList: jspb.Message.toObjectList(msg.getAttachmentFilesList(),
    proto.ordermade_request.AttachmentFile.toObject, includeInstance),
    deleteAttachmentFileIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    deleteUploadedFileIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.EditAttachmentRequest}
 */
proto.ordermade_request.EditAttachmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.EditAttachmentRequest;
  return proto.ordermade_request.EditAttachmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.EditAttachmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.EditAttachmentRequest}
 */
proto.ordermade_request.EditAttachmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.ordermade_request.AttachmentFile;
      reader.readMessage(value,proto.ordermade_request.AttachmentFile.deserializeBinaryFromReader);
      msg.addAttachmentFiles(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDeleteAttachmentFileIdsList(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDeleteUploadedFileIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.EditAttachmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.EditAttachmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.EditAttachmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.EditAttachmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAttachmentFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.ordermade_request.AttachmentFile.serializeBinaryToWriter
    );
  }
  f = message.getDeleteAttachmentFileIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getDeleteUploadedFileIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.EditAttachmentRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
 */
proto.ordermade_request.EditAttachmentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated AttachmentFile attachment_files = 2;
 * @return {!Array<!proto.ordermade_request.AttachmentFile>}
 */
proto.ordermade_request.EditAttachmentRequest.prototype.getAttachmentFilesList = function() {
  return /** @type{!Array<!proto.ordermade_request.AttachmentFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ordermade_request.AttachmentFile, 2));
};


/**
 * @param {!Array<!proto.ordermade_request.AttachmentFile>} value
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
*/
proto.ordermade_request.EditAttachmentRequest.prototype.setAttachmentFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.ordermade_request.AttachmentFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.AttachmentFile}
 */
proto.ordermade_request.EditAttachmentRequest.prototype.addAttachmentFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.ordermade_request.AttachmentFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
 */
proto.ordermade_request.EditAttachmentRequest.prototype.clearAttachmentFilesList = function() {
  return this.setAttachmentFilesList([]);
};


/**
 * repeated int32 delete_attachment_file_ids = 3;
 * @return {!Array<number>}
 */
proto.ordermade_request.EditAttachmentRequest.prototype.getDeleteAttachmentFileIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
 */
proto.ordermade_request.EditAttachmentRequest.prototype.setDeleteAttachmentFileIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
 */
proto.ordermade_request.EditAttachmentRequest.prototype.addDeleteAttachmentFileIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
 */
proto.ordermade_request.EditAttachmentRequest.prototype.clearDeleteAttachmentFileIdsList = function() {
  return this.setDeleteAttachmentFileIdsList([]);
};


/**
 * repeated int32 delete_uploaded_file_ids = 4;
 * @return {!Array<number>}
 */
proto.ordermade_request.EditAttachmentRequest.prototype.getDeleteUploadedFileIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
 */
proto.ordermade_request.EditAttachmentRequest.prototype.setDeleteUploadedFileIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
 */
proto.ordermade_request.EditAttachmentRequest.prototype.addDeleteUploadedFileIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.EditAttachmentRequest} returns this
 */
proto.ordermade_request.EditAttachmentRequest.prototype.clearDeleteUploadedFileIdsList = function() {
  return this.setDeleteUploadedFileIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.EditAttachmentReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.EditAttachmentReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.EditAttachmentReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.EditAttachmentReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.EditAttachmentReply}
 */
proto.ordermade_request.EditAttachmentReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.EditAttachmentReply;
  return proto.ordermade_request.EditAttachmentReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.EditAttachmentReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.EditAttachmentReply}
 */
proto.ordermade_request.EditAttachmentReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.EditAttachmentReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.EditAttachmentReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.EditAttachmentReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.EditAttachmentReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetOrdermadeRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetOrdermadeRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetOrdermadeRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetOrdermadeRequestRequest}
 */
proto.ordermade_request.GetOrdermadeRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetOrdermadeRequestRequest;
  return proto.ordermade_request.GetOrdermadeRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetOrdermadeRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetOrdermadeRequestRequest}
 */
proto.ordermade_request.GetOrdermadeRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetOrdermadeRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetOrdermadeRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetOrdermadeRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestRequest} returns this
 */
proto.ordermade_request.GetOrdermadeRequestRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ordermade_request.GetOrdermadeRequestReply.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetOrdermadeRequestReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetOrdermadeRequestReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    outline: (f = msg.getOutline()) && proto.ordermade_request.OrdermadeRequestDetailOutline.toObject(includeInstance, f),
    summariesList: jspb.Message.toObjectList(msg.getSummariesList(),
    proto.ordermade_request.OrdermadeRequestDetailItem.toObject, includeInstance),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.ordermade_request.OrdermadeRequestDetailItem.toObject, includeInstance),
    provider: (f = msg.getProvider()) && proto.ordermade_request.Provider.toObject(includeInstance, f),
    isOffered: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    directMessageUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isDeclined: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    offerId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    masterCategory: (f = msg.getMasterCategory()) && proto.ordermade_request.MasterCategory.toObject(includeInstance, f),
    parentMasterCategory: (f = msg.getParentMasterCategory()) && proto.ordermade_request.MasterCategory.toObject(includeInstance, f),
    isRegisteredTaxOperator: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply}
 */
proto.ordermade_request.GetOrdermadeRequestReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetOrdermadeRequestReply;
  return proto.ordermade_request.GetOrdermadeRequestReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetOrdermadeRequestReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply}
 */
proto.ordermade_request.GetOrdermadeRequestReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCurrentDate(value);
      break;
    case 6:
      var value = new proto.ordermade_request.OrdermadeRequestDetailOutline;
      reader.readMessage(value,proto.ordermade_request.OrdermadeRequestDetailOutline.deserializeBinaryFromReader);
      msg.setOutline(value);
      break;
    case 7:
      var value = new proto.ordermade_request.OrdermadeRequestDetailItem;
      reader.readMessage(value,proto.ordermade_request.OrdermadeRequestDetailItem.deserializeBinaryFromReader);
      msg.addSummaries(value);
      break;
    case 8:
      var value = new proto.ordermade_request.OrdermadeRequestDetailItem;
      reader.readMessage(value,proto.ordermade_request.OrdermadeRequestDetailItem.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    case 9:
      var value = new proto.ordermade_request.Provider;
      reader.readMessage(value,proto.ordermade_request.Provider.deserializeBinaryFromReader);
      msg.setProvider(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOffered(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDirectMessageUrl(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeclined(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOfferId(value);
      break;
    case 14:
      var value = new proto.ordermade_request.MasterCategory;
      reader.readMessage(value,proto.ordermade_request.MasterCategory.deserializeBinaryFromReader);
      msg.setMasterCategory(value);
      break;
    case 15:
      var value = new proto.ordermade_request.MasterCategory;
      reader.readMessage(value,proto.ordermade_request.MasterCategory.deserializeBinaryFromReader);
      msg.setParentMasterCategory(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRegisteredTaxOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetOrdermadeRequestReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetOrdermadeRequestReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOutline();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.ordermade_request.OrdermadeRequestDetailOutline.serializeBinaryToWriter
    );
  }
  f = message.getSummariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.ordermade_request.OrdermadeRequestDetailItem.serializeBinaryToWriter
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.ordermade_request.OrdermadeRequestDetailItem.serializeBinaryToWriter
    );
  }
  f = message.getProvider();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.ordermade_request.Provider.serializeBinaryToWriter
    );
  }
  f = message.getIsOffered();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDirectMessageUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsDeclined();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getOfferId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getMasterCategory();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.ordermade_request.MasterCategory.serializeBinaryToWriter
    );
  }
  f = message.getParentMasterCategory();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.ordermade_request.MasterCategory.serializeBinaryToWriter
    );
  }
  f = message.getIsRegisteredTaxOperator();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 current_date = 3;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getCurrentDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setCurrentDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional OrdermadeRequestDetailOutline outline = 6;
 * @return {?proto.ordermade_request.OrdermadeRequestDetailOutline}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getOutline = function() {
  return /** @type{?proto.ordermade_request.OrdermadeRequestDetailOutline} */ (
    jspb.Message.getWrapperField(this, proto.ordermade_request.OrdermadeRequestDetailOutline, 6));
};


/**
 * @param {?proto.ordermade_request.OrdermadeRequestDetailOutline|undefined} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
*/
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setOutline = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.clearOutline = function() {
  return this.setOutline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.hasOutline = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated OrdermadeRequestDetailItem summaries = 7;
 * @return {!Array<!proto.ordermade_request.OrdermadeRequestDetailItem>}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getSummariesList = function() {
  return /** @type{!Array<!proto.ordermade_request.OrdermadeRequestDetailItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ordermade_request.OrdermadeRequestDetailItem, 7));
};


/**
 * @param {!Array<!proto.ordermade_request.OrdermadeRequestDetailItem>} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
*/
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setSummariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.ordermade_request.OrdermadeRequestDetailItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.OrdermadeRequestDetailItem}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.addSummaries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.ordermade_request.OrdermadeRequestDetailItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.clearSummariesList = function() {
  return this.setSummariesList([]);
};


/**
 * repeated OrdermadeRequestDetailItem details = 8;
 * @return {!Array<!proto.ordermade_request.OrdermadeRequestDetailItem>}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.ordermade_request.OrdermadeRequestDetailItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ordermade_request.OrdermadeRequestDetailItem, 8));
};


/**
 * @param {!Array<!proto.ordermade_request.OrdermadeRequestDetailItem>} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
*/
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.ordermade_request.OrdermadeRequestDetailItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.OrdermadeRequestDetailItem}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.ordermade_request.OrdermadeRequestDetailItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};


/**
 * optional Provider provider = 9;
 * @return {?proto.ordermade_request.Provider}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getProvider = function() {
  return /** @type{?proto.ordermade_request.Provider} */ (
    jspb.Message.getWrapperField(this, proto.ordermade_request.Provider, 9));
};


/**
 * @param {?proto.ordermade_request.Provider|undefined} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
*/
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setProvider = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.clearProvider = function() {
  return this.setProvider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_offered = 10;
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getIsOffered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setIsOffered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string direct_message_url = 11;
 * @return {string}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getDirectMessageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setDirectMessageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_declined = 12;
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getIsDeclined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setIsDeclined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 offer_id = 13;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getOfferId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional MasterCategory master_category = 14;
 * @return {?proto.ordermade_request.MasterCategory}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getMasterCategory = function() {
  return /** @type{?proto.ordermade_request.MasterCategory} */ (
    jspb.Message.getWrapperField(this, proto.ordermade_request.MasterCategory, 14));
};


/**
 * @param {?proto.ordermade_request.MasterCategory|undefined} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
*/
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setMasterCategory = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.clearMasterCategory = function() {
  return this.setMasterCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.hasMasterCategory = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional MasterCategory parent_master_category = 15;
 * @return {?proto.ordermade_request.MasterCategory}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getParentMasterCategory = function() {
  return /** @type{?proto.ordermade_request.MasterCategory} */ (
    jspb.Message.getWrapperField(this, proto.ordermade_request.MasterCategory, 15));
};


/**
 * @param {?proto.ordermade_request.MasterCategory|undefined} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
*/
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setParentMasterCategory = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.clearParentMasterCategory = function() {
  return this.setParentMasterCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.hasParentMasterCategory = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool is_registered_tax_operator = 16;
 * @return {boolean}
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.getIsRegisteredTaxOperator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestReply.prototype.setIsRegisteredTaxOperator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetDeclineReasonRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetDeclineReasonRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetDeclineReasonRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetDeclineReasonRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetDeclineReasonRequest}
 */
proto.ordermade_request.GetDeclineReasonRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetDeclineReasonRequest;
  return proto.ordermade_request.GetDeclineReasonRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetDeclineReasonRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetDeclineReasonRequest}
 */
proto.ordermade_request.GetDeclineReasonRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetDeclineReasonRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetDeclineReasonRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetDeclineReasonRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetDeclineReasonRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ordermade_request.GetDeclineReasonReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetDeclineReasonReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetDeclineReasonReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetDeclineReasonReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetDeclineReasonReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    declineReasonsList: jspb.Message.toObjectList(msg.getDeclineReasonsList(),
    proto.ordermade_request.DeclineReason.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetDeclineReasonReply}
 */
proto.ordermade_request.GetDeclineReasonReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetDeclineReasonReply;
  return proto.ordermade_request.GetDeclineReasonReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetDeclineReasonReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetDeclineReasonReply}
 */
proto.ordermade_request.GetDeclineReasonReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ordermade_request.DeclineReason;
      reader.readMessage(value,proto.ordermade_request.DeclineReason.deserializeBinaryFromReader);
      msg.addDeclineReasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetDeclineReasonReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetDeclineReasonReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetDeclineReasonReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetDeclineReasonReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeclineReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ordermade_request.DeclineReason.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeclineReason decline_reasons = 1;
 * @return {!Array<!proto.ordermade_request.DeclineReason>}
 */
proto.ordermade_request.GetDeclineReasonReply.prototype.getDeclineReasonsList = function() {
  return /** @type{!Array<!proto.ordermade_request.DeclineReason>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ordermade_request.DeclineReason, 1));
};


/**
 * @param {!Array<!proto.ordermade_request.DeclineReason>} value
 * @return {!proto.ordermade_request.GetDeclineReasonReply} returns this
*/
proto.ordermade_request.GetDeclineReasonReply.prototype.setDeclineReasonsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ordermade_request.DeclineReason=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.DeclineReason}
 */
proto.ordermade_request.GetDeclineReasonReply.prototype.addDeclineReasons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ordermade_request.DeclineReason, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.GetDeclineReasonReply} returns this
 */
proto.ordermade_request.GetDeclineReasonReply.prototype.clearDeclineReasonsList = function() {
  return this.setDeclineReasonsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.AddProviderDeclineRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.AddProviderDeclineRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.AddProviderDeclineRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AddProviderDeclineRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    declineReasonId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    declineMessage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.AddProviderDeclineRequest}
 */
proto.ordermade_request.AddProviderDeclineRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.AddProviderDeclineRequest;
  return proto.ordermade_request.AddProviderDeclineRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.AddProviderDeclineRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.AddProviderDeclineRequest}
 */
proto.ordermade_request.AddProviderDeclineRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeclineReasonId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeclineMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.AddProviderDeclineRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.AddProviderDeclineRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.AddProviderDeclineRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AddProviderDeclineRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeclineReasonId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDeclineMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.AddProviderDeclineRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AddProviderDeclineRequest} returns this
 */
proto.ordermade_request.AddProviderDeclineRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 decline_reason_id = 2;
 * @return {number}
 */
proto.ordermade_request.AddProviderDeclineRequest.prototype.getDeclineReasonId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AddProviderDeclineRequest} returns this
 */
proto.ordermade_request.AddProviderDeclineRequest.prototype.setDeclineReasonId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string decline_message = 3;
 * @return {string}
 */
proto.ordermade_request.AddProviderDeclineRequest.prototype.getDeclineMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AddProviderDeclineRequest} returns this
 */
proto.ordermade_request.AddProviderDeclineRequest.prototype.setDeclineMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.AddProviderDeclineReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.AddProviderDeclineReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.AddProviderDeclineReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AddProviderDeclineReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isRegisteredTaxOperator: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.AddProviderDeclineReply}
 */
proto.ordermade_request.AddProviderDeclineReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.AddProviderDeclineReply;
  return proto.ordermade_request.AddProviderDeclineReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.AddProviderDeclineReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.AddProviderDeclineReply}
 */
proto.ordermade_request.AddProviderDeclineReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRegisteredTaxOperator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.AddProviderDeclineReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.AddProviderDeclineReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.AddProviderDeclineReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AddProviderDeclineReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsRegisteredTaxOperator();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string redirect_url = 1;
 * @return {string}
 */
proto.ordermade_request.AddProviderDeclineReply.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AddProviderDeclineReply} returns this
 */
proto.ordermade_request.AddProviderDeclineReply.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_registered_tax_operator = 2;
 * @return {boolean}
 */
proto.ordermade_request.AddProviderDeclineReply.prototype.getIsRegisteredTaxOperator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.AddProviderDeclineReply} returns this
 */
proto.ordermade_request.AddProviderDeclineReply.prototype.setIsRegisteredTaxOperator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetOrdermadeRequestCopyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetOrdermadeRequestCopyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyRequest}
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetOrdermadeRequestCopyRequest;
  return proto.ordermade_request.GetOrdermadeRequestCopyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetOrdermadeRequestCopyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyRequest}
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetOrdermadeRequestCopyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetOrdermadeRequestCopyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyRequest} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.repeatedFields_ = [3,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.GetOrdermadeRequestCopyReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.GetOrdermadeRequestCopyReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordermadeRequestItemsList: jspb.Message.toObjectList(msg.getOrdermadeRequestItemsList(),
    proto.ordermade_request.OrdermadeRequestItem.toObject, includeInstance),
    parentMasterCategoryId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    masterCategoryTypeId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lowestPrice: jspb.Message.getFieldWithDefault(msg, 7, 0),
    highestPrice: jspb.Message.getFieldWithDefault(msg, 8, 0),
    priceListList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.GetOrdermadeRequestCopyReply;
  return proto.ordermade_request.GetOrdermadeRequestCopyReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.GetOrdermadeRequestCopyReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.ordermade_request.OrdermadeRequestItem;
      reader.readMessage(value,proto.ordermade_request.OrdermadeRequestItem.deserializeBinaryFromReader);
      msg.addOrdermadeRequestItems(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentMasterCategoryId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryTypeId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLowestPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHighestPrice(value);
      break;
    case 9:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPriceListList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.GetOrdermadeRequestCopyReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.GetOrdermadeRequestCopyReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdermadeRequestItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ordermade_request.OrdermadeRequestItem.serializeBinaryToWriter
    );
  }
  f = message.getParentMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMasterCategoryTypeId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLowestPrice();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getHighestPrice();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPriceListList();
  if (f.length > 0) {
    writer.writePackedInt32(
      9,
      f
    );
  }
};


/**
 * repeated OrdermadeRequestItem ordermade_request_items = 3;
 * @return {!Array<!proto.ordermade_request.OrdermadeRequestItem>}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.getOrdermadeRequestItemsList = function() {
  return /** @type{!Array<!proto.ordermade_request.OrdermadeRequestItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ordermade_request.OrdermadeRequestItem, 3));
};


/**
 * @param {!Array<!proto.ordermade_request.OrdermadeRequestItem>} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
*/
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.setOrdermadeRequestItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ordermade_request.OrdermadeRequestItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.OrdermadeRequestItem}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.addOrdermadeRequestItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ordermade_request.OrdermadeRequestItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.clearOrdermadeRequestItemsList = function() {
  return this.setOrdermadeRequestItemsList([]);
};


/**
 * optional int32 parent_master_category_id = 4;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.getParentMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.setParentMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 master_category_id = 5;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 master_category_type_id = 6;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.getMasterCategoryTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.setMasterCategoryTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 lowest_price = 7;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.getLowestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.setLowestPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 highest_price = 8;
 * @return {number}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.getHighestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.setHighestPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated int32 price_list = 9;
 * @return {!Array<number>}
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.getPriceListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.setPriceListList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.addPriceList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.GetOrdermadeRequestCopyReply} returns this
 */
proto.ordermade_request.GetOrdermadeRequestCopyReply.prototype.clearPriceListList = function() {
  return this.setPriceListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.OrdermadeRequestDetailOutline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.OrdermadeRequestDetailOutline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.toObject = function(includeInstance, msg) {
  var f, obj = {
    priceMin: jspb.Message.getFieldWithDefault(msg, 1, 0),
    priceMax: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isExpired: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    expireDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    created: jspb.Message.getFieldWithDefault(msg, 5, 0),
    deliveryDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    withholdingFlag: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.OrdermadeRequestDetailOutline;
  return proto.ordermade_request.OrdermadeRequestDetailOutline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.OrdermadeRequestDetailOutline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMax(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpired(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpireDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveryDate(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithholdingFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.OrdermadeRequestDetailOutline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.OrdermadeRequestDetailOutline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriceMin();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPriceMax();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIsExpired();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExpireDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getDeliveryDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getWithholdingFlag();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 price_min = 1;
 * @return {number}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.getPriceMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.setPriceMin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 price_max = 2;
 * @return {number}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.getPriceMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.setPriceMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_expired = 3;
 * @return {boolean}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.getIsExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.setIsExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 expire_date = 4;
 * @return {number}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.getExpireDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.setExpireDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 created = 5;
 * @return {number}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 delivery_date = 6;
 * @return {number}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.getDeliveryDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.setDeliveryDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool withholding_flag = 7;
 * @return {boolean}
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.getWithholdingFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailOutline} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailOutline.prototype.setWithholdingFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.OrdermadeRequestDetailItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.OrdermadeRequestDetailItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.OrdermadeRequestDetailItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayStyle: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contentJson: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.OrdermadeRequestDetailItem}
 */
proto.ordermade_request.OrdermadeRequestDetailItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.OrdermadeRequestDetailItem;
  return proto.ordermade_request.OrdermadeRequestDetailItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.OrdermadeRequestDetailItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.OrdermadeRequestDetailItem}
 */
proto.ordermade_request.OrdermadeRequestDetailItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayStyle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.OrdermadeRequestDetailItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.OrdermadeRequestDetailItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.OrdermadeRequestDetailItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayStyle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContentJson();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string display_style = 1;
 * @return {string}
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.getDisplayStyle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailItem} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.setDisplayStyle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailItem} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailItem} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content_json = 4;
 * @return {string}
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.getContentJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.OrdermadeRequestDetailItem} returns this
 */
proto.ordermade_request.OrdermadeRequestDetailItem.prototype.setContentJson = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.Provider.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.Provider.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.Provider} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.Provider.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.Provider}
 */
proto.ordermade_request.Provider.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.Provider;
  return proto.ordermade_request.Provider.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.Provider} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.Provider}
 */
proto.ordermade_request.Provider.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.Provider.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.Provider.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.Provider} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.Provider.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.Provider.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.Provider} returns this
 */
proto.ordermade_request.Provider.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ordermade_request.Provider.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.Provider} returns this
 */
proto.ordermade_request.Provider.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.AttachmentFile.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.AttachmentFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.AttachmentFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AttachmentFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    size: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.AttachmentFile}
 */
proto.ordermade_request.AttachmentFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.AttachmentFile;
  return proto.ordermade_request.AttachmentFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.AttachmentFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.AttachmentFile}
 */
proto.ordermade_request.AttachmentFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.AttachmentFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.AttachmentFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.AttachmentFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AttachmentFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.ordermade_request.AttachmentFile.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AttachmentFile} returns this
 */
proto.ordermade_request.AttachmentFile.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_url = 2;
 * @return {string}
 */
proto.ordermade_request.AttachmentFile.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AttachmentFile} returns this
 */
proto.ordermade_request.AttachmentFile.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mime = 3;
 * @return {string}
 */
proto.ordermade_request.AttachmentFile.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AttachmentFile} returns this
 */
proto.ordermade_request.AttachmentFile.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 size = 4;
 * @return {number}
 */
proto.ordermade_request.AttachmentFile.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AttachmentFile} returns this
 */
proto.ordermade_request.AttachmentFile.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.AttachmentFileById.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.AttachmentFileById.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.AttachmentFileById} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AttachmentFileById.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fileName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    size: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.AttachmentFileById}
 */
proto.ordermade_request.AttachmentFileById.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.AttachmentFileById;
  return proto.ordermade_request.AttachmentFileById.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.AttachmentFileById} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.AttachmentFileById}
 */
proto.ordermade_request.AttachmentFileById.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.AttachmentFileById.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.AttachmentFileById.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.AttachmentFileById} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.AttachmentFileById.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.AttachmentFileById.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AttachmentFileById} returns this
 */
proto.ordermade_request.AttachmentFileById.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string file_name = 2;
 * @return {string}
 */
proto.ordermade_request.AttachmentFileById.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AttachmentFileById} returns this
 */
proto.ordermade_request.AttachmentFileById.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mime = 3;
 * @return {string}
 */
proto.ordermade_request.AttachmentFileById.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.AttachmentFileById} returns this
 */
proto.ordermade_request.AttachmentFileById.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 size = 4;
 * @return {number}
 */
proto.ordermade_request.AttachmentFileById.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.AttachmentFileById} returns this
 */
proto.ordermade_request.AttachmentFileById.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.UploadedFile.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.UploadedFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.UploadedFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.UploadedFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fileUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    size: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.UploadedFile}
 */
proto.ordermade_request.UploadedFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.UploadedFile;
  return proto.ordermade_request.UploadedFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.UploadedFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.UploadedFile}
 */
proto.ordermade_request.UploadedFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.UploadedFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.UploadedFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.UploadedFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.UploadedFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.UploadedFile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.UploadedFile} returns this
 */
proto.ordermade_request.UploadedFile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string file_url = 2;
 * @return {string}
 */
proto.ordermade_request.UploadedFile.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.UploadedFile} returns this
 */
proto.ordermade_request.UploadedFile.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string file_name = 3;
 * @return {string}
 */
proto.ordermade_request.UploadedFile.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.UploadedFile} returns this
 */
proto.ordermade_request.UploadedFile.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mime = 4;
 * @return {string}
 */
proto.ordermade_request.UploadedFile.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.UploadedFile} returns this
 */
proto.ordermade_request.UploadedFile.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 size = 5;
 * @return {number}
 */
proto.ordermade_request.UploadedFile.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.UploadedFile} returns this
 */
proto.ordermade_request.UploadedFile.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.MasterCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.MasterCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.MasterCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.MasterCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    masterCategoryType: (f = msg.getMasterCategoryType()) && proto.ordermade_request.MasterCategoryType.toObject(includeInstance, f),
    lowestPrice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    highestPrice: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.MasterCategory}
 */
proto.ordermade_request.MasterCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.MasterCategory;
  return proto.ordermade_request.MasterCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.MasterCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.MasterCategory}
 */
proto.ordermade_request.MasterCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.ordermade_request.MasterCategoryType;
      reader.readMessage(value,proto.ordermade_request.MasterCategoryType.deserializeBinaryFromReader);
      msg.setMasterCategoryType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLowestPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHighestPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.MasterCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.MasterCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.MasterCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.MasterCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterCategoryType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ordermade_request.MasterCategoryType.serializeBinaryToWriter
    );
  }
  f = message.getLowestPrice();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getHighestPrice();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.MasterCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.MasterCategory} returns this
 */
proto.ordermade_request.MasterCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ordermade_request.MasterCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.MasterCategory} returns this
 */
proto.ordermade_request.MasterCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MasterCategoryType master_category_type = 3;
 * @return {?proto.ordermade_request.MasterCategoryType}
 */
proto.ordermade_request.MasterCategory.prototype.getMasterCategoryType = function() {
  return /** @type{?proto.ordermade_request.MasterCategoryType} */ (
    jspb.Message.getWrapperField(this, proto.ordermade_request.MasterCategoryType, 3));
};


/**
 * @param {?proto.ordermade_request.MasterCategoryType|undefined} value
 * @return {!proto.ordermade_request.MasterCategory} returns this
*/
proto.ordermade_request.MasterCategory.prototype.setMasterCategoryType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ordermade_request.MasterCategory} returns this
 */
proto.ordermade_request.MasterCategory.prototype.clearMasterCategoryType = function() {
  return this.setMasterCategoryType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ordermade_request.MasterCategory.prototype.hasMasterCategoryType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 lowest_price = 4;
 * @return {number}
 */
proto.ordermade_request.MasterCategory.prototype.getLowestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.MasterCategory} returns this
 */
proto.ordermade_request.MasterCategory.prototype.setLowestPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 highest_price = 5;
 * @return {number}
 */
proto.ordermade_request.MasterCategory.prototype.getHighestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.MasterCategory} returns this
 */
proto.ordermade_request.MasterCategory.prototype.setHighestPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.MasterCategoryType.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.MasterCategoryType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.MasterCategoryType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.MasterCategoryType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.MasterCategoryType}
 */
proto.ordermade_request.MasterCategoryType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.MasterCategoryType;
  return proto.ordermade_request.MasterCategoryType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.MasterCategoryType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.MasterCategoryType}
 */
proto.ordermade_request.MasterCategoryType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.MasterCategoryType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.MasterCategoryType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.MasterCategoryType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.MasterCategoryType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.MasterCategoryType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.MasterCategoryType} returns this
 */
proto.ordermade_request.MasterCategoryType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ordermade_request.MasterCategoryType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.MasterCategoryType} returns this
 */
proto.ordermade_request.MasterCategoryType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.DeclineReason.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.DeclineReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.DeclineReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.DeclineReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    template: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.DeclineReason}
 */
proto.ordermade_request.DeclineReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.DeclineReason;
  return proto.ordermade_request.DeclineReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.DeclineReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.DeclineReason}
 */
proto.ordermade_request.DeclineReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.DeclineReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.DeclineReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.DeclineReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.DeclineReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTemplate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ordermade_request.DeclineReason.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ordermade_request.DeclineReason} returns this
 */
proto.ordermade_request.DeclineReason.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.ordermade_request.DeclineReason.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.DeclineReason} returns this
 */
proto.ordermade_request.DeclineReason.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string template = 3;
 * @return {string}
 */
proto.ordermade_request.DeclineReason.prototype.getTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.DeclineReason} returns this
 */
proto.ordermade_request.DeclineReason.prototype.setTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ordermade_request.OrdermadeRequestItem.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.toObject = function(opt_includeInstance) {
  return proto.ordermade_request.OrdermadeRequestItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ordermade_request.OrdermadeRequestItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.OrdermadeRequestItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inputText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selectedValuesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ordermade_request.OrdermadeRequestItem}
 */
proto.ordermade_request.OrdermadeRequestItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ordermade_request.OrdermadeRequestItem;
  return proto.ordermade_request.OrdermadeRequestItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ordermade_request.OrdermadeRequestItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ordermade_request.OrdermadeRequestItem}
 */
proto.ordermade_request.OrdermadeRequestItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputText(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setSelectedValuesList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ordermade_request.OrdermadeRequestItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ordermade_request.OrdermadeRequestItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ordermade_request.OrdermadeRequestItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInputText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelectedValuesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.OrdermadeRequestItem} returns this
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string input_text = 2;
 * @return {string}
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.getInputText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ordermade_request.OrdermadeRequestItem} returns this
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.setInputText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 selected_values = 3;
 * @return {!Array<number>}
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.getSelectedValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.ordermade_request.OrdermadeRequestItem} returns this
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.setSelectedValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.ordermade_request.OrdermadeRequestItem} returns this
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.addSelectedValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ordermade_request.OrdermadeRequestItem} returns this
 */
proto.ordermade_request.OrdermadeRequestItem.prototype.clearSelectedValuesList = function() {
  return this.setSelectedValuesList([]);
};


goog.object.extend(exports, proto.ordermade_request);
