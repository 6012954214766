import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
const initialState = () => ({
  blogsList: [],
  total: 0
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchBlogs({ commit, rootState }, page) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogsRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new GetBlogsRequest()
      request.setUserIdsList([rootState.auth.user.id])
      request.setIsMine(true)
      request.setPage(page)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.GetBlogs, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOGS', response)
  },
  async updateStatus({ dispatch, rootState }, data) {
    await new Promise(async (resolve, reject) => {
      const [{ UpdateBlogRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new UpdateBlogRequest()
      request.setId(data.blog.id)
      request.setUserId(rootState.auth.user.id)
      request.setBlogCategoryId(data.blog.blogCategoryId)
      request.setTitle(data.blog.title)
      request.setBody(data.blog.body)
      request.setStatus(data.status)
      request.setPaidFg(data.blog.paidFg)
      request.setPrice(data.blog.price)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.UpdateBlog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })
    await dispatch('fetchBlogs', data.page)
  },
  async deleteBlog({ dispatch, rootState }, data) {
    await new Promise(async (resolve, reject) => {
      const [{ DeleteBlogRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new DeleteBlogRequest()
      request.setId(data.blogId)
      request.setUserId(rootState.auth.user.id)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.DeleteBlog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })
    await dispatch('fetchBlogs', data.page)
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOGS(state, data) {
    Object.assign(state, data)
  }
}
