<template>
  <OCheckbox
    v-bind="$attrs"
    v-on="$listeners"
    v-model="innerValue"
    :native-value="nativeValue"
    :true-value="trueValue"
    :false-value="falseValue"
  >
    <slot
      v-for="slotName in Object.keys($slots)"
      :name="slotName"
      :slot="slotName"
    />
  </OCheckbox>
</template>
<script>
export default {
  name: 'CCheckbox',
  props: {
    value: [String, Number, Boolean, Function, Object, Array, Symbol],
    nativeValue: [String, Number],
    trueValue: {
      type: [String, Number, Boolean],
      default: undefined
    },
    falseValue: {
      type: [String, Number, Boolean],
      default: undefined
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.b-checkbox.checkbox {
  ::v-deep input[type='checkbox'] {
    border-radius: 2px;
    box-shadow: none !important;
    font-size: 10px;
    opacity: 1;
    transition: none;
    position: relative;
    border: 1px solid $ds2-color-gray-600;
    z-index: 1;
    padding: 1px;
    background-color: $ds2-color-white;
    &:checked {
      border-width: 2px;
      border-color: $ds2-color-brand-secondary;
      background: $ds2-color-brand-secondary
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat center center;
    }
    &:hover:not(:disabled) {
      border-color: $ds2-color-brand-secondary;
    }
    &:focus {
      outline: none;
    }
  }
  &.is-small {
    border-radius: 2px;
  }
  ::v-deep .control-label {
    line-height: 1.2;
  }
}
</style>
