import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '@/store/_helpers/common-helper'

// initial state
const initialState = () => ({
  userSpecialtiesList: [],
  skillsList: [],
  careersList: [],
  awardsList: [],
  experienceJobsList: []
})

// state
export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchMyCareers({ commit, rootState }) {
    const metadata = commonMetaData(rootState)
    const [
      { UserSpecialtyService },
      { GetMyUserSpecialtiesRequest },
      { SkillService },
      { GetMySkillsRequest },
      { CareerService },
      { GetMyCareersRequest },
      { AwardService },
      { GetMyAwardsRequest }
    ] = await Promise.all([
      import('~/stub/apigateway/profile/user_specialty_pb_service'),
      import('~/stub/apigateway/profile/user_specialty_pb'),
      import('~/stub/apigateway/profile/skill_pb_service'),
      import('~/stub/apigateway/profile/skill_pb'),
      import('~/stub/apigateway/profile/career_pb_service'),
      import('~/stub/apigateway/profile/career_pb'),
      import('~/stub/apigateway/profile/award_pb_service'),
      import('~/stub/apigateway/profile/award_pb')
    ])

    const grpcClientContextList = [
      {
        method: UserSpecialtyService.GetMyUserSpecialties,
        request: new GetMyUserSpecialtiesRequest(),
        metadata
      },
      {
        method: SkillService.GetMySkills,
        request: new GetMySkillsRequest(),
        metadata
      },
      {
        method: CareerService.GetMyCareers,
        request: new GetMyCareersRequest(),
        metadata
      },
      {
        method: AwardService.GetMyAwards,
        request: new GetMyAwardsRequest(),
        metadata
      }
    ]
    await Promise.all(
      grpcClientContextList.map(context => {
        return grpcClient(context).then(reply => commit('RECEIVE_CAREERS', reply))
      })
    )
  },
  async fetchSpecialties({ commit, rootState }) {
    const [{ GetMyUserSpecialtiesRequest }, { UserSpecialtyService }] = await Promise.all([
      import('~/stub/apigateway/profile/user_specialty_pb'),
      import('~/stub/apigateway/profile/user_specialty_pb_service')
    ])

    await grpcClient({
      method: UserSpecialtyService.GetMyUserSpecialties,
      request: new GetMyUserSpecialtiesRequest(),
      metadata: commonMetaData(rootState)
    }).then(reply => commit('RECEIVE_CAREERS', reply))
  },
  async createSpecialty({ dispatch, rootState }, { params }) {
    const [
      { UserSpecialtyDetailRequest, UserSpecialtyRequest },
      { UpdateUserSpecialtyRequest },
      { UserSpecialtyService }
    ] = await Promise.all([
      import('~/stub/profileservice/user_specialty_pb'),
      import('~/stub/apigateway/profile/user_specialty_pb'),
      import('~/stub/apigateway/profile/user_specialty_pb_service')
    ])

    const metadata = commonMetaData(rootState)

    const userSpecialtyDetailsRequest = params.userSpecialtyDetailsList.map(s => {
      const detailRequest = new UserSpecialtyDetailRequest()
      detailRequest.setSpecialtyName(s.specialtyName)
      detailRequest.setMinimumPrice(s.minimumPrice)
      detailRequest.setDescription(s.description)
      return detailRequest
    })

    const userSpecialtyRequest = new UserSpecialtyRequest()
    userSpecialtyRequest.setMasterCategoryId(params.masterCategoryId)
    userSpecialtyRequest.setUserSpecialtyDetailsList(userSpecialtyDetailsRequest)
    userSpecialtyRequest.setTagsList(params.tagsList)

    const request = new UpdateUserSpecialtyRequest()
    request.setUserSpecialty(userSpecialtyRequest)

    await grpcClient({
      method: UserSpecialtyService.UpdateUserSpecialty,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async updateSpecialty({ dispatch, rootState }, { params }) {
    const [
      { UserSpecialtyDetailRequest, UserSpecialtyRequest },
      { UpdateUserSpecialtyRequest },
      { UserSpecialtyService }
    ] = await Promise.all([
      import('~/stub/profileservice/user_specialty_pb'),
      import('~/stub/apigateway/profile/user_specialty_pb'),
      import('~/stub/apigateway/profile/user_specialty_pb_service')
    ])

    const metadata = commonMetaData(rootState)

    const userSpecialtyDetailsRequest = params.userSpecialtyDetailsList.map(s => {
      const detailRequest = new UserSpecialtyDetailRequest()
      detailRequest.setSpecialtyName(s.specialtyName)
      detailRequest.setMinimumPrice(s.minimumPrice)
      detailRequest.setDescription(s.description)
      return detailRequest
    })

    const userSpecialtyRequest = new UserSpecialtyRequest()
    userSpecialtyRequest.setId(params.id)
    userSpecialtyRequest.setMasterCategoryId(params.masterCategoryId)
    userSpecialtyRequest.setUserSpecialtyDetailsList(userSpecialtyDetailsRequest)
    userSpecialtyRequest.setTagsList(params.tagsList)

    const request = new UpdateUserSpecialtyRequest()
    request.setUserSpecialty(userSpecialtyRequest)

    await grpcClient({
      method: UserSpecialtyService.UpdateUserSpecialty,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async deleteSpecialty({ dispatch, rootState }, { id }) {
    const [{ DeleteUserSpecialtyRequest }, { UserSpecialtyService }] = await Promise.all([
      import('~/stub/profileservice/user_specialty_pb'),
      import('~/stub/apigateway/profile/user_specialty_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new DeleteUserSpecialtyRequest()
    request.setId(id)
    await grpcClient({
      method: UserSpecialtyService.DeleteUserSpecialty,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async fetchSkills({ commit, rootState }) {
    const [{ GetMySkillsRequest }, { SkillService }] = await Promise.all([
      import('~/stub/apigateway/profile/skill_pb'),
      import('~/stub/apigateway/profile/skill_pb_service')
    ])

    await grpcClient({
      method: SkillService.GetMySkills,
      request: new GetMySkillsRequest(),
      metadata: commonMetaData(rootState)
    }).then(reply => commit('RECEIVE_CAREERS', reply))
  },
  async createSkill({ dispatch, rootState }, { params }) {
    const [{ UpdateSkillRequest }, { SkillService }] = await Promise.all([
      import('~/stub/apigateway/profile/skill_pb'),
      import('~/stub/apigateway/profile/skill_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new UpdateSkillRequest()
    request.setName(params.name)
    request.setYearsOfExperience(params.yearsOfExperience)
    request.setSkillId(params.skillId)

    await grpcClient({
      method: SkillService.UpdateSkill,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async updateSkill({ dispatch, rootState }, { id, params }) {
    const [{ UpdateSkillRequest }, { SkillService }] = await Promise.all([
      import('~/stub/apigateway/profile/skill_pb'),
      import('~/stub/apigateway/profile/skill_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new UpdateSkillRequest()
    request.setId(id)
    request.setName(params.name)
    request.setYearsOfExperience(params.yearsOfExperience)
    request.setSkillId(params.skillId)

    await grpcClient({
      method: SkillService.UpdateSkill,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async deleteSkill({ dispatch, rootState }, { id }) {
    const [{ DeleteSkillRequest }, { SkillService }] = await Promise.all([
      import('~/stub/apigateway/profile/skill_pb'),
      import('~/stub/apigateway/profile/skill_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new DeleteSkillRequest()
    request.setId(id)
    await grpcClient({
      method: SkillService.DeleteSkill,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async fetchCareers({ commit, rootState }) {
    const [{ GetMyCareersRequest }, { CareerService }] = await Promise.all([
      import('~/stub/apigateway/profile/career_pb'),
      import('~/stub/apigateway/profile/career_pb_service')
    ])

    await grpcClient({
      method: CareerService.GetMyCareers,
      request: new GetMyCareersRequest(),
      metadata: commonMetaData(rootState)
    }).then(reply => commit('RECEIVE_CAREERS', reply))
  },
  async createCareer({ dispatch, rootState }, { params }) {
    const [{ UpdateCareerRequest }, { CareerService }] = await Promise.all([
      import('~/stub/apigateway/profile/career_pb'),
      import('~/stub/apigateway/profile/career_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new UpdateCareerRequest()
    request.setCompanyName(params.companyName)
    request.setJobTitle(params.jobTitle)
    request.setStartAt(params.startAt)
    request.setEndAt(params.endAt)
    request.setDescription(params.description)
    await grpcClient({
      method: CareerService.UpdateCareer,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async updateCareer({ dispatch, rootState }, { id, params }) {
    const [{ UpdateCareerRequest }, { CareerService }] = await Promise.all([
      import('~/stub/apigateway/profile/career_pb'),
      import('~/stub/apigateway/profile/career_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new UpdateCareerRequest()
    request.setId(id)
    request.setCompanyName(params.companyName)
    request.setJobTitle(params.jobTitle)
    request.setStartAt(params.startAt)
    request.setEndAt(params.endAt)
    request.setDescription(params.description)
    await grpcClient({
      method: CareerService.UpdateCareer,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async deleteCareer({ dispatch, rootState }, { id }) {
    const [{ DeleteCareerRequest }, { CareerService }] = await Promise.all([
      import('~/stub/apigateway/profile/career_pb'),
      import('~/stub/apigateway/profile/career_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new DeleteCareerRequest()
    request.setId(id)
    await grpcClient({
      method: CareerService.DeleteCareer,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async fetchAwards({ commit, rootState }) {
    const [{ GetMyAwardsRequest }, { AwardService }] = await Promise.all([
      import('~/stub/apigateway/profile/award_pb'),
      import('~/stub/apigateway/profile/award_pb_service')
    ])

    await grpcClient({
      method: AwardService.GetMyAwards,
      request: new GetMyAwardsRequest(),
      metadata: commonMetaData(rootState)
    }).then(reply => commit('RECEIVE_CAREERS', reply))
  },
  async createAward({ dispatch, rootState }, { params }) {
    const [{ UpdateAwardRequest }, { AwardService }] = await Promise.all([
      import('~/stub/apigateway/profile/award_pb'),
      import('~/stub/apigateway/profile/award_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new UpdateAwardRequest()
    request.setKind(params.kind)
    request.setTitle(params.title)
    request.setWinningYear(params.winningYear)
    request.setWinningMonth(params.winningMonth)
    await grpcClient({
      method: AwardService.UpdateAward,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async updateAward({ dispatch, rootState }, { id, params }) {
    const [{ UpdateAwardRequest }, { AwardService }] = await Promise.all([
      import('~/stub/apigateway/profile/award_pb'),
      import('~/stub/apigateway/profile/award_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new UpdateAwardRequest()
    request.setId(id)
    request.setKind(params.kind)
    request.setTitle(params.title)
    request.setWinningYear(params.winningYear)
    request.setWinningMonth(params.winningMonth)
    await grpcClient({
      method: AwardService.UpdateAward,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  },
  async deleteAward({ dispatch, rootState }, { id }) {
    const [{ DeleteAwardRequest }, { AwardService }] = await Promise.all([
      import('~/stub/apigateway/profile/award_pb'),
      import('~/stub/apigateway/profile/award_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new DeleteAwardRequest()
    request.setId(id)
    await grpcClient({
      method: AwardService.DeleteAward,
      request,
      metadata
    })
    await dispatch('fetchMyCareers')
  }
}

// mutations
export const mutations = {
  RECEIVE_CAREERS(state, data) {
    Object.assign(state, data)
  }
}
