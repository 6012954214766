let deepFreeze = require('deep-freeze')

export const state = () =>
  deepFreeze({
    serviceClass: {
      text: 0,
      phone: 1,
      offer: 2
    },
    serviceLayout: {
      list: 1,
      block: 2
    },
    CUSTOMIZE_ORIGINS: {
      PROFILE: 1,
      SPECIALTY: 2,
      PRICE: 3,
      PORTFOLIO: 4
    },
    HEADER_HEIGHT: {
      DEFAULT: 100,
      DEFAULT_SP: 47
    },
    MEDIA_TYPES: {
      IMAGE: 1,
      VIDEO: 2
    },
    USER_STATUSES: {
      AVAILABLE: 1,
      DPENDS_ON: 2,
      BUSY: 3
    },
    VIRTUAL_CATEGORY: {
      DIVINATION_PHONE: {
        ID: 10001,
        NAME: '電話占い',
        ORIGIN_NAME: '占い',
        CONDITION: {
          CATEGORY_ID: 3,
          SERVICE_CLASS: 1,
          SERVICE_KIND: 1
        }
      },
      DIVINATION_TEXT: {
        ID: 10002,
        NAME: 'メッセージ・チャット占い',
        ORIGIN_NAME: '占い',
        CONDITION: {
          CATEGORY_ID: 3,
          SERVICE_CLASS: 0,
          SERVICE_KIND: 0
        }
      }
    },
    LEGAL_URL: 'https://legal.coconala.com',
    CRITEO_PHONE_PRICE_AVG: 4951,
    // 公表KPI(2022年8月時点)
    PUBLISHED_VALUE: {
      ACHIEVEMENTS: '420万',
      // カテゴリー数
      CATEGORIES: '450',
      // 出品サービス数
      SERVICES: '70万',
      // 会員登録数
      USERS: '400万',
      // コナラビジネス登録社数
      BUSINESS_USERS: '20万'
    },
    PROVIDER_TYPE: {
      NONE: 1,
      SOLO: 2,
      CORPORATION: 3
    },
    HEADER_TYPE: {
      SIMPLE: 'simple',
      BEFORE_LOGIN: 'beforeLogin',
      PROVIDER: 'provider',
      BUYER: 'buyer',
      BUSINESS_SIMPLE: 'businessSimple',
      BUSINESS_BEFORE_LOGIN: 'businessBeforeLogin',
      BUSINESS: 'business',
      MZHC: 'mzhc',
      MZHC_SIMPLE: 'mzhc_simple'
    },
    HEADER_TYPE_SIMPLE_PAGES: ['services-id-reservation', 'questionnaire-user_interest_categories'], // 完全一致
    HEADER_TYPE_MZHC_SIMPLE_PAGES: [
      'register-identity_in_progress_mzhc',
      'register-top_mzhc',
      'mizuho_coconala-company_profile',
      'pages-tokushou_mzhc',
      'pages-consent_form_mzhc'
    ],
    HEADER_TYPE_PROVIDER_ONLY_PAGES: [
      'mypage-analytics',
      'mypage-blogs-add',
      'mypage-dashboard_provider',
      'mypage-received_favorites',
      'mypage-received_favorites_blogs',
      'mypage-received_favorites_users',
      'mypage-promotion',
      'mypage-received_orders-required',
      'mypage-received_orders-requests',
      'mypage-received_orders-open',
      'mypage-received_orders-closed',
      'mypage-received_orders-canceled',
      'mypage-received_orders-trash',
      'mypage-received_orders-flags',
      'mypage-received_orders-user-userId',
      'mypage-provider_coupons'
    ], // 完全一致
    HEADER_TYPE_BUYER_ONLY_PAGES: [
      'customize-requests-add-userId',
      'customize-requests-add-userId-serviceId',
      'mypage-blog_orders',
      'mypage-dashboard',
      'mypage-favorites-blogs',
      'requests-add',
      'mypage-orders-required',
      'mypage-orders-requests',
      'mypage-orders-open',
      'mypage-orders-closed',
      'mypage-orders-canceled',
      'mypage-orders-trash',
      'mypage-orders-flags'
    ], // 完全一致
    // ブログの記事とサービス詳細でリンク化を許可するURLとして使っています。
    // 下記にURLパターンを追加するとブログ記事内とサービス詳細内でURLがハイパーリンク化されるので、
    // 追加する場合はブログ・サービス詳細の方に影響が出ないように注意してください。
    // TODO: プロフィール移行時に、本番定義PROFILE_URL、PROFILE_SEARCH_URLの変更が必要。開発定義は既に移行後の定義を反映済み。
    COCONALA_WHITE_LIST_URL:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging'
        ? {
            MAIN_WIDTH_BLOG_ID_URL: 'https?://(dev[0-9]*\\.)?coconala\\.com/blogs/[0-9]+/[0-9]+',
            MAIN_WIDTH_ID_URL:
              'https?://(dev[0-9]*\\.)coconala\\.com/(curations|services|blogs|categories|requests|news)/[0-9]+',
            OLD_PROFILE_URL:
              'https?://profile(-dev)?\\.coconala\\.com/users/[0-9]+(/(ratings|careers|portfolios(/[0-9]+)*|prices|services))*',
            PROFILE_URL:
              'https?://(dev[0-9]*\\.)?coconala\\.com/users/[0-9]+(/(reviews|careers|portfolios(/[0-9]+)*|prices|services))*',
            MAIN_INFO_URL:
              'https?://((dev[0-9]*\\.)?coconala\\.com/)pages/[-_.!~*()a-zA-Z0-9;/:@&=+$,%#]*',
            MAIN_SEARCH_URL:
              'https?://(dev[0-9]*\\.)?coconala\\.com/(search|requests|blogs(/search|/categories/[0-9]+)*)',
            OLD_PROFILE_SEARCH_URL: 'https?://profile(-dev)?\\.coconala\\.com/search',
            PROFILE_SEARCH_URL: 'https?://(dev[0-9]*\\.)?coconala\\.com/(users/search|users/?)*',
            MAIN_URL: 'https?://(dev[0-9]*\\.)?coconala\\.com/?'
          }
        : {
            MAIN_WIDTH_BLOG_ID_URL: 'https?://coconala\\.com/blogs/[0-9]+/[0-9]+',
            MAIN_WIDTH_ID_URL:
              'https?://coconala\\.com/(curations|services|blogs|categories|requests|news)/[0-9]+',
            OLD_PROFILE_URL:
              'https?://profile\\.coconala\\.com/users/[0-9]+(/(ratings|careers|portfolios(/[0-9]+)*|prices|services))*',
            PROFILE_URL:
              'https?://coconala\\.com/users/[0-9]+(/(reviews|careers|portfolios(/[0-9]+)*|prices|services))*',
            MAIN_INFO_URL: 'https?://(coconala\\.com/)pages/[-_.!~*()a-zA-Z0-9;/:@&=+$,%#]*',
            MAIN_SEARCH_URL:
              'https?://coconala\\.com/(search|requests|blogs(/search|/categories/[0-9]+)*)',
            OLD_PROFILE_SEARCH_URL: 'https?://profile\\.coconala\\.com/search',
            PROFILE_SEARCH_URL: 'https?://coconala\\.com/(users/search|users/?)*',
            MAIN_URL: 'https?://coconala\\.com/?'
          },

    /**
     * 取引通知のトリガータイプ(呼び出し元によって指定する)
     * 1:支払い
     * 2:遷移先閲覧
     * 3:返信
     * 4:トークルームクローズ
     * 5:トークルームキャンセル
     * 6:選択
     * 7:評価
     * 8:検収
     * 9:正式な納品
     * 10:電話
     * 11:DM返信
     * 12:提案
     * 13:辞退
     * 14:募集終了
     */
    TRANSACTION_TRIGGER_TYPE: {
      PAYMENT: 1,
      BROWSING: 2,
      REPLY: 3,
      TALK_ROOM_CLOSED: 4,
      TALK_ROOM_CANCEL: 5,
      SELECT: 6,
      EVALUATION: 7,
      ACCEPTANCE: 8,
      ANSWER: 9,
      PHONE_CALL: 10,
      DM_REPLY: 11,
      PROPOSAL: 12,
      DECLINE: 13,
      RECRUITMENT_END: 14
    },
    COCONALA_COMPANY_INFO: {
      name: '株式会社ココナラ',
      postalCode: '150-0031',
      address: '東京都渋谷区桜丘町20−1 渋谷インフォスタワー6F',
      invoiceNumber: 'T9010701026061'
    },
    MZHC_COMPANY_INFO: {
      name: '株式会社みずほココナラ',
      nameEn: 'Mizuho coconala, Inc.',
      postalCode: '150-0031',
      address: '東京都渋谷区桜丘町20−1 渋谷インフォスタワー6F',
      invoiceNumber: 'T1011001159765'
    },
    // ココナラのカテゴリ数
    COCONALA_CATEGORY_KINDS_COUNT: 450,
    ALLIANCE_TYPE: {
      COCONALA: 0,
      MZHC: 1
    }
  })

export const getters = {
  userStatuses(state) {
    return [
      { value: 1, text: '対応可能です' },
      { value: 2, text: '内容によります' },
      { value: 3, text: '忙しいです' }
    ]
  }
}
