import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({
  declineReasons: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async getDeclineReasonRequest({ commit, rootState }) {
    const [{ GetDeclineReasonRequest }, { OrdermadeRequestService }] = await Promise.all([
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb'),
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb_service')
    ])

    const metadata = commonMetaData(rootState)
    const request = new GetDeclineReasonRequest()
    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(OrdermadeRequestService.GetDeclineReason, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    commit('RECEIVE_DECLINE_REASON_REQUEST', {
      declineReasons: reply.declineReasonsList
    })
  },

  async addProviderDeclineRequest({ commit, rootState }, param) {
    const [{ AddProviderDeclineRequest }, { OrdermadeRequestService }] = await Promise.all([
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb'),
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb_service')
    ])

    return new Promise((resolve, reject) => {
      const metadata = commonMetaData(rootState)
      const request = new AddProviderDeclineRequest()
      request.setId(param.id)
      request.setDeclineReasonId(param.declineReasonId)
      request.setDeclineMessage(param.declineReasonMessage)

      grpc.invoke(OrdermadeRequestService.AddProviderDecline, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_DECLINE_REASON_REQUEST(state, data) {
    Object.assign(state, data)
  }
}
