import { ValueOf } from '../types/util'

export const ERROR_STATUS_UNAUTHENTICATED = 1
export const ERROR_STATUS_FAIL_OPEN_ID_REGISTER = 2
export const IS_BACK_STATUS = 1

export type RegisterStepItem = {
  text: string
  iconImageName: string
  path: string[]
  maxProgress?: number
}

export const REGISTER_TYPE = {
  EMAIL: 'Email',
  GOOGLE: 'Google',
  APPLE: 'Apple',
  FACEBOOK: 'Facebook',
  YAHOO: 'YahooJapan'
} as const
export type RegisterType = ValueOf<typeof REGISTER_TYPE>

export const REGISTER_URL_TYPE = {
  BUYER_INDIVIDUAL: 'BuyerIndividual',
  BUYER_CORPORATE: 'BuyerCorporate',
  PROVIDER_INDIVIDUAL: 'ProviderIndividual',
  PROVIDER_CORPORATE: 'ProviderCorporate'
} as const

export const STEP_ITEM: { [key in ValueOf<typeof REGISTER_URL_TYPE>]: RegisterStepItem[] } = {
  [REGISTER_URL_TYPE.BUYER_INDIVIDUAL]: [
    {
      text: '基本情報',
      iconImageName: 'icon_person.svg',
      path: ['/register/buyer/individual']
    },
    {
      text: '興味のあるカテゴリ',
      iconImageName: 'icon_genre.svg',
      path: ['/register/buyer/individual/user_interest_categories']
    },
    {
      text: '完了',
      iconImageName: 'icon_check.svg',
      path: ['/register/buyer/individual/user_name']
    }
  ],
  [REGISTER_URL_TYPE.BUYER_CORPORATE]: [
    {
      text: '基本情報',
      iconImageName: 'icon_person.svg',
      path: ['/register/buyer/corporate']
    },
    {
      text: '会社情報',
      iconImageName: 'icon_bill.svg',
      path: ['/register/buyer/corporate/corporate']
    },
    {
      text: '発注情報',
      iconImageName: 'icon_genre.svg',
      path: [
        '/register/buyer/corporate/user_interest_categories',
        '/register/buyer/corporate/buyer_type',
        '/register/buyer/corporate/buyer_plan'
      ],
      maxProgress: 3
    },
    {
      text: '完了',
      iconImageName: 'icon_check.svg',
      path: ['/register/buyer/corporate/user_name']
    }
  ],
  [REGISTER_URL_TYPE.PROVIDER_INDIVIDUAL]: [
    {
      text: '基本情報',
      iconImageName: 'icon_person.svg',
      path: ['/register/provider/individual', '/register/provider/individual/address']
    },
    {
      text: '職種',
      iconImageName: 'icon_genre.svg',
      path: ['/register/provider/individual/jobs']
    },
    {
      text: '働き方',
      iconImageName: 'icon_working_style.svg',
      path: ['/register/provider/individual/provider_type']
    },
    {
      text: '完了',
      iconImageName: 'icon_check.svg',
      path: ['/register/provider/individual/user_name']
    }
  ],
  [REGISTER_URL_TYPE.PROVIDER_CORPORATE]: [
    {
      text: '基本情報',
      iconImageName: 'icon_person.svg',
      path: ['/register/provider/corporate']
    },
    {
      text: '会社情報',
      iconImageName: 'icon_bill.svg',
      path: ['/register/provider/corporate/corporate']
    },
    {
      text: '職種',
      iconImageName: 'icon_genre.svg',
      path: ['/register/provider/corporate/jobs']
    },
    {
      text: '働き方',
      iconImageName: 'icon_working_style.svg',
      path: ['/register/provider/corporate/provider_type']
    },
    {
      text: '完了',
      iconImageName: 'icon_check.svg',
      path: ['/register/provider/corporate/user_name']
    }
  ]
}
