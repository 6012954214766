// package: profile
// file: apigateway/profile/portfolio.proto

import * as apigateway_profile_portfolio_pb from "../../apigateway/profile/portfolio_pb";
import * as profileservice_portfolio_pb from "../../profileservice/portfolio_pb";
export class PortfolioService {
  static serviceName = "profile.PortfolioService";
}
export namespace PortfolioService {
  export class GetPortfolio {
    static readonly methodName = "GetPortfolio";
    static readonly service = PortfolioService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_portfolio_pb.GetPortfolioRequest;
    static readonly responseType = apigateway_profile_portfolio_pb.PortfolioReply;
  }
  export class GetMyPortfolio {
    static readonly methodName = "GetMyPortfolio";
    static readonly service = PortfolioService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_portfolio_pb.GetMyPortfolioRequest;
    static readonly responseType = apigateway_profile_portfolio_pb.PortfolioReply;
  }
  export class GetPortfolios {
    static readonly methodName = "GetPortfolios";
    static readonly service = PortfolioService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_portfolio_pb.GetPortfoliosRequest;
    static readonly responseType = apigateway_profile_portfolio_pb.PortfoliosReply;
  }
  export class GetMyPortfolios {
    static readonly methodName = "GetMyPortfolios";
    static readonly service = PortfolioService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_portfolio_pb.GetMyPortfoliosRequest;
    static readonly responseType = apigateway_profile_portfolio_pb.PortfoliosReply;
  }
  export class UpdatePortfolio {
    static readonly methodName = "UpdatePortfolio";
    static readonly service = PortfolioService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_portfolio_pb.UpdatePortfolioRequest;
    static readonly responseType = apigateway_profile_portfolio_pb.PortfolioReply;
  }
  export class DeletePortfolio {
    static readonly methodName = "DeletePortfolio";
    static readonly service = PortfolioService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_portfolio_pb.DeletePortfolioRequest;
    static readonly responseType = apigateway_profile_portfolio_pb.DeletePortfolioReply;
  }
}
