import Util from '~/assets/javascripts/util'

export const A8_COOKIE_KEY = 'a8_affiliate_id'
export const LINK_EDGE_COOKIE_KEY = 'link_edge_affiliate_id'
export const ACCESS_TRADE_COOKIE_KEY = 'access_trade_affiliate_id'
// NOTE: 'CL_{広告ID}'なので別広告を利用する場合は別途設定が必要
export const AFFILI_CODE_COOKIE_KEY = 'CL_ph4r84go87io'
const ACCESS_TRADE_ROOT_VAL = ['001', '002']

const affiliateCookieSet = (cookie_key, cookie_val, ctx) => {
  if (cookie_val) {
    const host = ctx.req.headers.host
    ctx.app.$cookies.set(cookie_key, cookie_val, {
      path: '/',
      maxAge: 60 * 60 * 24 * 90,
      secure: !!host.match(/coconala.com$/),
      domain: '.coconala.com'
    })
  }
}

const extractTheLast = query_value => {
  if (Array.isArray(query_value)) {
    // 複数パラメータが付与されている場合は一番最後に付与されたものを使用する
    return query_value[query_value.length - 1]
  }
  // 複数パラメータではない場合はそのまま返す
  return query_value
}

export default context => {
  // A8
  affiliateCookieSet(A8_COOKIE_KEY, context.query && extractTheLast(context.query['a8']), context)
  // LinkEdge
  affiliateCookieSet(
    LINK_EDGE_COOKIE_KEY,
    context.query && extractTheLast(context.query['cid']),
    context
  )
  // AccessTrade
  const root_value = extractTheLast(context.query['root'])
  // NOTE: atssとrootのパラメータを結合してcookie保存する
  const valid_atss_value =
    context.query &&
    ACCESS_TRADE_ROOT_VAL.includes(root_value) &&
    Util.excludeInvalidAffiliateValue(extractTheLast(context.query['atss']))

  affiliateCookieSet(
    ACCESS_TRADE_COOKIE_KEY,
    valid_atss_value && `${valid_atss_value}_${root_value}`,
    context
  )

  // AffiliCode
  // NOTE: アフィリコードはGTMで着地点タグを設定してCookieを生成している
}
