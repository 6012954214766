import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'
import {
  prefectureTextGetter,
  sexTextGetter,
  corporateFgTextGetterForProfile,
  initialUserState,
  initialProviderSummaryOptions
} from '~/store/_helpers/user-helper'

// state
export const state = () => initialUserState()

// getters
export const getters = {
  prefectureText(state, getters, globalState) {
    return prefectureTextGetter(state, getters, globalState)
  },
  sexText(state, getters, globalState) {
    return sexTextGetter(state, getters, globalState)
  },
  corporateFgText(state, getters, globalState) {
    return corporateFgTextGetterForProfile(state, getters, globalState)
  },
  // 出品者属性のユーザーかどうか
  isProviderAttribute(state) {
    return (
      state.hasInfo.hasAward ||
      state.hasInfo.hasCareer ||
      state.hasInfo.hasLicense ||
      state.hasInfo.hasPortfolio ||
      state.hasInfo.hasRating ||
      state.hasInfo.hasService ||
      state.hasInfo.hasSkill ||
      state.hasInfo.hasSpecialty ||
      state.hasInfo.hasBlog ||
      state.hasInfo.hasPrice ||
      state.hasInfo.hasWorkHistory ||
      state.hasInfo.hasSchoolHistory ||
      state.hasInfo.hasLanguageSkill ||
      state.hasInfo.hasExperienceJob ||
      !!state.userJobsList.length
    )
  }
}

// actions
export const actions = {
  async fetchUser(
    { dispatch },
    {
      userId,
      options = initialProviderSummaryOptions(),
      isNotUpdateOption = false,
      userAction = '',
      detailJson = {}
    }
  ) {
    const promises = [
      new Promise((resolve, reject) => {
        dispatch('getProviderSummary', {
          userId,
          options,
          isNotUpdateOption,
          userAction,
          detailJson
        })
          .then(resolve)
          .catch(reject)
      }),
      new Promise((resolve, reject) => {
        dispatch('getUserRelation', userId).then(resolve).catch(reject)
      }),
      new Promise((resolve, reject) => {
        dispatch('getHasInfo', userId).then(resolve).catch(reject)
      })
    ]
    await Promise.all(promises).catch(({ grpcCode }) => {
      return Promise.reject({ grpcCode })
    })
  },
  async getProviderSummary(
    { commit, rootState },
    { userId, options, isNotUpdateOption, userAction, detailJson }
  ) {
    const { ProviderSummaryAccessor } = await import(
      '~/api/accessors/profile/provider-summary-accessor'
    )
    const apiAccessor = new ProviderSummaryAccessor(rootState)
    const requestReply = await apiAccessor.getProviderSummary({
      userId,
      includeUserJob: options.userJob,
      includeSkill: options.skill,
      includeUserSpecialty: options.userSpecialty,
      includeLicense: options.license,
      includeAward: options.award,
      detailJson,
      userAction
    })
    commit('RECEIVE_USER', requestReply)
    commit('RECEIVE_PROFILE', requestReply)
    commit('RECEIVE_USER_STATUS', requestReply)
    commit('RECEIVE_RATING_SUMMARY', requestReply.ratingSummary)
    if (!isNotUpdateOption) {
      commit('RECEIVE_USER_JOBS', requestReply)
      commit('RECEIVE_SKILLS', requestReply)
      commit('RECEIVE_USER_SPECIALTY', requestReply)
      commit('RECEIVE_LICENSE', requestReply)
      commit('RECEIVE_AWARD', requestReply)
    }
  },
  async getUserRelation({ commit, rootState }, userId) {
    const { UserAccessor } = await import('~/api/accessors/profile/user-accessor')
    const apiAccessor = new UserAccessor(rootState)
    const requestReply = await apiAccessor.getUserRelation({ userId })
    commit('RECEIVE_USER_RELATION', requestReply)
  },
  async getHasInfo({ commit, rootState }, userId) {
    const { HasAccessor } = await import('~/api/accessors/profile/has-accessor')

    const apiAccessor = new HasAccessor(rootState)
    const requestReply = await apiAccessor.getHasInfo({ userId })
    commit('RECEIVE_HAS_INFO', requestReply)
  },
  // // NOTE user relation
  async addFollow({ state, dispatch, rootState }) {
    const { FollowAccessor } = await import('~/api/accessors/profile/follow-accessor')
    const apiAccessor = new FollowAccessor(rootState)
    await apiAccessor.addFollow({ followId: state.id })
    await dispatch('fetchUser', { userId: state.id, isNotUpdateOption: true })
  },
  async deleteFollow({ state, dispatch, rootState }) {
    const { FollowAccessor } = await import('~/api/accessors/profile/follow-accessor')
    const apiAccessor = new FollowAccessor(rootState)
    await apiAccessor.deleteFollow({ followId: state.id })
    await dispatch('fetchUser', { userId: state.id, isNotUpdateOption: true })
  },
  // NOTE: user block
  async addBlock({ state, dispatch, rootState }, { blockeeId }) {
    await new Promise(async (resolve, reject) => {
      const [{ UserBlockRequest }, { UserBlockService }] = await Promise.all([
        import('~/stub/apigateway/profile/user_block_pb'),
        import('~/stub/apigateway/profile/user_block_pb_service')
      ])
      const request = new UserBlockRequest()
      request.setBlockeeId(blockeeId)
      const metadata = commonMetaData(rootState)
      grpc.invoke(UserBlockService.AddUserBlock, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, msg, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
            reject({ grpcCode, msg: decodedMessage })
          }
        }
      })
    })
    await dispatch('fetchUser', { userId: state.id, isNotUpdateOption: true })
  },
  async deleteBlock({ state, dispatch, rootState }, { blockeeId }) {
    await new Promise(async (resolve, reject) => {
      const [{ UserBlockRequest }, { UserBlockService }] = await Promise.all([
        import('~/stub/apigateway/profile/user_block_pb'),
        import('~/stub/apigateway/profile/user_block_pb_service')
      ])
      const request = new UserBlockRequest()
      request.setBlockeeId(blockeeId)
      const metadata = commonMetaData(rootState)
      grpc.invoke(UserBlockService.DeleteUserBlock, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, msg, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
    await dispatch('fetchUser', { userId: state.id, isNotUpdateOption: true })
  }
}

// mutations
export const mutations = {
  RESET(state) {
    Object.assign(state, initialUserState())
  },
  RECEIVE_USER(state, data) {
    Object.assign(state, {
      ...data.user
    })
  },
  RECEIVE_USER_RELATION(state, data) {
    Object.assign(state, { userRelation: data.userRelation })
  },
  RECEIVE_PROFILE(state, data) {
    Object.assign(state, { profile: data.profile })
  },
  RECEIVE_USER_STATUS(state, data) {
    Object.assign(state, { userStatus: data.userStatus })
  },
  RECEIVE_USER_JOBS(state, data) {
    Object.assign(state, { userJobsList: data.userJobsList })
  },
  RECEIVE_SKILLS(state, data) {
    Object.assign(state, { skillsList: data.skillsList })
  },
  RECEIVE_EDUCATIONAL_BACKGROUNDS(state, data) {
    Object.assign(state, { educationalBackgroundsList: data.schoolHistoriesList })
  },
  RECEIVE_LANGUAGE_SKILLS(state, data) {
    Object.assign(state, { languageSkillsList: data.languageSkillsList })
  },
  RECEIVE_EXPERIENCE_JOBS(state, data) {
    Object.assign(state, { experienceJobsList: data.experienceJobsList })
  },
  RECEIVE_CAREERS(state, data) {
    Object.assign(state, { careersList: data.careersList })
  },
  RECEIVE_USER_WORK_HISTORIES(state, data) {
    Object.assign(state, { userWorkHistoriesList: data.userWorkHistoriesList })
  },
  RECEIVE_DESIRED_WORKING_CONDITION(state, data) {
    Object.assign(state, { desiredWorkingCondition: data.desiredWorkingCondition })
  },
  RECEIVE_USER_SPECIALTY(state, data) {
    Object.assign(state, { userSpecialtiesList: data.userSpecialtiesList })
  },
  RECEIVE_LICENSE(state, data) {
    Object.assign(state, { licensesList: data.licensesList })
  },
  RECEIVE_AWARD(state, data) {
    Object.assign(state, { awardsList: data.awardsList })
  },
  RECEIVE_RATING_SUMMARY(state, data) {
    Object.assign(state, {
      ratingAvg: data.ratingAvg,
      achievementCount: data.achievementCount,
      followerCount: data.followerCount
    })
  },
  RECEIVE_HAS_INFO(state, data) {
    Object.assign(state, { hasInfo: data.hasInfo })
  }
}
