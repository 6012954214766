import { Middleware } from '@nuxt/types'

/**
 * アプリ（webview）以外の場合はトークルームへリダイレクトする
 */
const redirectToTalkroomsIfNotCoconalaApp: Middleware = ({ route, store, redirect }) => {
  if (!store.getters['auth/isFromCoconalaApp']) {
    redirect(`/talkrooms/${route.params.talkroomId}`)
  }
}
export default redirectToTalkroomsIfNotCoconalaApp
