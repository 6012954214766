import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// state
export const state = () => ({
  blogOrderHistoriesList: [],
  page: 1,
  perPage: 10,
  totalCount: 0
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchBlogOrderHistories({ commit, rootState }, { page, perPage }) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogOrderHistoriesRequest }, { BlogOrderService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_order_pb'),
        import('~/stub/apigateway/profile/blog_order_pb_service')
      ])
      const request = new GetBlogOrderHistoriesRequest()
      request.setPage(page)
      request.setPerPage(perPage)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogOrderService.GetBlogOrderHistories, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOG_ORDER_HISTORIES', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOG_ORDER_HISTORIES(state, data) {
    Object.assign(state, data)
  }
}
