import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'
import { toInt32 } from '~/grpc/grpc-util'

// initial state
const initialState = () => ({
  blogs: {
    blogsList: [],
    total: 0
  },
  blogMenus: {
    menusByLatestList: [],
    menusByYearList: [],
    menusByTagList: []
  }
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchBlogs({ commit, rootState }, data) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogsRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new GetBlogsRequest()
      try {
        request.setUserIdsList([toInt32(data.userId, true)])
        request.setYearAndMonth(toInt32(data.yearAndMonth))
        if (data.blogTagId) {
          request.setBlogTagIdsList([toInt32(data.blogTagId)])
        }
        request.setPage(toInt32(data.page))
      } catch (e) {
        reject(e)
      }
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.GetBlogs, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOGS', response)
  },
  async fetchBlogMenus({ commit, rootState }, userId) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogMenusRequest }, { BlogMenuService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_menu_pb'),
        import('~/stub/apigateway/profile/blog_menu_pb_service')
      ])
      const request = new GetBlogMenusRequest()
      try {
        request.setUserId(toInt32(userId, true))
      } catch (e) {
        reject(e)
      }
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogMenuService.GetBlogMenus, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOG_MENUS', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOGS(state, data) {
    Object.assign(state.blogs, {
      ...data
    })
  },
  RECEIVE_BLOG_MENUS(state, data) {
    Object.assign(state.blogMenus, {
      ...data
    })
  }
}
