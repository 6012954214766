// source: apigateway/profile/received_favorite.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var profileservice_received_favorite_pb = require('../../profileservice/received_favorite_pb.js');
goog.object.extend(proto, profileservice_received_favorite_pb);
goog.exportSymbol('proto.profile.BlogFollower', null, global);
goog.exportSymbol('proto.profile.GetReceivedFavoriteBlogFollowersReply', null, global);
goog.exportSymbol('proto.profile.GetReceivedFavoriteBlogFollowersRequest', null, global);
goog.exportSymbol('proto.profile.GetReceivedFavoriteBlogsReply', null, global);
goog.exportSymbol('proto.profile.GetReceivedFavoriteBlogsRequest', null, global);
goog.exportSymbol('proto.profile.ProviderRank', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.GetReceivedFavoriteBlogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.GetReceivedFavoriteBlogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.GetReceivedFavoriteBlogsRequest.displayName = 'proto.profile.GetReceivedFavoriteBlogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.GetReceivedFavoriteBlogsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profile.GetReceivedFavoriteBlogsReply.repeatedFields_, null);
};
goog.inherits(proto.profile.GetReceivedFavoriteBlogsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.GetReceivedFavoriteBlogsReply.displayName = 'proto.profile.GetReceivedFavoriteBlogsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profile.GetReceivedFavoriteBlogFollowersRequest.repeatedFields_, null);
};
goog.inherits(proto.profile.GetReceivedFavoriteBlogFollowersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.GetReceivedFavoriteBlogFollowersRequest.displayName = 'proto.profile.GetReceivedFavoriteBlogFollowersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profile.GetReceivedFavoriteBlogFollowersReply.repeatedFields_, null);
};
goog.inherits(proto.profile.GetReceivedFavoriteBlogFollowersReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.GetReceivedFavoriteBlogFollowersReply.displayName = 'proto.profile.GetReceivedFavoriteBlogFollowersReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.BlogFollower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.BlogFollower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.BlogFollower.displayName = 'proto.profile.BlogFollower';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.ProviderRank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.ProviderRank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.ProviderRank.displayName = 'proto.profile.ProviderRank';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.GetReceivedFavoriteBlogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.GetReceivedFavoriteBlogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.GetReceivedFavoriteBlogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetReceivedFavoriteBlogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.GetReceivedFavoriteBlogsRequest}
 */
proto.profile.GetReceivedFavoriteBlogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.GetReceivedFavoriteBlogsRequest;
  return proto.profile.GetReceivedFavoriteBlogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.GetReceivedFavoriteBlogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.GetReceivedFavoriteBlogsRequest}
 */
proto.profile.GetReceivedFavoriteBlogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.GetReceivedFavoriteBlogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.GetReceivedFavoriteBlogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.GetReceivedFavoriteBlogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetReceivedFavoriteBlogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.profile.GetReceivedFavoriteBlogsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.GetReceivedFavoriteBlogsRequest} returns this
 */
proto.profile.GetReceivedFavoriteBlogsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.profile.GetReceivedFavoriteBlogsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.GetReceivedFavoriteBlogsRequest} returns this
 */
proto.profile.GetReceivedFavoriteBlogsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profile.GetReceivedFavoriteBlogsReply.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.GetReceivedFavoriteBlogsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.GetReceivedFavoriteBlogsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetReceivedFavoriteBlogsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    blogsList: jspb.Message.toObjectList(msg.getBlogsList(),
    profileservice_received_favorite_pb.FollowBlog.toObject, includeInstance),
    followersList: jspb.Message.toObjectList(msg.getFollowersList(),
    proto.profile.BlogFollower.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.GetReceivedFavoriteBlogsReply}
 */
proto.profile.GetReceivedFavoriteBlogsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.GetReceivedFavoriteBlogsReply;
  return proto.profile.GetReceivedFavoriteBlogsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.GetReceivedFavoriteBlogsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.GetReceivedFavoriteBlogsReply}
 */
proto.profile.GetReceivedFavoriteBlogsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new profileservice_received_favorite_pb.FollowBlog;
      reader.readMessage(value,profileservice_received_favorite_pb.FollowBlog.deserializeBinaryFromReader);
      msg.addBlogs(value);
      break;
    case 3:
      var value = new proto.profile.BlogFollower;
      reader.readMessage(value,proto.profile.BlogFollower.deserializeBinaryFromReader);
      msg.addFollowers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.GetReceivedFavoriteBlogsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.GetReceivedFavoriteBlogsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetReceivedFavoriteBlogsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBlogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      profileservice_received_favorite_pb.FollowBlog.serializeBinaryToWriter
    );
  }
  f = message.getFollowersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.profile.BlogFollower.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.GetReceivedFavoriteBlogsReply} returns this
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated profileservice.FollowBlog blogs = 2;
 * @return {!Array<!proto.profileservice.FollowBlog>}
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.getBlogsList = function() {
  return /** @type{!Array<!proto.profileservice.FollowBlog>} */ (
    jspb.Message.getRepeatedWrapperField(this, profileservice_received_favorite_pb.FollowBlog, 2));
};


/**
 * @param {!Array<!proto.profileservice.FollowBlog>} value
 * @return {!proto.profile.GetReceivedFavoriteBlogsReply} returns this
*/
proto.profile.GetReceivedFavoriteBlogsReply.prototype.setBlogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.profileservice.FollowBlog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profileservice.FollowBlog}
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.addBlogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.profileservice.FollowBlog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profile.GetReceivedFavoriteBlogsReply} returns this
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.clearBlogsList = function() {
  return this.setBlogsList([]);
};


/**
 * repeated BlogFollower followers = 3;
 * @return {!Array<!proto.profile.BlogFollower>}
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.getFollowersList = function() {
  return /** @type{!Array<!proto.profile.BlogFollower>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.profile.BlogFollower, 3));
};


/**
 * @param {!Array<!proto.profile.BlogFollower>} value
 * @return {!proto.profile.GetReceivedFavoriteBlogsReply} returns this
*/
proto.profile.GetReceivedFavoriteBlogsReply.prototype.setFollowersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.profile.BlogFollower=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profile.BlogFollower}
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.addFollowers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.profile.BlogFollower, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profile.GetReceivedFavoriteBlogsReply} returns this
 */
proto.profile.GetReceivedFavoriteBlogsReply.prototype.clearFollowersList = function() {
  return this.setFollowersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.GetReceivedFavoriteBlogFollowersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.GetReceivedFavoriteBlogFollowersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersRequest}
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.GetReceivedFavoriteBlogFollowersRequest;
  return proto.profile.GetReceivedFavoriteBlogFollowersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.GetReceivedFavoriteBlogFollowersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersRequest}
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setUserIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.GetReceivedFavoriteBlogFollowersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.GetReceivedFavoriteBlogFollowersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 user_ids = 1;
 * @return {!Array<number>}
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersRequest} returns this
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.prototype.setUserIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersRequest} returns this
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.prototype.addUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersRequest} returns this
 */
proto.profile.GetReceivedFavoriteBlogFollowersRequest.prototype.clearUserIdsList = function() {
  return this.setUserIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.GetReceivedFavoriteBlogFollowersReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.GetReceivedFavoriteBlogFollowersReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    followersList: jspb.Message.toObjectList(msg.getFollowersList(),
    proto.profile.BlogFollower.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersReply}
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.GetReceivedFavoriteBlogFollowersReply;
  return proto.profile.GetReceivedFavoriteBlogFollowersReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.GetReceivedFavoriteBlogFollowersReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersReply}
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.profile.BlogFollower;
      reader.readMessage(value,proto.profile.BlogFollower.deserializeBinaryFromReader);
      msg.addFollowers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.GetReceivedFavoriteBlogFollowersReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.GetReceivedFavoriteBlogFollowersReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFollowersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.profile.BlogFollower.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BlogFollower followers = 1;
 * @return {!Array<!proto.profile.BlogFollower>}
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.prototype.getFollowersList = function() {
  return /** @type{!Array<!proto.profile.BlogFollower>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.profile.BlogFollower, 1));
};


/**
 * @param {!Array<!proto.profile.BlogFollower>} value
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersReply} returns this
*/
proto.profile.GetReceivedFavoriteBlogFollowersReply.prototype.setFollowersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.profile.BlogFollower=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profile.BlogFollower}
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.prototype.addFollowers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.profile.BlogFollower, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profile.GetReceivedFavoriteBlogFollowersReply} returns this
 */
proto.profile.GetReceivedFavoriteBlogFollowersReply.prototype.clearFollowersList = function() {
  return this.setFollowersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.BlogFollower.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.BlogFollower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.BlogFollower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.BlogFollower.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userIconResizable: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sex: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastLoginAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    providerRank: (f = msg.getProviderRank()) && proto.profile.ProviderRank.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.BlogFollower}
 */
proto.profile.BlogFollower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.BlogFollower;
  return proto.profile.BlogFollower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.BlogFollower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.BlogFollower}
 */
proto.profile.BlogFollower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIconResizable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSex(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastLoginAt(value);
      break;
    case 7:
      var value = new proto.profile.ProviderRank;
      reader.readMessage(value,proto.profile.ProviderRank.deserializeBinaryFromReader);
      msg.setProviderRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.BlogFollower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.BlogFollower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.BlogFollower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.BlogFollower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserIconResizable();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSex();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastLoginAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getProviderRank();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.profile.ProviderRank.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.profile.BlogFollower.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.BlogFollower} returns this
 */
proto.profile.BlogFollower.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.profile.BlogFollower.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.BlogFollower} returns this
 */
proto.profile.BlogFollower.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_icon_resizable = 3;
 * @return {string}
 */
proto.profile.BlogFollower.prototype.getUserIconResizable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.BlogFollower} returns this
 */
proto.profile.BlogFollower.prototype.setUserIconResizable = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sex = 5;
 * @return {string}
 */
proto.profile.BlogFollower.prototype.getSex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.BlogFollower} returns this
 */
proto.profile.BlogFollower.prototype.setSex = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 last_login_at = 6;
 * @return {number}
 */
proto.profile.BlogFollower.prototype.getLastLoginAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.BlogFollower} returns this
 */
proto.profile.BlogFollower.prototype.setLastLoginAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional ProviderRank provider_rank = 7;
 * @return {?proto.profile.ProviderRank}
 */
proto.profile.BlogFollower.prototype.getProviderRank = function() {
  return /** @type{?proto.profile.ProviderRank} */ (
    jspb.Message.getWrapperField(this, proto.profile.ProviderRank, 7));
};


/**
 * @param {?proto.profile.ProviderRank|undefined} value
 * @return {!proto.profile.BlogFollower} returns this
*/
proto.profile.BlogFollower.prototype.setProviderRank = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profile.BlogFollower} returns this
 */
proto.profile.BlogFollower.prototype.clearProviderRank = function() {
  return this.setProviderRank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profile.BlogFollower.prototype.hasProviderRank = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.ProviderRank.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.ProviderRank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.ProviderRank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ProviderRank.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.ProviderRank}
 */
proto.profile.ProviderRank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.ProviderRank;
  return proto.profile.ProviderRank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.ProviderRank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.ProviderRank}
 */
proto.profile.ProviderRank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.ProviderRank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.ProviderRank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.ProviderRank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ProviderRank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.profile.ProviderRank.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.ProviderRank} returns this
 */
proto.profile.ProviderRank.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.profile.ProviderRank.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.ProviderRank} returns this
 */
proto.profile.ProviderRank.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.profile);
