import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
const initialState = () => ({
  blogSnapshot: {},
  userProfile: {}
})

export const state = () => initialState()

// actions
export const actions = {
  async fetchSnapshotBlog({ commit, rootState }, hashId) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogSnapshotRequest }, { BlogSnapshotService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_snapshot_pb'),
        import('~/stub/apigateway/profile/blog_snapshot_pb_service')
      ])
      const request = new GetBlogSnapshotRequest()
      request.setHashId(hashId)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogSnapshotService.GetBlogSnapshot, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_SNAPSHOT_BLOG', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_SNAPSHOT_BLOG(state, data) {
    Object.assign(state, {
      ...data
    })
  }
}
