// package: suggest
// file: apigateway/suggest/service.proto

import * as apigateway_suggest_service_pb from "../../apigateway/suggest/service_pb";
export class ServiceService {
  static serviceName = "suggest.ServiceService";
}
export namespace ServiceService {
  export class GetSearchQuery {
    static readonly methodName = "GetSearchQuery";
    static readonly service = ServiceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_suggest_service_pb.GetSearchQueryRequest;
    static readonly responseType = apigateway_suggest_service_pb.GetSearchQueryReply;
  }
  export class AddSelectLog {
    static readonly methodName = "AddSelectLog";
    static readonly service = ServiceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_suggest_service_pb.AddSelectLogRequest;
    static readonly responseType = apigateway_suggest_service_pb.AddSelectLogReply;
  }
}
