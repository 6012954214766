import { commonMetaData } from '~/store/_helpers/common-helper'
import {
  GetTransactionsRequest,
  UpdateTransactionRequest
} from '~/stub/apigateway/transaction_activities/transaction_activities_pb'
import { TransactionActivitiesService } from '~/stub/apigateway/transaction_activities/transaction_activities_pb_service'
import grpcClient from '@/grpc/grpc-client'
import { MarkAsReadRequest } from '~/stub/apigateway/activities/activities_pb'
import { ActivitiesService } from '~/stub/apigateway/activities/activities_pb_service'

const DEFAULT_PER_PAGE = 30
const ICON_SIZE = 'default'

// initial state
export const state = () => ({
  transactionNotifications: {
    totalCount: 0,
    transactionsList: []
  }
})

// getters
export const getters = {}

// actions
export const actions = {
  /**
   * 取引通知の一覧を取得する
   */
  async fetchTransactions({ commit, rootState }, { currentPage, perPage = DEFAULT_PER_PAGE }) {
    const setMetadata = commonMetaData(rootState)
    const request = new GetTransactionsRequest()
    request.setPage(currentPage)
    request.setPerPage(perPage)
    request.setIconSize(ICON_SIZE)
    const transactionsReply = await grpcClient({
      method: TransactionActivitiesService.GetTransactions,
      request,
      metadata: setMetadata
    })
    commit('RECEIVE_TRANSACTION_FOR_PAGE', transactionsReply)
  },

  /**
   * 取引通知の状態の更新を行う（新形式）
   */
  async updateTransaction({ commit, rootState }, { groupType, groupId, triggerType }) {
    const setMetadata = commonMetaData(rootState)
    const request = new UpdateTransactionRequest()
    request.setGroupType(groupType)
    request.setGroupId(groupId)
    request.setTriggerType(triggerType)
    await grpcClient({
      method: TransactionActivitiesService.UpdateTransaction,
      request,
      metadata: setMetadata
    })
  },

  /**
   * 取引通知の状態の更新を行う（旧形式）
   */
  async updateOldTransaction({ commit, rootState }, { activityId }) {
    const setMetadata = commonMetaData(rootState)
    const request = new MarkAsReadRequest()
    request.setActivityId(activityId)
    await grpcClient({
      method: ActivitiesService.MarkAsRead,
      request,
      metadata: setMetadata
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_TRANSACTION_FOR_PAGE(state, data) {
    state.transactionNotifications = data
  }
}
