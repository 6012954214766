import Vue from 'vue'
import Coconala from '~/assets/javascripts/coconala'
import { A8_COOKIE_KEY, LINK_EDGE_COOKIE_KEY, ACCESS_TRADE_COOKIE_KEY } from './affiliate'
import Util from '~/assets/javascripts/util'

export default (context, inject) => {
  inject(
    'coconala',
    new Coconala({
      config: context.env.config,
      req: context.req,
      route: context.route,
      a8Id: context.app.$cookies.get(A8_COOKIE_KEY) || context.route.query.a8,
      linkEdgeId: context.app.$cookies.get(LINK_EDGE_COOKIE_KEY) || context.route.query.cid,
      accessTradeId:
        context.app.$cookies.get(ACCESS_TRADE_COOKIE_KEY) ||
        Util.excludeInvalidAffiliateValue(context.route.query.atss)
    })
  )
}

// カスタムスクロールディレクティブ
// https://jp.vuejs.org/v2/cookbook/creating-custom-scroll-directives.html
Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)

    el.f = f
  },
  unbind: function (el) {
    if (el.f) {
      window.removeEventListener('scroll', el.f)
      delete el.f
    }
  }
})
