import { commonMetaData } from '~/store/_helpers/common-helper'
import { GetDirectMessagesRequest } from '~/stub/apigateway/activities/activities_pb'
import { ActivitiesService } from '~/stub/apigateway/activities/activities_pb_service'
import grpcClient from '@/grpc/grpc-client'

const DEFAULT_PER_PAGE = 30
const ICON_SIZE = 's'

// initial state
export const state = () => ({
  messages: {
    totalCount: 0,
    directMessagesList: []
  }
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchMessages({ commit, rootState }, { currentPage, perPage = DEFAULT_PER_PAGE }) {
    const setMetadata = commonMetaData(rootState)
    const request = new GetDirectMessagesRequest()
    request.setPage(currentPage)
    request.setPerPage(perPage)
    request.setIconSize(ICON_SIZE)
    const directMessagesReply = await grpcClient({
      method: ActivitiesService.GetDirectMessages,
      request,
      metadata: setMetadata
    })
    commit('RECEIVE_DIRECT_MESSAGES', directMessagesReply)
  }
}

// mutations
export const mutations = {
  RECEIVE_DIRECT_MESSAGES(state, data) {
    state.messages = data
  }
}
