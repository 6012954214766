import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '~/grpc/grpc-client'

// initial state
const initialState = () => ({})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchBuyerDashboard({ commit, rootState }, data) {
    const [
      { GetBuyerServiceRecommendationRequest, GetBuyerDashboardRequest, GetBuyerHeroAreaRequest },
      { DashboardBuyerService }
    ] = await Promise.all([
      import('~/stub/apigateway/dashboard/dashboard_buyer_pb'),
      import('~/stub/apigateway/dashboard/dashboard_buyer_pb_service')
    ])
    const setMetadata = commonMetaData(rootState)
    setMetadata['x-log-detail'] = JSON.stringify({ view_id: rootState.big_query.viewId })
    const buyerServiceRecommendationRequest = new GetBuyerServiceRecommendationRequest()
    buyerServiceRecommendationRequest.setAlgorithm('personalize')
    buyerServiceRecommendationRequest.setBusinessFlag(data.businessFlag)
    const responses = Object.fromEntries(
      await Promise.all([
        // GetBuyerDashboard
        grpcClient({
          method: DashboardBuyerService.GetBuyerDashboard,
          request: new GetBuyerDashboardRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['buyerDashboard', reply]
          }
        }),
        // GetBuyerServiceRecommendationRequest
        grpcClient({
          method: DashboardBuyerService.GetBuyerServiceRecommendation,
          request: buyerServiceRecommendationRequest,
          metadata: setMetadata,
          transformReply: reply => {
            return ['buyerServiceRecommendation', reply]
          }
        }),
        // GetBuyerHeroAreaRequest
        grpcClient({
          method: DashboardBuyerService.GetBuyerHeroArea,
          request: new GetBuyerHeroAreaRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['buyerHeroArea', reply]
          }
        })
      ])
    )
    return responses
  }
}

// mutations
export const mutations = {}
