import Util from '~/assets/javascripts/util'

export const A8_COOKIE_KEY = 'a8_affiliate_id'
export const LINK_EDGE_COOKIE_KEY = 'link_edge_affiliate_id'
export const ACCESS_TRADE_COOKIE_KEY = 'access_trade_affiliate_id'
// NOTE: 'CL_{広告ID}'なので別広告を利用する場合は別途設定が必要
export const AFFILI_CODE_COOKIE_KEY = 'CL_ph4r84go87io'
export const AFFILI_CODE_REGISTRATION_COOKIE_KEY = 'CL_phjd44vpseck'
export const ENUM_ASP_TYPE = {
  A8: 1,
  LinkEdge: 2,
  AccessTrade: 3,
  AffiliCode: 4
}

const requestLinkAsp = (asp_type, affiliate_value, store) => {
  if (affiliate_value) {
    store.dispatch('my/affiliate/linkAspWithUserId', {
      aspType: asp_type,
      affiliateValue: affiliate_value
    })
  }
}

export default ({ app, store }) => {
  if (!store.getters['auth/isLoggedIn']) {
    return
  }

  const a8Id = Util.excludeInvalidAffiliateValue(app.$cookies.get(A8_COOKIE_KEY))
  const linkEdgeId = Util.excludeInvalidAffiliateValue(app.$cookies.get(LINK_EDGE_COOKIE_KEY))
  const accessTradeId = Util.excludeInvalidAffiliateValue(app.$cookies.get(ACCESS_TRADE_COOKIE_KEY))
  const affiliCodePurchaseId = Util.excludeInvalidAffiliateValue(
    app.$cookies.get(AFFILI_CODE_COOKIE_KEY)
  )
  const affiliCodeRegistrationId = Util.excludeInvalidAffiliateValue(
    app.$cookies.get(AFFILI_CODE_REGISTRATION_COOKIE_KEY)
  )
  const affiliCodeId = affiliCodeRegistrationId || affiliCodePurchaseId

  requestLinkAsp(ENUM_ASP_TYPE.A8, a8Id, store)
  requestLinkAsp(ENUM_ASP_TYPE.LinkEdge, linkEdgeId, store)
  requestLinkAsp(ENUM_ASP_TYPE.AccessTrade, accessTradeId, store)
  requestLinkAsp(ENUM_ASP_TYPE.AffiliCode, affiliCodeId, store)
}
