export default async ({ app }) => {
  if (process.client) {
    // GTMに定義したカスタムイベントを送信することで、SPA画面遷移をトリガーにしてGTMタグの発火が行われる
    app.router.afterEach(() =>
      setTimeout(
        () => {
          if (!Array.isArray(window.dataLayer)) {
            window.dataLayer = []
          }
          window.dataLayer.push({ event: 'spa_page_transition' })
        },
        500 // 画面遷移が完全に完了することを待たないと、前のページのtitleが記録されてしまうのを防ぐ
      )
    )
  }
}
