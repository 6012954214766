import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// 占いカテゴリID
const DIVINATION_CATEGORY_IDS = [3, 79, 80, 656, 657, 658, 659, 660, 661, 816, 817]

// initial state
export const state = () => ({
  searchResultsList: [],
  searchCondition: {
    categoriesList: [
      {
        id: null,
        name: '',
        isCurrent: false,
        subCategoriesList: []
      }
    ],
    conditionOptions: [
      {
        keyCategoryId: '',
        value: '',
        valuesList: [],
        min: {},
        max: {}
      }
    ],
    page: '',
    perPage: '',
    serviceClass: '',
    serviceKind: '',
    serviceType: '',
    keyword: '',
    priceMin: '',
    priceMax: '',
    categoryId: '',
    masterCategoryId: '',
    masterCategoryTypeId: '',
    sortBy: '',
    forceExclusion: '',
    publishedOnly: '',
    proFlag: '',
    proPriorityDisplay: '',
    certificationDivinationPriorityDisplay: '',
    customizeFlag: '',
    shipmentFlag: '',
    identificationFlag: '',
    ndaConclusionFlag: '',
    reservationAvailableFlag: '',
    includeNgService: '',
    excludeFullCorrespondence: '',
    videoServiceFlag: '',
    providerStatusesList: [],
    reservationReceptDate: '',
    reservationReceptStartTime: '',
    reservationReceptEndTime: '',
    canAdvanceReservation: false,
    canReservation: false,
    isWaitingPhone: false,
    waitingPhonePriorityDisplay: ''
  },
  total: 0
})

// getters
export const getters = {
  keyCategoryIdForTechniques: state => {
    if (!state.searchCondition.conditionOptionsList) {
      return ''
    }
    const techniqueOption = state.searchCondition.conditionOptionsList.find(option =>
      option.keyCategoryId.match('technique_ids')
    )
    return techniqueOption?.keyCategoryId || ''
  }
}

// actions
export const actions = {
  async searchRequests(
    { commit, rootState, rootGetters },
    { params, query, type = 'all', fullPath }
  ) {
    const [{ SearchServiceRequest }, { SearchService }] = await Promise.all([
      import('~/stub/apigateway/service/search_pb'),
      import('~/stub/apigateway/service/search_pb_service')
    ])
    const getRequest = new SearchServiceRequest()
    const setMetadata = commonMetaData(rootState)
    if (fullPath) {
      setMetadata['x-full-path'] = [fullPath]
    }
    setMetadata['x-log-detail'] = JSON.stringify({ view_id: rootState.big_query.viewId })
    const toString = value => {
      return typeof value === 'undefined' ? value : String(value)
    }
    const toNumber = value => {
      if (typeof value === 'undefined') {
        return undefined
      }
      if (isNaN(value)) {
        throw { grpcCode: grpc.Code.InvalidArgument }
      }
      return Number(value)
    }
    const toBoolean = value => {
      if (typeof value === 'undefined' || value === null) {
        return undefined
      }
      if (typeof value !== 'boolean') {
        throw { grpcCode: grpc.Code.InvalidArgument }
      }
      return value
    }
    let categoryId = params.categoryId
    if (!categoryId && query.category_id) {
      categoryId = query.category_id
    }
    let categoryTypeId = params.categoryTypeId
    if (!categoryTypeId && query.category_type_id) {
      categoryTypeId = query.category_type_id
    }

    let layout = this.$cookies.get('search_layout')
    if (query.layout) {
      layout = query.layout
    } else if (rootGetters['translate/isTranslatableTarget']) {
      // 外国人ユーザーはデフォルトタイル表示にし、翻訳コストを抑える
      layout = rootState.constants.serviceLayout.block
    }

    let proPriorityDisplay = this.$cookies.get('pro_priority_display')
    if (query.pro_priority_display) {
      proPriorityDisplay = query.pro_priority_display
    }
    let certificationDivinationPriorityDisplay = this.$cookies.get(
      'certification_divination_priority_display'
    )
    if (query.certification_divination_priority_display) {
      certificationDivinationPriorityDisplay = query.certification_divination_priority_display
    }
    // マーケ施策に被せるかたちで初心者向けソートを追加
    let sortBy = query.sort_by
    if (!query.sort_by) {
      if (rootState.auth.user.isBeginner) {
        sortBy = 'beginner'
      } else if (
        rootGetters['auth/isLoggedIn'] ||
        query.keyword ||
        DIVINATION_CATEGORY_IDS.includes(Number(categoryId))
      ) {
        sortBy = 'recommend'
      } else {
        sortBy = 'ranking'
      }
    }
    // 新着順のときはPRO優先で絞り込まない（URLパラメータは維持するため、ここでAPIに投げる値だけ0にする）
    if (sortBy === 'new') {
      proPriorityDisplay = 0
    }

    getRequest.setSearchType(type) // "all" 検索結果 + コンディション / "condition" コンディション
    getRequest.setPage(toNumber(query.page))
    getRequest.setPerPage(null)
    getRequest.setServiceKind(toString(query.service_kind))
    getRequest.setKeyword(toString(query.keyword))
    getRequest.setPriceMin(toNumber(query.price_min))
    getRequest.setPriceMax(toNumber(query.price_max))
    getRequest.setMasterCategoryId(toNumber(categoryId))
    getRequest.setMasterCategoryTypeId(toNumber(categoryTypeId))
    getRequest.setSortBy(toString(sortBy))
    getRequest.setDeliveryAverage(toString(query.delivery_average))
    getRequest.setForceExclusion(toString(query.force_exclusion))
    getRequest.setPublishedOnly(toString(query.published_only))
    getRequest.setProFlag(toString(query.pro_flag))
    getRequest.setCertificationDivinationPriorityDisplay(
      toString(certificationDivinationPriorityDisplay)
    )
    getRequest.setProPriorityDisplay(toString(proPriorityDisplay))
    getRequest.setCustomizeFlag(toString(query.customize_flag))
    getRequest.setShipmentFlag(toString(query.shipment_flag))
    getRequest.setIdentificationFlag(toString(query.identification_flag))
    getRequest.setNdaConclusionFlag(toString(query.nda_conclusion_flag))
    getRequest.setReservationAvailableFlag(toString(query.reservation_available_flag))
    getRequest.setIncludeNgService(toString(query.include_ng_service))
    getRequest.setExcludeFullCorrespondence(toString(query.exclude_full_correspondence))
    getRequest.setVideoServiceFlag(toString(query.video_service_flag))
    getRequest.setAvailableOrder(toString(query.available_order))
    getRequest.setLoginDays(toNumber(query.login_days))
    getRequest.setFixLimitMin(toNumber(query.fix_limit_min))
    getRequest.setProposalLimitMin(toNumber(query.proposal_limit_min))
    getRequest.setUnitPriceMin(toNumber(query.unit_price_min))
    getRequest.setUnitPriceMax(toNumber(query.unit_price_max))
    getRequest.setProviderStatusesList(query.provider_statuses)
    getRequest.setStyleIdsList(query.style_ids)
    getRequest.setContentIdsList(query.content_ids)
    getRequest.setGenreIdsList(query.genre_ids)
    getRequest.setUsageIdsList(query.usage_ids)
    getRequest.setOccupationIdsList(query.occupation_ids)
    getRequest.setIndustryIdsList(query.industry_ids)
    getRequest.setLanguageIdsList(query.language_ids)
    getRequest.setFileTypeIdsList(query.file_type_ids)
    getRequest.setLayout(toString(layout))
    getRequest.setTechniqueIdsList(query.technique_ids)
    getRequest.setPaperSizeIdsList(query.paper_size_ids)
    getRequest.setBaseScopeIdsList(query.base_scope_ids)
    getRequest.setSexIdsList(query.sex_ids)
    getRequest.setBusinessFlag(toBoolean(query.business_flag))
    getRequest.setProviderLevelIdsList(query.provider_level_ids)
    getRequest.setReservationReceptDate(toString(query.reservation_recept_date))
    getRequest.setReservationReceptStartTime(toString(query.reservation_recept_start_time))
    getRequest.setReservationReceptEndTime(toString(query.reservation_recept_end_time))
    getRequest.setCanAdvanceReservation(toBoolean(query.can_advance_reservation))
    getRequest.setCanReservation(toBoolean(query.can_reservation))
    getRequest.setIsWaitingPhone(toBoolean(query.is_waiting_phone))
    getRequest.setWaitingPhonePriorityDisplay(toString(query.waiting_phone_priority_display))
    getRequest.setDetailJson(toString(params.detailJson))
    getRequest.setIsMzhc(toBoolean(query.mzhc_flag))

    const searchServiceReply = await new Promise((resolve, reject) => {
      grpc.invoke(SearchService.SearchServices, {
        request: getRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    // NOTE:サービスPRとサービスのそれぞれの表示順を結果に挿入する
    let onlyNormalServiceIndex = 0
    let onlyPrServiceIndex = 0
    for (const service of searchServiceReply.searchResultsList) {
      if (service.hasOwnProperty('servicePr') && !!service.servicePr?.sprKey) {
        /*
          NOTE:idをkeyとして使用した場合、PRありサービスとなしサービスで同じサービスが表示される可能性があり
          keyが重複してエラーになるため、'pr-' + sprkey でkeyを生成する
          keyがサービスIDのみの場合、別ページでも同じDOMとして扱われImpressionが発火しなくなるため、SprKeyを末尾につけてKeyを一意のものにしている
        */
        service.serviceKey = `pr-${service.servicePr.sprKey}`
        service.onlyPrServiceIndex = onlyPrServiceIndex
        onlyPrServiceIndex++
      } else {
        service.serviceKey = service.service.id
        service.onlyNormalServiceIndex = onlyNormalServiceIndex
        onlyNormalServiceIndex++
      }
    }

    commit('RECEIVE_SEARCH_REQUESTS', {
      search: searchServiceReply,
      type: type
    })
  },
  async categoryChange({ state, commit }, data) {
    commit('CATEGORY_CHANGE', data)
  }
}

// mutations
export const mutations = {
  RECEIVE_SEARCH_REQUESTS(state, data) {
    // searchTypeが'condition'の場合は検索結果を更新しない
    if (data.type === 'condition') {
      Object.assign(state.searchCondition, data.search.searchCondition)
    } else {
      Object.assign(state, data.search)
    }
  },
  CATEGORY_CHANGE(state, data) {
    state.searchCondition.categoriesList[0].name = data
    state.searchCondition.categoriesList[0].subCategoriesList.length = 0
  }
}
