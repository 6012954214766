import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { GetDownloadUrlRequest } from '~/stub/apigateway/common/file_download_pb'
import { FileDownloadService } from '~/stub/apigateway/common/file_download_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async getDownloadUrl({ rootState }, { downloadType, fileId, expireSecond = null }) {
    const request = new GetDownloadUrlRequest()
    request.setType(downloadType)
    request.setFileId(fileId)
    request.setExpireSecond(expireSecond)
    const metadata = commonMetaData(rootState)
    return new Promise((resolve, reject) => {
      grpc.invoke(FileDownloadService.GetDownloadUrl, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {}
