// source: apigateway/profile/service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.profile.GetMyServicesRequest', null, global);
goog.exportSymbol('proto.profile.GetServicesRequest', null, global);
goog.exportSymbol('proto.profile.ProviderStatus', null, global);
goog.exportSymbol('proto.profile.Service', null, global);
goog.exportSymbol('proto.profile.ServiceCategory', null, global);
goog.exportSymbol('proto.profile.ServiceRating', null, global);
goog.exportSymbol('proto.profile.ServicesReply', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.ServiceCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.ServiceCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.ServiceCategory.displayName = 'proto.profile.ServiceCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.ServiceRating = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.ServiceRating, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.ServiceRating.displayName = 'proto.profile.ServiceRating';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.Service = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.Service, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.Service.displayName = 'proto.profile.Service';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.ProviderStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.ProviderStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.ProviderStatus.displayName = 'proto.profile.ProviderStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.GetServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.GetServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.GetServicesRequest.displayName = 'proto.profile.GetServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.GetMyServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profile.GetMyServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.GetMyServicesRequest.displayName = 'proto.profile.GetMyServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profile.ServicesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profile.ServicesReply.repeatedFields_, null);
};
goog.inherits(proto.profile.ServicesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profile.ServicesReply.displayName = 'proto.profile.ServicesReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.ServiceCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.ServiceCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.ServiceCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ServiceCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    parentName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.ServiceCategory}
 */
proto.profile.ServiceCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.ServiceCategory;
  return proto.profile.ServiceCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.ServiceCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.ServiceCategory}
 */
proto.profile.ServiceCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.ServiceCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.ServiceCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.ServiceCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ServiceCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getParentName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profile.ServiceCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.ServiceCategory} returns this
 */
proto.profile.ServiceCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.profile.ServiceCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.ServiceCategory} returns this
 */
proto.profile.ServiceCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 parent_id = 3;
 * @return {number}
 */
proto.profile.ServiceCategory.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.ServiceCategory} returns this
 */
proto.profile.ServiceCategory.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string parent_name = 4;
 * @return {string}
 */
proto.profile.ServiceCategory.prototype.getParentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.ServiceCategory} returns this
 */
proto.profile.ServiceCategory.prototype.setParentName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.ServiceRating.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.ServiceRating.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.ServiceRating} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ServiceRating.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    indicator: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    ratingCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.ServiceRating}
 */
proto.profile.ServiceRating.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.ServiceRating;
  return proto.profile.ServiceRating.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.ServiceRating} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.ServiceRating}
 */
proto.profile.ServiceRating.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIndicator(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.ServiceRating.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.ServiceRating.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.ServiceRating} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ServiceRating.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndicator();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRatingCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.profile.ServiceRating.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.ServiceRating} returns this
 */
proto.profile.ServiceRating.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float indicator = 2;
 * @return {number}
 */
proto.profile.ServiceRating.prototype.getIndicator = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.profile.ServiceRating} returns this
 */
proto.profile.ServiceRating.prototype.setIndicator = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 rating_count = 3;
 * @return {number}
 */
proto.profile.ServiceRating.prototype.getRatingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.ServiceRating} returns this
 */
proto.profile.ServiceRating.prototype.setRatingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.Service.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.Service.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.Service} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.Service.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    overview: jspb.Message.getFieldWithDefault(msg, 3, ""),
    catchphrase: jspb.Message.getFieldWithDefault(msg, 4, ""),
    head: jspb.Message.getFieldWithDefault(msg, 5, ""),
    unitTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    trialCount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    openDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mainImageUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    serviceClass: jspb.Message.getFieldWithDefault(msg, 11, 0),
    favoriteCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    version: jspb.Message.getFieldWithDefault(msg, 13, 0),
    performanceCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    videoFlag: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    proFlag: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    customizeFlag: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    favoriteFlag: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    created: jspb.Message.getFieldWithDefault(msg, 19, 0),
    modified: jspb.Message.getFieldWithDefault(msg, 20, 0),
    rating: (f = msg.getRating()) && proto.profile.ServiceRating.toObject(includeInstance, f),
    stopFlag: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    instantOnlyFlag: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    fullCorrespondenceFlag: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    serviceImage: jspb.Message.getFieldWithDefault(msg, 27, ""),
    providerSiteMovieId: jspb.Message.getFieldWithDefault(msg, 28, ""),
    isMovieThumbnail: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    masterCategory: (f = msg.getMasterCategory()) && proto.profile.ServiceCategory.toObject(includeInstance, f),
    certificationDivinationFlag: jspb.Message.getBooleanFieldWithDefault(msg, 31, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.Service}
 */
proto.profile.Service.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.Service;
  return proto.profile.Service.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.Service} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.Service}
 */
proto.profile.Service.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOverview(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCatchphrase(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHead(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUnitTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrialCount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpenDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainImageUrl(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceClass(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFavoriteCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerformanceCount(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVideoFlag(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProFlag(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustomizeFlag(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavoriteFlag(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModified(value);
      break;
    case 22:
      var value = new proto.profile.ServiceRating;
      reader.readMessage(value,proto.profile.ServiceRating.deserializeBinaryFromReader);
      msg.setRating(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStopFlag(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInstantOnlyFlag(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFullCorrespondenceFlag(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceImage(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderSiteMovieId(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMovieThumbnail(value);
      break;
    case 30:
      var value = new proto.profile.ServiceCategory;
      reader.readMessage(value,proto.profile.ServiceCategory.deserializeBinaryFromReader);
      msg.setMasterCategory(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCertificationDivinationFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.Service.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.Service.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.Service} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.Service.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOverview();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCatchphrase();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHead();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUnitTime();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTrialCount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getOpenDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getMainImageUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getServiceClass();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getFavoriteCount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getPerformanceCount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getVideoFlag();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getProFlag();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getCustomizeFlag();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getFavoriteFlag();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getModified();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getRating();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.profile.ServiceRating.serializeBinaryToWriter
    );
  }
  f = message.getStopFlag();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getInstantOnlyFlag();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getFullCorrespondenceFlag();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getServiceImage();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getProviderSiteMovieId();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getIsMovieThumbnail();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getMasterCategory();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.profile.ServiceCategory.serializeBinaryToWriter
    );
  }
  f = message.getCertificationDivinationFlag();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profile.Service.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.profile.Service.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string overview = 3;
 * @return {string}
 */
proto.profile.Service.prototype.getOverview = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setOverview = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string catchphrase = 4;
 * @return {string}
 */
proto.profile.Service.prototype.getCatchphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setCatchphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string head = 5;
 * @return {string}
 */
proto.profile.Service.prototype.getHead = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setHead = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 unit_time = 6;
 * @return {number}
 */
proto.profile.Service.prototype.getUnitTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setUnitTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float price = 7;
 * @return {number}
 */
proto.profile.Service.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 trial_count = 8;
 * @return {number}
 */
proto.profile.Service.prototype.getTrialCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setTrialCount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 open_date = 9;
 * @return {number}
 */
proto.profile.Service.prototype.getOpenDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setOpenDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string main_image_url = 10;
 * @return {string}
 */
proto.profile.Service.prototype.getMainImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setMainImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 service_class = 11;
 * @return {number}
 */
proto.profile.Service.prototype.getServiceClass = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setServiceClass = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 favorite_count = 12;
 * @return {number}
 */
proto.profile.Service.prototype.getFavoriteCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setFavoriteCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 version = 13;
 * @return {number}
 */
proto.profile.Service.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 performance_count = 14;
 * @return {number}
 */
proto.profile.Service.prototype.getPerformanceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setPerformanceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool video_flag = 15;
 * @return {boolean}
 */
proto.profile.Service.prototype.getVideoFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setVideoFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool pro_flag = 16;
 * @return {boolean}
 */
proto.profile.Service.prototype.getProFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setProFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool customize_flag = 17;
 * @return {boolean}
 */
proto.profile.Service.prototype.getCustomizeFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setCustomizeFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool favorite_flag = 18;
 * @return {boolean}
 */
proto.profile.Service.prototype.getFavoriteFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setFavoriteFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional int64 created = 19;
 * @return {number}
 */
proto.profile.Service.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 modified = 20;
 * @return {number}
 */
proto.profile.Service.prototype.getModified = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setModified = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional ServiceRating rating = 22;
 * @return {?proto.profile.ServiceRating}
 */
proto.profile.Service.prototype.getRating = function() {
  return /** @type{?proto.profile.ServiceRating} */ (
    jspb.Message.getWrapperField(this, proto.profile.ServiceRating, 22));
};


/**
 * @param {?proto.profile.ServiceRating|undefined} value
 * @return {!proto.profile.Service} returns this
*/
proto.profile.Service.prototype.setRating = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.clearRating = function() {
  return this.setRating(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profile.Service.prototype.hasRating = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional bool stop_flag = 23;
 * @return {boolean}
 */
proto.profile.Service.prototype.getStopFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setStopFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool instant_only_flag = 24;
 * @return {boolean}
 */
proto.profile.Service.prototype.getInstantOnlyFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setInstantOnlyFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool full_correspondence_flag = 26;
 * @return {boolean}
 */
proto.profile.Service.prototype.getFullCorrespondenceFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setFullCorrespondenceFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional string service_image = 27;
 * @return {string}
 */
proto.profile.Service.prototype.getServiceImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setServiceImage = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string provider_site_movie_id = 28;
 * @return {string}
 */
proto.profile.Service.prototype.getProviderSiteMovieId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setProviderSiteMovieId = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional bool is_movie_thumbnail = 29;
 * @return {boolean}
 */
proto.profile.Service.prototype.getIsMovieThumbnail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setIsMovieThumbnail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional ServiceCategory master_category = 30;
 * @return {?proto.profile.ServiceCategory}
 */
proto.profile.Service.prototype.getMasterCategory = function() {
  return /** @type{?proto.profile.ServiceCategory} */ (
    jspb.Message.getWrapperField(this, proto.profile.ServiceCategory, 30));
};


/**
 * @param {?proto.profile.ServiceCategory|undefined} value
 * @return {!proto.profile.Service} returns this
*/
proto.profile.Service.prototype.setMasterCategory = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.clearMasterCategory = function() {
  return this.setMasterCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profile.Service.prototype.hasMasterCategory = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional bool certification_divination_flag = 31;
 * @return {boolean}
 */
proto.profile.Service.prototype.getCertificationDivinationFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.Service} returns this
 */
proto.profile.Service.prototype.setCertificationDivinationFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.ProviderStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.ProviderStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.ProviderStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ProviderStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneWait: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    phoneTalking: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.ProviderStatus}
 */
proto.profile.ProviderStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.ProviderStatus;
  return proto.profile.ProviderStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.ProviderStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.ProviderStatus}
 */
proto.profile.ProviderStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhoneWait(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhoneTalking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.ProviderStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.ProviderStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.ProviderStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ProviderStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneWait();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPhoneTalking();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool phone_wait = 1;
 * @return {boolean}
 */
proto.profile.ProviderStatus.prototype.getPhoneWait = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.ProviderStatus} returns this
 */
proto.profile.ProviderStatus.prototype.setPhoneWait = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool phone_talking = 2;
 * @return {boolean}
 */
proto.profile.ProviderStatus.prototype.getPhoneTalking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profile.ProviderStatus} returns this
 */
proto.profile.ProviderStatus.prototype.setPhoneTalking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.GetServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.GetServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.GetServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    excludeServiceId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    userAction: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.GetServicesRequest}
 */
proto.profile.GetServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.GetServicesRequest;
  return proto.profile.GetServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.GetServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.GetServicesRequest}
 */
proto.profile.GetServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExcludeServiceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserAction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.GetServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.GetServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.GetServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExcludeServiceId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUserAction();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.profile.GetServicesRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.GetServicesRequest} returns this
 */
proto.profile.GetServicesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 exclude_service_id = 2;
 * @return {number}
 */
proto.profile.GetServicesRequest.prototype.getExcludeServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.GetServicesRequest} returns this
 */
proto.profile.GetServicesRequest.prototype.setExcludeServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string user_action = 3;
 * @return {string}
 */
proto.profile.GetServicesRequest.prototype.getUserAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profile.GetServicesRequest} returns this
 */
proto.profile.GetServicesRequest.prototype.setUserAction = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.GetMyServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.GetMyServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.GetMyServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetMyServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    excludeServiceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.GetMyServicesRequest}
 */
proto.profile.GetMyServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.GetMyServicesRequest;
  return proto.profile.GetMyServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.GetMyServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.GetMyServicesRequest}
 */
proto.profile.GetMyServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExcludeServiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.GetMyServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.GetMyServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.GetMyServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.GetMyServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExcludeServiceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 exclude_service_id = 1;
 * @return {number}
 */
proto.profile.GetMyServicesRequest.prototype.getExcludeServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profile.GetMyServicesRequest} returns this
 */
proto.profile.GetMyServicesRequest.prototype.setExcludeServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profile.ServicesReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profile.ServicesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profile.ServicesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profile.ServicesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ServicesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerStatus: (f = msg.getProviderStatus()) && proto.profile.ProviderStatus.toObject(includeInstance, f),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.profile.Service.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profile.ServicesReply}
 */
proto.profile.ServicesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profile.ServicesReply;
  return proto.profile.ServicesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profile.ServicesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profile.ServicesReply}
 */
proto.profile.ServicesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.profile.ProviderStatus;
      reader.readMessage(value,proto.profile.ProviderStatus.deserializeBinaryFromReader);
      msg.setProviderStatus(value);
      break;
    case 2:
      var value = new proto.profile.Service;
      reader.readMessage(value,proto.profile.Service.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profile.ServicesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profile.ServicesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profile.ServicesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profile.ServicesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.profile.ProviderStatus.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.profile.Service.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProviderStatus provider_status = 1;
 * @return {?proto.profile.ProviderStatus}
 */
proto.profile.ServicesReply.prototype.getProviderStatus = function() {
  return /** @type{?proto.profile.ProviderStatus} */ (
    jspb.Message.getWrapperField(this, proto.profile.ProviderStatus, 1));
};


/**
 * @param {?proto.profile.ProviderStatus|undefined} value
 * @return {!proto.profile.ServicesReply} returns this
*/
proto.profile.ServicesReply.prototype.setProviderStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profile.ServicesReply} returns this
 */
proto.profile.ServicesReply.prototype.clearProviderStatus = function() {
  return this.setProviderStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profile.ServicesReply.prototype.hasProviderStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Service services = 2;
 * @return {!Array<!proto.profile.Service>}
 */
proto.profile.ServicesReply.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.profile.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.profile.Service, 2));
};


/**
 * @param {!Array<!proto.profile.Service>} value
 * @return {!proto.profile.ServicesReply} returns this
*/
proto.profile.ServicesReply.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.profile.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profile.Service}
 */
proto.profile.ServicesReply.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.profile.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profile.ServicesReply} returns this
 */
proto.profile.ServicesReply.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


goog.object.extend(exports, proto.profile);
