import { Middleware } from '@nuxt/types'
import { isUnavailableCoconalaAppVersion } from '~/components/logics/check-unavailable-coconala-app-version'
import {
  JOB_MATCHING_SUPPORTED_IOS_VERSION,
  JOB_MATCHING_SUPPORTED_ANDROID_VERSION
} from '~/constants/coconala-app-version'

// アプリの旧バージョンが対応していないページに含まれるパス文字列
const REQUIRED_UPDATE_COCONALA_APP_VERSION_PATH_STRING = 'job_matching'

const requiredUpdateCoconalaAppVersionForJobMatching: Middleware = ({
  store,
  route,
  $util,
  error
}) => {
  if (
    route.path.indexOf(REQUIRED_UPDATE_COCONALA_APP_VERSION_PATH_STRING) !== -1 &&
    isUnavailableCoconalaAppVersion(store, {
      availableCoconalaIOSEarliestVersion: JOB_MATCHING_SUPPORTED_IOS_VERSION,
      availableCoconalaAndroidEarliestVersion: JOB_MATCHING_SUPPORTED_ANDROID_VERSION
    })
  ) {
    error({
      title: 'お使いのバージョンでは、<br>この機能はご利用いただけません',
      showsMessage: true,
      message: 'アプリのアップデートまたは、\nウェブ版でのご利用をお願いいたします。',
      imgType: 'warning',
      buttonText: 'アプリをアップデートする',
      buttonRightIcon: 'open-in-new',
      url: $util.genAppStoreUrl(store.getters['auth/isFromCoconalaIOS'])
    })
  }
}
export default requiredUpdateCoconalaAppVersionForJobMatching
