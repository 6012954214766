import { botPattern } from '~/components/logics/platform'
import { translateTargetPage } from '~/components/logics/translate/target-page'
import { LANGUAGES, DEFAULT_LANGUAGE_KEY, SETTING_LANGUAGE_KEY } from '~/constants/language'
import TranslateAccessor from '~/api/accessors/translate/translate-accessor'

export default ({ store, app, route, res, req }) => {
  // SSR時だと、本処理が２回走り２回目のroute.nameがnullになるのでその対応
  if (!route.name) return

  const sessionMaxAge = 60 * 60 * 24 * 365 * 10 // 10年
  // 言語設定
  let currentLangCode = LANGUAGES.JA.code
  const cookieSettingLangCode = app.$cookies.get(SETTING_LANGUAGE_KEY)
  const userLangCode = store.getters['my/header/getCurrentLangCode']
  const isFromCoconalaApp = store.getters['auth/isFromCoconalaApp']
  const userAgent = store.state?.auth?.ua || ''
  const isBot = botPattern.test(userAgent)

  const getExchangeRate = () => {
    if (store.state.translate.hasOwnProperty('exchangeRateDomainsList')) return
    const apiAccessor = new TranslateAccessor(store.state)
    const cache = app.context.ssrContext?.$caches?.exchangeRate || null
    apiAccessor
      .getListExchangeRate(cache)
      .then(response => {
        store.commit('translate/SET_EXCHANGE_RATE', response)
      })
      .catch(() => {
        // エラーになった場合握りつぶす
        return null
      })
  }

  // 翻訳対象ページでない場合 or WebView or Botは、「ja」を無理やりセットして日本語表示
  if (translateTargetPage(route.name) && !isFromCoconalaApp && !isBot) {
    // 言語の優先順位は「DBに登録されている言語 > Cookieに登録されている言語 > 日本語」
    currentLangCode = userLangCode || cookieSettingLangCode || LANGUAGES.JA.code

    if (!cookieSettingLangCode || currentLangCode !== cookieSettingLangCode) {
      // SETTING_LANGUAGE_KEYが存在しないならCake用にCookieをセットする必要がある
      app.$cookies.set(SETTING_LANGUAGE_KEY, currentLangCode, {
        path: '/',
        maxAge: sessionMaxAge
      })
    }

    if (process.server && currentLangCode !== LANGUAGES.JA.code) {
      // 言語設定が日本語以外のユーザーの場合は、ブラウザバックにも対応するようにキャッシュしない
      res.setHeader('Cache-Control', 'no-cache, no-store')
      // 決済表記用の為替レートを取得する
      getExchangeRate()
    }
  } else if (isBot) {
    // ボットだったらSETTING_LANGUAGE_KEYを無理やり日本語に変更
    app.$cookies.set(SETTING_LANGUAGE_KEY, LANGUAGES.JA.code, {
      path: '/',
      maxAge: sessionMaxAge
    })
  }

  app.$cookies.set(DEFAULT_LANGUAGE_KEY, currentLangCode, {
    path: '/',
    maxAge: sessionMaxAge
  })
  store.commit('translate/SET_LANGUAGE', currentLangCode)
  return
}
