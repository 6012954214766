export const state = () => ({
  /** 「最新のメッセージに移動」ボタンの表示制御フラグ */
  isShownLastMessageButton: true,
  /** ビデオチャットが開始されたトークルームID */
  startedVideochatTalkroomId: null,
  /** 電話開始予定時刻を迎えたトークルームID */
  startedPhoneTalkroomId: null,
  /** メッセージが更新されたトークルームID */
  updatedMessageTalkroomId: null,
  /** メッセージの送信が可能なトークルームが表示されているか */
  hasMessageSendableTalkroom: false
})

// getters
export const getters = {}

// actions
export const actions = {}

// mutations
export const mutations = {
  SET_IS_SHOWN_LAST_MESSAGE_BUTTON(state, isShownLastMessageButton) {
    state.isShownLastMessageButton = isShownLastMessageButton
  },
  SET_STARTED_VIDEOCHAT_TALKROOM_ID(state, startedVideochatTalkroomId) {
    state.startedVideochatTalkroomId = startedVideochatTalkroomId
  },
  SET_STARTED_PHONE_TALKROOM_ID(state, startedPhoneTalkroomId) {
    state.startedPhoneTalkroomId = startedPhoneTalkroomId
  },
  SET_UPDATED_MESSAGE_TALKROOM_ID(state, updatedMessageTalkroomId) {
    state.updatedMessageTalkroomId = updatedMessageTalkroomId
  },
  SET_HAS_MESSAGE_SENDABLE_TALKROOM(state, hasMessageSendableTalkroom) {
    state.hasMessageSendableTalkroom = hasMessageSendableTalkroom
  }
}
