export const state = () => ({
  ccuid: '',
  type: '',
  selectedPurpose: 0,
  selectedPrivateCorporation: 0,
  isMzhc: false
})

export const actions = {}

export const mutations = {
  updateSelectedPurpose(state, value) {
    state.selectedPurpose = value
  },
  updateSelectedPrivateCorporation(state, value) {
    state.selectedPrivateCorporation = value
  },
  updateCcuid(state, value) {
    state.ccuid = value
  },
  updateType(state, value) {
    state.type = value
  },
  setIsMzhc(state, value) {
    state.isMzhc = value
  }
}
