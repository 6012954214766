// package: profile
// file: apigateway/profile/profile.proto

import * as apigateway_profile_profile_pb from "../../apigateway/profile/profile_pb";
import * as profileservice_profile_pb from "../../profileservice/profile_pb";
export class ProfileService {
  static serviceName = "profile.ProfileService";
}
export namespace ProfileService {
  export class GetProfile {
    static readonly methodName = "GetProfile";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.GetProfileRequest;
    static readonly responseType = apigateway_profile_profile_pb.ProfileReply;
  }
  export class GetMyProfile {
    static readonly methodName = "GetMyProfile";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.GetMyProfileRequest;
    static readonly responseType = apigateway_profile_profile_pb.ProfileReply;
  }
  export class UpdateProfile {
    static readonly methodName = "UpdateProfile";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.UpdateProfileRequest;
    static readonly responseType = apigateway_profile_profile_pb.ProfileReply;
  }
  export class UpdateCoverImage {
    static readonly methodName = "UpdateCoverImage";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.UpdateCoverImageRequest;
    static readonly responseType = apigateway_profile_profile_pb.ProfileReply;
  }
  export class UpdateOccupation {
    static readonly methodName = "UpdateOccupation";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.UpdateOccupationRequest;
    static readonly responseType = apigateway_profile_profile_pb.ProfileReply;
  }
  export class UpdateIsDisplayAge {
    static readonly methodName = "UpdateIsDisplayAge";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.UpdateIsDisplayAgeRequest;
    static readonly responseType = apigateway_profile_profile_pb.ProfileReply;
  }
  export class DeleteCoverImage {
    static readonly methodName = "DeleteCoverImage";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.DeleteCoverImageRequest;
    static readonly responseType = apigateway_profile_profile_pb.ProfileReply;
  }
  export class GetHasOldRecords {
    static readonly methodName = "GetHasOldRecords";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.GetHasOldRecordsRequest;
    static readonly responseType = apigateway_profile_profile_pb.GetHasOldRecordsResponse;
  }
  export class DeleteOldRecords {
    static readonly methodName = "DeleteOldRecords";
    static readonly service = ProfileService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_profile_pb.DeleteOldRecordsRequest;
    static readonly responseType = apigateway_profile_profile_pb.DeleteOldRecordsResponse;
  }
}
