<template>
  <div
    id="vueport"
    :class="breakpointType"
  ></div>
</template>
<script>
export default {
  name: 'TheVueport',
  props: {
    breakpointType: {
      type: String,
      default: 'vueportDefault'
    }
  },
  data() {
    return {
      size: null,
      width: 'auto'
    }
  },
  mounted() {
    this.setViewport()
    window.addEventListener('resize', this.setViewport, false)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setViewport, false)
  },
  methods: {
    setViewport() {
      this.size = this.getStyleValue('font-family')
      this.width = this.getStyleValue('width').replace('px', '')
    },
    getStyleValue(targetProperty) {
      return window.getComputedStyle(this.$el).getPropertyValue(targetProperty)
    }
  },
  watch: {
    size(v) {
      if (!v) {
        return
      }
      this.$store.dispatch('ui/syncViewport', {
        size: v,
        width: this.width
      })
    }
  },
  computed: {
    viewport() {
      return this.$store.state.ui.viewport
    }
  }
}
</script>
<style lang="scss" scoped>
#vueport {
  display: none;
  width: 100%;
  font-family: FULL; // 'FULL'だとIE, Edgeのdevelopment buildで動作しない

  @each $type in $breakpointTypes {
    &.vueport#{$type} {
      @each $size in $breakpointSizes {
        @media (max-width: breakpoint(#{$type}, #{$size})) {
          width: breakpoint(#{$type}, #{$size});
          font-family: #{$size};
        }
      }
    }
  }
}
</style>
