import { commonMetaData } from '~/store/_helpers/common-helper'
import { toInt32Value } from '~/grpc/grpc-util'
import grpcClient from '~/grpc/grpc-client'

// initial state
export const state = () => ({
  starsList: [],
  overallRating: {},
  ratingSummaryList: [],
  ratingCommentsList: [],
  page: null,
  perPage: null,
  totalCount: null
})

// getters
export const getters = {}

// actions
export const actions = {
  async getRatingSummary({ commit, rootState }, { params }) {
    const [{ GetServiceRatingSummaryRequest }, { ServiceService }] = await Promise.all([
      import('~/stub/gw/service/service_pb'),
      import('~/stub/gw/service/service_pb_service')
    ])
    const request = new GetServiceRatingSummaryRequest()
    const metadata = commonMetaData(rootState)
    request.setServiceId(toInt32Value(params.id))

    const serviceServiceRatingSummarysReply = await grpcClient({
      method: ServiceService.GetServiceRatingSummary,
      request,
      metadata,
      strip: true
    })

    commit('RECEIVE', serviceServiceRatingSummarysReply)
  },
  async getRatingComments({ commit, rootState }, { params, page, star }) {
    const [{ GetServiceRatingsRequest }, { ServiceService }] = await Promise.all([
      import('~/stub/gw/service/service_pb'),
      import('~/stub/gw/service/service_pb_service')
    ])
    const request = new GetServiceRatingsRequest()
    const metadata = commonMetaData(rootState)
    request.setServiceId(toInt32Value(params.id))
    if (page) {
      request.setPage(toInt32Value(page))
    }
    if (star) {
      request.setStar(toInt32Value(star))
    }

    const getRatingCommentsReply = await grpcClient({
      method: ServiceService.GetServiceRatings,
      request,
      metadata,
      strip: true
    })

    const commitType = page ? 'RECEIVE_MORE_RATINGS' : 'RECEIVE'
    commit(commitType, getRatingCommentsReply)
  }
}

// mutations
export const mutations = {
  RECEIVE(state, data) {
    Object.assign(state, data)
  },
  RECEIVE_MORE_RATINGS(state, data) {
    state.page = data.page
    state.ratingCommentsList = state.ratingCommentsList.concat(data.ratingCommentsList)
  }
}
