import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'

// initial state
const initialState = () => ({
  account: {
    businessCorporation: {
      id: null,
      enterpriseName: '',
      enterpriseNameKana: '',
      representativeFirstName: '',
      representativeFirstNameKana: '',
      representativeLastName: '',
      representativeLastNameKana: '',
      postalCode: '',
      prefecture: null,
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      typeOfIndustry: null,
      employeeNumber: null,
      url: '',
      annualSales: null,
      description: '',
      logoFileName: '',
      logoFileUrl: '',
      logoMime: '',
      logoSize: null,
      enterpriseTelNumber: ''
    },
    businessUser: {
      applicantLastName: '',
      applicantFirstName: '',
      applicantLastNameKana: '',
      applicantFirstNameKana: '',
      sex: '',
      isQuestionnaireFinished: false,
      mobileTelNumber: ''
    },
    privateUser: {
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: ''
    }
  },
  pulldownMaster: {
    annualSalesList: [],
    employeeNumbersList: [],
    prefecturesList: [],
    purposesOfUsesList: [],
    reasonOfRegistrationsList: [],
    typeOfIndustriesList: []
  }
})

export const state = () => initialState()

export const getters = {}

export const actions = {
  async fetchMyAccount({ commit, rootState }) {
    const businessMasterRequestModule = await import(
      '~/stub/apigateway/business/business_master_pb'
    )
    const businessMasterMethodModule = await import(
      '~/stub/apigateway/business/business_master_pb_service'
    )
    const businessRegistrationRequestModule = await import(
      '~/stub/apigateway/business/business_registration_pb'
    )
    const businessRegistrationMethodModule = await import(
      '~/stub/apigateway/business/business_registration_pb_service'
    )
    const setMetadata = commonMetaData(rootState)
    const responses = Object.fromEntries(
      await Promise.all([
        grpcClient({
          method: businessMasterMethodModule.BusinessMasterService.GetPulldownMaster,
          request: new businessMasterRequestModule.GetPulldownMasterRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['pulldownMaster', reply]
          }
        }),
        grpcClient({
          method: businessRegistrationMethodModule.BusinessRegistrationService.GetAccount,
          request: new businessRegistrationRequestModule.GetAccountRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['account', reply]
          }
        })
      ])
    )
    commit('SET_PULLDOWN_MASTER', responses.pulldownMaster)
    commit('SET_ACCOUNT', responses.account)
  },
  async updateCorporation({ rootState }, { enterprise_registration_id, userInput }) {
    const requestModule = await import('~/stub/apigateway/business/business_registration_pb')
    const methodModule = await import('~/stub/apigateway/business/business_registration_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.UpdateBusinessCorporationRequest()
    const businessCorporation = new requestModule.BusinessCorporation()
    businessCorporation.setId(enterprise_registration_id)
    businessCorporation.setEnterpriseName(userInput.enterpriseName)
    businessCorporation.setEnterpriseNameKana(userInput.enterpriseNameKana)
    businessCorporation.setRepresentativeLastName(userInput.representativeLastName)
    businessCorporation.setRepresentativeLastNameKana(userInput.representativeLastNameKana)
    businessCorporation.setRepresentativeFirstName(userInput.representativeFirstName)
    businessCorporation.setRepresentativeFirstNameKana(userInput.representativeFirstNameKana)
    businessCorporation.setPostalCode(userInput.postalCode)
    businessCorporation.setPrefecture(userInput.prefecture)
    businessCorporation.setAddress1(userInput.address1)
    businessCorporation.setAddress2(userInput.address2)
    businessCorporation.setAddress3(userInput.address3)
    businessCorporation.setAddress4(userInput.address4)
    businessCorporation.setTypeOfIndustry(userInput.typeOfIndustry)
    businessCorporation.setEmployeeNumber(userInput.employeeNumber)
    businessCorporation.setUrl(userInput.url)
    businessCorporation.setAnnualSales(userInput.annualSales)
    businessCorporation.setDescription(userInput.description)
    businessCorporation.setLogoFileName(userInput.logoFileName)
    businessCorporation.setLogoFileUrl(userInput.logoFileUrl)
    businessCorporation.setLogoMime(userInput.logoMime)
    businessCorporation.setLogoSize(userInput.logoSize)
    businessCorporation.setEnterpriseTelNumber(userInput.enterpriseTelNumber)
    request.setBusinessCorporation(businessCorporation)
    await grpcClient({
      method: methodModule.BusinessRegistrationService.UpdateBusinessCorporation,
      request,
      metadata
    })
  },
  async updateUser({ rootState }, { userInput }) {
    const requestModule = await import('~/stub/apigateway/business/business_registration_pb')
    const methodModule = await import('~/stub/apigateway/business/business_registration_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.UpdateBusinessUserRequest()
    const businessUser = new requestModule.BusinessUser()
    businessUser.setApplicantLastName(userInput.applicantLastName)
    businessUser.setApplicantFirstName(userInput.applicantFirstName)
    businessUser.setApplicantLastNameKana(userInput.applicantLastNameKana)
    businessUser.setApplicantFirstNameKana(userInput.applicantFirstNameKana)
    businessUser.setMobileTelNumber(userInput.mobileTelNumber)
    request.setBusinessUser(businessUser)
    await grpcClient({
      method: methodModule.BusinessRegistrationService.UpdateBusinessUser,
      request,
      metadata
    })
  },
  async deleteAccount({ commit, rootState }, { id }) {
    const requestModule = await import('~/stub/apigateway/enterprise/member_pb')
    const methodModule = await import('~/stub/apigateway/enterprise/member_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.DeleteMemberRequest()
    request.setId(id)
    request.setIsFromSettings(true)
    await grpcClient({
      method: methodModule.MemberService.DeleteMember,
      request,
      metadata
    })
  }
}

export const mutations = {
  SET_ACCOUNT(state, account) {
    // asyncDataで初期値を取得すると、intの場合、値がなければ初期値0が入ってしまうため
    Object.keys(account.businessCorporation).forEach(key => {
      if (account.businessCorporation[key]) {
        state.account.businessCorporation[key] = account.businessCorporation[key]
      }
    })
    Object.keys(account.businessUser).forEach(key => {
      if (account.businessUser[key]) {
        state.account.businessUser[key] = account.businessUser[key]
      }
    })
    Object.keys(account.privateUser).forEach(key => {
      if (account.privateUser[key]) {
        state.account.privateUser[key] = account.privateUser[key]
      }
    })
  },
  SET_PULLDOWN_MASTER(state, pulldownMaster) {
    Object.assign(state.pulldownMaster, pulldownMaster)
  }
}
