// package: profile
// file: apigateway/profile/service.proto

import * as apigateway_profile_service_pb from "../../apigateway/profile/service_pb";
export class ServiceService {
  static serviceName = "profile.ServiceService";
}
export namespace ServiceService {
  export class GetServices {
    static readonly methodName = "GetServices";
    static readonly service = ServiceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_service_pb.GetServicesRequest;
    static readonly responseType = apigateway_profile_service_pb.ServicesReply;
  }
  export class GetMyServices {
    static readonly methodName = "GetMyServices";
    static readonly service = ServiceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_service_pb.GetMyServicesRequest;
    static readonly responseType = apigateway_profile_service_pb.ServicesReply;
  }
}
