var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OField',_vm._g(_vm._b({class:{
    '-required': _vm.required,
    '-either-required': _vm.eitherRequired,
    '-has-error': !!_vm.errorMessage,
    '-is-small-label': _vm.labelSmall,
    '-is-no-margin': _vm.noMargin,
    'is-expanded': _vm.expanded
  },attrs:{"variant":_vm.errorMessage ? 'danger' : '',"message":_vm.errorMessage ? _vm.errorMessage : _vm.$attrs.message}},'OField',_vm.$attrs,false),_vm.$listeners),[_vm._l((Object.keys(_vm.$slots)),function(slotName){return _vm._t(slotName,null,{"slot":slotName})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }