// package: common
// file: apigateway/common/file_upload.proto

import * as apigateway_common_file_upload_pb from "../../apigateway/common/file_upload_pb";
export class FileUploadService {
  static serviceName = "common.FileUploadService";
}
export namespace FileUploadService {
  export class GetUploadInfo {
    static readonly methodName = "GetUploadInfo";
    static readonly service = FileUploadService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_common_file_upload_pb.GetUploadInfoRequest;
    static readonly responseType = apigateway_common_file_upload_pb.UploadInfoReply;
  }
}
