import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { ProtobufMessage } from '@improbable-eng/grpc-web/dist/typings/message'
import { MethodDefinition } from '@improbable-eng/grpc-web/dist/typings/service'
import Util from '~/assets/javascripts/util'
import { Stripped, stripValue } from './grpc-util'

type GrpcClientContext<TRequest extends ProtobufMessage, TResponse extends ProtobufMessage> = {
  method: MethodDefinition<TRequest, TResponse>
  request: TRequest
  host?: string
  metadata?: any
  transport?: grpc.TransportFactory
  cache?: any
  cacheKey?: string
  transformReply?: (reply: any) => typeof reply
}

type StripEnabledGrpcClientContext<
  TRequest extends ProtobufMessage,
  TResponse extends ProtobufMessage
> = GrpcClientContext<TRequest, TResponse> & { strip: true }

type PostMessageParams = {
  methodFullname?: string
  request?: any
  response?: any
  error?: any
}

type PostMessage = {
  (params: PostMessageParams): void
}

/** gRPC-webのChrome拡張にログ出力 */
let postMessage: PostMessage = ({ methodFullname, request, response, error }) => {}
if (process.env.config.enableGrpcWebDebug && process.client) {
  postMessage = ({ methodFullname, request, response, error }) => {
    window.postMessage(
      {
        type: '__GRPCWEB_DEVTOOLS__',
        method: methodFullname,
        methodType: 'server_streaming',
        request,
        response,
        error
      },
      '*'
    )
  }
}

type GrpcClient = {
  <TRequest extends ProtobufMessage, TResponse extends ProtobufMessage>(
    context: GrpcClientContext<TRequest, TResponse>
  ): Promise<ReturnType<TResponse['toObject']>>
  <TRequest extends ProtobufMessage, TResponse extends ProtobufMessage>(
    context: StripEnabledGrpcClientContext<TRequest, TResponse>
  ): Promise<Stripped<ReturnType<TResponse['toObject']>>>
}

/**
 * **Grpcクライアント**
 * - grpc.invokeのonMessageコールバック引数をtoObject()したものをPromiseで返す
 *
 * @param context Grpcクライアントコンテキスト(メソッド、リクエストなどを指定)
 */
// @ts-ignore TS2322
const grpcClient: GrpcClient = (context: Parameters<GrpcClient>[0]) => {
  const { method, host, request, metadata, transport, cache, cacheKey, transformReply, strip } =
    context
  const methodFullname = `${method.service.serviceName}.${method.methodName}`
  const _cacheKey = `${methodFullname}#${cacheKey || ''}`

  postMessage({
    methodFullname,
    request: request.toObject()
  })

  // キャッシュ指定あり＆キャッシュヒットするなら即時に返す
  if (cache) {
    const cachedData = cache.get(_cacheKey)
    if (cachedData) {
      postMessage({
        methodFullname,
        response: {
          __INFO__: {
            respondFromCache: true
          },
          ...cachedData
        }
      })
      return Promise.resolve(cachedData)
    }
  }

  return new Promise((resolve, reject) => {
    const grpcHost = process.server
      ? process.env.config.internalGrpcWebUrl
      : process.env.config.grpcWebUrl

    grpc.invoke(method, {
      request,
      host: host || grpcHost,
      transport: transport || NodeHttpTransport(),
      metadata,
      onMessage: message => {
        const messageObj = strip ? stripValue(message.toObject()) : message.toObject()
        const reply = transformReply ? transformReply(messageObj) : messageObj
        // キャッシュ指定ありならキャッシュ
        if (cache) {
          cache.set(_cacheKey, reply)
        }
        postMessage({
          methodFullname,
          response: {
            __INFO__: {
              respondFromCache: false,
              transformed: !!transformReply
            },
            ...reply
          }
        })
        resolve(reply)
      },
      onEnd: (grpcCode, message, trailers) => {
        if (grpcCode !== grpc.Code.OK) {
          const statusCode = Util.grpcCodeToHttpStatusCode(grpcCode)
          const requestObject = request.toObject()
          if (process.env.NODE_ENV !== 'production') {
            postMessage({
              methodFullname,
              error: {
                statusCode,
                grpcCode,
                message,
                trailers,
                request: requestObject
              }
            })
          }
          reject({
            statusCode,
            grpcCode,
            message,
            trailers,
            method: methodFullname,
            request: requestObject
          })
        }
      }
    })
  })
}
export default grpcClient
