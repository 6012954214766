import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'
import {
  prefectureTextGetter,
  sexTextGetter,
  initialEnterpriseinfo,
  initialUserState
} from '~/store/_helpers/user-helper'

const ENTERPRISE_ADMIN_ROLE = 1
const ENTERPRISE_STAFF_ROLE = 2
const ENTERPRISE_ROLES = [
  {
    code: ENTERPRISE_ADMIN_ROLE,
    name: '管理者'
  },
  {
    code: ENTERPRISE_STAFF_ROLE,
    name: 'メンバー'
  }
]

const ENTERPRISE_INVOICE_NOT_APPLYING = -1
const ENTERPRISE_INVOICE_APPLYING = [1, 2]
const ENTERPRISE_INVOICE_AVAILABLE = 3
const ENTERPRISE_INVOICE_REJECTED = [4, 5]
const ENTERPRISE_INVOICE_WITHDRAWAL = 6

const BUSINESS_SETTINGS_INVOICE_NOT_APPLYING = [-1, 4, 5, 6]

export const state = () => initialUserState()

// getters
export const getters = {
  prefectureText(state, getters, globalState) {
    return prefectureTextGetter(state, getters, globalState)
  },
  sexText(state, getters, globalState) {
    return sexTextGetter(state, getters, globalState)
  },
  isEnterpriseMember(state) {
    return !!state.enterpriseInfo.id
  },
  isEnterpriseAdmin(state) {
    return state.enterpriseInfo.role === ENTERPRISE_ADMIN_ROLE
  },
  isEnterpriseStaff(state) {
    return state.enterpriseInfo.role === ENTERPRISE_STAFF_ROLE
  },
  enterpriseRoleName: () => roleCode => {
    const filtered = ENTERPRISE_ROLES.filter(r => r.code === roleCode)
    return filtered.length ? filtered[0].name : ''
  },
  ENTERPRISE_ROLES() {
    return ENTERPRISE_ROLES
  },
  ENTERPRISE_STAFF_ROLE() {
    return ENTERPRISE_STAFF_ROLE
  },
  isInvoiceNotApplying(state) {
    return state.enterpriseInfo.status === ENTERPRISE_INVOICE_NOT_APPLYING
  },
  isInvoiceApplying(state) {
    return ENTERPRISE_INVOICE_APPLYING.includes(state.enterpriseInfo.status)
  },
  isInvoiceAvailable(state) {
    return state.enterpriseInfo.status === ENTERPRISE_INVOICE_AVAILABLE
  },
  isInvoiceRejected(state) {
    return ENTERPRISE_INVOICE_REJECTED.includes(state.enterpriseInfo.status)
  },
  isInvoiceWithdrawal(state) {
    return state.enterpriseInfo.status === ENTERPRISE_INVOICE_WITHDRAWAL
  },
  // 設定ページで利用（法人機能の利用申請ができる状態かどうかを判定する）
  isBusinessSettingsInvoiceNotApplying(state) {
    return BUSINESS_SETTINGS_INVOICE_NOT_APPLYING.includes(state.enterpriseInfo.status)
  }
}

// actions
export const actions = {
  async fetchAll({ commit, rootState }) {
    const requestReply = await new Promise(async (resolve, reject) => {
      const [{ GetUserRequest }, { UserService }] = await Promise.all([
        import('~/stub/apigateway/profile/user_pb'),
        import('~/stub/apigateway/profile/user_pb_service')
      ])
      const getUserRequest = new GetUserRequest()
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(UserService.GetMyUser, {
        request: getUserRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject().user)
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })

    commit('SET', requestReply)
  },
  async getBlackList({ rootState }) {
    return await new Promise(async (resolve, reject) => {
      const [{ GetBlackListRequest }, { UserService }] = await Promise.all([
        import('~/stub/apigateway/user/user_pb'),
        import('~/stub/apigateway/user/user_pb_service')
      ])
      const request = new GetBlackListRequest()
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(UserService.GetBlackList, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  },
  async getBloggerRegistrationStatus({ rootState }) {
    return await new Promise(async (resolve, reject) => {
      const [{ GetBloggerRegistrationStatusRequest }, { UserService }] = await Promise.all([
        import('~/stub/apigateway/user/user_pb'),
        import('~/stub/apigateway/user/user_pb_service')
      ])
      const request = new GetBloggerRegistrationStatusRequest()
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(UserService.GetBloggerRegistrationStatus, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {
  SET(state, user) {
    Object.assign(state, user)
    if (!state.enterpriseInfo) {
      state.enterpriseInfo = initialEnterpriseinfo()
    }
  },
  RESET(state) {
    Object.assign(state, initialUserState())
  },
  RECEIVE_USER_ALL(state, data) {
    Object.assign(state, data)
  }
}
