import { Middleware } from '@nuxt/types'
import { ProfileAccessor } from '~/api/accessors/profile/profile-accessor'

const getHasOldRecordsRequest: Middleware = async ({ store, error }) => {
  try {
    const profileAccessor = new ProfileAccessor(store.state)
    const response = await profileAccessor.getHasOldRecordsRequest()

    const { hasCareers, hasSkills, hasLicenses } = response

    store.commit('pages/users/my/user/RECEIVE_HAS_CAREERS', hasCareers)
    store.commit('pages/users/my/user/RECEIVE_HAS_SKILLS', hasSkills)
    store.commit('pages/users/my/user/RECEIVE_HAS_LICENSES', hasLicenses)
  } catch (grpcCode) {
    return error({ grpcCode })
  }
}
export default getHasOldRecordsRequest
