<template>
  <a
    class="button"
    :class="classes"
    :href="href"
    :target="target"
    v-on="$listeners"
    v-if="!!href"
  >
    <span
      class="icon"
      :class="{ [sizeClassName]: !!this.size }"
      v-if="iconName && iconPosition !== 'after'"
    >
      <coconala-icon
        :name="iconName"
        :color="iconColor"
      ></coconala-icon>
    </span>
    <span v-if="$slots.default">
      <slot></slot>
    </span>
    <span
      class="icon"
      :class="{ [sizeClassName]: !!this.size }"
      v-if="iconName && iconPosition === 'after'"
    >
      <coconala-icon
        :name="iconName"
        :color="iconColor"
      ></coconala-icon>
    </span>
  </a>
  <button
    class="button"
    :class="classes"
    v-on="$listeners"
    v-else
  >
    <span
      class="icon"
      :class="{ [sizeClassName]: !!this.size }"
      v-if="iconName && iconPosition !== 'after'"
    >
      <coconala-icon
        :name="iconName"
        :color="iconColor"
      ></coconala-icon>
    </span>
    <span v-if="$slots.default">
      <slot></slot>
    </span>
    <span
      class="icon"
      :class="{ [sizeClassName]: !!this.size }"
      v-if="iconName && iconPosition === 'after'"
    >
      <coconala-icon
        :name="iconName"
        :color="iconColor"
      ></coconala-icon>
    </span>
  </button>
</template>
<script>
// NOTE: atomsなので本当は依存してはいけないが例外とする
import CoconalaIcon from '~/components/atoms/CoconalaIcon'

export const BUTTON_COLORS = Object.freeze([
  '',
  'primary',
  'primary-green',
  // 旧profile-webのページのボタン
  'secondary-profile',
  'secondary',
  'secondary-green',
  'thirdly',
  'fourthly',
  'special',
  'white',
  'light',
  'dark',
  'black',
  'text',
  'link'
])
export const BUTTON_SIZES = Object.freeze(['', 'small', 'medium', 'large'])
export const ICON_POSITIONS = Object.freeze(['before', 'after'])

export default {
  name: 'CButton',
  components: { CoconalaIcon },
  props: {
    color: {
      type: String,
      validator(v) {
        return BUTTON_COLORS.includes(v)
      }
    },
    size: {
      type: String,
      validator(v) {
        return BUTTON_SIZES.includes(v)
      }
    },
    href: {
      type: String
    },
    target: {
      type: String,
      default: '_self'
    },
    isFull: {
      type: Boolean,
      default: false
    },
    isOutlined: {
      type: Boolean,
      default: false
    },
    isInverted: {
      type: Boolean,
      default: false
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isBold: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String
    },
    iconPosition: {
      type: String,
      default: ICON_POSITIONS[0],
      validator(v) {
        return ICON_POSITIONS.includes(v)
      }
    }
  },
  computed: {
    classes() {
      let classes = {
        'is-fullwidth': this.isFull,
        'is-outlined': this.isOutlined,
        'is-inverted': this.isInverted,
        'is-rounded': this.isRounded,
        'is-focused': this.isFocused,
        'is-active': this.isActive,
        'is-loading': this.isLoading,
        'is-bold': this.isBold
      }
      classes[`is-${this.color || 'default'}`] = true
      if (this.size) classes[this.sizeClassName] = this.size
      return classes
    },
    sizeClassName() {
      return `is-${this.size}`
    },
    iconColor() {
      return this.isActive && !this.color ? 'ds2Primary' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.button {
  padding-right: 1em;
  padding-left: 1em;
  height: 32px;
  border-radius: 3px;
  font-size: 13px;

  &.is-small {
    height: 28px;
    font-size: 11px;
  }
  &.is-medium {
    height: 40px;
    font-size: 14px;
  }
  &.is-large {
    height: 54px;
    font-size: 18px;
  }
  &.is-bold {
    font-weight: bold;
  }
  &.is-outlined {
    background-color: $color-white;
  }
  &.is-rounded {
    border-radius: 290486px;
  }
  &.is-default {
    border-color: $ds2-color-gray-200;
  }
  &.is-primary {
    font-weight: bold;
  }
  &.is-primary-green {
    @include link-effect;
    background-color: $ds2-color-brand-primary-600;
    border-color: $ds2-color-brand-primary-600;
    color: $color-white;
    font-weight: bold;
  }
  &.is-thirdly {
    color: $ds2-color-gray-400;
    font-weight: bold;
    border: 1px solid $ds2-color-gray-400;
    border-bottom-color: $ds2-color-gray-600;
  }
  &.is-fourthly {
    background-color: $ds2-color-gray-50;
    border: 1px solid $ds2-color-gray-200;
    border-bottom-color: $ds2-color-gray-400;
  }
  &.is-secondary-profile {
    font-weight: bold;
  }
  &.is-secondary {
    font-weight: bold;
  }
  &.is-special {
    font-weight: bold;
  }
  &.is-light {
    font-weight: bold;
  }
  &.is-dark {
    font-weight: bold;
  }
  &.is-black {
    font-weight: bold;
  }

  &:disabled {
    background-color: $ds2-color-gray-200;
    color: $color-white;
    border: 0;
    opacity: 1;
    box-shadow: none;
  }
}
.is-secondary-profile {
  color: $ds2-color-app-primary;
  background-color: $ds2-color-gray-25;
  border: 0;
  &.is-outlined {
    border-color: $ds2-color-gray-50;
    color: $ds2-color-app-primary;
  }
  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
}
.is-secondary {
  @include link-effect;
  color: $ds2-color-app-primary;
  border-color: $ds2-color-app-primary;
  background: $color-white;
  &.is-outlined {
    border-color: $ds2-color-gray-50;
    color: $ds2-color-app-primary;
  }

  &:hover {
    color: $ds2-color-app-primary;
    border-color: $ds2-color-app-primary;
    background: $color-white;
  }
}
.is-secondary-green {
  @include link-effect;
  color: $ds2-color-brand-primary-600;
  border-color: $ds2-color-brand-primary-600;
  background: $color-white;
  &.is-outlined {
    border-color: $ds2-color-gray-50;
    color: $ds2-color-brand-primary-600;
  }

  &:hover {
    color: $ds2-color-brand-primary-600;
    border-color: $ds2-color-brand-primary-600;
    background: $color-white;
  }
}
</style>
