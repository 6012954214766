import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({
  total: null,
  offset: null,
  limit: null,
  requestsList: [],
  condition: {
    keyword: '',
    masterCategoriesList: [
      {
        id: null,
        isCurrent: false,
        name: '',
        subMasterCategoriesList: []
      }
    ],
    priceRange: {
      priceMax: '',
      priceMin: ''
    },
    extraConditions: {},
    sortType: '',
    userIconSize: 'default'
  }
})

// getters
export const getters = {}

// actions
export const actions = {
  async searchRequests({ commit, rootState }, data) {
    const [{ SearchRequestsRequest }, { RequestService }] = await Promise.all([
      import('~/stub/apigateway/request/request_pb'),
      import('~/stub/apigateway/request/request_pb_service')
    ])
    const params = data.params
    const query = data.query
    const businessFlag = false
    const getRequest = new SearchRequestsRequest()
    const setMetadata = commonMetaData(rootState)
    const toString = value => {
      return typeof value === 'undefined' ? value : String(value)
    }
    const toNumber = value => {
      if (typeof value === 'undefined') {
        return undefined
      }
      if (isNaN(value)) {
        throw { grpcCode: grpc.Code.InvalidArgument }
      }
      return Number(value)
    }
    const toBoolean = value => {
      if (typeof value === 'undefined' || value === null) {
        return undefined
      }
      if (typeof value !== 'boolean') {
        throw { grpcCode: grpc.Code.InvalidArgument }
      }
      return value
    }
    getRequest.setPage(toNumber(query.page))
    getRequest.setKeyword(toString(query.keyword))
    getRequest.setPriceMin(toNumber(query.priceMin))
    getRequest.setPriceMax(toNumber(query.priceMax))
    getRequest.setMasterCategoryId(toNumber(params.categoryId))
    getRequest.setRecruiting(toBoolean(query.recruiting))
    getRequest.setBudgetDefined(toBoolean(query.budgetDefined))
    getRequest.setUserIconSize('default')
    getRequest.setSortType(toString(query.sortType))
    getRequest.setBusinessFlag(toBoolean(businessFlag))

    const requestsReply = await new Promise((resolve, reject) => {
      grpc.invoke(RequestService.SearchRequests, {
        request: getRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    const requestsConditionReply = await new Promise((resolve, reject) => {
      grpc.invoke(RequestService.GetSearchRequestsCondition, {
        request: getRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    const requestsResponse = Object.assign(requestsReply, requestsConditionReply)

    commit('RECEIVE_SEARCH_REQUESTS', requestsResponse)
  },
  async categoryChange({ state, commit }, data) {
    commit('CATEGORY_CHANGE', data)
  },
  async toggleFollow({ state, commit }, user) {}
}

// mutations
export const mutations = {
  RECEIVE_SEARCH_REQUESTS(state, data) {
    Object.assign(state, data)
  },
  CATEGORY_CHANGE(state, data) {
    state.condition.masterCategoriesList[0].name = data
    state.condition.masterCategoriesList[0].subMasterCategoriesList.length = 0
  },
  TOGGLE_FOLLOW(state, userId) {
    let target = state.results.find(user => user.userId === userId)
    target.isFollowed = !target.isFollowed
  }
}
