import { Plugin } from '@nuxt/types'

const sentryPlugin: Plugin = ({ $sentry, store }) => {
  if (store.getters['auth/isLoggedIn']) {
    $sentry.setUser({
      id: store.state.auth.user.id,
      // デフォルトでIPアドレスを送っているので指定
      ip_address: store.state.auth.ip
    })
  }
}
export default sentryPlugin
