import Vue from 'vue'
import autosize from 'autosize' // http://www.jacklmoore.com/autosize/

const getTextarea = el => {
  return el.tagName === 'TEXTAREA' ? el : el.getElementsByTagName('textarea')[0]
}

Vue.directive('autosize', {
  bind(el) {
    autosize(getTextarea(el))
  },
  inserted(el) {
    el = getTextarea(el)
    if (el.value) autosize.update(el)
  },
  update(el, binding, vnode, oldVnode) {
    const oldTextAreaValue = getTextarea(oldVnode.elm).value

    // updateだと子コンポーネントの更新が終わる前に実行されてしまう。
    // そのため、変更前の状態でautosize.updateが呼び出されてしまい、リサイズがされないケースがある。
    // ディレクティブではthis.$nextTick()を使用することができないため、setTimeoutで時間差でリサイズ処理を実行する・
    // ex) https://v2.ja.vuejs.org/v2/guide/custom-directive#%E3%83%95%E3%83%83%E3%82%AF%E9%96%A2%E6%95%B0
    setTimeout(() => {
      const textAreaElValue = getTextarea(el).value
      if (textAreaElValue !== oldTextAreaValue) {
        autosize.update(getTextarea(el))
      }
    }, 10)
  },
  unbind(el) {
    el = getTextarea(el)
    autosize.destroy(el)
  }
})
