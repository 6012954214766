import dayjs from 'dayjs'
import path from 'path'
import Util from '~/assets/javascripts/util'

/**
 * S3のアップロード先fileNameを取得する
 *
 * @param {string} subType アップロードするファイルの種類
 *                         e.g. outsources, applications, messages
 * @param {string} fileName オリジナルのファイル名
 *                          e.g. オリジナル.jpg
 * @return {string}
 */
const getUploadingFileKey = (subType: string, fileName: string) => {
  const today = dayjs().format('YYYY-MM-DD')
  const uuid = Util.generateUuid()
  return `${today}/${subType}/${uuid}/${fileName}`
}

/**
 * S3の署名付き用URLから、BEに送信するfileUrlを作成する
 *
 * @param {string} signedUrl S3の署名付きURL
 *                           e.g. https://{S3バケットのドメイン}/outsource/{中略}/{uuid}/{urlエンコードされたファイル名}?{署名パラメータ}
 * @param {string} fileName オリジナルのファイル名
 *                          e.g. オリジナル.jpg
 * @return {string} アップロード済みのファイルURL
 *                  e.g. https://{S3バケットのドメイン}/outsources/{中略}/{uuid}/{オリジナルのファイル名}
 */
const buildFileUrlFromSignedUrl = (signedUrl: string, fileName: string) => {
  // URL以外の文字列ならエラーになる
  const parsedSignedUrl = new URL(signedUrl)

  // パスを'/'で分割する
  const splittedPaths = parsedSignedUrl.pathname.split('/')

  // 分割したパスから先頭の空文字と最後のファイル名を削除する
  const sliceSplittedPaths = splittedPaths.slice(1, splittedPaths.length - 1)

  // ファイル名だけもとに戻して返却する
  return [parsedSignedUrl.origin, ...sliceSplittedPaths, fileName].join('/')
}

/**
 * ファイルURLのなかからファイル名だけURLエンコードする
 *
 * @param {string?} fileUrl ファイルURL
 * @returns {string} ファイル名がURLエンコードされたURL
 */
const encodeFileUrl = (fileUrl?: string) => {
  if (!fileUrl) {
    return ''
  }

  // ファイル名だけURLエンコードする
  const encodedFileName = String(encodeURIComponent(path.basename(fileUrl)))

  // 結合してして返却する
  return [path.dirname(fileUrl), encodedFileName].join('/')
}

export { getUploadingFileKey, buildFileUrlFromSignedUrl, encodeFileUrl }
