import { Plugin } from '@nuxt/types'
import DToast from '~/components/molecules/DS2/DToast'
import Util from '~/assets/javascripts/util'
import Vue from 'vue'

/** トーストタイプ */
type ToastType = 'success' | 'error'

/** トースト表示位置 */
type ToastPosition = 'top' | 'bottom'

type ToastOptions = {
  /** トーストタイプ(成功/失敗) */
  type?: ToastType
  /** メッセージ */
  message: string
  /** 表示位置(上端/下端) */
  position?: ToastPosition
  /** 表示間隔(ms) */
  duration?: number
  /** アイコン表示フラグ */
  hasIcon?: boolean
  /** 縦方向の表示位置調整 */
  verticalOffset?: number
  /** 閉じるボタン表示フラグ */
  showHideButton?: boolean
}

type ToastShowArgument = ToastOptions | string

export interface ToastManager {
  /** トースト表示 */
  open(showArg: ToastShowArgument): Promise<void>
  /** トースト非表示 */
  hide(): Promise<void>
}

const vueToastPlugin: Plugin = (_, inject) => {
  const ToastComponent = Vue.extend(DToast)

  // 1つのコンポーネントインスタンスを使い回す
  let componentInstance = null
  const defaultOptions: ToastOptions = {
    type: 'success',
    message: '',
    position: 'top',
    duration: 5000,
    hasIcon: true,
    showHideButton: false
  }

  const toast: ToastManager = Object.freeze({
    async open(showArg: ToastShowArgument) {
      const extendOptions: ToastOptions =
        typeof showArg === 'string' ? { message: showArg } : showArg
      const options = Object.assign({}, defaultOptions, extendOptions)

      // トースト表示中だったら非表示になるのを待ち受ける
      // @ts-ignore TS2339
      await Util.waitUntil(() => !componentInstance || componentInstance.isHidden)

      if (!componentInstance) {
        const div = document.createElement('div')
        document.body.appendChild(div)
        // @ts-ignore TS2322
        componentInstance = new ToastComponent({
          el: div,
          propsData: options
        })
        // @ts-ignore TS2531
        componentInstance.$forceUpdate()
      } else {
        // @ts-ignore TS2339
        Object.assign(componentInstance.$props, options)
        // @ts-ignore TS2339
        componentInstance.show()
      }
    },
    async hide() {
      if (componentInstance) {
        // @ts-ignore TS2339
        componentInstance.hide()
        // 非表示になるのを待ち受ける
        // @ts-ignore TS2531
        await Util.waitUntil(() => componentInstance.isHidden)
      }
    }
  })

  inject('toast', toast)
}
export default vueToastPlugin
