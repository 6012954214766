import { render, staticRenderFns } from "./register.vue?vue&type=template&id=832fe600&scoped=true&"
import script from "./register.vue?vue&type=script&lang=ts&"
export * from "./register.vue?vue&type=script&lang=ts&"
import style0 from "~/assets/stylesheets/pages/register/register-common.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./register.vue?vue&type=style&index=1&id=832fe600&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "832fe600",
  null
  
)

export default component.exports