<template>
  <img
    class="c-userIcon"
    :class="{ 'c-userIcon-withBorder': hasBorder }"
    :src="$coconala.userIconURLV2(filePath, width, height, isFromSystem)"
    :alt="userName"
    :style="style"
    loading="lazy"
    decoding="async"
  />
</template>
<script>
export default {
  name: 'CUserIconV2',
  props: {
    filePath: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: 'user'
    },
    // 表示サイズ（正方形）
    size: {
      type: Number
    },
    // 取得サイズ（横）
    width: {
      type: Number,
      default: 100
    },
    // 取得サイズ（縦）
    height: {
      type: Number,
      default: 100
    },
    isFromSystem: {
      type: Boolean,
      defalut: false
    },
    hasBorder: {
      type: Boolean,
      defalut: false
    }
  },
  data() {
    return {
      hasError: false
    }
  },
  mounted() {
    this.$el.onerror = () => {
      this.hasError = true
    }
  },
  computed: {
    style() {
      if (!this.size) return {}
      return { width: `${this.size}px`, height: `${this.size}px` }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-userIcon {
  max-width: 172px;
  max-height: 172px;
  width: 100%;
  height: auto;
  border-radius: 50%;

  &-withBorder {
    border: 1px solid $ds2-color-gray-50;
  }
}
</style>
