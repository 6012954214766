export const prefectureTextGetter = (state, getters, { master }) => {
  let prefectureId = state.profile && state.profile.prefectureId
  if (!prefectureId) return null
  if (prefectureId === 99) {
    return state.profile.locationText
  }
  let prefecture = master.prefectures.find(p => p.id === prefectureId)
  return prefecture && prefecture.name
}

export const sexTextGetter = (state, getters, { master }) => {
  if (!state.sex) return null
  return master.sexes[state.sex] || null
}

export const corporateFgTextGetter = (state, getters, { master }) => {
  if (!state.hasProviderIdentification) return null
  if (typeof state.corporateFg !== 'boolean') return null
  if (typeof state.identificationNormalizedFg !== 'boolean') return null

  const texts = state.identificationNormalizedFg
    ? master.corporateFgTexts.identificationNormalized
    : master.corporateFgTexts.notIdentificationNormalized
  return state.corporateFg ? texts.C : texts.P
}

export const corporateFgTextGetterForProfile = (state, getters, { master }) => {
  if (!state.hasProviderIdentification) return null
  if (typeof state.corporateFg !== 'boolean') return null
  if (!state.corporateFg) return null // 個人の場合は文言表示なし
  if (typeof state.identificationNormalizedFg !== 'boolean') return null

  const texts = state.identificationNormalizedFg
    ? master.corporateFgTexts.identificationNormalized
    : master.corporateFgTexts.notIdentificationNormalized
  return texts.C
}

export const initialEnterpriseinfo = () => ({
  id: null,
  role: null,
  status: null,
  name: null
})

export const initialUserState = () => ({
  id: null,
  name: '',
  sex: '',
  age: '',
  message: '',
  schedule: '',
  icon: '',
  identificationFg: false,
  identificationNormalizedFg: false,
  soloProprietorshipFg: false,
  ndaConclusionFg: false,
  corporateFg: false,
  lastLoginedAt: null,
  level: {
    value: null,
    nameText: ''
  },
  enterpriseInfo: initialEnterpriseinfo(),
  userKey: '',
  isBuyer: false,
  isProvider: false,
  profile: {
    id: null,
    coverImageUrl: '',
    isDisplayAge: false,
    locationText: '',
    occupation: '',
    prefectureId: null,
    themeColor: ''
  },
  userStatus: {
    id: null,
    kind: 0
  },
  userJobsList: [],
  skillsList: [],
  experienceJobsList: [],
  userRelation: {
    followingFlag: false,
    blockerFlag: false,
    blockeeFlag: false,
    customizingFlag: false,
    directMessageRoomId: null,
    hasTalkrooms: false
  },
  hasInfo: {
    hasAward: false,
    hasCareer: false,
    hasLicense: false,
    hasPortfolio: false,
    hasRating: false,
    hasService: false,
    hasSkill: false,
    hasSpecialty: false,
    hasBlog: false,
    hasPrice: false,
    hasWorkHistory: false,
    hasSchoolHistory: false,
    hasLanguageSkill: false,
    hasExperienceJob: false,
    hasJob: false
  },
  achievementCount: 0,
  ratingAvg: 0,
  followerCount: 0,
  userSpecialtiesList: [],
  licensesList: [],
  awardsList: [],
  resizableProfileIcon: '',
  isBusiness: false,
  proFlag: false,
  hasBoughtService: false,
  isBeginner: false,
  certificationDivinationFlag: false,
  shouldShowPromotionBalloon: false,
  signupAttributePurpose: '',
  isQualifiedInvoiceIssuer: false,
  hasProviderIdentification: false,
  usage: '',
  hasFinishedEntryForm: false,
  desiredWorkingCondition: {
    id: null,
    weeklyWorkingDays: null,
    maxHourlyPay: null,
    minHourlyPay: null,
    dailyWorkingHour: null,
    workingTimeframesList: []
  },
  myDesiredWorkingCondition: {
    id: null,
    weeklyWorkingDays: null,
    maxHourlyPay: null,
    minHourlyPay: null,
    minMonthlyPay: null,
    dailyWorkingHour: null,
    workingTimeframesList: [],
    workingLocationsList: []
  },
  userInfoUrlsList: [],
  languageSkillsList: [],
  isShowBusinessUseBanner: false,
  isCoconalaAttributeUnregistered: false
})

export const initialProviderSummaryOptions = () => ({
  userJob: false,
  skill: false,
  userSpecialty: false,
  license: false,
  award: false
})
