// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchHeader({ dispatch, rootState }) {
    if (rootState.auth.sToken) {
      await dispatch('header/fetchHeader')
    }
  },
  async markAsReadNotification({ dispatch, rootState }, activityId) {
    if (rootState.auth.sToken) {
      await dispatch('notifications/markAsReadNotification', activityId)
    }
  },
  async fetchToYouNotifications({ dispatch, rootState }) {
    if (rootState.auth.sToken) {
      await dispatch('to_you_notifications/fetchToYouNotifications')
    }
  }
}

// mutations
export const mutations = {}
