import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async linkAspWithUserId({ rootState }, params) {
    const [{ LinkAspWithUserIdRequest }, { AffiliateService }] = await Promise.all([
      import('~/stub/apigateway/affiliate/v3/asp_pb'),
      import('~/stub/apigateway/affiliate/v3/asp_pb_service')
    ])
    return await new Promise((resolve, reject) => {
      const metadata = commonMetaData(rootState)
      const request = new LinkAspWithUserIdRequest()
      request.setAspType(params.aspType)
      request.setAffiliateValue(params.affiliateValue)
      grpc.invoke(AffiliateService.LinkAspWithUserId, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
  },
  async getFirstOrder({ rootState }, params) {
    const [{ GetFirstOrderRequest }, { A8Service }] = await Promise.all([
      import('~/stub/apigateway/affiliate/a8_pb'),
      import('~/stub/apigateway/affiliate/a8_pb_service')
    ])
    return await new Promise((resolve, reject) => {
      const request = new GetFirstOrderRequest()
      request.setTalkroomId(params.talkroomId)
      request.setBlogOrderId(params.blogOrderId)
      request.setBlogOrderId(params.blogOrderId)
      const metadata = commonMetaData(rootState)
      grpc.invoke(A8Service.GetFirstOrder, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {}
