// source: domain/enumeration/service_kind.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.ServiceKind', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.ServiceKind = {
  SERVICE_KIND_UNSPECIFIED: 0,
  SERVICE_KIND_TEXT: 1,
  SERVICE_KIND_PHONE: 2,
  SERVICE_KIND_VIDEO: 3
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
