// initial state
const initialState = () => ({
  hasErrorCalledByUsersMixin: false
})

export const state = () => initialState()

// getters
export const getters = {
  isOwner(state, getters, { auth }) {
    if (!auth.user || !auth.user.id) {
      return false
    }
    if (state.user.id) {
      return state.user.id === auth.user.id
    }
    return getters.isEditing
  },
  isEditing(state, getters, { route }) {
    let path = route && route.path
    if (!path) return false
    return !!path.match(/^\/mypage\/user/)
  }
}

// actions
export const actions = {
  resetPages({ commit }) {
    commit('user/RESET')
  }
}

// mutations
export const mutations = {
  UPDATE_HAS_ERROR_CALLED_BY_USERS_MIXIN(state, v) {
    state.hasErrorCalledByUsersMixin = v
  }
}
