export default function ({ $sentry }) {
  if (Comment) {
    Comment.prototype.setAttribute = function (name, value) {
      const parentElement = this?.parentElement
      $sentry.withScope(scope => {
        scope.setTag('Error', 'setAttributeIsNotFunction')
        scope.setExtras({
          name,
          value,
          parentClass: parentElement?.className
        })
        $sentry.captureException(new Error('setAttribute is not function'))
      })
    }
  }
}
