import dayjs from 'dayjs'

export const state = () => ({
  hasUsedTimestampOnCSR: false,
  ssrCacheTimestamp: 0
})

export const actions = {}

export const mutations = {
  /**
   * SSR時のタイムスタンプ更新
   * SSR時またはCSR時で2回目以降の呼び出しはUnixタイムスタンプで更新する
   */
  updateSSRCacheTimestamp(moduleState: ReturnType<typeof state>) {
    if (process.server || moduleState.hasUsedTimestampOnCSR) {
      moduleState.ssrCacheTimestamp = dayjs().unix()
    } else {
      moduleState.hasUsedTimestampOnCSR = true
    }
  }
}
