// package: campaign_event
// file: apigateway/campaign_event/campaign_event.proto

import * as apigateway_campaign_event_campaign_event_pb from "../../apigateway/campaign_event/campaign_event_pb";
export class CampaignEventService {
  static serviceName = "campaign_event.CampaignEventService";
}
export namespace CampaignEventService {
  export class GetCampaignEvent {
    static readonly methodName = "GetCampaignEvent";
    static readonly service = CampaignEventService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_campaign_event_campaign_event_pb.GetCampaignEventRequest;
    static readonly responseType = apigateway_campaign_event_campaign_event_pb.GetCampaignEventReply;
  }
  export class GetEntryStatus {
    static readonly methodName = "GetEntryStatus";
    static readonly service = CampaignEventService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_campaign_event_campaign_event_pb.GetEntryStatusRequest;
    static readonly responseType = apigateway_campaign_event_campaign_event_pb.GetEntryStatusReply;
  }
  export class EntryCampaignEvent {
    static readonly methodName = "EntryCampaignEvent";
    static readonly service = CampaignEventService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_campaign_event_campaign_event_pb.EntryCampaignEventRequest;
    static readonly responseType = apigateway_campaign_event_campaign_event_pb.EntryCampaignEventReply;
  }
}
