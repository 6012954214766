// package: activities
// file: apigateway/activities/activities.proto

import * as apigateway_activities_activities_pb from "../../apigateway/activities/activities_pb";
export class ActivitiesService {
  static serviceName = "activities.ActivitiesService";
}
export namespace ActivitiesService {
  export class GetUserHeader {
    static readonly methodName = "GetUserHeader";
    static readonly service = ActivitiesService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_activities_activities_pb.GetUserHeaderRequest;
    static readonly responseType = apigateway_activities_activities_pb.GetUserHeaderReply;
  }
  export class GetDirectMessages {
    static readonly methodName = "GetDirectMessages";
    static readonly service = ActivitiesService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_activities_activities_pb.GetDirectMessagesRequest;
    static readonly responseType = apigateway_activities_activities_pb.GetDirectMessagesReply;
  }
  export class MarkAsRead {
    static readonly methodName = "MarkAsRead";
    static readonly service = ActivitiesService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_activities_activities_pb.MarkAsReadRequest;
    static readonly responseType = apigateway_activities_activities_pb.MarkAsReadReply;
  }
}
