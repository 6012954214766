// package: profile
// file: apigateway/profile/introduction.proto

import * as apigateway_profile_introduction_pb from "../../apigateway/profile/introduction_pb";
import * as profileservice_introduction_pb from "../../profileservice/introduction_pb";
export class IntroductionService {
  static serviceName = "profile.IntroductionService";
}
export namespace IntroductionService {
  export class GetIntroduction {
    static readonly methodName = "GetIntroduction";
    static readonly service = IntroductionService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_introduction_pb.GetIntroductionRequest;
    static readonly responseType = apigateway_profile_introduction_pb.IntroductionReply;
  }
  export class GetMyIntroduction {
    static readonly methodName = "GetMyIntroduction";
    static readonly service = IntroductionService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_introduction_pb.GetMyIntroductionRequest;
    static readonly responseType = apigateway_profile_introduction_pb.IntroductionReply;
  }
  export class UpdateIntroduction {
    static readonly methodName = "UpdateIntroduction";
    static readonly service = IntroductionService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_introduction_pb.UpdateIntroductionRequest;
    static readonly responseType = apigateway_profile_introduction_pb.IntroductionReply;
  }
}
