// source: apigateway/received_favorite/received_favorite.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.received_favorite.GetServicesReply', null, global);
goog.exportSymbol('proto.received_favorite.GetServicesRequest', null, global);
goog.exportSymbol('proto.received_favorite.GetUsersReply', null, global);
goog.exportSymbol('proto.received_favorite.GetUsersRequest', null, global);
goog.exportSymbol('proto.received_favorite.ProviderRank', null, global);
goog.exportSymbol('proto.received_favorite.ServiceFollower', null, global);
goog.exportSymbol('proto.received_favorite.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.received_favorite.GetServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.received_favorite.GetServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.received_favorite.GetServicesRequest.displayName = 'proto.received_favorite.GetServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.received_favorite.GetServicesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.received_favorite.GetServicesReply.repeatedFields_, null);
};
goog.inherits(proto.received_favorite.GetServicesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.received_favorite.GetServicesReply.displayName = 'proto.received_favorite.GetServicesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.received_favorite.ServiceFollower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.received_favorite.ServiceFollower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.received_favorite.ServiceFollower.displayName = 'proto.received_favorite.ServiceFollower';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.received_favorite.GetUsersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.received_favorite.GetUsersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.received_favorite.GetUsersRequest.displayName = 'proto.received_favorite.GetUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.received_favorite.GetUsersReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.received_favorite.GetUsersReply.repeatedFields_, null);
};
goog.inherits(proto.received_favorite.GetUsersReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.received_favorite.GetUsersReply.displayName = 'proto.received_favorite.GetUsersReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.received_favorite.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.received_favorite.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.received_favorite.User.displayName = 'proto.received_favorite.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.received_favorite.ProviderRank = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.received_favorite.ProviderRank, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.received_favorite.ProviderRank.displayName = 'proto.received_favorite.ProviderRank';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.received_favorite.GetServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.received_favorite.GetServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.received_favorite.GetServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.GetServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.received_favorite.GetServicesRequest}
 */
proto.received_favorite.GetServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.received_favorite.GetServicesRequest;
  return proto.received_favorite.GetServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.received_favorite.GetServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.received_favorite.GetServicesRequest}
 */
proto.received_favorite.GetServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.received_favorite.GetServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.received_favorite.GetServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.received_favorite.GetServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.GetServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.received_favorite.GetServicesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.GetServicesRequest} returns this
 */
proto.received_favorite.GetServicesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.received_favorite.GetServicesRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.GetServicesRequest} returns this
 */
proto.received_favorite.GetServicesRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.received_favorite.GetServicesReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.received_favorite.GetServicesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.received_favorite.GetServicesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.received_favorite.GetServicesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.GetServicesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    followersList: jspb.Message.toObjectList(msg.getFollowersList(),
    proto.received_favorite.ServiceFollower.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.received_favorite.GetServicesReply}
 */
proto.received_favorite.GetServicesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.received_favorite.GetServicesReply;
  return proto.received_favorite.GetServicesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.received_favorite.GetServicesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.received_favorite.GetServicesReply}
 */
proto.received_favorite.GetServicesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.received_favorite.ServiceFollower;
      reader.readMessage(value,proto.received_favorite.ServiceFollower.deserializeBinaryFromReader);
      msg.addFollowers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.received_favorite.GetServicesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.received_favorite.GetServicesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.received_favorite.GetServicesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.GetServicesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFollowersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.received_favorite.ServiceFollower.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.received_favorite.GetServicesReply.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.GetServicesReply} returns this
 */
proto.received_favorite.GetServicesReply.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated ServiceFollower followers = 2;
 * @return {!Array<!proto.received_favorite.ServiceFollower>}
 */
proto.received_favorite.GetServicesReply.prototype.getFollowersList = function() {
  return /** @type{!Array<!proto.received_favorite.ServiceFollower>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.received_favorite.ServiceFollower, 2));
};


/**
 * @param {!Array<!proto.received_favorite.ServiceFollower>} value
 * @return {!proto.received_favorite.GetServicesReply} returns this
*/
proto.received_favorite.GetServicesReply.prototype.setFollowersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.received_favorite.ServiceFollower=} opt_value
 * @param {number=} opt_index
 * @return {!proto.received_favorite.ServiceFollower}
 */
proto.received_favorite.GetServicesReply.prototype.addFollowers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.received_favorite.ServiceFollower, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.received_favorite.GetServicesReply} returns this
 */
proto.received_favorite.GetServicesReply.prototype.clearFollowersList = function() {
  return this.setFollowersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.received_favorite.ServiceFollower.prototype.toObject = function(opt_includeInstance) {
  return proto.received_favorite.ServiceFollower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.received_favorite.ServiceFollower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.ServiceFollower.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userIconResizable: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sex: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastLoginAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    serviceName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    followedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    providerRank: (f = msg.getProviderRank()) && proto.received_favorite.ProviderRank.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.received_favorite.ServiceFollower}
 */
proto.received_favorite.ServiceFollower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.received_favorite.ServiceFollower;
  return proto.received_favorite.ServiceFollower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.received_favorite.ServiceFollower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.received_favorite.ServiceFollower}
 */
proto.received_favorite.ServiceFollower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIconResizable(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSex(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastLoginAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFollowedAt(value);
      break;
    case 9:
      var value = new proto.received_favorite.ProviderRank;
      reader.readMessage(value,proto.received_favorite.ProviderRank.deserializeBinaryFromReader);
      msg.setProviderRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.received_favorite.ServiceFollower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.received_favorite.ServiceFollower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.received_favorite.ServiceFollower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.ServiceFollower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserIconResizable();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSex();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastLoginAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFollowedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getProviderRank();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.received_favorite.ProviderRank.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.received_favorite.ServiceFollower.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.received_favorite.ServiceFollower.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_icon_resizable = 3;
 * @return {string}
 */
proto.received_favorite.ServiceFollower.prototype.getUserIconResizable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.setUserIconResizable = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 service_id = 4;
 * @return {number}
 */
proto.received_favorite.ServiceFollower.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string sex = 5;
 * @return {string}
 */
proto.received_favorite.ServiceFollower.prototype.getSex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.setSex = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 last_login_at = 6;
 * @return {number}
 */
proto.received_favorite.ServiceFollower.prototype.getLastLoginAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.setLastLoginAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string service_name = 7;
 * @return {string}
 */
proto.received_favorite.ServiceFollower.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 followed_at = 8;
 * @return {number}
 */
proto.received_favorite.ServiceFollower.prototype.getFollowedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.setFollowedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional ProviderRank provider_rank = 9;
 * @return {?proto.received_favorite.ProviderRank}
 */
proto.received_favorite.ServiceFollower.prototype.getProviderRank = function() {
  return /** @type{?proto.received_favorite.ProviderRank} */ (
    jspb.Message.getWrapperField(this, proto.received_favorite.ProviderRank, 9));
};


/**
 * @param {?proto.received_favorite.ProviderRank|undefined} value
 * @return {!proto.received_favorite.ServiceFollower} returns this
*/
proto.received_favorite.ServiceFollower.prototype.setProviderRank = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.received_favorite.ServiceFollower} returns this
 */
proto.received_favorite.ServiceFollower.prototype.clearProviderRank = function() {
  return this.setProviderRank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.received_favorite.ServiceFollower.prototype.hasProviderRank = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.received_favorite.GetUsersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.received_favorite.GetUsersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.received_favorite.GetUsersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.GetUsersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.received_favorite.GetUsersRequest}
 */
proto.received_favorite.GetUsersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.received_favorite.GetUsersRequest;
  return proto.received_favorite.GetUsersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.received_favorite.GetUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.received_favorite.GetUsersRequest}
 */
proto.received_favorite.GetUsersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.received_favorite.GetUsersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.received_favorite.GetUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.received_favorite.GetUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.GetUsersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.received_favorite.GetUsersRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.GetUsersRequest} returns this
 */
proto.received_favorite.GetUsersRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.received_favorite.GetUsersRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.GetUsersRequest} returns this
 */
proto.received_favorite.GetUsersRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.received_favorite.GetUsersReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.received_favorite.GetUsersReply.prototype.toObject = function(opt_includeInstance) {
  return proto.received_favorite.GetUsersReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.received_favorite.GetUsersReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.GetUsersReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto.received_favorite.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.received_favorite.GetUsersReply}
 */
proto.received_favorite.GetUsersReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.received_favorite.GetUsersReply;
  return proto.received_favorite.GetUsersReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.received_favorite.GetUsersReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.received_favorite.GetUsersReply}
 */
proto.received_favorite.GetUsersReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.received_favorite.User;
      reader.readMessage(value,proto.received_favorite.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.received_favorite.GetUsersReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.received_favorite.GetUsersReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.received_favorite.GetUsersReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.GetUsersReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.received_favorite.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.received_favorite.GetUsersReply.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.GetUsersReply} returns this
 */
proto.received_favorite.GetUsersReply.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated User users = 2;
 * @return {!Array<!proto.received_favorite.User>}
 */
proto.received_favorite.GetUsersReply.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.received_favorite.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.received_favorite.User, 2));
};


/**
 * @param {!Array<!proto.received_favorite.User>} value
 * @return {!proto.received_favorite.GetUsersReply} returns this
*/
proto.received_favorite.GetUsersReply.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.received_favorite.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.received_favorite.User}
 */
proto.received_favorite.GetUsersReply.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.received_favorite.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.received_favorite.GetUsersReply} returns this
 */
proto.received_favorite.GetUsersReply.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.received_favorite.User.prototype.toObject = function(opt_includeInstance) {
  return proto.received_favorite.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.received_favorite.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userIconResizable: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sex: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastLoginAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    followedAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    providerRank: (f = msg.getProviderRank()) && proto.received_favorite.ProviderRank.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.received_favorite.User}
 */
proto.received_favorite.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.received_favorite.User;
  return proto.received_favorite.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.received_favorite.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.received_favorite.User}
 */
proto.received_favorite.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIconResizable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSex(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastLoginAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFollowedAt(value);
      break;
    case 9:
      var value = new proto.received_favorite.ProviderRank;
      reader.readMessage(value,proto.received_favorite.ProviderRank.deserializeBinaryFromReader);
      msg.setProviderRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.received_favorite.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.received_favorite.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.received_favorite.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserIconResizable();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSex();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastLoginAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getFollowedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getProviderRank();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.received_favorite.ProviderRank.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.received_favorite.User.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.User} returns this
 */
proto.received_favorite.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.received_favorite.User.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.received_favorite.User} returns this
 */
proto.received_favorite.User.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_icon_resizable = 3;
 * @return {string}
 */
proto.received_favorite.User.prototype.getUserIconResizable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.received_favorite.User} returns this
 */
proto.received_favorite.User.prototype.setUserIconResizable = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sex = 5;
 * @return {string}
 */
proto.received_favorite.User.prototype.getSex = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.received_favorite.User} returns this
 */
proto.received_favorite.User.prototype.setSex = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 last_login_at = 6;
 * @return {number}
 */
proto.received_favorite.User.prototype.getLastLoginAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.User} returns this
 */
proto.received_favorite.User.prototype.setLastLoginAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 followed_at = 8;
 * @return {number}
 */
proto.received_favorite.User.prototype.getFollowedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.User} returns this
 */
proto.received_favorite.User.prototype.setFollowedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional ProviderRank provider_rank = 9;
 * @return {?proto.received_favorite.ProviderRank}
 */
proto.received_favorite.User.prototype.getProviderRank = function() {
  return /** @type{?proto.received_favorite.ProviderRank} */ (
    jspb.Message.getWrapperField(this, proto.received_favorite.ProviderRank, 9));
};


/**
 * @param {?proto.received_favorite.ProviderRank|undefined} value
 * @return {!proto.received_favorite.User} returns this
*/
proto.received_favorite.User.prototype.setProviderRank = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.received_favorite.User} returns this
 */
proto.received_favorite.User.prototype.clearProviderRank = function() {
  return this.setProviderRank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.received_favorite.User.prototype.hasProviderRank = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.received_favorite.ProviderRank.prototype.toObject = function(opt_includeInstance) {
  return proto.received_favorite.ProviderRank.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.received_favorite.ProviderRank} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.ProviderRank.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.received_favorite.ProviderRank}
 */
proto.received_favorite.ProviderRank.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.received_favorite.ProviderRank;
  return proto.received_favorite.ProviderRank.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.received_favorite.ProviderRank} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.received_favorite.ProviderRank}
 */
proto.received_favorite.ProviderRank.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.received_favorite.ProviderRank.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.received_favorite.ProviderRank.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.received_favorite.ProviderRank} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.received_favorite.ProviderRank.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.received_favorite.ProviderRank.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.received_favorite.ProviderRank} returns this
 */
proto.received_favorite.ProviderRank.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.received_favorite.ProviderRank.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.received_favorite.ProviderRank} returns this
 */
proto.received_favorite.ProviderRank.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.received_favorite);
