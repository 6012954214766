// package: analytics
// file: apigateway/analytics/blog.proto

import * as apigateway_analytics_blog_pb from "../../apigateway/analytics/blog_pb";
import * as profileservice_blog_analytics_pb from "../../profileservice/blog_analytics_pb";
export class AnalyticsBlogService {
  static serviceName = "analytics.AnalyticsBlogService";
}
export namespace AnalyticsBlogService {
  export class GetAnalyticsBlog {
    static readonly methodName = "GetAnalyticsBlog";
    static readonly service = AnalyticsBlogService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_analytics_blog_pb.GetAnalyticsBlogRequest;
    static readonly responseType = apigateway_analytics_blog_pb.GetAnalyticsBlogReply;
  }
  export class GetBlogList {
    static readonly methodName = "GetBlogList";
    static readonly service = AnalyticsBlogService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_analytics_blog_pb.GetBlogListRequest;
    static readonly responseType = apigateway_analytics_blog_pb.GetBlogListReply;
  }
}
