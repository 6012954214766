import { ValueOf } from '~/types/util'

export const DEFAULT_LANGUAGE_KEY = 'default_language_key' // 表示用の言語設定
export const SETTING_LANGUAGE_KEY = 'language_key' // ユーザーが設定した言語設定

// 検索したワードを保存する
export const LANGUAGE_SEARCH_ORIGIN_TEXT = 'language_search_origin_text_key'
export const FIRST_TIME_LANDING_FOR_TRANSLATE = 'first_time_landing_for_translate'

export const LANGUAGES = {
  JA: {
    code: 'ja',
    label: '日本語'
  },
  EN: {
    code: 'en',
    label: 'English'
  },
  ZHCN: {
    code: 'zh-CN',
    label: '中文（简体）'
  },
  ZHTW: {
    code: 'zh-TW',
    label: '中文（繁體）'
  },
  KO: {
    code: 'ko',
    label: '한국어'
  }
} as const

export const isTranslatableLanguage = (target: string): target is LanguageType => {
  return Object.values(LANGUAGES).some(l => l.code === target)
}

export const isLanguageUseKanji = (target: LanguageType): boolean => {
  const useKanjiLangCode: LanguageType[] = [LANGUAGES.ZHCN.code, LANGUAGES.ZHTW.code]
  return useKanjiLangCode.includes(target)
}

export type LanguageType = ValueOf<typeof LANGUAGES>['code']
