export default async ({ store, error, app }) => {
  if (store.state.pages.blogs.master.blogCategoriesList.length) {
    return
  }

  // 人気のタグ取得APIも後で呼ぶ予定
  const promises = [
    new Promise((resolve, reject) => {
      store.dispatch('pages/blogs/master/fetchBlogCategories').then(resolve).catch(reject)
    })
  ]
  try {
    await Promise.all(promises)
  } catch (err) {
    const statusCode = app.$util.grpcCodeToHttpStatusCode(err.grpcCode)
    return error({
      statusCode,
      message: '',
      grpcCode: err.grpcCode
    })
  }
}
