import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'
import { toStringValue } from '~/grpc/grpc-util'
import { LinkStatus } from '~/stub/apigateway/enterprise/invitation_link_pb'

export const state = () => ({
  linkId: null,
  email: null,
  status: LinkStatus.LINK_STATUS_UNSPECIFIED
})

export const getters = {
  availableInvitationLink(state) {
    return state.status === LinkStatus.LINK_STATUS_VALID
  }
}

export const actions = {
  async getInvitationLinkStatus({ commit, rootState }, { linkId }) {
    const [{ GetInvitationLinkStatusRequest }, { InvitationLinkService }] = await Promise.all([
      import('~/stub/apigateway/enterprise/invitation_link_pb'),
      import('~/stub/apigateway/enterprise/invitation_link_pb_service')
    ])
    const metadata = commonMetaData(rootState)
    const request = new GetInvitationLinkStatusRequest()
    request.setLinkId(toStringValue(linkId))

    const response = await grpcClient({
      method: InvitationLinkService.GetInvitationLinkStatus,
      request,
      metadata,
      strip: true
    })
    commit('RECEIVE_INVITATION_LINK_STATUS', response)
  },
  async addInvitationFromLink({ commit, rootState }, { linkId, email }) {
    const [{ AddInvitationFromLinkRequest }, { InvitationLinkService }] = await Promise.all([
      import('~/stub/apigateway/enterprise/invitation_link_pb'),
      import('~/stub/apigateway/enterprise/invitation_link_pb_service')
    ])
    const metadata = commonMetaData(rootState)
    const request = new AddInvitationFromLinkRequest()
    request.setLinkId(toStringValue(linkId))
    request.setEmail(toStringValue(email))

    await grpcClient({
      method: InvitationLinkService.AddInvitationFromLink,
      request,
      metadata
    })
    commit('SAVE_INVITATION_LINK', { linkId, email })
  }
}

export const mutations = {
  RECEIVE_INVITATION_LINK_STATUS(state, data) {
    state.status = data.status
  },
  SAVE_INVITATION_LINK(state, data) {
    state.linkId = data.linkId
    state.email = data.email
  }
}
