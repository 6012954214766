import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '@/store/_helpers/common-helper'

const DEFAULT_PER_PAGE = 20

export const state = () => ({
  blogsList: [],
  page: 1,
  perPage: 0,
  total: 0
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchBlogFavorites({ commit, rootState }, { page = 1, perPage = DEFAULT_PER_PAGE }) {
    const metadata = commonMetaData(rootState)
    const [{ GetFavoriteBlogsRequest }, { BlogFavoriteService }] = await Promise.all([
      import('@/stub/apigateway/favorite/blog_pb'),
      import('@/stub/apigateway/favorite/blog_pb_service')
    ])
    const request = new GetFavoriteBlogsRequest()
    // ローカル環境ではinternalGrpcWebUrlはgrpcWebUrlと同じ（ローカルでinternalAPIは使用できない為）
    const host = process.server
      ? process.env.config.internalGrpcWebUrl
      : process.env.config.grpcWebUrl
    request.setPage(page)
    request.setPerPage(perPage)
    const response = await new Promise((resolve, reject) => {
      grpc.invoke(BlogFavoriteService.GetFavoriteBlogs, {
        request,
        metadata,
        host,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOG_FAVORITES', response)
  },

  async addFavorite({ commit, rootState }, { blogId }) {
    const metadata = commonMetaData(rootState)
    const [{ AddBlogFavoriteRequest }, { BlogFavoriteService }] = await Promise.all([
      import('@/stub/apigateway/favorite/blog_pb'),
      import('@/stub/apigateway/favorite/blog_pb_service')
    ])
    const request = new AddBlogFavoriteRequest()
    request.setBlogId(blogId)

    return new Promise((resolve, reject) => {
      grpc.invoke(BlogFavoriteService.AddBlogFavorite, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  },

  async deleteFavorite({ commit, rootState }, { blogId }) {
    const metadata = commonMetaData(rootState)
    const [{ DeleteBlogFavoriteRequest }, { BlogFavoriteService }] = await Promise.all([
      import('@/stub/apigateway/favorite/blog_pb'),
      import('@/stub/apigateway/favorite/blog_pb_service')
    ])
    const request = new DeleteBlogFavoriteRequest()
    request.setBlogId(blogId)
    return new Promise((resolve, reject) => {
      grpc.invoke(BlogFavoriteService.DeleteBlogFavorite, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOG_FAVORITES(state, data) {
    Object.assign(state, data)
  }
}
