// package: user
// file: apigateway/user/template.proto

import * as apigateway_user_template_pb from "../../apigateway/user/template_pb";
export class TemplateService {
  static serviceName = "user.TemplateService";
}
export namespace TemplateService {
  export class GetUserTemplates {
    static readonly methodName = "GetUserTemplates";
    static readonly service = TemplateService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_user_template_pb.GetUserTemplatesRequest;
    static readonly responseType = apigateway_user_template_pb.GetUserTemplatesReply;
  }
  export class AddUserTemplate {
    static readonly methodName = "AddUserTemplate";
    static readonly service = TemplateService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_user_template_pb.AddUserTemplateRequest;
    static readonly responseType = apigateway_user_template_pb.AddUserTemplateReply;
  }
  export class DeleteUserTemplate {
    static readonly methodName = "DeleteUserTemplate";
    static readonly service = TemplateService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_user_template_pb.DeleteUserTemplateRequest;
    static readonly responseType = apigateway_user_template_pb.DeleteUserTemplateReply;
  }
  export class UpdateUserTemplate {
    static readonly methodName = "UpdateUserTemplate";
    static readonly service = TemplateService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_user_template_pb.UpdateUserTemplateRequest;
    static readonly responseType = apigateway_user_template_pb.UpdateUserTemplateReply;
  }
}
