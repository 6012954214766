import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'
import {
  GetServicesRequest,
  GetUsersRequest
} from '~/stub/apigateway/received_favorite/received_favorite_pb'
import { ReceivedFavoriteService } from '~/stub/apigateway/received_favorite/received_favorite_pb_service'
import { GetReceivedFavoriteBlogsRequest } from '~/stub/apigateway/profile/received_favorite_pb'
import { ReceivedFavoriteService as ReceivedFavoriteServiceBlog } from '~/stub/apigateway/profile/received_favorite_pb_service'

const DEFAULT_PER_PAGE = 30

/**
 * ブログのフォロワー一覧を生成する
 * @param followersList
 * @param blogsList
 * @param userId
 * @returns {[]}
 */
function generateBlogFollowers({ followersList, blogsList, userId }) {
  return blogsList.reduce((accumulator, blog) => {
    const follower = followersList.find(follower => blog.userId === follower.userId)
    if (follower) {
      accumulator.push({
        userId: follower.userId,
        userName: follower.userName,
        userIcon: follower.userIconResizable,
        sex: follower.sex,
        lastLoginAt: follower.lastLoginAt,
        bodyText: blog.blogTitle,
        bodyTextLinkPath: `/blogs/${userId}/${blog.blogId}`,
        followedAt: blog.followedAt,
        providerRank: follower.providerRank
      })
    }
    return accumulator
  }, [])
}

/**
 * サービスのフォロワー一覧を生成する
 * @param followers
 * @returns {[]}
 */
function generateServiceFollowers(followers) {
  const array = []
  followers.forEach(follower => {
    array.push({
      userId: follower.userId,
      userName: follower.userName,
      userIcon: follower.userIconResizable,
      sex: follower.sex,
      lastLoginAt: follower.lastLoginAt,
      bodyText: follower.serviceName,
      bodyTextLinkPath: `/services/${follower.serviceId}`,
      followedAt: follower.followedAt,
      providerRank: follower.providerRank
    })
  })
  return array
}

/**
 * ユーザーのフォロワー一覧を生成する
 * @param followers
 * @returns {[]}
 */
function generateUserFollowers(followers) {
  const array = []
  followers.forEach(follower => {
    array.push({
      userId: follower.userId,
      userName: follower.userName,
      userIcon: follower.userIconResizable,
      sex: follower.sex,
      lastLoginAt: follower.lastLoginAt,
      followedAt: follower.followedAt,
      providerRank: follower.providerRank
    })
  })
  return array
}

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchServiceFollowers({ commit, rootState }, { currentPage, perPage = DEFAULT_PER_PAGE }) {
    const metadata = commonMetaData(rootState)
    const request = new GetServicesRequest()
    request.setPage(currentPage)
    request.setPerPage(perPage)
    const response = await grpcClient({
      method: ReceivedFavoriteService.GetServices,
      request,
      metadata
    })

    const followers = generateServiceFollowers(response.followersList)
    return {
      followers,
      totalCount: response.totalCount
    }
  },

  async fetchBlogFollowers(
    { commit, rootState },
    { currentPage, perPage = DEFAULT_PER_PAGE, userId }
  ) {
    const metadata = commonMetaData(rootState)
    const request = new GetReceivedFavoriteBlogsRequest()
    request.setPage(currentPage)
    request.setPerPage(perPage)
    const response = await grpcClient({
      method: ReceivedFavoriteServiceBlog.GetReceivedFavoriteBlogs,
      request,
      metadata
    })

    const followers = generateBlogFollowers({
      followersList: response.followersList,
      blogsList: response.blogsList,
      userId
    })
    return {
      followers,
      totalCount: response.totalCount
    }
  },

  async fetchUserFollowers({ commit, rootState }, { currentPage, perPage = DEFAULT_PER_PAGE }) {
    const metadata = commonMetaData(rootState)
    const request = new GetUsersRequest()
    request.setPage(currentPage)
    request.setPerPage(perPage)
    const response = await grpcClient({
      method: ReceivedFavoriteService.GetUsers,
      request,
      metadata
    })

    const followers = generateUserFollowers(response.usersList)
    return {
      followers,
      totalCount: response.totalCount
    }
  }
}

// mutations
export const mutations = {}
