<template>
  <i
    class="coconala-icon"
    :class="{ [`-${name}`]: true, [`-is-${color}`]: !!color }"
    :style="{ 'font-size': size }"
  ></i>
</template>
<script>
export const ICON_COLORS = Object.freeze([
  '',
  'primary',
  'secondary',
  'special',
  'white',
  'yellow',
  'red',
  'ds2Red',
  'gray200',
  'gray300',
  'gray400',
  'gray500',
  'gray600',
  'gray700',
  'gray800',
  'ds2Primary',
  'ds2Primary600',
  'ds2Secondary',
  'ds2Secondary900',
  'ds2Orange',
  'ds2Gray25',
  'ds2Gray100',
  'ds2Gray200',
  'ds2Gray400',
  'ds2Gray600',
  'ds2Gray700',
  'ds2Gray900',
  'ds2Pink',
  'ds2Silver'
])

export default {
  name: 'CoconalaIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String
    },
    color: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
a.-is,
.-is {
  &-primary {
    color: $ds2-color-app-primary;
  }
  &-secondary {
    color: $ds2-color-app-secondary;
  }
  &-special {
    color: $ds2-color-app-special;
  }
  &-black {
    color: $ds2-color-font-base;
  }
  &-white {
    color: $color-white;
  }
  &-yellow {
    color: $color-yellow-500;
  }
  &-red {
    color: $color-app-error;
  }
  &-ds2Red {
    color: $ds2-color-red;
  }
  &-gray200 {
    color: $ds2-color-gray-50;
  }
  &-gray300 {
    color: $ds2-color-gray-100;
  }
  &-gray400 {
    color: $ds2-color-gray-200;
  }
  &-gray500 {
    color: $ds2-color-gray-200;
  }
  &-gray600 {
    color: $ds2-color-gray-400;
  }
  &-gray700 {
    color: $ds2-color-gray-600;
  }
  &-gray800 {
    color: $ds2-color-gray-700;
  }
  &-ds2Primary {
    color: $ds2-color-brand-primary;
  }
  &-ds2Primary600 {
    color: $ds2-color-brand-primary-600;
  }
  &-ds2Secondary {
    color: $ds2-color-brand-secondary;
  }
  &-ds2Secondary900 {
    color: $ds2-color-brand-secondary-900;
  }
  &-ds2Orange {
    color: $ds2-color-orange;
  }
  &-ds2Gray25 {
    color: $ds2-color-gray-25;
  }
  &-ds2Gray200 {
    color: $ds2-color-gray-200;
  }
  &-ds2Gray100 {
    color: $ds2-color-gray-100;
  }
  &-ds2Gray400 {
    color: $ds2-color-gray-400;
  }
  &-ds2Gray600 {
    color: $ds2-color-gray-600;
  }
  &-ds2Gray700 {
    color: $ds2-color-gray-700;
  }
  &-ds2Gray900 {
    color: $ds2-color-gray-900;
  }
  &-ds2Pink {
    color: $ds2-color-pink;
  }
  &-ds2Silver {
    color: $ds2-color-silver;
  }
}
</style>
