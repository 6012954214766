// package: coconala.protobuf.gw.translate
// file: gw/translate/translate_service.proto

import * as gw_translate_translate_service_pb from "../../gw/translate/translate_service_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
import * as domain_exchange_rate_pb from "../../domain/exchange_rate_pb";
export class TranslateService {
  static serviceName = "coconala.protobuf.gw.translate.TranslateService";
}
export namespace TranslateService {
  export class SaveUserTranslateInfo {
    static readonly methodName = "SaveUserTranslateInfo";
    static readonly service = TranslateService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_translate_translate_service_pb.SaveUserTranslateInfoRequest;
    static readonly responseType = gw_translate_translate_service_pb.SaveUserTranslateInfoResponse;
  }
  export class TranslateText {
    static readonly methodName = "TranslateText";
    static readonly service = TranslateService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_translate_translate_service_pb.TranslateTextRequest;
    static readonly responseType = gw_translate_translate_service_pb.TranslateTextResponse;
  }
  export class ListExchangeRate {
    static readonly methodName = "ListExchangeRate";
    static readonly service = TranslateService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_translate_translate_service_pb.ListExchangeRateRequest;
    static readonly responseType = gw_translate_translate_service_pb.ListExchangeRateResponse;
  }
}
