export const state = () => ({
  viewId: ''
})

export const getters = {}

export const actions = {
  updateViewId({ rootState, commit }) {
    const ccuid = rootState.auth.ccuid || ''
    commit('SET_VIEW_ID', ccuid + new Date().getTime())
  }
}

export const mutations = {
  SET_VIEW_ID(state, viewId) {
    state.viewId = viewId
  }
}
