<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    class="c-logo"
    :class="{ [`-is-${currentType}`]: true }"
  >
    <g v-if="currentType === 'business'">
      <g clip-path="url(#clip0)">
        <path
          d="M163.407 0.577759H170.199C173.207 0.577759 176.392 1.79998 176.392 5.59998C176.392 7.82221 175.021 9.35553 172.942 9.91109V9.97776C175.508 10.2889 177.322 12.0222 177.322 14.8C177.322 18.6 174.003 20.4222 169.911 20.4222H163.407V0.577759ZM166.15 8.95553H169.756C171.88 8.95553 173.627 7.99998 173.627 5.86665C173.627 3.73331 171.946 2.88887 169.314 2.88887H166.173V8.95553H166.15ZM166.15 18.0666H169.601C171.946 18.0666 174.512 17.3333 174.512 14.6444C174.512 12.2889 172.875 11.3111 169.867 11.3111H166.15V18.0666Z"
          fill="white"
        />
        <path
          d="M189.842 20.4222C189.754 19.7555 189.732 18.8 189.732 18.2444H189.665C189.002 19.6666 187.276 20.8 185.352 20.8C181.923 20.8 180.419 18.4 180.419 15.4V7.17773H183.029V14.5111C183.029 16.6666 183.582 18.6 186.037 18.6C188.161 18.6 189.577 16.8888 189.577 14.2222V7.15552H192.187V17.5555C192.187 18.2666 192.209 19.5333 192.298 20.4222H189.842Z"
          fill="white"
        />
        <path
          d="M203.314 10.511C202.738 9.53327 201.588 8.86661 200.239 8.86661C199.022 8.86661 197.783 9.37772 197.783 10.5999C197.783 11.7111 198.779 12.1333 200.792 12.5777C203.336 13.1555 205.216 14.1555 205.216 16.4888C205.216 19.6222 202.628 20.7777 199.951 20.7777C197.938 20.7777 195.881 19.9999 194.62 18.3999L196.478 16.7999C197.186 17.8444 198.513 18.6444 199.995 18.6444C201.301 18.6444 202.606 18.1777 202.606 16.7111C202.606 15.4888 201.411 15.0666 199.155 14.4888C197.363 14.0666 195.306 13.3111 195.306 10.8222C195.306 7.99994 197.783 6.75549 200.305 6.75549C202.208 6.75549 204.132 7.51105 205.105 8.93328L203.314 10.511Z"
          fill="white"
        />
        <path
          d="M209.375 4.0222C208.402 4.0222 207.649 3.26664 207.649 2.33331C207.649 1.35553 208.402 0.622192 209.375 0.622192C210.348 0.622192 211.122 1.35553 211.122 2.33331C211.122 3.31108 210.348 4.0222 209.375 4.0222ZM210.68 20.4222H208.07V7.15553H210.68V20.4222Z"
          fill="white"
        />
        <path
          d="M216.874 7.13327C216.962 7.79994 216.984 8.7555 216.984 9.31106H217.073C217.737 7.88883 219.44 6.75549 221.364 6.75549C224.771 6.75549 226.297 9.19994 226.297 12.1555V20.3777H223.687V13.0222C223.687 10.8888 223.134 8.95549 220.679 8.95549C218.577 8.95549 217.139 10.6666 217.139 13.3333V20.3999H214.529V9.99994C214.529 9.28883 214.507 8.02216 214.418 7.13327H216.874Z"
          fill="white"
        />
        <path
          d="M242.401 14.5555H231.916C232.005 16.8222 233.885 18.5777 236.208 18.5777C237.999 18.5777 239.305 17.6888 240.057 16.5555L241.893 18.0444C240.477 19.9111 238.552 20.7555 236.208 20.7555C232.204 20.7555 229.24 17.9555 229.24 13.7777C229.24 9.64438 232.159 6.75549 236.075 6.75549C239.968 6.75549 242.424 9.37772 242.424 13.6444C242.424 13.9777 242.424 14.2444 242.401 14.5555ZM239.769 12.5999C239.681 10.4888 238.508 8.84438 236.053 8.84438C233.819 8.84438 232.049 10.4888 231.894 12.5999H239.769Z"
          fill="white"
        />
        <path
          d="M252.732 10.511C252.157 9.53327 251.006 8.86661 249.657 8.86661C248.44 8.86661 247.202 9.37772 247.202 10.5999C247.202 11.7111 248.197 12.1333 250.21 12.5777C252.754 13.1555 254.634 14.1555 254.634 16.4888C254.634 19.6222 252.046 20.7777 249.37 20.7777C247.356 20.7777 245.299 19.9999 244.038 18.3999L245.896 16.7999C246.604 17.8444 247.932 18.6444 249.414 18.6444C250.719 18.6444 252.024 18.1777 252.024 16.7111C252.024 15.4888 250.829 15.0666 248.573 14.4888C246.781 14.0666 244.724 13.3111 244.724 10.8222C244.724 7.99994 247.202 6.75549 249.723 6.75549C251.626 6.75549 253.55 7.51105 254.524 8.93328L252.732 10.511Z"
          fill="white"
        />
        <path
          d="M264.965 10.511C264.39 9.53327 263.239 8.86661 261.89 8.86661C260.673 8.86661 259.435 9.37772 259.435 10.5999C259.435 11.7111 260.43 12.1333 262.443 12.5777C264.987 13.1555 266.867 14.1555 266.867 16.4888C266.867 19.6222 264.279 20.7777 261.602 20.7777C259.589 20.7777 257.532 19.9999 256.271 18.3999L258.129 16.7999C258.837 17.8444 260.165 18.6444 261.647 18.6444C262.952 18.6444 264.257 18.1777 264.257 16.7111C264.257 15.4888 263.062 15.0666 260.806 14.4888C259.014 14.0666 256.957 13.3111 256.957 10.8222C256.957 7.99994 259.435 6.75549 261.956 6.75549C263.859 6.75549 265.783 7.51105 266.757 8.93328L264.965 10.511Z"
          fill="white"
        />
        <path
          d="M55.4727 20.7711C53.4449 20.7711 51.7659 20.1178 50.4357 18.8111C49.1055 17.5045 48.4419 15.8304 48.4448 13.7889C48.4448 11.7519 49.1085 10.0741 50.4357 8.75557C51.763 7.43705 53.442 6.77927 55.4727 6.78224C57.5211 6.78224 59.2045 7.43631 60.5229 8.74446C61.8413 10.0526 62.5005 11.7341 62.5005 13.7889C62.5005 15.8289 61.8413 17.503 60.5229 18.8111C59.2045 20.1193 57.5211 20.7726 55.4727 20.7711ZM52.796 16.5222C53.5167 17.2181 54.4775 17.6067 55.4771 17.6067C56.4767 17.6067 57.4375 17.2181 58.1582 16.5222C58.8291 15.7692 59.2001 14.794 59.2001 13.7833C59.2001 12.7727 58.8291 11.7975 58.1582 11.0445C57.4334 10.3567 56.4741 9.97352 55.4771 9.97352C54.4801 9.97352 53.5208 10.3567 52.796 11.0445C52.1264 11.7982 51.7563 12.7731 51.7563 13.7833C51.7563 14.7936 52.1264 15.7685 52.796 16.5222V16.5222Z"
          fill="white"
        />
        <path
          d="M86.3049 20.7711C84.2742 20.7711 82.5952 20.1178 81.268 18.8111C79.9407 17.5045 79.2771 15.8304 79.2771 13.7889C79.2771 11.7519 79.9407 10.0741 81.268 8.75557C82.5952 7.43705 84.2742 6.77927 86.3049 6.78224C88.3533 6.78224 90.0367 7.43631 91.3551 8.74446C92.6736 10.0526 93.3328 11.7341 93.3328 13.7889C93.3328 15.8289 92.6736 17.503 91.3551 18.8111C90.0367 20.1193 88.3533 20.7726 86.3049 20.7711ZM83.6239 16.5222C84.3445 17.2181 85.3053 17.6067 86.3049 17.6067C87.3045 17.6067 88.2653 17.2181 88.986 16.5222C89.657 15.7692 90.0279 14.794 90.0279 13.7833C90.0279 12.7727 89.657 11.7975 88.986 11.0445C88.2612 10.3567 87.302 9.97352 86.3049 9.97352C85.3079 9.97352 84.3487 10.3567 83.6239 11.0445C82.9543 11.7982 82.5842 12.7731 82.5842 13.7833C82.5842 14.7936 82.9543 15.7685 83.6239 16.5222V16.5222Z"
          fill="white"
        />
        <path
          d="M134.126 17.6289C133.753 17.6487 133.385 17.5344 133.088 17.3067C132.959 17.1992 132.856 17.063 132.788 16.9087C132.72 16.7544 132.689 16.5863 132.697 16.4178V0.506714C132.697 0.402985 132.656 0.303504 132.583 0.230157C132.51 0.156809 132.411 0.115601 132.308 0.115601H129.828C129.725 0.115601 129.626 0.156809 129.553 0.230157C129.479 0.303504 129.438 0.402985 129.438 0.506714V16.6356C129.438 17.8534 129.81 18.8467 130.545 19.5934C131.279 20.3401 132.277 20.7045 133.513 20.7045C134.335 20.6854 135.144 20.5011 135.893 20.1623C135.979 20.1281 136.049 20.0647 136.092 19.9833C136.135 19.9019 136.148 19.8078 136.128 19.7178L135.761 17.7178C135.75 17.6637 135.729 17.6124 135.697 17.5673C135.666 17.5221 135.626 17.484 135.579 17.4555C135.532 17.427 135.479 17.4087 135.425 17.4019C135.371 17.395 135.315 17.3997 135.263 17.4156C134.896 17.5406 134.513 17.6124 134.126 17.6289V17.6289Z"
          fill="white"
        />
        <path
          d="M95.8677 13.24C95.8677 11.2356 96.4664 9.65853 97.6639 8.5089C98.8614 7.35927 100.423 6.78371 102.349 6.78223C104.259 6.78223 105.816 7.35779 107.021 8.5089C108.226 9.66001 108.829 11.237 108.831 13.24V20.1289C108.831 20.1804 108.82 20.2315 108.801 20.2791C108.781 20.3267 108.752 20.3699 108.716 20.4062C108.679 20.4426 108.636 20.4713 108.589 20.4909C108.541 20.5104 108.49 20.5203 108.439 20.52H105.966C105.863 20.52 105.764 20.4788 105.691 20.4054C105.618 20.3321 105.577 20.2326 105.577 20.1289V13.3289C105.577 12.2652 105.277 11.4415 104.678 10.8578C104.033 10.2914 103.206 9.97923 102.349 9.97923C101.492 9.97923 100.665 10.2914 100.02 10.8578C99.4196 11.443 99.1202 12.2667 99.1217 13.3289V20.1245C99.1217 20.176 99.1115 20.227 99.0918 20.2746C99.0721 20.3222 99.0432 20.3655 99.0069 20.4018C98.9705 20.4381 98.9273 20.4669 98.8798 20.4864C98.8323 20.506 98.7814 20.5158 98.7301 20.5156H96.257C96.1538 20.5156 96.0547 20.4744 95.9817 20.401C95.9087 20.3277 95.8677 20.2282 95.8677 20.1245V13.24Z"
          fill="white"
        />
        <path
          d="M118.296 20.7711C117.41 20.7919 116.53 20.6259 115.712 20.2837C114.894 19.9415 114.156 19.4308 113.547 18.7845C112.271 17.46 111.633 15.7948 111.633 13.7889C111.633 11.783 112.306 10.1096 113.651 8.76889C114.996 7.44445 116.7 6.78223 118.765 6.78223C120.76 6.78223 122.422 7.42742 123.749 8.71779C125.076 10.0082 125.74 11.6289 125.74 13.58V20.12C125.74 20.2237 125.699 20.3232 125.626 20.3966C125.553 20.4699 125.454 20.5111 125.351 20.5111H123.116C123.013 20.5111 122.914 20.4699 122.841 20.3966C122.768 20.3232 122.727 20.2237 122.727 20.12V18.3133C121.718 19.9519 120.241 20.7711 118.296 20.7711ZM121.393 16.5222C122.07 15.775 122.446 14.8007 122.446 13.79C122.446 12.7793 122.07 11.805 121.393 11.0578C120.67 10.3621 119.707 9.97374 118.705 9.97374C117.704 9.97374 116.741 10.3621 116.018 11.0578C115.331 11.8003 114.949 12.7764 114.949 13.79C114.949 14.8037 115.331 15.7798 116.018 16.5222C116.741 17.2179 117.704 17.6063 118.705 17.6063C119.707 17.6063 120.67 17.2179 121.393 16.5222V16.5222Z"
          fill="white"
        />
        <path
          d="M144.138 20.7711C143.252 20.7917 142.371 20.6255 141.553 20.2834C140.734 19.9412 139.996 19.4306 139.386 18.7844C138.112 17.46 137.475 15.7948 137.475 13.7889C137.475 11.783 138.147 10.1096 139.49 8.76889C140.835 7.44445 142.54 6.78223 144.605 6.78223C146.6 6.78223 148.262 7.42742 149.591 8.71779C150.919 10.0082 151.583 11.6289 151.582 13.58V20.12C151.582 20.1716 151.571 20.2226 151.552 20.2702C151.532 20.3178 151.503 20.361 151.467 20.3973C151.43 20.4337 151.387 20.4625 151.34 20.482C151.292 20.5015 151.241 20.5114 151.19 20.5111H148.951C148.848 20.5111 148.749 20.4699 148.676 20.3966C148.603 20.3232 148.562 20.2237 148.562 20.12V18.3133C147.556 19.9519 146.082 20.7711 144.138 20.7711ZM147.222 16.5222C147.9 15.7757 148.277 14.8011 148.277 13.79C148.277 12.7789 147.9 11.8043 147.222 11.0578C146.498 10.3621 145.535 9.97374 144.534 9.97374C143.532 9.97374 142.57 10.3621 141.846 11.0578C141.161 11.801 140.78 12.7768 140.78 13.79C140.78 14.8032 141.161 15.779 141.846 16.5222C142.57 17.2179 143.532 17.6063 144.534 17.6063C145.535 17.6063 146.498 17.2179 147.222 16.5222V16.5222Z"
          fill="white"
        />
        <path
          d="M42.9036 17.0067C42.3065 17.406 41.6034 17.6151 40.8862 17.6067C40.4086 17.6193 39.9339 17.529 39.494 17.342C39.0541 17.1549 38.6591 16.8754 38.3356 16.5223C37.6807 15.7641 37.3201 14.7939 37.3201 13.79C37.3201 12.7862 37.6807 11.816 38.3356 11.0578C38.6594 10.7051 39.0545 10.426 39.4943 10.2389C39.9342 10.0519 40.4087 9.96138 40.8862 9.97337C41.6081 9.96059 42.3167 10.17 42.9169 10.5734C43.2061 10.7688 43.4629 11.0088 43.6778 11.2845C43.7397 11.3619 43.8287 11.4126 43.9266 11.4263C44.0245 11.4399 44.1238 11.4154 44.2043 11.3578L46.2505 9.91781C46.2937 9.88776 46.3304 9.84924 46.3583 9.80457C46.3863 9.7599 46.405 9.71001 46.4132 9.6579C46.4215 9.60579 46.4192 9.55254 46.4064 9.50135C46.3937 9.45016 46.3707 9.40209 46.339 9.36005C46.0313 8.95785 45.678 8.59315 45.286 8.27338C44.0499 7.27192 42.5002 6.74354 40.9127 6.78226C38.9174 6.78226 37.2686 7.44448 35.9665 8.76893C34.6643 10.0934 34.0139 11.7667 34.0154 13.7889C34.0154 15.8141 34.6657 17.4837 35.9665 18.7978C37.2672 20.1119 38.9159 20.7697 40.9127 20.7712C42.502 20.814 44.0551 20.2905 45.2971 19.2934C45.6947 18.9738 46.052 18.6066 46.3611 18.2C46.3925 18.1579 46.4152 18.1098 46.4278 18.0587C46.4404 18.0075 46.4426 17.9544 46.4344 17.9023C46.4261 17.8503 46.4076 17.8005 46.3798 17.7558C46.352 17.7111 46.3155 17.6725 46.2726 17.6423L44.2264 16.2045C44.1459 16.1469 44.0466 16.1224 43.9487 16.136C43.8508 16.1497 43.7618 16.2004 43.7 16.2778C43.4756 16.5625 43.2066 16.8087 42.9036 17.0067V17.0067Z"
          fill="white"
        />
        <path
          d="M73.7359 17.0067C73.1388 17.406 72.4357 17.6151 71.7184 17.6067C71.2409 17.6192 70.7662 17.529 70.3263 17.3419C69.8863 17.1549 69.4914 16.8754 69.1679 16.5222C68.513 15.764 68.1524 14.7939 68.1524 13.79C68.1524 12.7862 68.513 11.816 69.1679 11.0578C69.4917 10.7051 69.8868 10.4259 70.3266 10.2389C70.7664 10.0519 71.241 9.96134 71.7184 9.97334C72.4404 9.96055 73.149 10.1699 73.7491 10.5733C74.0381 10.7683 74.2942 11.0084 74.5079 11.2845C74.5698 11.3619 74.6588 11.4126 74.7566 11.4262C74.8545 11.4399 74.9539 11.4154 75.0344 11.3578L77.0828 9.91778C77.1257 9.88752 77.1621 9.84892 77.1899 9.80424C77.2177 9.75957 77.2363 9.70974 77.2445 9.65772C77.2528 9.60569 77.2506 9.55252 77.238 9.50138C77.2254 9.45024 77.2027 9.40218 77.1713 9.36001C76.8634 8.9573 76.5092 8.59254 76.1161 8.27334C74.8807 7.27222 73.3318 6.74384 71.745 6.78223C69.7482 6.78223 68.0994 7.44445 66.7987 8.76889C65.498 10.0933 64.8477 11.7667 64.8477 13.7889C64.8477 15.8141 65.498 17.4837 66.7987 18.7978C68.0994 20.1119 69.7482 20.7696 71.745 20.7711C73.3298 20.811 74.8777 20.2876 76.1161 19.2933C76.5144 18.9738 76.8725 18.6066 77.1823 18.2C77.2137 18.1577 77.2362 18.1095 77.2486 18.0583C77.261 18.0071 77.2631 17.9538 77.2546 17.9018C77.2461 17.8498 77.2274 17.8 77.1994 17.7554C77.1714 17.7108 77.1347 17.6723 77.0916 17.6422L75.0476 16.2045C74.9672 16.1469 74.8678 16.1224 74.7699 16.136C74.672 16.1496 74.583 16.2004 74.5212 16.2778C74.3001 16.5616 74.0349 16.8077 73.7359 17.0067V17.0067Z"
          fill="white"
        />
        <g clip-path="url(#clip0)">
          <path
            d="M14.2017 13H4.73389L2.36694 17.1208L16.5686 25.3624L18.9356 21.2416L14.2017 13Z"
            fill="#009DFF"
          />
          <path
            d="M18.9355 21.2416H23.6694V4.75842H18.9355L14.2017 13L18.9355 21.2416Z"
            fill="#FFEA00"
          />
          <path
            d="M4.73389 13.0001L2.36694 8.87928L16.5686 0.637695L18.9356 4.75849L14.2017 13.0001H4.73389Z"
            fill="#EE4499"
          />
          <path
            d="M0 13C0 15.6266 2.12078 17.7584 4.73389 17.7584C5.61912 17.7584 6.85467 17.2778 7.56475 16.8496L14.2017 13L7.56475 9.15044C6.85467 8.72694 5.61912 8.24158 4.73389 8.24158C2.12078 8.24158 0 10.3734 0 13Z"
            fill="#B033DD"
          />
          <path
            d="M21.3026 25.3624C23.5654 24.049 24.3417 21.1369 23.0352 18.8624C22.5949 18.0915 21.5582 17.254 20.8386 16.8496L14.2017 13V20.7039C14.1923 21.5318 14.3958 22.8499 14.8361 23.6208C16.1426 25.9001 19.0398 26.6757 21.3026 25.3624Z"
            fill="#22CCBB"
          />
          <path
            d="M21.3026 0.637606C19.0398 -0.675718 16.1426 0.104663 14.8361 2.37919C14.3958 3.15005 14.1923 4.46813 14.2017 5.2961V13L20.8386 9.15042C21.5582 8.74595 22.5949 7.90847 23.0352 7.13761C24.3417 4.86308 23.5654 1.95093 21.3026 0.637606Z"
            fill="#FF9900"
          />
        </g>
      </g>
    </g>
    <g v-else-if="currentType === 'businessShort'">
      <g clip-path="url(#clip0_3179_89180)">
        <path
          d="M108.683 125.975H36.2273L18.1133 157.349L126.797 220.096L144.911 188.722L108.683 125.975Z"
          fill="#009DFF"
        />
        <path
          d="M144.912 188.722H181.14V63.228H144.912L108.684 125.975L144.912 188.722Z"
          fill="#FFEA00"
        />
        <path
          d="M36.2273 125.975L18.1133 94.6015L126.797 31.8545L144.911 63.228L108.683 125.975H36.2273Z"
          fill="#EE4499"
        />
        <path
          d="M0 125.975C0 145.973 16.2302 162.203 36.228 162.203C43.0027 162.203 52.4582 158.544 57.8924 155.284L108.684 125.975L57.8924 96.6666C52.4582 93.4423 43.0027 89.7471 36.228 89.7471C16.2302 89.7471 0 105.977 0 125.975Z"
          fill="#B030DD"
        />
        <path
          d="M163.026 220.095C180.343 210.097 186.285 187.925 176.286 170.608C172.916 164.739 164.982 158.363 159.476 155.284L108.684 125.975V184.628C108.612 190.932 110.169 200.967 113.539 206.836C123.538 224.189 145.709 230.094 163.026 220.095Z"
          fill="#22CCBB"
        />
        <path
          d="M163.026 31.8543C145.709 21.8554 123.538 27.7968 113.539 45.1138C110.169 50.9827 108.612 61.0179 108.684 67.3216V125.975L159.476 96.6663C164.982 93.5869 172.916 87.2108 176.286 81.3418C186.285 64.0248 180.343 41.8533 163.026 31.8543Z"
          fill="#FF9900"
        />
      </g>
      <path
        d="M248.9 123.29H282.67C297.62 123.29 313.39 129.38 313.39 148.06C313.39 158.99 306.61 166.6 296.23 169.37V169.65C308.96 171.17 317.96 179.75 317.96 193.45C317.96 212.27 301.49 221.27 281.15 221.27H248.91V123.29H248.9ZM262.6 164.67H280.45C290.97 164.67 299.69 159.96 299.69 149.45C299.69 138.94 291.39 134.78 278.24 134.78H262.6V164.67ZM262.6 209.65H279.76C291.38 209.65 304.12 206.05 304.12 192.77C304.12 181.15 295.95 176.3 281.01 176.3H262.6V209.65Z"
        fill="white"
      />
      <path
        d="M380.14 221.27C379.72 217.95 379.59 213.24 379.59 210.48H379.31C375.99 217.54 367.41 223.07 357.86 223.07C340.84 223.07 333.36 211.17 333.36 196.36V155.67H346.37V191.93C346.37 202.59 349.14 212.13 361.32 212.13C371.84 212.13 378.9 203.69 378.9 190.54V155.67H391.91V207.15C391.91 210.61 392.05 216.98 392.46 221.27H380.14Z"
        fill="white"
      />
      <path
        d="M447.13 172.28C444.22 167.44 438.55 164.11 431.91 164.11C425.82 164.11 419.73 166.6 419.73 172.69C419.73 178.23 424.71 180.3 434.68 182.52C447.27 185.43 456.68 190.27 456.68 201.89C456.68 217.39 443.81 223.06 430.52 223.06C420.56 223.06 410.31 219.19 404.09 211.3L413.36 203.41C416.82 208.53 423.46 212.54 430.8 212.54C437.3 212.54 443.81 210.19 443.81 202.99C443.81 196.9 437.86 194.82 426.65 192.06C417.79 189.98 407.55 186.25 407.55 173.93C407.55 159.95 419.87 153.86 432.32 153.86C441.73 153.86 451.28 157.6 456.12 164.65L447.12 172.26L447.13 172.28Z"
        fill="white"
      />
      <path
        d="M477.19 140.31C472.35 140.31 468.61 136.57 468.61 132.01C468.61 127.17 472.35 123.57 477.19 123.57C482.03 123.57 485.91 127.17 485.91 132.01C485.91 136.85 482.04 140.31 477.19 140.31ZM483.69 221.27H470.68V155.67H483.69V221.27Z"
        fill="white"
      />
      <path
        d="M514.39 155.67C514.81 158.99 514.94 163.7 514.94 166.46H515.36C518.68 159.4 527.12 153.87 536.67 153.87C553.55 153.87 561.17 165.91 561.17 180.58V221.27H548.16V184.87C548.16 174.35 545.39 164.8 533.21 164.8C522.83 164.8 515.63 173.24 515.63 186.39V221.26H502.62V169.78C502.62 166.32 502.48 159.95 502.07 155.66H514.39V155.67Z"
        fill="white"
      />
      <path
        d="M641.09 192.35H589.06C589.48 203.56 598.89 212.28 610.37 212.28C619.23 212.28 625.73 207.85 629.47 202.32L638.6 209.65C631.54 218.92 621.99 223.07 610.37 223.07C590.44 223.07 575.77 209.23 575.77 188.61C575.77 167.99 590.3 153.87 609.67 153.87C629.04 153.87 641.22 166.88 641.22 187.91C641.22 189.43 641.22 190.82 641.08 192.34L641.09 192.35ZM628.08 182.66C627.66 172.28 621.85 164.12 609.67 164.12C598.6 164.12 589.74 172.29 589.05 182.66H628.08Z"
        fill="white"
      />
      <path
        d="M692.37 172.28C689.46 167.44 683.79 164.11 677.15 164.11C671.06 164.11 664.97 166.6 664.97 172.69C664.97 178.23 669.95 180.3 679.92 182.52C692.51 185.43 701.92 190.27 701.92 201.89C701.92 217.39 689.05 223.06 675.76 223.06C665.8 223.06 655.55 219.19 649.33 211.3L658.6 203.41C662.06 208.53 668.7 212.54 676.04 212.54C682.54 212.54 689.05 210.19 689.05 202.99C689.05 196.9 683.1 194.82 671.89 192.06C663.03 189.98 652.79 186.25 652.79 173.93C652.79 159.95 665.11 153.86 677.56 153.86C686.97 153.86 696.52 157.6 701.36 164.65L692.36 172.26L692.37 172.28Z"
        fill="white"
      />
      <path
        d="M753.16 172.28C750.25 167.44 744.58 164.11 737.94 164.11C731.85 164.11 725.76 166.6 725.76 172.69C725.76 178.23 730.74 180.3 740.71 182.52C753.3 185.43 762.71 190.27 762.71 201.89C762.71 217.39 749.84 223.06 736.55 223.06C726.59 223.06 716.34 219.19 710.12 211.3L719.39 203.41C722.85 208.53 729.49 212.54 736.83 212.54C743.33 212.54 749.84 210.19 749.84 202.99C749.84 196.9 743.89 194.82 732.68 192.06C723.82 189.98 713.58 186.25 713.58 173.93C713.58 159.95 725.9 153.86 738.35 153.86C747.76 153.86 757.31 157.6 762.15 164.65L753.15 172.26L753.16 172.28Z"
        fill="white"
      />
      <path
        d="M353.18 100.695C343.041 100.695 334.656 97.445 328.027 90.9454C321.397 84.4457 318.082 76.1261 318.082 65.9867C318.082 55.8472 321.397 47.5276 328.027 40.9629C334.656 34.3983 343.041 31.1484 353.18 31.1484C363.385 31.1484 371.834 34.3983 378.399 40.8979C384.964 47.3976 388.278 55.7822 388.278 65.9867C388.278 76.1261 384.964 84.4457 378.399 90.9454C371.834 97.445 363.385 100.695 353.18 100.695ZM339.791 79.571C343.236 83.1458 347.721 84.9657 353.18 84.9657C358.64 84.9657 363.125 83.1458 366.57 79.571C370.014 75.9961 371.769 71.4464 371.769 65.9867C371.769 60.4619 370.014 55.9122 366.57 52.3374C363.125 48.7625 358.64 47.0076 353.18 47.0076C347.721 47.0076 343.236 48.7625 339.791 52.3374C336.346 55.9122 334.591 60.4619 334.591 65.9867C334.591 71.4464 336.346 75.9961 339.791 79.571Z"
        fill="white"
      />
      <path
        d="M507.157 100.695C497.017 100.695 488.633 97.445 482.003 90.9454C475.373 84.4457 472.059 76.1261 472.059 65.9867C472.059 55.8472 475.373 47.5276 482.003 40.9629C488.633 34.3983 497.017 31.1484 507.157 31.1484C517.361 31.1484 525.811 34.3983 532.376 40.8979C538.94 47.3976 542.255 55.7822 542.255 65.9867C542.255 76.1261 538.94 84.4457 532.376 90.9454C525.811 97.445 517.361 100.695 507.157 100.695ZM493.768 79.571C497.212 83.1458 501.697 84.9657 507.157 84.9657C512.617 84.9657 517.101 83.1458 520.546 79.571C523.991 75.9961 525.746 71.4464 525.746 65.9867C525.746 60.4619 523.991 55.9122 520.546 52.3374C517.101 48.7625 512.617 47.0076 507.157 47.0076C501.697 47.0076 497.212 48.7625 493.768 52.3374C490.323 55.9122 488.568 60.4619 488.568 65.9867C488.568 71.4464 490.323 75.9961 493.768 79.571Z"
        fill="white"
      />
      <path
        d="M746.02 85.0956C743.94 85.0956 742.185 84.5756 740.82 83.4706C739.455 82.3657 738.87 80.8708 738.87 79.0509V-0.0500995C738.87 -1.15504 738.025 -2 736.92 -2H724.571C723.466 -2 722.621 -1.15504 722.621 -0.0500995V80.1558C722.621 86.2005 724.506 91.1402 728.211 94.8451C731.916 98.5499 736.855 100.435 743.03 100.435C746.67 100.435 750.635 99.5248 754.924 97.7699C755.769 97.3799 756.289 96.535 756.094 95.625L754.274 85.6155C754.079 84.4456 752.91 83.7956 751.805 84.1206C749.53 84.7056 747.58 85.0956 746.02 85.0956Z"
        fill="white"
      />
      <path
        d="M554.93 63.2568C554.93 53.3123 557.92 45.4477 563.899 39.728C569.879 34.0083 577.679 31.1484 587.298 31.1484C596.853 31.1484 604.587 34.0083 610.632 39.728C616.677 45.4477 619.666 53.3123 619.666 63.2568V97.445C619.666 98.55 618.821 99.3949 617.717 99.3949H605.367C604.262 99.3949 603.417 98.55 603.417 97.445V63.6468C603.417 58.382 601.922 54.2873 598.932 51.3624C595.943 48.4376 592.043 47.0076 587.298 47.0076C582.553 47.0076 578.653 48.4376 575.664 51.3624C572.674 54.2873 571.179 58.382 571.179 63.6468V97.445C571.179 98.55 570.334 99.3949 569.229 99.3949H556.88C555.775 99.3949 554.93 98.55 554.93 97.445V63.2568V63.2568Z"
        fill="white"
      />
      <path
        d="M666.919 100.695C657.494 100.695 649.565 97.3801 643.195 90.8154C636.825 84.2507 633.641 75.9311 633.641 65.9867C633.641 55.9122 637.02 47.5926 643.715 41.0279C650.475 34.4633 658.989 31.1484 669.259 31.1484C679.203 31.1484 687.523 34.3333 694.153 40.7679C700.782 47.2026 704.097 55.2622 704.097 64.9467V97.445C704.097 98.55 703.252 99.3949 702.147 99.3949H690.968C689.863 99.3949 689.018 98.55 689.018 97.445V88.4755C684.013 96.6001 676.668 100.695 666.919 100.695ZM682.323 79.571C685.833 75.9961 687.588 71.4464 687.588 65.9867C687.588 60.5269 685.833 55.9772 682.323 52.4023C678.813 48.8275 674.329 47.0076 668.869 47.0076C663.474 47.0076 659.054 48.8275 655.479 52.4023C651.905 55.9772 650.15 60.5269 650.15 65.9867C650.15 71.4464 651.905 75.9961 655.479 79.571C659.054 83.1458 663.474 84.9657 668.869 84.9657C674.329 84.9657 678.813 83.1458 682.323 79.571Z"
        fill="white"
      />
      <path
        d="M796.001 100.695C786.576 100.695 778.647 97.3801 772.277 90.8154C765.908 84.2507 762.723 75.9311 762.723 65.9867C762.723 55.9122 766.102 47.5926 772.797 41.0279C779.492 34.4633 788.006 31.1484 798.341 31.1484C808.285 31.1484 816.605 34.3333 823.235 40.7679C829.864 47.2026 833.179 55.2622 833.179 64.9467V97.445C833.179 98.55 832.334 99.3949 831.229 99.3949H820.05C818.945 99.3949 818.1 98.55 818.1 97.445V88.4755C813.03 96.6001 805.685 100.695 796.001 100.695ZM811.405 79.571C814.915 75.9961 816.67 71.4464 816.67 65.9867C816.67 60.5269 814.915 55.9772 811.405 52.4023C807.895 48.8275 803.411 47.0076 797.951 47.0076C792.556 47.0076 788.136 48.8275 784.562 52.4023C780.987 55.9772 779.232 60.5269 779.232 65.9867C779.232 71.4464 780.987 75.9961 784.562 79.571C788.136 83.1458 792.556 84.9657 797.951 84.9657C803.411 84.9657 807.895 83.1458 811.405 79.571Z"
        fill="white"
      />
      <path
        d="M290.393 81.9758C287.403 83.9907 284.023 84.9657 280.318 84.9657C275.184 84.9657 270.959 83.1458 267.579 79.571C264.199 75.9961 262.509 71.4464 262.509 65.9867C262.509 60.5269 264.199 55.9772 267.579 52.4023C270.959 48.8275 275.184 47.0076 280.318 47.0076C284.153 47.0076 287.533 47.9826 290.458 49.9975C291.953 51.0374 293.188 52.2074 294.228 53.5073C294.877 54.2873 296.047 54.4822 296.827 53.8973L307.097 46.7476C308.007 46.0977 308.202 44.8627 307.552 43.9528C306.057 42.0029 304.302 40.183 302.287 38.5581C296.242 33.6183 288.963 31.1484 280.448 31.1484C270.504 31.1484 262.249 34.4633 255.75 41.0279C249.25 47.5926 246 55.9122 246 65.9867C246 76.0611 249.25 84.3157 255.75 90.8804C262.249 97.445 270.504 100.695 280.448 100.695C288.963 100.695 296.242 98.225 302.287 93.3503C304.302 91.7253 306.057 89.9054 307.617 87.8905C308.267 87.0456 308.072 85.7456 307.162 85.0957L296.957 77.946C296.112 77.3611 294.942 77.4911 294.358 78.336C293.253 79.766 291.953 80.9359 290.393 81.9758Z"
        fill="white"
      />
      <path
        d="M444.369 81.9758C441.379 83.9907 438 84.9657 434.295 84.9657C429.16 84.9657 424.935 83.1458 421.555 79.571C418.176 75.9961 416.486 71.4464 416.486 65.9867C416.486 60.5269 418.176 55.9772 421.555 52.4023C424.935 48.8275 429.16 47.0076 434.295 47.0076C438.13 47.0076 441.509 47.9826 444.434 49.9975C445.929 51.0374 447.164 52.2074 448.204 53.5073C448.854 54.2873 450.024 54.4822 450.804 53.8973L461.008 46.7476C461.918 46.0977 462.113 44.8627 461.463 43.9528C459.968 42.0029 458.214 40.183 456.199 38.5581C450.219 33.6183 442.939 31.1484 434.425 31.1484C424.48 31.1484 416.226 34.4633 409.726 41.0279C403.226 47.5926 399.977 55.9122 399.977 65.9867C399.977 76.0611 403.226 84.3157 409.726 90.8804C416.226 97.445 424.48 100.695 434.425 100.695C442.939 100.695 450.219 98.225 456.264 93.3503C458.279 91.7253 460.034 89.9054 461.593 87.8905C462.243 87.0456 462.048 85.7456 461.138 85.0957L450.934 77.946C450.089 77.3611 448.919 77.4911 448.334 78.336C447.229 79.766 445.929 80.9359 444.369 81.9758Z"
        fill="white"
      />
    </g>
    <g v-else-if="currentType === 'simple'">
      <defs>
        <polygon
          id="a"
          points="0 0.9062 290.47 0.9062 290.47 346 0 346"
        />
      </defs>
      <g
        fill="none"
        fill-rule="evenodd"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M13.1998 12.2427H4.39983L2.19983 16.0465L15.3998 23.6541L17.5998 19.8503L13.1998 12.2427Z"
            fill="#009DFF"
          />
          <path
            d="M17.6001 19.8503H22.0001V4.63504H17.6001L13.2001 12.2427L17.6001 19.8503Z"
            fill="#FFEA00"
          />
          <path
            d="M4.39983 12.2427L2.19983 8.43891L15.3998 0.831299L17.5998 4.63511L13.1998 12.2427H4.39983Z"
            fill="#EE4499"
          />
          <path
            d="M0 12.2427C0 14.6673 1.9712 16.6351 4.4 16.6351C5.2228 16.6351 6.3712 16.1915 7.0312 15.7962L13.2 12.2427L7.0312 8.68929C6.3712 8.29837 5.2228 7.85034 4.4 7.85034C1.9712 7.85034 0 9.81813 0 12.2427Z"
            fill="#B033DD"
          />
          <path
            d="M19.8001 23.6541C21.9033 22.4418 22.6249 19.7537 21.4105 17.6541C21.0013 16.9426 20.0377 16.1695 19.3689 15.7961L13.2001 12.2427V19.354C13.1913 20.1183 13.3805 21.335 13.7897 22.0465C15.0041 24.1505 17.6969 24.8664 19.8001 23.6541Z"
            fill="#22CCBB"
          />
          <path
            d="M19.8001 0.831256C17.6969 -0.381043 15.0041 0.339309 13.7897 2.43887C13.3805 3.15044 13.1913 4.36713 13.2001 5.1314V12.2427L19.3689 8.68924C20.0377 8.31588 21.0013 7.54282 21.4105 6.83126C22.6249 4.7317 21.9033 2.04355 19.8001 0.831256Z"
            fill="#FF9900"
          />
        </g>
        <path
          d="M50.2254 20.107C48.4078 20.107 46.9047 19.5057 45.7162 18.3032C44.5277 17.1007 43.9335 15.5614 43.9335 13.6855C43.9335 11.8095 44.5277 10.2703 45.7162 9.05575C46.9047 7.84119 48.4078 7.23993 50.2254 7.23993C52.0548 7.23993 53.5695 7.84119 54.7463 9.04372C55.9232 10.2463 56.5174 11.7975 56.5174 13.6855C56.5174 15.5614 55.9232 17.1007 54.7463 18.3032C53.5579 19.5057 52.0548 20.107 50.2254 20.107ZM47.8252 16.1988C48.4427 16.8602 49.2467 17.1969 50.2254 17.1969C51.2042 17.1969 52.0082 16.8602 52.6257 16.1988C53.2433 15.5374 53.5579 14.6956 53.5579 13.6855C53.5579 12.6633 53.2433 11.8216 52.6257 11.1602C52.0082 10.4988 51.2042 10.1741 50.2254 10.1741C49.2467 10.1741 48.4427 10.4988 47.8252 11.1602C47.2076 11.8216 46.893 12.6633 46.893 13.6855C46.893 14.6956 47.196 15.5374 47.8252 16.1988Z"
          fill="#333333"
        />
        <path
          d="M77.8171 20.107C75.9994 20.107 74.4964 19.5057 73.3079 18.3032C72.1194 17.1007 71.5251 15.5614 71.5251 13.6855C71.5251 11.8095 72.1194 10.2703 73.3079 9.05575C74.4964 7.84119 75.9994 7.23993 77.8171 7.23993C79.6465 7.23993 81.1612 7.84119 82.338 9.04372C83.5149 10.2463 84.1091 11.7975 84.1091 13.6855C84.1091 15.5614 83.5149 17.1007 82.338 18.3032C81.1612 19.5057 79.6581 20.107 77.8171 20.107ZM75.4169 16.1988C76.0344 16.8602 76.8384 17.1969 77.8171 17.1969C78.7959 17.1969 79.5999 16.8602 80.2174 16.1988C80.8349 15.5374 81.1495 14.6956 81.1495 13.6855C81.1495 12.6633 80.8349 11.8216 80.2174 11.1602C79.5999 10.4988 78.7959 10.1741 77.8171 10.1741C76.8384 10.1741 76.0344 10.4988 75.4169 11.1602C74.7993 11.8216 74.4847 12.6633 74.4847 13.6855C74.4847 14.6956 74.7993 15.5374 75.4169 16.1988Z"
          fill="#333333"
        />
        <path
          d="M120.637 17.2209C120.264 17.2209 119.95 17.1247 119.705 16.9202C119.46 16.7158 119.355 16.4392 119.355 16.1025V1.46772C119.355 1.26329 119.204 1.10696 119.006 1.10696H116.792C116.594 1.10696 116.443 1.26329 116.443 1.46772V16.3069C116.443 17.4253 116.78 18.3392 117.445 19.0247C118.109 19.7101 118.994 20.0588 120.101 20.0588C120.754 20.0588 121.464 19.8905 122.233 19.5658C122.385 19.4937 122.478 19.3373 122.443 19.169L122.117 17.3171C122.082 17.1006 121.872 16.9804 121.674 17.0405C121.266 17.1487 120.928 17.2209 120.637 17.2209Z"
          fill="#333333"
        />
        <path
          d="M86.3817 13.1804C86.3817 11.3406 86.9177 9.8855 87.9897 8.82727C89.0616 7.76904 90.4598 7.23993 92.1843 7.23993C93.8971 7.23993 95.2837 7.76904 96.3673 8.82727C97.4509 9.8855 97.9869 11.3406 97.9869 13.1804V19.5057C97.9869 19.7102 97.8354 19.8665 97.6374 19.8665H95.4235C95.2254 19.8665 95.074 19.7102 95.074 19.5057V13.2526C95.074 12.2785 94.806 11.5209 94.27 10.9798C93.734 10.4387 93.0349 10.1741 92.1843 10.1741C91.3337 10.1741 90.6346 10.4387 90.0986 10.9798C89.5627 11.5209 89.2947 12.2785 89.2947 13.2526V19.5057C89.2947 19.7102 89.1432 19.8665 88.9451 19.8665H86.7313C86.5332 19.8665 86.3817 19.7102 86.3817 19.5057V13.1804Z"
          fill="#333333"
        />
        <path
          d="M106.469 20.107C104.779 20.107 103.358 19.4937 102.216 18.2792C101.074 17.0646 100.503 15.5254 100.503 13.6855C100.503 11.8216 101.109 10.2823 102.309 9.06777C103.509 7.85322 105.036 7.23993 106.888 7.23993C108.671 7.23993 110.163 7.82917 111.351 9.01967C112.539 10.2102 113.134 11.7013 113.134 13.4931V19.5057C113.134 19.7102 112.982 19.8665 112.784 19.8665H110.78C110.582 19.8665 110.431 19.7102 110.431 19.5057V17.8462C109.522 19.3494 108.205 20.107 106.469 20.107ZM109.23 16.1988C109.86 15.5374 110.174 14.6956 110.174 13.6855C110.174 12.6754 109.86 11.8336 109.23 11.1722C108.601 10.5108 107.797 10.1741 106.818 10.1741C105.851 10.1741 105.059 10.5108 104.418 11.1722C103.777 11.8336 103.463 12.6754 103.463 13.6855C103.463 14.6956 103.777 15.5374 104.418 16.1988C105.059 16.8602 105.851 17.1969 106.818 17.1969C107.797 17.1969 108.601 16.8602 109.23 16.1988Z"
          fill="#333333"
        />
        <path
          d="M129.597 20.107C127.908 20.107 126.486 19.4937 125.344 18.2792C124.203 17.0646 123.632 15.5254 123.632 13.6855C123.632 11.8216 124.237 10.2823 125.438 9.06777C126.638 7.85322 128.164 7.23993 130.017 7.23993C131.8 7.23993 133.291 7.82917 134.479 9.01967C135.668 10.2102 136.262 11.7013 136.262 13.4931V19.5057C136.262 19.7102 136.111 19.8665 135.913 19.8665H133.908C133.71 19.8665 133.559 19.7102 133.559 19.5057V17.8462C132.662 19.3494 131.333 20.107 129.597 20.107ZM132.359 16.1988C132.988 15.5374 133.303 14.6956 133.303 13.6855C133.303 12.6754 132.988 11.8336 132.359 11.1722C131.73 10.5108 130.926 10.1741 129.947 10.1741C128.98 10.1741 128.187 10.5108 127.547 11.1722C126.906 11.8336 126.591 12.6754 126.591 13.6855C126.591 14.6956 126.906 15.5374 127.547 16.1988C128.187 16.8602 128.98 17.1969 129.947 17.1969C130.926 17.1969 131.73 16.8602 132.359 16.1988Z"
          fill="#333333"
        />
        <path
          d="M38.9582 16.6437C38.4222 17.0165 37.8163 17.1969 37.1522 17.1969C36.2317 17.1969 35.4743 16.8602 34.8684 16.1988C34.2625 15.5374 33.9596 14.6956 33.9596 13.6855C33.9596 12.6754 34.2625 11.8336 34.8684 11.1722C35.4743 10.5108 36.2317 10.1741 37.1522 10.1741C37.8396 10.1741 38.4455 10.3545 38.9698 10.7273C39.2378 10.9197 39.4592 11.1361 39.6456 11.3766C39.7622 11.5209 39.9719 11.557 40.1117 11.4488L41.9411 10.126C42.1042 10.0057 42.1391 9.77727 42.0226 9.60891C41.7546 9.24815 41.44 8.91145 41.0788 8.61081C40.0069 7.69689 38.7018 7.23993 37.1755 7.23993C35.3927 7.23993 33.913 7.85322 32.7478 9.06777C31.5826 10.2823 31 11.8216 31 13.6855C31 15.5494 31.5826 17.0766 32.7478 18.2912C33.913 19.5057 35.3927 20.107 37.1755 20.107C38.7018 20.107 40.0069 19.65 41.0905 18.7481C41.4517 18.4475 41.7663 18.1108 42.0459 17.738C42.1624 17.5817 42.1275 17.3412 41.9644 17.2209L40.135 15.8981C39.9835 15.7899 39.7738 15.814 39.6689 15.9703C39.4709 16.2349 39.2378 16.4513 38.9582 16.6437Z"
          fill="#333333"
        />
        <path
          d="M66.5613 16.6437C66.0253 17.0165 65.4195 17.1969 64.7553 17.1969C63.8348 17.1969 63.0774 16.8602 62.4715 16.1988C61.8657 15.5374 61.5627 14.6956 61.5627 13.6855C61.5627 12.6754 61.8657 11.8336 62.4715 11.1722C63.0774 10.5108 63.8348 10.1741 64.7553 10.1741C65.4428 10.1741 66.0486 10.3545 66.573 10.7273C66.841 10.9197 67.0624 11.1361 67.2488 11.3766C67.3653 11.5209 67.575 11.557 67.7149 11.4488L69.5675 10.126C69.7306 10.0057 69.7656 9.77727 69.6491 9.60891C69.3811 9.24815 69.0665 8.91145 68.7053 8.61081C67.61 7.69689 66.305 7.23993 64.7786 7.23993C62.9959 7.23993 61.5161 7.85322 60.3509 9.06777C59.1857 10.2823 58.6031 11.8216 58.6031 13.6855C58.6031 15.5494 59.1857 17.0766 60.3509 18.2912C61.5161 19.5057 62.9959 20.107 64.7786 20.107C66.305 20.107 67.61 19.65 68.6936 18.7481C69.0548 18.4475 69.3694 18.1108 69.6491 17.738C69.7656 17.5817 69.7306 17.3412 69.5675 17.2209L67.7382 15.8981C67.5867 15.7899 67.377 15.814 67.2721 15.9703C67.074 16.2349 66.841 16.4513 66.5613 16.6437Z"
          fill="#333333"
        />
      </g>
    </g>
    <g
      v-else
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g>
        <path
          d="M18.1184 16.5H6.0393L3.01953 21.7302L21.1381 32.1907L24.1579 26.9605L18.1184 16.5Z"
          fill="#009DFF"
        />
        <path
          d="M24.1582 26.9604H30.1977V6.03943H24.1582L18.1187 16.4999L24.1582 26.9604Z"
          fill="#FFEA00"
        />
        <path
          d="M6.0393 16.5L3.01953 11.2698L21.1381 0.809326L24.1579 6.03956L18.1184 16.5H6.0393Z"
          fill="#EE4499"
        />
        <path
          d="M0 16.5C0 19.8338 2.70571 22.5395 6.03953 22.5395C7.16892 22.5395 8.74524 21.9295 9.65117 21.386L18.1186 16.5L9.65117 11.614C8.74524 11.0765 7.16892 10.4604 6.03953 10.4604C2.70571 10.4604 0 13.1662 0 16.5Z"
          fill="#B033DD"
        />
        <path
          d="M27.1779 32.1907C30.0648 30.5238 31.0552 26.8276 29.3883 23.9407C28.8267 22.9623 27.504 21.8993 26.586 21.386L18.1186 16.5V26.278C18.1065 27.3289 18.3662 29.0018 18.9279 29.9802C20.5948 32.8732 24.291 33.8576 27.1779 32.1907Z"
          fill="#22CCBB"
        />
        <path
          d="M27.1779 0.809302C24.291 -0.857609 20.5948 0.132874 18.9279 3.01977C18.3662 3.99817 18.1065 5.67112 18.1186 6.722V16.5L26.586 11.614C27.504 11.1007 28.8267 10.0377 29.3883 9.0593C31.0552 6.17241 30.0648 2.47621 27.1779 0.809302Z"
          fill="#FF9900"
        />
      </g>
      <path
        d="M66.2083 26.07C63.6343 26.07 61.5058 25.245 59.8228 23.595C58.1398 21.945 57.2983 19.833 57.2983 17.259C57.2983 14.685 58.1398 12.573 59.8228 10.9065C61.5058 9.24004 63.6343 8.41504 66.2083 8.41504C68.7988 8.41504 70.9438 9.24004 72.6103 10.89C74.2768 12.54 75.1183 14.6685 75.1183 17.259C75.1183 19.833 74.2768 21.945 72.6103 23.595C70.9438 25.245 68.7988 26.07 66.2083 26.07ZM62.8093 20.7075C63.6838 21.615 64.8223 22.077 66.2083 22.077C67.5943 22.077 68.7328 21.615 69.6073 20.7075C70.4818 19.8 70.9273 18.645 70.9273 17.259C70.9273 15.8565 70.4818 14.7015 69.6073 13.794C68.7328 12.8865 67.5943 12.441 66.2083 12.441C64.8223 12.441 63.6838 12.8865 62.8093 13.794C61.9348 14.7015 61.4893 15.8565 61.4893 17.259C61.4893 18.645 61.9348 19.8 62.8093 20.7075Z"
        fill="#333333"
      />
      <path
        d="M105.296 26.07C102.722 26.07 100.594 25.245 98.9107 23.595C97.2277 21.945 96.3862 19.833 96.3862 17.259C96.3862 14.685 97.2277 12.573 98.9107 10.9065C100.594 9.24004 102.722 8.41504 105.296 8.41504C107.887 8.41504 110.032 9.24004 111.698 10.89C113.365 12.54 114.206 14.6685 114.206 17.259C114.206 19.833 113.365 21.945 111.698 23.595C110.032 25.245 107.887 26.07 105.296 26.07ZM101.897 20.7075C102.772 21.615 103.91 22.077 105.296 22.077C106.682 22.077 107.821 21.615 108.695 20.7075C109.57 19.8 110.015 18.645 110.015 17.259C110.015 15.8565 109.57 14.7015 108.695 13.794C107.821 12.8865 106.682 12.441 105.296 12.441C103.91 12.441 102.772 12.8865 101.897 13.794C101.023 14.7015 100.577 15.8565 100.577 17.259C100.577 18.645 101.023 19.8 101.897 20.7075Z"
        fill="#333333"
      />
      <path
        d="M165.934 22.11C165.406 22.11 164.96 21.978 164.614 21.6975C164.267 21.417 164.119 21.0375 164.119 20.5755V0.495C164.119 0.2145 163.904 0 163.624 0H160.489C160.208 0 159.994 0.2145 159.994 0.495V20.856C159.994 22.3905 160.472 23.6445 161.413 24.585C162.353 25.5255 163.607 26.004 165.175 26.004C166.099 26.004 167.105 25.773 168.194 25.3275C168.409 25.2285 168.541 25.014 168.491 24.783L168.029 22.242C167.98 21.945 167.683 21.78 167.402 21.8625C166.825 22.011 166.33 22.11 165.934 22.11Z"
        fill="#333333"
      />
      <path
        d="M117.424 16.566C117.424 14.0415 118.183 12.045 119.701 10.593C121.219 9.14104 123.199 8.41504 125.641 8.41504C128.066 8.41504 130.03 9.14104 131.564 10.593C133.099 12.045 133.858 14.0415 133.858 16.566V25.245C133.858 25.5255 133.643 25.74 133.363 25.74H130.228C129.947 25.74 129.733 25.5255 129.733 25.245V16.665C129.733 15.3285 129.353 14.289 128.594 13.5465C127.835 12.804 126.845 12.441 125.641 12.441C124.436 12.441 123.446 12.804 122.687 13.5465C121.928 14.289 121.549 15.3285 121.549 16.665V25.245C121.549 25.5255 121.334 25.74 121.054 25.74H117.919C117.638 25.74 117.424 25.5255 117.424 25.245V16.566Z"
        fill="#333333"
      />
      <path
        d="M145.854 26.07C143.461 26.07 141.448 25.2285 139.831 23.562C138.214 21.8955 137.406 19.7835 137.406 17.259C137.406 14.7015 138.264 12.5895 139.963 10.923C141.679 9.25654 143.841 8.41504 146.448 8.41504C148.972 8.41504 151.084 9.22354 152.767 10.857C154.45 12.4905 155.292 14.5365 155.292 16.995V25.245C155.292 25.5255 155.077 25.74 154.797 25.74H151.959C151.678 25.74 151.464 25.5255 151.464 25.245V22.968C150.193 25.0305 148.329 26.07 145.854 26.07ZM149.764 20.7075C150.655 19.8 151.101 18.645 151.101 17.259C151.101 15.873 150.655 14.718 149.764 13.8105C148.873 12.903 147.735 12.441 146.349 12.441C144.979 12.441 143.857 12.903 142.95 13.8105C142.042 14.718 141.597 15.873 141.597 17.259C141.597 18.645 142.042 19.8 142.95 20.7075C143.857 21.615 144.979 22.077 146.349 22.077C147.735 22.077 148.873 21.615 149.764 20.7075Z"
        fill="#333333"
      />
      <path
        d="M178.623 26.07C176.23 26.07 174.217 25.2285 172.6 23.562C170.983 21.8955 170.175 19.7835 170.175 17.259C170.175 14.7015 171.033 12.5895 172.732 10.923C174.432 9.25654 176.593 8.41504 179.217 8.41504C181.741 8.41504 183.853 9.22354 185.536 10.857C187.219 12.4905 188.061 14.5365 188.061 16.995V25.245C188.061 25.5255 187.846 25.74 187.566 25.74H184.728C184.447 25.74 184.233 25.5255 184.233 25.245V22.968C182.946 25.0305 181.081 26.07 178.623 26.07ZM182.533 20.7075C183.424 19.8 183.87 18.645 183.87 17.259C183.87 15.873 183.424 14.718 182.533 13.8105C181.642 12.903 180.504 12.441 179.118 12.441C177.748 12.441 176.626 12.903 175.719 13.8105C174.811 14.718 174.366 15.873 174.366 17.259C174.366 18.645 174.811 19.8 175.719 20.7075C176.626 21.615 177.748 22.077 179.118 22.077C180.504 22.077 181.642 21.615 182.533 20.7075Z"
        fill="#333333"
      />
      <path
        d="M50.2695 21.318C49.5105 21.8295 48.6525 22.077 47.712 22.077C46.4085 22.077 45.336 21.615 44.478 20.7075C43.62 19.8 43.191 18.645 43.191 17.259C43.191 15.873 43.62 14.718 44.478 13.8105C45.336 12.903 46.4085 12.441 47.712 12.441C48.6855 12.441 49.5435 12.6885 50.286 13.2C50.6655 13.464 50.979 13.761 51.243 14.091C51.408 14.289 51.705 14.3385 51.903 14.19L54.51 12.375C54.741 12.21 54.7905 11.8965 54.6255 11.6655C54.246 11.1705 53.8005 10.7085 53.289 10.296C51.7545 9.04204 49.9065 8.41504 47.745 8.41504C45.2205 8.41504 43.125 9.25654 41.475 10.923C39.825 12.5895 39 14.7015 39 17.259C39 19.8165 39.825 21.912 41.475 23.5785C43.125 25.245 45.2205 26.07 47.745 26.07C49.9065 26.07 51.7545 25.443 53.289 24.2055C53.8005 23.793 54.246 23.331 54.642 22.8195C54.807 22.605 54.7575 22.275 54.5265 22.11L51.936 20.295C51.7215 20.1465 51.4245 20.1795 51.276 20.394C50.9955 20.757 50.6655 21.054 50.2695 21.318Z"
        fill="#333333"
      />
      <path
        d="M89.3579 21.318C88.5989 21.8295 87.7409 22.077 86.8004 22.077C85.4969 22.077 84.4244 21.615 83.5664 20.7075C82.7084 19.8 82.2794 18.645 82.2794 17.259C82.2794 15.873 82.7084 14.718 83.5664 13.8105C84.4244 12.903 85.4969 12.441 86.8004 12.441C87.7739 12.441 88.6319 12.6885 89.3744 13.2C89.7539 13.464 90.0674 13.761 90.3314 14.091C90.4964 14.289 90.7934 14.3385 90.9914 14.19L93.5819 12.375C93.8129 12.21 93.8624 11.8965 93.6974 11.6655C93.3179 11.1705 92.8724 10.7085 92.3609 10.296C90.8429 9.04204 88.9949 8.41504 86.8334 8.41504C84.3089 8.41504 82.2134 9.25654 80.5634 10.923C78.9134 12.5895 78.0884 14.7015 78.0884 17.259C78.0884 19.8165 78.9134 21.912 80.5634 23.5785C82.2134 25.245 84.3089 26.07 86.8334 26.07C88.9949 26.07 90.8429 25.443 92.3774 24.2055C92.8889 23.793 93.3344 23.331 93.7304 22.8195C93.8954 22.605 93.8459 22.275 93.6149 22.11L91.0244 20.295C90.8099 20.1465 90.5129 20.1795 90.3644 20.394C90.0839 20.757 89.7539 21.054 89.3579 21.318Z"
        fill="#333333"
      />
    </g>
  </svg>
</template>
<script>
export const TYPES = ['full', 'simple', 'business', 'businessShort']
export default {
  name: 'CoconalaLogo',
  props: {
    type: {
      type: String,
      default: TYPES[0]
    }
  },
  computed: {
    currentType() {
      return TYPES.includes(this.type) ? this.type : TYPES[0]
    },
    isShowText() {
      return this.currentType === 'full'
    },
    viewBox() {
      let viewBox = ''
      switch (this.type) {
        case 'business':
          viewBox = '0 0 267 26'
          break
        case 'businessShort':
          viewBox = '0 0 880 230'
          break
        case 'simple':
          viewBox = '0 0 137 25'
          break
        default:
          viewBox = '0 0 189 33'
          break
      }
      return viewBox
    }
  }
}
</script>
<style lang="scss" scoped>
.c-logo {
  vertical-align: middle;
}
</style>
