// initial state
export const state = () => ({
  categoryName: '',
  title: '',
  description: '',
  metaDescription: '',
  keywords: '',
  footerText: '',
  trendKeywordsList: [],
  canonical: '',
  metaTitle: '',
  recentOrderServiceList: [],
  tag: null,
  ratingsList: [],
  isTrendKeywordPage: false,
  isTagPage: false
})

// getters
export const getters = {}

// actions
export const actions = {}

// mutations
export const mutations = {
  RECEIVE(state, data) {
    Object.assign(state, data)
  }
}
