export const state = () => ({
  accountButtonPosition: 0,
  accountPattern: 'DEFAULT'
})

export const getters = {}

export const actions = {}

export const mutations = {
  SET_ACCOUNT_BUTTON_POSITION(state, data) {
    state.accountButtonPosition = data
  },
  SET_ACCOUNT_PATTERN(state, data) {
    state.accountPattern = data
  }
}
