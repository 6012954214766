import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'

export const state = () => ({})

export const getters = {}

export const actions = {
  async kickCampaign({ rootState }, { event_name, parameter_via_link }) {
    const requestModule = await import('~/stub/apigateway/campaign/campaign_pb')
    const methodModule = await import('~/stub/apigateway/campaign/campaign_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.KickCampaignRequest()
    if (event_name) {
      request.setEventName(event_name)
    }
    if (parameter_via_link) {
      request.setParameterViaLink(parameter_via_link)
    }
    await grpcClient({
      method: methodModule.CampaignService.KickCampaign,
      request,
      metadata
    })
  }
}

export const mutations = {}
