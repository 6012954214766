import GrpcAccessorBase from '~/api/accessors/grpc-accessor-base'
import grpcClient from '~/grpc/grpc-client'
import {
  GetMyOutsourcesRequest,
  GetMyOutsourcesResponse,
  GetOutsourceDetailRequest,
  CreateOutsourceRequest,
  GetOutsourceDetailResponse,
  MyOutsource,
  UpdateStatusRequest,
  UpdateStatusResponse,
  SearchOutsourcesRequest,
  SearchOutsourcesResponse,
  IsAuthorizedResponse,
  MyOutsorucesFilter,
  UpdateOutsourceRequest,
  IsAuthorizedRequest,
  GetMyOutsourceDetailRequest,
  GetMyOutsourceDetailResponse
} from '~/stub/gw/outsource/outsource_service_pb'
import { OutsourceService } from '~/stub/gw/outsource/outsource_service_pb_service'
import { OutsourceStatus } from '~/stub/domain/enumeration/outsource_status_pb'
import { OutsourcePaymentMethodType } from '~/stub/domain/enumeration/outsource_payment_method_type_pb'
import { SearchOutsourceDomain } from '~/stub/domain/search_outsource_pb'
import {
  OutsourceAttachmentFileDomain,
  OutsourceCompany,
  OutsourceCompanyContact,
  OutsourceDomain
} from '~/stub/domain/outsource_pb'
import { OutsourceWorkingDayType } from '~/stub/domain/enumeration/outsource_working_day_type_pb'
import { Int32Value } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  toStringValue,
  toInt32Value,
  toInt32ValueArray,
  toBoolValue,
  Stripped,
  stripValue
} from '~/grpc/grpc-util'
import { toNonNullableFilter } from '~/components/domain/jobMatching/outsources/JobMatchingOutsourcesForm/logics/type-guard'
import { OutsourceUploaded } from '../../../stub/gw/outsource/outsource_service_pb.d'
import { encodeFileUrl } from '~/components/domain/jobMatching/outsources/logics/outsource-attachment-file'

type GetMyOutsourcesRequestType = Stripped<GetMyOutsourcesRequest.AsObject>
type GetMyOutsourcesResponseType = Stripped<GetMyOutsourcesResponse.AsObject>
type MyOutsourceType = Stripped<MyOutsource.AsObject>
type PaymentMethodType = MyOutsourceType['paymentMethodType']
type OutsourceStatusType = MyOutsourceType['status']

const initialValueMyOutsources = stripValue(new GetMyOutsourcesResponse().toObject())

type AuthorizedResponseType = Stripped<IsAuthorizedResponse.AsObject>

const initAuthorizedStatus = stripValue(
  new IsAuthorizedResponse().toObject()
) as AuthorizedResponseType

type GetOutsourceDetailResponseType = Stripped<GetOutsourceDetailResponse.AsObject>
type UploadedFileType = Stripped<OutsourceUploaded.AsObject>

const initOutsourceDetail = stripValue(
  new GetOutsourceDetailResponse().toObject()
) as GetOutsourceDetailResponseType

type GetMyOutsourceDetailResponseType = Stripped<GetMyOutsourceDetailResponse.AsObject>

const initMyOutsourceDetail = stripValue(
  new GetMyOutsourceDetailResponse().toObject()
) as GetMyOutsourceDetailResponseType

export type SearchOutsourcesRequestType = Stripped<SearchOutsourcesRequest.AsObject>

export type OutsourcesListType = Stripped<SearchOutsourceDomain.AsObject>
export type OutsourcesPaginationType = Omit<
  Stripped<SearchOutsourcesResponse.AsObject>,
  'outsourcesList'
>

export type CreateOutsourceRequestType = Stripped<CreateOutsourceRequest.AsObject>
export type UpdateOutsourceRequestType = Stripped<UpdateOutsourceRequest.AsObject>

class OutsourceServiceAccessor extends GrpcAccessorBase {
  fetchOutsourceServiceDetail(params: Stripped<GetOutsourceDetailRequest.AsObject>) {
    const metadata = this.getMetadata()
    const request = new GetOutsourceDetailRequest()

    request.setOutsourceUlid(toStringValue(params.outsourceUlid))
    request.setIsIncrementViewCount(toBoolValue(params.isIncrementViewCount))

    return new Promise<GetOutsourceDetailResponseType>(async (resolve, reject) => {
      try {
        const response = await grpcClient({
          method: OutsourceService.GetOutsourceDetail,
          request,
          metadata,
          strip: true
        })

        // 会社ロゴをURLエンコードする
        if (response.outsourceCompany?.logoFileUrl) {
          response.outsourceCompany.logoFileUrl = encodeFileUrl(
            response.outsourceCompany.logoFileUrl
          )
        }

        // 添付ファイルをURLエンコードする
        response.uploadedFilesList.forEach(uploadedFile => {
          uploadedFile.fileUrl = encodeFileUrl(uploadedFile.fileUrl)
        })

        resolve(response)
      } catch (e) {
        reject(e)
      }
    })
  }

  fetchMyOutsourceServiceDetail(params: Stripped<GetMyOutsourceDetailRequest.AsObject>) {
    const metadata = this.getMetadata()
    const request = new GetMyOutsourceDetailRequest()

    request.setOutsourceUlid(toStringValue(params.outsourceUlid))

    return new Promise<GetMyOutsourceDetailResponseType>(async (resolve, reject) => {
      try {
        const response = await grpcClient({
          method: OutsourceService.GetMyOutsourceDetail,
          request,
          metadata,
          strip: true
        })

        // 会社ロゴをURLエンコードする
        if (response.outsourceCompany?.logoFileUrl) {
          response.outsourceCompany.logoFileUrl = encodeFileUrl(
            response.outsourceCompany.logoFileUrl
          )
        }

        // 添付ファイルをURLエンコードする
        response.uploadedFilesList.forEach(uploadedFile => {
          uploadedFile.fileUrl = encodeFileUrl(uploadedFile.fileUrl)
        })

        resolve(response)
      } catch (e) {
        reject(e)
      }
    })
  }

  fetchAuthorizeStatus() {
    const metadata = this.getMetadata()
    const request = new IsAuthorizedRequest()

    return grpcClient({
      method: OutsourceService.IsAuthorized,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 継続(業務委託)の募集一覧を取得する
   */
  getMyOutsources(param: GetMyOutsourcesRequestType): Promise<GetMyOutsourcesResponseType> {
    const metadata = this.getMetadata()
    const request = new GetMyOutsourcesRequest()

    const getFilterParam = (status?: OutsourceStatus): MyOutsorucesFilter | undefined => {
      if (!status) return undefined
      const filterParam = new MyOutsorucesFilter()
      filterParam.setStatus(status)
      return filterParam
    }

    request.setPage(toInt32Value(param.page))
    request.setPerPage(toInt32Value(param.perPage))
    request.setFilter(getFilterParam(param.filter?.status))
    return grpcClient({
      method: OutsourceService.GetMyOutsources,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 募集一覧の未読件数を返す
   * @param param
   * @returns 募集一覧の未読件数
   */
  async getUnreadAppliedOutsourcesCount(
    param: GetMyOutsourcesRequestType
  ): Promise<GetMyOutsourcesResponseType['unreadAppliedOutsourcesCount']> {
    // TODO:案件マッチング: 未読件数のみを返すAPIを作ったほうがいい
    const myOutsources = await this.getMyOutsources(param)
    return myOutsources.unreadAppliedOutsourcesCount
  }

  /**
   * 募集を停止する
   * @param outsourceUlid
   */
  updateStatusStop(outsourceUlid: string): Promise<UpdateStatusResponse.AsObject> {
    const metadata = this.getMetadata()
    const request = new UpdateStatusRequest()
    request.setOutsourceUlid(toStringValue(outsourceUlid))
    return grpcClient({
      method: OutsourceService.UpdateStatusStop,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 募集を終了する
   * @param outsourceUlid
   */
  updateStatusFinish(outsourceUlid: string): Promise<UpdateStatusResponse.AsObject> {
    const metadata = this.getMetadata()
    const request = new UpdateStatusRequest()
    request.setOutsourceUlid(toStringValue(outsourceUlid))
    return grpcClient({
      method: OutsourceService.UpdateStatusFinish,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 募集を再開する
   * @param outsourceUlid
   */
  updateStatusPublish(outsourceUlid: string): Promise<UpdateStatusResponse.AsObject> {
    const metadata = this.getMetadata()
    const request = new UpdateStatusRequest()
    request.setOutsourceUlid(toStringValue(outsourceUlid))
    return grpcClient({
      method: OutsourceService.UpdateStatusPublish,
      request,
      metadata,
      strip: true
    })
  }

  searchOutsources(params: SearchOutsourcesRequestType) {
    const metadata = this.getMetadata()
    const request = new SearchOutsourcesRequest()

    request.setPage(toInt32Value(params.page))
    request.setPerPage(toInt32Value(params.perPage))
    request.setIsRecruiting(toBoolValue(params.isRecruiting))
    if (params.keyword) {
      request.setKeyword(toStringValue(params.keyword))
    }
    request.setExperienceJobCategoryId(toInt32Value(params.experienceJobCategoryId))
    request.setPaymentMethodType(params.paymentMethodType)
    request.setHourlyMinUnitPrice(toInt32Value(params.hourlyMinUnitPrice))
    request.setHourlyMaxUnitPrice(toInt32Value(params.hourlyMaxUnitPrice))
    request.setMonthlyMinUnitPrice(toInt32Value(params.monthlyMinUnitPrice))
    request.setMonthlyMaxUnitPrice(toInt32Value(params.monthlyMaxUnitPrice))
    request.setWorkingDayTypesList(params.workingDayTypesList)
    request.setSkillIdsList(toInt32ValueArray(params.skillIdsList))
    request.setFeatureIdsList(toInt32ValueArray(params.featureIdsList))
    // v1リリース時にsort機能はprotoはあるが未実装なのでコメントアウト（undefineでも送るとエラーになるので）
    // request.setSortType(toStringValue(params.sortType))

    return grpcClient({
      method: OutsourceService.SearchOutsources,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 下書き状態の継続型公開募集を新規作成
   * @param params APIリクエストのパラメータ
   */
  createDraftOutsource(params: CreateOutsourceRequestType) {
    const metadata = this.getMetadata()
    const request = new CreateOutsourceRequest()

    const outsourceDomainObject = this.makeOutsourceDomainObject(params.outsource)
    request.setOutsource(outsourceDomainObject)

    return grpcClient({
      method: OutsourceService.CreateDraftOutsource,
      request,
      metadata
    })
  }

  /**
   * 継続型公開募集を新規作成
   * @param params APIリクエストのパラメータ
   */
  createPublishOutsource(params: CreateOutsourceRequestType) {
    const metadata = this.getMetadata()
    const request = new CreateOutsourceRequest()

    const outsourceDomainObject = this.makeOutsourceDomainObject(params.outsource)
    request.setOutsource(outsourceDomainObject)

    return grpcClient({
      method: OutsourceService.CreatePublishOutsource,
      request,
      metadata
    })
  }

  /**
   * 継続型公開募集の更新
   * @param params APIリクエストのパラメータ
   */
  updateOutsource(params: UpdateOutsourceRequestType) {
    const metadata = this.getMetadata()
    const request = new UpdateOutsourceRequest()

    const outsourceDomainObject = this.makeOutsourceDomainObject(params.outsource)
    request.setOutsource(outsourceDomainObject)
    request.setOutsourceUlid(params.outsourceUlid ? toStringValue(params.outsourceUlid) : undefined)
    request.setStatus(params.status || OutsourceStatus.OUTSOURCE_STATUS_UNSPECIFIED)

    return grpcClient({
      method: OutsourceService.UpdateOutsource,
      request,
      metadata
    })
  }

  /**
   * OutsourceDomainのリクエストインスタンスを生成
   * @param param outsourceのパラメータ
   */
  private makeOutsourceDomainObject(
    param: CreateOutsourceRequestType['outsource']
  ): OutsourceDomain {
    const request = new OutsourceDomain()

    request.setExperienceJobCategoryId(toInt32Value(param?.experienceJobCategoryId))
    request.setTitle(param?.title ? toStringValue(param.title) : undefined)
    request.setDescription(param?.description ? toStringValue(param.description) : undefined)
    request.setPaymentMethodType(
      param?.paymentMethodType ||
        OutsourcePaymentMethodType.OUTSOURCE_PAYMENT_METHOD_TYPE_UNSPECIFIED
    )
    request.setWeeklyMinWorkingHour(toInt32Value(param?.weeklyMinWorkingHour))
    request.setWeeklyMaxWorkingHour(toInt32Value(param?.weeklyMaxWorkingHour))
    request.setMonthlyMinWorkingHour(toInt32Value(param?.monthlyMinWorkingHour))
    request.setMonthlyMaxWorkingHour(toInt32Value(param?.monthlyMaxWorkingHour))
    request.setHourlyMinUnitPrice(toInt32Value(param?.hourlyMinUnitPrice))
    request.setHourlyMaxUnitPrice(toInt32Value(param?.hourlyMaxUnitPrice))
    request.setMonthlyMinUnitPrice(toInt32Value(param?.monthlyMinUnitPrice))
    request.setMonthlyMaxUnitPrice(toInt32Value(param?.monthlyMaxUnitPrice))
    request.setWorkingDayType(
      param?.workingDayType || OutsourceWorkingDayType.OUTSOURCE_WORKING_DAY_TYPE_UNSPECIFIED
    )
    request.setApplicationDefaultMessage(
      param?.applicationDefaultMessage ? toStringValue(param.applicationDefaultMessage) : undefined
    )
    const skillForToolIdsList = param?.skillForToolIdsList
      ? param.skillForToolIdsList.map(v => toInt32Value(v) as Int32Value)
      : []
    request.setSkillForToolIdsList(skillForToolIdsList)
    const skillForProgrammingIdsList = param?.skillForProgrammingIdsList
      ? param.skillForProgrammingIdsList.map(v => toInt32Value(v) as Int32Value)
      : []
    request.setSkillForProgrammingIdsList(skillForProgrammingIdsList)
    const licenseIdsList = param?.licenseIdsList
      ? param.licenseIdsList.map(v => toInt32Value(v) as Int32Value)
      : []
    request.setLicenseIdsList(licenseIdsList)
    const urlsList = param?.urlsList
      ? param.urlsList
          .map(url => (url ? toStringValue(url) : undefined))
          .filter(toNonNullableFilter)
      : []
    request.setUrlsList(urlsList)
    const featureIdsList = param?.featureIdsList
      ? param.featureIdsList.map(v => toInt32Value(v) as Int32Value)
      : []
    request.setFeatureIdsList(featureIdsList)

    /** OutsourceCompany */
    const outsourceCompanyObject = this.makeOutsourceCompanyObject(param)
    request.setOutsourceCompany(outsourceCompanyObject)

    /** OutsourceCompanyContact */
    const outsourceCompanyContactObject = this.makeOutsourceCompanyContactObject(param)
    request.setOutsourceCompanyContact(outsourceCompanyContactObject)

    /** OutsourceAttachmentFileDomain */
    const outsourceAttachmentFileDomainList = this.makeOutsourceAttachmentFileDomainList(param)
    request.setAttachmentFilesList(outsourceAttachmentFileDomainList)

    return request
  }

  /**
   * OutsourceCompanyのリクエストインスタンスを生成
   * @param param outsourceのパラメータ
   */
  private makeOutsourceCompanyObject(
    param: CreateOutsourceRequestType['outsource']
  ): OutsourceCompany {
    const request = new OutsourceCompany()

    request.setName(
      param?.outsourceCompany?.name ? toStringValue(param.outsourceCompany.name) : undefined
    )
    request.setPostalCode(
      param?.outsourceCompany?.postalCode
        ? toStringValue(param.outsourceCompany.postalCode)
        : undefined
    )
    request.setPrefectureId(toInt32Value(param?.outsourceCompany?.prefectureId))
    request.setCity(
      param?.outsourceCompany?.city ? toStringValue(param.outsourceCompany.city) : undefined
    )
    request.setDistrictName(
      param?.outsourceCompany?.districtName
        ? toStringValue(param.outsourceCompany.districtName)
        : undefined
    )
    request.setStreetAddress(
      param?.outsourceCompany?.streetAddress
        ? toStringValue(param.outsourceCompany.streetAddress)
        : undefined
    )
    request.setBuildingName(
      param?.outsourceCompany?.buildingName
        ? toStringValue(param.outsourceCompany.buildingName)
        : undefined
    )
    request.setUrl(
      param?.outsourceCompany?.url ? toStringValue(param.outsourceCompany.url) : undefined
    )
    request.setDescription(
      param?.outsourceCompany?.description
        ? toStringValue(param.outsourceCompany.description)
        : undefined
    )
    request.setLogoFileName(
      param?.outsourceCompany?.logoFileName
        ? toStringValue(param.outsourceCompany.logoFileName)
        : undefined
    )
    request.setLogoFileUrl(
      param?.outsourceCompany?.logoFileUrl
        ? toStringValue(param.outsourceCompany.logoFileUrl)
        : undefined
    )

    return request
  }

  /**
   * OutsourceCompanyContactのリクエストインスタンスを生成
   * @param param outsourceのパラメータ
   */
  private makeOutsourceCompanyContactObject(
    param: CreateOutsourceRequestType['outsource']
  ): OutsourceCompanyContact {
    const request = new OutsourceCompanyContact()

    request.setLastName(
      param?.outsourceCompanyContact?.lastName
        ? toStringValue(param.outsourceCompanyContact.lastName)
        : undefined
    )
    request.setFirstName(
      param?.outsourceCompanyContact?.firstName
        ? toStringValue(param.outsourceCompanyContact.firstName)
        : undefined
    )
    request.setLastNameKana(
      param?.outsourceCompanyContact?.lastNameKana
        ? toStringValue(param.outsourceCompanyContact.lastNameKana)
        : undefined
    )
    request.setFirstNameKana(
      param?.outsourceCompanyContact?.firstNameKana
        ? toStringValue(param.outsourceCompanyContact.firstNameKana)
        : undefined
    )
    request.setPhoneNumber(
      param?.outsourceCompanyContact?.phoneNumber
        ? toStringValue(param.outsourceCompanyContact.phoneNumber)
        : undefined
    )

    return request
  }

  /**
   * OutsourceAttachmentFileDomainのリクエストインスタンスを生成
   * @param param outsourceのパラメータ
   */
  private makeOutsourceAttachmentFileDomainList(
    param: CreateOutsourceRequestType['outsource']
  ): OutsourceAttachmentFileDomain[] {
    const requests =
      param?.attachmentFilesList?.map(attachmentFile => {
        const request = new OutsourceAttachmentFileDomain()

        if (attachmentFile.fileName) request.setFileName(toStringValue(attachmentFile.fileName))
        if (attachmentFile.fileUrl) request.setFileUrl(toStringValue(attachmentFile.fileUrl))
        if (attachmentFile.mime) request.setMime(toStringValue(attachmentFile.mime))
        if (attachmentFile.size) request.setSize(toInt32Value(attachmentFile.size))

        return request
      }) || []

    return requests
  }
}

export default OutsourceServiceAccessor

export type {
  GetMyOutsourcesResponseType,
  MyOutsourceType,
  PaymentMethodType,
  OutsourceStatusType,
  GetOutsourceDetailResponseType,
  GetMyOutsourceDetailResponseType
}
export {
  initialValueMyOutsources,
  initOutsourceDetail,
  initMyOutsourceDetail,
  initAuthorizedStatus,
  AuthorizedResponseType,
  UploadedFileType
}
