// initial state
const initialState = () => ({
  selectedFacetCategoryId: '',
  selectedFacetKey: ''
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  setFacetKey({ commit }, data) {
    commit('SELECT_FACET', data)
  }
}

// mutations
export const mutations = {
  SELECT_FACET(state, data) {
    Object.assign(state, data)
  },
  CLEAR_FACET(state, data) {
    Object.assign(state, initialState())
  }
}
