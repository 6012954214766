<template>
  <a
    class="d-button"
    :class="classes"
    :href="href"
    v-on="$listeners"
    v-if="!!href"
    :target="target"
    :rel="target === '_blank' ? 'noopener' : ''"
  >
    <span
      class="d-button_text"
      v-if="$slots.default"
    >
      <span
        :class="{ [sizeClassName]: !!this.size }"
        v-if="iconName && iconPosition !== 'after'"
        class="d-button_icon-before"
      >
        <CoconalaIcon
          :name="iconName"
          :color="iconColor"
        />
      </span>
      <slot></slot>
      <span
        :class="{ [sizeClassName]: !!this.size }"
        v-if="iconName && iconPosition === 'after'"
        class="d-button_icon-after"
      >
        <CoconalaIcon
          :name="iconName"
          :color="iconColor"
        />
      </span>
    </span>
  </a>
  <NuxtLink
    class="d-button"
    :class="classes"
    :to="nuxtLinkTo"
    v-on="$listeners"
    v-else-if="!!nuxtLinkTo"
  >
    <span
      class="d-button_text"
      v-if="$slots.default"
    >
      <span
        :class="{ [sizeClassName]: !!this.size }"
        v-if="iconName && iconPosition !== 'after'"
        class="d-button_icon-before"
      >
        <CoconalaIcon
          :name="iconName"
          :color="iconColor"
        />
      </span>
      <slot></slot>
      <span
        :class="{ [sizeClassName]: !!this.size }"
        v-if="iconName && iconPosition === 'after'"
        class="d-button_icon-after"
      >
        <CoconalaIcon
          :name="iconName"
          :color="iconColor"
        />
      </span>
    </span>
  </NuxtLink>
  <button
    class="d-button"
    :class="classes"
    :type="nativeType"
    v-on="$listeners"
    v-else
  >
    <span
      class="d-button_text"
      v-if="$slots.default"
    >
      <span
        :class="{ [sizeClassName]: !!this.size }"
        v-if="iconName && iconPosition !== 'after'"
        class="d-button_icon-before"
      >
        <CoconalaIcon
          :name="iconName"
          :color="iconColor"
          :size="iconSize"
        />
      </span>
      <slot></slot>
      <span
        :class="{ [sizeClassName]: !!this.size }"
        v-if="iconName && iconPosition === 'after'"
        class="d-button_icon-after"
      >
        <CoconalaIcon
          :name="iconName"
          :color="iconColor"
        />
      </span>
    </span>
  </button>
</template>
<script>
// NOTE: atomsなので本当は依存してはいけないが例外とする
import CoconalaIcon from '~/components/atoms/CoconalaIcon'

export const BUTTON_TYPES = Object.freeze([
  'contained',
  'outlined',
  'outlined2',
  'outlined3', // 未ログイントップページのABテスト用
  'text'
])
// 未ログイントップページのABテスト用(ez-red)
export const BUTTON_COLORS = Object.freeze(['primary', 'red', 'ez-red', 'white'])
export const BUTTON_SIZES = Object.freeze(['', 'small', 'medium', 'large'])
export const BUTTON_PATTERNS = Object.freeze(['', 'reverse', 'business'])
export const BUTTON_NATIVE_TYPE = Object.freeze(['submit', 'reset', 'button'])
export const ICON_POSITIONS = Object.freeze(['before', 'after'])

export default {
  name: 'DButton',
  components: { CoconalaIcon },
  props: {
    type: {
      type: String,
      default: 'contained',
      validator(v) {
        return BUTTON_TYPES.includes(v)
      }
    },
    color: {
      type: String,
      default: 'primary',
      validator(v) {
        return BUTTON_COLORS.includes(v)
      }
    },
    size: {
      type: String,
      default: 'medium',
      validator(v) {
        return BUTTON_SIZES.includes(v)
      }
    },
    pattern: {
      type: String,
      default: '',
      validator(v) {
        return BUTTON_PATTERNS.includes(v)
      }
    },
    href: {
      type: String
    },
    target: {
      type: String,
      default: '_self'
    },
    isFull: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isBold: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String
    },
    iconSize: {
      type: String
    },
    iconPosition: {
      type: String,
      default: ICON_POSITIONS[0],
      validator(v) {
        return ICON_POSITIONS.includes(v)
      }
    },
    nuxtLinkTo: {
      type: String
    },
    nativeType: {
      type: String,
      default: 'submit',
      validator(v) {
        return BUTTON_NATIVE_TYPE.includes(v)
      }
    }
  },
  computed: {
    classes() {
      const classes = {
        'is-fullwidth': this.isFull,
        'is-loading': this.isLoading,
        'is-bold': this.isBold,
        'is-disabled': this.isDisabled
      }
      if (this.type) classes[`is-${this.type}`] = this.type
      if (this.color) classes[`is-${this.color}`] = this.color
      if (this.size) classes[this.sizeClassName] = this.size
      if (this.pattern) classes[`is-${this.pattern}`] = this.pattern
      return classes
    },
    sizeClassName() {
      return `is-${this.size}`
    },
    iconColor() {
      return this.isActive && !this.color ? 'primary' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
// 未ログイントップページのABテスト用
@import '~/assets/stylesheets/pages/ez-top/ez-top-common';

/** Contained - Primary */
@mixin ds2-button-contained-primary {
  border-color: $ds2-color-brand-primary;
  background: $ds2-color-brand-primary;
  color: $ds2-color-white;
  &:hover,
  &:active {
    border-color: $ds2-color-brand-primary-700;
    background: $ds2-color-brand-primary-700;
  }
  &:active {
    outline: none;
    border-color: $ds2-color-brand-primary-900;
    background: $ds2-color-brand-primary-900;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    border-color: $ds2-color-brand-primary;
    background: $ds2-color-brand-primary;
    box-shadow: none;
    color: $ds2-color-white;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Contained - Primary - Reverse */
@mixin ds2-button-contained-primary-reverse {
  border-color: $ds2-color-white;
  background: $ds2-color-white;
  color: $ds2-color-brand-primary;
  &:hover,
  &:active {
    border-color: $ds2-color-brand-primary-700;
    background: $ds2-color-brand-primary-700;
    color: $ds2-color-white;
  }
  &:active {
    outline: none;
    border-color: $ds2-color-brand-primary-900;
    background: $ds2-color-brand-primary-900;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    border-color: $ds2-color-white;
    background: $ds2-color-white;
    box-shadow: none;
    color: $ds2-color-brand-primary;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Contained - Red */
@mixin ds2-button-contained-red {
  border-color: $ds2-color-red;
  background: $ds2-color-red;
  color: $ds2-color-white;
  &:hover,
  &:active {
    outline: none;
    border-color: $ds2-color-bronze;
    background: $ds2-color-bronze;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    border-color: $ds2-color-red;
    background: $ds2-color-red;
    box-shadow: none;
    color: $ds2-color-white;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Outlined - Primary */
@mixin ds2-button-outlined-primary {
  border-color: $ds2-color-brand-primary;
  background: transparent;
  color: $ds2-color-brand-primary;

  &:hover,
  &:active {
    border-color: $ds2-color-brand-primary;
    background: $ds2-color-brand-primary;
    color: $ds2-color-white;
  }
  &:active {
    outline: none;
    border-color: $ds2-color-brand-primary-700;
    background: $ds2-color-brand-primary-700;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    border-color: $ds2-color-brand-primary;
    background: transparent;
    box-shadow: none;
    color: $ds2-color-brand-primary;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Outlined - Primary - Reverse */
@mixin ds2-button-outlined-primary-reverse {
  border-color: $ds2-color-white;
  background: transparent;
  color: $ds2-color-white;

  &:hover,
  &:active {
    border-color: $ds2-color-white;
    background: $ds2-color-white;
    color: $ds2-color-brand-primary;
  }
  &:active {
    outline: none;
    border-color: transparent;
    background: $ds2-color-white;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    border-color: $ds2-color-white;
    background: transparent;
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Outlined - White */
@mixin ds2-button-outlined-white {
  @include link-effect;

  border-color: $ds2-color-white;
  background: transparent;
  color: $ds2-color-white;

  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    border-color: $ds2-color-white;
    background: transparent;
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Outlined2 - Primary */
@mixin ds2-button-outlined2-primary {
  border: 1px solid $ds2-color-gray-100;
  background: transparent;
  color: $ds2-color-brand-primary;

  &:hover,
  &:active {
    background: $ds2-color-gray-25;
  }
  &:active {
    outline: none;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Outlined3 - Primary */
// 未ログイントップページのABテスト用
@mixin ds2-button-outlined3-primary {
  border: 1px solid $ds2-color-brand-primary;
  background: transparent;
  color: $ds2-color-brand-primary;

  &:hover,
  &:active {
    background: $ds2-color-brand-primary-100;
  }
  &:active {
    background: $ds2-color-brand-primary-200;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Outlined3 - ez-red */
// 未ログイントップページのABテスト用
@mixin ds2-button-outlined3-ez-red {
  border-color: $ez-top-red;
  background: transparent;
  color: $ez-top-red;

  &:hover,
  &:active {
    background: $ez-top-light-red;
  }
  &:active {
    background: #ffd1d5;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    border-color: $ez-top-red;
    background: transparent;
    box-shadow: none;
    color: $ez-top-red;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Text - Primary */
@mixin ds2-button-text-primary {
  border: 0;
  background: transparent;
  color: $ds2-color-brand-primary;

  &:hover,
  &:active {
    background: $ds2-color-gray-25;
  }
  &:active {
    outline: none;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }
}

/** Text - Primary - Reverse */
@mixin ds2-button-text-primary-reverse {
  border: 0;
  background: transparent;
  color: $ds2-color-white;

  &:hover,
  &:active {
    // 枠線が半透明にならないので消す
    border: 0;
    background: rgba($ds2-color-white, 0.6);
  }
  &:active {
    outline: none;
  }
  // bulmaのCSSに上書きされないように各classに記載が必要
  &.is-disabled {
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }
}

.d-button {
  display: inline-block;
  -ms-flex-pack: center;
  padding: 0 16px;
  min-width: 72px;
  height: 40px; //mediumサイズをデフォに
  border: 1px solid $ds2-color-black;
  border-radius: 8px;
  background-color: $ds2-color-white;
  color: $ds2-color-black;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.05em;
  font-size: 13px;
  cursor: pointer;
  transition: 0.2s;

  -webkit-box-pack: center;
  justify-content: center;
  &_text {
    display: flex;
    height: 100%;

    align-items: center;
    justify-content: center;
  }
  &_icon {
    &-before {
      display: inline-flex;
      margin-right: 4px;
    }
    &-after {
      display: inline-flex;
      margin-left: 4px;
    }
  }
  &:focus {
    outline: none;
  }

  &.is-small {
    padding: 0 12px;
    min-width: 48px;
    height: 32px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 32px;
  }
  &.is-medium {
    min-width: 72px;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
  }
  &.is-large {
    min-width: 120px;
    height: 52px;
    font-size: 13px;
    line-height: 52px;
  }
  &.is-bold {
    font-weight: bold;
  }
  &.is-fullwidth {
    width: 100%;
  }
}

.is-contained {
  &.is-primary {
    // Businessはデフォルトと同じ
    @include ds2-button-contained-primary;

    &.is-reverse {
      @include ds2-button-contained-primary-reverse;
    }
  }
  &.is-red {
    @include ds2-button-contained-red;
  }
}
.is-outlined {
  &.is-primary {
    @include ds2-button-outlined-primary;

    // BusinessはReverseと同じ
    &.is-reverse,
    &.is-business {
      @include ds2-button-outlined-primary-reverse;
    }

    // Outlined - Business時の例外対応
    &.is-business {
      &:hover,
      &:active {
        color: $ds2-color-business-navy-1000;
      }
    }
  }
  &.is-white {
    @include ds2-button-outlined-white;
  }
}
.is-outlined2 {
  &.is-primary {
    @include ds2-button-outlined2-primary;

    // reverse, businessは未定義
  }
}

// 未ログイントップページのABテスト用
.is-outlined3 {
  &.is-primary {
    @include ds2-button-outlined3-primary;
  }

  &.is-ez-red {
    @include ds2-button-outlined3-ez-red;
  }
}

.is-text {
  &.is-primary {
    @include ds2-button-text-primary;

    // BusinessはReverseと同じ
    &.is-reverse,
    &.is-business {
      @include ds2-button-text-primary-reverse;
    }
  }
}
</style>
