// source: domain/enumeration/entry_form_status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.EntryFormStatus', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.EntryFormStatus = {
  ENTRY_FORM_STATUS_UNSPECIFIED: 0,
  ENTRY_FORM_STATUS_COMPLETED: 1,
  ENTRY_FORM_STATUS_MEMBER_REGISTRATION: 2,
  ENTRY_FORM_STATUS_AUTH_EMAIL: 3,
  ENTRY_FORM_STATUS_REGISTRATION_PURPOSE: 4,
  ENTRY_FORM_STATUS_PRIVATE_CORPORATION: 5,
  ENTRY_FORM_STATUS_PRIVATE_BUYER_BASIC: 11,
  ENTRY_FORM_STATUS_PRIVATE_BUYER_INTERESTED: 12,
  ENTRY_FORM_STATUS_PRIVATE_BUYER_USER_NAME: 13,
  ENTRY_FORM_STATUS_CORPORATE_BUYER_BASIC: 21,
  ENTRY_FORM_STATUS_CORPORATE_BUYER_CORPORATION: 22,
  ENTRY_FORM_STATUS_CORPORATE_BUYER_INTERESTED: 23,
  ENTRY_FORM_STATUS_CORPORATE_BUYER_ORDER_FORM: 24,
  ENTRY_FORM_STATUS_CORPORATE_BUYER_USER_NAME: 25,
  ENTRY_FORM_STATUS_CORPORATE_BUYER_BUDGET: 26,
  ENTRY_FORM_STATUS_PRIVATE_SELLER_BASIC: 31,
  ENTRY_FORM_STATUS_PRIVATE_SELLER_BASIC_2: 32,
  ENTRY_FORM_STATUS_PRIVATE_SELLER_EXPERIENCE_JOB: 33,
  ENTRY_FORM_STATUS_PRIVATE_SELLER_EXPERIENCE_YEARS: 34,
  ENTRY_FORM_STATUS_PRIVATE_SELLER_USER_NAME: 35,
  ENTRY_FORM_STATUS_PRIVATE_SELLER_CONTRACT_STYLE: 36,
  ENTRY_FORM_STATUS_PRIVATE_SELLER_WORKING_CONDITION: 37,
  ENTRY_FORM_STATUS_CORPORATE_SELLER_BASIC: 41,
  ENTRY_FORM_STATUS_CORPORATE_SELLER_CORPORATION: 42,
  ENTRY_FORM_STATUS_CORPORATE_SELLER_EXPERIENCE_JOB: 43,
  ENTRY_FORM_STATUS_CORPORATE_SELLER_EXPERIENCE_YEARS: 44,
  ENTRY_FORM_STATUS_CORPORATE_SELLER_USER_NAME: 45,
  ENTRY_FORM_STATUS_CORPORATE_SELLER_CONTRACT_STYLE: 46,
  ENTRY_FORM_STATUS_CORPORATE_SELLER_WORKING_CONDITION: 47
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
