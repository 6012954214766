import GrpcAccessorBase from '~/api/accessors/grpc-accessor-base'
import {
  SaveUserTranslateInfoRequest,
  ListExchangeRateRequest,
  ListExchangeRateResponse
} from '~/stub/gw/translate/translate_service_pb'
import grpcClient from '~/grpc/grpc-client'
import { toStringValue, Stripped } from '~/grpc/grpc-util'
import { TranslateService } from '~/stub/gw/translate/translate_service_pb_service'

type LRUCacheType = {
  max: number
  maxAge: number
}

class TranslateAccessor extends GrpcAccessorBase {
  // 翻訳の言語設定のリクエスト
  async updateTranslateLanguage(languageCode: string): Promise<void> {
    const metadata = this.getMetadata()
    const request = new SaveUserTranslateInfoRequest()

    request.setLangCode(toStringValue(languageCode))

    await grpcClient({
      method: TranslateService.SaveUserTranslateInfo,
      request,
      metadata
    })
  }
  // 決済表記用レート取得
  getListExchangeRate(
    exchangeRateCache: LRUCacheType
  ): Promise<Stripped<ListExchangeRateResponse.AsObject>> {
    const metadata = this.getMetadata()
    const request = new ListExchangeRateRequest()

    return grpcClient({
      method: TranslateService.ListExchangeRate,
      request,
      metadata,
      strip: true,
      cache: exchangeRateCache
    })
  }
}

export default TranslateAccessor
