const GUIDE_LOCALSTORAGE_KEY = 'menu_guide_scene'

export const GUIDE_SCENE_NAME = {
  MODE_CHANGE: 'mode_change',
  TRANSACTION_MANAGEMENT: 'transaction_management'
}

const STEP_GUIDE_SCENE_LIST = [
  GUIDE_SCENE_NAME.MODE_CHANGE,
  GUIDE_SCENE_NAME.TRANSACTION_MANAGEMENT
]

// initial state
export const state = () => ({
  currentGuideScene: ''
})

// getters
export const getters = {}

// actions
export const actions = {
  /**
   * localStorageから現在のガイドを判断
   */
  setCurrentMenuGuideScene({ commit }) {
    const localStorageValue = localStorage.getItem(GUIDE_LOCALSTORAGE_KEY)
    const currentSceneName =
      STEP_GUIDE_SCENE_LIST.find(step => !localStorageValue?.includes(step)) || ''

    commit('SET_CURRENT_SCENE', currentSceneName)
  },

  /**
   * localStorageにガイドの終了を格納
   */
  setFinishedGuideScene({ dispatch }, sceneName) {
    const localStorageValue = localStorage.getItem(GUIDE_LOCALSTORAGE_KEY)
    const completedSceneNameList = localStorageValue?.split(',') || []
    if (!completedSceneNameList.includes(sceneName)) {
      localStorage.setItem(GUIDE_LOCALSTORAGE_KEY, [...completedSceneNameList, sceneName].join(','))
      dispatch('setCurrentMenuGuideScene')
    }
  },

  /**
   * 現在のガイドを終了し、メニューガイドを次のシーンに設定する
   * 主にガイド右上バツボタンを押下時に使用
   */
  setNextGuideScene({ state, dispatch }) {
    dispatch('setFinishedGuideScene', state.currentGuideScene)
  },

  /**
   * メニューガイドのモード切り替えシーンを終了する
   */
  setFinishedModeChangeScene({ dispatch }) {
    dispatch('setFinishedGuideScene', GUIDE_SCENE_NAME.MODE_CHANGE)
  },

  /**
   * メニューガイドの取引管理シーンを終了する
   */
  setFinishedTransactionScene({ dispatch }) {
    dispatch('setFinishedGuideScene', GUIDE_SCENE_NAME.TRANSACTION_MANAGEMENT)
  }
}

// mutations
export const mutations = {
  SET_CURRENT_SCENE(state, data) {
    state.currentGuideScene = data
  }
}
