import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { GetMyServicesRequest } from '~/stub/apigateway/profile/service_pb'
import { ServiceService } from '~/stub/apigateway/profile/service_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
const initialState = () => ({
  servicesList: [],
  providerStatus: {
    phoneWait: false,
    phoneTalking: false
  }
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchServices({ commit, rootState }) {
    const response = await new Promise((resolve, reject) => {
      const request = new GetMyServicesRequest()
      const metadata = commonMetaData(rootState)
      grpc.invoke(ServiceService.GetMyServices, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_SERVICES', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_SERVICES(state, data) {
    Object.assign(state, {
      ...data
    })
  }
}
