// package: footer
// file: apigateway/footer/footer.proto

import * as apigateway_footer_footer_pb from "../../apigateway/footer/footer_pb";
export class FooterService {
  static serviceName = "footer.FooterService";
}
export namespace FooterService {
  export class AddUserFeedback {
    static readonly methodName = "AddUserFeedback";
    static readonly service = FooterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_footer_footer_pb.AddUserFeedbackRequest;
    static readonly responseType = apigateway_footer_footer_pb.AddUserFeedbackReply;
  }
}
