// package: big_query
// file: apigateway/big_query/log_action.proto

import * as apigateway_big_query_log_action_pb from "../../apigateway/big_query/log_action_pb";
export class LogActionService {
  static serviceName = "big_query.LogActionService";
}
export namespace LogActionService {
  export class Add {
    static readonly methodName = "Add";
    static readonly service = LogActionService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_big_query_log_action_pb.AddRequest;
    static readonly responseType = apigateway_big_query_log_action_pb.AddReply;
  }
  export class AddUserActionLog {
    static readonly methodName = "AddUserActionLog";
    static readonly service = LogActionService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_big_query_log_action_pb.AddUserActionLogRequest;
    static readonly responseType = apigateway_big_query_log_action_pb.AddUserActionLogReply;
  }
}
