// package: profile
// file: apigateway/profile/blog_tag.proto

import * as apigateway_profile_blog_tag_pb from "../../apigateway/profile/blog_tag_pb";
import * as profileservice_blog_tag_pb from "../../profileservice/blog_tag_pb";
export class BlogTagService {
  static serviceName = "profile.BlogTagService";
}
export namespace BlogTagService {
  export class GetBlogTags {
    static readonly methodName = "GetBlogTags";
    static readonly service = BlogTagService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_blog_tag_pb.GetBlogTagsRequest;
    static readonly responseType = apigateway_profile_blog_tag_pb.BlogTagsReply;
  }
  export class GetBlogTag {
    static readonly methodName = "GetBlogTag";
    static readonly service = BlogTagService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_blog_tag_pb.GetBlogTagRequest;
    static readonly responseType = apigateway_profile_blog_tag_pb.BlogTagReply;
  }
  export class CreateBlogTag {
    static readonly methodName = "CreateBlogTag";
    static readonly service = BlogTagService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_blog_tag_pb.CreateBlogTagRequest;
    static readonly responseType = apigateway_profile_blog_tag_pb.BlogTagReply;
  }
  export class UpdateBlogTag {
    static readonly methodName = "UpdateBlogTag";
    static readonly service = BlogTagService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_blog_tag_pb.UpdateBlogTagRequest;
    static readonly responseType = apigateway_profile_blog_tag_pb.BlogTagReply;
  }
  export class DeleteBlogTag {
    static readonly methodName = "DeleteBlogTag";
    static readonly service = BlogTagService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_blog_tag_pb.DeleteBlogTagRequest;
    static readonly responseType = apigateway_profile_blog_tag_pb.DeleteBlogTagReply;
  }
}
