// package: coconala.protobuf.gw.interest_category
// file: gw/interest_category/interest_category.proto

import * as gw_interest_category_interest_category_pb from "../../gw/interest_category/interest_category_pb";
import * as domain_user_interest_category_pb from "../../domain/user_interest_category_pb";
import * as domain_interest_category_pb from "../../domain/interest_category_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
export class InterestCategoryService {
  static serviceName = "coconala.protobuf.gw.interest_category.InterestCategoryService";
}
export namespace InterestCategoryService {
  export class SaveUserInterestCategory {
    static readonly methodName = "SaveUserInterestCategory";
    static readonly service = InterestCategoryService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_interest_category_interest_category_pb.SaveUserInterestCategoryRequest;
    static readonly responseType = gw_interest_category_interest_category_pb.SaveUserInterestCategoryResponse;
  }
  export class ListUserInterestCategories {
    static readonly methodName = "ListUserInterestCategories";
    static readonly service = InterestCategoryService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_interest_category_interest_category_pb.ListUserInterestCategoriesRequest;
    static readonly responseType = gw_interest_category_interest_category_pb.ListUserInterestCategoriesResponse;
  }
  export class ListInterestCategories {
    static readonly methodName = "ListInterestCategories";
    static readonly service = InterestCategoryService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = gw_interest_category_interest_category_pb.ListInterestCategoriesRequest;
    static readonly responseType = gw_interest_category_interest_category_pb.ListInterestCategoriesResponse;
  }
}
