// source: profileservice/recommend_blog.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.profileservice.GetRecommendBlogsRequest', null, global);
goog.exportSymbol('proto.profileservice.RecommendBlog', null, global);
goog.exportSymbol('proto.profileservice.RecommendBlogsReply', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.RecommendBlog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.RecommendBlog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.RecommendBlog.displayName = 'proto.profileservice.RecommendBlog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.GetRecommendBlogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.GetRecommendBlogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.GetRecommendBlogsRequest.displayName = 'proto.profileservice.GetRecommendBlogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.RecommendBlogsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profileservice.RecommendBlogsReply.repeatedFields_, null);
};
goog.inherits(proto.profileservice.RecommendBlogsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.RecommendBlogsReply.displayName = 'proto.profileservice.RecommendBlogsReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.RecommendBlog.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.RecommendBlog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.RecommendBlog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.RecommendBlog.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 4, ""),
    coverImageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    restriction: jspb.Message.getFieldWithDefault(msg, 9, 0),
    tocAvailable: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    kind: jspb.Message.getFieldWithDefault(msg, 11, 0),
    openedAt: jspb.Message.getFieldWithDefault(msg, 12, 0),
    price: jspb.Message.getFieldWithDefault(msg, 13, 0),
    categoryName: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.RecommendBlog}
 */
proto.profileservice.RecommendBlog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.RecommendBlog;
  return proto.profileservice.RecommendBlog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.RecommendBlog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.RecommendBlog}
 */
proto.profileservice.RecommendBlog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoverImageUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRestriction(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTocAvailable(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKind(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpenedAt(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.RecommendBlog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.RecommendBlog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.RecommendBlog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.RecommendBlog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCoverImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getRestriction();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getTocAvailable();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getKind();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getOpenedAt();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getCategoryName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.profileservice.RecommendBlog.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string summary = 4;
 * @return {string}
 */
proto.profileservice.RecommendBlog.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cover_image_url = 5;
 * @return {string}
 */
proto.profileservice.RecommendBlog.prototype.getCoverImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setCoverImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 status = 8;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 restriction = 9;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getRestriction = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setRestriction = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool toc_available = 10;
 * @return {boolean}
 */
proto.profileservice.RecommendBlog.prototype.getTocAvailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setTocAvailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 kind = 11;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getKind = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setKind = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 opened_at = 12;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getOpenedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setOpenedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 price = 13;
 * @return {number}
 */
proto.profileservice.RecommendBlog.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string category_name = 14;
 * @return {string}
 */
proto.profileservice.RecommendBlog.prototype.getCategoryName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.RecommendBlog} returns this
 */
proto.profileservice.RecommendBlog.prototype.setCategoryName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.GetRecommendBlogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.GetRecommendBlogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.GetRecommendBlogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetRecommendBlogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    blogId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.GetRecommendBlogsRequest}
 */
proto.profileservice.GetRecommendBlogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.GetRecommendBlogsRequest;
  return proto.profileservice.GetRecommendBlogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.GetRecommendBlogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.GetRecommendBlogsRequest}
 */
proto.profileservice.GetRecommendBlogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBlogId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.GetRecommendBlogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.GetRecommendBlogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.GetRecommendBlogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetRecommendBlogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlogId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 blog_id = 1;
 * @return {number}
 */
proto.profileservice.GetRecommendBlogsRequest.prototype.getBlogId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetRecommendBlogsRequest} returns this
 */
proto.profileservice.GetRecommendBlogsRequest.prototype.setBlogId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profileservice.RecommendBlogsReply.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.RecommendBlogsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.RecommendBlogsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.RecommendBlogsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.RecommendBlogsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    recommendBlogsList: jspb.Message.toObjectList(msg.getRecommendBlogsList(),
    proto.profileservice.RecommendBlog.toObject, includeInstance),
    blogUserIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.RecommendBlogsReply}
 */
proto.profileservice.RecommendBlogsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.RecommendBlogsReply;
  return proto.profileservice.RecommendBlogsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.RecommendBlogsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.RecommendBlogsReply}
 */
proto.profileservice.RecommendBlogsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.profileservice.RecommendBlog;
      reader.readMessage(value,proto.profileservice.RecommendBlog.deserializeBinaryFromReader);
      msg.addRecommendBlogs(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setBlogUserIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.RecommendBlogsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.RecommendBlogsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.RecommendBlogsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.RecommendBlogsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecommendBlogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.profileservice.RecommendBlog.serializeBinaryToWriter
    );
  }
  f = message.getBlogUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * repeated RecommendBlog recommend_blogs = 1;
 * @return {!Array<!proto.profileservice.RecommendBlog>}
 */
proto.profileservice.RecommendBlogsReply.prototype.getRecommendBlogsList = function() {
  return /** @type{!Array<!proto.profileservice.RecommendBlog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.profileservice.RecommendBlog, 1));
};


/**
 * @param {!Array<!proto.profileservice.RecommendBlog>} value
 * @return {!proto.profileservice.RecommendBlogsReply} returns this
*/
proto.profileservice.RecommendBlogsReply.prototype.setRecommendBlogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.profileservice.RecommendBlog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profileservice.RecommendBlog}
 */
proto.profileservice.RecommendBlogsReply.prototype.addRecommendBlogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.profileservice.RecommendBlog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.RecommendBlogsReply} returns this
 */
proto.profileservice.RecommendBlogsReply.prototype.clearRecommendBlogsList = function() {
  return this.setRecommendBlogsList([]);
};


/**
 * repeated int32 blog_user_ids = 2;
 * @return {!Array<number>}
 */
proto.profileservice.RecommendBlogsReply.prototype.getBlogUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.profileservice.RecommendBlogsReply} returns this
 */
proto.profileservice.RecommendBlogsReply.prototype.setBlogUserIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.profileservice.RecommendBlogsReply} returns this
 */
proto.profileservice.RecommendBlogsReply.prototype.addBlogUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.RecommendBlogsReply} returns this
 */
proto.profileservice.RecommendBlogsReply.prototype.clearBlogUserIdsList = function() {
  return this.setBlogUserIdsList([]);
};


goog.object.extend(exports, proto.profileservice);
