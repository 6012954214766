// source: apigateway/notification_activities/notification_activities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.notification_activities.GetNotificationsReply', null, global);
goog.exportSymbol('proto.notification_activities.GetNotificationsRequest', null, global);
goog.exportSymbol('proto.notification_activities.Notification', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification_activities.GetNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification_activities.GetNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification_activities.GetNotificationsRequest.displayName = 'proto.notification_activities.GetNotificationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification_activities.GetNotificationsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notification_activities.GetNotificationsReply.repeatedFields_, null);
};
goog.inherits(proto.notification_activities.GetNotificationsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification_activities.GetNotificationsReply.displayName = 'proto.notification_activities.GetNotificationsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification_activities.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification_activities.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification_activities.Notification.displayName = 'proto.notification_activities.Notification';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification_activities.GetNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.notification_activities.GetNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification_activities.GetNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification_activities.GetNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    iconSize: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isRead: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification_activities.GetNotificationsRequest}
 */
proto.notification_activities.GetNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification_activities.GetNotificationsRequest;
  return proto.notification_activities.GetNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification_activities.GetNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification_activities.GetNotificationsRequest}
 */
proto.notification_activities.GetNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconSize(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRead(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification_activities.GetNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification_activities.GetNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification_activities.GetNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification_activities.GetNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIconSize();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsRead();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.notification_activities.GetNotificationsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.GetNotificationsRequest} returns this
 */
proto.notification_activities.GetNotificationsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.notification_activities.GetNotificationsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.GetNotificationsRequest} returns this
 */
proto.notification_activities.GetNotificationsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string icon_size = 3;
 * @return {string}
 */
proto.notification_activities.GetNotificationsRequest.prototype.getIconSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification_activities.GetNotificationsRequest} returns this
 */
proto.notification_activities.GetNotificationsRequest.prototype.setIconSize = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_read = 4;
 * @return {boolean}
 */
proto.notification_activities.GetNotificationsRequest.prototype.getIsRead = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notification_activities.GetNotificationsRequest} returns this
 */
proto.notification_activities.GetNotificationsRequest.prototype.setIsRead = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notification_activities.GetNotificationsReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification_activities.GetNotificationsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.notification_activities.GetNotificationsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification_activities.GetNotificationsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification_activities.GetNotificationsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.notification_activities.Notification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification_activities.GetNotificationsReply}
 */
proto.notification_activities.GetNotificationsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification_activities.GetNotificationsReply;
  return proto.notification_activities.GetNotificationsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification_activities.GetNotificationsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification_activities.GetNotificationsReply}
 */
proto.notification_activities.GetNotificationsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.notification_activities.Notification;
      reader.readMessage(value,proto.notification_activities.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification_activities.GetNotificationsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification_activities.GetNotificationsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification_activities.GetNotificationsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification_activities.GetNotificationsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.notification_activities.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.notification_activities.GetNotificationsReply.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.GetNotificationsReply} returns this
 */
proto.notification_activities.GetNotificationsReply.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Notification notifications = 2;
 * @return {!Array<!proto.notification_activities.Notification>}
 */
proto.notification_activities.GetNotificationsReply.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.notification_activities.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification_activities.Notification, 2));
};


/**
 * @param {!Array<!proto.notification_activities.Notification>} value
 * @return {!proto.notification_activities.GetNotificationsReply} returns this
*/
proto.notification_activities.GetNotificationsReply.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.notification_activities.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification_activities.Notification}
 */
proto.notification_activities.GetNotificationsReply.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.notification_activities.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification_activities.GetNotificationsReply} returns this
 */
proto.notification_activities.GetNotificationsReply.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification_activities.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.notification_activities.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification_activities.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification_activities.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fromUserIcon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromUserIconResizable: jspb.Message.getFieldWithDefault(msg, 4, ""),
    message: jspb.Message.getFieldWithDefault(msg, 5, ""),
    activityType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    notificationType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    url: jspb.Message.getFieldWithDefault(msg, 8, ""),
    opened: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    expireDate: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification_activities.Notification}
 */
proto.notification_activities.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification_activities.Notification;
  return proto.notification_activities.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification_activities.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification_activities.Notification}
 */
proto.notification_activities.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserIcon(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserIconResizable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActivityType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotificationType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpened(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpireDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification_activities.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification_activities.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification_activities.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification_activities.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFromUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFromUserIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromUserIconResizable();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActivityType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNotificationType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOpened();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getExpireDate();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.notification_activities.Notification.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 from_user_id = 2;
 * @return {number}
 */
proto.notification_activities.Notification.prototype.getFromUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setFromUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string from_user_icon = 3;
 * @return {string}
 */
proto.notification_activities.Notification.prototype.getFromUserIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setFromUserIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string from_user_icon_resizable = 4;
 * @return {string}
 */
proto.notification_activities.Notification.prototype.getFromUserIconResizable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setFromUserIconResizable = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string message = 5;
 * @return {string}
 */
proto.notification_activities.Notification.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 activity_type = 6;
 * @return {number}
 */
proto.notification_activities.Notification.prototype.getActivityType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 notification_type = 7;
 * @return {number}
 */
proto.notification_activities.Notification.prototype.getNotificationType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setNotificationType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string url = 8;
 * @return {string}
 */
proto.notification_activities.Notification.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool opened = 9;
 * @return {boolean}
 */
proto.notification_activities.Notification.prototype.getOpened = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setOpened = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 created_at = 10;
 * @return {number}
 */
proto.notification_activities.Notification.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 expire_date = 11;
 * @return {number}
 */
proto.notification_activities.Notification.prototype.getExpireDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.notification_activities.Notification} returns this
 */
proto.notification_activities.Notification.prototype.setExpireDate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


goog.object.extend(exports, proto.notification_activities);
