import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { GetRecommendBlogsRequest } from '~/stub/apigateway/profile/recommend_blog_pb'
import { RecommendBlogService } from '~/stub/apigateway/profile/recommend_blog_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'
import { toInt32 } from '~/grpc/grpc-util'

// initial state
const initialState = () => ({
  recommendBlogsList: {},
  blogUsersList: {}
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchRecommendBlog({ commit, rootState }, blogId) {
    const response = await new Promise((resolve, reject) => {
      const request = new GetRecommendBlogsRequest()
      request.setBlogId(toInt32(blogId, true))
      const metadata = commonMetaData(rootState)
      grpc.invoke(RecommendBlogService.GetRecommendBlogs, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_RECOMMEND_BLOG', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_RECOMMEND_BLOG(state, data) {
    Object.assign(state, {
      ...data
    })
  }
}
