import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '@/store/_helpers/common-helper'
import { GetIntroductionRequest } from '@/stub/apigateway/profile/introduction_pb'
import { IntroductionService } from '@/stub/apigateway/profile/introduction_pb_service'
import { GetServicesRequest } from '@/stub/apigateway/profile/service_pb'
import { ServiceService } from '@/stub/apigateway/profile/service_pb_service'
import { GetPortfoliosRequest } from '@/stub/apigateway/profile/portfolio_pb'
import { PortfolioService } from '@/stub/apigateway/profile/portfolio_pb_service'
import { FavoriteRequest } from '@/stub/apigateway/profile/favorite_pb'
import { FavoriteService } from '@/stub/apigateway/profile/favorite_pb_service'

// initial state
const initialState = () => ({
  introduction: {
    catchphrase: ''
  },
  servicesList: [],
  userSpecialtiesList: [],
  skillsList: [],
  licensesList: [],
  portfoliosList: [],
  careersList: [],
  awardsList: [],
  ratingCommentsList: [],
  experienceJobsList: [],
  userWorkHistoriesList: [],
  schoolHistoriesList: [],
  languageSkillsList: []
})

// state
export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchHome({ commit, rootState }) {
    const [
      { UserSpecialtyService },
      { GetUserSpecialtiesRequest },
      { SkillService },
      { GetSkillsRequest },
      { LicenseService },
      { GetLicensesRequest },
      { CareerService },
      { GetCareersRequest },
      { AwardService },
      { GetAwardsRequest },
      { RatingService },
      { GetRatingCommentsRequest },
      { ExperienceJobService },
      { GetExperienceJobsRequest },
      { UserWorkHistoryService },
      { GetUserWorkHistoriesRequest },
      { SchoolHistoryService },
      { GetHistoriesRequest },
      { LanguageSkillService },
      { GetLanguageSkillsRequest }
    ] = await Promise.all([
      import('~/stub/apigateway/profile/user_specialty_pb_service'),
      import('~/stub/apigateway/profile/user_specialty_pb'),
      import('~/stub/apigateway/profile/skill_pb_service'),
      import('~/stub/apigateway/profile/skill_pb'),
      import('~/stub/apigateway/profile/license_pb_service'),
      import('~/stub/apigateway/profile/license_pb'),
      import('~/stub/apigateway/profile/career_pb_service'),
      import('~/stub/apigateway/profile/career_pb'),
      import('~/stub/apigateway/profile/award_pb_service'),
      import('~/stub/apigateway/profile/award_pb'),
      import('~/stub/apigateway/profile/rating_pb_service'),
      import('~/stub/apigateway/profile/rating_pb'),
      import('~/stub/apigateway/profile/experience_job_pb_service'),
      import('~/stub/apigateway/profile/experience_job_pb'),
      import('~/stub/apigateway/profile/user_work_history_pb_service'),
      import('~/stub/apigateway/profile/user_work_history_pb'),
      import('~/stub/apigateway/profile/school_history_pb_service'),
      import('~/stub/apigateway/profile/school_history_pb'),
      import('~/stub/apigateway/profile/language_skill_pb_service'),
      import('~/stub/apigateway/profile/language_skill_pb')
    ])

    const metadata = commonMetaData(rootState)
    const grpcClientContextList = [
      (() => {
        const request = new GetIntroductionRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: IntroductionService.GetIntroduction,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetServicesRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: ServiceService.GetServices,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetUserSpecialtiesRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: UserSpecialtyService.GetUserSpecialties,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetSkillsRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: SkillService.GetSkills,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetLicensesRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: LicenseService.GetLicenses,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetPortfoliosRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: PortfolioService.GetPortfolios,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetCareersRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: CareerService.GetCareers,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetAwardsRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: AwardService.GetAwards,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetRatingCommentsRequest()
        request.setUserId(rootState.pages.users.user.id)
        request.setPage(1)
        request.setLimit(3)
        return {
          method: RatingService.GetRatingComments,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetExperienceJobsRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: ExperienceJobService.GetExperienceJobs,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetUserWorkHistoriesRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: UserWorkHistoryService.GetUserWorkHistories,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetHistoriesRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: SchoolHistoryService.GetHistories,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetLanguageSkillsRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: LanguageSkillService.GetLanguageSkills,
          request,
          metadata
        }
      })()
    ]
    await Promise.all(
      grpcClientContextList.map(context => {
        return grpcClient(context).then(reply => commit('RECEIVE_HOME', reply))
      })
    )
  },
  async toggleFavorite({ commit, rootState }, { service }) {
    const metadata = commonMetaData(rootState)
    const request = new FavoriteRequest()
    request.setServiceId(service.id)
    await grpcClient({
      method: service.favoriteFlag ? FavoriteService.DeleteFavorite : FavoriteService.AddFavorite,
      request,
      metadata
    })
    commit('TOGGLE_FAVORITE', service.id)
  }
}

// mutations
export const mutations = {
  RESET(state) {
    Object.assign(state, initialState())
  },
  RECEIVE_HOME(state, data) {
    Object.assign(state, data)
  },
  TOGGLE_FAVORITE(state, serviceId) {
    let target = state.servicesList.find(s => s.id === serviceId)
    target.favoriteFlag = !target.favoriteFlag
  }
}
