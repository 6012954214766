import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '@/store/_helpers/common-helper'
import { GetServicesRequest } from '@/stub/apigateway/profile/service_pb'
import { ServiceService } from '@/stub/apigateway/profile/service_pb_service'
import { FavoriteRequest } from '@/stub/apigateway/profile/favorite_pb'
import { FavoriteService } from '@/stub/apigateway/profile/favorite_pb_service'

// initial state
const initialState = () => ({
  servicesList: [],
  providerStatus: {
    phoneWait: false,
    phoneTalking: false
  }
})

// state
export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchServices({ commit, rootState }) {
    const metadata = commonMetaData(rootState)
    const request = new GetServicesRequest()
    request.setUserId(rootState.pages.users.user.id)
    request.setUserAction('view_profile_service')
    const response = await grpcClient({
      method: ServiceService.GetServices,
      request,
      metadata
    })
    commit('RECEIVE_SERVICES', response)
  },
  async toggleFavorite({ commit, rootState }, { service }) {
    const metadata = commonMetaData(rootState)
    const request = new FavoriteRequest()
    request.setServiceId(service.id)
    await grpcClient({
      method: service.favoriteFlag ? FavoriteService.DeleteFavorite : FavoriteService.AddFavorite,
      request,
      metadata
    })
    commit('TOGGLE_FAVORITE', service.id)
  }
}

// mutations
export const mutations = {
  RESET(state) {
    Object.assign(state, initialState())
  },
  RECEIVE_SERVICES(state, data) {
    Object.assign(state, data)
  },
  TOGGLE_FAVORITE(state, serviceId) {
    const target = state.servicesList.find(s => s.id === serviceId)
    target.favoriteFlag = !target.favoriteFlag
  }
}
