import { Middleware } from '@nuxt/types'

/**
 * 未ログイン時にtab=3(フォロータブ)の指定があった場合、/blogs/へリダイレクトする
 */
const blogSearchRedirect: Middleware = ({ route, store, redirect }) => {
  const tab = Number(route.query.tab || -1)
  if (!store.getters['auth/isLoggedIn'] && tab === store.state.pages.blogs.search.TABS.ONLY_FOLLOWING ) {
    redirect(302, '/blogs/')
  }
}
export default blogSearchRedirect
