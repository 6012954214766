// source: apigateway/transaction_activities/transaction_activities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.transaction_activities.GetTransactionsReply', null, global);
goog.exportSymbol('proto.transaction_activities.GetTransactionsRequest', null, global);
goog.exportSymbol('proto.transaction_activities.Transaction', null, global);
goog.exportSymbol('proto.transaction_activities.UpdateTransactionReply', null, global);
goog.exportSymbol('proto.transaction_activities.UpdateTransactionRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transaction_activities.GetTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transaction_activities.GetTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transaction_activities.GetTransactionsRequest.displayName = 'proto.transaction_activities.GetTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transaction_activities.GetTransactionsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.transaction_activities.GetTransactionsReply.repeatedFields_, null);
};
goog.inherits(proto.transaction_activities.GetTransactionsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transaction_activities.GetTransactionsReply.displayName = 'proto.transaction_activities.GetTransactionsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transaction_activities.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transaction_activities.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transaction_activities.Transaction.displayName = 'proto.transaction_activities.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transaction_activities.UpdateTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transaction_activities.UpdateTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transaction_activities.UpdateTransactionRequest.displayName = 'proto.transaction_activities.UpdateTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.transaction_activities.UpdateTransactionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.transaction_activities.UpdateTransactionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.transaction_activities.UpdateTransactionReply.displayName = 'proto.transaction_activities.UpdateTransactionReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transaction_activities.GetTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transaction_activities.GetTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transaction_activities.GetTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.GetTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    iconSize: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transaction_activities.GetTransactionsRequest}
 */
proto.transaction_activities.GetTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transaction_activities.GetTransactionsRequest;
  return proto.transaction_activities.GetTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transaction_activities.GetTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transaction_activities.GetTransactionsRequest}
 */
proto.transaction_activities.GetTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transaction_activities.GetTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transaction_activities.GetTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transaction_activities.GetTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.GetTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIconSize();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.transaction_activities.GetTransactionsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.GetTransactionsRequest} returns this
 */
proto.transaction_activities.GetTransactionsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.transaction_activities.GetTransactionsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.GetTransactionsRequest} returns this
 */
proto.transaction_activities.GetTransactionsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string icon_size = 3;
 * @return {string}
 */
proto.transaction_activities.GetTransactionsRequest.prototype.getIconSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.transaction_activities.GetTransactionsRequest} returns this
 */
proto.transaction_activities.GetTransactionsRequest.prototype.setIconSize = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.transaction_activities.GetTransactionsReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transaction_activities.GetTransactionsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.transaction_activities.GetTransactionsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transaction_activities.GetTransactionsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.GetTransactionsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.transaction_activities.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transaction_activities.GetTransactionsReply}
 */
proto.transaction_activities.GetTransactionsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transaction_activities.GetTransactionsReply;
  return proto.transaction_activities.GetTransactionsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transaction_activities.GetTransactionsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transaction_activities.GetTransactionsReply}
 */
proto.transaction_activities.GetTransactionsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.transaction_activities.Transaction;
      reader.readMessage(value,proto.transaction_activities.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transaction_activities.GetTransactionsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transaction_activities.GetTransactionsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transaction_activities.GetTransactionsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.GetTransactionsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.transaction_activities.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.transaction_activities.GetTransactionsReply.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.GetTransactionsReply} returns this
 */
proto.transaction_activities.GetTransactionsReply.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Transaction transactions = 2;
 * @return {!Array<!proto.transaction_activities.Transaction>}
 */
proto.transaction_activities.GetTransactionsReply.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.transaction_activities.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.transaction_activities.Transaction, 2));
};


/**
 * @param {!Array<!proto.transaction_activities.Transaction>} value
 * @return {!proto.transaction_activities.GetTransactionsReply} returns this
*/
proto.transaction_activities.GetTransactionsReply.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.transaction_activities.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.transaction_activities.Transaction}
 */
proto.transaction_activities.GetTransactionsReply.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.transaction_activities.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.transaction_activities.GetTransactionsReply} returns this
 */
proto.transaction_activities.GetTransactionsReply.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transaction_activities.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.transaction_activities.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transaction_activities.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fromUserName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromUserIcon: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fromUserIconResizable: jspb.Message.getFieldWithDefault(msg, 5, ""),
    groupType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    message: jspb.Message.getFieldWithDefault(msg, 8, ""),
    infoType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    activityType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    notificationType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    url: jspb.Message.getFieldWithDefault(msg, 12, ""),
    opened: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    notificationCount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    title: jspb.Message.getFieldWithDefault(msg, 16, ""),
    todoTitle: jspb.Message.getFieldWithDefault(msg, 17, ""),
    version: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transaction_activities.Transaction}
 */
proto.transaction_activities.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transaction_activities.Transaction;
  return proto.transaction_activities.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transaction_activities.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transaction_activities.Transaction}
 */
proto.transaction_activities.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserIcon(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserIconResizable(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupType(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInfoType(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActivityType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNotificationType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpened(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNotificationCount(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTodoTitle(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transaction_activities.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transaction_activities.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transaction_activities.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFromUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFromUserName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromUserIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFromUserIconResizable();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGroupType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInfoType();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getActivityType();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getNotificationType();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOpened();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getNotificationCount();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTodoTitle();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 from_user_id = 2;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getFromUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setFromUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string from_user_name = 3;
 * @return {string}
 */
proto.transaction_activities.Transaction.prototype.getFromUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setFromUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string from_user_icon = 4;
 * @return {string}
 */
proto.transaction_activities.Transaction.prototype.getFromUserIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setFromUserIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string from_user_icon_resizable = 5;
 * @return {string}
 */
proto.transaction_activities.Transaction.prototype.getFromUserIconResizable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setFromUserIconResizable = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 group_type = 6;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getGroupType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setGroupType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 group_id = 7;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string message = 8;
 * @return {string}
 */
proto.transaction_activities.Transaction.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 info_type = 9;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getInfoType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setInfoType = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 activity_type = 10;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getActivityType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setActivityType = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 notification_type = 11;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getNotificationType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setNotificationType = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string url = 12;
 * @return {string}
 */
proto.transaction_activities.Transaction.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool opened = 13;
 * @return {boolean}
 */
proto.transaction_activities.Transaction.prototype.getOpened = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setOpened = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int64 created_at = 14;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 notification_count = 15;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getNotificationCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setNotificationCount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string title = 16;
 * @return {string}
 */
proto.transaction_activities.Transaction.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string todo_title = 17;
 * @return {string}
 */
proto.transaction_activities.Transaction.prototype.getTodoTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setTodoTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 version = 18;
 * @return {number}
 */
proto.transaction_activities.Transaction.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.Transaction} returns this
 */
proto.transaction_activities.Transaction.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transaction_activities.UpdateTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.transaction_activities.UpdateTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transaction_activities.UpdateTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.UpdateTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    triggerType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transaction_activities.UpdateTransactionRequest}
 */
proto.transaction_activities.UpdateTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transaction_activities.UpdateTransactionRequest;
  return proto.transaction_activities.UpdateTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transaction_activities.UpdateTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transaction_activities.UpdateTransactionRequest}
 */
proto.transaction_activities.UpdateTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroupId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTriggerType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transaction_activities.UpdateTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transaction_activities.UpdateTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transaction_activities.UpdateTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.UpdateTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTriggerType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 group_type = 1;
 * @return {number}
 */
proto.transaction_activities.UpdateTransactionRequest.prototype.getGroupType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.UpdateTransactionRequest} returns this
 */
proto.transaction_activities.UpdateTransactionRequest.prototype.setGroupType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 group_id = 2;
 * @return {number}
 */
proto.transaction_activities.UpdateTransactionRequest.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.UpdateTransactionRequest} returns this
 */
proto.transaction_activities.UpdateTransactionRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 trigger_type = 3;
 * @return {number}
 */
proto.transaction_activities.UpdateTransactionRequest.prototype.getTriggerType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.transaction_activities.UpdateTransactionRequest} returns this
 */
proto.transaction_activities.UpdateTransactionRequest.prototype.setTriggerType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.transaction_activities.UpdateTransactionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.transaction_activities.UpdateTransactionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.transaction_activities.UpdateTransactionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.UpdateTransactionReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.transaction_activities.UpdateTransactionReply}
 */
proto.transaction_activities.UpdateTransactionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.transaction_activities.UpdateTransactionReply;
  return proto.transaction_activities.UpdateTransactionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.transaction_activities.UpdateTransactionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.transaction_activities.UpdateTransactionReply}
 */
proto.transaction_activities.UpdateTransactionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.transaction_activities.UpdateTransactionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.transaction_activities.UpdateTransactionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.transaction_activities.UpdateTransactionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.transaction_activities.UpdateTransactionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.transaction_activities);
