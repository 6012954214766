import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { AddUserFeedbackRequest } from '~/stub/apigateway/footer/footer_pb'
import { FooterService } from '~/stub/apigateway/footer/footer_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async sendGoikenBox({ commit, rootState }, data) {
    const getRequest = new AddUserFeedbackRequest()
    const setMetadata = commonMetaData(rootState)

    let ccuid = this.$cookies.get(process.env.config['ccuid'])
    getRequest.setMessage(String(data.contentBody))
    getRequest.setCcuid(ccuid)
    await new Promise((resolve, reject) => {
      grpc.invoke(FooterService.AddUserFeedback, {
        request: getRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {}
