
import {
  useRoute,
  defineComponent,
  computed,
  ref,
  onMounted,
  useContext
} from '@nuxtjs/composition-api'
import TheHeader from '~/components/organisms/TheHeader'
import TheFooter from '~/components/organisms/TheFooter'
import { LANGUAGES, LanguageType, FIRST_TIME_LANDING_FOR_TRANSLATE } from '~/constants/language'
import { getBrowserLanguage } from '~/components/logics/translate/getBrowserLanguage'
import { isUserAgentBot } from '~/components/logics/platform'

/** フッター表示ルート */
const SHOW_FOOTER_ROUTES: readonly string[] = Object.freeze([
  'register-top',
  'business-register-invitation_link-linkId'
])

export default defineComponent({
  head() {
    const meta: { name: string; content: string }[] = []
    if (this.$route.path !== '/register/top') {
      meta.push({
        name: 'robots',
        content: 'noindex, nofollow'
      })
    }
    return {
      title: 'ユーザー情報入力｜ココナラ',
      meta
    }
  },
  components: {
    TheHeader,
    TheFooter,
    PageTranslate: () => import('~/components/organisms/PageTranslate'),
    DLanguageSwitchModal: () => import('~/components/organisms/DS2/DLanguageSwitchModal')
  },
  middleware: ({ store }) => {
    store.commit('layouts/UPDATE_LOGO_ONLY_MODE', true)
  },
  setup() {
    const { $cookies, store } = useContext()
    const route = useRoute()
    const browserLanguage = ref<LanguageType>(LANGUAGES.JA.code)
    const showFooter = computed(() => SHOW_FOOTER_ROUTES.includes(route.value.name || ''))

    onMounted(() => {
      browserLanguage.value = getBrowserLanguage()
    })

    const isShowLanguageModal = computed(() => {
      return (
        !$cookies.get(FIRST_TIME_LANDING_FOR_TRANSLATE) &&
        browserLanguage.value !== LANGUAGES.JA.code &&
        !isUserAgentBot(store.state.auth.ua) &&
        !route.value.path.includes('mzhc')
      )
    })

    return {
      showFooter,
      isShowLanguageModal
    }
  }
})
