
import { toTranslatedText } from '~/components/logics/translate/translate'
import { translateTargetPage } from '~/components/logics/translate/target-page'

const ICON_NAMES = Object.freeze({
  success: 'check-circle',
  error: 'exclamation-circle'
})

const BASE_VERTICAL_POSITION = Object.freeze({
  top: 24,
  bottom: -24
})

const dToast = {
  name: 'DToast',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    message: {
      type: String,
      required: true
    },
    position: {
      type: String,
      default: 'top'
    },
    duration: {
      type: Number,
      default: 5000
    },
    hasIcon: {
      type: Boolean,
      default: true
    },
    isWrap: {
      type: Boolean,
      default: true
    },
    /** 下方向を正とする垂直方向オフセット */
    verticalOffset: {
      type: Number,
      default: 0
    },
    showHideButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /** 表示フラグ */
      isShow: false,
      /**
       * トーストが画面上に表示されていないかどうかの判定フラグ
       * isShowとの違いは、消えるアニメーションが終了して見えなくなって初めてtrueに戻ること
       */
      isHidden: true,
      windowHeight: 0,
      translatedMessage: '',
      // 1行分の高さをデフォルト値で設定
      toastHeight: 40,
      timerId: null
    }
  },
  computed: {
    iconName() {
      return ICON_NAMES[this.type]
    },
    classes() {
      const classes = [`d-toast-${this.type}`, `d-toast-${this.position}`]
      if (this.verticalOffset !== 0) {
        classes.push('d-toast-verticalOffset')
      }
      if (this.isWrap) {
        classes.push('d-toast-wrap')
      }
      return classes
    },
    styles() {
      const verticalPosition =
        this.position === 'top'
          ? BASE_VERTICAL_POSITION[this.position] + this.toastHeight
          : BASE_VERTICAL_POSITION[this.position] - this.toastHeight

      const styles = {
        '--vertical-position': `${verticalPosition + this.verticalOffset}px`
      }
      if (this.windowHeight > 0) {
        if (this.position === 'top') {
          styles['bottom'] = `${this.windowHeight}px`
        } else if (this.position === 'bottom') {
          styles['top'] = `${this.windowHeight}px`
        }
      }
      return styles
    }
  },
  methods: {
    show() {
      if (this.isShow || !this.isHidden) return
      this.windowHeight = window.innerHeight
      this.isShow = true
      this.isHidden = false

      // NOTE: isShowがtrueになった直後だとトーストの要素の高さが取得できなかったので、若干タイミングをずらしている
      setTimeout(() => {
        const toastHeight = this.$refs.toast?.clientHeight
        if (toastHeight) {
          this.toastHeight = toastHeight
        }
      }, 10)
      this.timerId = setTimeout(this.hide, this.duration)
    },
    hide() {
      this.isShow = false
      clearTimeout(this.timerId)
    }
  },
  watch: {
    async message(newValue) {
      if (translateTargetPage(this.$nuxt.$route.name)) {
        this.translatedMessage = await toTranslatedText(newValue)
      }
    }
  },
  async created() {
    if (translateTargetPage(this.$nuxt.$route.name)) {
      this.translatedMessage = await toTranslatedText(this.message)
    }
  },
  mounted() {
    this.show()
  }
}
export default dToast
