// source: apigateway/analytics/service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.analytics.GetAnalyticsServiceReply', null, global);
goog.exportSymbol('proto.analytics.GetAnalyticsServiceRequest', null, global);
goog.exportSymbol('proto.analytics.NumberOfTerm', null, global);
goog.exportSymbol('proto.analytics.UpdateServiceMemoReply', null, global);
goog.exportSymbol('proto.analytics.UpdateServiceMemoRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.GetAnalyticsServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.GetAnalyticsServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.GetAnalyticsServiceRequest.displayName = 'proto.analytics.GetAnalyticsServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.GetAnalyticsServiceReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.analytics.GetAnalyticsServiceReply.repeatedFields_, null);
};
goog.inherits(proto.analytics.GetAnalyticsServiceReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.GetAnalyticsServiceReply.displayName = 'proto.analytics.GetAnalyticsServiceReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.NumberOfTerm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.NumberOfTerm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.NumberOfTerm.displayName = 'proto.analytics.NumberOfTerm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.UpdateServiceMemoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.UpdateServiceMemoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.UpdateServiceMemoRequest.displayName = 'proto.analytics.UpdateServiceMemoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.analytics.UpdateServiceMemoReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.analytics.UpdateServiceMemoReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.analytics.UpdateServiceMemoReply.displayName = 'proto.analytics.UpdateServiceMemoReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.GetAnalyticsServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.GetAnalyticsServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.GetAnalyticsServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.GetAnalyticsServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetDay: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.GetAnalyticsServiceRequest}
 */
proto.analytics.GetAnalyticsServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.GetAnalyticsServiceRequest;
  return proto.analytics.GetAnalyticsServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.GetAnalyticsServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.GetAnalyticsServiceRequest}
 */
proto.analytics.GetAnalyticsServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.GetAnalyticsServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.GetAnalyticsServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.GetAnalyticsServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.GetAnalyticsServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTargetDay();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 service_id = 1;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceRequest.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceRequest} returns this
 */
proto.analytics.GetAnalyticsServiceRequest.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 target_day = 2;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceRequest.prototype.getTargetDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceRequest} returns this
 */
proto.analytics.GetAnalyticsServiceRequest.prototype.setTargetDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.analytics.GetAnalyticsServiceReply.repeatedFields_ = [13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.GetAnalyticsServiceReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.GetAnalyticsServiceReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.GetAnalyticsServiceReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    numberOfView: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numberOfMaxLineView: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfPerLineView: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numberOfSale: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numberOfMaxLineSale: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numberOfPerLineSale: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: jspb.Message.getFieldWithDefault(msg, 7, 0),
    numberOfFavorite: jspb.Message.getFieldWithDefault(msg, 8, 0),
    beginOfDate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    endOfDate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    memo: jspb.Message.getFieldWithDefault(msg, 11, ""),
    targetDay: jspb.Message.getFieldWithDefault(msg, 12, 0),
    numberOfTermsList: jspb.Message.toObjectList(msg.getNumberOfTermsList(),
    proto.analytics.NumberOfTerm.toObject, includeInstance),
    tax: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.GetAnalyticsServiceReply}
 */
proto.analytics.GetAnalyticsServiceReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.GetAnalyticsServiceReply;
  return proto.analytics.GetAnalyticsServiceReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.GetAnalyticsServiceReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.GetAnalyticsServiceReply}
 */
proto.analytics.GetAnalyticsServiceReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfView(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfMaxLineView(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfPerLineView(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfSale(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfMaxLineSale(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfPerLineSale(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfFavorite(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeginOfDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndOfDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetDay(value);
      break;
    case 13:
      var value = new proto.analytics.NumberOfTerm;
      reader.readMessage(value,proto.analytics.NumberOfTerm.deserializeBinaryFromReader);
      msg.addNumberOfTerms(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.GetAnalyticsServiceReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.GetAnalyticsServiceReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.GetAnalyticsServiceReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumberOfView();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNumberOfMaxLineView();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNumberOfPerLineView();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNumberOfSale();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getNumberOfMaxLineSale();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getNumberOfPerLineSale();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getNumberOfFavorite();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getBeginOfDate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEndOfDate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTargetDay();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getNumberOfTermsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.analytics.NumberOfTerm.serializeBinaryToWriter
    );
  }
  f = message.getTax();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional int32 number_of_view = 1;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getNumberOfView = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setNumberOfView = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 number_of_max_line_view = 2;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getNumberOfMaxLineView = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setNumberOfMaxLineView = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 number_of_per_line_view = 3;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getNumberOfPerLineView = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setNumberOfPerLineView = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 number_of_sale = 4;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getNumberOfSale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setNumberOfSale = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 number_of_max_line_sale = 5;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getNumberOfMaxLineSale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setNumberOfMaxLineSale = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 number_of_per_line_sale = 6;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getNumberOfPerLineSale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setNumberOfPerLineSale = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 price = 7;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 number_of_favorite = 8;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getNumberOfFavorite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setNumberOfFavorite = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string begin_of_date = 9;
 * @return {string}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getBeginOfDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setBeginOfDate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string end_of_date = 10;
 * @return {string}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getEndOfDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setEndOfDate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string memo = 11;
 * @return {string}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setMemo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 target_day = 12;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getTargetDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setTargetDay = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated NumberOfTerm number_of_terms = 13;
 * @return {!Array<!proto.analytics.NumberOfTerm>}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getNumberOfTermsList = function() {
  return /** @type{!Array<!proto.analytics.NumberOfTerm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.analytics.NumberOfTerm, 13));
};


/**
 * @param {!Array<!proto.analytics.NumberOfTerm>} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
*/
proto.analytics.GetAnalyticsServiceReply.prototype.setNumberOfTermsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.analytics.NumberOfTerm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.analytics.NumberOfTerm}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.addNumberOfTerms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.analytics.NumberOfTerm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.clearNumberOfTermsList = function() {
  return this.setNumberOfTermsList([]);
};


/**
 * optional int32 tax = 14;
 * @return {number}
 */
proto.analytics.GetAnalyticsServiceReply.prototype.getTax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.GetAnalyticsServiceReply} returns this
 */
proto.analytics.GetAnalyticsServiceReply.prototype.setTax = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.NumberOfTerm.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.NumberOfTerm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.NumberOfTerm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.NumberOfTerm.toObject = function(includeInstance, msg) {
  var f, obj = {
    term: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numberOfView: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numberOfSale: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.NumberOfTerm}
 */
proto.analytics.NumberOfTerm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.NumberOfTerm;
  return proto.analytics.NumberOfTerm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.NumberOfTerm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.NumberOfTerm}
 */
proto.analytics.NumberOfTerm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerm(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfView(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfSale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.NumberOfTerm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.NumberOfTerm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.NumberOfTerm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.NumberOfTerm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTerm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumberOfView();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNumberOfSale();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string term = 1;
 * @return {string}
 */
proto.analytics.NumberOfTerm.prototype.getTerm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.NumberOfTerm} returns this
 */
proto.analytics.NumberOfTerm.prototype.setTerm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 number_of_view = 2;
 * @return {number}
 */
proto.analytics.NumberOfTerm.prototype.getNumberOfView = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.NumberOfTerm} returns this
 */
proto.analytics.NumberOfTerm.prototype.setNumberOfView = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 number_of_sale = 3;
 * @return {number}
 */
proto.analytics.NumberOfTerm.prototype.getNumberOfSale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.NumberOfTerm} returns this
 */
proto.analytics.NumberOfTerm.prototype.setNumberOfSale = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.UpdateServiceMemoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.UpdateServiceMemoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.UpdateServiceMemoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.UpdateServiceMemoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    memo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.UpdateServiceMemoRequest}
 */
proto.analytics.UpdateServiceMemoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.UpdateServiceMemoRequest;
  return proto.analytics.UpdateServiceMemoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.UpdateServiceMemoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.UpdateServiceMemoRequest}
 */
proto.analytics.UpdateServiceMemoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.UpdateServiceMemoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.UpdateServiceMemoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.UpdateServiceMemoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.UpdateServiceMemoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 service_id = 1;
 * @return {number}
 */
proto.analytics.UpdateServiceMemoRequest.prototype.getServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.analytics.UpdateServiceMemoRequest} returns this
 */
proto.analytics.UpdateServiceMemoRequest.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string memo = 2;
 * @return {string}
 */
proto.analytics.UpdateServiceMemoRequest.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.UpdateServiceMemoRequest} returns this
 */
proto.analytics.UpdateServiceMemoRequest.prototype.setMemo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.analytics.UpdateServiceMemoReply.prototype.toObject = function(opt_includeInstance) {
  return proto.analytics.UpdateServiceMemoReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.analytics.UpdateServiceMemoReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.UpdateServiceMemoReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    memo: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.analytics.UpdateServiceMemoReply}
 */
proto.analytics.UpdateServiceMemoReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.analytics.UpdateServiceMemoReply;
  return proto.analytics.UpdateServiceMemoReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.analytics.UpdateServiceMemoReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.analytics.UpdateServiceMemoReply}
 */
proto.analytics.UpdateServiceMemoReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.analytics.UpdateServiceMemoReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.analytics.UpdateServiceMemoReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.analytics.UpdateServiceMemoReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.analytics.UpdateServiceMemoReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string memo = 1;
 * @return {string}
 */
proto.analytics.UpdateServiceMemoReply.prototype.getMemo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.analytics.UpdateServiceMemoReply} returns this
 */
proto.analytics.UpdateServiceMemoReply.prototype.setMemo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.analytics);
