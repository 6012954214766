<template>
  <div class="c-modal">
    <OModal
      v-bind="$attrs"
      v-on="$listeners"
      :active="active"
      :animation="animation"
      :can-cancel="canCancel"
      content-class="modal-content"
      @close="close"
      ref="modal"
    >
      <section
        class="c-modal_inner"
        v-intersect-translate.once
      >
        <header
          v-if="$slots.header"
          class="c-modal_header"
        >
          <slot name="header" />
          <a
            v-if="canCancel"
            class="c-modal_close"
            href="javascript:;"
            @click="close"
          >
            <CoconalaIcon
              name="cross"
              size="12px"
            />
          </a>
        </header>
        <header
          v-else-if="!!title"
          class="c-modal_header"
        >
          <h1 class="c-modal_title">{{ title }}</h1>
          <a
            v-if="canCancel"
            class="c-modal_close"
            href="javascript:;"
            @click="close"
          >
            <CoconalaIcon
              name="cross"
              size="12px"
            />
          </a>
        </header>
        <div
          class="c-modal_body"
          ref="body"
        >
          <slot />
        </div>
        <footer
          v-if="$slots.footer"
          class="c-modal_footer"
        >
          <slot name="footer" />
        </footer>
      </section>
    </OModal>
    <transition
      :name="animation"
      @after-enter="$emit('opened')"
      @after-leave="$emit('closed')"
    >
      <div v-if="active"></div>
    </transition>
  </div>
</template>
<script>
import CoconalaIcon from '~/components/atoms/CoconalaIcon'

export default {
  name: 'CModal',
  components: {
    CoconalaIcon
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    animation: {
      type: String,
      default: 'zoom-out'
    },
    disableScroll: {
      type: Boolean,
      default: true
    },
    canCancel: {
      type: Boolean,
      default: true
    }
  },
  beforeDestroy() {
    // モーダルをv-if切り替えで開閉する場合を考慮して、スクロール固定の解除を入れておく。
    // また、CSR遷移時にもwatchが動作しないため本処理が必要。
    document.body.classList.remove('is-noscroll')
  },
  watch: {
    active(v) {
      if (v && this.disableScroll) {
        this.$nextTick(() => {
          document.body.classList.add('is-noscroll')
        })
      } else {
        document.body.classList.remove('is-noscroll')
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:active', false)
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .animation-content {
  z-index: z(modal, wrapper);
  margin: 0 10px;
  max-height: calc(100vh - 80px);
  width: 100%;
}
::v-deep .modal-background {
  z-index: z(modal, overlay);
}
::v-deep .modal-close {
  display: none;
}

::v-deep .zoom-out-leave-active {
  display: flex;
}

.c-modal {
  $padding-size: 16px;
  &_inner {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: $color-white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }
  &_header {
    position: relative;
    padding: 10px $padding-size;
    border-bottom: 1px solid $ds2-color-border-base;
  }
  &_title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
  &_close {
    position: absolute;
    top: 8px;
    right: 8px;
    display: inline-block;
    padding: 5px 10px;
    i {
      color: $ds2-color-gray-600;
    }
  }
  &_body {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: $padding-size;
    max-height: calc(100vh - 240px);
  }
  &_footer {
    padding: $padding-size;
  }
}

@media (max-width: $breakpoint-s) {
  .c-modal {
    $padding-size: 10px;
    &_header {
      padding: 10px $padding-size;
    }
    &_title {
      padding: 0 24px;
    }
    &_body {
      padding: $padding-size;
    }
    &_footer {
      padding: $padding-size;
    }
  }
}
</style>
