import Vue from 'vue'
const TIMEOUT = 300000

export default function ({ $axios, store, env }) {
  $axios.defaults.timeout = TIMEOUT

  if (process.client) {
    $axios.onError(error => {
      Vue.notify({
        group: 'flash',
        title: 'システムエラーが発生しました',
        text: 'ブラウザを閉じて再度やり直してください',
        type: 'error',
        max: 1
      })
    })
  }

  $axios.onRequest(config => {
    // profile-webではAPI通信にaxiosを使っていたためにキャッシュ対策でタイムスタンプをつけていたが、coconala-webでは不要なのでつけない
    // 逆に、S3アップロード時に不要なパラメータが付いてエラーを招く
    // 将来必要なら限定的に再付与を検討する(全部に付けないように注意)
    // if (typeof config.params === 'undefined') {
    //   config.params = {}
    // }
    // if (typeof config.params === 'object') {
    //   if (
    //     typeof URLSearchParams === 'function' &&
    //     config.params instanceof URLSearchParams
    //   ) {
    //     config.params.append('_', Date.now())
    //   } else {
    //     config.params._ = Date.now()
    //   }
    // }
    return config
  })
}
