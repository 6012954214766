import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({
  requestDetail: {
    id: 0,
    title: '',
    currentDate: 0,
    category: {
      id: 0,
      name: ''
    },
    parentCategory: {
      id: 0,
      name: ''
    },
    outline: {},
    summariesList: [],
    detailsList: [],
    provider: {
      id: 0,
      name: ''
    },
    isOffered: false,
    directMessageUrl: '',
    isDeclined: false,
    offerId: 0,
    masterCategory: {
      id: 0,
      name: ''
    },
    parentMasterCategory: {
      id: 0,
      name: ''
    }
  }
})

// getters
export const getters = {}

// actions
export const actions = {
  async getOrdermadeRequest({ commit, rootState }, data) {
    const [{ GetOrdermadeRequestRequest }, { OrdermadeRequestService }] = await Promise.all([
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb'),
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb_service')
    ])

    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetOrdermadeRequestRequest()

    request.setId(params.id)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(OrdermadeRequestService.GetOrdermadeRequest, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    reply.detailsList.forEach(detail => {
      if (detail.contentJson) {
        detail.contentJson = JSON.parse(detail.contentJson)
      }
    })

    reply.summariesList.forEach(detail => {
      if (detail.contentJson) {
        detail.contentJson = JSON.parse(detail.contentJson)
      }
    })

    commit('RECEIVE_ORDERMADE_REQUEST', { requestDetail: reply })
  }
}

// mutations
export const mutations = {
  RECEIVE_ORDERMADE_REQUEST(state, data) {
    Object.assign(state, data)
  }
}
