<template>
  <div class="control">
    <OInput
      v-bind="$attrs"
      v-on="$listeners"
      v-model="innerValue"
      :maxlength="null"
      :class="{ '-inline-block': inlineBlock }"
      @keydown.native.enter="emitEnterEvent"
      @compositionstart.native="composing = true"
      @compositionend.native="composing = false"
    />
    <small
      v-if="maxlength"
      class="help counter"
    >
      {{ valueLength }} / {{ maxlength }}
    </small>
  </div>
</template>
<script>
export default {
  name: 'CInput',
  props: {
    value: {
      type: [Number, String]
    },
    inlineBlock: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [Number, String]
    },
    isTruncatable: {
      type: Boolean,
      default: false
    },
    isEnableEnterEvent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newValue: this.value,
      composing: false
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        const value = this.maxlength && this.isTruncatable ? val.substring(0, this.maxlength) : val
        this.newValue = value
        // OInputのemitの方が後で走り、上書きされるため
        this.$nextTick(function () {
          this.$emit('input', value)
        })
      }
    },
    valueLength() {
      let string = ''
      if (typeof this.newValue === 'string') {
        string = this.newValue
      } else if (typeof this.newValue === 'number') {
        string = this.newValue.toString()
      }
      return string ? string.replace(/(\r\n|\r|\n)/g, '\r\n').length : 0
    }
  },
  methods: {
    emitEnterEvent() {
      // IME確定時のEnterは無視
      if (this.composing) {
        return
      }
      if (this.isEnableEnterEvent) {
        this.$emit('enter-clicked')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.control {
  &.-inline-block {
    display: inline-block;
    vertical-align: 0.4em;
    &.small {
      vertical-align: 0.2em;
    }
    &.medium {
      vertical-align: 0.6em;
    }
    &.large {
      vertical-align: 1.2em;
    }
  }
}

// NOTE: IE hack
*::-ms-backdrop,
.control {
  &.-inline-block {
    display: inline-block;
    vertical-align: -12px;
    &.small {
      vertical-align: -8px;
    }
    &.medium {
      vertical-align: -14px;
    }
    &.large {
      vertical-align: -22px;
    }
  }
}
</style>
