// package: ordermade_request.v2
// file: apigateway/ordermade_request/v2/ordermade_request.proto

import * as apigateway_ordermade_request_v2_ordermade_request_pb from "../../../apigateway/ordermade_request/v2/ordermade_request_pb";
export class OrdermadeRequestService {
  static serviceName = "ordermade_request.v2.OrdermadeRequestService";
}
export namespace OrdermadeRequestService {
  export class AddOrdermadeRequest {
    static readonly methodName = "AddOrdermadeRequest";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_v2_ordermade_request_pb.AddOrdermadeRequestRequest;
    static readonly responseType = apigateway_ordermade_request_v2_ordermade_request_pb.AddOrdermadeRequestReply;
  }
}
