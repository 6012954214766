import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/auth.js'), 'auth.js')
  resolveStoreModules(require('../store/cakeNotify.js'), 'cakeNotify.js')
  resolveStoreModules(require('../store/constants.js'), 'constants.js')
  resolveStoreModules(require('../store/external_system.js'), 'external_system.js')
  resolveStoreModules(require('../store/file_download.js'), 'file_download.js')
  resolveStoreModules(require('../store/file_upload.js'), 'file_upload.js')
  resolveStoreModules(require('../store/footer.js'), 'footer.js')
  resolveStoreModules(require('../store/gmo.js'), 'gmo.js')
  resolveStoreModules(require('../store/layouts.js'), 'layouts.js')
  resolveStoreModules(require('../store/master.js'), 'master.js')
  resolveStoreModules(require('../store/menu_guide.js'), 'menu_guide.js')
  resolveStoreModules(require('../store/my.js'), 'my.js')
  resolveStoreModules(require('../store/news.js'), 'news.js')
  resolveStoreModules(require('../store/pages.js'), 'pages.js')
  resolveStoreModules(require('../store/register.ts'), 'register.ts')
  resolveStoreModules(require('../store/report.js'), 'report.js')
  resolveStoreModules(require('../store/sp_menu_guide.js'), 'sp_menu_guide.js')
  resolveStoreModules(require('../store/suggest.js'), 'suggest.js')
  resolveStoreModules(require('../store/transaction.ts'), 'transaction.ts')
  resolveStoreModules(require('../store/translate.ts'), 'translate.ts')
  resolveStoreModules(require('../store/ui.js'), 'ui.js')
  resolveStoreModules(require('../store/web_vitals.js'), 'web_vitals.js')
  resolveStoreModules(require('../store/big_query/index.js'), 'big_query/index.js')
  resolveStoreModules(require('../store/auth/user.js'), 'auth/user.js')
  resolveStoreModules(require('../store/big_query/log_action.js'), 'big_query/log_action.js')
  resolveStoreModules(require('../store/big_query/log_impression.js'), 'big_query/log_impression.js')
  resolveStoreModules(require('../store/my/activities.js'), 'my/activities.js')
  resolveStoreModules(require('../store/my/affiliate.js'), 'my/affiliate.js')
  resolveStoreModules(require('../store/my/header.js'), 'my/header.js')
  resolveStoreModules(require('../store/my/notifications.js'), 'my/notifications.js')
  resolveStoreModules(require('../store/my/to_you_notifications.js'), 'my/to_you_notifications.js')
  resolveStoreModules(require('../store/my/user_template.js'), 'my/user_template.js')
  resolveStoreModules(require('../store/pages/users/index.js'), 'pages/users/index.js')
  resolveStoreModules(require('../store/pages/analytics/blogAnalytics.js'), 'pages/analytics/blogAnalytics.js')
  resolveStoreModules(require('../store/pages/analytics/blogs.js'), 'pages/analytics/blogs.js')
  resolveStoreModules(require('../store/pages/analytics/serviceAnalytics.js'), 'pages/analytics/serviceAnalytics.js')
  resolveStoreModules(require('../store/pages/analytics/services.js'), 'pages/analytics/services.js')
  resolveStoreModules(require('../store/pages/blogs/constants.js'), 'pages/blogs/constants.js')
  resolveStoreModules(require('../store/pages/blogs/master.js'), 'pages/blogs/master.js')
  resolveStoreModules(require('../store/pages/blogs/order.js'), 'pages/blogs/order.js')
  resolveStoreModules(require('../store/pages/blogs/search.js'), 'pages/blogs/search.js')
  resolveStoreModules(require('../store/pages/blogs/widget.js'), 'pages/blogs/widget.js')
  resolveStoreModules(require('../store/pages/business/accountInfo.js'), 'pages/business/accountInfo.js')
  resolveStoreModules(require('../store/pages/business/accountLink.js'), 'pages/business/accountLink.js')
  resolveStoreModules(require('../store/pages/business/businessSettings.js'), 'pages/business/businessSettings.js')
  resolveStoreModules(require('../store/pages/business/invitation.js'), 'pages/business/invitation.js')
  resolveStoreModules(require('../store/pages/business/invitationLink.js'), 'pages/business/invitationLink.js')
  resolveStoreModules(require('../store/pages/business/lp.js'), 'pages/business/lp.js')
  resolveStoreModules(require('../store/pages/business/migration.js'), 'pages/business/migration.js')
  resolveStoreModules(require('../store/pages/business/project.js'), 'pages/business/project.js')
  resolveStoreModules(require('../store/pages/business/questionnaire.js'), 'pages/business/questionnaire.js')
  resolveStoreModules(require('../store/pages/campaign/participations.js'), 'pages/campaign/participations.js')
  resolveStoreModules(require('../store/pages/enterprise/invitations.js'), 'pages/enterprise/invitations.js')
  resolveStoreModules(require('../store/pages/enterprise/members.js'), 'pages/enterprise/members.js')
  resolveStoreModules(require('../store/pages/enterprise/orders.js'), 'pages/enterprise/orders.js')
  resolveStoreModules(require('../store/pages/home/home.js'), 'pages/home/home.js')
  resolveStoreModules(require('../store/pages/message/message.js'), 'pages/message/message.js')
  resolveStoreModules(require('../store/pages/mypage/dashboardBuyer.js'), 'pages/mypage/dashboardBuyer.js')
  resolveStoreModules(require('../store/pages/mypage/dashboardProvider.js'), 'pages/mypage/dashboardProvider.js')
  resolveStoreModules(require('../store/pages/requests/add.js'), 'pages/requests/add.js')
  resolveStoreModules(require('../store/pages/requests/request.js'), 'pages/requests/request.js')
  resolveStoreModules(require('../store/pages/requests/search.js'), 'pages/requests/search.js')
  resolveStoreModules(require('../store/pages/search/facet.js'), 'pages/search/facet.js')
  resolveStoreModules(require('../store/pages/search/search.js'), 'pages/search/search.js')
  resolveStoreModules(require('../store/pages/search/seo.js'), 'pages/search/seo.js')
  resolveStoreModules(require('../store/pages/services/serviceDetail.js'), 'pages/services/serviceDetail.js')
  resolveStoreModules(require('../store/pages/services/serviceDetailRatings.js'), 'pages/services/serviceDetailRatings.js')
  resolveStoreModules(require('../store/pages/talkrooms/uiControls.js'), 'pages/talkrooms/uiControls.js')
  resolveStoreModules(require('../store/pages/talkrooms/userMemo.js'), 'pages/talkrooms/userMemo.js')
  resolveStoreModules(require('../store/pages/users/customize_requester.js'), 'pages/users/customize_requester.js')
  resolveStoreModules(require('../store/pages/users/past_estimates_request.js'), 'pages/users/past_estimates_request.js')
  resolveStoreModules(require('../store/pages/users/search.js'), 'pages/users/search.js')
  resolveStoreModules(require('../store/pages/users/user/index.js'), 'pages/users/user/index.js')
  resolveStoreModules(require('../store/pages/mypage/received_favorites/index.js'), 'pages/mypage/received_favorites/index.js')
  resolveStoreModules(require('../store/pages/mypage/blogs/index.js'), 'pages/mypage/blogs/index.js')
  resolveStoreModules(require('../store/pages/mypage/blogOrders/index.js'), 'pages/mypage/blogOrders/index.js')
  resolveStoreModules(require('../store/pages/blogs/user/index.js'), 'pages/blogs/user/index.js')
  resolveStoreModules(require('../store/pages/blogs/snapshot/index.js'), 'pages/blogs/snapshot/index.js')
  resolveStoreModules(require('../store/pages/blogs/user/blog.js'), 'pages/blogs/user/blog.js')
  resolveStoreModules(require('../store/pages/blogs/user/recommendBlog.js'), 'pages/blogs/user/recommendBlog.js')
  resolveStoreModules(require('../store/pages/blogs/user/services.js'), 'pages/blogs/user/services.js')
  resolveStoreModules(require('../store/pages/customize/requests/add.js'), 'pages/customize/requests/add.js')
  resolveStoreModules(require('../store/pages/customize/requests/attachmentFile.js'), 'pages/customize/requests/attachmentFile.js')
  resolveStoreModules(require('../store/pages/customize/requests/decline.js'), 'pages/customize/requests/decline.js')
  resolveStoreModules(require('../store/pages/customize/requests/detail.js'), 'pages/customize/requests/detail.js')
  resolveStoreModules(require('../store/pages/mypage/activities/notification.js'), 'pages/mypage/activities/notification.js')
  resolveStoreModules(require('../store/pages/mypage/activities/transaction.js'), 'pages/mypage/activities/transaction.js')
  resolveStoreModules(require('../store/pages/mypage/blogCharges/receipt.js'), 'pages/mypage/blogCharges/receipt.js')
  resolveStoreModules(require('../store/pages/mypage/blogOrders/detail.js'), 'pages/mypage/blogOrders/detail.js')
  resolveStoreModules(require('../store/pages/mypage/blogs/blog.js'), 'pages/mypage/blogs/blog.js')
  resolveStoreModules(require('../store/pages/mypage/blogs/services.js'), 'pages/mypage/blogs/services.js')
  resolveStoreModules(require('../store/pages/mypage/blogs/tmp.js'), 'pages/mypage/blogs/tmp.js')
  resolveStoreModules(require('../store/pages/mypage/user/careers.js'), 'pages/mypage/user/careers.js')
  resolveStoreModules(require('../store/pages/mypage/user/portfolios.js'), 'pages/mypage/user/portfolios.js')
  resolveStoreModules(require('../store/pages/ordermade/requests/add.js'), 'pages/ordermade/requests/add.js')
  resolveStoreModules(require('../store/pages/ordermade/requests/attachmentFile.js'), 'pages/ordermade/requests/attachmentFile.js')
  resolveStoreModules(require('../store/pages/ordermade/requests/decline.js'), 'pages/ordermade/requests/decline.js')
  resolveStoreModules(require('../store/pages/ordermade/requests/detail.js'), 'pages/ordermade/requests/detail.js')
  resolveStoreModules(require('../store/pages/users/my/user.js'), 'pages/users/my/user.js')
  resolveStoreModules(require('../store/pages/users/user/careers.js'), 'pages/users/user/careers.js')
  resolveStoreModules(require('../store/pages/users/user/home.js'), 'pages/users/user/home.js')
  resolveStoreModules(require('../store/pages/users/user/portfolios.js'), 'pages/users/user/portfolios.js')
  resolveStoreModules(require('../store/pages/users/user/rating.js'), 'pages/users/user/rating.js')
  resolveStoreModules(require('../store/pages/users/user/services.js'), 'pages/users/user/services.js')
  resolveStoreModules(require('../store/pages/mypage/favorites/blogs/index.js'), 'pages/mypage/favorites/blogs/index.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
