import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '~/store/_helpers/common-helper'

// state
export const state = () => ({
  servicesList: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchServiceList({ commit, rootState }) {
    const [{ GetServiceListRequest }, { ServiceService }] = await Promise.all([
      import('~/stub/apigateway/service/service_pb'),
      import('~/stub/apigateway/service/service_pb_service')
    ])
    const response = await grpcClient({
      method: ServiceService.GetServiceList,
      request: new GetServiceListRequest(),
      metadata: commonMetaData(rootState)
    })

    commit('RECEIVE_SERVICE_LIST', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_SERVICE_LIST(state, data) {
    Object.assign(state, {
      ...data
    })
  }
}
