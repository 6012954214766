import { Middleware } from '@nuxt/types'
import OutsourceServiceAccessor from '~/api/accessors/outsource/outsource-service-accessor'

const isAccessableOutsource: Middleware = async ({ store, route, error }) => {
  try {
    const param = route.params.outsourceUlid
    if (!param) return error({ statusCode: 404 })

    const outsourceServiceAccessor = new OutsourceServiceAccessor(store.state)
    await outsourceServiceAccessor.fetchOutsourceServiceDetail({ outsourceUlid: param })
  } catch (grpcCode) {
    error(grpcCode)
  }
}
export default isAccessableOutsource
