<template>
  <default>
    <div slot="footer"></div>
  </default>
</template>
<script>
import Default from '~/layouts/default'

export default {
  name: 'LayoutNoFooter',
  components: {
    Default
  }
}
</script>
