import Util from '~/assets/javascripts/util'

class BlogBlogWidgetUtil {
  constructor(blog, user, coconala) {
    this.blog = blog
    this.user = user
    this.coconala = coconala
  }

  getSummary() {
    if (this.blog.summary.length <= 0) {
      return ''
    }
    return `<div class="c-leftColumn_summary">
        ${this.blog.summary}
      </div>`
  }
  getPrice() {
    if (this.blog.price <= 0) {
      return ''
    }
    return `<div class="c-leftColumn_price">
        ${Util.numberWithDelimiter(this.blog.price)}円
      </div>`
  }
  getResizableCoverImagePath() {
    if (!this.blog.resizableCoverImagePath) {
      return ''
    }
    return `<div class="c-rightColumn">
        <img
          class="c-rightColumn_coverImage"
          src="${this.coconala.blogCoverImageURL(this.blog.resizableCoverImagePath)}"
          alt="カバー画像"
          loading="lazy"
          decoding="async"
        />
      </div>`
  }
  genBlogBlogWidget() {
    return `
      <div class="c-blogWidgetItem" contenteditable="false">
        <div class="c-blogWidgetItem_inner">
          <div class="c-leftColumn">
            <div class="c-heading">
              <h3 class="c-heading_title">
                ${this.blog.title}
              </h3>
            </div>
            ${this.getSummary()}
            <div class="c-leftColumn_favoriteWrap">
              ${this.getPrice()}
            </div>
          </div>
          ${this.getResizableCoverImagePath()}
        </div>
        <div class="c-blogWidgetItem_footer">
          <div class="c-postInfo">
            <img
              class="c-userIcon"
              src="${this.coconala.userIconURLV2(this.user.userIconPath, 40, 40, false)}"
              alt="${this.user.name}"
              loading="lazy"
              decoding="async"
              style="width: 32px; height: 32px;"
            />
            <div class="c-postInfo_rightColumn">
              ${this.user.name}
              <div class="c-postInfo_openedAt">
                ${Util.dayjs.unix(this.blog.openedAt).format('YYYY/MM/DD')}
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }
}

export default (context, inject) => {
  // Injectされた$coconalaはBlogServiceUtilクラス内で使用できないため引数で渡す
  const coconala = context.app.$coconala
  const blogBlogWidgetUtil = (blog, user) => {
    return new BlogBlogWidgetUtil(blog, user, coconala)
  }
  inject('blogBlogWidgetUtil', blogBlogWidgetUtil)
}
