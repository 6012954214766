// package: common
// file: apigateway/common/cake_notification.proto

import * as apigateway_common_cake_notification_pb from "../../apigateway/common/cake_notification_pb";
export class CakeNotificationService {
  static serviceName = "common.CakeNotificationService";
}
export namespace CakeNotificationService {
  export class GetCakeNotification {
    static readonly methodName = "GetCakeNotification";
    static readonly service = CakeNotificationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_common_cake_notification_pb.GetCakeNotificationRequest;
    static readonly responseType = apigateway_common_cake_notification_pb.GetCakeNotificationReply;
  }
  export class AddCakeNotification {
    static readonly methodName = "AddCakeNotification";
    static readonly service = CakeNotificationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_common_cake_notification_pb.AddCakeNotificationRequest;
    static readonly responseType = apigateway_common_cake_notification_pb.AddCakeNotificationReply;
  }
}
