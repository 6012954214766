import grpcClient from '~/grpc/grpc-client'
import {
  UpdateIsDisplayAgeRequest,
  UpdateOccupationRequest,
  GetHasOldRecordsRequest,
  DeleteOldRecordsRequest
} from '~/stub/apigateway/profile/profile_pb'
import { ProfileService } from '~/stub/apigateway/profile/profile_pb_service'
import GrpcAccessorBase from '../grpc-accessor-base'
import { ProfileReply } from '~/stub/apigateway/profile/profile_pb'
import { Stripped } from '~/grpc/grpc-util'

/** ユーザー情報アクセサ */
export class ProfileAccessor extends GrpcAccessorBase {
  updateOccupation(occupation: string): Promise<ProfileReply.AsObject> {
    const method = ProfileService.UpdateOccupation
    const metadata = this.getMetadata()

    const request = new UpdateOccupationRequest()
    request.setOccupation(occupation)

    return grpcClient({ method, request, metadata })
  }

  updateIsDisplayAge(isDisplay: boolean): Promise<ProfileReply.AsObject> {
    const method = ProfileService.UpdateIsDisplayAge
    const metadata = this.getMetadata()

    const request = new UpdateIsDisplayAgeRequest()
    request.setIsDisplayAge(isDisplay)

    return grpcClient({ method, request, metadata })
  }

  getHasOldRecordsRequest() {
    return grpcClient({
      method: ProfileService.GetHasOldRecords,
      request: new GetHasOldRecordsRequest(),
      metadata: this.getMetadata()
    })
  }

  deleteOldRecordsRequest(params: Stripped<DeleteOldRecordsRequest.AsObject>) {
    const request = new DeleteOldRecordsRequest()
    request.setType(params.type)

    return grpcClient({
      method: ProfileService.DeleteOldRecords,
      request,
      metadata: this.getMetadata()
    })
  }
}
