// source: profileservice/introduction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.profileservice.GetIntroductionRequest', null, global);
goog.exportSymbol('proto.profileservice.Introduction', null, global);
goog.exportSymbol('proto.profileservice.IntroductionReply', null, global);
goog.exportSymbol('proto.profileservice.IntroductionUserBlackList', null, global);
goog.exportSymbol('proto.profileservice.UpdateIntroductionRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.Introduction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.Introduction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.Introduction.displayName = 'proto.profileservice.Introduction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.GetIntroductionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.GetIntroductionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.GetIntroductionRequest.displayName = 'proto.profileservice.GetIntroductionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.UpdateIntroductionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.UpdateIntroductionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.UpdateIntroductionRequest.displayName = 'proto.profileservice.UpdateIntroductionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.IntroductionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.IntroductionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.IntroductionReply.displayName = 'proto.profileservice.IntroductionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.IntroductionUserBlackList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profileservice.IntroductionUserBlackList.repeatedFields_, null);
};
goog.inherits(proto.profileservice.IntroductionUserBlackList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.IntroductionUserBlackList.displayName = 'proto.profileservice.IntroductionUserBlackList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.Introduction.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.Introduction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.Introduction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.Introduction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    catchphrase: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.Introduction}
 */
proto.profileservice.Introduction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.Introduction;
  return proto.profileservice.Introduction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.Introduction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.Introduction}
 */
proto.profileservice.Introduction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProfileId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCatchphrase(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.Introduction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.Introduction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.Introduction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.Introduction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfileId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCatchphrase();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.Introduction.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Introduction} returns this
 */
proto.profileservice.Introduction.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 profile_id = 2;
 * @return {number}
 */
proto.profileservice.Introduction.prototype.getProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Introduction} returns this
 */
proto.profileservice.Introduction.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string catchphrase = 3;
 * @return {string}
 */
proto.profileservice.Introduction.prototype.getCatchphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.Introduction} returns this
 */
proto.profileservice.Introduction.prototype.setCatchphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.profileservice.Introduction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Introduction} returns this
 */
proto.profileservice.Introduction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.profileservice.Introduction.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Introduction} returns this
 */
proto.profileservice.Introduction.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.GetIntroductionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.GetIntroductionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.GetIntroductionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetIntroductionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.GetIntroductionRequest}
 */
proto.profileservice.GetIntroductionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.GetIntroductionRequest;
  return proto.profileservice.GetIntroductionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.GetIntroductionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.GetIntroductionRequest}
 */
proto.profileservice.GetIntroductionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.GetIntroductionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.GetIntroductionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.GetIntroductionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetIntroductionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.profileservice.GetIntroductionRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetIntroductionRequest} returns this
 */
proto.profileservice.GetIntroductionRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.UpdateIntroductionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.UpdateIntroductionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.UpdateIntroductionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdateIntroductionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    catchphrase: jspb.Message.getFieldWithDefault(msg, 2, ""),
    introductionUserBlackList: (f = msg.getIntroductionUserBlackList()) && proto.profileservice.IntroductionUserBlackList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.UpdateIntroductionRequest}
 */
proto.profileservice.UpdateIntroductionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.UpdateIntroductionRequest;
  return proto.profileservice.UpdateIntroductionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.UpdateIntroductionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.UpdateIntroductionRequest}
 */
proto.profileservice.UpdateIntroductionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCatchphrase(value);
      break;
    case 3:
      var value = new proto.profileservice.IntroductionUserBlackList;
      reader.readMessage(value,proto.profileservice.IntroductionUserBlackList.deserializeBinaryFromReader);
      msg.setIntroductionUserBlackList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.UpdateIntroductionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.UpdateIntroductionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.UpdateIntroductionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdateIntroductionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCatchphrase();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntroductionUserBlackList();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.profileservice.IntroductionUserBlackList.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.profileservice.UpdateIntroductionRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdateIntroductionRequest} returns this
 */
proto.profileservice.UpdateIntroductionRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string catchphrase = 2;
 * @return {string}
 */
proto.profileservice.UpdateIntroductionRequest.prototype.getCatchphrase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdateIntroductionRequest} returns this
 */
proto.profileservice.UpdateIntroductionRequest.prototype.setCatchphrase = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional IntroductionUserBlackList introduction_user_black_list = 3;
 * @return {?proto.profileservice.IntroductionUserBlackList}
 */
proto.profileservice.UpdateIntroductionRequest.prototype.getIntroductionUserBlackList = function() {
  return /** @type{?proto.profileservice.IntroductionUserBlackList} */ (
    jspb.Message.getWrapperField(this, proto.profileservice.IntroductionUserBlackList, 3));
};


/**
 * @param {?proto.profileservice.IntroductionUserBlackList|undefined} value
 * @return {!proto.profileservice.UpdateIntroductionRequest} returns this
*/
proto.profileservice.UpdateIntroductionRequest.prototype.setIntroductionUserBlackList = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profileservice.UpdateIntroductionRequest} returns this
 */
proto.profileservice.UpdateIntroductionRequest.prototype.clearIntroductionUserBlackList = function() {
  return this.setIntroductionUserBlackList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profileservice.UpdateIntroductionRequest.prototype.hasIntroductionUserBlackList = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.IntroductionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.IntroductionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.IntroductionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.IntroductionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    introduction: (f = msg.getIntroduction()) && proto.profileservice.Introduction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.IntroductionReply}
 */
proto.profileservice.IntroductionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.IntroductionReply;
  return proto.profileservice.IntroductionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.IntroductionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.IntroductionReply}
 */
proto.profileservice.IntroductionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.profileservice.Introduction;
      reader.readMessage(value,proto.profileservice.Introduction.deserializeBinaryFromReader);
      msg.setIntroduction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.IntroductionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.IntroductionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.IntroductionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.IntroductionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntroduction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.profileservice.Introduction.serializeBinaryToWriter
    );
  }
};


/**
 * optional Introduction introduction = 1;
 * @return {?proto.profileservice.Introduction}
 */
proto.profileservice.IntroductionReply.prototype.getIntroduction = function() {
  return /** @type{?proto.profileservice.Introduction} */ (
    jspb.Message.getWrapperField(this, proto.profileservice.Introduction, 1));
};


/**
 * @param {?proto.profileservice.Introduction|undefined} value
 * @return {!proto.profileservice.IntroductionReply} returns this
*/
proto.profileservice.IntroductionReply.prototype.setIntroduction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profileservice.IntroductionReply} returns this
 */
proto.profileservice.IntroductionReply.prototype.clearIntroduction = function() {
  return this.setIntroduction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profileservice.IntroductionReply.prototype.hasIntroduction = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profileservice.IntroductionUserBlackList.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.IntroductionUserBlackList.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.IntroductionUserBlackList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.IntroductionUserBlackList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.IntroductionUserBlackList.toObject = function(includeInstance, msg) {
  var f, obj = {
    isOrderProhibited: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    isDmProhibited: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isReceiveDmProhibited: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isSellProhibited: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isTransferProhibited: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isProfileEditProhibited: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isRequestProhibited: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    subaccountUserIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.IntroductionUserBlackList}
 */
proto.profileservice.IntroductionUserBlackList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.IntroductionUserBlackList;
  return proto.profileservice.IntroductionUserBlackList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.IntroductionUserBlackList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.IntroductionUserBlackList}
 */
proto.profileservice.IntroductionUserBlackList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOrderProhibited(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDmProhibited(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReceiveDmProhibited(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSellProhibited(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTransferProhibited(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsProfileEditProhibited(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRequestProhibited(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setSubaccountUserIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.IntroductionUserBlackList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.IntroductionUserBlackList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.IntroductionUserBlackList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.IntroductionUserBlackList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsOrderProhibited();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIsDmProhibited();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsReceiveDmProhibited();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsSellProhibited();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsTransferProhibited();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsProfileEditProhibited();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsRequestProhibited();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSubaccountUserIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
};


/**
 * optional bool is_order_prohibited = 1;
 * @return {boolean}
 */
proto.profileservice.IntroductionUserBlackList.prototype.getIsOrderProhibited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.setIsOrderProhibited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool is_dm_prohibited = 2;
 * @return {boolean}
 */
proto.profileservice.IntroductionUserBlackList.prototype.getIsDmProhibited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.setIsDmProhibited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_receive_dm_prohibited = 3;
 * @return {boolean}
 */
proto.profileservice.IntroductionUserBlackList.prototype.getIsReceiveDmProhibited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.setIsReceiveDmProhibited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_sell_prohibited = 4;
 * @return {boolean}
 */
proto.profileservice.IntroductionUserBlackList.prototype.getIsSellProhibited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.setIsSellProhibited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_transfer_prohibited = 5;
 * @return {boolean}
 */
proto.profileservice.IntroductionUserBlackList.prototype.getIsTransferProhibited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.setIsTransferProhibited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_profile_edit_prohibited = 6;
 * @return {boolean}
 */
proto.profileservice.IntroductionUserBlackList.prototype.getIsProfileEditProhibited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.setIsProfileEditProhibited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_request_prohibited = 7;
 * @return {boolean}
 */
proto.profileservice.IntroductionUserBlackList.prototype.getIsRequestProhibited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.setIsRequestProhibited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated int32 subaccount_user_ids = 8;
 * @return {!Array<number>}
 */
proto.profileservice.IntroductionUserBlackList.prototype.getSubaccountUserIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.setSubaccountUserIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.addSubaccountUserIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.IntroductionUserBlackList} returns this
 */
proto.profileservice.IntroductionUserBlackList.prototype.clearSubaccountUserIdsList = function() {
  return this.setSubaccountUserIdsList([]);
};


goog.object.extend(exports, proto.profileservice);
