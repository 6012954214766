import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { LANGUAGES } from '~/constants/language'
import { commonMetaData } from '~/store/_helpers/common-helper'

import { GetCustomizeRequesterRequest } from '~/stub/apigateway/user/customize_requester_pb'
import { CustomizeRequesterService } from '~/stub/apigateway/user/customize_requester_pb_service'

// initial state
export const state = () => ({
  customizeRequester: {
    id: 0,
    icon: '',
    name: '',
    rating: '',
    orderCount: 0,
    langCode: LANGUAGES.JA.code
  }
})

// getters
export const getters = {
  requesterLanguageCode(state) {
    return state.customizeRequester.langCode || LANGUAGES.JA.code
  }
}

// actions
export const actions = {
  async getCustomizeRequester({ commit, rootState }, data) {
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetCustomizeRequesterRequest()
    request.setRequestId(params.requestId)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(CustomizeRequesterService.GetCustomizeRequester, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    commit('RECEIVE_CUSTOMIZE_REQUESTER', { customizeRequester: reply })
  }
}

// mutations
export const mutations = {
  RECEIVE_CUSTOMIZE_REQUESTER(state, data) {
    Object.assign(state, data)
  }
}
