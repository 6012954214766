export default async ({ store, app }) => {
  const SENTRY_TAG_NAME = 'Error_HeaderNotification'

  // 未ログイン時は通知を取得しない
  if (!store.getters['auth/isLoggedIn']) {
    return
  }

  try {
    await store.dispatch('my/activities/fetchUserHeader')
  } catch (err) {
    if (err.grpcCode === 16) {
      await store.dispatch('auth/clearAllModules')
      app.$cookies.remove(process.env.config['session-key'], {
        domain: '.coconala.com',
        path: '/',
        httpOnly: true,
        secure: true
      })
      return
    }
    // エラーの内容をsentryに送信する
    app.$sentry.withScope(scope => {
      scope.setTag(SENTRY_TAG_NAME, 'NotificationCount')
      scope.setExtras({ ...err })
      app.$sentry.captureException(new Error(err.message))
    })
    return
  }
}
