// 画像
import videoService from '@/assets/images/common/icon-video-service.svg'
import phoneService from '@/assets/images/common/icon-phone-service.svg'

import Util from '~/assets/javascripts/util'

const SERVICE_CLASS_PHONE = 1
const VIDEO_TYPE = 2

class BlogServiceUtil {
  constructor(service, providerStatus, serviceClass, coconala) {
    this.providerStatus = providerStatus
    this.serviceClass = serviceClass
    this.coconala = coconala
    this.service = service
  }

  serviceName() {
    if (this.service.overview) {
      return this.service.overview
    }
    return this.service.name
  }

  getProIconElement() {
    if (!this.service.proServiceFlag) {
      return ''
    }
    const proIconOutLine = this.coconala.fileURL(
      '/staticfiles/images/certification/pro/icon-outline.svg'
    )
    // prettier-ignore
    return `
      <div class="c-serviceWidgetItemRow_pro">
        <img
          src="${proIconOutLine}"
          alt="PRO"
          loading="lazy"
          style="width: 32px;"
          v-if="isOutlined"
        />
      </div>
    `
  }

  getLabelElement() {
    if (this.service.fullCorrespondenceFlag) {
      return `
        <div class="c-serviceWidgetItemRowLabel c-serviceWidgetItemRowLabel-limit">
          <div class="c-serviceWidgetItemRowLabel_label">満枠対応中</div>
        </div>
      `
    } else if (this.service.stopFg) {
      return `
        <div class="c-serviceWidgetItemRowLabel c-serviceWidgetItemRowLabel-stop">
          <div class="c-serviceWidgetItemRowLabel_label">受付休止中</div>
        </div>
      `
    }
    return ''
  }

  getFlagElement() {
    let flagElement = ''
    if (this.service.videoType === VIDEO_TYPE) {
      flagElement += `
        <img
          src="${videoService}"
          alt=""
          loading="lazy"
        />
      `
    }
    if (this.service.serviceClass === SERVICE_CLASS_PHONE) {
      flagElement += `
        <img
          src="${phoneService}"
          alt=""
          loading="lazy"
        />
      `
    }
    return flagElement
  }

  getTitleElement() {
    if (this.service.overview) {
      return `
        <p class="c-serviceWidgetItemContent_overview">
          ${this.serviceName()}
        </p>
        <h3 class="c-serviceWidgetItemContent_catchphrase">
          ${this.service.catchphrase}
        </h3>
      `
    } else {
      return `
        <h3 class="c-serviceWidgetItemContent_name">
          ${this.serviceName()}
        </h3>
      `
    }
  }

  getRatingElement() {
    if (this.service.rating.indicator) {
      return `
        <div class="c-serviceWidgetItemContentPriceRating">
          <i class="c-serviceWidgetItemContentPriceRating_star coconala-icon -star"></i>
          <div class="c-serviceWidgetItemContentPriceRating_score">
            ${this.service.rating.indicator.toFixed(1)}
          </div>
        </div>
      `
    } else {
      return `
        <div>
          <i class="c-serviceWidgetItemContentPriceRating_star coconala-icon -star"></i> -
        </div>
      `
    }
  }

  getPriceElement() {
    // prettier-ignore
    if (this.service.serviceClass === SERVICE_CLASS_PHONE) {
      return `<strong>${Util.numberWithDelimiter(this.service.priceWot)}</strong>円<div class="c-serviceWidgetItemContentPrice_unit">/分</div>`
    } else if (this.service.unitTime) {
      return `<strong>${Util.numberWithDelimiter(this.service.priceWot)}</strong>円<div class="c-serviceWidgetItemContentPrice_unit">/${Util.numberWithDelimiter(this.service.unitTime)}分</div>`
    } else {
      return `<strong>${Util.numberWithDelimiter(this.service.priceWot)}</strong>円`
    }
  }

  genBlogService() {
    // prettier-ignore
    return `
      <div
        class="c-serviceWidgetItem c-serviceWidgetItemBlock"
        contenteditable="false"
      >
        <div class="c-serviceWidgetItem_inner c-serviceWidgetItemRow c-serviceWidgetItemBlock_inner">
          ${this.getProIconElement()}
          <div class="c-serviceWidgetItemRow_image">
            <img
              src="${this.coconala.serviceImageURL(this.service.serviceImage)}"
              alt=""
              class="c-serviceWidgetItemImage"
              loading="lazy"
            >

            <div class="c-serviceWidgetItemRow_labels">
              ${this.getLabelElement()}
            </div>
          </div>

          <div class="c-serviceWidgetItemRow_flag">
            ${this.getFlagElement()}
          </div>

          <div class="c-serviceWidgetItemRow_content c-serviceWidgetItemContent">
            <div class="c-serviceWidgetItemContent_header c-serviceWidgetItemContentHeader">
              <div class="c-serviceWidgetItemContent_title">
                ${this.getTitleElement()}
              </div>
            </div>

            <div class="c-serviceWidgetItemContent_footer c-serviceWidgetItemContentFooter">
              <div class="c-serviceWidgetItemContent_infoUser c-serviceWidgetItemContentInfoUser">
                <div class="c-serviceWidgetItemContentInfoUser_icon">
                  <img
                    class="c-userIcon"
                    src="${this.coconala.userIconURLV2(this.service.provider.profileIconPath, 40, 40, false)}"
                    alt="user"
                    loading="lazy"
                    style="width: 20px; height: 20px;"
                  />
                </div>
                <div class="c-serviceWidgetItemContentInfoUser_name">
                  <div>${this.service.provider.name}</div>
                </div>
                <div class="c-serviceWidgetItemContentInfoUser_level">
                  ${this.service.provider.level > 0 ? `
                  <img
                    src="${this.coconala.levelIconURL(this.service.provider.level)}"
                    alt=""
                  >` : ''
                  }
                </div>
              </div>

              <div class="c-serviceWidgetItemContent_price c-serviceWidgetItemContentPrice">
                <div class="c-serviceWidgetItemContentPrice_rating">
                  ${this.getRatingElement()}
                  ${this.service.performanceCount > 0 ? `<div class="c-serviceWidgetItemContentPriceRating_count">(${this.service.performanceCount})</div>` : ''}
                </div>

                <div class="c-serviceWidgetItemContentPrice_price">
                  <div>
                    ${this.getPriceElement()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }
}

export default (context, inject) => {
  // Injectされた$coconalaはBlogServiceUtilクラス内で使用できないため引数で渡す
  const coconala = context.app.$coconala
  const blogServiceUtil = (service, providerStatus, serviceClass) => {
    return new BlogServiceUtil(service, providerStatus, serviceClass, coconala)
  }
  inject('blogServiceUtil', blogServiceUtil)
}
