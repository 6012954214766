import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import {
  GetMembersRequest,
  EditMemberRequest,
  DeleteMemberRequest
} from '~/stub/apigateway/enterprise/member_pb'
import { MemberService } from '~/stub/apigateway/enterprise/member_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
const initialState = () => ({
  membersList: []
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchMembers({ commit, rootState }) {
    const response = await new Promise((resolve, reject) => {
      const request = new GetMembersRequest()
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(MemberService.GetMembers, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })

    commit('RECEIVE_MEMBERS', response)
  },
  async editMember({ dispatch, rootState }, { id, department, role, userId }) {
    await new Promise((resolve, reject) => {
      const request = new EditMemberRequest()
      request.setId(id)
      request.setDepartment(department)
      request.setRole(role)
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(MemberService.EditMember, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    // 自分の情報を編集した場合、ログインユーザ情報を再取得し、権限によるUI表示制御を行う
    if (userId === rootState.auth.user.id) {
      await dispatch('auth/user/fetchAll', null, { root: true })
    }

    await dispatch('fetchMembers')
  },
  async deleteMember({ dispatch, rootState }, { id, userId }) {
    await new Promise((resolve, reject) => {
      const request = new DeleteMemberRequest()
      request.setId(id)
      request.setIsFromSettings(false)
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(MemberService.DeleteMember, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    // 自身を削除した場合
    if (userId === rootState.auth.user.id) {
      return
    }

    await dispatch('fetchMembers')
  },
  reset({ commit }) {
    commit('RESET')
  }
}

// mutations
export const mutations = {
  RECEIVE_MEMBERS(state, data) {
    Object.assign(state, {
      ...data
    })
  },
  RESET(state) {
    Object.assign(state, initialState())
  }
}
