/**
 * このStoreはブログエディタ用のStoreです。
 * 戻る進む処理のために利用されます。
 * このStoreの状態の履歴をエディタで保存していて、
 * 戻る、進むの度にStoreの内容をDOMに反映します。
 * contents: ブログ本文
 * focusBlockRow: 保存されている時に選択されているブロック行
 * focusOffset: 現在のエレメントの選択されている文字箇所
 * type: Caretは通常、Rangeは選択中
 */
const initialState = () => ({
  tmpBlog: {
    contents: '',
    focusBlockRow: 0,
    focusOffset: 0,
    type: 'Caret'
  }
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  updateTmpBlog({ state, commit }, tmpBlog) {
    commit('UPDATE_TMP_BLOG', tmpBlog)
  }
}

// mutations
export const mutations = {
  UPDATE_TMP_BLOG(state, tmpBlog) {
    state.tmpBlog = tmpBlog
  }
}
