/** @file クッキー定数 */

/** 公開募集「募集中の仕事のみ」チェックボックス状態 */
export const REQUESTS_RECRUITING = 'search_requests_recruiting'

/** クエリに応じてWebからAppインストールするユーザーの計測用 */
export const MEASURING_APP_INSTALL = 'measuring-app-install-users-from-ads'

/** onelink URLのID保存用 */
export const ONELINK_URL_ID = 'onelink_url_id'

/** ヘッダータイプ */
export const HEADER_TYPE_COOKIE = {
  NAME: 'header_type',
  VALUE_PROVIDER: 'provider',
  VALUE_BUYER: 'buyer',
  OPTS: {
    path: '/',
    maxAge: 60 * 60 * 24 * 365 * 10
  }
}
