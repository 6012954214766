import GrpcAccessorBase from '~/api/accessors/grpc-accessor-base'
import grpcClient from '~/grpc/grpc-client'
import { Stripped, stripValue, toInt32Value, toBoolValue } from '~/grpc/grpc-util'

import {
  ListInterestCategoriesRequest,
  ListInterestCategoriesResponse,
  ListUserInterestCategoriesRequest,
  ListUserInterestCategoriesResponse,
  SaveUserInterestCategoryRequest
} from '~/stub/gw/interest_category/interest_category_pb'
import { InterestCategoryService } from '~/stub/gw/interest_category/interest_category_pb_service'
import { UserInterestCategoryDomain } from '~/stub/domain/user_interest_category_pb'
import { InterestCategoryDomain } from '~/stub/domain/interest_category_pb'
import { MAX_INTEREST_CATEGORY_SELECTABLE_COUNT } from '~/components/logics/interest_category/interest-categories-hooks.ts'
import { CampaignService } from '~/stub/apigateway/campaign/campaign_pb_service'
import { KickRegistrateUserInterestCategoryCampaignRequest } from '~/stub/apigateway/campaign/campaign_pb'

export const generateInterestCategories = () =>
  stripValue(new ListInterestCategoriesResponse().toObject())
export const generateUserInterestCategories = () =>
  stripValue(new ListUserInterestCategoriesResponse().toObject())

// 初期値がundefinedだとリアクティブ化するときにプロパティごと削除されてしまうので初期値を変更
export const generateRequestUserInterestCategory = (): UserInterestCategoryDomainType => ({
  masterCategoryId: 0,
  subMasterCategoryId: 0,
  // ラジオボタンなので、違う変数で管理。リアクティブにする必要がないのでundefinedに
  isTopPriority: undefined,
  serviceKind: 0
})
export type UserInterestCategoriesResponseType =
  Stripped<ListUserInterestCategoriesResponse.AsObject>
export type UserInterestCategoryDomainType = Stripped<UserInterestCategoryDomain.AsObject>
export type InterestCategoriesResponseType = Stripped<ListInterestCategoriesResponse.AsObject>
export type InterestCategoriesType = InterestCategoriesResponseType['interestCategoryDomainsList']
export type InterestCategoryDomainType = Stripped<InterestCategoryDomain.AsObject>

const formatUserInterestCategoriesParams = (
  interestCategories: UserInterestCategoryDomainType[]
): UserInterestCategoryDomain[] => {
  // interestCategoriesがMAX_INTEREST_CATEGORY_SELECTABLE_COUNTに満たない場合は初期値で埋める
  const formattedInterestCategories = [...Array(MAX_INTEREST_CATEGORY_SELECTABLE_COUNT)].map(
    (_, i) => {
      return interestCategories[i] ?? generateRequestUserInterestCategory()
    }
  )
  return formattedInterestCategories.map(category => {
    const requestForProperty = new UserInterestCategoryDomain()
    requestForProperty.setMasterCategoryId(toInt32Value(category.masterCategoryId))
    if (category.subMasterCategoryId) {
      requestForProperty.setSubMasterCategoryId(toInt32Value(category.subMasterCategoryId))
    }
    requestForProperty.setIsTopPriority(toBoolValue(category.isTopPriority))
    requestForProperty.setServiceKind(category.serviceKind)
    return requestForProperty
  })
}

class InterestCategoryAccessor extends GrpcAccessorBase {
  /**
   * 興味のあるカテゴリを取得
   */
  getInterestCategories(
    fromEf?: boolean,
    isMzhc?: boolean
  ): Promise<Stripped<ListInterestCategoriesResponse.AsObject>> {
    const metadata = this.getMetadata()
    const request = new ListInterestCategoriesRequest()
    if (fromEf) request.setFromEf(fromEf)
    if (isMzhc) request.setIsMzhc(isMzhc)
    return grpcClient({
      method: InterestCategoryService.ListInterestCategories,
      request,
      metadata,
      strip: true
    })
  }

  /**
   * 現在設定中の興味のあるカテゴリを取得
   */
  getUserInterestCategories(): Promise<Stripped<ListUserInterestCategoriesResponse.AsObject>> {
    const metadata = this.getMetadata()
    const request = new ListUserInterestCategoriesRequest()
    return grpcClient({
      method: InterestCategoryService.ListUserInterestCategories,
      request,
      metadata,
      strip: true
    })
  }

  setUserInterestCategories(
    interestCategories: UserInterestCategoryDomainType[],
    fromEf?: boolean
  ) {
    const metadata = this.getMetadata()
    const request = new SaveUserInterestCategoryRequest()
    request.setUserInterestCategoryDomainsList(
      formatUserInterestCategoriesParams(interestCategories)
    )
    if (fromEf) request.setFromEf(true)
    return grpcClient({
      method: InterestCategoryService.SaveUserInterestCategory,
      request,
      metadata,
      strip: true
    })
  }

  kickUserInterestCategoryCampaign() {
    const metadata = this.getMetadata()
    const request = new KickRegistrateUserInterestCategoryCampaignRequest()
    request.setEventName('registrate_user_interest_category')

    return grpcClient({
      method: CampaignService.KickRegistrateUserInterestCategoryCampaign,
      request,
      metadata
    })
  }
}

export default InterestCategoryAccessor
