let deepFreeze = require('deep-freeze')

const PAY_METHOD_CARD = 1
const PAY_METHOD_CARRIER = 2

export const getters = {
  kindText: state => key => {
    switch (key) {
      case state.KIND.NORMAL:
        return '記事'
      case state.KIND.ANNOUNCE:
        return '告知'
      case state.KIND.CONTENTS:
        return 'コンテンツ'
      default:
        return '記事'
    }
  },
  carrierTypeText: state => key => {
    switch (key) {
      case state.CARRIER_TYPE.DOCOMO.ID:
        return state.CARRIER_TYPE.DOCOMO.NAME
      case state.CARRIER_TYPE.AU.ID:
        return state.CARRIER_TYPE.AU.NAME
      case state.CARRIER_TYPE.SOFTBANK.ID:
        return state.CARRIER_TYPE.SOFTBANK.NAME
      default:
        return ''
    }
  }
}

export const state = () =>
  deepFreeze({
    STATUS: {
      DRAFT: 1,
      OPEN: 2
    },
    RESTRICTION: {
      NOTHING: 1,
      OPENING: 2
    },
    VALIDATION: {
      MAX_TEXT_LENGTH: 255,
      MAX_BODY_LENGTH: 300000,
      MIN_PAID_AREA_TEXT: 1000
    },
    SETTING_VIEW_TYPE: {
      SETTING: 0,
      PAID_REQUIRED: 1
    },
    KIND: {
      NORMAL: 1,
      ANNOUNCE: 2,
      CONTENTS: 3
    },
    NO_CATEGORY_ID: 999,
    PAY_METHOD_CARD,
    PAY_METHOD_CARRIER,
    MAX_WIDGET_COUNT: 100,
    CARRIER_TYPE: {
      DOCOMO: {
        ID: 2,
        NAME: 'd払い'
      },
      AU: {
        ID: 3,
        NAME: 'auかんたん決済 (au / UQ mobile)'
      },
      SOFTBANK: {
        ID: 4,
        NAME: 'ソフトバンクまとめて支払い'
      }
    },
    PAYMENT_FEE_CARRIER: 100,
    PURCHASE_FEE_RATE: 0.055,
    TAX_RATE: 0.1,
    ORDER_STATUS: {
      ENABLE: 1,
      CANCEL: 2,
      PROCESSING: 3
    },
    CALL_BACK: {
      MOBILE_CARRIERS: {
        STATUS: {
          SUCCESS: 1,
          FAIL: 2
        }
      }
    }
  })
