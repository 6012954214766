import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
const initialState = () => ({
  blog: {},
  previousBlog: {},
  nextBlog: {}
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  resetBlog({ commit }) {
    commit('RESET')
  },
  async fetchBlog({ commit, rootState }, id) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new GetBlogRequest()
      request.setId(id)
      request.setIsMine(true)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.GetBlog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOG', response)
  },
  async createBlog({ commit, rootState }, data) {
    const { Toc } = await import('~/stub/profileservice/blog_pb')
    const response = await new Promise(async (resolve, reject) => {
      const tocList = data.toc.map(t => {
        const toc = new Toc()
        toc.setName(t.name)
        toc.setAnchor(t.anchor)
        toc.setIsPaid(t.isPaid)
        return toc
      })

      const [{ CreateBlogRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new CreateBlogRequest()
      request.setUserId(rootState.auth.user.id)
      request.setBlogCategoryId(data.blogCategoryId)
      request.setTitle(data.title)
      request.setBody(data.body)
      request.setCoverImageUrl(data.coverImageUrl)
      request.setStatus(data.status)
      request.setKind(data.kind)
      request.setTocList(tocList)
      request.setTocAvailable(data.tocAvailable)
      request.setBlogTagNamesList(data.blogTagNamesList)
      request.setPaidFg(data.paidFg)
      request.setPrice(data.price)
      request.setPaidBody(data.paidBody)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.CreateBlog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })
    commit('RECEIVE_BLOG', response)
  },
  async updateBlog({ commit, rootState }, data) {
    const { Toc } = await import('~/stub/profileservice/blog_pb')
    const response = await new Promise(async (resolve, reject) => {
      const tocList = data.toc.map(t => {
        const toc = new Toc()
        toc.setName(t.name)
        toc.setAnchor(t.anchor)
        toc.setIsPaid(t.isPaid)
        return toc
      })

      const [{ UpdateBlogRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new UpdateBlogRequest()
      request.setId(data.id)
      request.setUserId(rootState.auth.user.id)
      request.setBlogCategoryId(data.blogCategoryId)
      request.setTitle(data.title)
      request.setBody(data.body)
      request.setCoverImageUrl(data.coverImageUrl)
      request.setStatus(data.status)
      request.setTocList(tocList)
      request.setTocAvailable(data.tocAvailable)
      request.setBlogTagNamesList(data.blogTagNamesList)
      request.setPaidFg(data.paidFg)
      request.setPrice(data.price)
      request.setPaidBody(data.paidBody)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.UpdateBlog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })
    commit('RECEIVE_BLOG', response)
  },
  async deleteBlog({ rootState }, blogId) {
    await new Promise(async (resolve, reject) => {
      const [{ DeleteBlogRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new DeleteBlogRequest()
      request.setId(blogId)
      request.setUserId(rootState.auth.user.id)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.DeleteBlog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })
  },
  async checkInspectionKeyword({ rootState }, target) {
    return new Promise(async (resolve, reject) => {
      const [{ ValidateContentsRequest }, { InspectionKeywordService }] = await Promise.all([
        import('~/stub/apigateway/common/inspection_keyword_pb'),
        import('~/stub/apigateway/common/inspection_keyword_pb_service')
      ])
      const request = new ValidateContentsRequest()
      request.setUserId(rootState.auth.user.id)
      request.setTarget(target)
      request.setInspectionTypesList(['forbiddance', 'input_validation'])
      const metadata = commonMetaData(rootState)
      grpc.invoke(InspectionKeywordService.ValidateContents, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOG(state, data) {
    Object.assign(state, {
      ...data
    })
  },
  RESET(state) {
    Object.assign(state, initialState())
  }
}
