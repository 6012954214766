import { useLocalStorage } from '~/components/logics/local-storage'
import {
  DEFAULT_LANGUAGE_KEY,
  LANGUAGES,
  LanguageType,
  isLanguageUseKanji,
  isTranslatableLanguage
} from '~/constants/language'
import { memoize, throttle, uniq } from 'lodash-es'
import { getTranslatedText } from '~/api/web/translate/translate-client'
import { onMounted, ref, useContext, useRoute } from '@nuxtjs/composition-api'
import { useCookies } from '~/components/logics/cookies'
import Vue from 'vue'
import { translateTargetPage } from './target-page'

const LANGUAGE_DICTIONARY_KEY = 'coconala_language_dictionary_key'
type DictionaryType = { [key: string]: { [key in LanguageType]?: string } }
type JsonDictionaryType = { [key: string]: string }
// 翻訳の処理をコールするたびに、ユーザーIDを呼び出し元で設定するのが大変なので、グローバル変数で管理し、意識しなくていいように
let userIdToTranslation: number | null = null

export const KEY_TRANSLATE_OBSERVER_ATTRIBUTE_NAME = 'data-translate-observer-key'

// 空白などの余分なところを取り除いたDomの文字列を取得
const extractTextContent = (targetElements: (HTMLElement)[]): string[] => {
  return targetElements.flatMap(
    element =>
      // 日本語がマッチするもののみ抜き出し
      element
        .textContent!.split(/[\n ']/)
        .filter(s => !!s && s.match(/[ぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠]/))
        .map(s => s.trim()) || []
  )
}

const callTranslate = (targetLanguage: LanguageType, textsToTranslate: string[]): any => {
  let target = textsToTranslate.filter(v => !!v)
  if (isLanguageUseKanji(targetLanguage)) {
    // 「ひらがな or カタカナ or 数値」が含まれている or 漢字が含まれていない場合は、中国語でないので翻訳対象にする
    target = target.filter(s => s.match(/[ぁ-んァ-ヶｱ-ﾝﾞﾟ0-9]/) || !s.match(/[一-龠]/))
  }
  if (target.length === 0)
    return textsToTranslate
      .filter(v => !!v)
      .map(v => ({ [v]: v }))
      .reduce((previous, currentValue) => ({ ...previous, ...currentValue }), {})
  // 翻訳APIをコールしているものなのでコストを把握するために残します
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      '以下をGoogleAPIでリクエストしています。事前に固定文言登録できないか確認してください'
    )
    console.warn(target)
  }
  return getTranslatedText(targetLanguage, target, userIdToTranslation)
}

/**
 * 引数で与えられた翻訳対象のNodeを翻訳後の文字列に置換する
 */
const replaceTextTargetNode = (translateTextList: string[][], nodeList: Node[]) => {
  nodeList.forEach(node => {
    const trimText = node.textContent!.trim()
    const toReplace = translateTextList[trimText]
    if (!trimText || typeof toReplace !== 'string') return
    if (!node.hasChildNodes() && node.nodeType === Node.TEXT_NODE) {
      node.textContent = node.textContent!.replace(trimText, toReplace)
    }
    if (node.hasChildNodes()) {
      // ChildNodesがあるということはHTMLElementであり、hasAttributeをコールするためにasを使用
      if ((node as HTMLElement).hasAttribute('data-ignore-translate')) return
      const textNode = Array.from(node.childNodes).filter(
        childNode => childNode.nodeType === Node.TEXT_NODE
      )
      if (textNode.length === 0 || !textNode[0].textContent) return
      textNode[0].textContent = textNode[0].textContent.replace(trimText, toReplace)
    }
  })
}

/**
 * xPathを用いて翻訳対象のNodeを見つける
 * @param translateTextList : 翻訳元の文字列の配列（だいたい日本語）
 * @param topElement：topElement配下のDOMから対象文字列を探す。（パフォーマンス向上のため指定推奨）
 * @returns 翻訳対象のNode。すべて見つけられなかったら空配列
 */
const findNodeUsingXpath = (translateTextList: string[], topElement?: HTMLElement) => {
  let topXPath = '@data-translate'
  if (topElement) {
    // v-intersect-translate.onceを設定すると attrに一意のKEY_TRANSLATE_OBSERVER_ATTRIBUTE_NAMEが付与される
    const dynamicTranslateKey = topElement.getAttribute(KEY_TRANSLATE_OBSERVER_ATTRIBUTE_NAME)
    topXPath = dynamicTranslateKey
      ? `@${KEY_TRANSLATE_OBSERVER_ATTRIBUTE_NAME}='${dynamicTranslateKey}'`
      : topElement
          .getAttributeNames()
          // attrに登録されている要素から対象のDOMを探す(data-v-〇〇など)
          .map(attr => `@${attr}`)
          .join(' and ')
  }
  const containsXPath = translateTextList.map(t => `contains(text(), '${t}')`).join(' or ')

  const dom = document.evaluate(
    // 子要素に特定の文字を含む or 同列の要素に特定の文字を含む
    `//*[${topXPath}]//*[${containsXPath}]|//*[${topXPath}][${containsXPath}]`,
    document,
    null,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
    null
  )

  // 置換対象のDOM数が想定より少ない場合は、ここでは翻訳せずにextractTextNodesで翻訳する
  // document全てをやるとパフォーマンスがよろしくないのでtopElementが指定されている場合のみ許容
  if (topElement && dom.snapshotLength < translateTextList.length) return []
  const returnNodeList: Node[] = []
  for (let index = 0; index < dom.snapshotLength; index++) {
    returnNodeList.push(dom.snapshotItem(index)!)
  }
  return returnNodeList
}

// すでに登録されている翻訳済みデータを返却する
const registeredTranslate = (
  targetLanguage: LanguageType,
  textToTranslate: string,
  dictionary: DictionaryType,
  jsonDictionary: JsonDictionaryType
): string | undefined => {
  // jsonに登録済みの場合はそれを使用
  const findJsonDictionary = jsonDictionary[textToTranslate]
  if (typeof findJsonDictionary === 'string') return findJsonDictionary

  // localStorageに登録済みの場合はそれを使用
  const findDictionary = dictionary[textToTranslate]
  if (findDictionary && findDictionary[targetLanguage]) return findDictionary[targetLanguage]!
  return undefined
}

// 翻訳APIをコールする必要があるものとないものの振り分けを行う
const dispatchTranslate = async (
  targetLanguage: LanguageType,
  targetTexts: string[],
  dictionary: DictionaryType
): Promise<[string[][], string[]]> => {
  const translated = <[string][]>[] // 翻訳済み(固定文言 or localStorageに値あり)
  const needTranslate = <string[]>[] // API通信する必要あり
  const jsonDictionary = await getJsonDictionary(targetLanguage)

  // 処理速度を求めて、forEachではなくforを使用
  for (let index = 0; index < targetTexts.length; index++) {
    const text = registeredTranslate(
      targetLanguage,
      `"${targetTexts[index]}"`,
      dictionary,
      jsonDictionary
    )
    if (typeof text === 'string') {
      translated[targetTexts[index]] = text
    } else {
      needTranslate.push(targetTexts[index])
    }
  }

  return [translated, needTranslate]
}

/**
 * 再帰関数でtextNodeのみを抜き出し置換するので、精度は高くなるがパフォーマンスが悪いので最終手段
 * <span>テストです。詳しくは<a href="">こちら</a></span> みたいなDOMはこちらじゃないと対応できない
 */
const extractTextNodes = (node: Node, count = 0) => {
  let returnNodeList: Node[] = []
  if (
    typeof (node as HTMLElement).hasAttribute === 'function' &&
    (node as HTMLElement).hasAttribute('data-ignore-translate')
  )
    return []

  // テキストノードの場合、テキストを抜き出す
  if (node.nodeType === Node.TEXT_NODE && node.textContent!.match(/[ぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠]/)) {
    return [node]
  }

  if (process.env.NODE_ENV !== 'production' && count === 20) {
    // 再帰関数のループが多いとパフォーマンスに影響あるので警告
    console.warn(
      '必要以上に再帰関数が呼び出されている可能性があります。DOMを変更して、extractTextNodes を呼び出さないように修正できないか検討ください'
    )
  }

  // 子ノードを再帰的に処理する
  if (node.childNodes && node.childNodes.length > 0) {
    for (let i = 0; i < node.childNodes.length; i++) {
      returnNodeList = [...returnNodeList, ...extractTextNodes(node.childNodes[i], ++count)]
    }
  }
  return returnNodeList
}

/**
 * DOMを元に翻訳対象の文字列のみ抜き出し翻訳。結果をReplace
 * 詳しいロジックはdocs配下のドキュメントを参照ください
 */
const translateElement = async (
  targetLanguage: LanguageType,
  dictionary: DictionaryType,
  topElement?: HTMLElement
) => {
  const targetElements = topElement
    ? [topElement]
    : Array.from(document.querySelectorAll<HTMLElement>('[data-translate]'))
  if (targetElements.length === 0) return
  const targetXPathTexts = uniq(extractTextContent(targetElements))
  if (targetXPathTexts.length === 0) return

  let nodeList = findNodeUsingXpath(targetXPathTexts, topElement)
  if (nodeList.length === 0 && topElement) {
    nodeList = extractTextNodes(topElement)
  }
  if (nodeList.length === 0) return
  nodeList = nodeList.filter(node => {
    if (typeof (node as HTMLElement).hasAttribute !== 'function') return true
    return !(node as HTMLElement).hasAttribute('data-ignore-translate')
  })

  const targetTexts = uniq(nodeList.map(node => node.textContent!.trim()).filter(v => !!v))
  const [translated, needTranslate] = await dispatchTranslate(
    targetLanguage,
    targetTexts,
    dictionary
  )

  if (Object.keys(translated).length > 0) {
    replaceTextTargetNode(translated, nodeList)
  }

  if (needTranslate.length > 0) {
    const translatedText = await callTranslate(targetLanguage, needTranslate)
    replaceTextTargetNode(translatedText, nodeList)
    return translatedText
  }

  return []
}

// localStorageに登録されている辞書のupdateを行う
const updateLocalStorage = (targetLanguage: LanguageType, textsToTranslate: string[][]) => {
  const parsedDictionary = getLocalStorageDictionary()

  Object.entries(textsToTranslate).forEach(([japanese, translatedText]) => {
    if (parsedDictionary[`"${japanese}"`]) {
      parsedDictionary[`"${japanese}"`] = {
        ...parsedDictionary[`"${japanese}"`],
        [targetLanguage]: translatedText
      }
    } else {
      parsedDictionary[`"${japanese}"`] = { [targetLanguage]: translatedText }
    }
  })
  try {
    localStorage.setItem(LANGUAGE_DICTIONARY_KEY, JSON.stringify(parsedDictionary))
  } catch {
    // ローカルストレージの容量がいっぱいになったら、過去のデータを一旦リセットする
    localStorage.removeItem(LANGUAGE_DICTIONARY_KEY)
  }
}

export const useTranslate = () => {
  const dictionary = ref<DictionaryType>({})
  const { localStorageValue } = useLocalStorage(LANGUAGE_DICTIONARY_KEY)
  const { cookieValue: language } = useCookies(DEFAULT_LANGUAGE_KEY)
  const route = useRoute()
  const { $util, $sentry, store } = useContext()
  userIdToTranslation = store.state.auth.user.id

  onMounted(async () => {
    if (!translateTargetPage(route.value.name!)) return // 翻訳対象ページでない場合は処理を走らせない
    if (!isTranslatableTarget(language.value)) return // 日本語は翻訳処理を走らせない

    const isTargetDomShown = throttle(
      () => {
        const result = extractTextContent(
          Array.from(document.querySelectorAll<HTMLElement>('[data-translate]'))
        )
        return result.length > 0
      },
      // 100msに一回実行
      100,
      { leading: false }
    ) as () => boolean

    await Vue.nextTick()
    if (document.title) {
      toTranslatedText(document.title).then(result => {
        // 翻訳上限に達していると空になることがある
        if (result) {
          document.title = result
        }
      })
    }

    await $util.waitUntilWithLimit(isTargetDomShown, 1000).catch(() => {})
    dictionary.value = JSON.parse(localStorageValue.value) || {}
    translateElement(language.value, dictionary.value)
      .then(translatedText => {
        if (translatedText) {
          updateLocalStorage(language.value as LanguageType, translatedText)
        }
      })
      .catch(e => $sentry.captureException(e))
  })
}

export const dynamicTranslate = async (element: HTMLElement, { $sentry, $store }) => {
  const language = $store.state.translate.language
  if (!isTranslatableTarget(language)) return
  await Vue.nextTick()
  const parsedDictionary = getLocalStorageDictionary()
  translateElement(language, parsedDictionary, element)
    .then(translatedText => {
      if (translatedText) {
        updateLocalStorage(language, translatedText)
      }
    })
    .catch(e => $sentry.captureException(e))
}

export const dynamicTranslateNoCache = async (element: HTMLElement, { $sentry, $store }) => {
  const language = $store.state.translate.language
  if (!isTranslatableTarget(language)) return
  await Vue.nextTick()
  await translateElement(language, {}, element).catch(e => $sentry.captureException(e))
}

// テキストを翻訳する
export const toTranslatedText = async (target: string, isUpdateLocalCache = true) => {
  if (process.server) return target
  const [, language] = document.cookie.match(new RegExp(`${DEFAULT_LANGUAGE_KEY}=([^;]*);*`)) || []
  if (!isTranslatableTarget(language) || !target) return target
  const parsedDictionary = getLocalStorageDictionary()
  const [translated, needTranslate] = await dispatchTranslate(language, [target], parsedDictionary)

  // 辞書登録済み
  if (Object.keys(translated).length > 0) return translated[target]

  if (needTranslate.length > 0) {
    const translatedText = await callTranslate(language, needTranslate)
    if (isUpdateLocalCache) updateLocalStorage(language, translatedText)
    return translatedText[target]
  }
  return target
}

export const toTranslatedArrayText = async (target: string[]) => {
  if (process.server) return {}
  const [, language] = document.cookie.match(new RegExp(`${DEFAULT_LANGUAGE_KEY}=([^;]*);*`)) || []
  if (!isTranslatableTarget(language)) return {}
  const parsedDictionary = getLocalStorageDictionary()
  const noJapaneseList = target.filter(s => !s.match(/[ぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠]/))

  // 日本語の文字列のみ翻訳対象に
  target = target.filter(s => s.match(/[ぁ-んァ-ヶｱ-ﾝﾞﾟ一-龠]/))
  const [translated, needTranslate] = await dispatchTranslate(language, target, parsedDictionary)
  let resultTranslate: { [index: string]: string }[] = noJapaneseList.map(noJapanese => ({
    [noJapanese]: noJapanese
  }))
  // 辞書登録済み
  if (Object.keys(translated).length > 0) {
    resultTranslate = [
      ...resultTranslate,
      ...target.filter(v => !!translated[v]).map(v => ({
        [v]: translated[v]
      }))
    ]
  }

  if (needTranslate.length > 0) {
    const translatedText = await callTranslate(language, needTranslate)
    updateLocalStorage(language, translatedText)
    resultTranslate = [
      ...resultTranslate,
      ...target.filter(v => !!translatedText[v]).map(v => ({
        [v]: translatedText[v]
      }))
    ]
  }
  if (resultTranslate.length > 0) {
    // `{[日本語]: 翻訳後のテキスト}` というObj形に変換
    return resultTranslate.reduce(
      (previous, currentValue) => ({ ...previous, ...currentValue }),
      {}
    )
  }
  return {}
}

// キャッシュを見ずにテキストを翻訳する(結果もキャッシュはしない)
export const toTranslatedTextNoUseCache = async (
  text: string | string[],
  toLanguage: LanguageType,
  userId: number | null = null
) => {
  if (!isTranslatableLanguage(toLanguage) || !text) return text
  if (userId) userIdToTranslation = userId

  if (Array.isArray(text)) {
    return await callTranslate(toLanguage, text)
  }

  const translatedText = await callTranslate(toLanguage, [text])
  return translatedText[text]
}

// localStorageのアクセスが遅いのでキャッシュする
const getLocalStorageDictionary = memoize(
  () => {
    const localStorageDictionary = localStorage.getItem(LANGUAGE_DICTIONARY_KEY)
    try {
      const parsedDictionary = localStorageDictionary ? JSON.parse(localStorageDictionary) : {}
      return parsedDictionary
    } catch {
      // ユーザーがlocalStorageを直接修正した場合、JSON.parseに失敗するのでその考慮
      return {}
    }
  },
  // 1秒に1回キャッシュを更新
  () => Math.floor(Date.now() / 1000)
)

// 翻訳対象か否かを判定する(言語が日本以外を設定しているか)
export const isTranslatableTarget = memoize((language?: string): language is LanguageType => {
  let targetLang = language
  if (!targetLang) {
    if (!process.client) return false
    ;[, targetLang] = document.cookie.match(new RegExp(`${DEFAULT_LANGUAGE_KEY}=([^;]*);*`)) || []
  }
  if (!targetLang) return false
  if (targetLang === LANGUAGES.JA.code) return false
  return isTranslatableLanguage(targetLang)
  // Cookieの値が変わったら、翻訳後のデータを取得するためにSSRが走るので、キャッシュはSSRするまで保持
}, () => 'only_ssr_cache_clear')

// 言語ごとのJson情報を取得する
const getJsonDictionary = memoize(async (language: LanguageType): Promise<JsonDictionaryType> => {
  let json = {}
  try {
    json = (await import(`~/assets/json/translate/${language}.json`)).default
  } catch {
    // json読み込めなくても後続の処理は続行させたいので、握りつぶす
  }

  return json
}, () => 'only_ssr_cache_clear')
