// initial state
export const state = () => ({
  reasonsList: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchReasons({ commit, rootState }, params = {}) {
    const { ViolationAccessor } = await import('~/api/accessors/report/violation-accessor')
    const apiAccessor = new ViolationAccessor(rootState)
    const requestReply = await apiAccessor.getReasons()
    commit('RECEIVE_REASONS', requestReply)
  },
  async addReportRequest({ commit, rootState }, param = {}) {
    const { ViolationAccessor } = await import('~/api/accessors/report/violation-accessor')
    const apiAccessor = new ViolationAccessor(rootState)
    await apiAccessor.addReport({
      violationReportsList: param.violationReportList,
      serviceId: param.serviceId,
      requestId: param.requestId
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_REASONS(state, data) {
    state.reasonsList = data.reasonsList
  }
}
