import { LANGUAGES } from '~/constants/language'

const LANGUAGE_CODES = {
  ja: LANGUAGES.JA.code, //日本語の場合
  'ja-jp': LANGUAGES.JA.code, //日本語の場合(一部のAndroid)
  'ko-kr': LANGUAGES.KO.code, // 韓国語の場合(Firefox)
  ko: LANGUAGES.KO.code, // 韓国語の場合(Chrome)
  'zh-cn': LANGUAGES.ZHCN.code, // 简体中文の場合
  'zh-tw': LANGUAGES.ZHTW.code // 繁體中文の場合
}

// ブラウザの言語設定から言語コードを取得して返却する
export const getBrowserLanguage = ():
  | (typeof LANGUAGE_CODES)[keyof typeof LANGUAGE_CODES]
  | typeof LANGUAGES.EN.code => {
  const language = navigator.language.toLocaleLowerCase()

  return LANGUAGE_CODES.hasOwnProperty(language) ? LANGUAGE_CODES[language] : LANGUAGES.EN.code
}
