import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import {
  GetCakeNotificationRequest,
  AddCakeNotificationRequest
} from '~/stub/apigateway/common/cake_notification_pb'
import { CakeNotificationService } from '~/stub/apigateway/common/cake_notification_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async getCakeNotify({ commit, rootState }, cakeSession) {
    const getCakeNotifyRequest = new GetCakeNotificationRequest()
    const setMetadata = commonMetaData(rootState)
    getCakeNotifyRequest.setCakeSession(cakeSession)

    const getCakeNotifyRequestReply = await new Promise((resolve, reject) => {
      grpc.invoke(CakeNotificationService.GetCakeNotification, {
        request: getCakeNotifyRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
    return getCakeNotifyRequestReply
  },
  async addCakeNotify({ commit, rootState }, params) {
    const metadata = commonMetaData(rootState)
    const request = new AddCakeNotificationRequest()
    request.setCakeSession(params.cakeSession)
    request.setLevel(params.level)
    request.setMessage(params.message)

    await new Promise((resolve, reject) => {
      grpc.invoke(CakeNotificationService.AddCakeNotification, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {}
