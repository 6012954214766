<template>
  <div>
    <header class="p-businessHeader">
      <div class="p-businessHeaderInner">
        <div class="p-businessHeader_logoWrapper">
          <a href="/">
            <img
              :src="$coconala.fileURL('/staticfiles/images/pages/business/lp/header_logo.svg')"
              class="p-businessHeader_logo p-businessHeader_logo-pc"
              alt="ココナラロゴ"
              width="183"
              height="32"
            />
            <img
              :src="$coconala.fileURL('/staticfiles/images/pages/business/lp/header_logo.svg')"
              class="p-businessHeader_logo p-businessHeader_logo-sp"
              alt="ココナラロゴ"
              width="126"
              height="22"
            />
          </a>
        </div>
        <ClientOnly>
          <ul class="p-businessHeaderNav">
            <li class="p-businessHeaderNav_topLinkWrapper isPC">
              <NuxtLink
                class="p-businessHeaderNav_topLink"
                to="/"
              >
                ココナラトップへ
              </NuxtLink>
            </li>
            <li
              v-if="!isLoggedIn"
              class="p-businessHeaderNav_buttonWrapper"
            >
              <DButton
                class="p-businessHeaderNav_button"
                type="outlined"
                color="primary"
                :size="isSP ? 'small' : 'medium'"
                :href="$coconala.loginURL()"
                pattern="reverse"
                is-bold
              >
                ログイン
              </DButton>
              <DButton
                class="p-businessHeaderNav_button"
                type="contained"
                color="primary"
                :size="isSP ? 'small' : 'medium'"
                nuxt-link-to="/register/top"
                is-bold
              >
                会員登録
              </DButton>
            </li>
          </ul>
        </ClientOnly>
      </div>
    </header>
    <nuxt />
    <slot name="footer">
      <ClientOnly>
        <TheFooter
          ref="footer"
          is-business-footer
        />
      </ClientOnly>
    </slot>
    <TheVueport breakpoint-type="vueportBusinessTop" />
  </div>
</template>

<script>
import TheFooter from '~/components/organisms/TheFooter'
import TheVueport from '~/components/organisms/TheVueport'
import DButton from '~/components/atoms/DS2/DButton'
import { mapGetters } from 'vuex'

const VIEW_BUSINESS_LP_ACTION = 'view_business_lp'

export default {
  components: {
    TheFooter,
    TheVueport,
    DButton
  },
  computed: {
    ...mapGetters('ui', ['isPC', 'isSP']),
    ...mapGetters('auth', ['isLoggedIn'])
  },
  async mounted() {
    try {
      await this.$store.dispatch('big_query/log_action/addLogUserAction', {
        action: VIEW_BUSINESS_LP_ACTION,
        detailJson: JSON.stringify({
          ref: this.$route.query.ref,
          utm_source: this.$route.query.utm_source,
          utm_campaign: this.$route.query.utm_campaign,
          utm_medium: this.$route.query.utm_medium
        })
      })
    } catch (e) {
      this.$sentry.captureException(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.isPC {
  display: block;
}
.p-businessHeader {
  position: fixed;
  z-index: z(header, main);
  width: 100%;
  height: 72px;
  background: linear-gradient(to right, rgba(33, 45, 148, 0.825), rgba(10, 17, 90, 0.825));

  backdrop-filter: blur(10px);

  &_logoWrapper {
    display: flex;

    align-items: center;
    flex-shrink: 0;
  }
  &_logo {
    &-sp {
      display: none;
    }
  }
}

.p-businessHeaderInner {
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1400px;
  width: 100%;
  height: 100%;

  align-items: center;
}

.p-businessHeaderNav {
  display: flex;
  margin-left: auto;

  align-items: center;

  &_topLinkWrapper {
    margin-right: 20px;
  }
  &_topLink {
    @include link-effect;

    color: $ds2-color-white;
    font-size: 13px;
  }
  &_buttonWrapper {
    display: flex;
  }
  &_button {
    width: 112px;
    & + & {
      margin-left: 20px;
    }
  }
}

// SP
@media (max-width: breakpoint(BusinessTop, S)) {
  .isPC {
    display: none;
  }
  .p-businessHeader {
    height: 46px;

    &_logo {
      &-pc {
        display: none;
      }
      &-sp {
        display: block;
      }
    }
  }

  .p-businessHeaderInner {
    min-width: auto;
    padding: 0 12px;
  }

  .p-businessHeaderNav {
    &_button {
      width: 74px;
      & + & {
        margin-left: 8px;
      }
    }
  }
}
</style>
