export const AB_TEST_COOKIE_KEY = 'ct_adw'

export default context => {
  const abTestId =
    context.query &&
    (Array.isArray(context.query['ref'])
      ? context.query['ref']?.includes(AB_TEST_COOKIE_KEY)
      : context.query['ref'] === AB_TEST_COOKIE_KEY)
  if (
    context.route.path === '/' &&
    context.app.$cookies.get('ct_adw') !== 0 &&
    context.app.$cookies.get('ct_adw') !== 1
  ) {
    const host = context.req.headers.host
    context.app.$cookies.set(AB_TEST_COOKIE_KEY, abTestId ? 1 : 0, 30)
  }
}
