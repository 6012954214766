import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '~/grpc/grpc-client'

// initial state
const initialState = () => ({})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchProviderDashboard({ commit, rootState }) {
    const [
      {
        GetProviderRequestRecommendationRequest,
        GetProviderDashboardRequest,
        GetProviderAchievementRequest
      },
      { DashboardProviderService },
      { GetProviderBlogAchievementRequest, GetRegistrationRecommendedProfileItemsRequest },
      { DashboardProviderService: DashboardProviderServiceProfile },
      { GetMyUserJobsRequest },
      { UserJobService }
    ] = await Promise.all([
      import('~/stub/apigateway/dashboard/dashboard_provider_pb'),
      import('~/stub/apigateway/dashboard/dashboard_provider_pb_service'),
      import('~/stub/apigateway/profile/dashboard_provider_pb'),
      import('~/stub/apigateway/profile/dashboard_provider_pb_service'),
      import('~/stub/apigateway/profile/user_job_pb'),
      import('~/stub/apigateway/profile/user_job_pb_service')
    ])

    const setMetadata = commonMetaData(rootState)
    setMetadata['x-log-detail'] = JSON.stringify({ view_id: rootState.big_query.viewId })
    const providerRequestRecommendationRequest = new GetProviderRequestRecommendationRequest()
    providerRequestRecommendationRequest.setAlgorithm('vector_recommend')
    const responses = Object.fromEntries(
      await Promise.all([
        // GetProviderDashboard
        grpcClient({
          method: DashboardProviderService.GetProviderDashboard,
          request: new GetProviderDashboardRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['providerDashboard', reply]
          }
        }),
        // GetProviderAchievement
        grpcClient({
          method: DashboardProviderService.GetProviderAchievement,
          request: new GetProviderAchievementRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['providerAchievement', reply]
          }
        }),
        // GetProviderRequestRecommendation
        grpcClient({
          method: DashboardProviderService.GetProviderRequestRecommendation,
          request: providerRequestRecommendationRequest,
          metadata: setMetadata,
          transformReply: reply => {
            return ['providerRequestRecommendation', reply]
          }
        }),
        // GetProviderBlogAchievement
        grpcClient({
          method: DashboardProviderServiceProfile.GetProviderBlogAchievement,
          request: new GetProviderBlogAchievementRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['providerBlogAchievement', reply]
          }
        }),
        // GetRegistrationRecommendedProfileItems
        grpcClient({
          method: DashboardProviderServiceProfile.GetRegistrationRecommendedProfileItems,
          request: new GetRegistrationRecommendedProfileItemsRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['registrationRecommendedProfileItems', reply]
          }
        }),
        // GetMyUserJobs
        grpcClient({
          method: UserJobService.GetMyUserJobs,
          request: new GetMyUserJobsRequest(),
          metadata: setMetadata,
          transformReply: reply => {
            return ['userJobs', reply]
          }
        })
      ])
    )
    return responses
  },
  async fetchAchievement({ commit, rootState }, { monthType }) {
    const [
      { GetProviderAchievementRequest },
      { DashboardProviderService },
      { GetProviderBlogAchievementRequest },
      { DashboardProviderService: DashboardProviderServiceProfile }
    ] = await Promise.all([
      import('~/stub/apigateway/dashboard/dashboard_provider_pb'),
      import('~/stub/apigateway/dashboard/dashboard_provider_pb_service'),
      import('~/stub/apigateway/profile/dashboard_provider_pb'),
      import('~/stub/apigateway/profile/dashboard_provider_pb_service')
    ])
    const setMetadata = commonMetaData(rootState)
    const providerAchievementRequest = new GetProviderAchievementRequest()
    const providerBlogAchievementRequest = new GetProviderBlogAchievementRequest()
    providerAchievementRequest.setMonthType(String(monthType))
    providerBlogAchievementRequest.setMonthType(String(monthType))
    const responses = Object.fromEntries(
      await Promise.all([
        // GetProviderAchievement
        grpcClient({
          method: DashboardProviderService.GetProviderAchievement,
          request: providerAchievementRequest,
          metadata: setMetadata,
          transformReply: reply => {
            return ['providerAchievement', reply]
          }
        }),
        // GetProviderBlogAchievement
        grpcClient({
          method: DashboardProviderServiceProfile.GetProviderBlogAchievement,
          request: providerBlogAchievementRequest,
          metadata: setMetadata,
          transformReply: reply => {
            return ['providerBlogAchievement', reply]
          }
        })
      ])
    )
    return responses
  }
}

// mutations
export const mutations = {}
