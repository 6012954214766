"use strict";
// package: profile
// file: apigateway/profile/blog_category.proto
exports.__esModule = true;
exports.BlogCategoryService = void 0;
var apigateway_profile_blog_category_pb = require("../../apigateway/profile/blog_category_pb");
var BlogCategoryService = /** @class */ (function () {
    function BlogCategoryService() {
    }
    BlogCategoryService.serviceName = "profile.BlogCategoryService";
    return BlogCategoryService;
}());
exports.BlogCategoryService = BlogCategoryService;
(function (BlogCategoryService) {
    var GetBlogCategories = /** @class */ (function () {
        function GetBlogCategories() {
        }
        GetBlogCategories.methodName = "GetBlogCategories";
        GetBlogCategories.service = BlogCategoryService;
        GetBlogCategories.requestStream = false;
        GetBlogCategories.responseStream = false;
        GetBlogCategories.requestType = apigateway_profile_blog_category_pb.GetBlogCategoriesRequest;
        GetBlogCategories.responseType = apigateway_profile_blog_category_pb.BlogCategoriesReply;
        return GetBlogCategories;
    }());
    BlogCategoryService.GetBlogCategories = GetBlogCategories;
    var CreateBlogCategory = /** @class */ (function () {
        function CreateBlogCategory() {
        }
        CreateBlogCategory.methodName = "CreateBlogCategory";
        CreateBlogCategory.service = BlogCategoryService;
        CreateBlogCategory.requestStream = false;
        CreateBlogCategory.responseStream = false;
        CreateBlogCategory.requestType = apigateway_profile_blog_category_pb.CreateBlogCategoryRequest;
        CreateBlogCategory.responseType = apigateway_profile_blog_category_pb.BlogCategoryReply;
        return CreateBlogCategory;
    }());
    BlogCategoryService.CreateBlogCategory = CreateBlogCategory;
    var UpdateBlogCategory = /** @class */ (function () {
        function UpdateBlogCategory() {
        }
        UpdateBlogCategory.methodName = "UpdateBlogCategory";
        UpdateBlogCategory.service = BlogCategoryService;
        UpdateBlogCategory.requestStream = false;
        UpdateBlogCategory.responseStream = false;
        UpdateBlogCategory.requestType = apigateway_profile_blog_category_pb.UpdateBlogCategoryRequest;
        UpdateBlogCategory.responseType = apigateway_profile_blog_category_pb.BlogCategoryReply;
        return UpdateBlogCategory;
    }());
    BlogCategoryService.UpdateBlogCategory = UpdateBlogCategory;
    var DeleteBlogCategory = /** @class */ (function () {
        function DeleteBlogCategory() {
        }
        DeleteBlogCategory.methodName = "DeleteBlogCategory";
        DeleteBlogCategory.service = BlogCategoryService;
        DeleteBlogCategory.requestStream = false;
        DeleteBlogCategory.responseStream = false;
        DeleteBlogCategory.requestType = apigateway_profile_blog_category_pb.DeleteBlogCategoryRequest;
        DeleteBlogCategory.responseType = apigateway_profile_blog_category_pb.DeleteBlogCategoryReply;
        return DeleteBlogCategory;
    }());
    BlogCategoryService.DeleteBlogCategory = DeleteBlogCategory;
})(BlogCategoryService = exports.BlogCategoryService || (exports.BlogCategoryService = {}));
exports.BlogCategoryService = BlogCategoryService;
