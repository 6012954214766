import type { Route } from 'vue-router'
import { isSingleQuery } from '~/components/logics/utils'
import type { GetAppsFlyerUrlWithUtmParams } from '~/api/accessors/apps_flyer/apps-flyer-url-accessor'

/** onelinkの特定に必須のutmパラメータ */
export const ONELINK_REQUIRED_UTM_QUERY_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign'
] as const

export const pickOnelinkUtmParamsFromQuery = (
  query: Route['query']
): GetAppsFlyerUrlWithUtmParams | null => {
  if (ONELINK_REQUIRED_UTM_QUERY_PARAMS.some(v => !query[v])) return null

  const utmSource = isSingleQuery(query['utm_source'])
    ? query['utm_source']
    : query['utm_source'][0]
  const utmMedium = isSingleQuery(query['utm_medium'])
    ? query['utm_medium']
    : query['utm_medium'][0]
  const utmCampaign = isSingleQuery(query['utm_campaign'])
    ? query['utm_campaign']
    : query['utm_campaign'][0]
  const utmTerm = isSingleQuery(query['utm_term']) ? query['utm_term'] : query['utm_term'][0]
  const utmContent = isSingleQuery(query['utm_content'])
    ? query['utm_content']
    : query['utm_content'][0]

  return {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent
  }
}
