var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"control c-inputV2"},[_c('OInput',_vm._g(_vm._b({staticClass:"c-inputV2_text",class:{
      '-inline-block': _vm.inlineBlock,
      'c-inputV2_text-invalid': _vm.errorMessage && _vm.showErrorMessage,
      'c-inputV2_text-hasTemplate': _vm.hasTemplate
    },attrs:{"maxlength":_vm.maxInputLength,"has-counter":false},on:{"input":_vm.changeInput},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.emitEnterEvent.apply(null, arguments)},"compositionstart":function($event){_vm.composing = true},"compositionend":function($event){_vm.composing = false}},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},'OInput',_vm.$attrs,false),_vm.$listeners)),_vm._v(" "),(_vm.showErrorMessage && _vm.errorMessage)?_c('small',{staticClass:"help c-inputV2_errorMessage"},[_c('DTranslate',{attrs:{"text":_vm.errorMessage}})],1):_vm._e(),_vm._v(" "),(_vm.maxlength)?_c('small',{staticClass:"help counter c-inputV2_counter",class:{
      'c-inputV2_counter-invalid': _vm.errorMessage,
      'c-inputV2_counter-hasTemplate': _vm.hasTemplate
    }},[_vm._v("\n    "+_vm._s(_vm.valueLength)+" / "+_vm._s(_vm.maxlength)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }