import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

const toString = value => {
  return typeof value === 'undefined' ? value : String(value)
}

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async homeContentRequests({ commit, rootState, rootGetters }, fullPath) {
    const [{ GetHomeContentRequest }, { HomeService }] = await Promise.all([
      import('~/stub/apigateway/home/home_pb'),
      import('~/stub/apigateway/home/home_pb_service')
    ])
    const getRequest = new GetHomeContentRequest()
    const setMetadata = commonMetaData(rootState)
    setMetadata['x-full-path'] = [fullPath]
    setMetadata['x-log-detail'] = JSON.stringify({ view_id: rootState.big_query.viewId })

    let headersDate = ''
    const homeContentReply = await new Promise((resolve, reject) => {
      grpc.invoke(HomeService.GetHomeContent, {
        request: getRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onHeaders: headers => {
          if ('date' in headers.headersMap) {
            headersDate = headers.headersMap.date[0]
          }
        },
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    if (headersDate && this.$util.dayjs(headersDate).isValid()) {
      homeContentReply['currentDate'] = headersDate
    } else {
      homeContentReply['currentDate'] = new Date().toGMTString()
    }

    return homeContentReply
  },
  async myServiceHistoriesRequests({ commit, rootState }, cookie) {
    const [{ GetMyServiceHistoriesRequest }, { HomeService }] = await Promise.all([
      import('~/stub/apigateway/home/home_pb'),
      import('~/stub/apigateway/home/home_pb_service')
    ])
    const getRequest = new GetMyServiceHistoriesRequest()
    const setMetadata = commonMetaData(rootState)
    getRequest.setHistory(toString(cookie))

    const myServiceHistoriesReply = await new Promise(resolve => {
      grpc.invoke(HomeService.GetMyServiceHistories, {
        request: getRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            // トップページの表示には影響しないようにSentryにエラーを投げて握りつぶす
            this.$sentry.captureException({ grpcCode, rpc: 'HomeService.GetMyServiceHistories' })
            resolve()
          }
        }
      })
    })

    return myServiceHistoriesReply
  },
  async myFavoriteServicesRequests({ commit, rootState }) {
    const [{ GetMyFavoriteServicesRequest }, { HomeService }] = await Promise.all([
      import('~/stub/apigateway/home/home_pb'),
      import('~/stub/apigateway/home/home_pb_service')
    ])
    const getRequest = new GetMyFavoriteServicesRequest()
    const setMetadata = commonMetaData(rootState)

    const myFavoriteServicesReply = await new Promise(resolve => {
      grpc.invoke(HomeService.GetMyFavoriteServices, {
        request: getRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            // トップページの表示には影響しないようにSentryにエラーを投げて握りつぶす
            this.$sentry.captureException({ grpcCode, rpc: 'HomeService.GetMyFavoriteServices' })
            resolve()
          }
        }
      })
    })

    return myFavoriteServicesReply
  }
}

// mutations
export const mutations = {}
