import clsx from 'clsx'
import { tv, VariantProps } from 'tailwind-variants'
import { TAILWIND_THEME } from '~/assets/stylesheets/tailwind/tailwind-theme'

const buttonVariants = tv({
  base: clsx(
    'tw-relative tw-inline-flex tw-cursor-pointer tw-items-center tw-justify-center tw-border tw-border-solid tw-no-underline',
    'active:tw-transform-none',
    'disabled:tw-cursor-not-allowed'
  ),
  variants: {
    type: {
      primaryContain: clsx(
        'tw-border-primary-600 tw-bg-primary-600 tw-text-gray-0',
        'hover:tw-border-primary-700 hover:tw-bg-primary-700 hover:tw-text-gray-0',
        'active:tw-border-primary-900 active:tw-bg-primary-900'
      ),
      primaryOutline: clsx(
        'tw-border-primary-600 tw-bg-transparent tw-text-primary-600',
        'hover:tw-bg-primary-100 hover:tw-text-primary-600',
        'active:tw-bg-primary-200 active:tw-text-primary-600'
      ),
      grayOutline: clsx(
        'tw-border-gray-400 tw-bg-transparent tw-text-gray-600',
        'hover:tw-bg-gray-25 hover:tw-text-gray-600',
        'active:tw-bg-gray-50'
      ),
      text: clsx(
        'tw-border-transparent tw-bg-transparent tw-text-primary-600',
        'hover:tw-bg-primary-100 hover:tw-text-primary-600',
        'active:tw-bg-primary-200'
      )
    },
    size: {
      lg: clsx('tw-h-12 tw-min-w-30 tw-rounded-lg tw-px-4 tw-text-body-strong-large'),
      md: clsx('tw-h-10 tw-min-w-18 tw-rounded-lg tw-px-4 tw-text-label-large-strong'),
      sm: clsx('tw-h-8 tw-min-w-12 tw-rounded tw-px-3 tw-text-label-small-strong')
    },
    isDisabled: {
      true: clsx('!tw-cursor-not-allowed')
    },
    isLoading: {
      true: clsx('!tw-cursor-not-allowed')
    },
    isFullWidth: {
      true: clsx('tw-w-full')
    }
  },
  compoundVariants: [
    {
      type: 'primaryContain',
      isDisabled: true,
      className: clsx('!tw-border-primary-600 !tw-bg-primary-600 !tw-opacity-50')
    },
    {
      type: 'primaryOutline',
      isDisabled: true,
      className: clsx('!tw-border-primary-600 !tw-bg-primary-100 !tw-opacity-50')
    },
    {
      type: 'grayOutline',
      isDisabled: true,
      className: clsx('!tw-bg-gray-25 !tw-text-gray-900 !tw-opacity-50')
    },
    {
      type: 'text',
      isDisabled: true,
      className: clsx('!tw-bg-primary-100 !tw-opacity-50')
    },
    {
      type: 'primaryContain',
      isLoading: true,
      className: clsx('!tw-border-primary-600 !tw-bg-primary-600')
    },
    {
      type: 'primaryOutline',
      isLoading: true,
      className: clsx('!tw-border-primary-600 !tw-bg-transparent')
    },
    {
      type: 'grayOutline',
      isLoading: true,
      className: clsx('!tw-bg-gray-25')
    },
    {
      type: 'text',
      isLoading: true,
      className: clsx('!tw-bg-transparent')
    }
  ],
  defaultVariants: {
    type: 'primaryContain',
    size: 'lg',
    isDisabled: false,
    isLoading: false,
    isFullWidth: false
  }
})

type ButtonVariants = VariantProps<typeof buttonVariants>

const getIconColor = (type: ButtonVariants['type']): string => {
  switch (type) {
    case 'primaryContain':
      return TAILWIND_THEME.iconColor.white
    case 'grayOutline':
      return TAILWIND_THEME.iconColor['gray-400']
    case 'primaryOutline':
    case 'text':
    default:
      return TAILWIND_THEME.iconColor.primary
  }
}

const getLoaderColor = (type: ButtonVariants['type']): string => {
  if (type === 'primaryContain') {
    return 'white'
  }
  return 'primary'
}

const getLoaderSize = (buttonSize: ButtonVariants['size']): string => {
  switch (buttonSize) {
    case 'sm':
      return 'xxs'
    case 'md':
      return 'xs'
    case 'lg':
      return 'sm'
    default:
      return 'xs'
  }
}

export { buttonVariants, getIconColor, getLoaderColor, getLoaderSize }
