// source: domain/enumeration/outsource_status.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.OutsourceStatus', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.OutsourceStatus = {
  OUTSOURCE_STATUS_UNSPECIFIED: 0,
  OUTSOURCE_STATUS_DRAFT: 1,
  OUTSOURCE_STATUS_PUBLISHED: 2,
  OUTSOURCE_STATUS_STOP: 3,
  OUTSOURCE_STATUS_FINISHED: 4,
  OUTSOURCE_STATUS_DELETED: 5
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
