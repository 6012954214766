<template>
  <div class="control c-inputV2">
    <OInput
      v-bind="$attrs"
      v-on="$listeners"
      v-model="innerValue"
      class="c-inputV2_text"
      :class="{
        '-inline-block': inlineBlock,
        'c-inputV2_text-invalid': errorMessage && showErrorMessage,
        'c-inputV2_text-hasTemplate': hasTemplate
      }"
      :maxlength="maxInputLength"
      :has-counter="false"
      @input="changeInput"
      @keydown.native.enter="emitEnterEvent"
      @compositionstart.native="composing = true"
      @compositionend.native="composing = false"
    >
    </OInput>
    <small
      v-if="showErrorMessage && errorMessage"
      class="help c-inputV2_errorMessage"
    >
      <DTranslate :text="errorMessage" />
    </small>
    <small
      v-if="maxlength"
      class="help counter c-inputV2_counter"
      :class="{
        'c-inputV2_counter-invalid': errorMessage,
        'c-inputV2_counter-hasTemplate': hasTemplate
      }"
    >
      {{ valueLength }} / {{ maxlength }}
    </small>
  </div>
</template>
<script>
import DTranslate from '~/components/atoms/DS2/DTranslate'
export default {
  name: 'CInputV2',
  components: { DTranslate },
  props: {
    value: {
      type: [Number, String]
    },
    inlineBlock: {
      type: Boolean,
      default: false
    },
    maxInputLength: {
      // 入力制限する文字数
      type: Number,
      default: null
    },
    maxlength: {
      type: [Number, String]
    },
    minlength: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    isTruncatable: {
      type: Boolean,
      default: false
    },
    isEnableEnterEvent: {
      type: Boolean,
      default: false
    },
    showErrorMessage: {
      type: Boolean,
      default: true
    },
    pattern: {
      // 入力文字種の制限
      type: String,
      default: null
    },
    additionalValidations: {
      // 特殊なバリデーション
      type: Array,
      default: () => []
    },
    immediateValidation: {
      // 未入力でもバリデーションをする
      type: Boolean,
      default: false
    },
    hasTemplate: {
      // 定型文機能を付ける場合true
      type: Boolean,
      default: false
    },
    changedValue: {
      // 定型文の挿入等で親コンポーネントから値を変更したいとき、これで渡す
      // modelの値を直接操作すると、文字数カウントやバリデーションが発火しないため
      type: String,
      default: ''
    }
  },
  data() {
    return {
      newValue: this.value,
      composing: false,
      isEdited: false
    }
  },
  watch: {
    isValid: {
      handler() {
        this.$emit('validate', this.isValid)
      },
      immediate: true
    },
    changedValue() {
      this.innerValue = this.changedValue
    },
    errorMessage() {
      this.$emit('error-message', this.errorMessage)
    }
  },
  created() {
    this.$emit('input', this.value)
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        let value = this.maxlength && this.isTruncatable ? val.substring(0, this.maxlength) : val

        if (this.pattern === 'number' && value.length) {
          // 数字のみ入力許可
          value = value.replace(/\D/, '') // 数字以外削除
          value = parseInt(value, 10) || '' // 先頭の0を消す
          if (value === 0) {
            value = '' // 0は許可しない
          }
        }

        this.newValue = value
        // OInputのemitの方が後で走り、上書きされるため
        this.$nextTick(function () {
          this.$emit('input', value)
        })
      }
    },
    valueLength() {
      let string = ''
      if (typeof this.newValue === 'string') {
        string = this.newValue
      } else if (typeof this.newValue === 'number') {
        string = this.newValue.toString()
      }
      return string ? string.replace('\r\n', '\n').length : 0
    },
    isValid() {
      if (this.valueLength === 0) {
        return this.required ? false : true
      }

      if (this.valueLength < this.minlength) {
        return false
      }

      if (this.maxlength && this.valueLength > this.maxlength) {
        return false
      }

      if (this.additionalValidations.length) {
        // すべてvalidだったらtrue
        return this.additionalValidations.every(additionalValidation => {
          if (additionalValidation === 'url') {
            return this.isValidUrl
          }
          if (additionalValidation === 'coconalaUrl') {
            return this.isValidCoconalaUrl
          }
        })
      }

      return true
    },
    errorMessage() {
      if (this.valueLength === 0) {
        if (!this.isEdited && !this.immediateValidation) {
          // 未入力かつ初期値もないときにはエラー表示をしない
          return ''
        }

        return this.required ? '入力してください' : ''
      }

      if (this.valueLength < this.minlength) {
        return this.minlength + '文字以上で入力してください'
      }

      if (this.maxlength && this.valueLength > this.maxlength) {
        return this.maxlength + '文字以下で入力してください'
      }

      if (this.additionalValidations.length) {
        // 1つでもvalidでないものがあったらそのエラーメッセージを返す
        let errorMessage = ''
        this.additionalValidations.some(additionalValidation => {
          if (additionalValidation === 'url') {
            if (!this.isValidUrl) {
              errorMessage = '正しいURLを入力してください'
              return true
            }
          }
          if (additionalValidation === 'coconalaUrl') {
            if (!this.isValidCoconalaUrl) {
              errorMessage = 'ココナラ内のURLのみ有効です'
              return true
            }
          }
        })
        return errorMessage
      }

      return ''
    },
    isValidUrl() {
      // URL以外の行が含まれないかチェック 空行はOK
      const urls = this.newValue.replace(/\r\n/g, '\n').split('\n')
      return urls.every(url => {
        return url.length === 0 || /^https?:\/\/.+$/.test(url)
      })
    },
    isValidCoconalaUrl() {
      // URL以外の行が含まれないかチェック 空行はOK
      const urls = this.newValue.replace(/\r\n/g, '\n').split('\n')
      return urls.every(url => {
        // coconala.comとサブドメインを許容
        return url.length === 0 || /^https?:\/\/\w*\.?coconala\.com.+$/.test(url)
      })
    }
  },
  methods: {
    emitEnterEvent() {
      // IME確定時のEnterは無視
      if (this.composing) {
        return
      }
      if (this.isEnableEnterEvent) {
        this.$emit('enter-clicked')
      }
    },
    changeInput() {
      this.isEdited = true
    }
  }
}
</script>
<style lang="scss" scoped>
.control {
  &.-inline-block {
    display: inline-block;
    vertical-align: 0.4em;
    &.is-small {
      vertical-align: 0.2em;
    }
    &.is-medium {
      vertical-align: 0.6em;
    }
    &.is-large {
      vertical-align: 1.2em;
    }
  }
}

.c-inputV2 {
  &_text {
    &-invalid {
      ::v-deep input,
      ::v-deep textarea {
        border-color: $color-red-500;
      }
    }

    &-hasTemplate {
      border-bottom: 0;

      ::v-deep textarea {
        border-bottom: 0;
        border-radius: 4px 4px 0 0;
      }
    }
  }

  &_errorMessage {
    float: left;
    color: $color-red-500;
  }

  &_counter {
    margin-top: 4px;
    line-height: 1;

    &-invalid {
      color: $color-red-500;
    }

    &-hasTemplate {
      margin: 12px 8px 0 0;
      line-height: 1;
    }
  }
}

// NOTE: IE hack
*::-ms-backdrop,
.control {
  &.-inline-block {
    display: inline-block;
    vertical-align: -12px;
    &.is-small {
      vertical-align: -8px;
    }
    &.is-medium {
      vertical-align: -14px;
    }
    &.is-large {
      vertical-align: -22px;
    }
  }
}
</style>
