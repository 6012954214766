export default async ({ store, redirect, error, app, route }) => {
  try {
    const isOk = await store.dispatch('auth/tryAuth', {
      token: store.state.auth.sToken
    })
    if (isOk) {
      await store.dispatch('auth/user/fetchAll')
    }
  } catch (grpcCode) {
    return error({ grpcCode })
  }

  if (!store.getters['auth/isLoggedIn']) {
    // 未ログインの場合ログイン画面に飛ばす
    return new Promise(resolve => {
      // ローカルでは機能しないが、dev環境では本体と同ドメインなので機能する
      redirect(app.$coconala.loginURL())
      // リダイレクト処理が非同期なため、途中で後続処理である各pageのfetch()が走り、一瞬エラー画面が出てしまうため
      setTimeout(resolve, 10000)
    })
  }

  const businessPath = [
    '/business/settings/corporation',
    '/business/settings/user',
    '/business/settings/invoice'
  ]

  if (
    !store.state.auth.user?.isBusiness &&
    businessPath.includes(route) &&
    !store.state.my.header.privateAccountInfo.isMzhc
  ) {
    return error({
      statusCode: 404,
      message: ''
    })
  }
}
