import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'

export const state = () => ({
  anotherAccountInfo: {}
})

export const getters = {}

export const actions = {
  async fetchAnotherAccountInfo({ commit, rootGetters, rootState }, { anotherToken }) {
    if (!rootGetters['auth/isLoggedIn']) {
      return Promise.reject()
    }
    const requestModule = await import('~/stub/apigateway/header/header_pb')
    const methodModule = await import('~/stub/apigateway/header/header_pb_service')
    const request = new requestModule.GetMyAccountInfoRequest()
    const metadata = commonMetaData(rootState)
    metadata['x-auth-token'] = anotherToken
    const loginSessionKey = rootState.auth.sToken
    const privateSessionKey = rootState.auth.sTokenP
    const businessSessionKey = rootState.auth.sTokenE
    const sessionKeys = []
    if (privateSessionKey) {
      sessionKeys.push(privateSessionKey)
    }
    if (businessSessionKey) {
      sessionKeys.push(businessSessionKey)
    }
    if (sessionKeys.length === 0) {
      sessionKeys.push(loginSessionKey)
    }
    request.setSessionKeysList(sessionKeys)
    const response = await grpcClient({
      method: methodModule.HeaderService.GetMyAccountInfo,
      request,
      metadata
    })
    commit('RECEIVE_ANOTHER_ACCOUNT_INFO', response)
  }
}

export const mutations = {
  RECEIVE_ANOTHER_ACCOUNT_INFO(state, data) {
    if (data.privateAccountInfo) {
      Object.assign(state.anotherAccountInfo, data.privateAccountInfo)
    }
    if (data.businessAccountInfo) {
      Object.assign(state.anotherAccountInfo, data.businessAccountInfo)
    }
  }
}
