// package: campaign
// file: apigateway/campaign/campaign.proto

import * as apigateway_campaign_campaign_pb from "../../apigateway/campaign/campaign_pb";
export class CampaignService {
  static serviceName = "campaign.CampaignService";
}
export namespace CampaignService {
  export class KickCampaign {
    static readonly methodName = "KickCampaign";
    static readonly service = CampaignService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_campaign_campaign_pb.KickCampaignRequest;
    static readonly responseType = apigateway_campaign_campaign_pb.KickCampaignReply;
  }
  export class KickRegistrateUserInterestCategoryCampaign {
    static readonly methodName = "KickRegistrateUserInterestCategoryCampaign";
    static readonly service = CampaignService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_campaign_campaign_pb.KickRegistrateUserInterestCategoryCampaignRequest;
    static readonly responseType = apigateway_campaign_campaign_pb.KickRegistrateUserInterestCategoryCampaignReply;
  }
}
