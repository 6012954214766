import { translateTargetPage } from '~/components/logics/translate/target-page'
import { LANGUAGES, LanguageType, isTranslatableLanguage } from '~/constants/language'
import { Dayjs } from 'dayjs'

export type TranslateUtil = {
  // eslint-disable-next-line no-unused-vars
  prettyNumberForRequest(number: any): string
  isTranslatableTarget(): boolean
  // eslint-disable-next-line no-unused-vars
  multilingualFormat(japaneseFormat?: string, translateFormat?: string): string
  localizeDayjs(dayjs: Dayjs): Dayjs
  getLanguageCode(): LanguageType
  // eslint-disable-next-line no-unused-vars
  isCurrentTranslatePage(): boolean
  showJapaneseOnly(): boolean
  // eslint-disable-next-line no-unused-vars
  multilingualCurrency(price: string): string
  exchangePrice(price: number): number
  exchangePriceUnit(): string
  convertManToYen(price: number): string
}

export default ({ app, store, route }, inject) => {
  const isTranslatableTarget = () => {
    const languageCode = store.state.translate.language
    if (languageCode === LANGUAGES.JA.code) return false
    return isTranslatableLanguage(languageCode)
  }

  const languageCode = store.state.translate.language.toLowerCase()
  require(`dayjs/locale/${languageCode}`)

  const util: TranslateUtil = {
    prettyNumberForRequest(number) {
      if (isTranslatableTarget()) {
        return app.$util.numberWithDelimiter(this.exchangePrice(number))
      }
      // 日本語の場合のみ「万」付き表示にする
      return app.$util.prettyNumberForRequest(number)
    },
    isTranslatableTarget,
    multilingualFormat(japaneseFormat = 'YYYY年MM月DD日', translateFormat = 'YYYY/MM/DD') {
      return isTranslatableTarget() ? translateFormat : japaneseFormat
    },
    localizeDayjs(dayjs) {
      return dayjs.locale(languageCode)
    },
    getLanguageCode() {
      return store.state.translate.language
    },
    isCurrentTranslatePage() {
      return translateTargetPage(route.name)
    },
    showJapaneseOnly() {
      return this.getLanguageCode() === LANGUAGES.JA.code
    },
    multilingualCurrency(price) {
      const rateList = store.state.translate.exchangeRate
      if (!rateList.hasOwnProperty('exchangeRateDomainsList')) {
        return this.isTranslatableTarget()
          ? `${app.$util.numberWithDelimiter(price)} ${app.i18n.t('yen')}`
          : `¥${app.$util.numberWithDelimiter(price)}`
      }
      return `${app.$util.numberWithDelimiter(this.exchangePrice(price))} ${app.i18n.t(
        'exchange_unit'
      )}`
    },
    exchangePrice(price) {
      const rateList = store.state.translate.exchangeRate
      // langCodeが日本語 or 為替レートが取得できなかった場合 or 為替レートの取得APIが成功していてキャッシュがない場合は日本円をそのまま返す
      if (
        !isTranslatableTarget() ||
        !rateList.hasOwnProperty('exchangeRateDomainsList') ||
        rateList.exchangeRateDomainsList.length === 0
      )
        return price
      const langCode = this.getLanguageCode()
      // 基本ありえないが、万が一rateの中身が存在しない場合は1を返し日本円のpriceを戻すようにする
      const rate =
        rateList.exchangeRateDomainsList.find(rate => {
          return rate.langCode === langCode
        })?.rate ?? 1
      return Math.round(price * Number(rate))
    },
    exchangePriceUnit() {
      const rateList = store.state.translate.exchangeRate
      // langCodeが日本語 or 為替レートが取得できなかった場合は$t('yen')で円の翻訳を返す
      if (
        !isTranslatableTarget() ||
        !rateList.hasOwnProperty('exchangeRateDomainsList') ||
        rateList.exchangeRateDomainsList.length === 0
      ) {
        return app.i18n.t('yen')
      }
      return app.i18n.t('exchange_unit')
    },
    // BEから受け取った万円の値を円に変換して単位付きで返す
    convertManToYen(price) {
      if (isTranslatableTarget()) {
        return app.$util.numberWithDelimiter(price * 10000) + app.i18n.t('yen')
      }
      // 日本語の場合カンマつきで値を返す
      return app.$util.numberWithDelimiter(price) + '万円'
    }
  }

  inject('translate', util)
}
