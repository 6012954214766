import { useContext, computed, ref } from '@nuxtjs/composition-api'
import { CookieSerializeOptions } from 'cookie'

// NOTE: useContextを使用しているので、setup関数内でのみ使用可能です。
// ※ クライアントサイドでCookieをセットする場合、safariが1週間しか保持されないので、使用する場合はPJに確認を取ること
export const useCookies = (
  key: string,
  setCookieOption: CookieSerializeOptions = {
    path: '/',
    maxAge: 60 * 60 * 24 * 365 * 10 // 10年
  }
) => {
  const value = ref('')
  const { $cookies } = useContext()
  value.value = $cookies.get(key)
  return {
    // readonly
    cookieValue: computed(() => value.value),
    cookieUpdate: (newValue: string) => {
      $cookies.set(key, newValue, setCookieOption)
      value.value = newValue
    },
    cookieDelete: () => {
      $cookies.remove(key)
      value.value = ''
    }
  }
}
