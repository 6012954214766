// package: transaction_activities
// file: apigateway/transaction_activities/transaction_activities.proto

import * as apigateway_transaction_activities_transaction_activities_pb from "../../apigateway/transaction_activities/transaction_activities_pb";
export class TransactionActivitiesService {
  static serviceName = "transaction_activities.TransactionActivitiesService";
}
export namespace TransactionActivitiesService {
  export class GetTransactions {
    static readonly methodName = "GetTransactions";
    static readonly service = TransactionActivitiesService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_transaction_activities_transaction_activities_pb.GetTransactionsRequest;
    static readonly responseType = apigateway_transaction_activities_transaction_activities_pb.GetTransactionsReply;
  }
  export class UpdateTransaction {
    static readonly methodName = "UpdateTransaction";
    static readonly service = TransactionActivitiesService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_transaction_activities_transaction_activities_pb.UpdateTransactionRequest;
    static readonly responseType = apigateway_transaction_activities_transaction_activities_pb.UpdateTransactionReply;
  }
}
