// package: user
// file: apigateway/user/customize_requester.proto

import * as apigateway_user_customize_requester_pb from "../../apigateway/user/customize_requester_pb";
export class CustomizeRequesterService {
  static serviceName = "user.CustomizeRequesterService";
}
export namespace CustomizeRequesterService {
  export class GetCustomizeRequester {
    static readonly methodName = "GetCustomizeRequester";
    static readonly service = CustomizeRequesterService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_user_customize_requester_pb.GetCustomizeRequesterRequest;
    static readonly responseType = apigateway_user_customize_requester_pb.GetCustomizeRequesterReply;
  }
}
