// source: profileservice/portfolio.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.profileservice.DeletePortfolioReply', null, global);
goog.exportSymbol('proto.profileservice.DeletePortfolioRequest', null, global);
goog.exportSymbol('proto.profileservice.GetPortfolioRequest', null, global);
goog.exportSymbol('proto.profileservice.GetPortfoliosRequest', null, global);
goog.exportSymbol('proto.profileservice.Portfolio', null, global);
goog.exportSymbol('proto.profileservice.PortfolioImage', null, global);
goog.exportSymbol('proto.profileservice.PortfolioReply', null, global);
goog.exportSymbol('proto.profileservice.PortfolioVideo', null, global);
goog.exportSymbol('proto.profileservice.PortfoliosReply', null, global);
goog.exportSymbol('proto.profileservice.UpdatePortfolioImage', null, global);
goog.exportSymbol('proto.profileservice.UpdatePortfolioRequest', null, global);
goog.exportSymbol('proto.profileservice.UpdatePortfolioVideo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.Portfolio = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profileservice.Portfolio.repeatedFields_, null);
};
goog.inherits(proto.profileservice.Portfolio, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.Portfolio.displayName = 'proto.profileservice.Portfolio';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.PortfolioImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.PortfolioImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.PortfolioImage.displayName = 'proto.profileservice.PortfolioImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.PortfolioVideo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.PortfolioVideo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.PortfolioVideo.displayName = 'proto.profileservice.PortfolioVideo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.GetPortfolioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.GetPortfolioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.GetPortfolioRequest.displayName = 'proto.profileservice.GetPortfolioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.GetPortfoliosRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.GetPortfoliosRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.GetPortfoliosRequest.displayName = 'proto.profileservice.GetPortfoliosRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.UpdatePortfolioImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.UpdatePortfolioImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.UpdatePortfolioImage.displayName = 'proto.profileservice.UpdatePortfolioImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.UpdatePortfolioVideo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.UpdatePortfolioVideo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.UpdatePortfolioVideo.displayName = 'proto.profileservice.UpdatePortfolioVideo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.UpdatePortfolioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profileservice.UpdatePortfolioRequest.repeatedFields_, null);
};
goog.inherits(proto.profileservice.UpdatePortfolioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.UpdatePortfolioRequest.displayName = 'proto.profileservice.UpdatePortfolioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.PortfoliosReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.profileservice.PortfoliosReply.repeatedFields_, null);
};
goog.inherits(proto.profileservice.PortfoliosReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.PortfoliosReply.displayName = 'proto.profileservice.PortfoliosReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.PortfolioReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.PortfolioReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.PortfolioReply.displayName = 'proto.profileservice.PortfolioReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.DeletePortfolioRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.DeletePortfolioRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.DeletePortfolioRequest.displayName = 'proto.profileservice.DeletePortfolioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.profileservice.DeletePortfolioReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.profileservice.DeletePortfolioReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.profileservice.DeletePortfolioReply.displayName = 'proto.profileservice.DeletePortfolioReply';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profileservice.Portfolio.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.Portfolio.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.Portfolio.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.Portfolio} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.Portfolio.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    profileId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    workedDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mediaType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    portfolioImage: (f = msg.getPortfolioImage()) && proto.profileservice.PortfolioImage.toObject(includeInstance, f),
    portfolioVideo: (f = msg.getPortfolioVideo()) && proto.profileservice.PortfolioVideo.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.Portfolio}
 */
proto.profileservice.Portfolio.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.Portfolio;
  return proto.profileservice.Portfolio.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.Portfolio} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.Portfolio}
 */
proto.profileservice.Portfolio.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProfileId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkedDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMediaType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = new proto.profileservice.PortfolioImage;
      reader.readMessage(value,proto.profileservice.PortfolioImage.deserializeBinaryFromReader);
      msg.setPortfolioImage(value);
      break;
    case 11:
      var value = new proto.profileservice.PortfolioVideo;
      reader.readMessage(value,proto.profileservice.PortfolioVideo.deserializeBinaryFromReader);
      msg.setPortfolioVideo(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.Portfolio.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.Portfolio.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.Portfolio} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.Portfolio.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getProfileId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWorkedDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMediaType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPortfolioImage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.profileservice.PortfolioImage.serializeBinaryToWriter
    );
  }
  f = message.getPortfolioVideo();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.profileservice.PortfolioVideo.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.Portfolio.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 profile_id = 2;
 * @return {number}
 */
proto.profileservice.Portfolio.prototype.getProfileId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.profileservice.Portfolio.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.profileservice.Portfolio.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 worked_date = 6;
 * @return {number}
 */
proto.profileservice.Portfolio.prototype.getWorkedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setWorkedDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 media_type = 7;
 * @return {number}
 */
proto.profileservice.Portfolio.prototype.getMediaType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setMediaType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.profileservice.Portfolio.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.profileservice.Portfolio.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional PortfolioImage portfolio_image = 10;
 * @return {?proto.profileservice.PortfolioImage}
 */
proto.profileservice.Portfolio.prototype.getPortfolioImage = function() {
  return /** @type{?proto.profileservice.PortfolioImage} */ (
    jspb.Message.getWrapperField(this, proto.profileservice.PortfolioImage, 10));
};


/**
 * @param {?proto.profileservice.PortfolioImage|undefined} value
 * @return {!proto.profileservice.Portfolio} returns this
*/
proto.profileservice.Portfolio.prototype.setPortfolioImage = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.clearPortfolioImage = function() {
  return this.setPortfolioImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profileservice.Portfolio.prototype.hasPortfolioImage = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PortfolioVideo portfolio_video = 11;
 * @return {?proto.profileservice.PortfolioVideo}
 */
proto.profileservice.Portfolio.prototype.getPortfolioVideo = function() {
  return /** @type{?proto.profileservice.PortfolioVideo} */ (
    jspb.Message.getWrapperField(this, proto.profileservice.PortfolioVideo, 11));
};


/**
 * @param {?proto.profileservice.PortfolioVideo|undefined} value
 * @return {!proto.profileservice.Portfolio} returns this
*/
proto.profileservice.Portfolio.prototype.setPortfolioVideo = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.clearPortfolioVideo = function() {
  return this.setPortfolioVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profileservice.Portfolio.prototype.hasPortfolioVideo = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated string tags = 12;
 * @return {!Array<string>}
 */
proto.profileservice.Portfolio.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int32 master_category_id = 13;
 * @return {number}
 */
proto.profileservice.Portfolio.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.Portfolio} returns this
 */
proto.profileservice.Portfolio.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.PortfolioImage.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.PortfolioImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.PortfolioImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.PortfolioImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    portfolioId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    thumbnailUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    detailUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    path: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.PortfolioImage}
 */
proto.profileservice.PortfolioImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.PortfolioImage;
  return proto.profileservice.PortfolioImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.PortfolioImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.PortfolioImage}
 */
proto.profileservice.PortfolioImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPortfolioId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThumbnailUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.PortfolioImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.PortfolioImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.PortfolioImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.PortfolioImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPortfolioId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThumbnailUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDetailUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPath();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.PortfolioImage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioImage} returns this
 */
proto.profileservice.PortfolioImage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 portfolio_id = 2;
 * @return {number}
 */
proto.profileservice.PortfolioImage.prototype.getPortfolioId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioImage} returns this
 */
proto.profileservice.PortfolioImage.prototype.setPortfolioId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.profileservice.PortfolioImage.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.PortfolioImage} returns this
 */
proto.profileservice.PortfolioImage.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thumbnail_url = 4;
 * @return {string}
 */
proto.profileservice.PortfolioImage.prototype.getThumbnailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.PortfolioImage} returns this
 */
proto.profileservice.PortfolioImage.prototype.setThumbnailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string detail_url = 5;
 * @return {string}
 */
proto.profileservice.PortfolioImage.prototype.getDetailUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.PortfolioImage} returns this
 */
proto.profileservice.PortfolioImage.prototype.setDetailUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 created_at = 6;
 * @return {number}
 */
proto.profileservice.PortfolioImage.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioImage} returns this
 */
proto.profileservice.PortfolioImage.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.profileservice.PortfolioImage.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioImage} returns this
 */
proto.profileservice.PortfolioImage.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string path = 8;
 * @return {string}
 */
proto.profileservice.PortfolioImage.prototype.getPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.PortfolioImage} returns this
 */
proto.profileservice.PortfolioImage.prototype.setPath = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.PortfolioVideo.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.PortfolioVideo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.PortfolioVideo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.PortfolioVideo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    portfolioId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    url: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.PortfolioVideo}
 */
proto.profileservice.PortfolioVideo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.PortfolioVideo;
  return proto.profileservice.PortfolioVideo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.PortfolioVideo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.PortfolioVideo}
 */
proto.profileservice.PortfolioVideo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPortfolioId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.PortfolioVideo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.PortfolioVideo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.PortfolioVideo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.PortfolioVideo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPortfolioId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.PortfolioVideo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioVideo} returns this
 */
proto.profileservice.PortfolioVideo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 portfolio_id = 2;
 * @return {number}
 */
proto.profileservice.PortfolioVideo.prototype.getPortfolioId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioVideo} returns this
 */
proto.profileservice.PortfolioVideo.prototype.setPortfolioId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string url = 3;
 * @return {string}
 */
proto.profileservice.PortfolioVideo.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.PortfolioVideo} returns this
 */
proto.profileservice.PortfolioVideo.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.profileservice.PortfolioVideo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioVideo} returns this
 */
proto.profileservice.PortfolioVideo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.profileservice.PortfolioVideo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioVideo} returns this
 */
proto.profileservice.PortfolioVideo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.GetPortfolioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.GetPortfolioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.GetPortfolioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetPortfolioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.GetPortfolioRequest}
 */
proto.profileservice.GetPortfolioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.GetPortfolioRequest;
  return proto.profileservice.GetPortfolioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.GetPortfolioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.GetPortfolioRequest}
 */
proto.profileservice.GetPortfolioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.GetPortfolioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.GetPortfolioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.GetPortfolioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetPortfolioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.GetPortfolioRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetPortfolioRequest} returns this
 */
proto.profileservice.GetPortfolioRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.profileservice.GetPortfolioRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetPortfolioRequest} returns this
 */
proto.profileservice.GetPortfolioRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.GetPortfoliosRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.GetPortfoliosRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.GetPortfoliosRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetPortfoliosRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    per: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.GetPortfoliosRequest}
 */
proto.profileservice.GetPortfoliosRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.GetPortfoliosRequest;
  return proto.profileservice.GetPortfoliosRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.GetPortfoliosRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.GetPortfoliosRequest}
 */
proto.profileservice.GetPortfoliosRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.GetPortfoliosRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.GetPortfoliosRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.GetPortfoliosRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.GetPortfoliosRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFromId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPer();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 user_id = 1;
 * @return {number}
 */
proto.profileservice.GetPortfoliosRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetPortfoliosRequest} returns this
 */
proto.profileservice.GetPortfoliosRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 from_id = 2;
 * @return {number}
 */
proto.profileservice.GetPortfoliosRequest.prototype.getFromId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetPortfoliosRequest} returns this
 */
proto.profileservice.GetPortfoliosRequest.prototype.setFromId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 per = 3;
 * @return {number}
 */
proto.profileservice.GetPortfoliosRequest.prototype.getPer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.GetPortfoliosRequest} returns this
 */
proto.profileservice.GetPortfoliosRequest.prototype.setPer = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.UpdatePortfolioImage.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.UpdatePortfolioImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.UpdatePortfolioImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdatePortfolioImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    imageData: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.UpdatePortfolioImage}
 */
proto.profileservice.UpdatePortfolioImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.UpdatePortfolioImage;
  return proto.profileservice.UpdatePortfolioImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.UpdatePortfolioImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.UpdatePortfolioImage}
 */
proto.profileservice.UpdatePortfolioImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.UpdatePortfolioImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.UpdatePortfolioImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.UpdatePortfolioImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdatePortfolioImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getImageData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.UpdatePortfolioImage.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdatePortfolioImage} returns this
 */
proto.profileservice.UpdatePortfolioImage.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string image_data = 2;
 * @return {string}
 */
proto.profileservice.UpdatePortfolioImage.prototype.getImageData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdatePortfolioImage} returns this
 */
proto.profileservice.UpdatePortfolioImage.prototype.setImageData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.UpdatePortfolioVideo.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.UpdatePortfolioVideo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.UpdatePortfolioVideo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdatePortfolioVideo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.UpdatePortfolioVideo}
 */
proto.profileservice.UpdatePortfolioVideo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.UpdatePortfolioVideo;
  return proto.profileservice.UpdatePortfolioVideo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.UpdatePortfolioVideo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.UpdatePortfolioVideo}
 */
proto.profileservice.UpdatePortfolioVideo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.UpdatePortfolioVideo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.UpdatePortfolioVideo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.UpdatePortfolioVideo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdatePortfolioVideo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.UpdatePortfolioVideo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdatePortfolioVideo} returns this
 */
proto.profileservice.UpdatePortfolioVideo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.profileservice.UpdatePortfolioVideo.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdatePortfolioVideo} returns this
 */
proto.profileservice.UpdatePortfolioVideo.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profileservice.UpdatePortfolioRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.UpdatePortfolioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.UpdatePortfolioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdatePortfolioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    workedDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mediaType: jspb.Message.getFieldWithDefault(msg, 7, 0),
    portfolioImage: (f = msg.getPortfolioImage()) && proto.profileservice.UpdatePortfolioImage.toObject(includeInstance, f),
    portfolioVideo: (f = msg.getPortfolioVideo()) && proto.profileservice.UpdatePortfolioVideo.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.UpdatePortfolioRequest}
 */
proto.profileservice.UpdatePortfolioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.UpdatePortfolioRequest;
  return proto.profileservice.UpdatePortfolioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.UpdatePortfolioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.UpdatePortfolioRequest}
 */
proto.profileservice.UpdatePortfolioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWorkedDate(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMediaType(value);
      break;
    case 8:
      var value = new proto.profileservice.UpdatePortfolioImage;
      reader.readMessage(value,proto.profileservice.UpdatePortfolioImage.deserializeBinaryFromReader);
      msg.setPortfolioImage(value);
      break;
    case 9:
      var value = new proto.profileservice.UpdatePortfolioVideo;
      reader.readMessage(value,proto.profileservice.UpdatePortfolioVideo.deserializeBinaryFromReader);
      msg.setPortfolioVideo(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.UpdatePortfolioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.UpdatePortfolioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.UpdatePortfolioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWorkedDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getMediaType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPortfolioImage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.profileservice.UpdatePortfolioImage.serializeBinaryToWriter
    );
  }
  f = message.getPortfolioVideo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.profileservice.UpdatePortfolioVideo.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 4;
 * @return {string}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 worked_date = 6;
 * @return {number}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getWorkedDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.setWorkedDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 media_type = 7;
 * @return {number}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getMediaType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.setMediaType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional UpdatePortfolioImage portfolio_image = 8;
 * @return {?proto.profileservice.UpdatePortfolioImage}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getPortfolioImage = function() {
  return /** @type{?proto.profileservice.UpdatePortfolioImage} */ (
    jspb.Message.getWrapperField(this, proto.profileservice.UpdatePortfolioImage, 8));
};


/**
 * @param {?proto.profileservice.UpdatePortfolioImage|undefined} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
*/
proto.profileservice.UpdatePortfolioRequest.prototype.setPortfolioImage = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.clearPortfolioImage = function() {
  return this.setPortfolioImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.hasPortfolioImage = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UpdatePortfolioVideo portfolio_video = 9;
 * @return {?proto.profileservice.UpdatePortfolioVideo}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getPortfolioVideo = function() {
  return /** @type{?proto.profileservice.UpdatePortfolioVideo} */ (
    jspb.Message.getWrapperField(this, proto.profileservice.UpdatePortfolioVideo, 9));
};


/**
 * @param {?proto.profileservice.UpdatePortfolioVideo|undefined} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
*/
proto.profileservice.UpdatePortfolioRequest.prototype.setPortfolioVideo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.clearPortfolioVideo = function() {
  return this.setPortfolioVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.hasPortfolioVideo = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated string tags = 10;
 * @return {!Array<string>}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int32 master_category_id = 11;
 * @return {number}
 */
proto.profileservice.UpdatePortfolioRequest.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.UpdatePortfolioRequest} returns this
 */
proto.profileservice.UpdatePortfolioRequest.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.profileservice.PortfoliosReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.PortfoliosReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.PortfoliosReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.PortfoliosReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.PortfoliosReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    portfoliosList: jspb.Message.toObjectList(msg.getPortfoliosList(),
    proto.profileservice.Portfolio.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.PortfoliosReply}
 */
proto.profileservice.PortfoliosReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.PortfoliosReply;
  return proto.profileservice.PortfoliosReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.PortfoliosReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.PortfoliosReply}
 */
proto.profileservice.PortfoliosReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.profileservice.Portfolio;
      reader.readMessage(value,proto.profileservice.Portfolio.deserializeBinaryFromReader);
      msg.addPortfolios(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.PortfoliosReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.PortfoliosReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.PortfoliosReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.PortfoliosReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPortfoliosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.profileservice.Portfolio.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.profileservice.PortfoliosReply.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfoliosReply} returns this
 */
proto.profileservice.PortfoliosReply.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Portfolio portfolios = 2;
 * @return {!Array<!proto.profileservice.Portfolio>}
 */
proto.profileservice.PortfoliosReply.prototype.getPortfoliosList = function() {
  return /** @type{!Array<!proto.profileservice.Portfolio>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.profileservice.Portfolio, 2));
};


/**
 * @param {!Array<!proto.profileservice.Portfolio>} value
 * @return {!proto.profileservice.PortfoliosReply} returns this
*/
proto.profileservice.PortfoliosReply.prototype.setPortfoliosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.profileservice.Portfolio=} opt_value
 * @param {number=} opt_index
 * @return {!proto.profileservice.Portfolio}
 */
proto.profileservice.PortfoliosReply.prototype.addPortfolios = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.profileservice.Portfolio, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.profileservice.PortfoliosReply} returns this
 */
proto.profileservice.PortfoliosReply.prototype.clearPortfoliosList = function() {
  return this.setPortfoliosList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.PortfolioReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.PortfolioReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.PortfolioReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.PortfolioReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    portfolio: (f = msg.getPortfolio()) && proto.profileservice.Portfolio.toObject(includeInstance, f),
    prevId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    nextId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.PortfolioReply}
 */
proto.profileservice.PortfolioReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.PortfolioReply;
  return proto.profileservice.PortfolioReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.PortfolioReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.PortfolioReply}
 */
proto.profileservice.PortfolioReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.profileservice.Portfolio;
      reader.readMessage(value,proto.profileservice.Portfolio.deserializeBinaryFromReader);
      msg.setPortfolio(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrevId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNextId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.PortfolioReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.PortfolioReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.PortfolioReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.PortfolioReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPortfolio();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.profileservice.Portfolio.serializeBinaryToWriter
    );
  }
  f = message.getPrevId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNextId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional Portfolio portfolio = 1;
 * @return {?proto.profileservice.Portfolio}
 */
proto.profileservice.PortfolioReply.prototype.getPortfolio = function() {
  return /** @type{?proto.profileservice.Portfolio} */ (
    jspb.Message.getWrapperField(this, proto.profileservice.Portfolio, 1));
};


/**
 * @param {?proto.profileservice.Portfolio|undefined} value
 * @return {!proto.profileservice.PortfolioReply} returns this
*/
proto.profileservice.PortfolioReply.prototype.setPortfolio = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.profileservice.PortfolioReply} returns this
 */
proto.profileservice.PortfolioReply.prototype.clearPortfolio = function() {
  return this.setPortfolio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.profileservice.PortfolioReply.prototype.hasPortfolio = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 prev_id = 2;
 * @return {number}
 */
proto.profileservice.PortfolioReply.prototype.getPrevId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioReply} returns this
 */
proto.profileservice.PortfolioReply.prototype.setPrevId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 next_id = 3;
 * @return {number}
 */
proto.profileservice.PortfolioReply.prototype.getNextId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.PortfolioReply} returns this
 */
proto.profileservice.PortfolioReply.prototype.setNextId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.DeletePortfolioRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.DeletePortfolioRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.DeletePortfolioRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.DeletePortfolioRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.DeletePortfolioRequest}
 */
proto.profileservice.DeletePortfolioRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.DeletePortfolioRequest;
  return proto.profileservice.DeletePortfolioRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.DeletePortfolioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.DeletePortfolioRequest}
 */
proto.profileservice.DeletePortfolioRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.DeletePortfolioRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.DeletePortfolioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.DeletePortfolioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.DeletePortfolioRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.profileservice.DeletePortfolioRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.DeletePortfolioRequest} returns this
 */
proto.profileservice.DeletePortfolioRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 user_id = 2;
 * @return {number}
 */
proto.profileservice.DeletePortfolioRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.profileservice.DeletePortfolioRequest} returns this
 */
proto.profileservice.DeletePortfolioRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.profileservice.DeletePortfolioReply.prototype.toObject = function(opt_includeInstance) {
  return proto.profileservice.DeletePortfolioReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.profileservice.DeletePortfolioReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.DeletePortfolioReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.profileservice.DeletePortfolioReply}
 */
proto.profileservice.DeletePortfolioReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.profileservice.DeletePortfolioReply;
  return proto.profileservice.DeletePortfolioReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.profileservice.DeletePortfolioReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.profileservice.DeletePortfolioReply}
 */
proto.profileservice.DeletePortfolioReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.profileservice.DeletePortfolioReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.profileservice.DeletePortfolioReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.profileservice.DeletePortfolioReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.profileservice.DeletePortfolioReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.profileservice);
