/** @file URL定数 */

/** アプリストア定義 */
export const APP_STORE_URL = Object.freeze({
  APPLE_STORE_URL: 'https://itunes.apple.com/jp/app/id944884603',
  PLAY_STORE_URL: 'https://play.google.com/store/apps/details?id=com.coconala.android.portal'
})

export const BUSINESS_DOCUMENT_DOWNLOAD_LINK = 'https://coconala.com/r/document_dl'
export const BUSINESS_CONTACT_LINK = 'https://forms.gle/MFQdjZntK2327nn99'
export const BUSINESS_DOCUMENT_DOWNLOAD_LINK_MIZUHO =
  'https://docs.google.com/forms/d/e/1FAIpQLSd-EFL1EFDm-cbdvPos9GddyGDVXdONl8hKqwf0aVz8jdlBiA/viewform'
export const BUSINESS_CONTACT_LINK_MIZUHO =
  'https://docs.google.com/forms/d/e/1FAIpQLSfPSMKHoUjDqYJmW7gt37M0U45F7Xb5s5EYh_6lzKFfiyTgdA/viewform'
export const BUSINESS_REGISTER_LINK_MIZUHO =
  'https://docs.google.com/forms/d/e/1FAIpQLSeurzeseZI5rxdKfdVN-k2ngW0X0l6TrwUVh1_cQZM0J0dViw/viewform'
export const COCONALA_PROFESSIONAL_CONTACT = 'https://forms.gle/nNNsBMGPqnQi7GiTA'

// アプリのネイティブの機能を呼び出す
// SMS認証ダイアログを起動する
export const APP_TRIGGER_SMS_CERTIFICATION = 'coconala://viewscreen/sms'
// シェアダイアログを開く
export const APP_TRIGGER_SHARE = 'coconala://viewscreen/share'

// トークルームの予約日時設定（webview）でAPI完了時にアプリに戻り、ネイティブの成功トーストを表示する
export const getAppTriggerTalkroomsCompleteReservation = (talkroomId: number) =>
  `coconala://viewscreen/talkrooms/${talkroomId}/complete_reservation`

// 発注法人追加フォームのサンクス画面でアプリ側が遷移可能にする
export const APP_TRIGGER_REGISTRATION_BUYER_CORPORATE_COMPLETE =
  'coconala://viewscreen/update_registration_info/buyer/corporate/complete'

// ビデチャ事前申請フォームの画面で申請完了した場合にアプリに遷移します
export const getAppTriggerMessageCompleteVideosApplication = (directMessagesRoomId: number) =>
  `coconala://viewscreen/message/${directMessagesRoomId}/complete_videos_application`
