import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({
  attachmentFilesList: [], // 新形式の添付ファイル
  uploadedFilesList: [] // 旧形式の添付ファイル。
})
// getters
export const getters = {}

// actions
export const actions = {
  async getAttachments({ commit, rootState }, data) {
    const [{ GetAttachmentsRequest }, { OrdermadeRequestService }] = await Promise.all([
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb'),
      import('~/stub/apigateway/ordermade_request/ordermade_request_pb_service')
    ])

    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetAttachmentsRequest()
    request.setId(Number(params.id))
    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(OrdermadeRequestService.GetAttachments, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
    commit('RECEIVE_ATTACHMENT', { attachmentFiles: reply })
  },

  async editAttachments({ commit, rootState }, param) {
    const [{ EditAttachmentRequest, AttachmentFile }, { OrdermadeRequestService }] =
      await Promise.all([
        import('~/stub/apigateway/ordermade_request/ordermade_request_pb'),
        import('~/stub/apigateway/ordermade_request/ordermade_request_pb_service')
      ])

    const metadata = commonMetaData(rootState)
    const requestId = Number(param.id)
    const request = new EditAttachmentRequest()
    const newAttachmentFilesList = param.attachmentFiles.map(file => {
      const attachmentFile = new AttachmentFile()
      attachmentFile.setFileName(file.fileName)
      attachmentFile.setFileUrl(file.fileUrl)
      attachmentFile.setMime(file.mime)
      attachmentFile.setSize(file.size)
      return attachmentFile
    })
    request.setId(requestId)
    request.setAttachmentFilesList(newAttachmentFilesList)
    request.setDeleteAttachmentFileIdsList(param.deleteAttachmentFileIds)
    request.setDeleteUploadedFileIdsList(param.deleteUploadedFileIds)

    return new Promise((resolve, reject) => {
      grpc.invoke(OrdermadeRequestService.EditAttachment, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  },

  async removeAttachmentFile({ commit }, index) {
    commit('REMOVE_ATTACHMENT_FILE', index)
  },
  async removeUploadedFile({ commit }, index) {
    commit('REMOVE_UPLOADED_FILE', index)
  }
}

// mutations
export const mutations = {
  RECEIVE_ATTACHMENT(state, data) {
    Object.assign(state, data)
  },
  REMOVE_ATTACHMENT_FILE(state, index) {
    state.attachmentFiles.attachmentFilesList.splice(index, 1)
  },
  REMOVE_UPLOADED_FILE(state, index) {
    state.attachmentFiles.uploadedFilesList.splice(index, 1)
  }
}
