import Vue, { DirectiveOptions } from 'vue'
import { dynamicTranslate, dynamicTranslateNoCache, KEY_TRANSLATE_OBSERVER_ATTRIBUTE_NAME } from '~/components/logics/translate/translate'
import { translateTargetPage } from '~/components/logics/translate/target-page'

/** 一意なキーを生成 */
const createKey = () => {
  const n = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
  return n.toString(16)
}


// カスタムディレクティブを定義する
Vue.directive('intersect-translate', {
  inserted(el, binding, vnode, oldVnode) {
    if (!translateTargetPage(vnode.context!.$route.name!)) return

    const key = createKey()
    el.setAttribute(KEY_TRANSLATE_OBSERVER_ATTRIBUTE_NAME, key)

    const isTranslate = binding.value?.isTranslate ?? true
    const isNoCache = binding.value?.isNoCache
    const waitUntilFunction = binding.value?.waitUntilFunction
    vnode.data = {
      ...vnode.data,
      on: {
        intersect() {
          if (isTranslate) {
            if (isNoCache) {
              return dynamicTranslateNoCache(el, vnode.context!)
            }
            if (waitUntilFunction && typeof waitUntilFunction === 'function') {
              vnode.context!.$util.waitUntil(waitUntilFunction).then(() => dynamicTranslate(el, vnode.context!))
            } else {
              dynamicTranslate(el, vnode.context!)
            }
          }
        }
      }
    }
    // 他のカスタムディレクティブ(vue-intersection)を呼び出す
    const directiveOptions = vnode.context!.$options.directives!['intersect-on'] as DirectiveOptions
    directiveOptions.inserted!(el, binding, vnode, oldVnode)
  },
  unbind(el, binding, vnode, oldVnode) {
    const directiveOptions = vnode.context!.$options.directives!['intersect-on'] as DirectiveOptions
    try {
      directiveOptions.unbind!(el, binding, vnode, oldVnode)
    } catch {
      // CSR遷移時にエラーが発生することがあるが、遷移したらintersectionは発火しないので問題なし
    }
  }
})
