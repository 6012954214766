import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '@/store/_helpers/common-helper'

// initial state
const initialState = () => ({
  ratingCommentsList: []
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchRatingComments({ commit, rootState }, { userId, page, limit }) {
    const metadata = commonMetaData(rootState)
    const [{ GetRatingCommentsRequest }, { RatingService }] = await Promise.all([
      import('@/stub/apigateway/profile/rating_pb'),
      import('@/stub/apigateway/profile/rating_pb_service')
    ])
    const request = new GetRatingCommentsRequest()
    request.setUserId(userId)
    request.setPage(page)
    request.setLimit(limit)
    const response = await grpcClient({
      method: RatingService.GetRatingComments,
      request,
      metadata
    })
    commit('RECEIVE_RATING_COMMENTS', response)
    return response.ratingCommentsList.length < limit
  }
}

// mutations
export const mutations = {
  // コンポーネントからも使用
  RESET(state) {
    Object.assign(state, initialState())
  },
  RECEIVE_RATING_COMMENTS(state, data) {
    state.ratingCommentsList.splice(state.ratingCommentsList.length, 0, ...data.ratingCommentsList)
  }
}
