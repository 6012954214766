// package: user
// file: apigateway/user/past_estimates_request.proto

import * as apigateway_user_past_estimates_request_pb from "../../apigateway/user/past_estimates_request_pb";
export class PastEstimatesRequestService {
  static serviceName = "user.PastEstimatesRequestService";
}
export namespace PastEstimatesRequestService {
  export class GetPastEstimatesRequest {
    static readonly methodName = "GetPastEstimatesRequest";
    static readonly service = PastEstimatesRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_user_past_estimates_request_pb.GetPastEstimatesRequestRequest;
    static readonly responseType = apigateway_user_past_estimates_request_pb.GetPastEstimatesRequestReply;
  }
}
