import { Middleware } from '@nuxt/types'
import RequestAccessor from '~/api/accessors/request/request-accessor'

const smsAuthenticatedForJobMatching: Middleware = async ({ store, redirect, error }) => {
  try {
    const requestAccessor = new RequestAccessor(store.state)
    const response = await requestAccessor.isAuthorizedRequest()

    const { isAuthorized, reason } = response

    // 公開募集が使用できないかつその理由が「SMS認証未対応」のユーザーはSMS登録ページにリダイレクト
    if (!isAuthorized && !reason?.isAuthenticatedSms) {
      redirect({ path: '/mypage/sms', query: { required_sms_auth: 'job_matching' } })
    }
  } catch (grpcCode) {
    return error({ grpcCode })
  }
}
export default smsAuthenticatedForJobMatching
