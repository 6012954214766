import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'

const STATUS = Object.freeze({
  INVITING: 1,
  ACCEPTED: 2,
  CANCELED: 3,
  RE_INVITATION: 4,
  EXPIRED: 5,
  DENIED: 6,
  MEMBER_DELETED: 7
})

export const state = () => ({
  isRegistered: false,
  invitationEmail: '',
  status: null
})

export const getters = {
  INVITATION_STATUS() {
    return STATUS
  }
}

export const actions = {
  async fetchInvitationStatus({ commit, rootState }, { invitationKey }) {
    const requestModule = await import('~/stub/apigateway/business/business_registration_pb')
    const methodModule = await import('~/stub/apigateway/business/business_registration_pb_service')
    const metadata = commonMetaData(rootState)
    const request = new requestModule.GetInvitationStatusRequest()
    request.setInvitationKey(invitationKey)

    const response = await grpcClient({
      method: methodModule.BusinessRegistrationService.GetInvitationStatus,
      request,
      metadata
    })
    commit('SAVE_INVITATION', response)
  }
}

export const mutations = {
  SAVE_INVITATION(state, data) {
    Object.assign(state, data)
  }
}
