import { LANGUAGES, isTranslatableLanguage } from '~/constants/language'

// initial state
export const state = () => ({
  language: LANGUAGES.JA.code,
  exchangeRate: {}
})

// getters
export const getters = {
  isTranslatableTarget(state) {
    if (state.language === LANGUAGES.JA.code) return false
    return isTranslatableLanguage(state.language)
  }
}

// actions
export const actions = {}

// mutations
export const mutations = {
  SET_LANGUAGE(state, language) {
    state.language = language
  },
  SET_EXCHANGE_RATE(state, exchangeRate) {
    state.exchangeRate = exchangeRate
  }
}
