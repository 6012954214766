import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '@/store/_helpers/common-helper'
import { GetPortfolioRequest, GetPortfoliosRequest } from '@/stub/apigateway/profile/portfolio_pb'
import { PortfolioService } from '@/stub/apigateway/profile/portfolio_pb_service'

// initial state
const initialState = () => ({
  portfoliosList: [],
  totalCount: 0
})

// state
export const state = () => initialState()

// getters
export const getters = {
  hasNotMorePortfolios(state) {
    return state.portfoliosList.length >= state.totalCount
  }
}

// actions
export const actions = {
  async fetchPortfolio({ rootState }, { userId, portfolioId }) {
    const metadata = commonMetaData(rootState)
    const request = new GetPortfolioRequest()
    request.setId(portfolioId)
    request.setUserId(userId)
    const response = await grpcClient({
      method: PortfolioService.GetPortfolio,
      request,
      metadata
    })
    return response
  },
  async fetchPortfolios({ rootState, commit }, params = {}) {
    const metadata = commonMetaData(rootState)
    const request = new GetPortfoliosRequest()
    request.setUserId(rootState.pages.users.user.id)
    request.setPer(params.per)
    const response = await grpcClient({
      method: PortfolioService.GetPortfolios,
      request,
      metadata
    })
    commit('SET', response)
  },
  async appendPortfolios({ rootState, commit }, params = {}) {
    const metadata = commonMetaData(rootState)
    const request = new GetPortfoliosRequest()
    request.setUserId(rootState.pages.users.user.id)
    request.setFromId(params.fromId)
    request.setPer(params.per)
    const response = await grpcClient({
      method: PortfolioService.GetPortfolios,
      request,
      metadata
    })
    commit('APPEND', response)
  }
}

// mutations
export const mutations = {
  SET(state, data) {
    Object.assign(state, data)
  },
  APPEND(state, data) {
    state.totalCount = data.totalCount
    data.portfoliosList.forEach(portfolio => {
      state.portfoliosList.push(portfolio)
    })
  },
  RESET(state) {
    Object.assign(state, initialState())
  }
}
