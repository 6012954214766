// source: apigateway/activities/activities.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.activities.Coupon', null, global);
goog.exportSymbol('proto.activities.DirectMessage', null, global);
goog.exportSymbol('proto.activities.GetDirectMessagesReply', null, global);
goog.exportSymbol('proto.activities.GetDirectMessagesRequest', null, global);
goog.exportSymbol('proto.activities.GetUserHeaderReply', null, global);
goog.exportSymbol('proto.activities.GetUserHeaderRequest', null, global);
goog.exportSymbol('proto.activities.MarkAsReadReply', null, global);
goog.exportSymbol('proto.activities.MarkAsReadRequest', null, global);
goog.exportSymbol('proto.activities.TransactionBadge', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.GetUserHeaderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.activities.GetUserHeaderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.GetUserHeaderRequest.displayName = 'proto.activities.GetUserHeaderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.GetUserHeaderReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.activities.GetUserHeaderReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.GetUserHeaderReply.displayName = 'proto.activities.GetUserHeaderReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.Coupon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.activities.Coupon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.Coupon.displayName = 'proto.activities.Coupon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.TransactionBadge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.activities.TransactionBadge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.TransactionBadge.displayName = 'proto.activities.TransactionBadge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.GetDirectMessagesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.activities.GetDirectMessagesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.GetDirectMessagesRequest.displayName = 'proto.activities.GetDirectMessagesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.GetDirectMessagesReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.activities.GetDirectMessagesReply.repeatedFields_, null);
};
goog.inherits(proto.activities.GetDirectMessagesReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.GetDirectMessagesReply.displayName = 'proto.activities.GetDirectMessagesReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.DirectMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.activities.DirectMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.DirectMessage.displayName = 'proto.activities.DirectMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.MarkAsReadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.activities.MarkAsReadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.MarkAsReadRequest.displayName = 'proto.activities.MarkAsReadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.activities.MarkAsReadReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.activities.MarkAsReadReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.activities.MarkAsReadReply.displayName = 'proto.activities.MarkAsReadReply';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.GetUserHeaderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.GetUserHeaderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.GetUserHeaderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.GetUserHeaderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.GetUserHeaderRequest}
 */
proto.activities.GetUserHeaderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.GetUserHeaderRequest;
  return proto.activities.GetUserHeaderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.GetUserHeaderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.GetUserHeaderRequest}
 */
proto.activities.GetUserHeaderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.GetUserHeaderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.GetUserHeaderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.GetUserHeaderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.GetUserHeaderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.GetUserHeaderReply.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.GetUserHeaderReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.GetUserHeaderReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.GetUserHeaderReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationBadgeFlag: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dmCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    transactionCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    coupon: (f = msg.getCoupon()) && proto.activities.Coupon.toObject(includeInstance, f),
    buyerTransactionBadges: (f = msg.getBuyerTransactionBadges()) && proto.activities.TransactionBadge.toObject(includeInstance, f),
    providerTransactionBadges: (f = msg.getProviderTransactionBadges()) && proto.activities.TransactionBadge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.GetUserHeaderReply}
 */
proto.activities.GetUserHeaderReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.GetUserHeaderReply;
  return proto.activities.GetUserHeaderReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.GetUserHeaderReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.GetUserHeaderReply}
 */
proto.activities.GetUserHeaderReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotificationBadgeFlag(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDmCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTransactionCount(value);
      break;
    case 4:
      var value = new proto.activities.Coupon;
      reader.readMessage(value,proto.activities.Coupon.deserializeBinaryFromReader);
      msg.setCoupon(value);
      break;
    case 5:
      var value = new proto.activities.TransactionBadge;
      reader.readMessage(value,proto.activities.TransactionBadge.deserializeBinaryFromReader);
      msg.setBuyerTransactionBadges(value);
      break;
    case 6:
      var value = new proto.activities.TransactionBadge;
      reader.readMessage(value,proto.activities.TransactionBadge.deserializeBinaryFromReader);
      msg.setProviderTransactionBadges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.GetUserHeaderReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.GetUserHeaderReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.GetUserHeaderReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.GetUserHeaderReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationBadgeFlag();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDmCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTransactionCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCoupon();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.activities.Coupon.serializeBinaryToWriter
    );
  }
  f = message.getBuyerTransactionBadges();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.activities.TransactionBadge.serializeBinaryToWriter
    );
  }
  f = message.getProviderTransactionBadges();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.activities.TransactionBadge.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool notification_badge_flag = 1;
 * @return {boolean}
 */
proto.activities.GetUserHeaderReply.prototype.getNotificationBadgeFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.GetUserHeaderReply} returns this
 */
proto.activities.GetUserHeaderReply.prototype.setNotificationBadgeFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 dm_count = 2;
 * @return {number}
 */
proto.activities.GetUserHeaderReply.prototype.getDmCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.GetUserHeaderReply} returns this
 */
proto.activities.GetUserHeaderReply.prototype.setDmCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 transaction_count = 3;
 * @return {number}
 */
proto.activities.GetUserHeaderReply.prototype.getTransactionCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.GetUserHeaderReply} returns this
 */
proto.activities.GetUserHeaderReply.prototype.setTransactionCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Coupon coupon = 4;
 * @return {?proto.activities.Coupon}
 */
proto.activities.GetUserHeaderReply.prototype.getCoupon = function() {
  return /** @type{?proto.activities.Coupon} */ (
    jspb.Message.getWrapperField(this, proto.activities.Coupon, 4));
};


/**
 * @param {?proto.activities.Coupon|undefined} value
 * @return {!proto.activities.GetUserHeaderReply} returns this
*/
proto.activities.GetUserHeaderReply.prototype.setCoupon = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.activities.GetUserHeaderReply} returns this
 */
proto.activities.GetUserHeaderReply.prototype.clearCoupon = function() {
  return this.setCoupon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.activities.GetUserHeaderReply.prototype.hasCoupon = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TransactionBadge buyer_transaction_badges = 5;
 * @return {?proto.activities.TransactionBadge}
 */
proto.activities.GetUserHeaderReply.prototype.getBuyerTransactionBadges = function() {
  return /** @type{?proto.activities.TransactionBadge} */ (
    jspb.Message.getWrapperField(this, proto.activities.TransactionBadge, 5));
};


/**
 * @param {?proto.activities.TransactionBadge|undefined} value
 * @return {!proto.activities.GetUserHeaderReply} returns this
*/
proto.activities.GetUserHeaderReply.prototype.setBuyerTransactionBadges = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.activities.GetUserHeaderReply} returns this
 */
proto.activities.GetUserHeaderReply.prototype.clearBuyerTransactionBadges = function() {
  return this.setBuyerTransactionBadges(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.activities.GetUserHeaderReply.prototype.hasBuyerTransactionBadges = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TransactionBadge provider_transaction_badges = 6;
 * @return {?proto.activities.TransactionBadge}
 */
proto.activities.GetUserHeaderReply.prototype.getProviderTransactionBadges = function() {
  return /** @type{?proto.activities.TransactionBadge} */ (
    jspb.Message.getWrapperField(this, proto.activities.TransactionBadge, 6));
};


/**
 * @param {?proto.activities.TransactionBadge|undefined} value
 * @return {!proto.activities.GetUserHeaderReply} returns this
*/
proto.activities.GetUserHeaderReply.prototype.setProviderTransactionBadges = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.activities.GetUserHeaderReply} returns this
 */
proto.activities.GetUserHeaderReply.prototype.clearProviderTransactionBadges = function() {
  return this.setProviderTransactionBadges(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.activities.GetUserHeaderReply.prototype.hasProviderTransactionBadges = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.Coupon.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.Coupon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.Coupon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.Coupon.toObject = function(includeInstance, msg) {
  var f, obj = {
    isExpiring: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    discount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    discountType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    expireDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isMasterCategoryLimitCoupon: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.Coupon}
 */
proto.activities.Coupon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.Coupon;
  return proto.activities.Coupon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.Coupon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.Coupon}
 */
proto.activities.Coupon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpiring(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscountType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpireDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMasterCategoryLimitCoupon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.Coupon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.Coupon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.Coupon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.Coupon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsExpiring();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDiscountType();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getExpireDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsMasterCategoryLimitCoupon();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional bool is_expiring = 1;
 * @return {boolean}
 */
proto.activities.Coupon.prototype.getIsExpiring = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.Coupon} returns this
 */
proto.activities.Coupon.prototype.setIsExpiring = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 discount = 2;
 * @return {number}
 */
proto.activities.Coupon.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.Coupon} returns this
 */
proto.activities.Coupon.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 discount_type = 3;
 * @return {number}
 */
proto.activities.Coupon.prototype.getDiscountType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.Coupon} returns this
 */
proto.activities.Coupon.prototype.setDiscountType = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 expire_date = 4;
 * @return {number}
 */
proto.activities.Coupon.prototype.getExpireDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.Coupon} returns this
 */
proto.activities.Coupon.prototype.setExpireDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string display_name = 5;
 * @return {string}
 */
proto.activities.Coupon.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.activities.Coupon} returns this
 */
proto.activities.Coupon.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.activities.Coupon.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.activities.Coupon} returns this
 */
proto.activities.Coupon.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_master_category_limit_coupon = 7;
 * @return {boolean}
 */
proto.activities.Coupon.prototype.getIsMasterCategoryLimitCoupon = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.Coupon} returns this
 */
proto.activities.Coupon.prototype.setIsMasterCategoryLimitCoupon = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.TransactionBadge.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.TransactionBadge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.TransactionBadge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.TransactionBadge.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionRequiredEstimateCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    actionRequiredTalkroomCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actionRequiredDigest: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hasUnreadTradingTalkroom: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasUnreadCancelTalkroom: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    hasUnreadCompleteTalkroom: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.TransactionBadge}
 */
proto.activities.TransactionBadge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.TransactionBadge;
  return proto.activities.TransactionBadge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.TransactionBadge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.TransactionBadge}
 */
proto.activities.TransactionBadge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActionRequiredEstimateCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActionRequiredTalkroomCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActionRequiredDigest(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadTradingTalkroom(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadCancelTalkroom(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnreadCompleteTalkroom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.TransactionBadge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.TransactionBadge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.TransactionBadge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.TransactionBadge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionRequiredEstimateCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getActionRequiredTalkroomCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActionRequiredDigest();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHasUnreadTradingTalkroom();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasUnreadCancelTalkroom();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getHasUnreadCompleteTalkroom();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 action_required_estimate_count = 1;
 * @return {number}
 */
proto.activities.TransactionBadge.prototype.getActionRequiredEstimateCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.TransactionBadge} returns this
 */
proto.activities.TransactionBadge.prototype.setActionRequiredEstimateCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 action_required_talkroom_count = 2;
 * @return {number}
 */
proto.activities.TransactionBadge.prototype.getActionRequiredTalkroomCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.TransactionBadge} returns this
 */
proto.activities.TransactionBadge.prototype.setActionRequiredTalkroomCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 action_required_digest = 3;
 * @return {number}
 */
proto.activities.TransactionBadge.prototype.getActionRequiredDigest = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.TransactionBadge} returns this
 */
proto.activities.TransactionBadge.prototype.setActionRequiredDigest = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool has_unread_trading_talkroom = 4;
 * @return {boolean}
 */
proto.activities.TransactionBadge.prototype.getHasUnreadTradingTalkroom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.TransactionBadge} returns this
 */
proto.activities.TransactionBadge.prototype.setHasUnreadTradingTalkroom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_unread_cancel_talkroom = 5;
 * @return {boolean}
 */
proto.activities.TransactionBadge.prototype.getHasUnreadCancelTalkroom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.TransactionBadge} returns this
 */
proto.activities.TransactionBadge.prototype.setHasUnreadCancelTalkroom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool has_unread_complete_talkroom = 6;
 * @return {boolean}
 */
proto.activities.TransactionBadge.prototype.getHasUnreadCompleteTalkroom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.TransactionBadge} returns this
 */
proto.activities.TransactionBadge.prototype.setHasUnreadCompleteTalkroom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.GetDirectMessagesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.GetDirectMessagesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.GetDirectMessagesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.GetDirectMessagesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    iconSize: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.GetDirectMessagesRequest}
 */
proto.activities.GetDirectMessagesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.GetDirectMessagesRequest;
  return proto.activities.GetDirectMessagesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.GetDirectMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.GetDirectMessagesRequest}
 */
proto.activities.GetDirectMessagesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.GetDirectMessagesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.GetDirectMessagesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.GetDirectMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.GetDirectMessagesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getIconSize();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.activities.GetDirectMessagesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.GetDirectMessagesRequest} returns this
 */
proto.activities.GetDirectMessagesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.activities.GetDirectMessagesRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.GetDirectMessagesRequest} returns this
 */
proto.activities.GetDirectMessagesRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string icon_size = 3;
 * @return {string}
 */
proto.activities.GetDirectMessagesRequest.prototype.getIconSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.activities.GetDirectMessagesRequest} returns this
 */
proto.activities.GetDirectMessagesRequest.prototype.setIconSize = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.activities.GetDirectMessagesReply.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.GetDirectMessagesReply.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.GetDirectMessagesReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.GetDirectMessagesReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.GetDirectMessagesReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    directMessagesList: jspb.Message.toObjectList(msg.getDirectMessagesList(),
    proto.activities.DirectMessage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.GetDirectMessagesReply}
 */
proto.activities.GetDirectMessagesReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.GetDirectMessagesReply;
  return proto.activities.GetDirectMessagesReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.GetDirectMessagesReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.GetDirectMessagesReply}
 */
proto.activities.GetDirectMessagesReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    case 2:
      var value = new proto.activities.DirectMessage;
      reader.readMessage(value,proto.activities.DirectMessage.deserializeBinaryFromReader);
      msg.addDirectMessages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.GetDirectMessagesReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.GetDirectMessagesReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.GetDirectMessagesReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.GetDirectMessagesReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDirectMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.activities.DirectMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 total_count = 1;
 * @return {number}
 */
proto.activities.GetDirectMessagesReply.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.GetDirectMessagesReply} returns this
 */
proto.activities.GetDirectMessagesReply.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated DirectMessage direct_messages = 2;
 * @return {!Array<!proto.activities.DirectMessage>}
 */
proto.activities.GetDirectMessagesReply.prototype.getDirectMessagesList = function() {
  return /** @type{!Array<!proto.activities.DirectMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.activities.DirectMessage, 2));
};


/**
 * @param {!Array<!proto.activities.DirectMessage>} value
 * @return {!proto.activities.GetDirectMessagesReply} returns this
*/
proto.activities.GetDirectMessagesReply.prototype.setDirectMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.activities.DirectMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.activities.DirectMessage}
 */
proto.activities.GetDirectMessagesReply.prototype.addDirectMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.activities.DirectMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.activities.GetDirectMessagesReply} returns this
 */
proto.activities.GetDirectMessagesReply.prototype.clearDirectMessagesList = function() {
  return this.setDirectMessagesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.DirectMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.DirectMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.DirectMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.DirectMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    directMessagesRoomId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fromUserName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromUserIcon: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fromUserIconResizable: jspb.Message.getFieldWithDefault(msg, 5, ""),
    body: jspb.Message.getFieldWithDefault(msg, 6, ""),
    opened: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    unreadCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    hasCustomize: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    fromUserIsBusiness: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.DirectMessage}
 */
proto.activities.DirectMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.DirectMessage;
  return proto.activities.DirectMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.DirectMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.DirectMessage}
 */
proto.activities.DirectMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDirectMessagesRoomId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserIcon(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromUserIconResizable(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpened(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnreadCount(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCustomize(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFromUserIsBusiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.DirectMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.DirectMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.DirectMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.DirectMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDirectMessagesRoomId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFromUserId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFromUserName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromUserIcon();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFromUserIconResizable();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOpened();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUnreadCount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getHasCustomize();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getFromUserIsBusiness();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int32 direct_messages_room_id = 1;
 * @return {number}
 */
proto.activities.DirectMessage.prototype.getDirectMessagesRoomId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setDirectMessagesRoomId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 from_user_id = 2;
 * @return {number}
 */
proto.activities.DirectMessage.prototype.getFromUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setFromUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string from_user_name = 3;
 * @return {string}
 */
proto.activities.DirectMessage.prototype.getFromUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setFromUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string from_user_icon = 4;
 * @return {string}
 */
proto.activities.DirectMessage.prototype.getFromUserIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setFromUserIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string from_user_icon_resizable = 5;
 * @return {string}
 */
proto.activities.DirectMessage.prototype.getFromUserIconResizable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setFromUserIconResizable = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string body = 6;
 * @return {string}
 */
proto.activities.DirectMessage.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool opened = 7;
 * @return {boolean}
 */
proto.activities.DirectMessage.prototype.getOpened = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setOpened = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.activities.DirectMessage.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 unread_count = 9;
 * @return {number}
 */
proto.activities.DirectMessage.prototype.getUnreadCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setUnreadCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool has_customize = 10;
 * @return {boolean}
 */
proto.activities.DirectMessage.prototype.getHasCustomize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setHasCustomize = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool from_user_is_business = 11;
 * @return {boolean}
 */
proto.activities.DirectMessage.prototype.getFromUserIsBusiness = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.activities.DirectMessage} returns this
 */
proto.activities.DirectMessage.prototype.setFromUserIsBusiness = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.MarkAsReadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.MarkAsReadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.MarkAsReadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.MarkAsReadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    activityId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.MarkAsReadRequest}
 */
proto.activities.MarkAsReadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.MarkAsReadRequest;
  return proto.activities.MarkAsReadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.MarkAsReadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.MarkAsReadRequest}
 */
proto.activities.MarkAsReadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActivityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.MarkAsReadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.MarkAsReadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.MarkAsReadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.MarkAsReadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivityId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 activity_id = 1;
 * @return {number}
 */
proto.activities.MarkAsReadRequest.prototype.getActivityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.activities.MarkAsReadRequest} returns this
 */
proto.activities.MarkAsReadRequest.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.activities.MarkAsReadReply.prototype.toObject = function(opt_includeInstance) {
  return proto.activities.MarkAsReadReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.activities.MarkAsReadReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.MarkAsReadReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.activities.MarkAsReadReply}
 */
proto.activities.MarkAsReadReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.activities.MarkAsReadReply;
  return proto.activities.MarkAsReadReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.activities.MarkAsReadReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.activities.MarkAsReadReply}
 */
proto.activities.MarkAsReadReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.activities.MarkAsReadReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.activities.MarkAsReadReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.activities.MarkAsReadReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.activities.MarkAsReadReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.activities);
