// package: profile
// file: apigateway/profile/received_favorite.proto

import * as apigateway_profile_received_favorite_pb from "../../apigateway/profile/received_favorite_pb";
import * as profileservice_received_favorite_pb from "../../profileservice/received_favorite_pb";
export class ReceivedFavoriteService {
  static serviceName = "profile.ReceivedFavoriteService";
}
export namespace ReceivedFavoriteService {
  export class GetReceivedFavoriteBlogs {
    static readonly methodName = "GetReceivedFavoriteBlogs";
    static readonly service = ReceivedFavoriteService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_received_favorite_pb.GetReceivedFavoriteBlogsRequest;
    static readonly responseType = apigateway_profile_received_favorite_pb.GetReceivedFavoriteBlogsReply;
  }
}
