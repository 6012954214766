// state
export const state = () => ({
  metrics: []
})

// getters
export const getters = {}

// actions
export const actions = {
  pushMetric({ commit }, metric) {
    let deviceMetric = {
      cpu: navigator.hardwareConcurrency,
      memory: navigator.deviceMemory
    }

    if (navigator.connection) {
      const networkMetric = {
        network_type: navigator.connection.type,
        network_rtt: navigator.connection.rtt,
        network_downlink: navigator.connection.downlink,
        network_downlink_max: navigator.connection.downlinkMax,
        network_effective_type: navigator.connection.effectiveType,
        network_save_data: navigator.connection.saveData
      }

      deviceMetric = { ...deviceMetric, ...networkMetric }
    }

    const metricParam = { ...metric, ...deviceMetric }
    commit('PUSH_METRIC', metricParam)
  },
  clearMetric({ commit }) {
    commit('CLEAR_METRIC')
  }
}

// mutations
export const mutations = {
  PUSH_METRIC(state, metric) {
    state.metrics.push(metric)
  },
  CLEAR_METRIC(state) {
    state.metrics.length = 0
  }
}
