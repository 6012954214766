import 'intersection-observer'
import Vue, { VNode } from 'vue'

const observers: {[key: string]: IntersectionObserver} = {}

const KEY_ATTRIBUTE_NAME = 'data-intersection-observer-key'

/** 一意なキーを生成 */
const createKey = () => {
  const n = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
  return n.toString(16)
}

/**
 * イベント強制発火
 * @param vnode イベントターゲット
 * @param eventName イベント名
 * @param data イベント発火時のハンドラ引数
 */
const emit = <T>(vnode: VNode, eventName: string, data: T) => {
  // @ts-ignore TS2532
  const handlers = vnode.data.on
  if (handlers && handlers.hasOwnProperty(eventName)) {
    var handler = handlers[eventName];
    var fn = handler

    if (typeof fn === 'function') {
      fn(data)
    } else [
      fn.forEach(f => f(data))
    ]
  }
} 

Vue.directive('intersect-on', {
  inserted(el, binding, vnode) {
    const key = createKey()
    const { once, out, each } = binding.modifiers
    el.setAttribute(KEY_ATTRIBUTE_NAME, key)
    const observerOptions: IntersectionObserverInit = typeof binding.value === 'object' ? binding.value : {}
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        /** イベント発火フラグ */
        const isFiring = each || 
          ((out || entry.isIntersecting) && !(out && entry.isIntersecting))
        if (isFiring && entry.target === el) {
          emit(vnode, 'intersect', entry)
          if (once) {
            observer.disconnect()
            delete observers[key]
            el.removeAttribute(KEY_ATTRIBUTE_NAME)
          }
        }
      })
    }, observerOptions)
    observers[key] = observer
    observer.observe(el)
  },
  unbind(el) {
    const key = el.dataset?.intersectionObserverKey || el.getAttribute(KEY_ATTRIBUTE_NAME)
    // @ts-ignore TS2538
    observers[key]?.disconnect()
    // @ts-ignore TS2538
    delete observers[key]
  }
})
