// package: profile
// file: apigateway/profile/recommend_blog.proto

import * as apigateway_profile_recommend_blog_pb from "../../apigateway/profile/recommend_blog_pb";
import * as profileservice_recommend_blog_pb from "../../profileservice/recommend_blog_pb";
export class RecommendBlogService {
  static serviceName = "profile.RecommendBlogService";
}
export namespace RecommendBlogService {
  export class GetRecommendBlogs {
    static readonly methodName = "GetRecommendBlogs";
    static readonly service = RecommendBlogService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_recommend_blog_pb.GetRecommendBlogsRequest;
    static readonly responseType = apigateway_profile_recommend_blog_pb.RecommendBlogsReply;
  }
}
