// package: enterprise
// file: apigateway/enterprise/invitation.proto

import * as apigateway_enterprise_invitation_pb from "../../apigateway/enterprise/invitation_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
export class InvitationService {
  static serviceName = "enterprise.InvitationService";
}
export namespace InvitationService {
  export class GetInvitations {
    static readonly methodName = "GetInvitations";
    static readonly service = InvitationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_enterprise_invitation_pb.GetInvitationsRequest;
    static readonly responseType = apigateway_enterprise_invitation_pb.GetInvitationsReply;
  }
  export class AddInvitation {
    static readonly methodName = "AddInvitation";
    static readonly service = InvitationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_enterprise_invitation_pb.AddInvitationRequest;
    static readonly responseType = apigateway_enterprise_invitation_pb.AddInvitationReply;
  }
  export class ReSendInvitation {
    static readonly methodName = "ReSendInvitation";
    static readonly service = InvitationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_enterprise_invitation_pb.ReSendInvitationRequest;
    static readonly responseType = apigateway_enterprise_invitation_pb.ReSendInvitationReply;
  }
  export class CancelInvitation {
    static readonly methodName = "CancelInvitation";
    static readonly service = InvitationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_enterprise_invitation_pb.CancelInvitationRequest;
    static readonly responseType = apigateway_enterprise_invitation_pb.CancelInvitationReply;
  }
}
