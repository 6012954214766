import { Middleware } from '@nuxt/types'

/**
 * Mzhcアカウントのときトップにリダイレクト
 */
const redirectToTopIfMzhcAccount: Middleware = ({ store, redirect }) => {
  if (store.state.my.header.privateAccountInfo.isMzhc) {
    redirect({ path: '/', replace: true })
  }
}
export default redirectToTopIfMzhcAccount
