import { commonMetaData } from '~/store/_helpers/common-helper'

/** GrpcAccessorの基底クラス */
class GrpcAccessorBase {
  private readonly rootState
  // @ts-ignore TS2564
  private fullPath: string

  constructor(rootState) {
    this.rootState = rootState
  }

  setFullPath(fullPath: string) {
    this.fullPath = fullPath
  }

  /** gRPCリクエスト時に渡すヘッダ情報を取得 */
  getMetadata(): ReturnType<typeof commonMetaData> & { 'x-full-path'?: any[] } {
    const metadata = commonMetaData(this.rootState)
    if (this.fullPath) {
      metadata['x-full-path'] = [this.fullPath]
    }
    return metadata
  }
}
export default GrpcAccessorBase
