import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '~/store/_helpers/common-helper'
import { grpc } from '@improbable-eng/grpc-web'
import {
  EntryCampaignEventRequest,
  GetCampaignEventRequest,
  GetEntryStatusRequest
} from '~/stub/apigateway/campaign_event/campaign_event_pb'
import { CampaignEventService } from '~/stub/apigateway/campaign_event/campaign_event_pb_service'

// initial state
export const state = () => ({
  alreadyEntried: false,
  isEntryDone: false,
  isEntryClose: false,
  campaignEvent: {},
  result: ''
})

// getters
export const getters = {}

// actions
export const actions = {
  async entryCampaignEvent({ commit, rootState }, { campaignEventId }) {
    const request = new EntryCampaignEventRequest()
    request.setId(campaignEventId)
    const metadata = commonMetaData(rootState)

    const response = await grpcClient({
      method: CampaignEventService.EntryCampaignEvent,
      request,
      metadata
    })
    commit('RECIEVE_ENTRY_RESULT', response)
  },
  async getCampaignEvent({ commit, rootState }, { params, query, type = 'all' }) {
    const request = new GetCampaignEventRequest()
    request.setId(params.campaignEventId)
    const metadata = commonMetaData(rootState)

    const response = await grpcClient({
      method: CampaignEventService.GetCampaignEvent,
      request,
      metadata
    })
    commit('RECIEVE_CAMPAIGN_EVENT', response)
    return response
  },
  async getEntryStatus({ commit, rootState }, { params, query, type = 'all' }) {
    const request = new GetEntryStatusRequest()
    request.setId(params.campaignEventId)
    const metadata = commonMetaData(rootState)

    try {
      const response = await grpcClient({
        method: CampaignEventService.GetEntryStatus,
        request,
        metadata
      })
      commit('RECIEVE_CAMPAIGN_ENTRY_STATUS', response)
    } catch ({ grpcCode }) {
      if (grpcCode === grpc.Code.Unauthenticated) {
        return Promise.resolve(grpcCode)
      }
      return Promise.reject(grpcCode)
    }
  }
}

// mutations
export const mutations = {
  RECIEVE_CAMPAIGN_EVENT(state, data) {
    Object.assign(state.campaignEvent, data.campaignEvent)
  },
  RECIEVE_CAMPAIGN_ENTRY_STATUS(state, data) {
    state.alreadyEntried = data.alreadyEntried
  },
  RECIEVE_ENTRY_RESULT(state, data) {
    if (data.result === 'entry_done') {
      state.isEntryDone = true
    } else if (data.result === 'entry_disable' || data.result === 'close') {
      state.isEntryClose = true
    } else if (data.result === 'already_entried') {
      state.alreadyEntried = true
    }
    state.result = data.result
  }
}
