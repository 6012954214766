import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { GetBlogCategoriesRequest } from '~/stub/apigateway/profile/blog_category_pb'
import { BlogCategoryService } from '~/stub/apigateway/profile/blog_category_pb_service'
import { GetBlogTagsRequest } from '~/stub/apigateway/profile/blog_tag_pb'
import { BlogTagService } from '~/stub/apigateway/profile/blog_tag_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '~/grpc/grpc-client'

// initial state
const initialState = () => ({
  blogCategoriesList: [],
  pricesList: []
})

export const state = () => initialState()

// getters
export const getters = {
  blogCategoryNameById: state => id => {
    const category = state.blogCategoriesList.find(c => c.id === id)
    return category ? category.name : null
  }
}

// actions
export const actions = {
  async fetchBlogCategories({ commit, rootState }) {
    const cache = this.app.context.ssrContext?.$caches?.master || null
    const request = new GetBlogCategoriesRequest()
    const metadata = commonMetaData(rootState)
    const response = await grpcClient({
      method: BlogCategoryService.GetBlogCategories,
      request,
      metadata,
      cache
    })
    commit('RECEIVE_BLOG_CATEGORIES', response)
  },
  async fetchBlogTags({ rootState }, name) {
    return new Promise((resolve, reject) => {
      const request = new GetBlogTagsRequest()
      request.setName(name)
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogTagService.GetBlogTags, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  },
  async fetchBlogPrices({ commit, rootState }, kind) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBlogPriceListRequest }, { MasterService }] = await Promise.all([
        import('~/stub/apigateway/profile/master_pb'),
        import('~/stub/apigateway/profile/master_pb_service')
      ])
      const request = new GetBlogPriceListRequest()
      request.setKind(kind)
      const metadata = commonMetaData(rootState)
      grpc.invoke(MasterService.GetBlogPriceList, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
    commit('RECEIVE_BLOG_PRICES', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_BLOG_CATEGORIES(state, data) {
    Object.assign(state, {
      ...data
    })
  },
  RECEIVE_BLOG_PRICES(state, data) {
    Object.assign(state, {
      ...data
    })
  }
}
