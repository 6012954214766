import { Middleware } from '@nuxt/types'

/**
 * 登録済みユーザーをトップにリダイレクト（エントリーフォーム用）
 */
const redirectToTopIfRegistered: Middleware = ({ store, redirect }) => {
  if (store.state.auth.user.hasFinishedEntryForm) {
    redirect({ path: '/', replace: true })
  }
}
export default redirectToTopIfRegistered
