// package: received_favorite
// file: apigateway/received_favorite/received_favorite.proto

import * as apigateway_received_favorite_received_favorite_pb from "../../apigateway/received_favorite/received_favorite_pb";
export class ReceivedFavoriteService {
  static serviceName = "received_favorite.ReceivedFavoriteService";
}
export namespace ReceivedFavoriteService {
  export class GetServices {
    static readonly methodName = "GetServices";
    static readonly service = ReceivedFavoriteService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_received_favorite_received_favorite_pb.GetServicesRequest;
    static readonly responseType = apigateway_received_favorite_received_favorite_pb.GetServicesReply;
  }
  export class GetUsers {
    static readonly methodName = "GetUsers";
    static readonly service = ReceivedFavoriteService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_received_favorite_received_favorite_pb.GetUsersRequest;
    static readonly responseType = apigateway_received_favorite_received_favorite_pb.GetUsersReply;
  }
}
