<template>
  <a
    class="c-buttonV2"
    :class="classes"
    :href="href"
    :target="target"
    :style="styles"
    v-on="$listeners"
    v-if="href"
    @click="checkLoggedIn"
  >
    <coconala-icon
      :name="iconName"
      :size="iconSize"
      v-if="iconName && iconPosition !== 'after'"
    ></coconala-icon>
    <span v-if="$slots.default">
      <slot></slot>
    </span>
    <coconala-icon
      :name="iconName"
      :size="iconSize"
      v-if="iconName && iconPosition === 'after'"
    ></coconala-icon>
  </a>
  <button
    class="c-buttonV2"
    :class="classes"
    :style="styles"
    v-on="$listeners"
    v-else
    @click="checkLoggedIn"
  >
    <coconala-icon
      :name="iconName"
      :size="iconSize"
      v-if="iconName && iconPosition !== 'after'"
    ></coconala-icon>
    <span v-if="$slots.default">
      <slot></slot>
    </span>
    <coconala-icon
      :name="iconName"
      :size="iconSize"
      v-if="iconName && iconPosition === 'after'"
    ></coconala-icon>
  </button>
</template>
<script>
// NOTE: atomsなので本当は依存してはいけないが例外とする
import CoconalaIcon from '~/components/atoms/CoconalaIcon'

export const BUTTON_COLORS = Object.freeze([
  'primary',
  'secondary',
  'thirdly',
  'fourthly',
  'primary-blue',
  'secondary-blue'
])
export const BUTTON_SIZES = Object.freeze(['ll', 'l', 'm', 'ms', 's', 'ss'])
export const ICON_POSITIONS = Object.freeze(['before', 'after'])

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CButtonV2',
  components: { CoconalaIcon },
  props: {
    color: {
      type: String,
      validator(v) {
        return BUTTON_COLORS.includes(v)
      }
    },
    size: {
      type: String,
      validator(v) {
        return BUTTON_SIZES.includes(v)
      }
    },
    width: {
      type: String,
      default: 'auto'
    },
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: '_self'
    },
    iconName: {
      type: String
    },
    iconSize: {
      type: String
    },
    iconPosition: {
      type: String,
      default: ICON_POSITIONS[0],
      validator(v) {
        return ICON_POSITIONS.includes(v)
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noShadow: {
      type: Boolean,
      default: false
    },
    needLoggedIn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    classes() {
      let classes = {}
      if (this.color) classes[`is-${this.color}`] = this.color
      if (this.size) classes[`is-${this.size}`] = this.size
      if (this.disabled) classes['disabled'] = 'disabled'
      if (this.noShadow) classes['no-shadow'] = 'no-shadow'
      return classes
    },
    styles() {
      return {
        width: this.width
      }
    }
  },
  methods: {
    ...mapActions('ui', ['showLoginModal']),
    checkLoggedIn(e) {
      if (this.needLoggedIn && !this.isLoggedIn) {
        e.preventDefault()
        this.showLoginModal()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-buttonV2 {
  @include link-effect;

  display: inline-block;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;

  -webkit-appearance: none;

  @mixin c-btn-size($height, $font-size) {
    padding: 0 $font-size;
    height: $height;
    font-size: $font-size;
    line-height: $height - 2px;

    &.no-shadow {
      line-height: $height;
    }
  }

  &.is-ll {
    @include c-btn-size(60px, 20px);
  }

  &.is-l {
    @include c-btn-size(54px, 18px);
  }

  &.is-m {
    @include c-btn-size(44px, 14px);
  }

  &.is-ms {
    @include c-btn-size(32px, 13px);
  }

  &.is-s {
    @include c-btn-size(26px, 12px);
  }

  &.is-ss {
    @include c-btn-size(20px, 10px);
  }

  &.is-primary {
    border-color: $ds2-color-brand-primary-600;
    background-color: $ds2-color-brand-primary-600;
    color: $color-white;
  }

  &.is-secondary {
    border-color: $ds2-color-brand-primary;
    background-color: $color-white;
    color: $ds2-color-brand-primary-600;

    &.disabled {
      border-color: $ds2-color-gray-100;
      background-color: $ds2-color-gray-100;
    }
  }

  &.is-thirdly {
    border-color: $ds2-color-gray-200;
    background-color: $color-white;
    color: $ds2-color-gray-900;
    font-weight: normal;

    &.disabled {
      border-color: $ds2-color-gray-100;
      background-color: $ds2-color-gray-100;
    }
  }

  &.is-fourthly {
    border-color: $ds2-color-gray-200;
    background-color: $ds2-color-gray-50;
    color: $ds2-color-gray-900;
    font-weight: normal;

    &.disabled {
      border-color: $ds2-color-gray-100;
      background-color: $ds2-color-gray-100;
      color: $color-white;
    }
  }

  &.is-primary-blue {
    border-color: $ds2-color-brand-secondary-700;
    background-color: $ds2-color-brand-secondary-700;
    color: $color-white;
  }

  &.is-secondary-blue {
    border-color: $ds2-color-brand-secondary-700;
    background-color: $color-white;
    color: $ds2-color-brand-secondary-700;
  }

  &.disabled {
    border-color: $ds2-color-gray-200;
    background-color: $ds2-color-gray-200;
    box-shadow: none;
    color: $color-white;
    cursor: default;
    pointer-events: none;

    &:hover {
      opacity: 1;
    }
  }

  &.no-shadow {
    border: 0;
    box-shadow: none;
  }
}
</style>
