import grpcClient from '~/grpc/grpc-client'
import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'
import {
  prefectureTextGetter,
  sexTextGetter,
  corporateFgTextGetter,
  initialUserState
} from '~/store/_helpers/user-helper'

// state
export const state = () => initialUserState()

// getters
export const getters = {
  prefectureText(state, getters, globalState) {
    return prefectureTextGetter(state, getters, globalState)
  },
  sexText(state, getters, globalState) {
    return sexTextGetter(state, getters, globalState)
  },
  corporateFgText(state, getters, globalState) {
    return corporateFgTextGetter(state, getters, globalState)
  }
}

// actions
export const actions = {
  async fetchMyUser({ commit, rootState }) {
    const metadata = commonMetaData(rootState)
    const [
      { UserService },
      { GetMyUserRequest },
      { ProfileService },
      { GetMyProfileRequest },
      { UserStatusService },
      { GetMyUserStatusRequest },
      { RatingService },
      { GetMyRatingSummaryRequest },
      { HasService },
      { GetMyHasInfoRequest }
    ] = await Promise.all([
      import('~/stub/apigateway/profile/user_pb_service'),
      import('~/stub/apigateway/profile/user_pb'),
      import('~/stub/apigateway/profile/profile_pb_service'),
      import('~/stub/apigateway/profile/profile_pb'),
      import('~/stub/apigateway/profile/user_status_pb_service'),
      import('~/stub/apigateway/profile/user_status_pb'),
      import('~/stub/apigateway/profile/rating_pb_service'),
      import('~/stub/apigateway/profile/rating_pb'),
      import('~/stub/apigateway/profile/has_pb_service'),
      import('~/stub/apigateway/profile/has_pb')
    ])
    const grpcClientContextList = [
      {
        method: UserService.GetMyUser,
        request: new GetMyUserRequest(),
        metadata,
        transformReply: reply => ({ ...reply.user })
      },
      {
        method: ProfileService.GetMyProfile,
        request: new GetMyProfileRequest(),
        metadata,
        transformReply: reply => ({ profile: reply.profile })
      },
      {
        method: UserStatusService.GetMyUserStatus,
        request: new GetMyUserStatusRequest(),
        metadata,
        transformReply: reply => ({ userStatus: reply.userStatus })
      },
      {
        method: RatingService.GetMyRatingSummary,
        request: new GetMyRatingSummaryRequest(),
        metadata,
        transformReply: reply => ({ ...reply })
      },
      {
        method: HasService.GetMyHasInfo,
        request: new GetMyHasInfoRequest(),
        metadata,
        transformReply: reply => ({ hasInfo: reply.hasInfo })
      }
    ]
    await Promise.all(
      grpcClientContextList.map(context => {
        return grpcClient(context).then(reply => commit('RECEIVE_MY_USER', reply))
      })
    )
  },
  // NOTE: profile
  async updateProfile({ rootState }, { profile }) {
    await new Promise(async (resolve, reject) => {
      const [{ UpdateProfileRequest }, { ProfileService }] = await Promise.all([
        import('~/stub/apigateway/profile/profile_pb'),
        import('~/stub/apigateway/profile/profile_pb_service')
      ])
      const request = new UpdateProfileRequest()
      request.setOccupation(profile.occupation)
      request.setIsDisplayAge(profile.isDisplayAge)
      request.setPrefectureId(profile.prefectureId)
      request.setLocationText(profile.locationText)
      request.setThemeColor(profile.themeColor)
      const metadata = commonMetaData(rootState)
      grpc.invoke(ProfileService.UpdateProfile, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  },
  async updateCoverImage({ commit, rootState }, { coverImage }) {
    const [{ UpdateCoverImageRequest }, { ProfileService }] = await Promise.all([
      import('~/stub/apigateway/profile/profile_pb'),
      import('~/stub/apigateway/profile/profile_pb_service')
    ])
    const request = new UpdateCoverImageRequest()
    request.setCoverImage(coverImage)
    const metadata = commonMetaData(rootState)
    const res = await grpcClient({
      method: ProfileService.UpdateCoverImage,
      request,
      metadata
    })
    commit('RECEIVE_PROFILE', res)
  },
  async deleteCoverImage({ commit, rootState }) {
    let res = await new Promise(async (resolve, reject) => {
      const [{ DeleteCoverImageRequest }, { ProfileService }] = await Promise.all([
        import('~/stub/apigateway/profile/profile_pb'),
        import('~/stub/apigateway/profile/profile_pb_service')
      ])
      const request = new DeleteCoverImageRequest()
      const metadata = commonMetaData(rootState)
      grpc.invoke(ProfileService.DeleteCoverImage, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, msg, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
    if (res.profile) {
      commit('RECEIVE_PROFILE', res)
    }
  },
  // NOTE: user status
  async updateStatus({ dispatch, rootState }, { kind }) {
    await new Promise(async (resolve, reject) => {
      const [{ UpdateUserStatusRequest }, { UserStatusService }] = await Promise.all([
        import('~/stub/apigateway/profile/user_status_pb'),
        import('~/stub/apigateway/profile/user_status_pb_service')
      ])
      const request = new UpdateUserStatusRequest()
      request.setKind(kind)
      const metadata = commonMetaData(rootState)
      grpc.invoke(UserStatusService.UpdateUserStatus, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, msg, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
    await dispatch('fetchMyUser', {})
  },
  // NOTE user status
  async stopCustomize({ dispatch, rootState }, params) {
    await new Promise(async (resolve, reject) => {
      const [{ UpdateStopCustomizeRequest }, { UserStatusService }] = await Promise.all([
        import('~/stub/apigateway/profile/user_status_pb'),
        import('~/stub/apigateway/profile/user_status_pb_service')
      ])
      const request = new UpdateStopCustomizeRequest()
      request.setIsStopCustomize(params.isStopCustomize)
      const metadata = commonMetaData(rootState)
      grpc.invoke(UserStatusService.UpdateStopCustomize, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, msg, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
    await dispatch('fetchMyUser', {})
  }
}

// mutations
export const mutations = {
  RESET(state) {
    Object.assign(state, initialUserState())
  },
  RECEIVE_MY_USER(state, data) {
    Object.assign(state, data)
  },
  RECEIVE_MY_USER_NAME(state, data) {
    Object.assign(state, { name: data.name })
  },
  RECEIVE_PROFILE(state, data) {
    Object.assign(state, { profile: data.profile })
  },
  RECEIVE_EXPERIENCE_JOBS(state, data) {
    Object.assign(state, { experienceJobsList: data.experienceJobsList })
  },
  RECEIVE_USER_WORK_HISTORIES(state, data) {
    Object.assign(state, { userWorkHistoriesList: data.userWorkHistoriesList })
  },
  RECEIVE_USER_SPECIALTIES(state, data) {
    Object.assign(state, { userSpecialtiesList: data.userSpecialtiesList })
  },
  RECEIVE_CAREERS(state, data) {
    Object.assign(state, { careersList: data.careersList })
  },
  RECEIVE_EDUCATIONAL_BACKGROUNDS(state, data) {
    Object.assign(state, { educationalBackgroundsList: data.schoolHistoriesList })
  },
  RECEIVE_SKILLS(state, data) {
    Object.assign(state, { skillsList: data.skillsList })
  },
  RECEIVE_LANGUAGE_SKILLS(state, data) {
    Object.assign(state, { languageSkillsList: data.languageSkillsList })
  },
  RECEIVE_LICENSES(state, data) {
    Object.assign(state, { licensesList: data.licensesList })
  },
  RECEIVE_AWARD(state, data) {
    Object.assign(state, { awardsList: data.awardsList })
  },
  RECEIVE_DESIRED_WORKING_CONDITION(state, data) {
    Object.assign(state, { desiredWorkingCondition: data.desiredWorkingCondition })
  },
  RECEIVE_DESIRED_MY_WORKING_CONDITION(state, data) {
    Object.assign(state, { myDesiredWorkingCondition: data.desiredWorkingCondition })
  },
  RECEIVE_USER_INFO_URLS(state, data) {
    Object.assign(state, { userInfoUrlsList: data.userInfoUrlsList })
  },
  RECEIVE_RESUME_INFO(state, data) {
    Object.assign(state, { resumeInfosList: data.resumeInfosList })
  },
  RECEIVE_CATCHPHRASE(state, data) {
    Object.assign(state, { catchphrase: data.catchphrase })
  },
  RECEIVE_MESSAGE(state, data) {
    Object.assign(state, { message: data.message })
  },
  RECEIVE_USER_JOBS_LIST(state, data) {
    Object.assign(state, { userJobsList: data.userJobsList })
  },
  RECEIVE_HAS_CAREERS(state, data) {
    Object.assign(state, { hasCareers: data })
  },
  RECEIVE_HAS_SKILLS(state, data) {
    Object.assign(state, { hasSkills: data })
  },
  RECEIVE_HAS_LICENSES(state, data) {
    Object.assign(state, { hasLicenses: data })
  }
}
