// package: request
// file: apigateway/request/request.proto

import * as apigateway_request_request_pb from "../../apigateway/request/request_pb";
import * as google_protobuf_wrappers_pb from "google-protobuf/google/protobuf/wrappers_pb";
import * as google_protobuf_timestamp_pb from "google-protobuf/google/protobuf/timestamp_pb";
export class RequestService {
  static serviceName = "request.RequestService";
}
export namespace RequestService {
  export class GetMyRequests {
    static readonly methodName = "GetMyRequests";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.GetMyRequestsRequest;
    static readonly responseType = apigateway_request_request_pb.GetMyRequestsResponse;
  }
  export class SearchRequests {
    static readonly methodName = "SearchRequests";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.SearchRequestsRequest;
    static readonly responseType = apigateway_request_request_pb.SearchRequestsReply;
  }
  export class GetSearchRequestsCondition {
    static readonly methodName = "GetSearchRequestsCondition";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.SearchRequestsRequest;
    static readonly responseType = apigateway_request_request_pb.GetSearchRequestsConditionReply;
  }
  export class GetQuestions {
    static readonly methodName = "GetQuestions";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.GetQuestionsRequest;
    static readonly responseType = apigateway_request_request_pb.GetQuestionsReply;
  }
  export class AddQuestion {
    static readonly methodName = "AddQuestion";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.AddQuestionRequest;
    static readonly responseType = apigateway_request_request_pb.AddQuestionReply;
  }
  export class AddAnswer {
    static readonly methodName = "AddAnswer";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.AddAnswerRequest;
    static readonly responseType = apigateway_request_request_pb.AddAnswerReply;
  }
  export class AddRequestInformation {
    static readonly methodName = "AddRequestInformation";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.AddRequestInformationRequest;
    static readonly responseType = apigateway_request_request_pb.AddRequestInformationReply;
  }
  export class UpdateRequestInformation {
    static readonly methodName = "UpdateRequestInformation";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.UpdateRequestInformationRequest;
    static readonly responseType = apigateway_request_request_pb.UpdateRequestInformationResponse;
  }
  export class DeleteRequestInformation {
    static readonly methodName = "DeleteRequestInformation";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.DeleteRequestInformationRequest;
    static readonly responseType = apigateway_request_request_pb.DeleteRequestInformationReply;
  }
  export class GetRequestCopy {
    static readonly methodName = "GetRequestCopy";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.GetRequestCopyRequest;
    static readonly responseType = apigateway_request_request_pb.GetRequestCopyResponse;
  }
  export class IsAuthorized {
    static readonly methodName = "IsAuthorized";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.IsAuthorizedRequest;
    static readonly responseType = apigateway_request_request_pb.IsAuthorizedResponse;
  }
  export class ListBookmarks {
    static readonly methodName = "ListBookmarks";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.ListBookmarksRequest;
    static readonly responseType = apigateway_request_request_pb.ListBookmarksResponse;
  }
  export class AddBookmark {
    static readonly methodName = "AddBookmark";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.AddBookmarkRequest;
    static readonly responseType = apigateway_request_request_pb.AddBookmarkResponse;
  }
  export class DeleteBookmark {
    static readonly methodName = "DeleteBookmark";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.DeleteBookmarkRequest;
    static readonly responseType = apigateway_request_request_pb.DeleteBookmarkResponse;
  }
  export class ListCopyTargetRequests {
    static readonly methodName = "ListCopyTargetRequests";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.ListCopyTargetRequestsRequest;
    static readonly responseType = apigateway_request_request_pb.ListCopyTargetRequestsResponse;
  }
  export class ListProposedRequests {
    static readonly methodName = "ListProposedRequests";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.ListProposedRequestsRequest;
    static readonly responseType = apigateway_request_request_pb.ListProposedRequestsResponse;
  }
  export class IsQuestionAuthorized {
    static readonly methodName = "IsQuestionAuthorized";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.IsQuestionAuthorizedRequest;
    static readonly responseType = apigateway_request_request_pb.IsQuestionAuthorizedResponse;
  }
  export class GetRecommendRequests {
    static readonly methodName = "GetRecommendRequests";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.GetRecommendRequestsRequest;
    static readonly responseType = apigateway_request_request_pb.GetRecommendRequestsResponse;
  }
  export class GetPopularRequests {
    static readonly methodName = "GetPopularRequests";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.GetPopularRequestsRequest;
    static readonly responseType = apigateway_request_request_pb.GetPopularRequestsResponse;
  }
  export class NewRequestDelegation {
    static readonly methodName = "NewRequestDelegation";
    static readonly service = RequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_request_request_pb.NewRequestDelegationRequest;
    static readonly responseType = apigateway_request_request_pb.NewRequestDelegationResponse;
  }
}
