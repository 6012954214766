<template>
  <OSelect
    v-bind="$attrs"
    v-on="$listeners"
    v-model="innerValue"
    :class="{ '-inline-block': inlineBlock, [$attrs.size]: !!$attrs.size }"
    :disabled="selectDisabled"
    @change.native="blurSelect"
  >
    <slot
      v-for="slotName in Object.keys($slots)"
      :name="slotName"
      :slot="slotName"
    />
  </OSelect>
</template>
<script>
export default {
  name: 'CSelect',
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array, Symbol, Function],
      default: null
    },
    inlineBlock: {
      type: Boolean,
      default: false
    },
    selectDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    blurSelect() {
      // placeholderが設定されていてデフォルトで未選択状態のとき、iPadでプルダウンの選択がずれる
      // 選択後フォーカスを外すとずれなくなる
      if (this.$ua.isFromIpad()) {
        this.$el.querySelector('select').blur()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.control {
  &.select {
    border-color: $ds2-color-gray-100;
    ::v-deep select:not(.is-empty) {
      color: $ds2-color-gray-600;
    }
  }
  &.-inline-block {
    display: inline-block;
    vertical-align: 0.4em;
    &.is-small {
      vertical-align: 0.2em;
    }
    &.is-medium {
      vertical-align: 0.6em;
    }
    &.is-large {
      vertical-align: 1.2em;
    }
  }
}

// NOTE: IE hack
*::-ms-backdrop,
.control {
  &.-inline-block {
    display: inline-block;
    vertical-align: -12px;
    &.is-small {
      vertical-align: -8px;
    }
    &.is-medium {
      vertical-align: -14px;
    }
    &.is-large {
      vertical-align: -22px;
    }
  }
}
</style>
