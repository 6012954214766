import { EXTERNAL_SYSTEM_ID_LIST } from '~/constants/external-system'

// initial state
export const state = () => ({
  externalSystemStatuses: []
})

// getters
export const getters = {
  hasVerifiedForGoogle(state) {
    return state.externalSystemStatuses?.find(e => e.id === EXTERNAL_SYSTEM_ID_LIST.GOOGLE)
      ?.verified
  },
  hasVerifiedForYahoo(state) {
    return state.externalSystemStatuses?.find(e => e.id === EXTERNAL_SYSTEM_ID_LIST.YAHOO)?.verified
  },
  hasVerifiedForFacebook(state) {
    return state.externalSystemStatuses?.find(e => e.id === EXTERNAL_SYSTEM_ID_LIST.FACEBOOK)
      ?.verified
  },
  hasVerifiedForApple(state) {
    return state.externalSystemStatuses?.find(e => e.id === EXTERNAL_SYSTEM_ID_LIST.APPLE)?.verified
  },
  hasVerifiedForSendgrid(state) {
    return state.externalSystemStatuses?.find(e => e.id === EXTERNAL_SYSTEM_ID_LIST.SENDGRID)
      ?.verified
  },
  hasVerifiedOpenIdService(state) {
    return state.externalSystemStatuses?.some(
      e =>
        [
          EXTERNAL_SYSTEM_ID_LIST.GOOGLE,
          EXTERNAL_SYSTEM_ID_LIST.YAHOO,
          EXTERNAL_SYSTEM_ID_LIST.FACEBOOK,
          EXTERNAL_SYSTEM_ID_LIST.APPLE
        ].includes(e.id) && e.verified
    )
  }
}

// actions
export const actions = {
  async fetchExternalSystemStatuses({ commit, rootState }) {
    const { ExternalSystemAccessor } = await import(
      '~/api/accessors/external_system/external-system-accessor'
    )
    const externalSystemAccessor = new ExternalSystemAccessor(rootState)
    try {
      const externalSystemList = await externalSystemAccessor.listExternalSystems(this.app)
      if (externalSystemList?.externalSystemDomainsList) {
        commit('SET_EXTERNAL_SYSTEM_STATUSES', externalSystemList.externalSystemDomainsList)
      }
    } catch (error) {
      this.$sentry.captureException(error)
    }
  }
}

// mutations
export const mutations = {
  SET_EXTERNAL_SYSTEM_STATUSES(state, data) {
    state.externalSystemStatuses = data
  }
}
