import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

const SERVICE_CLASS_TEXT = 'class_text'
const SERVICE_CLASS_PHONE = 'class_phone'
const SERVICE_CLASS_OFFER = 'class_offer'

// initial state
const initialState = () => ({
  history: {
    amount: null,
    count: null,
    historiesList: []
  },
  billing: {
    amount: null,
    firstOrderClosedDate: null,
    billingList: []
  },
  csvPath: ''
})

export const state = () => initialState()

// getters
export const getters = {
  SERVICE_CLASS_PHONE() {
    return SERVICE_CLASS_PHONE
  }
}

// actions
export const actions = {
  async fetchHistories({ commit, rootState }) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetHistoriesRequest }, { OrderService }] = await Promise.all([
        import('~/stub/apigateway/enterprise/order_pb'),
        import('~/stub/apigateway/enterprise/order_pb_service')
      ])
      const request = new GetHistoriesRequest()
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(OrderService.GetHistories, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })

    commit('RECEIVE_HISTORY', response)
  },
  async fetchBilling({ commit, rootState }, { targetMonth }) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetBillingRequest }, { OrderService }] = await Promise.all([
        import('~/stub/apigateway/enterprise/order_pb'),
        import('~/stub/apigateway/enterprise/order_pb_service')
      ])
      const request = new GetBillingRequest()
      if (targetMonth) {
        request.setFilterByTime(targetMonth)
      }
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(OrderService.GetBilling, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })

    commit('RECEIVE_BILLING', response)
  },
  async fetchCsvPath({ commit, rootState }) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetCsvPathRequest }, { OrderService }] = await Promise.all([
        import('~/stub/apigateway/enterprise/order_pb'),
        import('~/stub/apigateway/enterprise/order_pb_service')
      ])
      const request = new GetCsvPathRequest()
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(OrderService.GetCsvPath, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    commit('RECEIVE_CSV_PATH', response)
  },
  reset({ commit }) {
    commit('RESET')
  }
}

// mutations
export const mutations = {
  RECEIVE_HISTORY(state, data) {
    state.history.amount = data.amount
    state.history.count = data.count
    state.history.historiesList = data.historiesList
  },
  RECEIVE_BILLING(state, data) {
    state.billing.amount = data.amount
    state.billing.firstOrderClosedDate = data.firstOrderClosedDate
    state.billing.billingList = data.billingList
  },
  RECEIVE_CSV_PATH(state, data) {
    state.csvPath = data.csvPath
  },
  RESET(state) {
    Object.assign(state, initialState())
  }
}
