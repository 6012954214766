import { Plugin } from '@nuxt/types'
import { onGlobalSetup, useRouter } from '@nuxtjs/composition-api'
import { Route } from 'vue-router/types/router'

/** GAの測定ID */
const MeasurementID = process.env.config['visit-log']['ga-measurement-id']

/** ログ記録用APIエンドポイント */
const VisitLogEndpoint = process.env.config['visit-log'].endpoint

window.dataLayer = window.dataLayer || []
window.ga4 =
  window.ga4 ||
  function () {
    window.dataLayer?.push(arguments)
  }

/**
 * 訪問ログデータ
 * service_id以降は特定できるなら設定する
 */
type VisitLog = {
  /** GAのclient_idを設定 */
  visitor_id?: string
  /** GAのsession_idを設定 */
  session_id?: string
  /** 閲覧中のユーザーID */
  user_id?: number
  /**
   * 現在時刻のタイムスタンプ
   * API側で設定するので基本不要
   */
  time_stamp?: number
  /**
   * デバイス種別
   * API側でUAから特定・設定するので基本不要
   */
  device_type?: string
  /** リファラ(CSRの場合は前ページのURL) */
  referer_url?: string | null
  /** アフィリエイトなどの流入元 */
  lastclick_channel?: string
  /** Google、Yahooなどの流入元SEプロバイダ */
  lastclick_publisher?: string
  /** 閲覧中のURL */
  landing_url?: string
  /** URL種別(Vueのルート名を設定) */
  url_type?: string | null
  /** User Agent */
  user_agent?: string
  /** サービスID */
  service_id?: number
  /** 出品者のユーザーID */
  provider_id?: number
  /** 親カテゴリID */
  parent_category_id?: number
  /** カテゴリID */
  category_id?: number

  [key: string]: string | number | null | undefined
}

/** 各ルート独自のパラメータを取得 */
const getAdditionalLog = (route: Route): VisitLog => {
  // サービス詳細
  const additionalLog: VisitLog = {}
  if (route.name === 'services-id') {
    additionalLog.service_id = Number(route.params.id)
  }
  // カテゴリーTOP
  if (route.name === 'categories-categoryId') {
    additionalLog.category_id = Number(route.params.categoryId)
  }
  // サービス検索(カテゴリIDが指定されてる場合のみ)
  if (route.name === 'search' && route.query.category_id) {
    additionalLog.category_id = Number(route.query.category_id)
  }
  // プロフィール
  if (route.name && /^users-id(-.+)?$/.test(route.name)) {
    additionalLog.provider_id = Number(route.params.id)
  }
  return additionalLog
}

const visitLogPlugin: Plugin = async ({ $axios, $cookies, store, route }) => {
  if (store.getters['auth/isFromCoconalaApp']) return

  const ccuid: string = store.state.auth.ccuid

  /** GAから対象の変数値を取得 */
  const getFromGA = <T extends number | string>(field: string): Promise<T> =>
    new Promise((resolve, reject) => {
      let isSucceeded = false
      window.ga4?.('get', MeasurementID, field, v => {
        isSucceeded = true
        resolve(v)
      })

      // GAからデータ取得できなかった場合のタイムアウト
      setTimeout(() => {
        if (!isSucceeded) reject()
      }, 2000)
    })

  // 最終訪問URL
  let previousLocation: string | undefined | null = null

  /** 訪問ログ送信 */
  const sendVisitLog = async (route: Route) => {
    try {
      // GAからセッションID、クライアントIDを取得
      const [sessionId, clientId] = await Promise.all([
        getFromGA<string>('session_id').catch(() => ''),
        // ccuidで代替していることがわかるように接頭辞をつける
        getFromGA<string>('client_id').catch(() => `ccuid:${ccuid}`)
      ])

      // セッションID、クライアントIDをクッキーに保存(決済時のログ記録用)
      $cookies.set('ga_session_id', sessionId)
      $cookies.set('ga_client_id', clientId)
      const log: VisitLog = {
        visitor_id: clientId,
        session_id: sessionId,
        user_id: store.state.auth?.user?.id,
        referer_url: previousLocation || store.state.auth?.initialReferer,
        landing_url: location.href,
        url_type: route.name,
        user_agent: navigator.userAgent,
        ccuid,
        ...getAdditionalLog(route)
      }

      previousLocation = log.landing_url

      if (navigator.sendBeacon) {
        navigator.sendBeacon(VisitLogEndpoint, JSON.stringify(log))
      } else {
        $axios.post(VisitLogEndpoint, log)
      }
    } catch (e) {
      // エラーは握り潰す
    }
  }

  // 初回ランディング時のログ送信
  sendVisitLog(route)

  // ナビゲーションガードで各ルートへ遷移時に自動ログ送信
  onGlobalSetup(() => {
    const router = useRouter()
    router.afterEach(to => {
      sendVisitLog(to)
    })
  })
}
export default visitLogPlugin
