// package: news
// file: apigateway/news/news.proto

import * as apigateway_news_news_pb from "../../apigateway/news/news_pb";
export class NewsService {
  static serviceName = "news.NewsService";
}
export namespace NewsService {
  export class GetLatestNews {
    static readonly methodName = "GetLatestNews";
    static readonly service = NewsService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_news_news_pb.GetLatestNewsRequest;
    static readonly responseType = apigateway_news_news_pb.LatestNewsReply;
  }
}
