import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '@/store/_helpers/common-helper'

// initial state
const initialState = () => ({
  userSpecialtiesList: [],
  skillsList: [],
  licensesList: [],
  careersList: [],
  awardsList: [],
  translatedCharacterCount: 0
})

// state
export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchCareers({ commit, rootState }) {
    const [
      { UserSpecialtyService },
      { GetUserSpecialtiesRequest },
      { SkillService },
      { GetSkillsRequest },
      { LicenseService },
      { GetLicensesRequest },
      { CareerService },
      { GetCareersRequest },
      { AwardService },
      { GetAwardsRequest }
    ] = await Promise.all([
      import('~/stub/apigateway/profile/user_specialty_pb_service'),
      import('~/stub/apigateway/profile/user_specialty_pb'),
      import('~/stub/apigateway/profile/skill_pb_service'),
      import('~/stub/apigateway/profile/skill_pb'),
      import('~/stub/apigateway/profile/license_pb_service'),
      import('~/stub/apigateway/profile/license_pb'),
      import('~/stub/apigateway/profile/career_pb_service'),
      import('~/stub/apigateway/profile/career_pb'),
      import('~/stub/apigateway/profile/award_pb_service'),
      import('~/stub/apigateway/profile/award_pb')
    ])

    const metadata = commonMetaData(rootState)
    const grpcClientContextList = [
      (() => {
        const request = new GetUserSpecialtiesRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: UserSpecialtyService.GetUserSpecialties,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetSkillsRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: SkillService.GetSkills,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetLicensesRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: LicenseService.GetLicenses,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetCareersRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: CareerService.GetCareers,
          request,
          metadata
        }
      })(),
      (() => {
        const request = new GetAwardsRequest()
        request.setUserId(rootState.pages.users.user.id)
        return {
          method: AwardService.GetAwards,
          request,
          metadata
        }
      })()
    ]
    await Promise.all(
      grpcClientContextList.map(context => {
        return grpcClient(context).then(reply => commit('RECEIVE_CAREERS', reply))
      })
    )
  }
}

// mutations
export const mutations = {
  RESET(state) {
    Object.assign(state, initialState())
  },
  RECEIVE_CAREERS(state, data) {
    // TranslatedCharacterCountが0で上書きされないように、待避
    const copyTranslatedCharacterCount =
      data?.translatedCharacterCount || state.translatedCharacterCount
    Object.assign(state, data)
    state.translatedCharacterCount = copyTranslatedCharacterCount
  }
}
