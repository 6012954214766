import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { flatMap } from 'lodash-es'
import util from '~/assets/javascripts/util'
import {
  toTranslatedArrayText,
  isTranslatableTarget
} from '~/components/logics/translate/translate'
import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '~/store/_helpers/common-helper'
import { uniq } from 'lodash-es'

const importMasterRequest = async () => {
  const modules = await import('~/stub/apigateway/master/master_pb')
  return { ...modules }
}

const importMasterService = async () => {
  const { MasterService } = await import('~/stub/apigateway/master/master_pb_service')
  return MasterService
}

/** PRO認定子カテゴリIDリスト */
const PRO_CATEGORY_IDS = Object.freeze([3, 5, 9, 10, 11, 12, 13, 16, 18, 19, 22, 23, 26, 27])

// state
export const state = () => ({
  prefectures: [],
  prefectureOther: 99,
  awardKinds: [],
  daysToDeliveries: [],
  daysToDeliveriesNeedConsultation: 999,
  masterCategories: [],
  masterCategoryTypesList: [],
  mirrorCategoriesList: [],
  jobs: [],
  minimumPrices: [],
  requestPrices: [],
  pricesMaster: {},
  optionMastersList: [],
  requestItemsList: [],
  requestLowestPrice: 0,
  requestHighestPrice: 0,
  lowestMarketPrice: 0,
  highestMarketPrice: 0,
  priceRangesList: [],
  customizeRequestItemsList: [],
  customizeRequestCommonItemsList: [],
  customizeRequestLowestPrice: 0,
  customizeRequestHighestPrice: 0,
  categoryDivinationId: 3,
  disallowVideoCategoryIds: [1, 2, 3, 17],
  agentAndLegalCategoryId: 27,
  sexes: {
    M: '男性',
    F: '女性',
    O: '性別：その他'
  },
  sexesRaw: {
    M: '男性',
    F: '女性',
    O: 'その他'
  },
  corporateFgTexts: {
    identificationNormalized: {
      C: '法人',
      P: '個人'
    },
    notIdentificationNormalized: {
      C: '法人・個人事業主',
      P: '個人'
    }
  },
  requestTypes: {
    REQUEST: 0,
    CUSTOMIZE: 1,
    DIRECT_OFFER: 2
  },
  useScenesList: [],
  licensesList: [],
  skillsList: [],
  languageSkillsList: []
})

// getters
export const getters = {
  flattenMasterCategories(state) {
    if (state.masterCategories.length === 0) return []
    return flatMap(state.masterCategories, c => [c, ...c.subCategories])
  },
  businessMasterCategories(state) {
    if (state.masterCategories.length === 0) return []
    const masterCategories = util.clone(state.masterCategories)
    return masterCategories
      .filter(c => {
        return c.businessFlag
      })
      .map(c => {
        c.subCategories = c.subCategories.filter(s => s.businessFlag)
        return c
      })
  },
  displayedUseScenesList(state, _, rootState) {
    return rootState.my.header.privateAccountInfo.isMzhc
      ? state.useScenesList.filter(useScene => useScene.isBusiness)
      : state.useScenesList
  },
  businessJobs(state) {
    if (state.jobs.length === 0) return []
    const jobs = util.clone(state.jobs)
    return jobs
      .filter(job => job.businessFlag)
      .map(job => {
        job.subJobs = job.subJobs
          .filter(subJob => subJob.businessFlag)
          .map(subJob => {
            subJob.subJobs = subJob.subJobs.filter(subSubJob => subSubJob.businessFlag)
            return subJob
          })
        return job
      })
  },
  getTechniqueByKeyCategoryId: state => keyCategoryId =>
    state.optionMastersList.find(o => o.keyCategoryId === keyCategoryId),
  getSubCategoryByCategoryId: state => masterCategoryId =>
    state.masterCategories.find(m => m.id === masterCategoryId)?.subCategories || [],
  getIsOnsiteByCategoryId: state => masterCategoryId =>
    state.masterCategories.find(m => m.id === masterCategoryId)?.isOnsite || false,
  getRelatedCategories(state, getters, { my }) {
    return categoryId => {
      const relatedCategories =
        state.mirrorCategoriesList.find(c => c.parentCategoryId === categoryId)?.categoriesList ||
        []
      if (my.header.privateAccountInfo.isMzhc) {
        return relatedCategories.filter(c => c.businessFlag)
      }
      return relatedCategories
    }
  }
}

// actions
export const actions = {
  async fetchBasic({ commit, rootState }) {
    const cache = this.app.context.ssrContext?.$caches?.master || null
    const setMetadata = commonMetaData(rootState)
    const [
      { GetRequest: GetRequestProfile },
      { MasterService: MasterServiceProfile },
      { GetRequest },
      MasterService,
      { MirrorCategoryRequest },
      { MirrorCategoryService }
    ] = await Promise.all([
      import('~/stub/apigateway/profile/master_pb'),
      import('~/stub/apigateway/profile/master_pb_service'),
      importMasterRequest(),
      importMasterService(),
      import('~/stub/gw/mirror_category/mirror_category_service_pb'),
      import('~/stub/gw/mirror_category/mirror_category_service_pb_service')
    ])

    const grpcClientContextList = [
      // GetJob
      {
        method: MasterServiceProfile.GetJobs,
        request: new GetRequestProfile(),
        metadata: setMetadata,
        transformReply: reply => {
          let master = []
          let jobs = Object.assign([], reply.jobsList)
          for (let i = 0; i < jobs.length; i++) {
            jobs[i]['subJobs'] = Object.assign([], jobs[i]['subJobsList'])
            delete jobs[i]['subJobsList']

            for (let j = 0; j < jobs[i]['subJobs'].length; j++) {
              jobs[i]['subJobs'][j]['subJobs'] = Object.assign(
                [],
                jobs[i]['subJobs'][j]['subJobsList']
              )
              delete jobs[i]['subJobs'][j]['subJobsList']

              for (let k = 0; k < jobs[i]['subJobs'][j]['subJobs'].length; k++) {
                jobs[i]['subJobs'][j]['subJobs'][k]['subJobs'] = Object.assign(
                  [],
                  jobs[i]['subJobs'][j]['subJobs'][k]['subJobsList']
                )
                delete jobs[i]['subJobs'][j]['subJobs'][k]['subJobsList']
              }
            }
          }
          master['jobs'] = jobs
          return master
        },
        cache
      },
      // GetMasterCategories
      {
        method: MasterService.GetMasterCategories,
        request: new GetRequest(),
        metadata: setMetadata,
        transformReply: reply => {
          let master = []

          let categories = Object.assign([], reply.categoriesList)
          for (let i = 0; i < categories.length; i++) {
            categories[i]['subCategories'] = Object.assign([], categories[i]['subCategoriesList'])
            delete categories[i]['subCategoriesList']

            // PRO認定カテゴリ判定フィールド追加
            categories[i]['isPro'] = PRO_CATEGORY_IDS.includes(categories[i].id)
            categories[i]['subCategories'].forEach(sc => {
              sc['isPro'] = PRO_CATEGORY_IDS.includes(sc.id)
            })
          }

          master['masterCategories'] = categories
          return master
        },
        cache
      },
      // GetMasterCategoryTypes
      {
        method: MasterService.GetMasterCategoryTypes,
        request: new GetRequest(),
        metadata: setMetadata,
        transformReply: reply => {
          let master = []
          master['masterCategoryTypesList'] = reply.masterCategoryTypeGroupsList
          return master
        },
        cache
      },
      // GetMirrorCategory
      {
        method: MirrorCategoryService.GetMirrorCategory,
        request: new MirrorCategoryRequest(),
        metadata: setMetadata,
        transformReply: reply => ({ mirrorCategoriesList: reply.mirrorCategoriesList }),
        cache,
        strip: true
      },
      // GetRequestSearchPrices
      {
        method: MasterService.GetRequestSearchPrices,
        request: new GetRequest(),
        metadata: setMetadata,
        transformReply: reply => {
          return {
            requestPrices: reply.pricesList.map(p => p.price)
          }
        },
        cache
      },
      // GetServiceSearchMasters
      {
        method: MasterService.GetServiceSearchMaster,
        request: new GetRequest(),
        metadata: setMetadata,
        cache
      },
      // GetPrefectures
      {
        method: MasterServiceProfile.GetPrefectures,
        request: new GetRequestProfile(),
        metadata: setMetadata,
        transformReply: reply => ({ prefectures: reply.prefecturesList }),
        cache
      },
      // GetAwardKinds(プロフィール関連ページ用)
      {
        method: MasterServiceProfile.GetAwardKinds,
        request: new GetRequestProfile(),
        metadata: setMetadata,
        transformReply: reply => ({ awardKinds: reply.awardKindsList }),
        cache
      },
      // GetDaysToDeliveries(プロフィール関連ページ用)
      {
        method: MasterServiceProfile.GetDaysToDeliveries,
        request: new GetRequestProfile(),
        metadata: setMetadata,
        transformReply: reply => ({ daysToDeliveries: reply.daysToDeliveriesList }),
        cache
      },
      // GetMinimumPrices(プロフィール関連ページ用)
      {
        method: MasterServiceProfile.GetMinimumPrices,
        request: new GetRequestProfile(),
        metadata: setMetadata,
        transformReply: reply => ({ minimumPrices: reply.minimumPricesList }),
        cache
      },
      // GetLicenseMasters(プロフィール関連ページ用)
      {
        method: MasterServiceProfile.GetLicenseMasters,
        request: new GetRequestProfile(),
        metadata: setMetadata,
        transformReply: reply => ({ licensesList: reply.licensesList }),
        cache
      }
    ]
    await Promise.all(
      grpcClientContextList.map(context => {
        return grpcClient(context).then(reply => {
          commit('RECEIVE_BASIC', reply)
        })
      })
    )
  },
  async getRequestEditMaster({ commit, rootState }, data) {
    const [MasterService, { GetRequestEditMasterRequest }] = await Promise.all([
      importMasterService(),
      importMasterRequest()
    ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetRequestEditMasterRequest()
    request.setMasterCategoryId(params.masterCategoryId)

    const reply = await grpcClient({
      method: MasterService.GetRequestEditMaster,
      request,
      metadata
    })

    reply.requestItemsList = reply.requestItemsList.filter(
      requestItem => requestItem.displayFlag && !requestItem.deletedFlag
    )

    reply.requestItemsList.forEach(requestItem => {
      if (requestItem.optionJson) {
        requestItem.optionJson = JSON.parse(requestItem.optionJson)
      }

      requestItem.selectItemsList.forEach(selectItem => {
        if (selectItem.optionJson) {
          selectItem.optionJson = JSON.parse(selectItem.optionJson)
        }
      })
    })

    commit('RECEIVE_BASIC', {
      priceListList: reply.priceListList,
      requestItemsList: reply.requestItemsList,
      requestLowestPrice: reply.lowestPrice,
      requestHighestPrice: reply.highestPrice,
      lowestMarketPrice: reply.lowestMarketPrice,
      highestMarketPrice: reply.highestMarketPrice,
      priceRangesList: reply.priceRangesList
    })
  },
  async getCustomizeRequestEditMaster({ commit, rootState, dispatch }, data) {
    const [MasterService, { GetCustomizeRequestEditMasterRequest }] = await Promise.all([
      importMasterService(),
      importMasterRequest()
    ])
    const params = data.params
    const setMetadata = commonMetaData(rootState)
    const request = new GetCustomizeRequestEditMasterRequest()
    request.setMasterCategoryId(params.masterCategoryId)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(MasterService.GetCustomizeRequestEditMaster, {
        request: request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    reply.customizeItemsList = reply.customizeItemsList.filter(
      requestItem => requestItem.displayFlag && !requestItem.deletedFlag
    )

    reply.customizeItemsList.forEach(requestItem => {
      if (requestItem.optionJson) {
        requestItem.optionJson = JSON.parse(requestItem.optionJson)
      }

      requestItem.selectItemsList.forEach(selectItem => {
        if (selectItem.optionJson) {
          selectItem.optionJson = JSON.parse(selectItem.optionJson)
        }
      })
    })

    reply.customizeCommonItemsList = reply.customizeCommonItemsList.filter(
      requestItem => requestItem.displayFlag && !requestItem.deletedFlag
    )

    reply.customizeCommonItemsList.forEach(requestItem => {
      if (requestItem.optionJson) {
        requestItem.optionJson = JSON.parse(requestItem.optionJson)
      }

      requestItem.selectItemsList.forEach(selectItem => {
        if (selectItem.optionJson) {
          selectItem.optionJson = JSON.parse(selectItem.optionJson)
        }
      })
    })

    if (isTranslatableTarget()) {
      // toTranslateCustomizeRequestTextは非同期のメソッドだが、google apiにコールするわけではないので、実質非同期ではない。
      // なので、可読性重視でPromise.allは使っていないです
      reply.customizeItemsList = await toTranslateCustomizeRequestText(reply.customizeItemsList)
      reply.customizeCommonItemsList = await toTranslateCustomizeRequestText(
        reply.customizeCommonItemsList
      )
    }

    commit('RECEIVE_BASIC', {
      customizeRequestItemsList: reply.customizeItemsList,
      customizeRequestCommonItemsList: reply.customizeCommonItemsList,
      customizeRequestLowestPrice: reply.lowestPrice,
      customizeRequestHighestPrice: reply.highestPrice
    })
  },
  async getOrdermadeRequestEditMaster({ commit, rootState, dispatch }, data) {
    const [MasterService, { GetOrdermadeRequestEditMasterRequest }] = await Promise.all([
      importMasterService(),
      importMasterRequest()
    ])
    const params = data.params
    const setMetadata = commonMetaData(rootState)
    const request = new GetOrdermadeRequestEditMasterRequest()
    request.setMasterCategoryId(params.masterCategoryId)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(MasterService.GetOrdermadeRequestEditMaster, {
        request: request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    reply.ordermadeItemsList = reply.ordermadeItemsList.filter(
      requestItem => requestItem.displayFlag && !requestItem.deletedFlag
    )

    reply.ordermadeItemsList.forEach(requestItem => {
      if (requestItem.optionJson) {
        requestItem.optionJson = JSON.parse(requestItem.optionJson)
      }

      requestItem.selectItemsList.forEach(selectItem => {
        if (selectItem.optionJson) {
          selectItem.optionJson = JSON.parse(selectItem.optionJson)
        }
      })
    })

    reply.ordermadeCommonItemsList = reply.ordermadeCommonItemsList.filter(
      requestItem => requestItem.displayFlag && !requestItem.deletedFlag
    )

    reply.ordermadeCommonItemsList.forEach(requestItem => {
      if (requestItem.optionJson) {
        requestItem.optionJson = JSON.parse(requestItem.optionJson)
      }

      requestItem.selectItemsList.forEach(selectItem => {
        if (selectItem.optionJson) {
          selectItem.optionJson = JSON.parse(selectItem.optionJson)
        }
      })
    })

    if (isTranslatableTarget()) {
      // toTranslateCustomizeRequestTextは非同期のメソッドだが、google apiにコールするわけではないので、実質非同期ではない。
      // なので、可読性重視でPromise.allは使っていないです
      reply.ordermadeItemsList = await toTranslateCustomizeRequestText(reply.ordermadeItemsList)
      reply.ordermadeCommonItemsList = await toTranslateCustomizeRequestText(
        reply.ordermadeCommonItemsList
      )
    }

    commit('RECEIVE_BASIC', {
      customizeRequestItemsList: reply.ordermadeItemsList,
      customizeRequestCommonItemsList: reply.ordermadeCommonItemsList,
      customizeRequestLowestPrice: reply.lowestPrice,
      customizeRequestHighestPrice: reply.highestPrice
    })
  },
  async getUseScenes({ commit, rootState }) {
    const [{ GetUseScenesRequest }, { UseScenesService }] = await Promise.all([
      import('~/stub/apigateway/category/use_scenes_pb'),
      import('~/stub/apigateway/category/use_scenes_pb_service')
    ])
    const useScenes = await grpcClient({
      method: UseScenesService.GetUseScenes,
      request: new GetUseScenesRequest(),
      metadata: commonMetaData(rootState)
    })
    commit('RECEIVE_BASIC', { useScenesList: useScenes.useScenesList })
  },
  async searchCorporates({ rootState }, data) {
    const [{ SearchCorporatesRequest }, { MasterService }] = await Promise.all([
      import('~/stub/apigateway/profile/master_pb'),
      import('~/stub/apigateway/profile/master_pb_service')
    ])

    const request = new SearchCorporatesRequest()
    request.setSearchwords(data.searchWord)
    request.setLimit(data.limit)
    request.setMaxNameLength(data.maxNameLength)

    return await grpcClient({
      method: MasterService.SearchCorporates,
      request,
      metadata: commonMetaData(rootState)
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_BASIC(state, data) {
    Object.assign(state, data)
  },
  RECEIVE_SKILL(state, data) {
    Object.assign(state, { skillsList: data.skillsList })
  },
  RECEIVE_SKILL_CATEGORIES(state, data) {
    Object.assign(state, { skillCategoriesList: data.kindsList })
  },
  RECEIVE_LANGUAGE_SKILL(state, data) {
    Object.assign(state, { languageSkillsList: data.languageSkillsList })
  }
}

// カスタマイズリクエストのテキスト情報を翻訳してstateにセットする
const toTranslateCustomizeRequestText = async list => {
  // 翻訳したい対象の日本語リスト
  const japaneseTextList = list
    .flatMap(item => [
      item.optionJson.placeholder,
      item.optionJson.content,
      item.optionJson.other?.placeholder
    ])
    .filter(v => !!v)

  const toTranslatedTextObj = await toTranslatedArrayText(uniq(japaneseTextList))

  const listCopy = JSON.parse(JSON.stringify(list))
  listCopy.forEach(item => {
    if (item.optionJson.placeholder) {
      item.optionJson.placeholder = toTranslatedTextObj[item.optionJson.placeholder]
    }

    if (item.optionJson.content) {
      item.optionJson.content = toTranslatedTextObj[item.optionJson.content]
    }

    if (item.optionJson.other?.placeholder) {
      item.optionJson.other.placeholder = toTranslatedTextObj[item.optionJson.other.placeholder]
    }
  })

  return listCopy
}
