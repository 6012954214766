// package: analytics
// file: apigateway/analytics/service.proto

import * as apigateway_analytics_service_pb from "../../apigateway/analytics/service_pb";
export class AnalyticsServiceService {
  static serviceName = "analytics.AnalyticsServiceService";
}
export namespace AnalyticsServiceService {
  export class GetAnalyticsService {
    static readonly methodName = "GetAnalyticsService";
    static readonly service = AnalyticsServiceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_analytics_service_pb.GetAnalyticsServiceRequest;
    static readonly responseType = apigateway_analytics_service_pb.GetAnalyticsServiceReply;
  }
  export class UpdateServiceMemo {
    static readonly methodName = "UpdateServiceMemo";
    static readonly service = AnalyticsServiceService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_analytics_service_pb.UpdateServiceMemoRequest;
    static readonly responseType = apigateway_analytics_service_pb.UpdateServiceMemoReply;
  }
}
