import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '~/grpc/grpc-client'

// initial state
const initialState = () => ({
  userInput: {
    enterpriseName: '',
    enterpriseNameKana: '',
    typeOfIndustry: null,
    employeeNumber: null,
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    year: null,
    month: null,
    day: null,
    sex: '',
    prefecture: '',
    department: '',
    mobileTel1: '',
    mobileTel2: '',
    mobileTel3: '',
    reasonOfRegistration: null,
    purposeOfUse: null,
    consideringCategory1: null,
    consideringCategory2: null,
    consideringCategory3: null
  },
  // APIで取得してきた値を格納する
  account: {
    businessCorporation: {
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      annualSales: null,
      employeeNumber: null,
      enterpriseName: '',
      enterpriseNameKana: '',
      enterpriseTel1: '',
      enterpriseTel2: '',
      enterpriseTel3: '',
      id: null,
      postalCode: '',
      prefecture: '',
      representativeFirstName: '',
      representativeFirstNameKana: '',
      representativeLastName: '',
      representativeLastNameKana: '',
      typeOfIndustry: null,
      url: ''
    },
    businessUser: {
      applicantFirstName: '',
      applicantFirstNameKana: '',
      applicantLastName: '',
      applicantLastNameKana: '',
      budget: null,
      consideringCategory1: null,
      consideringCategory2: null,
      consideringCategory3: null,
      day: null,
      isQuestionnaireFinished: false,
      mobileTel1: '',
      mobileTel2: '',
      mobileTel3: '',
      month: null,
      occupation: null,
      role: null,
      sex: '',
      userPrefecture: '',
      year: null
    },
    privateUser: {
      lastName: '',
      firstName: '',
      lastKanaName: '',
      firstKanaName: ''
    }
  },
  pulldownMaster: {
    annualSalesList: [],
    budgetsList: [],
    consideringCategoriesList: [],
    employeeNumbersList: [],
    occupationsList: [],
    prefecturesList: [],
    purposesOfUsesList: [],
    reasonOfRegistrationsList: [],
    typeOfIndustriesList: []
  },
  hasVisitedUserScreen: false,
  isMigratedAccount: false,
  hasFetched: false,
  redirectUrl: ''
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchPulldownMaster({ commit, rootState }) {
    const businessMasterRequestModule = await import(
      '~/stub/apigateway/business/business_master_pb'
    )
    const businessMasterMethodModule = await import(
      '~/stub/apigateway/business/business_master_pb_service'
    )
    const metadata = commonMetaData(rootState)
    const pulldownMaster = await grpcClient({
      method: businessMasterMethodModule.BusinessMasterService.GetPulldownMaster,
      request: new businessMasterRequestModule.GetPulldownMasterRequest(),
      metadata
    })
    commit('SET_PULLDOWN_MASTER', pulldownMaster)
  },
  async fetchAccount({ commit, rootState }) {
    const businessRegistrationRequestModule = await import(
      '~/stub/apigateway/business/business_registration_pb'
    )
    const businessRegistrationMethodModule = await import(
      '~/stub/apigateway/business/business_registration_pb_service'
    )
    const metadata = commonMetaData(rootState)
    const account = await grpcClient({
      method: businessRegistrationMethodModule.BusinessRegistrationService.GetAccount,
      request: new businessRegistrationRequestModule.GetAccountRequest(),
      metadata
    })
    commit('SET_ACCOUNT', account)
  }
}

// mutations
export const mutations = {
  UPDATE_USER_INPUT(state, userInput) {
    Object.assign(state.userInput, userInput)
  },
  SET_ACCOUNT(state, account) {
    Object.assign(state.account, account)
  },
  SET_PULLDOWN_MASTER(state, pulldownMaster) {
    Object.assign(state.pulldownMaster, pulldownMaster)
  },
  SET_VISITED_USER_SCREEN(state, hasVisitedUserScreen) {
    state.hasVisitedUserScreen = hasVisitedUserScreen
  },
  SET_MIGRATED_ACCOUNT(state, isMigratedAccount) {
    state.isMigratedAccount = isMigratedAccount
  },
  SET_HAS_FETCHED(state, hasFetched) {
    state.hasFetched = hasFetched
  }
}
