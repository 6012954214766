import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { GetLatestNewsRequest } from '~/stub/apigateway/news/news_pb'
import { NewsService } from '~/stub/apigateway/news/news_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({
  latestNews: {
    id: null,
    title: '',
    url: ''
  }
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchLatestNews({ commit, rootState }, params = {}) {
    const requestReply = await new Promise((resolve, reject) => {
      const getRequest = new GetLatestNewsRequest()
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(NewsService.GetLatestNews, {
        request: getRequest,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })

    commit('RECEIVE_LATEST_NEWS', requestReply)
  }
}

// mutations
export const mutations = {
  RECEIVE_LATEST_NEWS(state, data) {
    Object.assign(state.latestNews, data)
  }
}
