import Vue from 'vue'

// atoms
import CoconalaIcon from '~/components/atoms/CoconalaIcon'
import CUserIcon from '~/components/atoms/CUserIcon'
import CUserIconV2 from '~/components/atoms/CUserIconV2'
import CButton from '~/components/atoms/CButton'
import CButtonV2 from '~/components/atoms/CButtonV2'
import CCheckbox from '~/components/atoms/CCheckbox'
import CRadio from '~/components/atoms/CRadio'
import CInput from '~/components/atoms/CInput'
import CInputV2 from '~/components/atoms/CInputV2'
import CSelect from '~/components/atoms/CSelect'

// molecules
import CField from '~/components/molecules/CField'
import CModal from '~/components/molecules/CModal'

// atoms
Vue.component('CoconalaIcon', CoconalaIcon)
Vue.component('CUserIcon', CUserIcon)
Vue.component('CUserIconV2', CUserIconV2)
Vue.component('CButton', CButton)
Vue.component('CButtonV2', CButtonV2)
Vue.component('CCheckbox', CCheckbox)
Vue.component('CRadio', CRadio)
Vue.component('CInput', CInput)
Vue.component('CInputV2', CInputV2)
Vue.component('CSelect', CSelect)

// molecules
Vue.component('CField', CField)
Vue.component('CModal', CModal)
