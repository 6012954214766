import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'
import { GetNotificationsRequest } from '~/stub/apigateway/notification_activities/notification_activities_pb'
import { NotificationActivitiesService } from '~/stub/apigateway/notification_activities/notification_activities_pb_service'

const DEFAULT_PER_PAGE = 30

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  fetchNotifications({ commit, rootState }, { currentPage, perPage = DEFAULT_PER_PAGE }) {
    const metadata = commonMetaData(rootState)
    const request = new GetNotificationsRequest()
    request.setPage(currentPage)
    request.setPerPage(perPage)
    request.setIsRead(true)
    return grpcClient({
      method: NotificationActivitiesService.GetNotifications,
      request,
      metadata
    })
  }
}

// mutations
export const mutations = {}
