import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { AddRequest, AddUserActionLogRequest } from '~/stub/apigateway/big_query/log_action_pb'
import { LogActionService } from '~/stub/apigateway/big_query/log_action_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

const toString = value => {
  return typeof value === 'undefined' ? value : String(value)
}

export const state = () => {}

// getters
export const getters = {}

// actions
export const actions = {
  async addLogAction({ rootState }) {
    await new Promise((resolve, reject) => {
      const request = new AddRequest()
      const metadata = commonMetaData(rootState)
      grpc.invoke(LogActionService.Add, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
  },
  async addLogUserAction({ rootState }, { action, detailJson }) {
    await new Promise((resolve, reject) => {
      const request = new AddUserActionLogRequest()
      const metadata = commonMetaData(rootState)
      request.setAction(toString(action))
      request.setDetailJson(toString(detailJson))
      grpc.invoke(LogActionService.AddUserActionLog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {}
