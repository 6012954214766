let deviceMap = {
  FULL: 'pc',
  XL: 'pc',
  L: 'pc',
  M: 'tb',
  S: 'sp'
}

const deviceMapForMypage = {
  FULL: 'pc',
  XL: 'pc',
  L: 'tb',
  M: 'tb',
  S: 'sp'
}

const deviceMapForTalkroom = {
  FULL: 'pc',
  XL: 'pc',
  L: 'tb',
  M: 'tb',
  S: 'sp'
}

// state
export const state = () => ({
  viewport: {
    size: null,
    width: null,
    device: null
  },
  fixedHeaderHeight: 0,
  isActiveLoginModal: false,
  isActiveViolationModal: false,
  footerBottomTb: '0',
  footerBottomSp: '32px',
  // アプリバナーABテスト: アプリバナーの高さ
  appBannerHeight: 0
})

// getters
export const getters = {
  isPC(state) {
    return ['pc'].includes(state.viewport.device)
  },
  isTB(state) {
    return ['tb'].includes(state.viewport.device)
  },
  isSP(state) {
    return ['sp'].includes(state.viewport.device)
  },
  // Myapgeの場合のみ、L以下はSP/TBレイアウトになるため特別な判定を入れる
  isMypagePC(state) {
    const device = deviceMapForMypage[state.viewport.size] || null
    return ['pc'].includes(device)
  },
  // Talkroomの場合のみ、L以下はSP/TBレイアウトになるため特別な判定を入れる
  // TODO: Mypageと一緒なのでLサイズを判定するメソッドに切り分ける
  isTalkroomPC(state) {
    const device = deviceMapForTalkroom[state.viewport.size] || null
    return ['pc'].includes(device)
  },
  hasDeviceJudged(state, getters) {
    return getters.isPC || getters.isTB || getters.isSP
  }
}

// actions
export const actions = {
  syncViewport({ commit }, viewport) {
    commit('RECEIVE_VIEWPORT', { viewport })
  },
  updateFixedHeaderHeight({ commit }, height) {
    commit('RECEIVE_FIXED_HEADER_HEIGHT', { height })
  },
  updateFooterBottomTb({ commit }, height) {
    commit('RECEIVE_FOOTER_BOTTOM_TB', { height })
  },
  updateFooterBottomSp({ commit }, height) {
    commit('RECEIVE_FOOTER_BOTTOM_SP', { height })
  },
  showLoginModal({ commit, rootGetters }) {
    if (process.client && rootGetters['auth/isFromCoconalaApp']) {
      location.href = '/login'
      return
    }
    commit('UPDATE_LOGIN_MODAL_STATE', true)
  },
  closeLoginModal({ commit }) {
    commit('UPDATE_LOGIN_MODAL_STATE', false)
  },
  showViolationModal({ commit }) {
    commit('UPDATE_VIOLATION_MODAL_STATE', true)
  },
  closeViolationModal({ commit }) {
    commit('UPDATE_VIOLATION_MODAL_STATE', false)
  }
}

// mutations
export const mutations = {
  RECEIVE_VIEWPORT(state, { viewport }) {
    state.viewport = {
      ...state.viewport,
      size: viewport.size,
      width: viewport.width,
      device: deviceMap[viewport.size] || null
    }
  },
  RECEIVE_FIXED_HEADER_HEIGHT(state, { height }) {
    state.fixedHeaderHeight = height
  },
  RECEIVE_FOOTER_BOTTOM_TB(state, { height }) {
    state.footerBottomTb = height
  },
  RECEIVE_FOOTER_BOTTOM_SP(state, { height }) {
    state.footerBottomSp = height
  },
  UPDATE_LOGIN_MODAL_STATE(state, isActive) {
    state.isActiveLoginModal = isActive
  },
  UPDATE_VIOLATION_MODAL_STATE(state, isActive) {
    state.isActiveViolationModal = isActive
  },
  // アプリバナーABテスト: バナーの高さを書き換えるためのmutationを追加
  UPDATE_APPBANNER_HEIGHT(state, { height }) {
    state.appBannerHeight = height
  }
}
