import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '~/store/_helpers/common-helper'

// actions
export const actions = {
  async fetchWidgetBlogs({ rootState }, ids) {
    const [{ GetWidgetBlogsRequest }, { WidgetBlogService }] = await Promise.all([
      import('~/stub/apigateway/widget/widget_blog_pb'),
      import('~/stub/apigateway/widget/widget_blog_pb_service')
    ])
    const request = new GetWidgetBlogsRequest()
    request.setBlogIdsList(ids)
    const metadata = commonMetaData(rootState)
    return grpcClient({
      method: WidgetBlogService.GetWidgetBlogs,
      request,
      metadata
    })
  },
  async fetchWidgetServices({ rootState }, ids) {
    const [{ GetWidgetServicesRequest }, { WidgetBlogService }] = await Promise.all([
      import('~/stub/apigateway/widget/widget_blog_pb'),
      import('~/stub/apigateway/widget/widget_blog_pb_service')
    ])
    const request = new GetWidgetServicesRequest()
    request.setServiceIdsList(ids)
    const metadata = commonMetaData(rootState)
    return grpcClient({
      method: WidgetBlogService.GetWidgetServices,
      request,
      metadata
    })
  }
}
