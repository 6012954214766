const COLORS = {
  transparent: 'transparent',
  primary: {
    900: '#005840',
    700: '#00856a',
    600: '#1bb299',
    400: '#9adccf',
    200: '#d1efe9',
    100: '#eff9f7',
    50: '#f6fbfa'
  },
  secondary: {
    900: '#1046b4',
    700: '#0977e5',
    500: '#0098ff',
    300: '#5db7ff',
    100: '#d6ecff',
    75: '#eaf5ff',
    50: '#f4faff'
  },
  gray: {
    900: '#0a2733',
    700: '#3b525c',
    600: '#546871',
    400: '#849298',
    200: '#b6bfc2',
    100: '#ced4d6',
    50: '#e7eaeb',
    25: '#f3f4f5',
    10: '#f9fafb',
    0: '#fff'
  },
  black: '#000',
  pink: {
    500: '#fc6674'
  },
  red: {
    500: '#ff3b30',
    50: '#ffebee'
  },
  orange: {
    700: '#f57d00',
    500: '#ff9900',
    100: '#ffe0b2',
    50: '#fff3e0'
  },
  yellow: {
    700: '#e6d300'
  },
  brand: {
    purple: '#b030dd',
    pink: '#ee4499',
    yellow: '#ffea00',
    green: '#22ccbb',
    blue: '#009dff'
  },
  maintenance: {
    gray: '#c5c5c5'
  },
  footer: {
    main: '#2b2b2b',
    bottom: '#242424'
  }
} as const

export { COLORS }
