import Vue from 'vue'
import util from '../assets/javascripts/util'

/**
 * 3桁ごとにカンマを打つ
 * Usage:
 *   {{ 1000000 | withDelimiter }} => 1,000,000
 */
Vue.filter('withDelimiter', util.numberWithDelimiter)

/**
 * 3桁ごとにカンマを打つ（初期値「-」）
 * Usage:
 *   {{ 1000000 | withDelimiterDash }} => 1,000,000
 *   {{ '' | withDelimiterDash }} => -
 */
Vue.filter('withDelimiterDash', value => {
  return !Number(value) ? '-' : util.numberWithDelimiter(value)
})

/**
 * 3桁ごとにカンマを打つ（初期値「0」）
 * Usage:
 *   {{ 1000000 | withDelimiterZero }} => 1,000,000
 *   {{ '' | withDelimiterZero }} => 0
 */
Vue.filter('withDelimiterZero', value => {
  return !Number(value) ? '0' : util.numberWithDelimiter(value)
})

/**
 * 数字の桁を漢字表記にする（現在万のみ対応）&カンマ区切りにする
 * Usage:
 *   {{ 10000000 | prettyNumber }} => 1,000万
 */
Vue.filter('prettyNumber', value => {
  // filterにすると関数内でthis参照できないのでfilter内で呼び出すようにしている
  return util.prettyNumber(value)
})
