import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({
  serviceId: 0,
  serviceName: '',
  customizeNotice: '',
  providerId: 0,
  providerName: '',
  masterCategory: {
    id: 0,
    name: ''
  },
  parentMasterCategory: {
    id: 0,
    name: ''
  },
  category: {
    id: 0,
    name: ''
  },
  parentCategory: {
    id: 0,
    name: ''
  },
  masterCategoryType: {
    id: 0,
    name: ''
  },
  resizableProfileIcon: '',
  budgetsList: [],
  isAvailableWithholding: false,
  redirectUrl: ''
})

// getters
export const getters = {}

// actions
export const actions = {
  async getCustomizeRequestEditMaster({ commit, rootState }, data) {
    const [{ GetCustomizeRequestEditMasterRequest }, { CustomizeRequestService }] =
      await Promise.all([
        import('~/stub/apigateway/customize_request/customize_request_pb'),
        import('~/stub/apigateway/customize_request/customize_request_pb_service')
      ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetCustomizeRequestEditMasterRequest()
    request.setServiceId(params.serviceId)

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(CustomizeRequestService.GetCustomizeRequestEditMaster, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    commit('RECEIVE_CUSTOMIZE_REQUEST', reply)
  },
  async addCustomizeRequest({ commit, rootState }, data) {
    const [{ AddCustomizeRequestRequest }, { CustomizeRequestService }] = await Promise.all([
      import('~/stub/apigateway/customize_request/v2/customize_request_pb'),
      import('~/stub/apigateway/customize_request/v2/customize_request_pb_service')
    ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new AddCustomizeRequestRequest()

    request.setItemsJson(JSON.stringify(params))

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(CustomizeRequestService.AddCustomizeRequest, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    commit('RECEIVE_CUSTOMIZE_REQUEST', reply)
  },
  async getCustomizeRequestCopy({ commit, rootState }, data) {
    const [{ GetCustomizeRequestCopyRequest }, { CustomizeRequestService }] = await Promise.all([
      import('~/stub/apigateway/customize_request/customize_request_pb'),
      import('~/stub/apigateway/customize_request/customize_request_pb_service')
    ])
    const params = data.params
    const metadata = commonMetaData(rootState)
    const request = new GetCustomizeRequestCopyRequest()
    request.setRequestId(params.requestId)

    return await new Promise((resolve, reject) => {
      grpc.invoke(CustomizeRequestService.GetCustomizeRequestCopy, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_CUSTOMIZE_REQUEST(state, data) {
    Object.assign(state, data)
  }
}
