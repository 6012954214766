// initial state
const initialState = () => ({
  isEditing: false
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {}

// mutations
export const mutations = {
  SET_IS_EDITING(state, isEditing) {
    state.isEditing = isEditing
  }
}
