import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { GetUploadInfoRequest } from '~/stub/apigateway/common/file_upload_pb'
import { FileUploadService } from '~/stub/apigateway/common/file_upload_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async getUploadInfo({ rootState }, { uploadType, fileName, expireSecond = null }) {
    return new Promise((resolve, reject) => {
      const request = new GetUploadInfoRequest()
      request.setUploadType(uploadType)
      request.setFilePath(fileName)
      if (expireSecond) request.setExpireSecond(expireSecond)

      const metadata = commonMetaData(rootState)
      grpc.invoke(FileUploadService.GetUploadInfo, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {}
