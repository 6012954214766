import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

import { GetPastEstimatesRequestRequest } from '~/stub/apigateway/user/past_estimates_request_pb'
import { PastEstimatesRequestService } from '~/stub/apigateway/user/past_estimates_request_pb_service'

// initial state
export const state = () => ({
  pastEstimatesList: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async getPastEstimatesRequest({ commit, rootState }) {
    const metadata = commonMetaData(rootState)
    const request = new GetPastEstimatesRequestRequest()

    const reply = await new Promise((resolve, reject) => {
      grpc.invoke(PastEstimatesRequestService.GetPastEstimatesRequest, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode })
          }
        }
      })
    })

    commit('RECEIVE_PAST_ESTIMATES_REQUEST', reply)
  }
}

// mutations
export const mutations = {
  RECEIVE_PAST_ESTIMATES_REQUEST(state, data) {
    Object.assign(state, data)
  }
}
