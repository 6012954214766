import { Middleware, Context } from '@nuxt/types'
import { Route } from 'vue-router'

const middleware: Middleware = async (context: Context) => {
  const { error, store, route, $util } = context
  const query: Route['query'] =
    route.name === 'users' ? { page: route.query.page } : { ...route.query }
  query.mzhcFlag = store.state.my.header.privateAccountInfo.isMzhc
  await store.dispatch('pages/users/search/searchUsers', query).catch(err => {
    error({ statusCode: $util.grpcCodeToHttpStatusCode(err.grpcCode) })
  })

  const page = Number(query.page)
  if (store.state.pages.users.search.results.length < 1 && page > 1) {
    return error({ statusCode: 404 })
  }
}
export default middleware
