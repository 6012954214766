import { computed } from 'vue-demi'
import { Store } from 'vuex/types/index'

type Versions = {
  availableCoconalaIOSEarliestVersion: string // 使用可能なiOSアプリの最古バージョン
  availableCoconalaAndroidEarliestVersion: string // 使用可能なAndroidアプリの最古バージョン
}

/**
 * 使用できないアプリバージョンかどうかの判定
 * （引数の比較バージョンより小さい場合trueを返す、等しい場合はfalse）
 */
export const isUnavailableCoconalaAppVersion = <T>(
  store: Store<T>,
  { availableCoconalaIOSEarliestVersion, availableCoconalaAndroidEarliestVersion }: Versions
): boolean => {
  const isFromCoconalaAndroid = computed<boolean>(() => store.getters['auth/isFromCoconalaAndroid'])
  const isFromCoconalaIOS = computed<boolean>(() => store.getters['auth/isFromCoconalaIOS'])
  const appVersionLt = computed<(version: string) => boolean>(
    () => store.getters['auth/appVersionLt']
  )

  const isUnavailableUpdateCoconalaIOSVersion = computed(
    () => isFromCoconalaIOS.value && appVersionLt.value(availableCoconalaIOSEarliestVersion)
  )
  const isUnavailableUpdateCoconalaAndroidVersion = computed(
    () => isFromCoconalaAndroid.value && appVersionLt.value(availableCoconalaAndroidEarliestVersion)
  )

  return (
    isUnavailableUpdateCoconalaIOSVersion.value || isUnavailableUpdateCoconalaAndroidVersion.value
  )
}
