// source: domain/enumeration/outsource_working_day_type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType = {
  OUTSOURCE_WORKING_DAY_TYPE_UNSPECIFIED: 0,
  OUTSOURCE_WORKING_DAY_TYPE_WEEK_ONE: 1,
  OUTSOURCE_WORKING_DAY_TYPE_WEEK_TWO: 2,
  OUTSOURCE_WORKING_DAY_TYPE_WEEK_THREE: 3,
  OUTSOURCE_WORKING_DAY_TYPE_WEEK_FOUR: 4,
  OUTSOURCE_WORKING_DAY_TYPE_WEEK_FIVE: 5,
  OUTSOURCE_WORKING_DAY_TYPE_BIWEEKLY: 6,
  OUTSOURCE_WORKING_DAY_TYPE_MONTH_ONE: 7
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
