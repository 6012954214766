import { GetNotificationsRequest } from '~/stub/apigateway/notification/v2/notification_pb'
import { NotificationService } from '~/stub/apigateway/notification/v2/notification_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'

// initial state
export const state = () => ({
  notificationsList: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchToYouNotifications({ commit, rootState }) {
    const setMetadata = commonMetaData(rootState)
    const request = new GetNotificationsRequest()
    const toYouNotificationReply = await grpcClient({
      method: NotificationService.GetToYouNotifications,
      request,
      metadata: setMetadata
    })
    commit('RECEIVE_TO_YOU_NOTIFICATIONS', toYouNotificationReply)
  }
}

// mutations
export const mutations = {
  RECEIVE_TO_YOU_NOTIFICATIONS(state, data) {
    Object.assign(state, data)
  }
}
