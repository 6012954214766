import { ref } from '@nuxtjs/composition-api'
import {
  InterestCategoriesResponseType,
  InterestCategoriesType,
  InterestCategoryDomainType,
  UserInterestCategoryDomainType
} from '~/api/accessors/interest_category/interest-category-accessor'
import { ValidateMessageObjectType } from '~/mixins/form-mixin/jaDs2'

export const MAX_INTEREST_CATEGORY_SELECTABLE_COUNT = 3

const filterInterestCategories = (
  interestCategories: InterestCategoriesResponseType,
  userInterestCategories: UserInterestCategoryDomainType[]
) => {
  // 選択中のカテゴリID + serviceKind。serviceKindは電話占いとテキスト占いの考慮のため必要
  const selectedMasterCategoryKeys = userInterestCategories.map(
    userInterestCategory =>
      `${userInterestCategory.masterCategoryId}-${userInterestCategory.serviceKind}`
  )
  return userInterestCategories.map(category => {
    // 自身の項目以外のカテゴリID
    const masterCategoryKeysWithoutSelected = selectedMasterCategoryKeys.filter(
      selectedMasterCategoryKey =>
        selectedMasterCategoryKey !== `${category.masterCategoryId}-${category.serviceKind}`
    )

    return interestCategories.interestCategoryDomainsList.filter(
      domain =>
        !masterCategoryKeysWithoutSelected.includes(
          `${domain.masterCategoryId}-${domain.serviceKind}`
        )
    )
  })
}

// 選択できる興味のあるカテゴリを制御するhooks
// 他の選択肢で選択されているカテゴリは、重複しないように非表示にする。
export const useFilteredInterestCategories = () => {
  const filteredInterestCategories = ref<InterestCategoriesType[]>([])

  // カテゴリのフィルター状態を更新する
  const updateFilteredInterestCategories = (
    interestCategories: InterestCategoriesResponseType,
    userInterestCategories: UserInterestCategoryDomainType[]
  ) => {
    filteredInterestCategories.value = filterInterestCategories(
      interestCategories,
      userInterestCategories
    )
  }

  return { filteredInterestCategories, updateFilteredInterestCategories }
}

// 興味のあるカテゴリの行ごとにバリデーションを管理するhooks
export const useInterestCategoryValidateMessage = () => {
  const init = () => Array(MAX_INTEREST_CATEGORY_SELECTABLE_COUNT).fill(null)
  const interestCategoriesErrorMessages = ref<(ValidateMessageObjectType | null)[]>(init())
  const resetInterestCategoriesErrorMessages = () => {
    interestCategoriesErrorMessages.value = init()
  }

  const insertErrorMessage = (
    interestCategoryIndex: number,
    validateMessage: ValidateMessageObjectType
  ) => {
    interestCategoriesErrorMessages.value = [...Array(MAX_INTEREST_CATEGORY_SELECTABLE_COUNT)].map(
      (_, index) => {
        return index === interestCategoryIndex ? validateMessage : null
      }
    )
  }

  return {
    interestCategoriesErrorMessages,
    resetInterestCategoriesErrorMessages,
    insertErrorMessage
  }
}

export const findMatchCategory = (
  category: InterestCategoryDomainType | UserInterestCategoryDomainType,
  targetCategory: InterestCategoryDomainType | UserInterestCategoryDomainType
) => {
  return (
    category.masterCategoryId === targetCategory.masterCategoryId &&
    category.serviceKind === targetCategory.serviceKind
  )
}

export const getCategoryImageFileName = ({
  category,
  isIcon
}: {
  category: InterestCategoryDomainType
  isIcon?: boolean
}) => {
  const fileNamePrefix = isIcon ? 'master_category_icon_id' : 'master_category_id'
  return category.serviceKind
    ? `${fileNamePrefix}_${category.masterCategoryId}-${category.serviceKind}`
    : `${fileNamePrefix}_${category.masterCategoryId}`
}
