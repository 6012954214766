import { LanguageType } from '~/constants/language'
import axios from 'axios'
import { throttle } from 'lodash-es'

// 翻訳対象の文字列が入る
let translateApiCallQueue: string[][] = []
// APIの結果が配列で入る
let translateApiResponse: string[][] = []
// APIの結果を格納するためのkey配列
let translateApiResponseKeys: string[] = []

// APIコールをまとめる秒数（ミリ秒）
const THROTTLE_DELAY = 200

/**
 * 
 * google translate api をコールし翻訳した結果を取得する
 * こちらのメソッドが同時に何度呼ばれても大丈夫なように THROTTLE_DELAY ミリ秒だけ待って、
 * 翻訳したい文字列をまとめて、google translate api にコールする
 */
export const getTranslatedText = async (
  targetLanguage: LanguageType,
  textsToTranslate: string[],
  userId: number | null
) => {
  // 日本語がkey, 翻訳先がvalueの配列
  const returnArray = []
  const queueIndex = translateApiCallQueue.push(textsToTranslate) - 1

  // textsToTranslateで指定している翻訳対象のtranslateApiCallQueueのどこに入ったかを調べ、indexを返してもらう
  const { from, to } = getTargetFromToIndex(queueIndex)

  // randomな文字列を生成。
  // randomKeyをもとに、APIレスポンスを受け取る
  const randomKey = Math.random().toString(32).substring(2) // 'a6dpgjqlq8g' 等
  translateApiResponseKeys.push(randomKey)

  await callTranslate(targetLanguage, randomKey, userId)

  translateApiResponse[randomKey]?.forEach((translatedText, index) => {
    if (from <= index && index < to) {
      returnArray[textsToTranslate[index - from]] = translatedText
    }
  })
  // 使い終わったら、メモリを開放するためにリセット
  translateApiResponse[randomKey] = null
  return returnArray
}

const callTranslate = (targetLanguage: LanguageType, apiKey: string, userId: number | null): Promise<void> => {
  return new Promise((resolve, reject) => {
    callTranslateThrottle(targetLanguage, userId)
    let intervalCount = 0
    const MAX_LIMIT_INTERVAL_COUNT = 3000

    // callTranslateThrottleで呼び出した apiコールをsetIntervalで監視して待つ
    const intervalId = setInterval(() => {
      if (!!translateApiResponse[apiKey]) {
        // apiコールが終了し結果が正常に返ってきた
        clearInterval(intervalId)
        return resolve()
      }

      // 無限ループ防止用
      intervalCount++
      if (MAX_LIMIT_INTERVAL_COUNT < intervalCount){
        clearInterval(intervalId)
        return reject()
      }
    }, THROTTLE_DELAY / 3)
  })
}

// THROTTLE_DELAY ミリ秒に1回だけ実行される。
const callTranslateThrottle = throttle(
  async (targetLanguage: LanguageType, userId: number | null) => {
    const textsToTranslate = translateApiCallQueue.flatMap(q => q)
    // APIコールする前にqueueをリセット
    translateApiCallQueue = []

    // APIを待っている間にグローバル変数に書き換えが発生しても問題ないように最初に値を保持
    const _translateApiResponseKeys = translateApiResponseKeys
    // APIコールする前にkeysをリセット
    translateApiResponseKeys = []

    const result = await axios.post<{data: string[]}>(
      '/api/web/translate',
      JSON.stringify({
        targetLanguage,
        textList: textsToTranslate,
        url: `${location.origin}${location.pathname}`,
        userId
      })
    ).catch(() => {
      // 翻訳上限に達した場合、エラーになるので握り潰す
      // エラーログ自体はAPI側で取っているのでクライアント側では不要
      return null
    })

    _translateApiResponseKeys.forEach(key => {
      // 少し無駄ではあるが、getTranslatedTextを呼び出した数だけレスポンスをセットする
      // こうしないと、apiリクエスト中に新しいgetTranslatedTextが呼ばれるとグローバル変数がおかしくなってしまうため
      translateApiResponse[key] = result?.data || []
    })
  },
  THROTTLE_DELAY,
  { leading: false }
)

const getTargetFromToIndex = (queueIndex: number) => {
  let from = 0
  let to = 0

  translateApiCallQueue.forEach((q, index) => {
    if (queueIndex > index) {
      from += q.length
      to += q.length
    } else if (queueIndex === index) {
      to += q.length
    }
  })
  return { from, to }
}
