export const state = () => ({
  logoOnlyMode: false
})

// getters
export const getters = {}

// actions
export const actions = {}

// mutations
export const mutations = {
  UPDATE_LOGO_ONLY_MODE(state, logoOnlyMode) {
    state.logoOnlyMode = logoOnlyMode
  }
}
