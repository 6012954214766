import { grpc } from '@improbable-eng/grpc-web'
import grpcClient from '~/grpc/grpc-client'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import {
  GetAnalyticsServiceRequest,
  UpdateServiceMemoRequest
} from '~/stub/apigateway/analytics/service_pb'
import { AnalyticsServiceService } from '~/stub/apigateway/analytics/service_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

export const state = () => ({
  numberOfView: 0,
  numberOfMaxLineView: 0,
  numberOfPerLineView: 0,
  numberOfSale: 0,
  numberOfMaxLineSale: 0,
  numberOfPerLineSale: 0,
  price: 0,
  numberOfFavorite: 0,
  beginOfDate: '',
  endOfDate: '',
  memo: '',
  targetDay: '',
  numberOfTermsList: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async analyticsService({ commit, rootState }, { serviceId, term }) {
    const request = new GetAnalyticsServiceRequest()
    request.setServiceId(serviceId)
    request.setTargetDay(term)
    const response = await grpcClient({
      method: AnalyticsServiceService.GetAnalyticsService,
      request,
      metadata: commonMetaData(rootState)
    })

    commit('RECEIVE_ANALYTICS_SERVICE', response)
  },
  async updateServiceMemo({ commit, rootState }, { serviceId, memo }) {
    const response = await new Promise((resolve, reject) => {
      const request = new UpdateServiceMemoRequest()
      request.setServiceId(serviceId)
      request.setMemo(memo)
      const metadata = commonMetaData(rootState)
      grpc.invoke(AnalyticsServiceService.UpdateServiceMemo, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })
    commit('UPDATE_SERVICE_MEMO', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_ANALYTICS_SERVICE(state, data) {
    Object.assign(state, {
      ...data
    })
  },
  UPDATE_SERVICE_MEMO(state, data) {
    state.memo = data.memo
  }
}
