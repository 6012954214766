// package: profile
// file: apigateway/profile/favorite.proto

import * as apigateway_profile_favorite_pb from "../../apigateway/profile/favorite_pb";
export class FavoriteService {
  static serviceName = "profile.FavoriteService";
}
export namespace FavoriteService {
  export class AddFavorite {
    static readonly methodName = "AddFavorite";
    static readonly service = FavoriteService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_favorite_pb.FavoriteRequest;
    static readonly responseType = apigateway_profile_favorite_pb.FavoriteReply;
  }
  export class DeleteFavorite {
    static readonly methodName = "DeleteFavorite";
    static readonly service = FavoriteService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_favorite_pb.FavoriteRequest;
    static readonly responseType = apigateway_profile_favorite_pb.FavoriteReply;
  }
}
