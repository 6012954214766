import grpcClient from '~/grpc/grpc-client'
import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import {
  GetInvitationsRequest,
  AddInvitationRequest,
  ReSendInvitationRequest,
  CancelInvitationRequest
} from '~/stub/apigateway/enterprise/invitation_pb'
import { InvitationService } from '~/stub/apigateway/enterprise/invitation_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'
import { toInt32Value } from '~/grpc/grpc-util'

const MAX_AVAILABLE_INVITATION_COUNT = 100

// initial state
const initialState = () => ({
  availableInvitationCount: MAX_AVAILABLE_INVITATION_COUNT,
  domainName: '',
  invitationsList: []
})

export const state = () => initialState()

// getters
export const getters = {}

// actions
export const actions = {
  async fetchInvitations({ commit, rootState }) {
    const response = await new Promise((resolve, reject) => {
      const request = new GetInvitationsRequest()
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(InvitationService.GetInvitations, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })

    commit('RECEIVE_INVITATIONS', response)
  },
  async generateInvitationUrl({ rootState }) {
    const [{ AddInvitationLinkRequest }, { InvitationLinkService }] = await Promise.all([
      import('~/stub/apigateway/enterprise/invitation_link_pb'),
      import('~/stub/apigateway/enterprise/invitation_link_pb_service')
    ])
    const metadata = commonMetaData(rootState)
    const request = new AddInvitationLinkRequest()

    const response = await grpcClient({
      method: InvitationLinkService.AddInvitationLink,
      request,
      metadata,
      strip: true
    })
    return response
  },
  async invite({ dispatch, rootState }, { email, role }) {
    const request = new AddInvitationRequest()
    request.setEmail(email)
    request.setRole(toInt32Value(role))
    const setMetadata = commonMetaData(rootState)
    await grpcClient({
      method: InvitationService.AddInvitation,
      request,
      metadata: setMetadata
    })

    await dispatch('fetchInvitations')
  },
  async reInvite({ dispatch, rootState }, { invitationId }) {
    await new Promise((resolve, reject) => {
      const request = new ReSendInvitationRequest()
      request.setInvitationId(invitationId)
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(InvitationService.ReSendInvitation, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    await dispatch('fetchInvitations')
  },
  async cancelInvitation({ dispatch, rootState }, { invitationId }) {
    await new Promise((resolve, reject) => {
      const request = new CancelInvitationRequest()
      request.setInvitationId(invitationId)
      const setMetadata = commonMetaData(rootState)
      grpc.invoke(InvitationService.CancelInvitation, {
        request,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message: decodedMessage })
          }
        }
      })
    })

    await dispatch('fetchInvitations')
  },
  reset({ commit }) {
    commit('RESET')
  }
}

// mutations
export const mutations = {
  RECEIVE_INVITATIONS(state, data) {
    Object.assign(state, {
      ...data
    })
  },
  RESET(state) {
    Object.assign(state, initialState())
  }
}
