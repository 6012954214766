<template>
  <ORadio
    v-bind="$attrs"
    v-on="$listeners"
    v-model="innerValue"
    :native-value="nativeValue"
  >
    <slot
      v-for="slotName in Object.keys($slots)"
      :name="slotName"
      :slot="slotName"
    />
  </ORadio>
</template>
<script>
export default {
  name: 'CRadio',
  props: {
    value: [String, Number, Boolean, Function, Object, Array, Symbol],
    nativeValue: [String, Number]
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.b-radio.radio {
  ::v-deep {
    input[type='radio'] {
      border-width: 1px;
      background-color: $color-white;
      border: 1px solid $ds2-color-gray-200;
      border-radius: 50%;
      z-index: 1;
      opacity: 1;
      position: relative;
      width: 1.25em;
      height: 1.25em;

      &:checked {
        border-color: $ds2-color-brand-secondary;
        box-shadow: 0 0 6px 0 rgba(9, 119, 229, 0.8);
        &::before {
          background-color: $ds2-color-brand-secondary;
          width: 1.25em;
          height: 1.25em;
          bottom: 50%;
          left: 50%;
          display: flex;
          flex-shrink: 0;
          transition: transform 0.15s ease-out;
          cursor: pointer;
        }
      }
      &:hover {
        border-color: $ds2-color-brand-secondary;
      }
    }
    .check {
      &::before {
        background: $ds2-color-brand-secondary;
      }
    }
  }
  &.is-disabled {
    ::v-deep input[type='radio'] {
      border-color: $ds2-color-border-base;
      &::before {
        background-color: $ds2-color-gray-200;
      }
    }
  }
}
</style>
