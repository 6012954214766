import { AntisocialCheckStatusType } from '~/stub/domain/enumeration/antisocial_check_status_type_pb'
import type { Route } from 'vue-router'

type State = {
  my: { header: { privateAccountInfo: { isMzhc: boolean; mzhcStatus: AntisocialCheckStatusType } } }
}

const viewablePagesWhileIdentity: Route['name'][] = [
  'register-identity_in_progress_mzhc',
  'mizuho_coconala',
  'mizuho_coconala-company_profile'
]

export const checkMzhcIdentityInProgress = (state: State, route: Route) => {
  return (
    state.my.header.privateAccountInfo.isMzhc &&
    state.my.header.privateAccountInfo.mzhcStatus !==
      AntisocialCheckStatusType.ANTISOCIAL_CHECK_STATUS_TYPE_OK &&
    !viewablePagesWhileIdentity.includes(route.name)
  )
}
