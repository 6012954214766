import { computed, onMounted, ref } from '@nuxtjs/composition-api'

// NOTE:
// ※ クライアントサイドでLocalStorageをセットする場合、safariが1週間しか保持されないので、使用する場合はPJに確認を取ること
export const useLocalStorage = (key: string) => {
  const localStorageRef = ref('')
  onMounted(() => {
    // @ts-ignore TS2322
    localStorageRef.value = localStorage.getItem(key)
  })
  return {
    // readonly
    localStorageValue: computed(() => localStorageRef.value),
    localStorageUpdate: (newValue: string) => {
      // localStorageなので、CSR時のみ許容
      if (process.client) {
        localStorage.setItem(key, newValue)
        localStorageRef.value = newValue
      }
    }
  }
}
