<template>
  <nuxt />
</template>

<script>
/**
 * 最小構成レイアウト
 * @type {import('vue').ComponentOptions}
 */
const minHeaderLayout = {
  name: 'LayoutPlain'
}
export default minHeaderLayout
</script>
