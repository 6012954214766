// package: enterprise
// file: apigateway/enterprise/member.proto

import * as apigateway_enterprise_member_pb from "../../apigateway/enterprise/member_pb";
export class MemberService {
  static serviceName = "enterprise.MemberService";
}
export namespace MemberService {
  export class GetMembers {
    static readonly methodName = "GetMembers";
    static readonly service = MemberService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_enterprise_member_pb.GetMembersRequest;
    static readonly responseType = apigateway_enterprise_member_pb.GetMembersReply;
  }
  export class EditMember {
    static readonly methodName = "EditMember";
    static readonly service = MemberService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_enterprise_member_pb.EditMemberRequest;
    static readonly responseType = apigateway_enterprise_member_pb.EditMemberReply;
  }
  export class DeleteMember {
    static readonly methodName = "DeleteMember";
    static readonly service = MemberService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_enterprise_member_pb.DeleteMemberRequest;
    static readonly responseType = apigateway_enterprise_member_pb.DeleteMemberReply;
  }
}
