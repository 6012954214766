import { MarkAsReadNotificationRequest } from '~/stub/apigateway/notification/v2/notification_pb'
import { NotificationService } from '~/stub/apigateway/notification/v2/notification_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  async markAsReadNotification({ rootState }, activityId) {
    const setMetadata = commonMetaData(rootState)
    const request = new MarkAsReadNotificationRequest()
    request.setActivityId(activityId)
    await grpcClient({
      method: NotificationService.MarkAsReadNotification,
      request,
      metadata: setMetadata
    })
  }
}

// mutations
export const mutations = {}
