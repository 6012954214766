import { render, staticRenderFns } from "./DTranslate.vue?vue&type=template&id=3739399a&"
import script from "./DTranslate.vue?vue&type=script&lang=ts&"
export * from "./DTranslate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports