// package: profile
// file: apigateway/profile/gmo_credit_card.proto

import * as apigateway_profile_gmo_credit_card_pb from "../../apigateway/profile/gmo_credit_card_pb";
import * as profileservice_gmo_credit_card_pb from "../../profileservice/gmo_credit_card_pb";
export class GmoCreditCardService {
  static serviceName = "profile.GmoCreditCardService";
}
export namespace GmoCreditCardService {
  export class GetCreditCard {
    static readonly methodName = "GetCreditCard";
    static readonly service = GmoCreditCardService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_profile_gmo_credit_card_pb.GetCreditCardRequest;
    static readonly responseType = apigateway_profile_gmo_credit_card_pb.GetCreditCardReply;
  }
}
