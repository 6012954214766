import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '@/store/_helpers/common-helper'
import {
  GetMyPortfolioRequest,
  GetMyPortfoliosRequest,
  UpdatePortfolioRequest,
  DeletePortfolioRequest
} from '@/stub/apigateway/profile/portfolio_pb'
import { UpdatePortfolioImage, UpdatePortfolioVideo } from '@/stub/profileservice/portfolio_pb'
import { PortfolioService } from '@/stub/apigateway/profile/portfolio_pb_service'

// initial state
const initialState = () => ({
  portfoliosList: [],
  totalCount: 0
})

// state
export const state = () => initialState()

// getters
export const getters = {
  hasNotMorePortfolios(state) {
    return state.portfoliosList.length >= state.totalCount
  }
}

// actions
export const actions = {
  async fetchMyPortfolio({ rootState }, portfolioId) {
    const metadata = commonMetaData(rootState)
    const request = new GetMyPortfolioRequest()
    request.setId(portfolioId)
    const response = await grpcClient({
      method: PortfolioService.GetMyPortfolio,
      request,
      metadata
    })
    return response
  },
  async fetchMyPortfolios({ rootState, commit }, params = {}) {
    const metadata = commonMetaData(rootState)
    const request = new GetMyPortfoliosRequest()
    request.setPer(params.per)
    const response = await grpcClient({
      method: PortfolioService.GetMyPortfolios,
      request,
      metadata
    })
    commit('SET', response)
  },
  async appendMyPortfolios({ rootState, commit }, params = {}) {
    const metadata = commonMetaData(rootState)
    const request = new GetMyPortfoliosRequest()
    request.setFromId(params.fromId)
    request.setPer(params.per)
    const response = await grpcClient({
      method: PortfolioService.GetMyPortfolios,
      request,
      metadata
    })
    commit('APPEND', response)
  },
  async createPortfolio({ rootState, commit }, { params }) {
    const metadata = commonMetaData(rootState)

    const portfolioImage = new UpdatePortfolioImage()
    portfolioImage.setImageData(params.portfolioImage.imageData)

    const portfolioVideo = new UpdatePortfolioVideo()
    portfolioVideo.setUrl(params.portfolioVideo.url)

    const request = new UpdatePortfolioRequest()
    request.setMasterCategoryId(params.masterCategoryId)
    request.setTitle(params.title)
    request.setDescription(params.description)
    request.setWorkedDate(params.workedDate)
    request.setMediaType(params.mediaType)
    request.setPortfolioImage(portfolioImage)
    request.setPortfolioVideo(portfolioVideo)
    request.setTagsList(params.tags)

    const response = await grpcClient({
      method: PortfolioService.UpdatePortfolio,
      request,
      metadata
    })
    commit('CREATE', response.portfolio)
  },
  async updatePortfolio({ rootState, commit }, { id, params }) {
    const metadata = commonMetaData(rootState)

    const portfolioImage = new UpdatePortfolioImage()
    portfolioImage.setId(params.portfolioImage.id)
    portfolioImage.setImageData(params.portfolioImage.imageData)

    const portfolioVideo = new UpdatePortfolioVideo()
    portfolioVideo.setId(params.portfolioVideo.id)
    portfolioVideo.setUrl(params.portfolioVideo.url)

    const request = new UpdatePortfolioRequest()
    request.setId(id)
    request.setMasterCategoryId(params.masterCategoryId)
    request.setTitle(params.title)
    request.setDescription(params.description)
    request.setWorkedDate(params.workedDate)
    request.setMediaType(params.mediaType)
    request.setPortfolioImage(portfolioImage)
    request.setPortfolioVideo(portfolioVideo)
    request.setTagsList(params.tags)

    const response = await grpcClient({
      method: PortfolioService.UpdatePortfolio,
      request,
      metadata
    })
    commit('UPDATE', response.portfolio)
  },
  async deletePortfolio({ rootState, commit }, { id }) {
    const metadata = commonMetaData(rootState)
    const request = new DeletePortfolioRequest()
    request.setId(id)
    await grpcClient({
      method: PortfolioService.DeletePortfolio,
      request,
      metadata
    })
    commit('DELETE', id)
  }
}

// mutations
export const mutations = {
  SET(state, data) {
    Object.assign(state, data)
  },
  APPEND(state, data) {
    state.totalCount = data.totalCount
    data.portfoliosList.forEach(portfolio => {
      state.portfoliosList.push(portfolio)
    })
  },
  RESET(state) {
    Object.assign(state, initialState())
  },
  CREATE(state, portfolio) {
    state.totalCount++
    state.portfoliosList.unshift(portfolio)
  },
  UPDATE(state, portfolio) {
    let target = state.portfoliosList.find(p => p.id === portfolio.id)
    if (!target) return
    Object.assign(target, portfolio)
  },
  DELETE(state, portfolioId) {
    let index = state.portfoliosList.findIndex(p => p.id === portfolioId)
    if (index === -1) return
    state.totalCount--
    state.portfoliosList.splice(index, 1)
  }
}
