// package: notification.v2
// file: apigateway/notification/v2/notification.proto

import * as apigateway_notification_v2_notification_pb from "../../../apigateway/notification/v2/notification_pb";
export class NotificationService {
  static serviceName = "notification.v2.NotificationService";
}
export namespace NotificationService {
  export class GetMyNotificationHeader {
    static readonly methodName = "GetMyNotificationHeader";
    static readonly service = NotificationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_notification_v2_notification_pb.GetMyNotificationHeaderRequest;
    static readonly responseType = apigateway_notification_v2_notification_pb.NotificationHeaderReply;
  }
  export class GetToYouNotifications {
    static readonly methodName = "GetToYouNotifications";
    static readonly service = NotificationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_notification_v2_notification_pb.GetNotificationsRequest;
    static readonly responseType = apigateway_notification_v2_notification_pb.NotificationsReply;
  }
  export class GetTransactionNotifications {
    static readonly methodName = "GetTransactionNotifications";
    static readonly service = NotificationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_notification_v2_notification_pb.GetNotificationsRequest;
    static readonly responseType = apigateway_notification_v2_notification_pb.NotificationsReply;
  }
  export class MarkAsReadNotification {
    static readonly methodName = "MarkAsReadNotification";
    static readonly service = NotificationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_notification_v2_notification_pb.MarkAsReadNotificationRequest;
    static readonly responseType = apigateway_notification_v2_notification_pb.MarkAsReadNotificationReply;
  }
  export class MarkAsReadTransactionNotifications {
    static readonly methodName = "MarkAsReadTransactionNotifications";
    static readonly service = NotificationService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_notification_v2_notification_pb.MarkAsReadNotificationsRequest;
    static readonly responseType = apigateway_notification_v2_notification_pb.MarkAsReadNotificationsReply;
  }
}
