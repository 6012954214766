// package: ordermade_request
// file: apigateway/ordermade_request/ordermade_request.proto

import * as apigateway_ordermade_request_ordermade_request_pb from "../../apigateway/ordermade_request/ordermade_request_pb";
export class OrdermadeRequestService {
  static serviceName = "ordermade_request.OrdermadeRequestService";
}
export namespace OrdermadeRequestService {
  export class GetOrdermadeRequestEditMaster {
    static readonly methodName = "GetOrdermadeRequestEditMaster";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_ordermade_request_pb.GetOrdermadeRequestEditMasterRequest;
    static readonly responseType = apigateway_ordermade_request_ordermade_request_pb.GetOrdermadeRequestEditMasterReply;
  }
  export class AddOrdermadeRequest {
    static readonly methodName = "AddOrdermadeRequest";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_ordermade_request_pb.AddOrdermadeRequestRequest;
    static readonly responseType = apigateway_ordermade_request_ordermade_request_pb.AddOrdermadeRequestReply;
  }
  export class GetOrdermadeRequest {
    static readonly methodName = "GetOrdermadeRequest";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_ordermade_request_pb.GetOrdermadeRequestRequest;
    static readonly responseType = apigateway_ordermade_request_ordermade_request_pb.GetOrdermadeRequestReply;
  }
  export class GetAttachments {
    static readonly methodName = "GetAttachments";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_ordermade_request_pb.GetAttachmentsRequest;
    static readonly responseType = apigateway_ordermade_request_ordermade_request_pb.GetAttachmentsReply;
  }
  export class EditAttachment {
    static readonly methodName = "EditAttachment";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_ordermade_request_pb.EditAttachmentRequest;
    static readonly responseType = apigateway_ordermade_request_ordermade_request_pb.EditAttachmentReply;
  }
  export class GetDeclineReason {
    static readonly methodName = "GetDeclineReason";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_ordermade_request_pb.GetDeclineReasonRequest;
    static readonly responseType = apigateway_ordermade_request_ordermade_request_pb.GetDeclineReasonReply;
  }
  export class AddProviderDecline {
    static readonly methodName = "AddProviderDecline";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_ordermade_request_pb.AddProviderDeclineRequest;
    static readonly responseType = apigateway_ordermade_request_ordermade_request_pb.AddProviderDeclineReply;
  }
  export class GetOrdermadeRequestCopy {
    static readonly methodName = "GetOrdermadeRequestCopy";
    static readonly service = OrdermadeRequestService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_ordermade_request_ordermade_request_pb.GetOrdermadeRequestCopyRequest;
    static readonly responseType = apigateway_ordermade_request_ordermade_request_pb.GetOrdermadeRequestCopyReply;
  }
}
