import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { GetCreditCardRequest } from '~/stub/apigateway/profile/gmo_credit_card_pb'
import { GmoCreditCardService } from '~/stub/apigateway/profile/gmo_credit_card_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({})

// getters
export const getters = {}

// actions
export const actions = {
  // ログインユーザーのクレジットカード情報を取得する
  async getCreditCard({ rootState }) {
    const request = new GetCreditCardRequest()
    // ココナラではカード一枚のみため、Default値である0を入れる
    request.setCardSeq(0)
    const metadata = commonMetaData(rootState)

    return await new Promise((resolve, reject) => {
      grpc.invoke(GmoCreditCardService.GetCreditCard, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (grpcCode, message, trailers) => {
          if (grpcCode !== grpc.Code.OK) {
            reject({ grpcCode, message })
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {}
