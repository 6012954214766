import { grpc } from '@improbable-eng/grpc-web'
import grpcClient from '~/grpc/grpc-client'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

import { HEADER_TYPE_COOKIE } from '~/constants/cookie'

import InterestCategoryAccessor from '~/api/accessors/interest_category/interest-category-accessor'

// initial state
export const state = () => ({
  hasPhoneService: false,
  installedApp: true,
  isProvider: true,
  // 現状、isBloggerを通信で取得して更新しないケースがありうるので、isBloggerの利用を拡大する時は要注意
  isBlogger: true,
  phoneEndTime: 0,
  usablePoint: 0,
  hasPusherPublishingTalkroom: false,
  hasPusherPublishing: false,
  hasFetchedProvider: false,
  hasFetchedBlogger: false,
  headerTypeCookie: '',
  hasFetchedMyAccountInfo: false,
  businessAccountInfo: {},
  privateAccountInfo: {},
  isActiveSearchDrawer: false,
  userInputSearchText: '',
  hasRegisteredInterestCategories: false
})

// getters
export const getters = {
  getCurrentLangCode(state) {
    if (Object.keys(state.privateAccountInfo).length) return state.privateAccountInfo.langCode
    if (Object.keys(state.businessAccountInfo).length) return state.businessAccountInfo.langCode
    return ''
  }
}

// actions
export const actions = {
  async fetchHeader({ commit, rootState }) {
    const requestPromise = await new Promise(async (resolve, reject) => {
      const [{ GetHeaderRequest }, { HeaderService }] = await Promise.all([
        import('~/stub/apigateway/header/header_pb'),
        import('~/stub/apigateway/header/header_pb_service')
      ])
      let getHeader = new GetHeaderRequest()
      let setMetadata = commonMetaData(rootState)

      grpc.invoke(HeaderService.GetHeader, {
        request: getHeader,
        metadata: setMetadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })

    commit('RECEIVE_HEADER', requestPromise)
  },
  async fetchAccountInfo({ rootGetters, commit, rootState }) {
    const [{ GetMyAccountInfoRequest }, { HeaderService }] = await Promise.all([
      import('~/stub/apigateway/header/header_pb'),
      import('~/stub/apigateway/header/header_pb_service')
    ])
    const request = new GetMyAccountInfoRequest()
    const metadata = commonMetaData(rootState)
    const loginSessionkey = rootState.auth.sToken
    const privateSessionKey = rootState.auth.sTokenP
    const businessSessionKey = rootState.auth.sTokenE
    const sessionKeys = []
    if (privateSessionKey) {
      sessionKeys.push(privateSessionKey)
    }
    if (businessSessionKey) {
      sessionKeys.push(businessSessionKey)
    }
    if (sessionKeys.length === 0) {
      sessionKeys.push(loginSessionkey)
    }
    request.setSessionKeysList(sessionKeys)
    try {
      const response = await grpcClient({
        method: HeaderService.GetMyAccountInfo,
        request,
        metadata
      })
      commit('RECEIVE_MY_ACCOUNT_INFO', response)
    } catch ({ grpcCode }) {
      return Promise.reject(grpcCode)
    }
  },
  async fetchIsBlogger({ commit, rootState }) {
    const response = await new Promise(async (resolve, reject) => {
      const [{ GetIsBloggerRequest }, { BlogService }] = await Promise.all([
        import('~/stub/apigateway/profile/blog_pb'),
        import('~/stub/apigateway/profile/blog_pb_service')
      ])
      const request = new GetIsBloggerRequest()
      const metadata = commonMetaData(rootState)
      grpc.invoke(BlogService.GetIsBlogger, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })
    commit('RECEIVE_IS_BLOGGER', response)
  },
  falseInstalledApp({ commit }) {
    commit('FALSE_INSTALLED_APP')
  },
  async fetchUserInterestCategories({ commit, rootState }) {
    const interestCategoryAccessor = new InterestCategoryAccessor(rootState)
    const response = await interestCategoryAccessor.getUserInterestCategories()
    commit('SET_USER_INTEREST_CATEGORIES_FLAG', response)
  }
}

// mutations
export const mutations = {
  RECEIVE_HEADER(state, data) {
    Object.assign(state, data)
    state.hasFetchedProvider = true
  },
  RECEIVE_IS_BLOGGER(state, data) {
    state.isBlogger = data.isBlogger
    state.hasFetchedBlogger = true
  },
  FALSE_INSTALLED_APP(state) {
    state.installedApp = false
  },
  SET_COOKIE_HEADER_TYPE(state, data) {
    state.headerTypeCookie = data
  },
  SET_COOKIE_HEADER_TYPE_PROVIDER(state) {
    state.headerTypeCookie = HEADER_TYPE_COOKIE.VALUE_PROVIDER
    this.$cookies.set(
      HEADER_TYPE_COOKIE.NAME,
      HEADER_TYPE_COOKIE.VALUE_PROVIDER,
      HEADER_TYPE_COOKIE.OPTS
    )
  },
  SET_COOKIE_HEADER_TYPE_BUYER(state) {
    state.headerTypeCookie = HEADER_TYPE_COOKIE.VALUE_BUYER
    this.$cookies.set(
      HEADER_TYPE_COOKIE.NAME,
      HEADER_TYPE_COOKIE.VALUE_BUYER,
      HEADER_TYPE_COOKIE.OPTS
    )
  },
  RECEIVE_MY_ACCOUNT_INFO(state, data) {
    if (data.privateAccountInfo) {
      Object.assign(state.privateAccountInfo, data.privateAccountInfo)
    }
    if (data.businessAccountInfo) {
      Object.assign(state.businessAccountInfo, data.businessAccountInfo)
    }
    state.hasFetchedMyAccountInfo = true
  },
  TOGGLE_SEARCH_DRAWER(state, flag = null) {
    if (flag === null) {
      // パラメータが指定されていないときは反転させる
      flag = !state.isActiveSearchDrawer
    }
    state.isActiveSearchDrawer = flag
  },
  SAVE_USER_INPUT_SERCH_TEXT(state, data) {
    state.userInputSearchText = data
  },
  SET_USER_INTEREST_CATEGORIES_FLAG(state, data) {
    state.hasRegisteredInterestCategories = data.userInterestCategoryDomainsList.length > 0
  },
  SET_USER_INTEREST_CATEGORIES_TRUE(state) {
    state.hasRegisteredInterestCategories = true
  },
  SET_HEADER_TYPE(state, headerType) {
    state.headerType = headerType
  }
}
