import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '~/store/_helpers/common-helper'
import {
  GetTransactionsRequest,
  UpdateTransactionRequest
} from '@/stub/apigateway/transaction_activities/transaction_activities_pb'
import { TransactionActivitiesService } from '@/stub/apigateway/transaction_activities/transaction_activities_pb_service'
import {
  GetDirectMessagesRequest,
  GetUserHeaderRequest,
  MarkAsReadRequest,
  TransactionBadge
} from '~/stub/apigateway/activities/activities_pb'
import { ActivitiesService } from '~/stub/apigateway/activities/activities_pb_service'
import { GetNotificationsRequest } from '~/stub/apigateway/notification_activities/notification_activities_pb'
import { NotificationActivitiesService } from '~/stub/apigateway/notification_activities/notification_activities_pb_service'

// initial state
export const state = () => ({
  transactionNotifications: {
    totalCount: undefined,
    transactionsList: []
  },
  messageNotifications: {
    totalCount: undefined,
    directMessagesList: []
  },
  activityNotifications: {
    totalCount: undefined,
    notificationsList: []
  },
  userHeader: {
    notificationBadgeFlag: false,
    dmCount: 0,
    transactionCount: 0,
    coupon: {
      isExpiring: false,
      discount: 0,
      discountType: null,
      expireDate: null,
      displayName: '',
      description: '',
      isMasterCategoryLimitCoupon: false
    },
    buyerTransactionBadges: {
      actionRequiredEstimateCount: 0,
      actionRequiredTalkroomCount: 0,
      hasUnreadTradingTalkroom: false,
      hasUnreadCancelTalkroom: false,
      hasUnreadCompleteTalkroom: false
    },
    providerTransactionBadges: {
      actionRequiredEstimateCount: 0,
      actionRequiredTalkroomCount: 0,
      hasUnreadTradingTalkroom: false,
      hasUnreadCancelTalkroom: false,
      hasUnreadCompleteTalkroom: false
    }
  }
})

const DEFAULT_PER_PAGE = 20

// getters
export const getters = {}

// actions
export const actions = {
  /**
   * 取引通知の一覧を取得する
   */
  async fetchTransactions({ commit, rootState }) {
    const metadata = commonMetaData(rootState)
    const request = new GetTransactionsRequest()
    request.setPage(1)
    request.setPerPage(DEFAULT_PER_PAGE)
    request.setIconSize('s')
    const transactionsReply = await grpcClient({
      method: TransactionActivitiesService.GetTransactions,
      request,
      metadata
    })
    commit('RECEIVE_TRANSACTION', transactionsReply)
  },

  /**
   * メッセージの一覧を取得する
   */
  async fetchDirectMessages({ commit, rootState }) {
    const metadata = commonMetaData(rootState)
    const request = new GetDirectMessagesRequest()
    request.setPage(1)
    request.setPerPage(DEFAULT_PER_PAGE)
    request.setIconSize('s')
    const directMessagesReply = await grpcClient({
      method: ActivitiesService.GetDirectMessages,
      request,
      metadata
    })
    commit('RECEIVE_DIRECT_MESSAGES', directMessagesReply)
  },

  /**
   * お知らせの一覧を取得する
   */
  async fetchActivities({ commit, rootState }) {
    const metadata = commonMetaData(rootState)
    const request = new GetNotificationsRequest()
    request.setPage(1)
    request.setPerPage(DEFAULT_PER_PAGE)
    request.setIsRead(true)
    const activitiesReply = await grpcClient({
      method: NotificationActivitiesService.GetNotifications,
      request,
      metadata
    })
    commit('RECEIVE_ACTIVITIES', activitiesReply)
  },

  /**
   * 未読件数の取得する
   */
  async fetchUserHeader({ commit, rootState }) {
    const metadata = commonMetaData(rootState)
    const request = new GetUserHeaderRequest()
    const userHeaderReply = await grpcClient({
      method: ActivitiesService.GetUserHeader,
      request,
      metadata
    })
    commit('RECEIVE_USER_HEADER', userHeaderReply)
  },

  /**
   * 取引通知の状態の更新を行う（新形式）
   */
  async updateTransaction({ commit, rootState }, { groupType, groupId, triggerType }) {
    const metadata = commonMetaData(rootState)
    const request = new UpdateTransactionRequest()
    request.setGroupType(groupType)
    request.setGroupId(groupId)
    request.setTriggerType(triggerType)
    await grpcClient({
      method: TransactionActivitiesService.UpdateTransaction,
      request,
      metadata
    })
  },

  /**
   * 取引通知の状態の更新を行う（旧形式）
   */
  async updateOldTransaction({ commit, rootState }, { activityId }) {
    const metadata = commonMetaData(rootState)
    const request = new MarkAsReadRequest()
    request.setActivityId(activityId)
    await grpcClient({
      method: ActivitiesService.MarkAsRead,
      request,
      metadata
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_TRANSACTION(state, data) {
    state.transactionNotifications = data
  },
  RECEIVE_DIRECT_MESSAGES(state, data) {
    state.messageNotifications = data
  },
  RECEIVE_ACTIVITIES(state, data) {
    state.activityNotifications = data
  },
  RECEIVE_USER_HEADER(state, data) {
    state.userHeader = {
      buyerTransactionBadges: new TransactionBadge().toObject(),
      providerTransactionBadges: new TransactionBadge().toObject(),
      ...data
    }
  }
}
