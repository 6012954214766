var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:_setup.buttonVariants({
      size: _setup.props.size,
      type: _setup.props.type,
      isDisabled: _setup.props.isDisabled,
      isFullWidth: _setup.props.isFullWidth,
      isLoading: _setup.props.isLoading
    }),attrs:{"disabled":_setup.props.isDisabled || _setup.props.isLoading,"type":_setup.props.htmlType},on:{"click":_setup.onClick}},[(_setup.props.leftIcon)?_c('span',{staticClass:"tw-mr-1 tw-inline-flex",class:_setup.props.isFixedIcon ? _setup.clsx('tw-absolute tw-left-4 tw-top-1/2 -tw-translate-y-1/2') : null},[_c(_setup.AppIcon,{attrs:{"name":_setup.props.leftIcon,"size":_setup.props.size,"color":_setup.iconColor}})],1):_vm._e(),_vm._v(" "),(_setup.props.isLoading)?_c(_setup.AppLoader,{attrs:{"size":_setup.loaderSize,"color":_setup.loaderColor}}):_c('span',[_vm._t("default")],2),_vm._v(" "),(_setup.props.rightIcon)?_c('span',{staticClass:"tw-ml-1 tw-inline-flex",class:_setup.props.isFixedIcon ? _setup.clsx('tw-absolute tw-right-4 tw-top-1/2 -tw-translate-y-1/2') : null},[_c(_setup.AppIcon,{attrs:{"name":_setup.props.rightIcon,"size":_setup.props.size,"color":_setup.iconColor}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }