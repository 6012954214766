import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { AddSelectLogRequest, GetSearchQueryRequest } from '~/stub/apigateway/suggest/service_pb'
import { ServiceService } from '~/stub/apigateway/suggest/service_pb_service'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({
  suggestResult: []
})

// getters
export const getters = {}

// actions
export const actions = {
  async fetchSuggest({ commit, rootState }, params = {}) {
    const requestReply = await new Promise((resolve, reject) => {
      const request = new GetSearchQueryRequest()
      const metadata = commonMetaData(rootState)
      // TODO: protoにkeyword_onlyが追加された後に、setKeywordOnlyを指定する
      request.setBaseQuery(params.keyword)
      grpc.invoke(ServiceService.GetSearchQuery, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })
    commit('RECEIVE_SUGGEST_RESULT', requestReply)
  },

  async clearSuggest({ commit }) {
    commit('CLEAR_SUGGEST_RESULT')
  },

  async sendSuggestSelectLog({ commit, rootState }, params = {}) {
    await new Promise((resolve, reject) => {
      const request = new AddSelectLogRequest()
      const metadata = commonMetaData(rootState)
      const { baseQuery, selected, suggestion } = params
      request.setBaseQuery(baseQuery)
      request.setSelected(selected)
      request.setSuggestion(suggestion)
      grpc.invoke(ServiceService.AddSelectLog, {
        request,
        metadata,
        host: process.env.config.grpcWebUrl,
        transport: NodeHttpTransport(),
        onMessage: message => {
          resolve(message.toObject())
        },
        onEnd: (code, msg, trailers) => {
          if (code !== grpc.Code.OK) {
            reject(code)
          }
        }
      })
    })
  }
}

// mutations
export const mutations = {
  RECEIVE_SUGGEST_RESULT(state, data) {
    state.suggestResult = data.dataList
  },
  CLEAR_SUGGEST_RESULT(state) {
    state.suggestResult = []
  }
}
