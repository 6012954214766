import { Middleware } from '@nuxt/types'
import { Route, Location } from 'vue-router'
import { EntryFormStatus } from '~/stub/domain/enumeration/entry_form_status_pb'
import { EntryFormPurposeType } from '~/stub/domain/enumeration/entry_form_purpose_type_pb'
import { EntryFormOrganizationType } from '~/stub/domain/enumeration/entry_form_organization_type_pb'
import { IS_BACK_STATUS } from '~/constants/register'
import { isSingleQuery } from '~/components/logics/utils'
import RegisterAccessor from '~/api/accessors/auth/register-accessor'
import { Store } from 'vuex'
import { NuxtAppOptions } from '@nuxt/types'

export const usePreAuthHash = (
  route: Route,
  purpose: EntryFormPurposeType,
  privateCorporation: EntryFormOrganizationType
) => {
  const currentPath = route.path
  const query = route.query

  /**
   * 基本情報登録画面へのリダイレクト情報を返す
   */
  const locationBasicInfo = (): Location => {
    // 発注・個人の場合
    if (
      purpose === EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER &&
      privateCorporation === EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_PRIVATE
    ) {
      if (currentPath === '/register/buyer/individual') return {}
      return {
        path: '/register/buyer/individual',
        query,
        replace: true
      }
    }

    // 発注・法人の場合
    if (
      purpose === EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER &&
      privateCorporation === EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
    ) {
      if (currentPath === '/register/buyer/corporate') return {}
      return {
        path: '/register/buyer/corporate',
        query,
        replace: true
      }
    }

    // 受注・個人の場合
    if (
      purpose === EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_SELLER &&
      privateCorporation === EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_PRIVATE
    ) {
      if (currentPath === '/register/provider/individual') return {}
      return {
        path: '/register/provider/individual',
        query,
        replace: true
      }
    }

    // 受注・法人の場合
    if (
      purpose === EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_SELLER &&
      privateCorporation === EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_CORPORATION
    ) {
      if (currentPath === '/register/provider/corporate') return {}
      return {
        path: '/register/provider/corporate',
        query,
        replace: true
      }
    }

    return {
      path: '/register/top',
      query: { error_status: '1' }
    }
  }

  /**
   * ステータス毎のリダイレクト情報を返す
   */
  const locationByStatus = (status: EntryFormStatus): Location => {
    switch (status) {
      case EntryFormStatus.ENTRY_FORM_STATUS_MEMBER_REGISTRATION: // 会員登録形式を選択済
        if (currentPath === '/register/buyer_provider') return {}
        return {
          path: '/register/buyer_provider',
          query,
          replace: true
        }
      case EntryFormStatus.ENTRY_FORM_STATUS_AUTH_EMAIL: // メール認証済
        return { path: '/register/top', replace: true }
      case EntryFormStatus.ENTRY_FORM_STATUS_REGISTRATION_PURPOSE: {
        // 登録目的を選択済

        const { is_back } = query
        const isBack = isSingleQuery(is_back) ? is_back : ''

        // 登録目的選択画面にis_backパラメータがあったら、リダイレクトしない
        if (Number(isBack) === IS_BACK_STATUS && currentPath === '/register/buyer_provider') {
          return {}
        }
        if (currentPath === '/register/individual_corporate') return {}

        return {
          path: '/register/individual_corporate',
          query,
          replace: true
        }
      }
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_CORPORATION: // 個人法人を選択済
        return locationBasicInfo()
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_BUYER_BASIC:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_BUYER_INTERESTED:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_BUYER_BASIC:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_BUYER_CORPORATION:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_BUYER_INTERESTED:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_BUYER_ORDER_FORM:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_BUYER_BUDGET:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_SELLER_BASIC:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_SELLER_BASIC_2:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_SELLER_EXPERIENCE_JOB:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_SELLER_EXPERIENCE_YEARS:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_SELLER_CONTRACT_STYLE:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_SELLER_WORKING_CONDITION:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_SELLER_BASIC:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_SELLER_CORPORATION:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_SELLER_EXPERIENCE_JOB:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_SELLER_EXPERIENCE_YEARS:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_SELLER_CONTRACT_STYLE:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_SELLER_WORKING_CONDITION:
        // 基本情報登録済の場合、ログイン画面にリダイレクト
        return { path: '/login', replace: true }
      case EntryFormStatus.ENTRY_FORM_STATUS_COMPLETED:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_BUYER_USER_NAME:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_BUYER_USER_NAME:
      case EntryFormStatus.ENTRY_FORM_STATUS_PRIVATE_SELLER_USER_NAME:
      case EntryFormStatus.ENTRY_FORM_STATUS_CORPORATE_SELLER_USER_NAME:
        // ユーザー登録が完了すると、トップへリダイレクト
        return { path: '/', replace: true }
      case EntryFormStatus.ENTRY_FORM_STATUS_UNSPECIFIED:
      default:
        return { path: '/register/top', query: { error_status: '1' }, replace: true }
    }
  }

  /**
   * 発注者として登録する
   */
  const registerAsOrder = (
    app: NuxtAppOptions,
    store: Store<{ register: { type: string } }>,
    hashToken: string,
    ccuid: string,
    registerAccessor: RegisterAccessor
  ) => {
    const params = {
      preAuthHash: hashToken,
      purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER
    }
    return registerAccessor
      .updateRegistrationPurpose(params, {
        type: store.state.register.type,
        registration_ccuid: ccuid,
        purpose: params.purpose
      })
      .catch(err => {
        app.$sentry.captureException(err)
        throw err
      })
  }

  /**
   * 個人として登録する
   */
  const registerAsIndividual = (
    app: NuxtAppOptions,
    store: Store<{ register: { type: string } }>,
    hashToken: string,
    ccuid: string,
    registerAccessor: RegisterAccessor
  ) => {
    const params = {
      preAuthHash: hashToken,
      privateCorporation: EntryFormOrganizationType.ENTRY_FORM_ORGANIZATION_TYPE_PRIVATE
    }
    return registerAccessor
      .updatePrivateCorporation(params, {
        type: store.state.register.type,
        registration_ccuid: ccuid,
        purpose: EntryFormPurposeType.ENTRY_FORM_PURPOSE_TYPE_BUYER,
        private_corporation: params.privateCorporation
      })
      .catch(err => {
        app.$sentry.captureException(err)
        throw err
      })
  }

  return {
    locationBasicInfo,
    locationByStatus,
    registerAsOrder,
    registerAsIndividual
  }
}

/**
 * 認証メール内URLのハッシュが有効か確認してリダイレクトさせる
 */
const preAuthHash: Middleware = async ({ app, store, route, redirect }) => {
  if (store.getters['auth/isLoggedIn']) redirect('/')

  const { pre_auth_hash } = route.query
  const hashToken = isSingleQuery(pre_auth_hash) ? pre_auth_hash : ''

  // トークンがない時は、認証エラーで会員登録トップにリダイレクト
  if (!hashToken) redirect('/register/top?error_status=1')
  const RegisterAccessor = (await import('~/api/accessors/auth/register-accessor')).default
  const registerAccessor = new RegisterAccessor(store.state)

  try {
    const response = await registerAccessor.confirmPreAuthHash(hashToken)
    const { status, ccuid, type, purpose, privateCorporation, isMzhc } = response

    store.commit('register/updateSelectedPurpose', purpose)
    store.commit('register/updateSelectedPrivateCorporation', privateCorporation)
    store.commit('register/updateCcuid', ccuid)
    store.commit('register/updateType', type)
    store.commit('register/setIsMzhc', isMzhc)

    const { locationByStatus, registerAsOrder, registerAsIndividual } = usePreAuthHash(
      route,
      purpose,
      privateCorporation
    )

    // 外国人が登録目的ステータスの場合
    if (
      app.$translate.isTranslatableTarget() &&
      status === EntryFormStatus.ENTRY_FORM_STATUS_MEMBER_REGISTRATION &&
      ccuid
    ) {
      // 登録フォームと同じ順番で登録処理をする
      await registerAsOrder(app, store, hashToken, ccuid, registerAccessor)
      await registerAsIndividual(app, store, hashToken, ccuid, registerAccessor)
      redirect({ path: '/register/buyer/individual', query: route.query, replace: true })
    }

    const location = locationByStatus(status)
    if (Object.keys(location).length === 0) return
    redirect(location)
  } catch (err) {
    try {
      const response = await registerAccessor.getIsMzhc(hashToken)
      const { isMzhc } = response
      const path = isMzhc ? '/register/top_mzhc' : '/register/top'
      redirect({ path, query: { error_status: '1' } })
    } catch (err) {
      redirect({ path: '/register/top', query: { error_status: '1' } })
    }
  }
}
export default preAuthHash
