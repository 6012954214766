// source: gw/outsource/outsource_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var domain_outsource_pb = require('../../domain/outsource_pb.js');
goog.object.extend(proto, domain_outsource_pb);
var domain_search_outsource_pb = require('../../domain/search_outsource_pb.js');
goog.object.extend(proto, domain_search_outsource_pb);
var domain_enumeration_outsource_status_pb = require('../../domain/enumeration/outsource_status_pb.js');
goog.object.extend(proto, domain_enumeration_outsource_status_pb);
var domain_enumeration_outsource_payment_method_type_pb = require('../../domain/enumeration/outsource_payment_method_type_pb.js');
goog.object.extend(proto, domain_enumeration_outsource_payment_method_type_pb);
var domain_enumeration_outsource_working_day_type_pb = require('../../domain/enumeration/outsource_working_day_type_pb.js');
goog.object.extend(proto, domain_enumeration_outsource_working_day_type_pb);
goog.exportSymbol('proto.outsource.CreateOutsourceRequest', null, global);
goog.exportSymbol('proto.outsource.CreateOutsourceResponse', null, global);
goog.exportSymbol('proto.outsource.GetMyOutsourceDetailRequest', null, global);
goog.exportSymbol('proto.outsource.GetMyOutsourceDetailResponse', null, global);
goog.exportSymbol('proto.outsource.GetMyOutsourcesRequest', null, global);
goog.exportSymbol('proto.outsource.GetMyOutsourcesResponse', null, global);
goog.exportSymbol('proto.outsource.GetOutsourceDetailRequest', null, global);
goog.exportSymbol('proto.outsource.GetOutsourceDetailResponse', null, global);
goog.exportSymbol('proto.outsource.IsAuthorizedRequest', null, global);
goog.exportSymbol('proto.outsource.IsAuthorizedResponse', null, global);
goog.exportSymbol('proto.outsource.MyOutsorucesFilter', null, global);
goog.exportSymbol('proto.outsource.MyOutsource', null, global);
goog.exportSymbol('proto.outsource.Outsource', null, global);
goog.exportSymbol('proto.outsource.OutsourceCompany', null, global);
goog.exportSymbol('proto.outsource.OutsourceCompanyContact', null, global);
goog.exportSymbol('proto.outsource.OutsourceDetail', null, global);
goog.exportSymbol('proto.outsource.OutsourceDetailCount', null, global);
goog.exportSymbol('proto.outsource.OutsourceUploaded', null, global);
goog.exportSymbol('proto.outsource.SearchOutsourcesRequest', null, global);
goog.exportSymbol('proto.outsource.SearchOutsourcesResponse', null, global);
goog.exportSymbol('proto.outsource.SetBookmarkRequest', null, global);
goog.exportSymbol('proto.outsource.SetBookmarkResponse', null, global);
goog.exportSymbol('proto.outsource.UpdateOutsourceRequest', null, global);
goog.exportSymbol('proto.outsource.UpdateOutsourceResponse', null, global);
goog.exportSymbol('proto.outsource.UpdateStatusRequest', null, global);
goog.exportSymbol('proto.outsource.UpdateStatusResponse', null, global);
goog.exportSymbol('proto.outsource.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.CreateOutsourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.CreateOutsourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.CreateOutsourceRequest.displayName = 'proto.outsource.CreateOutsourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.CreateOutsourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.CreateOutsourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.CreateOutsourceResponse.displayName = 'proto.outsource.CreateOutsourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.UpdateOutsourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.UpdateOutsourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.UpdateOutsourceRequest.displayName = 'proto.outsource.UpdateOutsourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.UpdateOutsourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.UpdateOutsourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.UpdateOutsourceResponse.displayName = 'proto.outsource.UpdateOutsourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.UpdateStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.UpdateStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.UpdateStatusRequest.displayName = 'proto.outsource.UpdateStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.UpdateStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.UpdateStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.UpdateStatusResponse.displayName = 'proto.outsource.UpdateStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.GetMyOutsourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.GetMyOutsourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.GetMyOutsourcesRequest.displayName = 'proto.outsource.GetMyOutsourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.MyOutsorucesFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.MyOutsorucesFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.MyOutsorucesFilter.displayName = 'proto.outsource.MyOutsorucesFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.GetMyOutsourcesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.outsource.GetMyOutsourcesResponse.repeatedFields_, null);
};
goog.inherits(proto.outsource.GetMyOutsourcesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.GetMyOutsourcesResponse.displayName = 'proto.outsource.GetMyOutsourcesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.MyOutsource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.MyOutsource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.MyOutsource.displayName = 'proto.outsource.MyOutsource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.SearchOutsourcesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.outsource.SearchOutsourcesRequest.repeatedFields_, null);
};
goog.inherits(proto.outsource.SearchOutsourcesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.SearchOutsourcesRequest.displayName = 'proto.outsource.SearchOutsourcesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.SearchOutsourcesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.outsource.SearchOutsourcesResponse.repeatedFields_, null);
};
goog.inherits(proto.outsource.SearchOutsourcesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.SearchOutsourcesResponse.displayName = 'proto.outsource.SearchOutsourcesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.SetBookmarkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.SetBookmarkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.SetBookmarkRequest.displayName = 'proto.outsource.SetBookmarkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.SetBookmarkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.SetBookmarkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.SetBookmarkResponse.displayName = 'proto.outsource.SetBookmarkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.GetOutsourceDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.GetOutsourceDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.GetOutsourceDetailRequest.displayName = 'proto.outsource.GetOutsourceDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.GetMyOutsourceDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.GetMyOutsourceDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.GetMyOutsourceDetailRequest.displayName = 'proto.outsource.GetMyOutsourceDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.GetOutsourceDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.outsource.GetOutsourceDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.outsource.GetOutsourceDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.GetOutsourceDetailResponse.displayName = 'proto.outsource.GetOutsourceDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.GetMyOutsourceDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.outsource.GetMyOutsourceDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.outsource.GetMyOutsourceDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.GetMyOutsourceDetailResponse.displayName = 'proto.outsource.GetMyOutsourceDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.Outsource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.Outsource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.Outsource.displayName = 'proto.outsource.Outsource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.OutsourceDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.OutsourceDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.OutsourceDetail.displayName = 'proto.outsource.OutsourceDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.OutsourceUploaded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.OutsourceUploaded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.OutsourceUploaded.displayName = 'proto.outsource.OutsourceUploaded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.OutsourceCompany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.OutsourceCompany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.OutsourceCompany.displayName = 'proto.outsource.OutsourceCompany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.User.displayName = 'proto.outsource.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.OutsourceDetailCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.OutsourceDetailCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.OutsourceDetailCount.displayName = 'proto.outsource.OutsourceDetailCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.OutsourceCompanyContact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.OutsourceCompanyContact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.OutsourceCompanyContact.displayName = 'proto.outsource.OutsourceCompanyContact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.IsAuthorizedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.IsAuthorizedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.IsAuthorizedRequest.displayName = 'proto.outsource.IsAuthorizedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.outsource.IsAuthorizedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.outsource.IsAuthorizedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.outsource.IsAuthorizedResponse.displayName = 'proto.outsource.IsAuthorizedResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.CreateOutsourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.CreateOutsourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.CreateOutsourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.CreateOutsourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsource: (f = msg.getOutsource()) && domain_outsource_pb.OutsourceDomain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.CreateOutsourceRequest}
 */
proto.outsource.CreateOutsourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.CreateOutsourceRequest;
  return proto.outsource.CreateOutsourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.CreateOutsourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.CreateOutsourceRequest}
 */
proto.outsource.CreateOutsourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_outsource_pb.OutsourceDomain;
      reader.readMessage(value,domain_outsource_pb.OutsourceDomain.deserializeBinaryFromReader);
      msg.setOutsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.CreateOutsourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.CreateOutsourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.CreateOutsourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.CreateOutsourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      domain_outsource_pb.OutsourceDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional coconala.protobuf.domain.OutsourceDomain outsource = 1;
 * @return {?proto.coconala.protobuf.domain.OutsourceDomain}
 */
proto.outsource.CreateOutsourceRequest.prototype.getOutsource = function() {
  return /** @type{?proto.coconala.protobuf.domain.OutsourceDomain} */ (
    jspb.Message.getWrapperField(this, domain_outsource_pb.OutsourceDomain, 1));
};


/**
 * @param {?proto.coconala.protobuf.domain.OutsourceDomain|undefined} value
 * @return {!proto.outsource.CreateOutsourceRequest} returns this
*/
proto.outsource.CreateOutsourceRequest.prototype.setOutsource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.CreateOutsourceRequest} returns this
 */
proto.outsource.CreateOutsourceRequest.prototype.clearOutsource = function() {
  return this.setOutsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.CreateOutsourceRequest.prototype.hasOutsource = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.CreateOutsourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.CreateOutsourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.CreateOutsourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.CreateOutsourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourceUlid: (f = msg.getOutsourceUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.CreateOutsourceResponse}
 */
proto.outsource.CreateOutsourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.CreateOutsourceResponse;
  return proto.outsource.CreateOutsourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.CreateOutsourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.CreateOutsourceResponse}
 */
proto.outsource.CreateOutsourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutsourceUlid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.CreateOutsourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.CreateOutsourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.CreateOutsourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.CreateOutsourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourceUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue outsource_ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.CreateOutsourceResponse.prototype.getOutsourceUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.CreateOutsourceResponse} returns this
*/
proto.outsource.CreateOutsourceResponse.prototype.setOutsourceUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.CreateOutsourceResponse} returns this
 */
proto.outsource.CreateOutsourceResponse.prototype.clearOutsourceUlid = function() {
  return this.setOutsourceUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.CreateOutsourceResponse.prototype.hasOutsourceUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.UpdateOutsourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.UpdateOutsourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.UpdateOutsourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.UpdateOutsourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourceUlid: (f = msg.getOutsourceUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    outsource: (f = msg.getOutsource()) && domain_outsource_pb.OutsourceDomain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.UpdateOutsourceRequest}
 */
proto.outsource.UpdateOutsourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.UpdateOutsourceRequest;
  return proto.outsource.UpdateOutsourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.UpdateOutsourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.UpdateOutsourceRequest}
 */
proto.outsource.UpdateOutsourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutsourceUlid(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new domain_outsource_pb.OutsourceDomain;
      reader.readMessage(value,domain_outsource_pb.OutsourceDomain.deserializeBinaryFromReader);
      msg.setOutsource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.UpdateOutsourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.UpdateOutsourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.UpdateOutsourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.UpdateOutsourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourceUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOutsource();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      domain_outsource_pb.OutsourceDomain.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue outsource_ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.UpdateOutsourceRequest.prototype.getOutsourceUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.UpdateOutsourceRequest} returns this
*/
proto.outsource.UpdateOutsourceRequest.prototype.setOutsourceUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.UpdateOutsourceRequest} returns this
 */
proto.outsource.UpdateOutsourceRequest.prototype.clearOutsourceUlid = function() {
  return this.setOutsourceUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.UpdateOutsourceRequest.prototype.hasOutsourceUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourceStatus status = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus}
 */
proto.outsource.UpdateOutsourceRequest.prototype.getStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} value
 * @return {!proto.outsource.UpdateOutsourceRequest} returns this
 */
proto.outsource.UpdateOutsourceRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional coconala.protobuf.domain.OutsourceDomain outsource = 3;
 * @return {?proto.coconala.protobuf.domain.OutsourceDomain}
 */
proto.outsource.UpdateOutsourceRequest.prototype.getOutsource = function() {
  return /** @type{?proto.coconala.protobuf.domain.OutsourceDomain} */ (
    jspb.Message.getWrapperField(this, domain_outsource_pb.OutsourceDomain, 3));
};


/**
 * @param {?proto.coconala.protobuf.domain.OutsourceDomain|undefined} value
 * @return {!proto.outsource.UpdateOutsourceRequest} returns this
*/
proto.outsource.UpdateOutsourceRequest.prototype.setOutsource = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.UpdateOutsourceRequest} returns this
 */
proto.outsource.UpdateOutsourceRequest.prototype.clearOutsource = function() {
  return this.setOutsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.UpdateOutsourceRequest.prototype.hasOutsource = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.UpdateOutsourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.UpdateOutsourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.UpdateOutsourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.UpdateOutsourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.UpdateOutsourceResponse}
 */
proto.outsource.UpdateOutsourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.UpdateOutsourceResponse;
  return proto.outsource.UpdateOutsourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.UpdateOutsourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.UpdateOutsourceResponse}
 */
proto.outsource.UpdateOutsourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.UpdateOutsourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.UpdateOutsourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.UpdateOutsourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.UpdateOutsourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.UpdateStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.UpdateStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.UpdateStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.UpdateStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourceUlid: (f = msg.getOutsourceUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.UpdateStatusRequest}
 */
proto.outsource.UpdateStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.UpdateStatusRequest;
  return proto.outsource.UpdateStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.UpdateStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.UpdateStatusRequest}
 */
proto.outsource.UpdateStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutsourceUlid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.UpdateStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.UpdateStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.UpdateStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.UpdateStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourceUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue outsource_ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.UpdateStatusRequest.prototype.getOutsourceUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.UpdateStatusRequest} returns this
*/
proto.outsource.UpdateStatusRequest.prototype.setOutsourceUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.UpdateStatusRequest} returns this
 */
proto.outsource.UpdateStatusRequest.prototype.clearOutsourceUlid = function() {
  return this.setOutsourceUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.UpdateStatusRequest.prototype.hasOutsourceUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.UpdateStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.UpdateStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.UpdateStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.UpdateStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.UpdateStatusResponse}
 */
proto.outsource.UpdateStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.UpdateStatusResponse;
  return proto.outsource.UpdateStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.UpdateStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.UpdateStatusResponse}
 */
proto.outsource.UpdateStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.UpdateStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.UpdateStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.UpdateStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.UpdateStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.GetMyOutsourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.GetMyOutsourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.GetMyOutsourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetMyOutsourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.outsource.MyOutsorucesFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.GetMyOutsourcesRequest}
 */
proto.outsource.GetMyOutsourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.GetMyOutsourcesRequest;
  return proto.outsource.GetMyOutsourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.GetMyOutsourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.GetMyOutsourcesRequest}
 */
proto.outsource.GetMyOutsourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    case 3:
      var value = new proto.outsource.MyOutsorucesFilter;
      reader.readMessage(value,proto.outsource.MyOutsorucesFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.GetMyOutsourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.GetMyOutsourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.GetMyOutsourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetMyOutsourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.outsource.MyOutsorucesFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value page = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.GetMyOutsourcesRequest.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.GetMyOutsourcesRequest} returns this
*/
proto.outsource.GetMyOutsourcesRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourcesRequest} returns this
 */
proto.outsource.GetMyOutsourcesRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourcesRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.GetMyOutsourcesRequest.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.GetMyOutsourcesRequest} returns this
*/
proto.outsource.GetMyOutsourcesRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourcesRequest} returns this
 */
proto.outsource.GetMyOutsourcesRequest.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourcesRequest.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MyOutsorucesFilter filter = 3;
 * @return {?proto.outsource.MyOutsorucesFilter}
 */
proto.outsource.GetMyOutsourcesRequest.prototype.getFilter = function() {
  return /** @type{?proto.outsource.MyOutsorucesFilter} */ (
    jspb.Message.getWrapperField(this, proto.outsource.MyOutsorucesFilter, 3));
};


/**
 * @param {?proto.outsource.MyOutsorucesFilter|undefined} value
 * @return {!proto.outsource.GetMyOutsourcesRequest} returns this
*/
proto.outsource.GetMyOutsourcesRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourcesRequest} returns this
 */
proto.outsource.GetMyOutsourcesRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourcesRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.MyOutsorucesFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.MyOutsorucesFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.MyOutsorucesFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.MyOutsorucesFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.MyOutsorucesFilter}
 */
proto.outsource.MyOutsorucesFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.MyOutsorucesFilter;
  return proto.outsource.MyOutsorucesFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.MyOutsorucesFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.MyOutsorucesFilter}
 */
proto.outsource.MyOutsorucesFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.MyOutsorucesFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.MyOutsorucesFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.MyOutsorucesFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.MyOutsorucesFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourceStatus status = 1;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus}
 */
proto.outsource.MyOutsorucesFilter.prototype.getStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} value
 * @return {!proto.outsource.MyOutsorucesFilter} returns this
 */
proto.outsource.MyOutsorucesFilter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.outsource.GetMyOutsourcesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.GetMyOutsourcesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.GetMyOutsourcesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetMyOutsourcesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourcesList: jspb.Message.toObjectList(msg.getOutsourcesList(),
    proto.outsource.MyOutsource.toObject, includeInstance),
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalCount: (f = msg.getTotalCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    unreadAppliedOutsourcesCount: (f = msg.getUnreadAppliedOutsourcesCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.GetMyOutsourcesResponse}
 */
proto.outsource.GetMyOutsourcesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.GetMyOutsourcesResponse;
  return proto.outsource.GetMyOutsourcesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.GetMyOutsourcesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.GetMyOutsourcesResponse}
 */
proto.outsource.GetMyOutsourcesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.outsource.MyOutsource;
      reader.readMessage(value,proto.outsource.MyOutsource.deserializeBinaryFromReader);
      msg.addOutsources(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalCount(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUnreadAppliedOutsourcesCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.GetMyOutsourcesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.GetMyOutsourcesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetMyOutsourcesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.outsource.MyOutsource.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnreadAppliedOutsourcesCount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MyOutsource outsources = 1;
 * @return {!Array<!proto.outsource.MyOutsource>}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.getOutsourcesList = function() {
  return /** @type{!Array<!proto.outsource.MyOutsource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.outsource.MyOutsource, 1));
};


/**
 * @param {!Array<!proto.outsource.MyOutsource>} value
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
*/
proto.outsource.GetMyOutsourcesResponse.prototype.setOutsourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.outsource.MyOutsource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.outsource.MyOutsource}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.addOutsources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.outsource.MyOutsource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
 */
proto.outsource.GetMyOutsourcesResponse.prototype.clearOutsourcesList = function() {
  return this.setOutsourcesList([]);
};


/**
 * optional google.protobuf.Int32Value page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
*/
proto.outsource.GetMyOutsourcesResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
 */
proto.outsource.GetMyOutsourcesResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
*/
proto.outsource.GetMyOutsourcesResponse.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
 */
proto.outsource.GetMyOutsourcesResponse.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value total_count = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.getTotalCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
*/
proto.outsource.GetMyOutsourcesResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
 */
proto.outsource.GetMyOutsourcesResponse.prototype.clearTotalCount = function() {
  return this.setTotalCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.hasTotalCount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value unread_applied_outsources_count = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.getUnreadAppliedOutsourcesCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
*/
proto.outsource.GetMyOutsourcesResponse.prototype.setUnreadAppliedOutsourcesCount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourcesResponse} returns this
 */
proto.outsource.GetMyOutsourcesResponse.prototype.clearUnreadAppliedOutsourcesCount = function() {
  return this.setUnreadAppliedOutsourcesCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourcesResponse.prototype.hasUnreadAppliedOutsourcesCount = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.MyOutsource.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.MyOutsource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.MyOutsource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.MyOutsource.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourceUlid: (f = msg.getOutsourceUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    paymentMethodType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minUnitPrice: (f = msg.getMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    maxUnitPrice: (f = msg.getMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    minWorkingHour: (f = msg.getMinWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    maxWorkingHour: (f = msg.getMaxWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    workingDayType: jspb.Message.getFieldWithDefault(msg, 9, 0),
    appliedCount: (f = msg.getAppliedCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    unreadAppliedCount: (f = msg.getUnreadAppliedCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    applicationFinishedCount: (f = msg.getApplicationFinishedCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.MyOutsource}
 */
proto.outsource.MyOutsource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.MyOutsource;
  return proto.outsource.MyOutsource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.MyOutsource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.MyOutsource}
 */
proto.outsource.MyOutsource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutsourceUlid(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (reader.readEnum());
      msg.setPaymentMethodType(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMinUnitPrice(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaxUnitPrice(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMinWorkingHour(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaxWorkingHour(value);
      break;
    case 9:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} */ (reader.readEnum());
      msg.setWorkingDayType(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setAppliedCount(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setUnreadAppliedCount(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setApplicationFinishedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.MyOutsource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.MyOutsource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.MyOutsource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.MyOutsource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourceUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMinWorkingHour();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxWorkingHour();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWorkingDayType();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAppliedCount();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getUnreadAppliedCount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getApplicationFinishedCount();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue outsource_ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.MyOutsource.prototype.getOutsourceUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setOutsourceUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearOutsourceUlid = function() {
  return this.setOutsourceUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasOutsourceUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourceStatus status = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus}
 */
proto.outsource.MyOutsource.prototype.getStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} value
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue title = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.MyOutsource.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType payment_method_type = 4;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType}
 */
proto.outsource.MyOutsource.prototype.getPaymentMethodType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} value
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.setPaymentMethodType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional google.protobuf.Int32Value min_unit_price = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.MyOutsource.prototype.getMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearMinUnitPrice = function() {
  return this.setMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasMinUnitPrice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value max_unit_price = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.MyOutsource.prototype.getMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearMaxUnitPrice = function() {
  return this.setMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasMaxUnitPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value min_working_hour = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.MyOutsource.prototype.getMinWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setMinWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearMinWorkingHour = function() {
  return this.setMinWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasMinWorkingHour = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value max_working_hour = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.MyOutsource.prototype.getMaxWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setMaxWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearMaxWorkingHour = function() {
  return this.setMaxWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasMaxWorkingHour = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourceWorkingDayType working_day_type = 9;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType}
 */
proto.outsource.MyOutsource.prototype.getWorkingDayType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} value
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.setWorkingDayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional google.protobuf.Int32Value applied_count = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.MyOutsource.prototype.getAppliedCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setAppliedCount = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearAppliedCount = function() {
  return this.setAppliedCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasAppliedCount = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.Int32Value unread_applied_count = 11;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.MyOutsource.prototype.getUnreadAppliedCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 11));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setUnreadAppliedCount = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearUnreadAppliedCount = function() {
  return this.setUnreadAppliedCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasUnreadAppliedCount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Int32Value application_finished_count = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.MyOutsource.prototype.getApplicationFinishedCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.MyOutsource} returns this
*/
proto.outsource.MyOutsource.prototype.setApplicationFinishedCount = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.MyOutsource} returns this
 */
proto.outsource.MyOutsource.prototype.clearApplicationFinishedCount = function() {
  return this.setApplicationFinishedCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.MyOutsource.prototype.hasApplicationFinishedCount = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.outsource.SearchOutsourcesRequest.repeatedFields_ = [11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.SearchOutsourcesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.SearchOutsourcesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.SearchOutsourcesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.SearchOutsourcesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isRecruiting: (f = msg.getIsRecruiting()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    keyword: (f = msg.getKeyword()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    experienceJobCategoryId: (f = msg.getExperienceJobCategoryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    paymentMethodType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hourlyMinUnitPrice: (f = msg.getHourlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    hourlyMaxUnitPrice: (f = msg.getHourlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMinUnitPrice: (f = msg.getMonthlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMaxUnitPrice: (f = msg.getMonthlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    workingDayTypesList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    skillIdsList: jspb.Message.toObjectList(msg.getSkillIdsList(),
    google_protobuf_wrappers_pb.Int32Value.toObject, includeInstance),
    featureIdsList: jspb.Message.toObjectList(msg.getFeatureIdsList(),
    google_protobuf_wrappers_pb.Int32Value.toObject, includeInstance),
    sortType: (f = msg.getSortType()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.SearchOutsourcesRequest}
 */
proto.outsource.SearchOutsourcesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.SearchOutsourcesRequest;
  return proto.outsource.SearchOutsourcesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.SearchOutsourcesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.SearchOutsourcesRequest}
 */
proto.outsource.SearchOutsourcesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsRecruiting(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setKeyword(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setExperienceJobCategoryId(value);
      break;
    case 6:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (reader.readEnum());
      msg.setPaymentMethodType(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMinUnitPrice(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMaxUnitPrice(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMinUnitPrice(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMaxUnitPrice(value);
      break;
    case 11:
      var value = /** @type {!Array<!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType>} */ (reader.readPackedEnum());
      msg.setWorkingDayTypesList(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.addSkillIds(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.addFeatureIds(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSortType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.SearchOutsourcesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.SearchOutsourcesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.SearchOutsourcesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.SearchOutsourcesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsRecruiting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getKeyword();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExperienceJobCategoryId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getHourlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHourlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWorkingDayTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      11,
      f
    );
  }
  f = message.getSkillIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFeatureIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSortType();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value page = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue is_recruiting = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getIsRecruiting = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setIsRecruiting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearIsRecruiting = function() {
  return this.setIsRecruiting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasIsRecruiting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue keyword = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getKeyword = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearKeyword = function() {
  return this.setKeyword(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasKeyword = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value experience_job_category_id = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getExperienceJobCategoryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setExperienceJobCategoryId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearExperienceJobCategoryId = function() {
  return this.setExperienceJobCategoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasExperienceJobCategoryId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType payment_method_type = 6;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getPaymentMethodType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.setPaymentMethodType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Int32Value hourly_min_unit_price = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getHourlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setHourlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearHourlyMinUnitPrice = function() {
  return this.setHourlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasHourlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value hourly_max_unit_price = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getHourlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setHourlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearHourlyMaxUnitPrice = function() {
  return this.setHourlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasHourlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_min_unit_price = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getMonthlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setMonthlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearMonthlyMinUnitPrice = function() {
  return this.setMonthlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasMonthlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_max_unit_price = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getMonthlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setMonthlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearMonthlyMaxUnitPrice = function() {
  return this.setMonthlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasMonthlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated coconala.protobuf.domain.enumeration.OutsourceWorkingDayType working_day_types = 11;
 * @return {!Array<!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType>}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getWorkingDayTypesList = function() {
  return /** @type {!Array<!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType>} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.setWorkingDayTypesList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} value
 * @param {number=} opt_index
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.addWorkingDayTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearWorkingDayTypesList = function() {
  return this.setWorkingDayTypesList([]);
};


/**
 * repeated google.protobuf.Int32Value skill_ids = 12;
 * @return {!Array<!proto.google.protobuf.Int32Value>}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getSkillIdsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Int32Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {!Array<!proto.google.protobuf.Int32Value>} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setSkillIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.google.protobuf.Int32Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.addSkillIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, google_protobuf_wrappers_pb.Int32Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearSkillIdsList = function() {
  return this.setSkillIdsList([]);
};


/**
 * repeated google.protobuf.Int32Value feature_ids = 13;
 * @return {!Array<!proto.google.protobuf.Int32Value>}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getFeatureIdsList = function() {
  return /** @type{!Array<!proto.google.protobuf.Int32Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 13));
};


/**
 * @param {!Array<!proto.google.protobuf.Int32Value>} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setFeatureIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.google.protobuf.Int32Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesRequest.prototype.addFeatureIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, google_protobuf_wrappers_pb.Int32Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearFeatureIdsList = function() {
  return this.setFeatureIdsList([]);
};


/**
 * optional google.protobuf.StringValue sort_type = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.SearchOutsourcesRequest.prototype.getSortType = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
*/
proto.outsource.SearchOutsourcesRequest.prototype.setSortType = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesRequest} returns this
 */
proto.outsource.SearchOutsourcesRequest.prototype.clearSortType = function() {
  return this.setSortType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesRequest.prototype.hasSortType = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.outsource.SearchOutsourcesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.SearchOutsourcesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.SearchOutsourcesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.SearchOutsourcesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.SearchOutsourcesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourcesList: jspb.Message.toObjectList(msg.getOutsourcesList(),
    domain_search_outsource_pb.SearchOutsourceDomain.toObject, includeInstance),
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    total: (f = msg.getTotal()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.SearchOutsourcesResponse}
 */
proto.outsource.SearchOutsourcesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.SearchOutsourcesResponse;
  return proto.outsource.SearchOutsourcesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.SearchOutsourcesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.SearchOutsourcesResponse}
 */
proto.outsource.SearchOutsourcesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new domain_search_outsource_pb.SearchOutsourceDomain;
      reader.readMessage(value,domain_search_outsource_pb.SearchOutsourceDomain.deserializeBinaryFromReader);
      msg.addOutsources(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.SearchOutsourcesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.SearchOutsourcesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.SearchOutsourcesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.SearchOutsourcesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      domain_search_outsource_pb.SearchOutsourceDomain.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated coconala.protobuf.domain.SearchOutsourceDomain outsources = 1;
 * @return {!Array<!proto.coconala.protobuf.domain.SearchOutsourceDomain>}
 */
proto.outsource.SearchOutsourcesResponse.prototype.getOutsourcesList = function() {
  return /** @type{!Array<!proto.coconala.protobuf.domain.SearchOutsourceDomain>} */ (
    jspb.Message.getRepeatedWrapperField(this, domain_search_outsource_pb.SearchOutsourceDomain, 1));
};


/**
 * @param {!Array<!proto.coconala.protobuf.domain.SearchOutsourceDomain>} value
 * @return {!proto.outsource.SearchOutsourcesResponse} returns this
*/
proto.outsource.SearchOutsourcesResponse.prototype.setOutsourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.coconala.protobuf.domain.SearchOutsourceDomain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.coconala.protobuf.domain.SearchOutsourceDomain}
 */
proto.outsource.SearchOutsourcesResponse.prototype.addOutsources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.coconala.protobuf.domain.SearchOutsourceDomain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.SearchOutsourcesResponse} returns this
 */
proto.outsource.SearchOutsourcesResponse.prototype.clearOutsourcesList = function() {
  return this.setOutsourcesList([]);
};


/**
 * optional google.protobuf.Int32Value page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesResponse.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesResponse} returns this
*/
proto.outsource.SearchOutsourcesResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesResponse} returns this
 */
proto.outsource.SearchOutsourcesResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesResponse.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesResponse} returns this
*/
proto.outsource.SearchOutsourcesResponse.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesResponse} returns this
 */
proto.outsource.SearchOutsourcesResponse.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesResponse.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value total = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.SearchOutsourcesResponse.prototype.getTotal = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.SearchOutsourcesResponse} returns this
*/
proto.outsource.SearchOutsourcesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SearchOutsourcesResponse} returns this
 */
proto.outsource.SearchOutsourcesResponse.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SearchOutsourcesResponse.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.SetBookmarkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.SetBookmarkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.SetBookmarkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.SetBookmarkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ulid: (f = msg.getUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.SetBookmarkRequest}
 */
proto.outsource.SetBookmarkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.SetBookmarkRequest;
  return proto.outsource.SetBookmarkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.SetBookmarkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.SetBookmarkRequest}
 */
proto.outsource.SetBookmarkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUlid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.SetBookmarkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.SetBookmarkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.SetBookmarkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.SetBookmarkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.SetBookmarkRequest.prototype.getUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.SetBookmarkRequest} returns this
*/
proto.outsource.SetBookmarkRequest.prototype.setUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.SetBookmarkRequest} returns this
 */
proto.outsource.SetBookmarkRequest.prototype.clearUlid = function() {
  return this.setUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.SetBookmarkRequest.prototype.hasUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.SetBookmarkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.SetBookmarkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.SetBookmarkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.SetBookmarkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.SetBookmarkResponse}
 */
proto.outsource.SetBookmarkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.SetBookmarkResponse;
  return proto.outsource.SetBookmarkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.SetBookmarkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.SetBookmarkResponse}
 */
proto.outsource.SetBookmarkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.SetBookmarkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.SetBookmarkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.SetBookmarkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.SetBookmarkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.GetOutsourceDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.GetOutsourceDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.GetOutsourceDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetOutsourceDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourceUlid: (f = msg.getOutsourceUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isIncrementViewCount: (f = msg.getIsIncrementViewCount()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.GetOutsourceDetailRequest}
 */
proto.outsource.GetOutsourceDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.GetOutsourceDetailRequest;
  return proto.outsource.GetOutsourceDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.GetOutsourceDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.GetOutsourceDetailRequest}
 */
proto.outsource.GetOutsourceDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutsourceUlid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsIncrementViewCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.GetOutsourceDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.GetOutsourceDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.GetOutsourceDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetOutsourceDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourceUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsIncrementViewCount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue outsource_ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.GetOutsourceDetailRequest.prototype.getOutsourceUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.GetOutsourceDetailRequest} returns this
*/
proto.outsource.GetOutsourceDetailRequest.prototype.setOutsourceUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetOutsourceDetailRequest} returns this
 */
proto.outsource.GetOutsourceDetailRequest.prototype.clearOutsourceUlid = function() {
  return this.setOutsourceUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetOutsourceDetailRequest.prototype.hasOutsourceUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue is_increment_view_count = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.outsource.GetOutsourceDetailRequest.prototype.getIsIncrementViewCount = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.outsource.GetOutsourceDetailRequest} returns this
*/
proto.outsource.GetOutsourceDetailRequest.prototype.setIsIncrementViewCount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetOutsourceDetailRequest} returns this
 */
proto.outsource.GetOutsourceDetailRequest.prototype.clearIsIncrementViewCount = function() {
  return this.setIsIncrementViewCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetOutsourceDetailRequest.prototype.hasIsIncrementViewCount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.GetMyOutsourceDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.GetMyOutsourceDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.GetMyOutsourceDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetMyOutsourceDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourceUlid: (f = msg.getOutsourceUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.GetMyOutsourceDetailRequest}
 */
proto.outsource.GetMyOutsourceDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.GetMyOutsourceDetailRequest;
  return proto.outsource.GetMyOutsourceDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.GetMyOutsourceDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.GetMyOutsourceDetailRequest}
 */
proto.outsource.GetMyOutsourceDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutsourceUlid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.GetMyOutsourceDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.GetMyOutsourceDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.GetMyOutsourceDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetMyOutsourceDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourceUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue outsource_ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.GetMyOutsourceDetailRequest.prototype.getOutsourceUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.GetMyOutsourceDetailRequest} returns this
*/
proto.outsource.GetMyOutsourceDetailRequest.prototype.setOutsourceUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourceDetailRequest} returns this
 */
proto.outsource.GetMyOutsourceDetailRequest.prototype.clearOutsourceUlid = function() {
  return this.setOutsourceUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourceDetailRequest.prototype.hasOutsourceUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.outsource.GetOutsourceDetailResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.GetOutsourceDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.GetOutsourceDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetOutsourceDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsource: (f = msg.getOutsource()) && proto.outsource.Outsource.toObject(includeInstance, f),
    outsourceDetailsList: jspb.Message.toObjectList(msg.getOutsourceDetailsList(),
    proto.outsource.OutsourceDetail.toObject, includeInstance),
    uploadedFilesList: jspb.Message.toObjectList(msg.getUploadedFilesList(),
    proto.outsource.OutsourceUploaded.toObject, includeInstance),
    outsourceCompany: (f = msg.getOutsourceCompany()) && proto.outsource.OutsourceCompany.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.outsource.User.toObject(includeInstance, f),
    count: (f = msg.getCount()) && proto.outsource.OutsourceDetailCount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.GetOutsourceDetailResponse}
 */
proto.outsource.GetOutsourceDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.GetOutsourceDetailResponse;
  return proto.outsource.GetOutsourceDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.GetOutsourceDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.GetOutsourceDetailResponse}
 */
proto.outsource.GetOutsourceDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.outsource.Outsource;
      reader.readMessage(value,proto.outsource.Outsource.deserializeBinaryFromReader);
      msg.setOutsource(value);
      break;
    case 2:
      var value = new proto.outsource.OutsourceDetail;
      reader.readMessage(value,proto.outsource.OutsourceDetail.deserializeBinaryFromReader);
      msg.addOutsourceDetails(value);
      break;
    case 3:
      var value = new proto.outsource.OutsourceUploaded;
      reader.readMessage(value,proto.outsource.OutsourceUploaded.deserializeBinaryFromReader);
      msg.addUploadedFiles(value);
      break;
    case 4:
      var value = new proto.outsource.OutsourceCompany;
      reader.readMessage(value,proto.outsource.OutsourceCompany.deserializeBinaryFromReader);
      msg.setOutsourceCompany(value);
      break;
    case 5:
      var value = new proto.outsource.User;
      reader.readMessage(value,proto.outsource.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = new proto.outsource.OutsourceDetailCount;
      reader.readMessage(value,proto.outsource.OutsourceDetailCount.deserializeBinaryFromReader);
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.GetOutsourceDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.GetOutsourceDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetOutsourceDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.outsource.Outsource.serializeBinaryToWriter
    );
  }
  f = message.getOutsourceDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.outsource.OutsourceDetail.serializeBinaryToWriter
    );
  }
  f = message.getUploadedFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.outsource.OutsourceUploaded.serializeBinaryToWriter
    );
  }
  f = message.getOutsourceCompany();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.outsource.OutsourceCompany.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.outsource.User.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.outsource.OutsourceDetailCount.serializeBinaryToWriter
    );
  }
};


/**
 * optional Outsource outsource = 1;
 * @return {?proto.outsource.Outsource}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.getOutsource = function() {
  return /** @type{?proto.outsource.Outsource} */ (
    jspb.Message.getWrapperField(this, proto.outsource.Outsource, 1));
};


/**
 * @param {?proto.outsource.Outsource|undefined} value
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
*/
proto.outsource.GetOutsourceDetailResponse.prototype.setOutsource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
 */
proto.outsource.GetOutsourceDetailResponse.prototype.clearOutsource = function() {
  return this.setOutsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.hasOutsource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OutsourceDetail outsource_details = 2;
 * @return {!Array<!proto.outsource.OutsourceDetail>}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.getOutsourceDetailsList = function() {
  return /** @type{!Array<!proto.outsource.OutsourceDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.outsource.OutsourceDetail, 2));
};


/**
 * @param {!Array<!proto.outsource.OutsourceDetail>} value
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
*/
proto.outsource.GetOutsourceDetailResponse.prototype.setOutsourceDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.outsource.OutsourceDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.outsource.OutsourceDetail}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.addOutsourceDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.outsource.OutsourceDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
 */
proto.outsource.GetOutsourceDetailResponse.prototype.clearOutsourceDetailsList = function() {
  return this.setOutsourceDetailsList([]);
};


/**
 * repeated OutsourceUploaded uploaded_files = 3;
 * @return {!Array<!proto.outsource.OutsourceUploaded>}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.getUploadedFilesList = function() {
  return /** @type{!Array<!proto.outsource.OutsourceUploaded>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.outsource.OutsourceUploaded, 3));
};


/**
 * @param {!Array<!proto.outsource.OutsourceUploaded>} value
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
*/
proto.outsource.GetOutsourceDetailResponse.prototype.setUploadedFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.outsource.OutsourceUploaded=} opt_value
 * @param {number=} opt_index
 * @return {!proto.outsource.OutsourceUploaded}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.addUploadedFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.outsource.OutsourceUploaded, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
 */
proto.outsource.GetOutsourceDetailResponse.prototype.clearUploadedFilesList = function() {
  return this.setUploadedFilesList([]);
};


/**
 * optional OutsourceCompany outsource_company = 4;
 * @return {?proto.outsource.OutsourceCompany}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.getOutsourceCompany = function() {
  return /** @type{?proto.outsource.OutsourceCompany} */ (
    jspb.Message.getWrapperField(this, proto.outsource.OutsourceCompany, 4));
};


/**
 * @param {?proto.outsource.OutsourceCompany|undefined} value
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
*/
proto.outsource.GetOutsourceDetailResponse.prototype.setOutsourceCompany = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
 */
proto.outsource.GetOutsourceDetailResponse.prototype.clearOutsourceCompany = function() {
  return this.setOutsourceCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.hasOutsourceCompany = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional User user = 5;
 * @return {?proto.outsource.User}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.getUser = function() {
  return /** @type{?proto.outsource.User} */ (
    jspb.Message.getWrapperField(this, proto.outsource.User, 5));
};


/**
 * @param {?proto.outsource.User|undefined} value
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
*/
proto.outsource.GetOutsourceDetailResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
 */
proto.outsource.GetOutsourceDetailResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional OutsourceDetailCount count = 6;
 * @return {?proto.outsource.OutsourceDetailCount}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.getCount = function() {
  return /** @type{?proto.outsource.OutsourceDetailCount} */ (
    jspb.Message.getWrapperField(this, proto.outsource.OutsourceDetailCount, 6));
};


/**
 * @param {?proto.outsource.OutsourceDetailCount|undefined} value
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
*/
proto.outsource.GetOutsourceDetailResponse.prototype.setCount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetOutsourceDetailResponse} returns this
 */
proto.outsource.GetOutsourceDetailResponse.prototype.clearCount = function() {
  return this.setCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetOutsourceDetailResponse.prototype.hasCount = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.outsource.GetMyOutsourceDetailResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.GetMyOutsourceDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.GetMyOutsourceDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetMyOutsourceDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsource: (f = msg.getOutsource()) && proto.outsource.Outsource.toObject(includeInstance, f),
    outsourceDetailsList: jspb.Message.toObjectList(msg.getOutsourceDetailsList(),
    proto.outsource.OutsourceDetail.toObject, includeInstance),
    uploadedFilesList: jspb.Message.toObjectList(msg.getUploadedFilesList(),
    proto.outsource.OutsourceUploaded.toObject, includeInstance),
    outsourceCompany: (f = msg.getOutsourceCompany()) && proto.outsource.OutsourceCompany.toObject(includeInstance, f),
    user: (f = msg.getUser()) && proto.outsource.User.toObject(includeInstance, f),
    count: (f = msg.getCount()) && proto.outsource.OutsourceDetailCount.toObject(includeInstance, f),
    outsourceCompanyContact: (f = msg.getOutsourceCompanyContact()) && proto.outsource.OutsourceCompanyContact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse}
 */
proto.outsource.GetMyOutsourceDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.GetMyOutsourceDetailResponse;
  return proto.outsource.GetMyOutsourceDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.GetMyOutsourceDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse}
 */
proto.outsource.GetMyOutsourceDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.outsource.Outsource;
      reader.readMessage(value,proto.outsource.Outsource.deserializeBinaryFromReader);
      msg.setOutsource(value);
      break;
    case 2:
      var value = new proto.outsource.OutsourceDetail;
      reader.readMessage(value,proto.outsource.OutsourceDetail.deserializeBinaryFromReader);
      msg.addOutsourceDetails(value);
      break;
    case 3:
      var value = new proto.outsource.OutsourceUploaded;
      reader.readMessage(value,proto.outsource.OutsourceUploaded.deserializeBinaryFromReader);
      msg.addUploadedFiles(value);
      break;
    case 4:
      var value = new proto.outsource.OutsourceCompany;
      reader.readMessage(value,proto.outsource.OutsourceCompany.deserializeBinaryFromReader);
      msg.setOutsourceCompany(value);
      break;
    case 5:
      var value = new proto.outsource.User;
      reader.readMessage(value,proto.outsource.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 6:
      var value = new proto.outsource.OutsourceDetailCount;
      reader.readMessage(value,proto.outsource.OutsourceDetailCount.deserializeBinaryFromReader);
      msg.setCount(value);
      break;
    case 7:
      var value = new proto.outsource.OutsourceCompanyContact;
      reader.readMessage(value,proto.outsource.OutsourceCompanyContact.deserializeBinaryFromReader);
      msg.setOutsourceCompanyContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.GetMyOutsourceDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.GetMyOutsourceDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.GetMyOutsourceDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsource();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.outsource.Outsource.serializeBinaryToWriter
    );
  }
  f = message.getOutsourceDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.outsource.OutsourceDetail.serializeBinaryToWriter
    );
  }
  f = message.getUploadedFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.outsource.OutsourceUploaded.serializeBinaryToWriter
    );
  }
  f = message.getOutsourceCompany();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.outsource.OutsourceCompany.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.outsource.User.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.outsource.OutsourceDetailCount.serializeBinaryToWriter
    );
  }
  f = message.getOutsourceCompanyContact();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.outsource.OutsourceCompanyContact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Outsource outsource = 1;
 * @return {?proto.outsource.Outsource}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.getOutsource = function() {
  return /** @type{?proto.outsource.Outsource} */ (
    jspb.Message.getWrapperField(this, proto.outsource.Outsource, 1));
};


/**
 * @param {?proto.outsource.Outsource|undefined} value
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
*/
proto.outsource.GetMyOutsourceDetailResponse.prototype.setOutsource = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.clearOutsource = function() {
  return this.setOutsource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.hasOutsource = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated OutsourceDetail outsource_details = 2;
 * @return {!Array<!proto.outsource.OutsourceDetail>}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.getOutsourceDetailsList = function() {
  return /** @type{!Array<!proto.outsource.OutsourceDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.outsource.OutsourceDetail, 2));
};


/**
 * @param {!Array<!proto.outsource.OutsourceDetail>} value
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
*/
proto.outsource.GetMyOutsourceDetailResponse.prototype.setOutsourceDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.outsource.OutsourceDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.outsource.OutsourceDetail}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.addOutsourceDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.outsource.OutsourceDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.clearOutsourceDetailsList = function() {
  return this.setOutsourceDetailsList([]);
};


/**
 * repeated OutsourceUploaded uploaded_files = 3;
 * @return {!Array<!proto.outsource.OutsourceUploaded>}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.getUploadedFilesList = function() {
  return /** @type{!Array<!proto.outsource.OutsourceUploaded>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.outsource.OutsourceUploaded, 3));
};


/**
 * @param {!Array<!proto.outsource.OutsourceUploaded>} value
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
*/
proto.outsource.GetMyOutsourceDetailResponse.prototype.setUploadedFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.outsource.OutsourceUploaded=} opt_value
 * @param {number=} opt_index
 * @return {!proto.outsource.OutsourceUploaded}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.addUploadedFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.outsource.OutsourceUploaded, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.clearUploadedFilesList = function() {
  return this.setUploadedFilesList([]);
};


/**
 * optional OutsourceCompany outsource_company = 4;
 * @return {?proto.outsource.OutsourceCompany}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.getOutsourceCompany = function() {
  return /** @type{?proto.outsource.OutsourceCompany} */ (
    jspb.Message.getWrapperField(this, proto.outsource.OutsourceCompany, 4));
};


/**
 * @param {?proto.outsource.OutsourceCompany|undefined} value
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
*/
proto.outsource.GetMyOutsourceDetailResponse.prototype.setOutsourceCompany = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.clearOutsourceCompany = function() {
  return this.setOutsourceCompany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.hasOutsourceCompany = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional User user = 5;
 * @return {?proto.outsource.User}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.getUser = function() {
  return /** @type{?proto.outsource.User} */ (
    jspb.Message.getWrapperField(this, proto.outsource.User, 5));
};


/**
 * @param {?proto.outsource.User|undefined} value
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
*/
proto.outsource.GetMyOutsourceDetailResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional OutsourceDetailCount count = 6;
 * @return {?proto.outsource.OutsourceDetailCount}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.getCount = function() {
  return /** @type{?proto.outsource.OutsourceDetailCount} */ (
    jspb.Message.getWrapperField(this, proto.outsource.OutsourceDetailCount, 6));
};


/**
 * @param {?proto.outsource.OutsourceDetailCount|undefined} value
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
*/
proto.outsource.GetMyOutsourceDetailResponse.prototype.setCount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.clearCount = function() {
  return this.setCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.hasCount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional OutsourceCompanyContact outsource_company_contact = 7;
 * @return {?proto.outsource.OutsourceCompanyContact}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.getOutsourceCompanyContact = function() {
  return /** @type{?proto.outsource.OutsourceCompanyContact} */ (
    jspb.Message.getWrapperField(this, proto.outsource.OutsourceCompanyContact, 7));
};


/**
 * @param {?proto.outsource.OutsourceCompanyContact|undefined} value
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
*/
proto.outsource.GetMyOutsourceDetailResponse.prototype.setOutsourceCompanyContact = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.GetMyOutsourceDetailResponse} returns this
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.clearOutsourceCompanyContact = function() {
  return this.setOutsourceCompanyContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.GetMyOutsourceDetailResponse.prototype.hasOutsourceCompanyContact = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.Outsource.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.Outsource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.Outsource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.Outsource.toObject = function(includeInstance, msg) {
  var f, obj = {
    outsourceUlid: (f = msg.getOutsourceUlid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    experienceJobCategoryId: (f = msg.getExperienceJobCategoryId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    paymentMethodType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hourlyMinUnitPrice: (f = msg.getHourlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    hourlyMaxUnitPrice: (f = msg.getHourlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMinUnitPrice: (f = msg.getMonthlyMinUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMaxUnitPrice: (f = msg.getMonthlyMaxUnitPrice()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    workingDayType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    weeklyMinWorkingHour: (f = msg.getWeeklyMinWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    weeklyMaxWorkingHour: (f = msg.getWeeklyMaxWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMinWorkingHour: (f = msg.getMonthlyMinWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    monthlyMaxWorkingHour: (f = msg.getMonthlyMaxWorkingHour()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    applicationDefaultMessage: (f = msg.getApplicationDefaultMessage()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.Outsource}
 */
proto.outsource.Outsource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.Outsource;
  return proto.outsource.Outsource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.Outsource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.Outsource}
 */
proto.outsource.Outsource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOutsourceUlid(value);
      break;
    case 2:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setExperienceJobCategoryId(value);
      break;
    case 6:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (reader.readEnum());
      msg.setPaymentMethodType(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMinUnitPrice(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setHourlyMaxUnitPrice(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMinUnitPrice(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMaxUnitPrice(value);
      break;
    case 11:
      var value = /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} */ (reader.readEnum());
      msg.setWorkingDayType(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setWeeklyMinWorkingHour(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setWeeklyMaxWorkingHour(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMinWorkingHour(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMonthlyMaxWorkingHour(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setApplicationDefaultMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.Outsource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.Outsource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.Outsource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.Outsource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutsourceUlid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExperienceJobCategoryId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPaymentMethodType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getHourlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getHourlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMinUnitPrice();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMaxUnitPrice();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWorkingDayType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getWeeklyMinWorkingHour();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getWeeklyMaxWorkingHour();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMinWorkingHour();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMonthlyMaxWorkingHour();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getApplicationDefaultMessage();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue outsource_ulid = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.Outsource.prototype.getOutsourceUlid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setOutsourceUlid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearOutsourceUlid = function() {
  return this.setOutsourceUlid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasOutsourceUlid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourceStatus status = 2;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus}
 */
proto.outsource.Outsource.prototype.getStatus = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceStatus} value
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue title = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.Outsource.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue description = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.Outsource.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value experience_job_category_id = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getExperienceJobCategoryId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setExperienceJobCategoryId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearExperienceJobCategoryId = function() {
  return this.setExperienceJobCategoryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasExperienceJobCategoryId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType payment_method_type = 6;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType}
 */
proto.outsource.Outsource.prototype.getPaymentMethodType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourcePaymentMethodType} value
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.setPaymentMethodType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional google.protobuf.Int32Value hourly_min_unit_price = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getHourlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setHourlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearHourlyMinUnitPrice = function() {
  return this.setHourlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasHourlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value hourly_max_unit_price = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getHourlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setHourlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearHourlyMaxUnitPrice = function() {
  return this.setHourlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasHourlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_min_unit_price = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getMonthlyMinUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setMonthlyMinUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearMonthlyMinUnitPrice = function() {
  return this.setMonthlyMinUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasMonthlyMinUnitPrice = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_max_unit_price = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getMonthlyMaxUnitPrice = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setMonthlyMaxUnitPrice = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearMonthlyMaxUnitPrice = function() {
  return this.setMonthlyMaxUnitPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasMonthlyMaxUnitPrice = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional coconala.protobuf.domain.enumeration.OutsourceWorkingDayType working_day_type = 11;
 * @return {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType}
 */
proto.outsource.Outsource.prototype.getWorkingDayType = function() {
  return /** @type {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.coconala.protobuf.domain.enumeration.OutsourceWorkingDayType} value
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.setWorkingDayType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional google.protobuf.Int32Value weekly_min_working_hour = 12;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getWeeklyMinWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 12));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setWeeklyMinWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearWeeklyMinWorkingHour = function() {
  return this.setWeeklyMinWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasWeeklyMinWorkingHour = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Int32Value weekly_max_working_hour = 13;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getWeeklyMaxWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 13));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setWeeklyMaxWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearWeeklyMaxWorkingHour = function() {
  return this.setWeeklyMaxWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasWeeklyMaxWorkingHour = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_min_working_hour = 14;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getMonthlyMinWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 14));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setMonthlyMinWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearMonthlyMinWorkingHour = function() {
  return this.setMonthlyMinWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasMonthlyMinWorkingHour = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Int32Value monthly_max_working_hour = 15;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.Outsource.prototype.getMonthlyMaxWorkingHour = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 15));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setMonthlyMaxWorkingHour = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearMonthlyMaxWorkingHour = function() {
  return this.setMonthlyMaxWorkingHour(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasMonthlyMaxWorkingHour = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue application_default_message = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.Outsource.prototype.getApplicationDefaultMessage = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.Outsource} returns this
*/
proto.outsource.Outsource.prototype.setApplicationDefaultMessage = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.Outsource} returns this
 */
proto.outsource.Outsource.prototype.clearApplicationDefaultMessage = function() {
  return this.setApplicationDefaultMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.Outsource.prototype.hasApplicationDefaultMessage = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.OutsourceDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.OutsourceDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.OutsourceDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    detailKey: (f = msg.getDetailKey()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    detailValue: (f = msg.getDetailValue()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    detailRelatedId: (f = msg.getDetailRelatedId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.OutsourceDetail}
 */
proto.outsource.OutsourceDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.OutsourceDetail;
  return proto.outsource.OutsourceDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.OutsourceDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.OutsourceDetail}
 */
proto.outsource.OutsourceDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDetailKey(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDetailValue(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setDetailRelatedId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.OutsourceDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.OutsourceDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.OutsourceDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getDetailKey();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDetailValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDetailRelatedId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceDetail.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceDetail} returns this
*/
proto.outsource.OutsourceDetail.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceDetail} returns this
 */
proto.outsource.OutsourceDetail.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceDetail.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue detail_key = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceDetail.prototype.getDetailKey = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceDetail} returns this
*/
proto.outsource.OutsourceDetail.prototype.setDetailKey = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceDetail} returns this
 */
proto.outsource.OutsourceDetail.prototype.clearDetailKey = function() {
  return this.setDetailKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceDetail.prototype.hasDetailKey = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue detail_value = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceDetail.prototype.getDetailValue = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceDetail} returns this
*/
proto.outsource.OutsourceDetail.prototype.setDetailValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceDetail} returns this
 */
proto.outsource.OutsourceDetail.prototype.clearDetailValue = function() {
  return this.setDetailValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceDetail.prototype.hasDetailValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value detail_related_id = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceDetail.prototype.getDetailRelatedId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceDetail} returns this
*/
proto.outsource.OutsourceDetail.prototype.setDetailRelatedId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceDetail} returns this
 */
proto.outsource.OutsourceDetail.prototype.clearDetailRelatedId = function() {
  return this.setDetailRelatedId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceDetail.prototype.hasDetailRelatedId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.OutsourceUploaded.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.OutsourceUploaded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.OutsourceUploaded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceUploaded.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    fileName: (f = msg.getFileName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    fileUrl: (f = msg.getFileUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    size: (f = msg.getSize()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    mime: (f = msg.getMime()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.OutsourceUploaded}
 */
proto.outsource.OutsourceUploaded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.OutsourceUploaded;
  return proto.outsource.OutsourceUploaded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.OutsourceUploaded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.OutsourceUploaded}
 */
proto.outsource.OutsourceUploaded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFileName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFileUrl(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.OutsourceUploaded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.OutsourceUploaded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.OutsourceUploaded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceUploaded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFileName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFileUrl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getMime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceUploaded.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceUploaded} returns this
*/
proto.outsource.OutsourceUploaded.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceUploaded} returns this
 */
proto.outsource.OutsourceUploaded.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceUploaded.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue file_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceUploaded.prototype.getFileName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceUploaded} returns this
*/
proto.outsource.OutsourceUploaded.prototype.setFileName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceUploaded} returns this
 */
proto.outsource.OutsourceUploaded.prototype.clearFileName = function() {
  return this.setFileName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceUploaded.prototype.hasFileName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue file_url = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceUploaded.prototype.getFileUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceUploaded} returns this
*/
proto.outsource.OutsourceUploaded.prototype.setFileUrl = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceUploaded} returns this
 */
proto.outsource.OutsourceUploaded.prototype.clearFileUrl = function() {
  return this.setFileUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceUploaded.prototype.hasFileUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value size = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceUploaded.prototype.getSize = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceUploaded} returns this
*/
proto.outsource.OutsourceUploaded.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceUploaded} returns this
 */
proto.outsource.OutsourceUploaded.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceUploaded.prototype.hasSize = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue mime = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceUploaded.prototype.getMime = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceUploaded} returns this
*/
proto.outsource.OutsourceUploaded.prototype.setMime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceUploaded} returns this
 */
proto.outsource.OutsourceUploaded.prototype.clearMime = function() {
  return this.setMime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceUploaded.prototype.hasMime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.OutsourceCompany.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.OutsourceCompany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.OutsourceCompany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceCompany.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    url: (f = msg.getUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefecture: (f = msg.getPrefecture()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    logoFileUrl: (f = msg.getLogoFileUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    postalCode: (f = msg.getPostalCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    prefectureId: (f = msg.getPrefectureId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    city: (f = msg.getCity()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    districtName: (f = msg.getDistrictName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    streetAddress: (f = msg.getStreetAddress()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    buildingName: (f = msg.getBuildingName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    logoFileName: (f = msg.getLogoFileName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.OutsourceCompany}
 */
proto.outsource.OutsourceCompany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.OutsourceCompany;
  return proto.outsource.OutsourceCompany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.OutsourceCompany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.OutsourceCompany}
 */
proto.outsource.OutsourceCompany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUrl(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrefecture(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLogoFileUrl(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPostalCode(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPrefectureId(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCity(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDistrictName(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStreetAddress(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuildingName(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLogoFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.OutsourceCompany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.OutsourceCompany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.OutsourceCompany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceCompany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefecture();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLogoFileUrl();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPostalCode();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrefectureId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCity();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDistrictName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStreetAddress();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBuildingName();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLogoFileName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceCompany.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue url = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setUrl = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearUrl = function() {
  return this.setUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue description = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue prefecture = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getPrefecture = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setPrefecture = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearPrefecture = function() {
  return this.setPrefecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasPrefecture = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue logo_file_url = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getLogoFileUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setLogoFileUrl = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearLogoFileUrl = function() {
  return this.setLogoFileUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasLogoFileUrl = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue postal_code = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getPostalCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setPostalCode = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearPostalCode = function() {
  return this.setPostalCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasPostalCode = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value prefecture_id = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceCompany.prototype.getPrefectureId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setPrefectureId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearPrefectureId = function() {
  return this.setPrefectureId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasPrefectureId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue city = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getCity = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setCity = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearCity = function() {
  return this.setCity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasCity = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue district_name = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getDistrictName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setDistrictName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearDistrictName = function() {
  return this.setDistrictName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasDistrictName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue street_address = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getStreetAddress = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setStreetAddress = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearStreetAddress = function() {
  return this.setStreetAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasStreetAddress = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue building_name = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getBuildingName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setBuildingName = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearBuildingName = function() {
  return this.setBuildingName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasBuildingName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue logo_file_name = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompany.prototype.getLogoFileName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompany} returns this
*/
proto.outsource.OutsourceCompany.prototype.setLogoFileName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompany} returns this
 */
proto.outsource.OutsourceCompany.prototype.clearLogoFileName = function() {
  return this.setLogoFileName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompany.prototype.hasLogoFileName = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.User.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    logoFileUrl: (f = msg.getLogoFileUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    rankRate: (f = msg.getRankRate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    reviewCount: (f = msg.getReviewCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isIdentification: (f = msg.getIsIdentification()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isNdaConclusion: (f = msg.getIsNdaConclusion()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.User}
 */
proto.outsource.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.User;
  return proto.outsource.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.User}
 */
proto.outsource.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLogoFileUrl(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRankRate(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setReviewCount(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsIdentification(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsNdaConclusion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLogoFileUrl();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRankRate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getReviewCount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsIdentification();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsNdaConclusion();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.User.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.User} returns this
*/
proto.outsource.User.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.User} returns this
 */
proto.outsource.User.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.User.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.User.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.User} returns this
*/
proto.outsource.User.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.User} returns this
 */
proto.outsource.User.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.User.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue logo_file_url = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.User.prototype.getLogoFileUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.User} returns this
*/
proto.outsource.User.prototype.setLogoFileUrl = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.User} returns this
 */
proto.outsource.User.prototype.clearLogoFileUrl = function() {
  return this.setLogoFileUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.User.prototype.hasLogoFileUrl = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue rank_rate = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.User.prototype.getRankRate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.User} returns this
*/
proto.outsource.User.prototype.setRankRate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.User} returns this
 */
proto.outsource.User.prototype.clearRankRate = function() {
  return this.setRankRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.User.prototype.hasRankRate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value review_count = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.User.prototype.getReviewCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.User} returns this
*/
proto.outsource.User.prototype.setReviewCount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.User} returns this
 */
proto.outsource.User.prototype.clearReviewCount = function() {
  return this.setReviewCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.User.prototype.hasReviewCount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue is_identification = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.outsource.User.prototype.getIsIdentification = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.outsource.User} returns this
*/
proto.outsource.User.prototype.setIsIdentification = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.User} returns this
 */
proto.outsource.User.prototype.clearIsIdentification = function() {
  return this.setIsIdentification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.User.prototype.hasIsIdentification = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue is_nda_conclusion = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.outsource.User.prototype.getIsNdaConclusion = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.outsource.User} returns this
*/
proto.outsource.User.prototype.setIsNdaConclusion = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.User} returns this
 */
proto.outsource.User.prototype.clearIsNdaConclusion = function() {
  return this.setIsNdaConclusion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.User.prototype.hasIsNdaConclusion = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.OutsourceDetailCount.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.OutsourceDetailCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.OutsourceDetailCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceDetailCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    applicationCount: (f = msg.getApplicationCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    finishedCount: (f = msg.getFinishedCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    viewCount: (f = msg.getViewCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.OutsourceDetailCount}
 */
proto.outsource.OutsourceDetailCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.OutsourceDetailCount;
  return proto.outsource.OutsourceDetailCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.OutsourceDetailCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.OutsourceDetailCount}
 */
proto.outsource.OutsourceDetailCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setApplicationCount(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setFinishedCount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setViewCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.OutsourceDetailCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.OutsourceDetailCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.OutsourceDetailCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceDetailCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplicationCount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getFinishedCount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getViewCount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value application_count = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceDetailCount.prototype.getApplicationCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceDetailCount} returns this
*/
proto.outsource.OutsourceDetailCount.prototype.setApplicationCount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceDetailCount} returns this
 */
proto.outsource.OutsourceDetailCount.prototype.clearApplicationCount = function() {
  return this.setApplicationCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceDetailCount.prototype.hasApplicationCount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value finished_count = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceDetailCount.prototype.getFinishedCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceDetailCount} returns this
*/
proto.outsource.OutsourceDetailCount.prototype.setFinishedCount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceDetailCount} returns this
 */
proto.outsource.OutsourceDetailCount.prototype.clearFinishedCount = function() {
  return this.setFinishedCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceDetailCount.prototype.hasFinishedCount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value view_count = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceDetailCount.prototype.getViewCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceDetailCount} returns this
*/
proto.outsource.OutsourceDetailCount.prototype.setViewCount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceDetailCount} returns this
 */
proto.outsource.OutsourceDetailCount.prototype.clearViewCount = function() {
  return this.setViewCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceDetailCount.prototype.hasViewCount = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.OutsourceCompanyContact.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.OutsourceCompanyContact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.OutsourceCompanyContact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceCompanyContact.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    lastName: (f = msg.getLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstName: (f = msg.getFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastNameKana: (f = msg.getLastNameKana()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstNameKana: (f = msg.getFirstNameKana()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.OutsourceCompanyContact}
 */
proto.outsource.OutsourceCompanyContact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.OutsourceCompanyContact;
  return proto.outsource.OutsourceCompanyContact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.OutsourceCompanyContact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.OutsourceCompanyContact}
 */
proto.outsource.OutsourceCompanyContact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastNameKana(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstNameKana(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.OutsourceCompanyContact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.OutsourceCompanyContact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.OutsourceCompanyContact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.OutsourceCompanyContact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getLastName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastNameKana();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstNameKana();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.outsource.OutsourceCompanyContact.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
*/
proto.outsource.OutsourceCompanyContact.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
 */
proto.outsource.OutsourceCompanyContact.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompanyContact.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue last_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompanyContact.prototype.getLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
*/
proto.outsource.OutsourceCompanyContact.prototype.setLastName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
 */
proto.outsource.OutsourceCompanyContact.prototype.clearLastName = function() {
  return this.setLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompanyContact.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue first_name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompanyContact.prototype.getFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
*/
proto.outsource.OutsourceCompanyContact.prototype.setFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
 */
proto.outsource.OutsourceCompanyContact.prototype.clearFirstName = function() {
  return this.setFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompanyContact.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue last_name_kana = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompanyContact.prototype.getLastNameKana = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
*/
proto.outsource.OutsourceCompanyContact.prototype.setLastNameKana = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
 */
proto.outsource.OutsourceCompanyContact.prototype.clearLastNameKana = function() {
  return this.setLastNameKana(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompanyContact.prototype.hasLastNameKana = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue first_name_kana = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompanyContact.prototype.getFirstNameKana = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
*/
proto.outsource.OutsourceCompanyContact.prototype.setFirstNameKana = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
 */
proto.outsource.OutsourceCompanyContact.prototype.clearFirstNameKana = function() {
  return this.setFirstNameKana(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompanyContact.prototype.hasFirstNameKana = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue phone_number = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.outsource.OutsourceCompanyContact.prototype.getPhoneNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
*/
proto.outsource.OutsourceCompanyContact.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.OutsourceCompanyContact} returns this
 */
proto.outsource.OutsourceCompanyContact.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.OutsourceCompanyContact.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.IsAuthorizedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.IsAuthorizedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.IsAuthorizedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.IsAuthorizedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.IsAuthorizedRequest}
 */
proto.outsource.IsAuthorizedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.IsAuthorizedRequest;
  return proto.outsource.IsAuthorizedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.IsAuthorizedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.IsAuthorizedRequest}
 */
proto.outsource.IsAuthorizedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.IsAuthorizedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.IsAuthorizedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.IsAuthorizedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.IsAuthorizedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.outsource.IsAuthorizedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.outsource.IsAuthorizedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.outsource.IsAuthorizedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.IsAuthorizedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSellerRegistered: (f = msg.getIsSellerRegistered()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isAuthenticatedSms: (f = msg.getIsAuthenticatedSms()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.outsource.IsAuthorizedResponse}
 */
proto.outsource.IsAuthorizedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.outsource.IsAuthorizedResponse;
  return proto.outsource.IsAuthorizedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.outsource.IsAuthorizedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.outsource.IsAuthorizedResponse}
 */
proto.outsource.IsAuthorizedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsSellerRegistered(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsAuthenticatedSms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.outsource.IsAuthorizedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.outsource.IsAuthorizedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.outsource.IsAuthorizedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.outsource.IsAuthorizedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSellerRegistered();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsAuthenticatedSms();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue is_seller_registered = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.outsource.IsAuthorizedResponse.prototype.getIsSellerRegistered = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.outsource.IsAuthorizedResponse} returns this
*/
proto.outsource.IsAuthorizedResponse.prototype.setIsSellerRegistered = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.IsAuthorizedResponse} returns this
 */
proto.outsource.IsAuthorizedResponse.prototype.clearIsSellerRegistered = function() {
  return this.setIsSellerRegistered(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.IsAuthorizedResponse.prototype.hasIsSellerRegistered = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue is_authenticated_sms = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.outsource.IsAuthorizedResponse.prototype.getIsAuthenticatedSms = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.outsource.IsAuthorizedResponse} returns this
*/
proto.outsource.IsAuthorizedResponse.prototype.setIsAuthenticatedSms = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.outsource.IsAuthorizedResponse} returns this
 */
proto.outsource.IsAuthorizedResponse.prototype.clearIsAuthenticatedSms = function() {
  return this.setIsAuthenticatedSms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.outsource.IsAuthorizedResponse.prototype.hasIsAuthenticatedSms = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.outsource);
